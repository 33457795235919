import * as angular from 'angular';

var app = angular.module('strukshurUtilService', []);
app.factory('strukshurUtilService', ($window) => {
    var service = {
        supportedImageFormats: ['image/bmp', 'image/gif', 'image/jpeg', 'image/jpg', 'image/png', 'image/x-png'],

        /**
         * Returns wether or not the given string is a valid url
         *
         * @param  {string}  str  The string to check
         *
         * @return {bool}
         */
        isValidURL: function (str) {
            var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
                '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
                '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
                '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
                '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
                '(\\#[-a-z\\d_]*)?$','i'); // fragment locator

            return !!pattern.test(str);
        },

        /**
         * Truncates the receive text based on the given length and with an optional postfix
         *
         * @param  {string}  str      The string to truncate
         * @param  {int}     length   The maximum length
         * @param  {string}  postfix  An optional postfix to append to the end of the truncated text
         *
         * @return {string}
         */
        truncate: function (str, length, postfix) {
            var toAppend = postfix || '';

            return (str.length > length) ? str.substr(0, length) + toAppend : str;
        },

        /**
         * Basic slugify method to use with the project
         *
         * @param  {String}  input  The input to slugify
         *
         * @return {String}
         */
        slugify: function (input) {
            if (!input) { return; }

            // make lower case and trim
            var slug = input.toLowerCase().trim();

            // replace invalid chars with spaces
            slug = slug.replace(/[^a-z0-9\s-]/g, ' ');

            // replace multiple spaces or hyphens with a single hyphen
            slug = slug.replace(/[\s-]+/g, '-');

            return slug;
        },

        shuffle: function (array) {
            var currentIndex = array.length, temporaryValue, randomIndex;

            // While there remain elements to shuffle...
            while (0 !== currentIndex) {

                // Pick a remaining element...
                randomIndex = Math.floor(Math.random() * currentIndex);
                currentIndex -= 1;

                // And swap it with the current element.
                temporaryValue = array[currentIndex];
                array[currentIndex] = array[randomIndex];
                array[randomIndex] = temporaryValue;
            }

            return array;
        },

        /**
         * Creates a new pop window and centers it on the device's screen
         */
        centeredPopup: ({url, title, w, h}) => {

            const width = $window.innerWidth ? $window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
            const systemZoom = width / $window.screen.availWidth;
            const left = $window.top.outerWidth / 2 + $window.top.screenX - ( w / systemZoom / 2);
            const top = $window.top.outerHeight / 2 + $window.top.screenY - ( h / systemZoom / 2);
            const newWindow = $window.open(url, title,
              `
              scrollbars=yes,
              width=${w / systemZoom},
              height=${h / systemZoom},
              top=${top},
              left=${left}
              `
            );

            if ($window.focus) newWindow.focus();

            return newWindow;
        },
    };

    return service;
});
