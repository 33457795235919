import {
    pull as _pull,
} from 'lodash';
import * as angular from 'angular';
import * as EXIF from 'exif-js';
import * as isMobile from 'ismobilejs';

angular.module('strukshurApp.projects.inspiration', ['strukshurApp.projects.rooms'])

.config(function config($stateProvider, $uibModalProvider) {
    $uibModalProvider.options = { animation: true, backdrop: 'static', keyboard: false, ariaLabelledBy: 'modal-title', ariaDescribedBy: 'modal-body' };

    $stateProvider
        .state('projects-detail.inspiration', {
            url: '/inspiration',
            views: {
                '': {
                    controller: 'ProjectDetailInspirationCtrl',
                    template: require('./project.inspiration.tpl.html')
                },
            },
            data: { pageTitle: 'Project / Inspiration Board', class: 'projects projects-detail-inspiration' }
        })
    ;
})

.controller('ProjectDetailInspirationCtrl', function ProjectDetailInspirationCtrl($scope, $state, $uibModal, FileService, strukshurApiService, strukshurPinterestService, strukshurProjectService, strukshurUserService) {
    var vm = $scope;

    // Check if the user can access the current page or not
    if (!vm.$parent.checkPermission('project_pin_view')) {
        return $state.go('projects-detail.access-denied', { section: 'Inspiration' });
    }


    vm.rooms = [];
    vm.boards = [];
    vm.loading = true;
    vm.searchTerm = '';
    vm.currentUser = {};
    vm.canDelete = false;
    vm.pinterestBookmark = null;

    /**
     * Controller init logic
     */
    vm.init = () => {

        // Updates current route info on the project menu scope
        vm.$parent.currentRoute = $state.current.name;

        strukshurUserService.getUser().then(res => {
            vm.currentUser = (res.user) ? res.user : res;
            vm.canDelete = (vm.$parent.project.owner_id === vm.currentUser.id);
            vm.getProjectPins();
        });
    };

    /**
     * Checks if the current user has the given permission on the project
     *
     * @param  {String}  permission  The permission key
     *
     * @return {Boolean}
     */
    vm.checkPermission = (permission) => vm.$parent.checkPermission(permission);

    /**
     * Returns the list of project rooms
     */
    vm.getProjectPins = () => {
        vm.loading = true;
        strukshurApiService.projectRooms.list({ 'project_id': vm.$parent.project.id }).$promise
            .then(res => {
                res.rooms.forEach(room => {
                    room.dropdownOpened = false;
                    room.canDelete = vm.canDelete;
                    if (!room.pins) { room.pins = []; }

                    room.pins.forEach((pin) => {
                        pin.type = 'projectPin';
                        pin.parent = room.room;
                        pin.project = vm.$parent.project;
                        if (pin.isProcessing) {
                            strukshurProjectService.pinVideoStatus(pin);
                        }
                    });
                });

                vm.loading = false;
                vm.rooms = res.rooms;
                vm.updatePageItems();
            })
        ;
    };

    vm.loadMore = () => [];

    /**
     * Opens the modal for adding a new project room
     */
    vm.newRoom = () => {
        const modalInstance = $uibModal.open({
            scope: vm,
            keyboard: true,
            controller: 'ProjectDetailNewRoomCtrl',
            template: require('../../../common/templates/projects.newRoomModal.tpl.html'),
            resolve: {
                project: vm.$parent.project
            }
        });

        modalInstance.result.then(

            // Resolved callback
            (data) => {
                if (data.room) {
                    vm.rooms.push(data.room);
                    vm.updatePageItems();
                }
            },

            // Rejected callback
            angular.noop
        );
    };

    /**
     * Opens the modal for editing a room
     */
    vm.editRoom = (room) => {
        const modalInstance = $uibModal.open({
            scope: vm,
            keyboard: true,
            controller: 'ProjectDetailEditRoomCtrl',
            template: require('../../../common/templates/projects.editRoomModal.tpl.html'),
            resolve: {
                room: () => room,
            }
        });

        modalInstance.result.then(

            // Resolved callback
            (data) => {
                if (data.room) {
                    room.name = data.room.name;
                    vm.updatePageItems();
                }
            },

            // Rejected callback
            angular.noop
        );
    };

    /**
     * Deletes the received room on the server
     *
     * @param  {object}  room  The user room
     */
    vm.deleteRoom = (room) => {
        const $childScope = vm.$new();
        $childScope.room = room;
        $childScope.title = 'Delete room';
        $childScope.message = 'Are you sure you want to delete the room and all of its related information?';

        const modalInstance = $uibModal.open({
            keyboard: true,
            scope: $childScope,
            controller: 'ProjectDetailDeleteRoomCtrl',
            template: require('../../../common/templates/base.confirm-modal.tpl.html')
        });

        modalInstance.result.then(

            // Resolved callback
            (data) => {
                _pull(vm.rooms, room);
                vm.updatePageItems();
            },

            // Rejected callback
            angular.noop
        );
    };

    /**
     * Displays add pin dropdown options
     *
     * @param  {object}  room  The room
     */
    vm.showAddPinDropdown = (room) => {
        room.dropdownOpened = true;
    };

    /**
     * Adds a new pin to the received room
     */
    vm.addPin = (room) => {
        const modalInstance = $uibModal.open({
            scope: $scope,
            keyboard: true,
            controller: 'ProjectDetailBoardAddPinCtrl',
            template: require('../../../common/templates/pin-create-for-project-modal.tpl.html'),
            resolve: {
                project: () => vm.$parent.project,
                room: () => room,
            }
        });

        modalInstance.result.then(

            // Resolved callback
            (data) => {
                if (data.pin) {
                    data.pin.type = 'projectPin';

                    // We need to refresh the project pins
                    vm.getProjectPins();
                }
            },

            // Rejected callback
            angular.noop
        );
    };

    /**
     * Opens the modal to select a file to add to the room
     *
     * @param  {Object}  room  The project room
     */
     vm.openFilePickerModal = (room) => {
        const modalInstance = $uibModal.open({
            keyboard: true,
            scope: vm.$new(),
            controller: 'FilePickerModal',
            template: require('../../../common/templates/projects.filePickerModal.tpl.html'),
            resolve: {
                options: { project: vm.$parent.project, allowedFileExtensions: FileService.supportedImagedPreviewExtensions },
            },
        });

        modalInstance.result.then(

            // Resolved callback
            res => {
                const file = res.selected[0];

                // Open upload progress modal
                const progressModal = $uibModal.open({
                    scope: $scope,
                    controller: 'ProjectProgressModalCtrl',
                    template: require('../../../common/templates/progress-modal.tpl.html'),
                    resolve: {}
                });
                progressModal.result.then(angular.noop, angular.noop);

                const data = {
                    project_id: vm.$parent.project.id,
                    file_id: file.id,
                    room_id: room.id,
                };

                strukshurApiService.projectPin.createFromFile(data).$promise
                    .then((res) => {
                        if (res.pin) {
                            res.pin.type = 'projectPin';

                            // We need to refresh the project pins
                            vm.getProjectPins();
                        }
                    })
                    .catch((res) => {
                        room.errorMessage = (res.data.message?res.data.message:'');

                        if (res.status === 403) {
                            room.errorMessage = 'You don\'t have the necessary permission to add the file.';
                        } else if (room.errorMessage.indexOf('enough storage') !==-1 && room.errorMessage.indexOf('Upgrade') !== -1) {
                            room.upgradeLink = true;
                        } else {
                            room.errorMessage = 'There was an error trying to add the file.';
                        }
                    })
                    .finally(() => progressModal.close())
                ;
            },

            // Rejected callback
            angular.noop
        );
    }

    /**
     * Opens dialog to import new pins for the inspiration board
     */
    vm.importPins = () => {

        // Check if we first need to authenticate the user with Pinterest before proceeding
        if (!strukshurUserService.getUserObj().pinterestAccessToken) {

            // Opens authentication popup window
            strukshurPinterestService.authenticate();

            // Display authenticating modal to wait until the user has authenticated with Pinterest
            const modalInstance = $uibModal.open({
                scope: $scope,
                keyboard: true,
                windowClass: 'win-class',
                backdropClass: 'bg-class',
                controller: 'AuthenticatingPinterestModalCtrl',
                template: require('../../../common/templates/pinterest-processing-auth.tpl.html'),
            });

            modalInstance.result.then(

                // Resolved callback
                vm.openImportPinsModal,

                // Rejected callback
                angular.noop
            );

            return;
        }

        vm.openImportPinsModal();
    };

    vm.openImportPinsModal = () => {
        strukshurPinterestService.boards.list({ sort: 'alphabetical' }).$promise
            .then(res => {
                vm.boards = res.data;
                vm.pinterestBookmark = res.bookmark;

                const modalInstance = $uibModal.open({
                    scope: vm,
                    keyboard: true,
                    windowClass: 'win-class',
                    backdropClass: 'bg-class',
                    controller: 'ImportPinsCtrl',
                    template: require('../../../common/templates/board-pin-project-import.tpl.html'),
                    resolve: {
                        rooms: () => vm.rooms,
                        project: vm.$parent.project,
                    }
                });

                modalInstance.result.then(

                    // Resolved callback
                    (res: any) => vm.getProjectPins(),

                    // Rejected callback
                    angular.noop
                );
            })
            .catch(err => {})
        ;
    };

    /**
     * Updates the page items to show on the project sidebar
     */
    vm.updatePageItems = function() {
        var items = [];
        vm.rooms.forEach(function (room) {
            items.push({ title: room.name, href: room.slug });
        });
        vm.$parent.setPageItems($state.current.name, items);
    };

    vm.init();
})

.controller('ProjectDetailBoardAddPinCtrl', function ProjectDetailBoardAddPinCtrl($scope, $uibModal, $uibModalInstance, project, room, strukshurApiService, strukshurUserService) {
    var vm = $scope;

    vm.loading = false;
    vm.pinFail = false;
    vm.errorMessage = null;
    vm.upgadeLink = false;
    vm.form = { pinForm:{} };
    vm.pinImage = {};
    vm.pinImageSize = 0;
    vm.isVideo = false;
    vm.isMobile = isMobile;
    vm.URL = window.URL || window.webkitURL;
    vm.canPlayVideo = false;
    vm.loadingImage = false;

    vm.pin = {
        title: '',
        image: '',
        description: '',
        project_id: project.id,
        room_id: room.id
    };

    vm.currentUser = {};
    strukshurUserService.getUser().then(function (d) {
        vm.currentUser = (d.user ? d.user : d);
    });

    /**
     * Dismisses the modal
     */
    vm.cancel = function () {
        $uibModalInstance.dismiss();
    };

    vm.loadEnd = function () {
        vm.loadingImage = false;
    };

    vm.afterValidate = function (eventObject, fileReader, file) {
        if (!file || file.length === 0) { return; }

        var videoPlayer = document.getElementById('videoPlayer') as HTMLVideoElement;

        vm.loadingImage = false;
        vm.pinImageSize = file[0].size;
        vm.isVideo = (file[0].type.substr(0,5) !== 'image');

        if (vm.pinImage && !vm.pinImage.base64) {
            vm.pinFail = 'Invalid ' + (vm.isVideo ? 'Video' : 'Image');
            vm.pinImage = null;
            return;
        }

        if (file[0].size > 150*1000*1000) {
            vm.pinFail = (vm.isVideo ? 'Video' : 'Image') + ' must be less than 150MB';
            vm.pinImage = null;
            return;
        }

        if (vm.isVideo) {
            vm.canPlayVideo = videoPlayer.canPlayType(file[0].type);
            if (vm.canPlayVideo && file[0].size <= 25*1024*1024) {
                videoPlayer.src = URL.createObjectURL(file[0]);
            } else {
                videoPlayer.src = '';
            }
        } else if (file[0].type.substr(0,5) !== 'video' && file[0].type.substr(0,5) !== 'image') {
            vm.pinImage = null;
        } else {

            // Resize
            var dataUrlImage;
            var canvas, MAX_WIDTH, MAX_HEIGHT, width, height, ctx, img;
            MAX_WIDTH = 2048;
            MAX_HEIGHT = 2048;


            if (vm.pinImage && vm.pinImage.base64) {

                //compress image
                img = document.createElement('img');
                img.setAttribute('crossOrigin', 'anonymous');

                img.onload = function (ev) {
                    var resize;
                    width = img.width;
                    height = img.height;

                    if (width > height) {
                        if (width > MAX_WIDTH) {
                            height *= MAX_WIDTH / width;
                            width = MAX_WIDTH;
                        }
                    } else {
                        if (height > MAX_HEIGHT) {
                            width *= MAX_HEIGHT / height;
                            height = MAX_HEIGHT;
                        }
                    }

                    // Resize only if needed
                    if (width !== img.width || height !== img.height) {
                        canvas = document.createElement('canvas');
                        canvas.width = width;
                        canvas.height = height;
                        ctx = canvas.getContext('2d');
                        ctx.drawImage(img, 0, 0, width, height);

                        canvas.setAttribute('crossOrigin', 'anonymous');

                        dataUrlImage = canvas.toDataURL(vm.pinImage.filetype);
                        vm.pinImage.base64 = dataUrlImage.substr(dataUrlImage.indexOf(',')+1);
                        dataUrlImage = null;
                        canvas = null;
                        ctx = null;
                    }

                    //if iOS rotate if needed
                    if(isMobile.apple.device || isMobile.apple.ipod || isMobile.apple.phone || isMobile.apple.tablet){
                        resize = document.createElement('img');
                        resize.setAttribute('crossOrigin', 'anonymous');

                        resize.onload = function (event) {
                            width = resize.width;
                            height = resize.height;
                            EXIF.getData(img, function(){
                                var orientation = EXIF.getTag(this, "Orientation");
                                canvas = document.createElement('canvas');
                                ctx = canvas.getContext('2d');
                                if(orientation && orientation>4){
                                    canvas.width = height;
                                    canvas.height = width;
                                }
                                else{
                                    canvas.width = width;
                                    canvas.height = height;
                                }
                                switch(orientation){
                                    case 2:
                                        // horizontal flip
                                        ctx.translate(width, 0);ctx.scale(-1, 1);
                                        break;
                                    case 3:
                                        // 180° rotate left
                                        ctx.translate(width, height);ctx.rotate(Math.PI);
                                        break;
                                    case 4:
                                        // vertical flip
                                        ctx.translate(0, height);ctx.scale(1, -1);
                                        break;
                                    case 5:
                                        // vertical flip + 90 rotate right
                                        ctx.rotate(0.5 * Math.PI);ctx.scale(1, -1);
                                        break;
                                    case 6:
                                        // 90° rotate right
                                        ctx.rotate(0.5 * Math.PI);ctx.translate(0, -height);
                                        break;
                                    case 7:
                                        // horizontal flip + 90 rotate right
                                        ctx.rotate(0.5 * Math.PI);ctx.translate(width, -height);ctx.scale(-1, 1);
                                        break;
                                    case 8:
                                        // 90° rotate left
                                        ctx.rotate(-0.5 * Math.PI);ctx.translate(-width, 0);
                                        break;
                                    default:
                                        dataUrlImage = null;
                                        resize = null;
                                        img = null;
                                        canvas = null;
                                        ctx = null;
                                        return;
                                }
                                if(orientation && orientation>4){
                                    ctx.drawImage(resize, 0, 0);
                                }
                                else{
                                    ctx.drawImage(resize, 0, 0, width, height);
                                }


                                canvas.setAttribute('crossOrigin', 'anonymous');
                                dataUrlImage = canvas.toDataURL('image/jpeg');
                                vm.pinImage.filetype = 'image/jpeg';
                                vm.pinImage.base64 = dataUrlImage.substr(dataUrlImage.indexOf(',')+1);
                                dataUrlImage = null;
                                resize = null;
                                img = null;
                                canvas = null;
                                ctx = null;
                                vm.$apply();
                            });
                        };
                        resize.src = 'data:' + vm.pinImage.filetype + ';base64,' + vm.pinImage.base64;
                    } else {
                        img = null;
                    }
                };

                img.src = 'data:' + vm.pinImage.filetype + ';base64,' + vm.pinImage.base64;
            }
        }
    };

    vm.loadStart = function () {
        vm.pinFail = false;
        vm.loadingImage = true;
    };

    /**
     * Add a new pin to the active project and room
     */
    vm.addPin = function () {
        var modalInstance;

        vm.pinFail = false;
        vm.upgadeLink = false;
        vm.errorMessage = null;
        vm.form.pinForm.title.$invalid = false;

        // validate pin
        if (vm.pin.title === '') {
            vm.productRoomsError = true;
            vm.form.pinForm.title.$invalid = true;
            return;
        }

        if (!vm.pinImage || !vm.pinImage.base64) {
            vm.pinFail = 'You must include an image';
            return;
        }

        vm.isVideo = (vm.pinImage.filetype.substr(0, 5) === 'video');
        vm.pin.image = 'data:'+vm.pinImage.filetype+';base64,'+vm.pinImage.base64;

        modalInstance = $uibModal.open({
            scope: $scope,
            animation: false,
            controller: 'ProjectProgressModalCtrl',
            template: require('../../../common/templates/progress-modal.tpl.html'),
            resolve: {}
        });
        modalInstance.result.then(angular.noop, angular.noop);

        vm.loading = true;
        strukshurApiService.projectPin
            .create(vm.pin).$promise
            .then(function (res) {
                $uibModalInstance.close({ pin: res.pin });
            })
            .catch(function (res) {
                vm.errorMessage = (res.data.message ? res.data.message : '');
                if (res.status === 403) {
                    vm.errorMessage = 'You don\'t have the necessary permission to save the pin.';
                } else if (vm.errorMessage.indexOf('enough storage') !== -1 && vm.errorMessage.indexOf('Upgrade') !== -1) {
                    vm.upgadeLink = true;
                } else {
                    vm.errorMessage = 'There was an error trying to save the pin to the room.';
                }
            })
            .finally(function () {
                modalInstance.close();
                vm.loading = false;
            })
        ;
    };

    vm.upgradeSettingsAccount = function () {
        vm.$parent.upgradeSettingsAccount();
    };
})

.controller('ImportPinsCtrl', function ImportPinsCtrl ($scope, $uibModalInstance, project, rooms, strukshurApiService, strukshurPinterestService) {
    var vm = $scope;

    vm.pins = {};
    vm.rooms = rooms;
    vm.selection = [];
    vm.loading = false;
    vm.importing = false;
    vm.boardViewing = null;
    vm.selectedRoom = null;
    vm.importingError = false;
    vm.roomNotSelected = false;

    /**
     * Cancels the import pins modal
     */
    vm.cancel = function () {
        $uibModalInstance.dismiss();
    };

    vm.loadMore = () => {
        if (vm.loading) { return; }

        vm.loading = true;
        strukshurPinterestService.boards.list({ sort: 'alphabetical', bookmark: vm.pinterestBookmark }).$promise
            .then(res => {
                vm.boards.push(...res.data);
                vm.pinterestBookmark = res.bookmark;
                vm.loading = false;
            })
        ;
    };

    vm.getPinsFromBoard = (board_id) => {
        vm.selection = [];
        vm.boardViewing = board_id;

        strukshurPinterestService.pins.list({ 'board_id': board_id }).$promise
            .then((res) => vm.pins = res.data)
        ;
    };

    vm.selectAll = function() {
        angular.forEach(vm.pins, function(pin, key) {
            vm.selection.push(pin.id);
        });
    };

    vm.formImport = function() {
        vm.importing = true;
        vm.importingError = false;

        if (!vm.selectedRoom || vm.selectedRoom.id == null) {
            vm.roomNotSelected = true;
        }

        var savePins = [];
        angular.forEach(vm.pins, function (pin, key) {
            var idx = vm.selection.indexOf(pin.id);

            if (idx > -1) {
                var original_link = pin.link || '';
                var title = pin.title || '';
                var site_name = pin.domain || '';
                var image_original_url = pin.image_large_url;

                savePins.push({
                    id: pin.id,
                    url: image_original_url,
                    original_link: original_link,
                    site_name: site_name,
                    title: title,
                    note: pin.description
                });
            }
        });

        strukshurApiService.projectPins.importFromPinterest({ 'project_id': project.id, 'room_id': vm.selectedRoom.id, 'pins': savePins }, function (d) {
            vm.importing = false;
            $uibModalInstance.close();
        }, function (e) {
            vm.importing = false;
            vm.importingError = true;
        });
    };

    vm.toggleSelection = function toggleSelection(pinId) {
        var idx = vm.selection.indexOf(pinId);

        // Is currently selected
        if (idx > -1) {
            vm.selection.splice(idx, 1);
        } else {
            // Is newly selected
            vm.selection.push(pinId);
        }
    };

    /**
     * Called when a room is selected
     *
     * @param  {Object}  room     The selected room
     * @param  {Object}  $select  The ui-select object
     */
    vm.roomSelected = function(room, $select) {
        vm.selectedRoom = room;
        vm.roomNotSelected = false;
    };
})

.controller('AuthenticatingPinterestModalCtrl', function AuthenticatingPinterestModalCtrl ($interval, $scope, $uibModalInstance, strukshurPinterestService, strukshurUserService) {
    var vm = $scope;

    vm.intervalPromise = null;

    /**
     * Controller initialization logic
     */
    vm.init = () => {
        vm.intervalPromise = $interval(vm.loadPinterestAccessToken, 3000);
    };

    /**
     * Cancels the modal
     */
    vm.cancel = () => {
        $uibModalInstance.dismiss();
    };

    /**
     * Loads access token info from the server
     */
    vm.loadPinterestAccessToken = () => {
        strukshurPinterestService.getAccessToken(true)
            .then((res) => {

                // Check if we have received a token
                if (res.data.access_token) {
                    $interval.cancel(vm.intervalPromise);
                    strukshurUserService.setPinterestAccessToken(res.data.access_token);
                    $uibModalInstance.close();
                }
            })
        ;
    };

    vm.init();
})

;
