import * as angular from 'angular';
import * as isMobile from 'ismobilejs';
import * as EXIF from 'exif-js';
import { isInteger as _isInteger, remove as _remove } from 'lodash';

angular.module('strukshurApp.adminProducts', ['ui.router', 'ui.bootstrap', 'strukshurApp.admin'])

    .config(function config($stateProvider) {
        $stateProvider
            .state( 'admin.products', {
                url: '/products?page',
                params: {
                    page: null
                },
                views: {
                    "admin_middle_view": {
                        controller: 'AdminProductsCtrl',
                        template: require('./products/admin_products.tpl.html')
                    }
                },
                data: { pageTitle: 'Admin Products: List' }
            })

            .state( 'admin.product-add', {
                url: '/product/add',
                params: {
                    page: null
                },
                views: {
                    "admin_middle_view": {
                        controller: 'AdminProductAddCtrl',
                        template: require('./products/admin_product_add.tpl.html')
                    }
                },
                data: { pageTitle: 'Admin Product: Add' }
            })

            .state( 'admin.product', {
                url: '/product/:id',
                views: {
                    "admin_middle_view": {
                        controller: 'AdminProductDetailCtrl',
                        template: require('./products/admin_product_detail.tpl.html')
                    }
                },
                data: { pageTitle: 'Admin Product: Detail' }
            })

            .state( 'admin.product-edit', {
                url: '/product/:id/edit',
                views: {
                    "admin_middle_view": {
                        controller: 'AdminProductEditCtrl',
                        template: require('./products/admin_product_edit.tpl.html')
                    }
                },
                resolve: {
                    productResolve: function($q, $state, $stateParams, strukshurApiService){
                        var deferred = $q.defer();
                        console.log('start productResolve');
                        strukshurApiService.getAdminProduct.query({ id: $stateParams.id }, function(d) {

                            if (d === undefined) {
                                deferred.reject('Product not found.');
                                $state.go('admin.products');
                            } else {
                                deferred.resolve(d.product);
                            }
                        }, function (e) {
                            deferred.reject('Product not found.');
                            $state.go('admin.products');
                        });

                        return deferred.promise;
                    }
                },
                data: { pageTitle: 'Admin Product: Edit' }
            })
        ;
    })

    .factory('AdminPage', function () {
        var page = 0;

        return {
            setPage: function (p) {
                page = p;
                return page;
            },
            getPage: function () {
                return page;
            }
        };
    })

    .factory('RoomAttrsService', function ($q, strukshurApiService) {
        return {
            getAttrIds: function (myform) {
                var roomAttrs: string[] = [];

                angular.forEach(myform, function (value, index: any) {
                    if (index.indexOf('attr-') !== -1) {
                        if (value.$viewValue === true || value.$viewValue === 'true') {
                            roomAttrs.push(index);
                        }
                    }
                });

                return roomAttrs;
            }
        };
    })

    .controller('AdminProductsCtrl', function AdminProductsCtrl ($scope, $state, $stateParams, $uibModal, $window, smoothScroll, strukshurApiService, strukshurFlashService, strukshurUserService) {
        var vm = $scope;

        vm.page = null;
        vm.total = 0;
        vm.search = '';
        vm.pageSize = 20;
        vm.products = [];
        vm.isAdmin = false;
        vm.loading = false;
        vm.currentUser = {};
        vm.showPagination = false;
        vm.showVendorData = true;
        vm.successMessage = false;

        let initialPage = parseInt($stateParams.page || 1, 10);
        if (!_isInteger(initialPage)) {
            initialPage = 1;
        }
        vm.page = initialPage;

        vm.init = () => {
            vm.setupPagination();
            vm.fetchProducts(vm.page);

            strukshurUserService.getUser().then((res: any) => {
                vm.currentUser = res;
                vm.isAdmin = strukshurUserService.isAdmin();
            });
        };

        /**
         * Setup pagination logic depending on the device width
         */
        vm.setupPagination = () => {
            vm.showPrevNext = false;
            vm.showFirstLast = false;
            vm.adjacentPagination = 1;

            if ($window.innerWidth > 449) {
                vm.showPrevNext = true;
            }
            if ($window.innerWidth > 499) {
                vm.showFirstLast = true;
            }
            if ($window.innerWidth > 599) {
                vm.adjacentPagination = 2;
            }
        };

        /**
         * Updates the page parameter for the current page
         */
        vm.updatePageParams = () => {
            $state.go($state.current, { page: vm.page }, { location: true, inherit: true, notify: false });
        };

        /**
         * Fetches a list of products for the given page
         */
        vm.fetchProducts = (page: number) => {
            if (vm.loading) { return; }

            let oldPage = vm.page;

            vm.page = page;
            vm.loading = true;
            strukshurApiService.getAdminProducts.query({ page: vm.page, terms: vm.search }).$promise
                .then((res: any) => {
                    vm.total = res.total;
                    vm.products = res.products;
                    vm.showPagination = true;
                    vm.updatePageParams();

                    smoothScroll(document.querySelector('.table'), { offset: 80 });
                })
                .catch((err: any) => {
                    vm.page = oldPage;
                    strukshurFlashService.handleApiResponseErrorMessage({
                        response: err,
                        container: 'admin-products-flash',
                        defaultMessage: 'There was an error while trying to fetch the products.',
                        accessDeniedMessage: 'You don\'t have the necessary permission to list products.',
                    });
                })
                .finally(() => vm.loading = false)
            ;
        };

        vm.deleteProductPop = (product: any) => {
            vm.confirmMessage = false;
            let modalInstance = $uibModal.open({
                scope: vm,
                keyboard: true,
                animation: true,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                controller: 'ConfirmProductDeleteCtrl',
                template: require('../../common/templates/confirm-modal.tpl.html'),
                resolve: {
                    product: () => { return product },
                    message: () => { return 'Are you sure?' },
                }
            });

            modalInstance.result.then((selectedItem: any) => {
                _remove(vm.products, { id: selectedItem.id });

                strukshurFlashService.displayMessage({
                    type: 'success',
                    duration: 5000,
                    container: 'admin-products-flash',
                    message: 'Product successfully removed.',
                });
            }, angular.noop);
        };

        vm.init();
    })

    .controller('ConfirmProductDeleteCtrl', function ConfirmProductDeleteCtrl ($scope, $uibModalInstance, strukshurApiService, message, product) {
        $scope.loading = false;
        $scope.title = 'Confirm';
        $scope.message = message;
        $scope.product = product;
        $scope.errorMessage = false;

        $scope.confirm = function () {
            var data;

            if ($scope.loading) {
                return;
            }

            $scope.loading = true;
            $scope.errorMessage = false;
            data = {
                product_id: $scope.product.id
            };

            strukshurApiService.adminProductDelete.delete(data, function (d) {
                $uibModalInstance.close(product);
            }, function (e) {
                $scope.loading = false;
            });
        };

        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    })

    .controller('AdminProductDetailCtrl', function AdminProductDetailCtrl($scope, $state, $stateParams, strukshurApiService) {
        $scope.showVendorData = true;
        strukshurApiService.getAdminProduct.query({ id: $stateParams.id }, function (d) {
            $scope.product = d.product;
        });

        $scope.productEdit = function () {
            $state.go('admin.product-edit', { id: $stateParams.id });
        };
    })

    .controller('AdminProductAddCtrl', function AdminProductAddCtrl($scope, $q, $state, strukshurApiService, strukshurAdminService, RoomAttrsService, strukshurUserService) {
        $scope.form = {};
        $scope.loading = false;
        $scope.formError = false;
        $scope.status = {
            name: 'active'
        };
        $scope.enable_shipping_2day = 0;
        $scope.enable_shipping_overnight = 0;
        $scope.other = {
            shipping_2day: '',
            shipping_overnight: ''
        };
        $scope.currentUser = {};
        $scope.isAdmin = false;
        $scope.roomTypes = [];
        $scope.productCats = [];
        $scope.vendors = [];
        strukshurUserService.getUser().then(function (d) {
            $scope.currentUser = d;
            $scope.isAdmin = strukshurUserService.isAdmin();
        });
        $scope.cancel = function () {
            $state.go('admin.products');
        };

        $scope.loadEnd = function () {
            $scope.imageSrcLoading = false;
        };

        $scope.afterValidate = function (eventObject, fileReader, file) {
            $scope.imageSrcLoading = false;
            if (!file || file.length === 0) {
                return;
            }

            if ($scope.product_image && !$scope.product_image.base64) {
                $scope.pinFail = 'Invalid Image';
                $scope.product_image = null;
                $scope.imageSrc = null;

                return;
            }

            if (file[0].size > 150*1000*1000) {
                $scope.pinFail = 'Image must be less than 150MB';
                $scope.product_image = null;
                $scope.imageSrc = null;

                return;
            }

            if (file[0].type.substr(0,5)!=='image') {
                $scope.product_image = null;
                $scope.imageSrc = null;
            } else{
                //resize
                var dataUrlImage;
                var canvas, MAX_WIDTH, MAX_HEIGHT, width, height, ctx, img;
                MAX_WIDTH = 2048;
                MAX_HEIGHT = 2048;


                if ($scope.product_image && $scope.product_image.base64) {

                    //compress image
                    img = document.createElement('img');
                    img.setAttribute('crossOrigin', 'anonymous');

                    img.onload = function(ev){
                        var resize;
                        width = img.width;
                        height = img.height;

                        if (width > height) {
                            if (width > MAX_WIDTH) {
                                height *= MAX_WIDTH / width;
                                width = MAX_WIDTH;
                            }
                        } else {
                            if (height > MAX_HEIGHT) {
                                width *= MAX_HEIGHT / height;
                                height = MAX_HEIGHT;
                            }
                        }

                        // Resize only if needed
                        if (width !== img.width || height !== img.height) {
                            canvas = document.createElement('canvas');
                            canvas.width = width;
                            canvas.height = height;
                            ctx = canvas.getContext('2d');
                            ctx.drawImage(img, 0, 0, width, height);

                            canvas.setAttribute('crossOrigin', 'anonymous');

                            dataUrlImage = canvas.toDataURL($scope.product_image.filetype);
                            $scope.product_image.base64 = dataUrlImage.substr(dataUrlImage.indexOf(',')+1);
                            $scope.imageSrc = 'data:' + $scope.product_image.filetype + ';base64,' + $scope.product_image.base64;
                            dataUrlImage = null;
                            canvas = null;
                            ctx = null;
                            $scope.$apply();
                        }

                        //if iOS rotate if needed
                        if(isMobile.apple.device || isMobile.apple.ipod || isMobile.apple.phone || isMobile.apple.tablet){
                            resize = document.createElement('img');
                            resize.setAttribute('crossOrigin', 'anonymous');

                            resize.onload = function(event){
                                width = resize.width;
                                height = resize.height;
                                EXIF.getData(img, function(){
                                    var orientation = EXIF.getTag(this, "Orientation");
                                    canvas = document.createElement('canvas');
                                    ctx = canvas.getContext('2d');
                                    if(orientation && orientation>4){
                                        canvas.width = height;
                                        canvas.height = width;
                                    }
                                    else{
                                        canvas.width = width;
                                        canvas.height = height;
                                    }
                                    switch(orientation){
                                        case 2:
                                            // horizontal flip
                                            ctx.translate(width, 0);ctx.scale(-1, 1);
                                            break;
                                        case 3:
                                            // 180° rotate left
                                            ctx.translate(width, height);ctx.rotate(Math.PI);
                                            break;
                                        case 4:
                                            // vertical flip
                                            ctx.translate(0, height);ctx.scale(1, -1);
                                            break;
                                        case 5:
                                            // vertical flip + 90 rotate right
                                            ctx.rotate(0.5 * Math.PI);ctx.scale(1, -1);
                                            break;
                                        case 6:
                                            // 90° rotate right
                                            ctx.rotate(0.5 * Math.PI);ctx.translate(0, -height);
                                            break;
                                        case 7:
                                            // horizontal flip + 90 rotate right
                                            ctx.rotate(0.5 * Math.PI);ctx.translate(width, -height);ctx.scale(-1, 1);
                                            break;
                                        case 8:
                                            // 90° rotate left
                                            ctx.rotate(-0.5 * Math.PI);ctx.translate(-width, 0);
                                            break;
                                        default:
                                            $scope.imageSrc = 'data:' + $scope.product_image.filetype + ';base64,' + $scope.product_image.base64;
                                            dataUrlImage = null;
                                            resize = null;
                                            img = null;
                                            canvas = null;
                                            ctx = null;
                                            $scope.$apply();
                                            return;
                                    }
                                    if(orientation && orientation>4){
                                        ctx.drawImage(resize, 0, 0);
                                    }
                                    else{
                                        ctx.drawImage(resize, 0, 0, width, height);
                                    }


                                    canvas.setAttribute('crossOrigin', 'anonymous');
                                    dataUrlImage = canvas.toDataURL('image/jpeg');
                                    $scope.product_image.filetype = 'image/jpeg';
                                    $scope.product_image.base64 = dataUrlImage.substr(dataUrlImage.indexOf(',')+1);
                                    $scope.imageSrc = 'data:' + $scope.product_image.filetype + ';base64,' + $scope.product_image.base64;
                                    dataUrlImage = null;
                                    resize = null;
                                    img = null;
                                    canvas = null;
                                    ctx = null;
                                    $scope.$apply();
                                });
                            };
                            resize.src = 'data:' + $scope.product_image.filetype + ';base64,' + $scope.product_image.base64;
                        }
                        else{
                            $scope.imageSrc = 'data:' + $scope.product_image.filetype + ';base64,' + $scope.product_image.base64;
                            img = null;
                            $scope.$apply();
                        }
                    };
                    img.src = 'data:' + $scope.product_image.filetype + ';base64,' + $scope.product_image.base64;
                }
            }
        };

        $scope.loadStart = function () {
            $scope.pinFail = false;
            $scope.imageSrcLoading = true;
        };

        $scope.loadVendors = function($query) {
            var deferred = $q.defer();
            strukshurApiService.searchVendors.query({terms: $query}, function(d){
                deferred.resolve(d.vendors);
                return d.vendors;
            });
            return deferred.promise;
        };

        $scope.loadRoomTypes = function($query) {
            var deferred = $q.defer();
            strukshurApiService.searchRoomTypes.query({terms: $query}, function(d){
                deferred.resolve(d.room_types);
                return d.room_types;
            });
            return deferred.promise;
        };

        $scope.roomsTax = [];

        $scope.roomAdded = function ($tag) {

            // clear and start over
            $scope.roomsTax = [];

            angular.forEach($scope.roomTypes, function (value, key) {
                console.log('sending');
                console.log(value);
                strukshurApiService.searchRoomProps.query({ terms: '', rooms: [value] }, function (d) {
                    $scope.roomProps = d.room_props;
                    $scope.roomsTax.push({'roomName': value.name, 'roomResults': d.room_props});
                });
            });
        };

        $scope.subAttrsSave = [];
        $scope.subAttrAdd = function($tag) {
            console.log('subAttrsSave');
            console.log($scope.subAttrsSave);
        };

        $scope.loadSubAttrs = function($query, subProperty) {
            var deferred = $q.defer();
            strukshurApiService.searchSubAttrs.query({ terms: $query, sub_prop_id: subProperty.id }, function (d) {
                //$scope.tags = d.room_types;
                deferred.resolve(d.attrs);

                return d.attrs;
            });

            return deferred.promise;
        };

        $scope.loadProductCategories = function ($query) {
            var deferred = $q.defer();

            strukshurApiService.getAdminProductCategories.query({}, function (d) {
                //deferred.reject();
                deferred.resolve(d.cats);

                return d.cats;
            });

            return deferred.promise;
        };

        $scope.productAdd = function (myform) {
            if (!myform.$valid) {
                return;
            }

            $scope.loading = true;

            var roomAtrs = RoomAttrsService.getAttrIds(myform);

            myform.enable_shipping_2day = $scope.enable_shipping_2day;
            if(!$scope.enable_shipping_2day){
                $scope.other.shipping_2day = '';
            }

            myform.shipping_2day = $scope.other.shipping_2day;
            myform.enable_shipping_overnight = $scope.enable_shipping_overnight;
            if(!$scope.enable_shipping_overnight){
                $scope.other.shipping_overnight = '';
            }
            myform.shipping_overnight = $scope.other.shipping_overnight;

            var promise = strukshurAdminService.productAdd(myform, $scope.roomTypes, roomAtrs, $scope.productCats, $scope.vendors);

            promise.then(function(data) {
                $scope.loading = false;
                console.log('promise Success');
                console.log(data);
                $scope.formError = false;
                $state.go('admin.products');
            }, function(e) {
                $scope.formError = true;
                console.log('promise Failed:');
                $scope.loading = false;
            });
        };
    })

    .controller( 'AdminProductEditCtrl', function AdminProductEditCtrl( $scope, $state, $q, productResolve, strukshurAdminService, strukshurApiService, RoomAttrsService, strukshurUserService ) {

        $scope.loading = false;
        $scope.formError = false;

        $scope.currentUser = {};
        $scope.isAdmin = false;
        strukshurUserService.getUser().then(function (d) {
            $scope.currentUser = d;
            $scope.isAdmin = strukshurUserService.isAdmin();
        });

        $scope.form = {};
        //console.log(productResolve);

        $scope.product = productResolve;

        $scope.form = productResolve;

        $scope.imageSrc = productResolve.img;

        $scope.prodStatus = {
            status: productResolve.active === false ? 'hidden' : 'active'
        };

        function fetchRoomAttrs() {
            $scope.roomsTax = [];
            //console.log('roomTypes');console.log($scope.roomTypes);
            angular.forEach($scope.roomTypes, function(value, key) {
                //console.log('sending');console.log(value);
                strukshurApiService.searchRoomProps.query({terms: '', rooms: [value]}, function(d){
                    //$scope.roomProps = d.room_props;
                    var roomProps = d.room_props;

                    // Pre-Check room attributes
                    angular.forEach(roomProps, function(value,key) {
                        angular.forEach(value, function(valueP,keyP) {
                            roomProps[key][keyP].selected = false;
                            angular.forEach($scope.roomAttrs, function(valueA, keyA) {
                                if (valueA.id === valueP.attr_id)
                                {
                                    roomProps[key][keyP].selected = true;
                                }
                            });
                        });
                    });



                    $scope.roomsTax.push({'roomName': value.name, 'roomResults': roomProps});
                });
            });
        }

        $scope.roomTypes = productResolve.types;
        //$scope.roomTags = productResolve.roomTags;
        $scope.roomAttrs = productResolve.roomAttrs;
        $scope.productCats = productResolve.productCats;
        $scope.vendors = productResolve.vendors;


        $scope.roomAdded = function($tag) {
            fetchRoomAttrs();
        };

        // add initial room attrs
        fetchRoomAttrs();


        $scope.cancel = function () {
            $state.go('admin.product', {id: $scope.product.id});
        };


        $scope.loadEnd = function () {
            $scope.imageSrcLoading = false;
        };

        $scope.afterValidate = function (eventObject, fileReader, file) {
            $scope.imageSrcLoading = false;
            if(!file || file.length===0){
                $scope.product_image = null;
                $scope.imageSrc = null;
                return;
            }

            if($scope.product_image && !$scope.product_image.base64){
                $scope.pinFail = 'Invalid Image';
                $scope.product_image = null;
                $scope.imageSrc = null;
                return;
            }
            if(file[0].size>150*1000*1000){
                $scope.pinFail = 'Image must be less than 150MB';
                $scope.product_image = null;
                $scope.imageSrc = null;
                return;
            }

            if(file[0].type.substr(0,5)!=='image'){
                $scope.product_image = null;
                $scope.imageSrc = null;
            }
            else{
                //resize

                var dataUrlImage;
                var canvas, MAX_WIDTH, MAX_HEIGHT, width, height, ctx, img;
                MAX_WIDTH = 2048;
                MAX_HEIGHT = 2048;


                if ($scope.product_image && $scope.product_image.base64) {

                    //compress image
                    img = document.createElement('img');
                    img.setAttribute('crossOrigin', 'anonymous');

                    img.onload = function(ev){
                        var resize;
                        width = img.width;
                        height = img.height;

                        if (width > height) {
                            if (width > MAX_WIDTH) {
                                height *= MAX_WIDTH / width;
                                width = MAX_WIDTH;
                            }
                        } else {
                            if (height > MAX_HEIGHT) {
                                width *= MAX_HEIGHT / height;
                                height = MAX_HEIGHT;
                            }
                        }

                        // Resize only if needed
                        if (width !== img.width || height !== img.height) {
                            canvas = document.createElement('canvas');
                            canvas.width = width;
                            canvas.height = height;
                            ctx = canvas.getContext('2d');
                            ctx.drawImage(img, 0, 0, width, height);

                            canvas.setAttribute('crossOrigin', 'anonymous');

                            dataUrlImage = canvas.toDataURL($scope.product_image.filetype);
                            $scope.product_image.base64 = dataUrlImage.substr(dataUrlImage.indexOf(',')+1);
                            $scope.imageSrc = 'data:' + $scope.product_image.filetype + ';base64,' + $scope.product_image.base64;
                            dataUrlImage = null;
                            canvas = null;
                            ctx = null;
                            $scope.$apply();
                        }

                        //if iOS rotate if needed
                        if(isMobile.apple.device || isMobile.apple.ipod || isMobile.apple.phone || isMobile.apple.tablet){
                            resize = document.createElement('img');
                            resize.setAttribute('crossOrigin', 'anonymous');

                            resize.onload = function(event){
                                width = resize.width;
                                height = resize.height;
                                EXIF.getData(img, function(){
                                    var orientation = EXIF.getTag(this, "Orientation");
                                    canvas = document.createElement('canvas');
                                    ctx = canvas.getContext('2d');
                                    if(orientation && orientation>4){
                                        canvas.width = height;
                                        canvas.height = width;
                                    }
                                    else{
                                        canvas.width = width;
                                        canvas.height = height;
                                    }
                                    switch(orientation){
                                        case 2:
                                            // horizontal flip
                                            ctx.translate(width, 0);ctx.scale(-1, 1);
                                            break;
                                        case 3:
                                            // 180° rotate left
                                            ctx.translate(width, height);ctx.rotate(Math.PI);
                                            break;
                                        case 4:
                                            // vertical flip
                                            ctx.translate(0, height);ctx.scale(1, -1);
                                            break;
                                        case 5:
                                            // vertical flip + 90 rotate right
                                            ctx.rotate(0.5 * Math.PI);ctx.scale(1, -1);
                                            break;
                                        case 6:
                                            // 90° rotate right
                                            ctx.rotate(0.5 * Math.PI);ctx.translate(0, -height);
                                            break;
                                        case 7:
                                            // horizontal flip + 90 rotate right
                                            ctx.rotate(0.5 * Math.PI);ctx.translate(width, -height);ctx.scale(-1, 1);
                                            break;
                                        case 8:
                                            // 90° rotate left
                                            ctx.rotate(-0.5 * Math.PI);ctx.translate(-width, 0);
                                            break;
                                        default:
                                            $scope.imageSrc = 'data:' + $scope.product_image.filetype + ';base64,' + $scope.product_image.base64;
                                            dataUrlImage = null;
                                            resize = null;
                                            img = null;
                                            canvas = null;
                                            ctx = null;
                                            $scope.$apply();
                                            return;
                                    }
                                    if(orientation && orientation>4){
                                        ctx.drawImage(resize, 0, 0);
                                    }
                                    else{
                                        ctx.drawImage(resize, 0, 0, width, height);
                                    }


                                    canvas.setAttribute('crossOrigin', 'anonymous');
                                    dataUrlImage = canvas.toDataURL('image/jpeg');
                                    $scope.product_image.filetype = 'image/jpeg';
                                    $scope.product_image.base64 = dataUrlImage.substr(dataUrlImage.indexOf(',')+1);
                                    $scope.imageSrc = 'data:' + $scope.product_image.filetype + ';base64,' + $scope.product_image.base64;
                                    dataUrlImage = null;
                                    resize = null;
                                    img = null;
                                    canvas = null;
                                    ctx = null;
                                    $scope.$apply();
                                });
                            };
                            resize.src = 'data:' + $scope.product_image.filetype + ';base64,' + $scope.product_image.base64;
                        }
                        else{
                            $scope.imageSrc = 'data:' + $scope.product_image.filetype + ';base64,' + $scope.product_image.base64;
                            img = null;
                            $scope.$apply();
                        }
                    };
                    img.src = 'data:' + $scope.product_image.filetype + ';base64,' + $scope.product_image.base64;
                }
            }
        };

        $scope.loadStart = function () {
            $scope.pinFail = false;
            $scope.imageSrcLoading = true;
        };

        $scope.productEdit = function(myform) {

            if (!myform.$valid) {
                console.log('productEdit Form Error');
                return;
            }

            $scope.loading = true;

            var roomAttrs = RoomAttrsService.getAttrIds(myform);

            myform['id'] = productResolve.id;
            myform['status'] = $scope.prodStatus.status;

            myform.enable_shipping_2day = $scope.product.enable_shipping_2day;
            if(!$scope.product.enable_shipping_2day){
                $scope.product.shipping_2day = '';
            }
            myform.shipping_2day = $scope.product.shipping_2day;
            myform.enable_shipping_overnight = $scope.product.enable_shipping_overnight;
            if(!$scope.product.enable_shipping_overnight){
                $scope.product.shipping_overnight = '';
            }
            myform.shipping_overnight = $scope.product.shipping_overnight;

            var promise = strukshurAdminService.productEdit(myform, $scope.roomTypes, roomAttrs, $scope.productCats, $scope.vendors);

            promise.then(function(data) {
                $scope.loading = false;
                console.log('promise Success');
                console.log(data);
                $scope.formError = false;
                $state.go('admin.product', {id: $scope.product.id});
            }, function(e) {
                console.log('promise Failed:');
                $scope.formError = true;
                $scope.loading = false;
            });


        };

        $scope.loadVendors = function($query) {
            var deferred = $q.defer();
            strukshurApiService.searchVendors.query({terms: $query}, function(d){
                deferred.resolve(d.vendors);
                return d.vendors;
            });
            return deferred.promise;
        };

        $scope.loadRoomTypes = function($query) {
            var deferred = $q.defer();
            strukshurApiService.searchRoomTypes.query({terms: $query}, function(d){
                //deferred.reject();
                //$scope.tags = d.room_types;
                deferred.resolve(d.room_types);
                return d.room_types;
            });
            return deferred.promise;
        };


        $scope.loadRoomTags = function($query) {
            var deferred = $q.defer();
            strukshurApiService.searchRoomTags.query({terms: $query, rooms: $scope.roomTypes}, function(d){
                //deferred.reject();
                deferred.resolve(d.room_tags);
                return d.room_tags;
            });
            return deferred.promise;
        };

        $scope.loadProductCategories = function($query) {
            var deferred = $q.defer();
            strukshurApiService.getAdminProductCategories.query({}, function(d){
                //deferred.reject();
                deferred.resolve(d.cats);
                return d.cats;
            });
            return deferred.promise;
        };

    })

;
