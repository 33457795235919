import * as angular from 'angular';

angular.module( 'strukshurApp.adminVendors', ['ui.router', 'ui.bootstrap', 'strukshurApp.admin'])

    .config(function config( $stateProvider ) {
        $stateProvider

            .state( 'admin.vendors', {
                url: '/vendors',
                params: {
                    page: null
                },
                views: {
                    "admin_middle_view": {
                        controller: 'AdminVendorsCtrl',
                        template: require('./vendors/admin_vendors.tpl.html')
                    }
                },
                data:{ pageTitle: 'Admin Vendors: List' }
            })
            .state( 'admin.vendor-add', {
                url: '/vendor/add',
                params: {
                    page: null
                },
                views: {
                    "admin_middle_view": {
                        controller: 'AdminVendorAddCtrl',
                        template: require('./vendors/admin_vendor_add.tpl.html')
                    }
                },
                data:{ pageTitle: 'Admin Vendor: Add' }
            })
            .state( 'admin.vendor', {
                url: '/vendor/:id',
                views: {
                    "admin_middle_view": {
                        controller: 'AdminVendorDetailCtrl',
                        template: require('./vendors/admin_vendor_detail.tpl.html')
                    }
                },
                data:{ pageTitle: 'Admin Vendor: Detail' }
            })
            .state( 'admin.vendor-edit', {
                url: '/vendor/:id/edit',
                views: {
                    "admin_middle_view": {
                        controller: 'AdminVendorEditCtrl',
                        template: require('./vendors/admin_vendor_edit.tpl.html')
                    }
                },
                resolve: {
                    vendorResolve: function($q, $state, $stateParams, strukshurApiService){
                        var deferred = $q.defer();
                        console.log('start vendorResolve');
                        strukshurApiService.getAdminVendor.query({id: $stateParams.id}, function(d){

                            if (d === undefined)
                            {
                                deferred.reject('Vendor not found.');
                                $state.go('admin.vendors');
                            }
                            else {
                                deferred.resolve(d.vendor);
                            }
                        }, function(e){
                            deferred.reject('Vendor not found.');
                            $state.go('admin.vendors');
                        });

                        return deferred.promise;
                    }
                },
                data:{ pageTitle: 'Admin Vendor: Edit' }
            })


        ;
    })
    .controller( 'AdminVendorsCtrl', function AdminVendorsCtrl( $scope, $state, strukshurApiService, AdminPage ) {

        $scope.perPage = 5;
        $scope.page = 1;
        $scope.total = 0;
        $scope.products = [];

        function fetchUsers() {
            strukshurApiService.getAdminVendors.query({page: $scope.page}, function(d) {

                $scope.total = d.total;
                $scope.perPage = d.perPage;
                $scope.vendors = d.vendors;

                //$scope.page++;
                $scope.totalPages = $scope.calculateTotalPages();

            }, function(e) {

                return false;
            });
        }

        fetchUsers();

        $scope.selectPage = function(page)
        {
            if (page > 0 && (page <= $scope.totalPages))
            {
                $scope.page = AdminPage.setPage(page);
                fetchUsers();
            }
        };

        $scope.noNext = function()
        {
            return Math.ceil($scope.total / $scope.perPage) <= $scope.page;
        };

        $scope.noPrevious = function()
        {
            return 1 >= $scope.page;
        };

        $scope.calculateTotalPages = function() {
            var totalPages = $scope.perPage < 1 ? 1 : Math.ceil($scope.total / $scope.perPage);
            return Math.max(totalPages || 0, 1);
        };

    })
    .controller( 'AdminVendorDetailCtrl', function AdminVendorDetailCtrl( $scope, $state, $stateParams, strukshurApiService, AdminPage ) {

        strukshurApiService.getAdminVendor.query({id: $stateParams.id}, function(d){
            $scope.vendor = d.vendor;

        });

        $scope.edit = function()
        {
            $state.go('admin.vendor-edit', {id: $stateParams.id});
        };


    })
    .controller( 'AdminVendorAddCtrl', function AdminVendorAddCtrl( $scope, $q, $state, strukshurApiService, strukshurAdminService, fileReaderService, $uibModal, $rootScope, $timeout ) {

        $scope.loading = false;
        $scope.formError = false;
        $scope.imgs = [];
        $scope.progress = 0;

        // very important
        $scope.form = {};
        $scope.status = {
            name: 'active'
        };

        // $scope.uploader = new FileUploader({
        //     url: 'upload.php'
        // });



        $scope.cancel = function () {
            $state.go('admin.vendors');
        };

        $scope.getFile = function (el) {
            var elementId = el[0].id;
            $scope.$apply();
            if($scope.file){
                fileReaderService.readAsDataUrl($scope.file, $scope)
                .then(function(result) {
                    $scope.imgs[elementId] = {'imageSrc': result, 'imageSrcLoading': false};
                    //$scope.imageSrcLoading = false;
                });
            }
            else{
                delete $scope.imgs[elementId];
            }

        };

        $scope.vendorAdd = function(myform) {
            var modalInstance, promise;
            if($scope.loading===true){
                return;
            }

            $scope.loading = true;

            modalInstance = $uibModal.open({
                animation: false,
                keyboard: false,
                backdrop: 'static',
                scope: $scope,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                controller: 'AdminProgressModalCtrl',
                template: require('../../common/templates/progress-modal.tpl.html'),
                resolve: {}
            });
            modalInstance.result.then(function (data) {},function (data) {});

            promise = strukshurAdminService.vendorAdd(myform);

            promise.then(function(data) {
                modalInstance.dismiss();
                $scope.loading = false;
                $scope.formError = false;
                $state.go('admin.vendors');

            }, function(e) {
                $timeout(function () {modalInstance.dismiss();}, 200);
                $scope.formError = true;
                console.log('promise Failed:', e);
                $scope.loading = false;
            });


        };
    })

    .controller( 'AdminVendorEditCtrl', function AdminVendorEditCtrl( $scope, $state, $q, $stateParams, vendorResolve, strukshurAdminService, strukshurApiService, fileReaderService ) {

        $scope.loading = false;
        $scope.formError = false;
        $scope.imgs = [];

        $scope.form = {};
        //console.log(productResolve);

        $scope.vendor = vendorResolve;

        //$scope.form = vendorResolve;

        //$scope.imageSrc = vendorResolve.headerImg;
        $scope.imgs['header_image'] = {'imageSrc': vendorResolve.headerImg, 'imageSrcLoading': false};
        $scope.imgs['logo'] = {'imageSrc': vendorResolve.logo, 'imageSrcLoading': false};

        $scope.vendorStatus = {
            status: vendorResolve.active === false ? 'hidden' : 'active'
        };

        $scope.cancel = function () {
            $state.go('admin.vendor', {id: $scope.vendor.id});
        };

        $scope.getFile = function (el) {
            var elementId = el[0].id;
            $scope.$apply();
            fileReaderService.readAsDataUrl($scope.file, $scope)
                .then(function(result) {
                    $scope.imgs[elementId] = {'imageSrc': result, 'imageSrcLoading': false};
                    //$scope.imageSrc = result;
                    //$scope.imageSrcLoading = false;
                });
        };

        $scope.vendorEdit = function(myform) {

            $scope.loading = true;

            myform['id'] = vendorResolve.id;
            myform['status'] = $scope.vendorStatus.status;

            var promise = strukshurAdminService.vendorEdit(myform);

            promise.then(function(data) {
                $scope.loading = false;
                console.log('promise Success');
                console.log(data);
                $scope.formError = false;
                $state.go('admin.vendor', {id: $scope.vendor.id});
            }, function(e) {
                console.log('promise Failed:');
                $scope.formError = true;
                $scope.loading = false;
            });


        };


    })

    .controller( 'AdminProgressModalCtrl', function AdminProgressModalCtrl( $scope ) {
        $scope.progress = 0;
        $scope.title = 'Uploading';
        $scope.$on('updateProgress', function (event, args) {
            if(args.name==='postAdminVendorAdd.save'){
                $scope.progress = args.progress;
                if($scope.progress>=100){
                    $scope.title = 'Please wait...';
                }
            }
        });
    })
;
