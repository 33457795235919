import * as angular from 'angular';

/**
 * @see https://github.com/adonespitogo/angular-base64-upload
 */
angular.module('FileBase64Directive', [])
.directive('strukshurBaseSixtyFour', [
    '$window',
    '$q',
    function ($window, $q) {
        var isolateScope = {
            onChange: '&',
            onAfterValidate: '&',
            parser: '&'
        };

        var FILE_READER_EVENTS = ['onabort', 'onerror', 'onloadstart', 'onloadend', 'onprogress', 'onload'];

        FILE_READER_EVENTS.forEach(function(e) {
            isolateScope[e] = '&';
        });

        return {
            restrict: 'A',
            require: 'ngModel',
            scope: isolateScope,
            link: function(scope: any, elem, attrs, ngModel) {

                var rawFiles: any;
                var fileObjects = [];

                if (!ngModel) {
                    return;
                }

                // VALIDATIONS =========================================================

                function _maxnum(val) {
                    if (attrs.maxnum && attrs.multiple && val) {
                        var valid = val.length <= parseInt(attrs.maxnum);
                        ngModel.$setValidity('maxnum', valid);
                    }
                    return val;
                }

                function _minnum(val) {
                    if (attrs.minnum && attrs.multiple && val) {
                        var valid = val.length >= parseInt(attrs.minnum);
                        ngModel.$setValidity('minnum', valid);
                    }
                    return val;
                }

                function _maxsize(val) {
                    var valid = true, max, i, file;

                    if (attrs.maxsize && val) {
                        max = parseFloat(attrs.maxsize) * 1000;

                        if (attrs.multiple) {
                            for (i = 0; i < val.length; i++) {
                                file = val[i];
                                if (file.filesize > max) {
                                    valid = false;
                                    break;
                                }
                            }
                        }
                        else {
                            valid = val.filesize <= max;
                        }
                        ngModel.$setValidity('maxsize', valid);
                    }

                    return val;
                }

                function _minsize(val) {
                    var valid = true, i, file;
                    var min = parseFloat(attrs.minsize) * 1000;

                    if (attrs.minsize && val) {
                        if (attrs.multiple) {
                            for (i = 0; i < val.length; i++) {
                                file = val[i];
                                if (file.filesize < min) {
                                    valid = false;
                                    break;
                                }
                            }
                        }
                        else {
                            valid = val.filesize >= min;
                        }
                        ngModel.$setValidity('minsize', valid);
                    }

                    return val;
                }

                function _accept(val) {
                    var valid = true, i, file;
                    var regExp, exp, fileExt;
                    if (attrs.accept) {
                        exp = attrs.accept.trim().replace(/[,\s]+/gi, "|").replace(/\./g, "\\.").replace(/\/\*/g, "/.*");
                        regExp = new RegExp(exp);
                    }

                    if (attrs.accept && val) {
                        if (attrs.multiple) {
                            for (i = 0; i < val.length; i++) {
                                file = val[i];
                                fileExt = "." + file.filename.split('.').pop();
                                valid = regExp.test(file.filetype) || regExp.test(fileExt);

                                if (!valid) {
                                    break;
                                }
                            }
                        }
                        else {
                            fileExt = "." + val.filename.split('.').pop();
                            valid = regExp.test(val.filetype) || regExp.test(fileExt);
                        }
                        ngModel.$setValidity('accept', valid);
                    }

                    return val;
                }

                //end validations ===============

                function _setViewValue() {
                    var newVal = attrs.multiple ? fileObjects : fileObjects[0];
                    ngModel.$setViewValue(newVal);
                    _maxsize(newVal);
                    _minsize(newVal);
                    _maxnum(newVal);
                    _minnum(newVal);
                    _accept(newVal);
                }

                function _attachHandlerForEvent(eventName, handler, fReader, file, fileObject) {
                    fReader[eventName] = function(e) {
                        handler()(e, fReader, file, rawFiles, fileObjects, fileObject);
                    };
                }

                function _readerOnLoad(fReader, file, fileObject) {

                    return function(e) {

                        var buffer = e.target.result;
                        var promise, exceedsMaxSize;

                        buffer = buffer.substr(buffer.indexOf(',') + 1);
                        // do not convert the image to base64 if it exceeds the maximum
                        // size to prevent the browser from freezing
                        exceedsMaxSize = attrs.maxsize && file.size > attrs.maxsize * 1024;
                        if (attrs.doNotParseIfOversize !== undefined && exceedsMaxSize) {
                            fileObject.base64 = null;
                        }
                        else {
                            fileObject.base64 = buffer;
                        }

                        if (attrs.parser) {
                            promise = $q.when(scope.parser()(file, fileObject));
                        }
                        else {
                            promise = $q.when(fileObject);
                        }

                        promise
                            .then(function(fileObj) {
                                fileObjects.push(fileObj);
                                // fulfill the promise here.
                                file.deferredObj.resolve();
                            })
                        ;

                        if (attrs.onload) {
                            if (scope.onload && typeof scope.onload() === "function") {
                                scope.onload()(e, fReader, file, rawFiles, fileObjects, fileObject);
                            } else {
                                scope.onload(e, rawFiles);
                            }
                        }
                    };

                }

                function _attachEventHandlers(fReader, file, fileObject) {
                    var i, e;
                    for (i = FILE_READER_EVENTS.length - 1; i >= 0; i--) {
                        e = FILE_READER_EVENTS[i];
                        if (attrs[e] && e !== 'onload') { // don't attach handler to onload yet
                            _attachHandlerForEvent(e, scope[e], fReader, file, fileObject);
                        }
                    }

                    fReader.onload = _readerOnLoad(fReader, file, fileObject);
                }

                function _readFiles() {
                    var i, promises = [], reader, file, fileObject;
                    for (i = rawFiles.length - 1; i >= 0; i--) {
                        // append file a new promise, that waits until resolved
                        rawFiles[i].deferredObj = $q.defer();
                        promises.push(rawFiles[i].deferredObj.promise);
                        // TODO: Make sure all promises are resolved even during file reader error, otherwise view value wont be updated
                    }

                    // set view value once all files are read
                    $q.all(promises).then(_setViewValue);

                    for (i = rawFiles.length - 1; i >= 0; i--) {
                        reader = new $window.FileReader();
                        file = rawFiles[i];
                        fileObject = {};

                        fileObject.filetype = file.type;
                        fileObject.filename = file.name;
                        fileObject.filesize = file.size;

                        _attachEventHandlers(reader, file, fileObject);
                        reader.readAsDataURL(file);
                    }
                }

                function _onChange(e) {
                    if (attrs.onChange) {
                        if (scope.onChange && typeof scope.onChange() === "function") {
                            scope.onChange()(e, rawFiles);
                        } else {
                            scope.onChange(e, rawFiles);
                        }
                    }
                }

                function _onAfterValidate(e) {
                    var promises, i;
                    if (attrs.onAfterValidate) {
                        // wait for all promises, in rawFiles,
                        //   then call onAfterValidate
                        promises = [];
                        for (i = rawFiles.length - 1; i >= 0; i--) {
                            promises.push(rawFiles[i].deferredObj.promise);
                        }
                        $q.all(promises).then(function() {
                            if (scope.onAfterValidate && typeof scope.onAfterValidate() === "function") {
                                scope.onAfterValidate()(e, fileObjects, rawFiles);
                            } else {
                                scope.onAfterValidate(e, fileObjects, rawFiles);
                            }
                        });
                    }
                }

                ngModel.$isEmpty = function (val) {
                    return !val || (angular.isArray(val) ? val.length === 0 : !val.base64);
                };

                // http://stackoverflow.com/questions/1703228/how-can-i-clear-an-html-file-input-with-javascript
                scope._clearInput = function () {
                    (<HTMLInputElement>elem[0]).value = '';
                };

                scope.$watch(function () {
                    return ngModel.$viewValue;
                }, function(val) {
                    if (ngModel.$isEmpty(val) && ngModel.$dirty) {
                        scope._clearInput();
                        // Remove validation errors
                        ngModel.$setValidity('maxnum', true);
                        ngModel.$setValidity('minnum', true);
                        ngModel.$setValidity('maxsize', true);
                        ngModel.$setValidity('minsize', true);
                        ngModel.$setValidity('accept', true);
                    }
                });

                elem.on('change', function (e: Event) {
                    fileObjects = [];
                    fileObjects = angular.copy(fileObjects);

                    if ((<HTMLInputElement>e.target).files.length === 0) {
                        rawFiles = [];
                        _setViewValue();
                    } else {
                        rawFiles = (<HTMLInputElement>e.target).files; // use event target so we can mock the files from test
                        _readFiles();
                        _onChange(e);
                        _onAfterValidate(e);
                    }

                    if (attrs.allowSameFile) {
                        scope._clearInput();
                    }
                });
            }
        };
    }
]);
