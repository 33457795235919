import * as angular from 'angular';

angular.module( 'strukshurApp.adminDeliveries', ['ui.router', 'ui.bootstrap'])

.config(function config($stateProvider) {
    $stateProvider

        .state( 'admin.deliveries', {
            url: '/delivery/list?:city&:state',
            params: {
                page: null
            },
            views: {
                "admin_middle_view": {
                    controller: 'AdminDeliveriesCtrl',
                    template: require('../productAdmin/delivery/admin_deliveries.tpl.html')
                }
            },
            data: { pageTitle: 'Delivery Prices: List' }
        })
        .state( 'admin.delivery-add', {
            url: '/delivery/:vendor_id/add',
            views: {
                "admin_middle_view": {
                    controller: 'AdminDeliveryCtrl',
                    template: require('../productAdmin/delivery/admin_delivery.tpl.html')
                }
            },
            data: { pageTitle: 'Delivery: Add' },
            resolve: {
                states: function (StateService) {
                    return StateService.getStates().then(function (d) {
                        return d;
                    });
                }
            }
        })
        .state( 'admin.delivery-edit', {
            url: '/delivery/:vendor_id/:delivery_id/edit',
            views: {
                "admin_middle_view": {
                    controller: 'AdminDeliveryCtrl',
                    template: require('../productAdmin/delivery/admin_delivery.tpl.html')
                }
            },
            data: { pageTitle: 'Delivery: Add' },
            resolve: {
                states: function (StateService) {
                    return StateService.getStates().then(function (d) {
                        return d;
                    });
                }
            }
        })
    ;
})

;
