import {
    pull as _pull,
} from 'lodash';
import * as angular from 'angular';

/**
 * Each section of the site has its own module. It probably also has
 * submodules, though this boilerplate is too simple to demonstrate it. Within
 * `src/app/home`, however, could exist several additional folders representing
 * additional modules that would then be listed as dependencies of this one.
 * For example, a `note` section could have the submodules `note.create`,
 * `note.delete`, `note.edit`, etc.
 *
 * Regardless, so long as dependencies are managed correctly, the build process
 * will automatically take take of the rest.
 *
 * The dependencies block here is also where component dependencies should be
 * specified, as shown below.
 */
angular.module('strukshurApp.favorite', ['ui.router'])

.config(function config($stateProvider) {
    $stateProvider
        .state('favorites', {
            url: '/favorites',
            views: {
                main: {
                    controller: 'FavoritesCtrl',
                    template: require('./favorites.tpl.html')
                }
            },
            resolve: {
                listsResolver: (strukshurApiService) => strukshurApiService.userLists.fetch().$promise,
            },
            data: { pageTitle: 'Favorites', class: 'favorites favorites-list', headerLogoFull: true }
        })

        .state('favorite', {
            url: '/favorite/:id/:slug',
            views: {
                main: {
                    controller: 'FavoriteCtrl',
                    template: require('./favorite.tpl.html')
                }
            },
            resolve: {
                favoriteResolver: ($stateParams, strukshurApiService) => strukshurApiService.userList.get({ id: $stateParams.id }).$promise,
                itemsResolver: ($stateParams, strukshurApiService) => strukshurApiService.userListItems.fetch({ id: $stateParams.id }).$promise,
            },
            data: { pageTitle: 'Favorite', class: 'favorites favorite-detail', headerLogoFull: true }
        })
    ;
})

    .controller('FavoritesCtrl', function FavoritesCtrl($scope, $state, $uibModal, listsResolver) {
        var vm = $scope;

        vm.lists = listsResolver.lists;

        /**
         * Opens modal to create a new list
         */
        vm.openNewListModal = () => {
            const modalInstance = $uibModal.open({
                scope: vm.$new(),
                controller: 'UserListNewCtrl',
                template: require('./new-list-modal.tpl.html'),
                resolve: {}
            });

            modalInstance.result.then(

                // Resolved callback
                data => {
                    if (data.list) {
                        data.list.itemsCount = 0;
                        vm.lists.push(data.list);
                    }
                },

                // Rejected callback
                angular.noop
            );
        };

        /**
         * Edits the received list
         *
         * @param  {object}  list  The list to edit
         */
        vm.openEditListModal = (event, list) => {
            event.preventDefault();
            event.stopPropagation();

            const $childScope = vm.$new();
            $childScope.list = list;
            $childScope.title = 'Edit list';

            const modalInstance = $uibModal.open({
                scope: vm,
                controller: 'UserListEditCtrl',
                template: require('./edit-list-modal.tpl.html'),
                resolve: {
                    list: () => list,
                }
            });

            modalInstance.result.then(

                // Resolved callback
                (data) => {
                    if (data.list) {
                        list.title = data.list.title;
                    }
                },

                // Rejected callback
                angular.noop
            );
        };

        /**
         * Removes the received list
         *
         * @param  {object}  list  The list to remove
         */
        vm.removeList = (event, list) => {
            event.preventDefault();
            event.stopPropagation();

            const $childScope = vm.$new();
            $childScope.list = list;
            $childScope.title = 'Remove list';
            $childScope.message = 'Are you sure you want to remove this list?';

            const modalInstance = $uibModal.open({
                keyboard: true,
                scope: $childScope,
                controller: 'UserListRemoveCtrl',
                template: require('../../common/templates/base.confirm-modal.tpl.html')
            });

            modalInstance.result.then(

                // Resolved callback
                () => _pull(vm.lists, list),

                // Rejected callback
                angular.noop
            );
        };

        /**
         * Navigates the user to the given list
         *
         * @param  {object}  list  The list
         */
        vm.goToList = (list) => {
            $state.go(`favorite`, { id: list.id, slug: list.slug });
        };
    })

    .controller('FavoriteCtrl', function FavoriteCtrl($scope, $uibModal, favoriteResolver, itemsResolver) {
        var vm = $scope;

        vm.list = favoriteResolver.list;
        vm.items = itemsResolver.items;
    })

    .controller('SaveFavoriteModalCtrl', function SaveFavoriteModalCtrl ($scope, $uibModalInstance, item, strukshurApiService, type) {
        var vm = $scope;

        vm.item = item;
        vm.type = type;
        vm.lists = [];
        vm.loading = false;
        vm.errorMessage = null;
        vm.selectedLists = [];

        vm.init = () => {
            strukshurApiService.userLists.fetch().$promise
                .then(res => {
                    vm.lists = res.lists;
                })
                .catch(err => {
                    vm.errorMessage = 'There was an error loading the favorites list.';
                })
            ;

            // Watch lists for changes
            vm.$watch('lists|filter:{checked:true}', (nv) => {
                vm.selectedLists = nv.map((list) => list.id);
            }, true);
        };

        vm.saveFavorite = () => {
            if (vm.loading) { return; }

            if (vm.selectedLists.length === 0) {
                vm.errorMessage = 'Please select at least one list to save the item.';
                return;
            }

            let data: any = {
                lists: vm.selectedLists.join(','),
                img: item.img,
                thumb: item.thumb,
                title: item.title,
                note: item.note,
                height: item.height,
                width: item.width,
                source_id: item.id,
                source_type: type,
                siteName: item.siteName,
                original_link: item.originalLink,
            };

            if (type === 'product') {
                data.product_id = item.id;
            }

            vm.loading = true;
            vm.errorMessage = '';
            strukshurApiService.userListItem.create(data).$promise
                .then(res => {
                    $uibModalInstance.close({ item: res.item });
                })
                .catch(err => vm.errorMessage = 'There was an error while saving the item.')
                .finally(() => vm.loading = false)
            ;
        };

        vm.init();
    })

    .controller('UserListNewCtrl', function UserListNewCtrl ($scope, $uibModalInstance, strukshurApiService) {
        var vm = $scope;

        vm.title = '';
        vm.loading = false;
        vm.errorMessage = '';

        /**
         * Saves the current list
         */
        vm.saveList = (form) => {
            vm.errorMessage = '';

            // Validates if a role was selected
            if (!form.title.$viewValue) {
                vm.errorMessage = 'The list\'s title cannot be empty.';
                return;
            }

            // Updates the user list
            vm.loading = true;
            strukshurApiService.userList.create({ title: form.title.$viewValue }).$promise
                .then(res => $uibModalInstance.close({ list: res.list }))
                .catch(res => {
                    if (res.status === 403) {
                        vm.errorMessage = 'You don\'t have the necessary permission to create the list.';
                    } else {
                        if (res && res.data && res.data.message) {
                            vm.errorMessage = res.data.message;
                        } else {
                            vm.errorMessage = 'There was an error trying to create the list.';
                        }
                    }
                })
                .finally(() => vm.loading = false)
            ;
        };
    })

    .controller('UserListEditCtrl', function UserListEditCtrl ($scope, $uibModalInstance, list, strukshurApiService) {
        var vm = $scope;

        vm.list = list;
        vm.title = list.title;
        vm.loading = false;
        vm.errorMessage = '';

        /**
         * Saves the current list
         */
        vm.saveList = (form) => {
            vm.errorMessage = '';

            // Validates if a role was selected
            if (!form.title) {
                vm.errorMessage = 'The list\'s title cannot be empty.';
                return;
            }

            const data = {
                id: list.id,
                title: form.title.$viewValue,
            };

            // Updates the user list
            vm.loading = true;
            strukshurApiService.userList.update(data).$promise
                .then(res => $uibModalInstance.close({ list: res.list }))
                .catch(res => {
                    if (res.status === 403) {
                        vm.errorMessage = 'You don\'t have the necessary permission to edit the list.';
                    } else {
                        if (res && res.data && res.data.message) {
                            vm.errorMessage = res.data.message;
                        } else {
                            vm.errorMessage = 'There was an error trying to edit the list.';
                        }
                    }
                })
                .finally(() => vm.loading = false)
            ;
        };
    })

    .controller('UserListRemoveCtrl', function UserListRemoveCtrl ($scope, $uibModalInstance, strukshurApiService) {
        var vm = $scope;

        vm.onConfirmChosen = () => {

            // Removes the user list
            vm.loading = true;
            strukshurApiService.userList.remove({ id: vm.list.id }).$promise
                .then(() => $uibModalInstance.close())
                .catch((res) => {
                    if (res.status === 403) {
                        vm.errorMessage = 'You don\'t have the necessary permission to remove the list.';
                    } else {
                        vm.errorMessage = 'There was an error trying to remove the user list.';
                    }
                })
                .finally(() => vm.loading = false)
            ;
        };
    })
;
