import * as angular from 'angular';
import { remove as _remove } from 'lodash';

angular.module('BoardDirective', [])
    .directive('board', function ($uibModal, $state, strukshurUserService) {
        return {
            restrict: 'EA',
            template: require('./board.item.tpl.html'),
            scope: {
                item: '=',
                type: '@',
                galleryId: '@'
            },
            controllerAs: 'dir',
            bindToController: true,
            controller: function ($scope, $timeout, angularGridInstance) {
                var vm = this;

                vm.currentUser = {};
                vm.canEdit = false;
                vm.canDelete = false;

                strukshurUserService.getUser().then(function (d) {
                    vm.currentUser = (d.user ? d.user : d);

                    // Handles canDelete setting if it's a project pin or not
                    if (vm.item.type === 'projectPin') {
                        vm.canDelete = (vm.currentUser && vm.currentUser.id !== null && strukshurUserService.hasPermission('project_pin_delete', vm.item.project));
                    } else if (vm.item.type === 'projectProgressItem') {
                        vm.canEdit = (vm.currentUser && vm.currentUser.id !== null && strukshurUserService.hasPermission('project_progress_item_edit', vm.item.project));
                        vm.canDelete = (vm.currentUser && vm.currentUser.id !== null && strukshurUserService.hasPermission('project_progress_item_delete', vm.item.project));
                    } else {
                        vm.canDelete = (vm.currentUser && vm.currentUser.id !== null && vm.item.user_id !== null && vm.item.user_id === vm.currentUser.id);
                    }
                });
                $scope.console = { log: console.log };

                vm.productAdd = function () {
                    console.log('open the other modal');
                    $scope.item = vm.item;
                    var modalInstance = $uibModal.open({
                        animation: true,
                        keyboard: false,
                        scope: $scope,
                        ariaLabelledBy: 'modal-title',
                        ariaDescribedBy: 'modal-body',
                        template: require('../templates/board-modal-save.tpl.html'),
                        controller: 'BoardModalSaveInstanceCtrl',
                        resolve: {
                            item: function () {
                                return $scope.item;
                            }
                        }
                    });

                    modalInstance.result.then(angular.noop, function (roomSlug) {
                        if (roomSlug !== false) {
                            $state.go('room', { userSlug: strukshurUserService.getUserSlug(), roomSlug: roomSlug, houseSlug: 'my-house' });
                        }
                    });
                };

                vm.boardOpen = function (size) {
                    $scope.item = vm.item;

                    // either product or pin
                    $scope.type = vm.type;

                    var modalInstance;
                    if (vm.item.type && vm.item.type === 'projectPin') {
                        modalInstance = $uibModal.open({
                            animation: true,
                            keyboard: true,
                            scope: $scope,
                            ariaLabelledBy: 'modal-title',
                            ariaDescribedBy: 'modal-body',
                            template: require('../templates/board-modal.tpl.html'),
                            controller: 'ProjectPinBoardModalInstanceCtrl',
                            size: size,
                            resolve: {
                                pin: function () {
                                    return $scope.item;
                                }
                            }
                        });
                    } else {
                        modalInstance = $uibModal.open({
                            animation: true,
                            keyboard: true,
                            scope: $scope,
                            ariaLabelledBy: 'modal-title',
                            ariaDescribedBy: 'modal-body',
                            template: require('../templates/board-modal.tpl.html'),
                            controller: 'BoardModalInstanceCtrl',
                            size: size,
                            resolve: {
                                pin_id: function () {
                                    return $scope.item.id;
                                }
                            }
                        });
                    }

                    modalInstance.result.then(angular.noop, angular.noop);
                };

                vm.confirmPop = function (pin) {
                    $scope.confirmMessage = false;

                    var deleteController = 'ConfirmPinDeleteCtrl';
                    if (vm.type === 'projectPin') {
                        deleteController = 'ConfirmProjectPinDeleteCtrl';
                    } else if (vm.type === 'projectProgressItem') {
                        deleteController = 'ConfirmProjectProgressItemDeleteCtrl';
                    }

                    var modalInstance = $uibModal.open({
                        animation: true,
                        keyboard: true,
                        scope: $scope,
                        ariaLabelledBy: 'modal-title',
                        ariaDescribedBy: 'modal-body',
                        controller: deleteController,
                        template: require('../templates/confirm-modal.tpl.html'),
                        resolve: {
                            pin: function () {
                                return pin;
                            },
                            message: function () {
                                return 'Are you sure?';
                            }
                        }
                    });

                    modalInstance.result.then(function (selectedItem) {
                        pin.deleted = true;
                        if (vm.galleryId && angularGridInstance.hasOwnProperty(vm.galleryId)) {
                            $timeout(function (timeout_data) {
                                timeout_data.angularGridInstance[timeout_data.galleryId].refresh();
                            }, 1000, true, { galleryId: vm.galleryId, angularGridInstance: angularGridInstance });
                        }
                    }, angular.noop);
                };

                /**
                 * Opens a modal to edit the received item
                 *
                 * @param  {Object}  item  The item
                 */
                vm.editItem = function (item) {
                    var modalInstance = $uibModal.open({
                        animation: true,
                        keyboard: true,
                        scope: $scope,
                        ariaLabelledBy: 'modal-title',
                        ariaDescribedBy: 'modal-body',
                        controller: 'ProjectDetailProgressEditItemCtrl',
                        template: require('../templates/projects.progressEditItemModal.tpl.html'),
                        resolve: {
                            item: function () {
                                return item;
                            },
                            project: function () {
                                return item.project;
                            }
                        }
                    });

                    modalInstance.result.then(function (data) {
                        if (data.item) {
                            item.title = data.item.title;
                            item.note = data.item.note;
                            item.task_id = data.item.task_id;
                            item.milestone_id = data.item.milestone_id;
                        }
                    }, angular.noop);
                };
            }
        };
    })

    .directive('pintag', function ($compile, $timeout, $uibModal) {
        return {
            restrict: 'A',
            scope: {
                tag: '='
            },
            link: function (scope: any, element, attrs) {
                scope.loaded = false;
                scope.remove = false;

                /**
                 * Removes the received tag for the current pin
                 *
                 * @param  {object}  tag  The tag
                 */
                scope.removeTag = function (tag) {
                    var parentPin = scope.$parent.$parent.item;

                    var $childScope: any = scope.$new();
                    $childScope.tag = tag;
                    $childScope.parentPin = parentPin;
                    $childScope.title = 'Remove tag';
                    $childScope.message = 'Are you sure you want to remove the tag from the pin?';

                    var modalInstance = $uibModal.open({
                        keyboard: true,
                        scope: $childScope,
                        controller: 'ConfirmPinTagRemoveCtrl',
                        template: require('../templates/base.confirm-modal.tpl.html')
                    });

                    modalInstance.result.then(

                        // Resolved callback
                        function () {

                            // Remove tag from the pin's tag list
                            _remove(parentPin.tags, { id: tag.id });

                            // Removes the pin tag element
                            element.remove();

                            // Prevents click from bubbling up
                            $timeout(function () { scope.remove = false; }, 1000);
                        },

                        // Rejected callback
                        angular.noop
                    );
                };

                element.bind('click', function (event) {
                    if (!scope.remove && scope.loaded) {
                        // hide the tag note
                        element.children('div').addClass('hidden');
                        scope.loaded = false;
                    } else {

                        // prevent bubbling for remove and stops from adding multiple remove links
                        if (!scope.remove && !scope.loaded) {
                            // show the tag note
                            element.children('div').removeClass('hidden');

                            if (scope.tag.left > 70) {
                                element.children('div').addClass('shift-left');
                            }

                            // add a remove link to tag note
                            var el = angular.element('<span/>');
                            el.append('<span class="delete-tag" ng-click="scope.remove=true;removeTag(tag)">Remove</span>');
                            $compile(el)(scope);
                            element.children('div').append(el);

                            scope.loaded = true;
                        }
                    }
                });
            }
        };
    })

    .directive('pintagimage', function ($compile, $timeout, $window, roomService, strukshurApiService, toastr) {
        return {
            restrict: 'A',
            scope: {
                pin: '=',
                type: '='
            },
            link: function(scope: any, element, attrs) {
                scope.img = null;
                scope.w = 0;
                scope.h = 0;

                scope.tagX = 0;
                scope.tagY = 0;

                scope.prop = {};
                scope.tagnote = '';
                scope.loading = false;
                scope.propAttr = {};
                scope.imageLoading = true;

                if (scope.pin && scope.pin.type && (scope.pin.type === 'projectPin' || scope.pin.type === 'projectFinish')) {
                    scope.roomProps = scope.pin.parent.roomProps;
                } else {
                    scope.roomProps = roomService.getCurrentRoomProps();
                }

                element.bind('load', function (event: Event) {
                    $timeout(function () {
                        scope.img = event.target;
                        scope.w = (<HTMLImageElement>event.target).width;
                        scope.h = (<HTMLImageElement>event.target).height;
                        scope.imageLoading = false;
                    }, 50);
                });

                angular.element($window).bind('resize', function () {
                    scope.$apply(function () {
                        if (scope.img && scope.img.width) {
                            scope.w = scope.img.width;
                            scope.h = scope.img.height;
                        }
                    });
                });

                scope.close = function () {
                    scope.removeTagForm();
                };

                // api requires float value, not int
                function intToFloat (num, decPlaces) {
                    if (num === parseInt(num, 10)) {
                        return num.toFixed(decPlaces);
                    } else {
                        return num;
                    }
                }

                scope.saveNote = function (form, note, prop, attr) {
                    if (form.$invalid) { return; }

                    if (note !== undefined && note !== '' && prop !== '') {
                        scope.loading = true;
                        scope.tagX = intToFloat(scope.tagX, 2);
                        scope.tagY = intToFloat(scope.tagY, 2);

                        // Saves the pin tag according to the type of pin
                        if (scope.pin.type && scope.pin.type === 'projectPin') {
                            strukshurApiService.projectPinTag.create({ pin_id: scope.pin.id, top: scope.tagY, left: scope.tagX, note: note, prop_id: prop.id, attr_id: attr.id }).$promise
                                .then(function (res) {
                                    scope.removeTagForm();

                                    // clear textarea value
                                    scope.prop = {};
                                    scope.tagnote = '';
                                    scope.propAttr = {};

                                    // add to pin tags, adds tags immediately to the image
                                    if (scope.pin.tags === undefined) {
                                        scope.pin.tags = [];
                                    }
                                    scope.pin.tags.push({ 'id': res.tag.id, 'top': scope.tagY, 'left': scope.tagX, 'note': note, prop_id: prop.id, attr_id: attr.id });
                                })
                                .catch(function (err) {
                                    console.error(err);
                                })
                                .finally(function () {
                                    scope.loading = false;
                                })
                            ;
                        } else if (scope.pin.type && scope.pin.type === 'projectFinish') {
                            strukshurApiService.projectFinishFileTag.create({ file_id: scope.pin.id, top: scope.tagY, left: scope.tagX, note: note, prop_id: prop.id, attr_id: attr.id }).$promise
                                .then(function (res) {
                                    scope.removeTagForm();

                                    // clear textarea value
                                    scope.prop = {};
                                    scope.tagnote = '';
                                    scope.propAttr = {};

                                    // add to pin tags, adds tags immediately to the image
                                    if (scope.pin.tags === undefined) {
                                        scope.pin.tags = [];
                                    }
                                    scope.pin.tags.push({ id: res.tag.id, top: scope.tagY, left: scope.tagX, note: note, prop_id: prop.id, attr_id: attr.id });
                                })
                                .catch(function (err) {
                                    console.error(err);
                                })
                                .finally(function () {
                                    scope.loading = false;
                                })
                            ;
                        } else {
                            strukshurApiService.pinTag.add({ id: scope.pin.id, top: scope.tagY, left: scope.tagX, note: note, prop_id: prop.id, attr_id: attr.id }).$promise
                                .then(function (res) {
                                    scope.loading = false;

                                    scope.removeTagForm();

                                    // clear textarea value
                                    scope.prop = {};
                                    scope.tagnote = '';
                                    scope.propAttr = {};

                                    // add to pin tags, adds tags immediately to the image
                                    if (scope.pin.tags === undefined) {
                                        scope.pin.tags = [];
                                    }
                                    scope.pin.tags.push({ id: res.tag.id, top: scope.tagY, left: scope.tagX, note: note, prop_id: prop.id, attr_id: attr.id });
                                })
                                .catch((err) => {
                                    if (err && err.data && err.data.message) {
                                        toastr.error(err.data.message, 'Error');
                                    } else if (err.status === 403) {
                                        toastr.error('You don\'t have the necessary permission to save the tag.', 'Error');
                                    } else {
                                        toastr.error('There was an error trying to save the tag.', 'Error');
                                    }
                                })
                                .finally(() => scope.loading = false)
                            ;
                        }
                    }
                };

                scope.removeTagForm = function () {
                    var childForm = element.parent()[0].querySelector('.product-tag-note-form');
                    if (childForm !== null) {
                        childForm.remove();
                    }
                };

                element.bind('click', function (event) {

                    // Prevents trigger if the image hasn't completely loaded yet
                    if (scope.imageLoading) { return false; }

                    // Prevents tag form from showing on product or progress item
                    if (['product', 'projectProgressItem', 'searchPin'].includes(scope.type)) {
                        return false;
                    }

                    var el, leftOffset, clickX, clickY, notePositionY, notePositionX;

                    leftOffset = 40;

                    scope.removeTagForm();

                    clickX = event.offsetX;
                    clickY = event.offsetY;

                    // xs screens need different offset ?
                    if (scope.w < 650) {
                        leftOffset = 18;
                    }

                    if (clickX > leftOffset) {
                        clickX = clickX - leftOffset;
                    } else {
                        clickX = 0;
                    }

                    scope.tagX = ((clickX / scope.w) * 100) + 2;
                    scope.tagY = (clickY / scope.h) * 100;

                    notePositionY = scope.tagY;
                    notePositionX = scope.tagX;

                    // Shifts the form over away from edge
                    if (notePositionX > 50 && scope.w < 650) {
                        notePositionX = notePositionX - 40;
                        if (notePositionX < 0) {
                            notePositionX = 0;
                        }
                    }

                    //if (notePositionX > 70)
                    //{
                    //    // shift the form over away from edge
                    //    notePositionX = notePositionX - 240;
                    //}

                    // xs screen note form should be centered
                    if (scope.w < 650) {
                        notePositionX = 5;
                    }

                    scope.tagnote = '';
                    scope.prop = '';
                    scope.propAttr = '';
                    scope.tag_form = {};

                    el = angular.element('<div/>');
                    el.css('top', notePositionY+'%');
                    el.css('left',notePositionX+'%');
                    el.addClass('product-tag-note-form');
                    el.append('<form id="tag_form" name="tag_form" ng-submit="saveNote(tag_form, tagnote, prop, propAttr)" novalidate>' +
                        '<p>Tag this item by adding a note</p>' +
                        '<span class="close" ng-click="close()">X</span>' +
                        '<div class="row"><div class="form-group col-md-6" ng-class="{\'has-error\': tag_form.$submitted && tag_form.tag_prop.$invalid}">' +
                        '<select class="form-control" ng-options="item as item.name for item in roomProps track by item.id" ng-model="prop" id="tag_prop" name="tag_prop" required></select>' +
                        '</div><div class="form-group col-md-6" ng-class="{\'has-error\': tag_form.$submitted && tag_form.tag_propAttr.$invalid}">' +
                        '<select class="form-control" ng-options="item as item.name for item in prop.attrs track by item.id" ng-model="propAttr" id="tag_propAttr" name="tag_propAttr" required></select>' +
                        '</div></div><div class="form-group" ng-class="{\'has-error\': tag_form.$submitted && tag_form.tagnote.$invalid}">' +
                        '<textarea class="form-control" class="tagnote" id="tagnote" name="tagnote" ng-model="tagnote" required></textarea>' +
                        '</div><div class="form-group">' +
                        '<button type="submit" id="submit" name="submit" ng-disabled="loading" value="Save" class="btn btn-success btn-block" ng-class="{ disabled: loading }">' +
                        '<span ng-show="!loading">Save</span>' +
                        '<span ng-show="loading"><i class="fa fa-refresh fa-spin"></i></span>' +
                        '</button>' +
                        '</div></form>');
                    $compile(el)(scope);
                    element.parent().append(el);
                });
            }
        };
    })

    .directive('watchHeightTarget', function ($compile) {
        return {
            restrict: 'A',
            link: function (scope, elem, attrs, controller, transcludeFn) {
                scope.$watch( '__height', function (newHeight: number, oldHeight: number) {
                    var elem_id, circle, icon;
                    if (newHeight && elem && elem.length > 0) {
                        elem_id = elem[0].id;
                        circle = document.getElementById('circle-' + elem_id.split('-')[1]);
                        if (!circle) {
                            circle = document.createElement("div");
                            circle.innerHTML = '<i class="fa fa-play-circle-o" aria-hidden="true"></i>';
                            circle.id = 'circle-'+elem_id.split('-')[1];
                            circle.classList.add('hoverPlay');
                            circle.onclick = function (ev){elem[0].click();};
                            //debugger;
                            elem[0].parentNode.insertBefore(circle, elem[0].nextSibling);
                        }
                        if (circle) {
                            if ((<HTMLImageElement>elem[0]).width) {
                                circle.style.left = Math.round((<HTMLImageElement>elem[0]).width / 2)+'px';
                            }
                            circle.style.top = Math.round((newHeight - 37) / 2)+'px';
                            circle.style.display = 'block';
                        }
                    }
                });
            }
        };
    })

    .directive('watchHeightSource', function () {
        return {
            restrict: 'A',
            link: function (scope: any, elem, attrs) {
                scope.$watch(function () {
                    if (elem && elem.length > 0) {
                        scope.__height = (<HTMLImageElement>elem[0]).height;
                    }
                });
            }
        };
    })

    .controller('BoardModalInstanceCtrl', function BoardModalInstanceCtrl ($scope, $uibModalInstance, pin_id, roomService, strukshurApiService) {
        var vm = $scope;

        vm.form = {};
        vm.tagged = false;
        vm.popupImage = {};
        vm.roomProps = roomService.getCurrentRoomProps();

        // Item from API
        if (vm.type === 'pin') {
            if (!vm.roomProps || vm.roomProps.length === 0) {
                strukshurApiService.getRoomProps.query({ room_slug: vm.item.room_slug }, function (d) {
                    vm.roomProps = d.room.roomProps;
                });
            }

            vm.item = { tags: [], imageLoaded: false };
            strukshurApiService.getPin.query({ id: pin_id }, function (d) {
                vm.item = d.pin;
            });
        }

        vm.getPropName = function (prop_id) {
            var i, tot;
            if (!vm.roomProps) {
                return '';
            }

            tot = vm.roomProps.length;
            for (i = 0; i < tot; i++) {
                if (vm.roomProps[i].id === prop_id) {
                    return vm.roomProps[i].name;
                }
            }

            return '';
        };

        vm.getAttrName = function (prop_id, attr_id) {
            var i, tot, i2, tot2;

            if (!vm.roomProps) { return ''; }

            tot = vm.roomProps.length;
            for (i = 0; i < tot; i++) {
                if (vm.roomProps[i].id === prop_id) {
                    tot2 = vm.roomProps[i].attrs.length;
                    for (i2 = 0; i2 < tot2; i2++) {
                        if (vm.roomProps[i].attrs[i2].id === attr_id) {
                            return vm.roomProps[i].attrs[i2].name;
                        }
                    }
                }
            }

            return '';
        };

        vm.galleryLoadImage = function () {
            var th = this, element = document.getElementById('popupImage');
            vm.item.height = th.thisImage.naturalHeight;
            vm.item.width = th.thisImage.naturalWidth;
            vm.popupImage.height = element.clientHeight;
            vm.popupImage.width = element.clientWidth;
            vm.item.imageLoaded = true;
        };

        // used to parse json tags in the template
        vm.parJson = function (json) {
            if (typeof json === 'object') { return json; }

            return JSON.parse(json);
        };

        vm.ok = function () {
            $uibModalInstance.close();
        };

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    })

    .controller('ProjectPinBoardModalInstanceCtrl', function ProjectPinBoardModalInstanceCtrl($scope, $uibModalInstance, pin) {
        var vm = $scope;

        vm.form = {};
        vm.item = pin;
        vm.tagged = false;
        vm.popupImage = {};
        vm.item.imageLoaded = false;
        vm.roomProps = pin.parent.roomProps;

        vm.getPropName = function (prop_id) {
            var i, tot;

            if (typeof prop_id === 'undefined') { return ''; }

            if (!vm.roomProps) { return ''; }

            tot = vm.roomProps.length;
            for (i = 0; i < tot; i++) {
                if (vm.roomProps[i].id === prop_id) {
                    return vm.roomProps[i].name;
                }
            }

            return '';
        };

        vm.getAttrName = function (prop_id, attr_id) {
            var i, tot, i2, tot2;
            if (!vm.roomProps) {
                return '';
            }

            tot = vm.roomProps.length;
            for (i = 0; i < tot; i++) {
                if (vm.roomProps[i].id === prop_id) {
                    tot2 = vm.roomProps[i].attrs.length;
                    for (i2 = 0; i2 < tot2; i2++) {
                        if (vm.roomProps[i].attrs[i2].id===attr_id) {
                            return vm.roomProps[i].attrs[i2].name;
                        }
                    }

                }
            }

            return '';
        };

        vm.galleryLoadImage = function(){
            var th = this, element = document.getElementById('popupImage');
            vm.item.height = th.thisImage.naturalHeight;
            vm.item.width = th.thisImage.naturalWidth;
            vm.popupImage.height = element.clientHeight;
            vm.popupImage.width = element.clientWidth;
            vm.item.imageLoaded = true;
        };

        // used to parse json tags in the template
        vm.parJson = function (json) {
            if (typeof json === 'object') {
                return json;
            }

            return JSON.parse(json);
        };

        vm.ok = function () {
            $uibModalInstance.close();
        };

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    })

    .controller('TaxonomySelectModalCtrl', function TaxonomySelectModalCtrl ($scope, $uibModalInstance, item, strukshurApiService, strukshurUserService) {
        var i, tot, row;

        $scope.selectedItem = item;
        $scope.loading = false;
        $scope.form = {};
        $scope.pinTags = [];
        $scope.itemAttrs = [];
        tot = $scope.selectedItem.attrs.length;

        for (i = 0; i < tot; i++) {
            row = $scope.selectedItem.attrs[i];
            row.checked = false;
            row.note = '';
            row.attr_name = row.name;
            $scope.itemAttrs.push(angular.copy(row));
        }

        strukshurApiService.getAllPinsRoom.query({ room_slug: $scope.selectedRoom.room.slug, user_slug: strukshurUserService.getUserSlug() }, function (d) {
            var i, tot, row, i2, tot2, row2, cpy, idx;
            $scope.pinTags = (d.pinTags === null ? [] : d.pinTags);
            tot = $scope.itemAttrs.length;
            tot2 = $scope.pinTags.length;

            for (i = 0; i < tot; i++) {
                row = $scope.itemAttrs[i];
                for (i2=0;i2<tot2;i2++) {
                    row2 = $scope.pinTags[i2];
                    if (row2.attr_id===row.id) {
                        idx = $scope.itemAttrs.indexOf(row);
                        if (idx>=0 && $scope.itemAttrs[idx].checked) {
                            cpy = angular.copy(row);
                            cpy.pin_id = row2.id;
                            cpy.note = row2.note;
                            cpy.checked = true;
                            $scope.itemAttrs.splice(i, 0, cpy);
                            i++;
                            tot++;
                        } else {
                            row.pin_id = row2.id;
                            row.note = row2.note;
                            row.checked = true;
                            $scope.itemAttrs[i] = row;
                        }
                    }
                }
            }
        });

        $scope.saveChecklist = function (form) {
            var i, tot, selection = [];
            $scope.loading = true;

            tot = $scope.itemAttrs.length;
            for (i = 0; i < tot; i++) {
                selection.push(angular.copy($scope.itemAttrs[i]));
            }

            strukshurApiService.pinTag.update({
                prop_id: $scope.selectedItem.id,
                room_slug: $scope.selectedRoom.room.slug,
                checklist: JSON.stringify(selection)
            }, function (d) {
                $scope.loading = false;
                $scope.ok();
            });
        };

        $scope.ok = function () {
            $uibModalInstance.close();
        };

        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    })

    .controller('BoardModalSaveInstanceCtrl', function BoardModalSaveInstanceCtrl($scope, $uibModalInstance, item, strukshurApiService, strukshurUserService) {
        var vm = $scope;

        vm.loading = false;
        vm.userRooms = [];
        vm.productRooms = {};

        vm.projects = [];
        vm.errorMessage = '';
        vm.projectRooms = [];
        vm.projectSelected = {};
        vm.projectSelectedError = false;

        strukshurUserService.getUser().then(function (d) {
            vm.projects = angular.copy(strukshurUserService.getProjects());

            if (vm.projects.length > 0) {
                vm.projects.unshift({ id: -1, title: '-- Add to home --', slug: '' });
                vm.projectSelected = vm.projects[0];
            }
        });

        // Load available user rooms
        vm.roomsLoaded = function (d) {
            vm.rooms = d.rooms;

            // Add select option to use as placeholder
            if (vm.rooms[0].slug !== '') {
                vm.rooms.unshift({ 'id': -1, 'name': ' Select a Room', 'slug': '' });
            }
            vm.productRooms = vm.rooms[0];
        };

        if (!vm.rooms || vm.rooms.length === 0) {
            strukshurApiService.userRooms.list({ house_id: strukshurUserService.getHouseId() }).$promise
                .then(function (res) {
                    vm.roomsLoaded(res);
                    vm.userRooms = res.rooms;
                })
            ;
        } else {
            vm.roomsLoaded({ rooms: vm.rooms });
        }

        // only required field for now
        vm.productRoomsError = false;

        vm.roomChanged = function (selectedRoom) {
            if (selectedRoom && typeof selectedRoom.id !== 'undefined') {
                vm.errorMessage = '';
                vm.productRoomsError = false;
            } else {
                vm.errorMessage = 'You need to select a room to proceed.';
                vm.productRoomsError = true;
            }
        };

        vm.projectChanged = function (selectedProject) {

            // Set user rooms if the user selected the home option
            if (selectedProject.id == -1) {
                vm.productRoomsError = false;
                vm.roomsLoaded({ rooms: vm.userRooms });
            } else {

                // Search project rooms for the selected project
                vm.errorMessage = '';
                vm.productRoomsError = false;
                vm.rooms = [];
                vm.loadingRooms = true;
                strukshurApiService.projectRooms.list({ project_id: selectedProject.id }).$promise
                    .then(function (res) {
                        vm.loadingRooms = false;
                        res.rooms.unshift({ 'id': -1, 'name': ' Select a Room', 'slug': '' });
                        vm.rooms = res.rooms;
                        vm.productRooms = vm.rooms[0];
                    })
                    .catch(function (res) {
                        vm.loadingRooms = false;
                        vm.rooms = [{ 'id': -1, 'name': ' Select a Room', 'slug': '' }];
                        vm.productRooms = vm.rooms[0];
                        vm.productRoomsError = true;

                        if (res.status === 403) {
                            vm.errorMessage = 'You don\'t have the necessary permission to list the project rooms.';
                        } else {
                            vm.errorMessage = 'There was an error trying to list the project rooms.';
                        }
                    })
                ;
            }
        };

        vm.cancel = function () {
            vm.rooms.splice(0, 1);
            $uibModalInstance.dismiss(false);
        };

        vm.cancelGoRoom = function (roomSlug) {
            // $uibModalInstance.dismiss(false);
            var data = {
                'roomSlug': roomSlug,
                'project': vm.projectSelected
            };
            $uibModalInstance.close(data);
        };

        vm.pinIdeaAdd = function(myForm) {
            var data;

            // Simple form validation
            if (typeof vm.productRooms.id === 'undefined') {
                vm.productRoomsError = true;
                vm.errorMessage = 'You need to select a room to proceed.';
                return;
            }

            vm.projectSelected = (myForm.projectSelected) ? myForm.projectSelected.$viewValue : null;
            data = {
                'project_id': (vm.projectSelected) ? vm.projectSelected.id : null,
                'room_id': vm.productRooms.id,
                'pins': [{
                    'url': vm.item.img,
                    'note': myForm.note.$viewValue,
                    'site_name': (vm.url?vm.url:window.location.href)
                }]
            };

            vm.loading = true;
            vm.errorMessage = '';
            vm.productRoomsError = false;
            strukshurApiService.postRoomBoardImport.save(data).$promise
                .then(function (res) {
                    vm.cancelGoRoom(vm.productRooms.slug); // close modal
                })
                .catch(function (res) {
                    vm.productRoomsError = true;

                    if (res.status === 403) {
                        vm.errorMessage = 'You don\'t have the necessary permission to add the pin.';
                    } else {
                        vm.errorMessage = 'There was an error trying to add the pin to the room.';
                    }
                })
                .finally(function () {
                    vm.loading = false;
                })
            ;
        };
    })

    .directive('imageonload', function () {
        return {
            restrict: 'A',
            link: function(scope: any, element, attrs) {
                element.bind('load', function() {
                    scope.thisImage = this;
                    //call the function that was passed
                    scope.$apply(attrs.imageonload);
                });
            }
        };
    })

    .controller('ConfirmPinDeleteCtrl', function ConfirmPinDeleteCtrl($scope, $uibModalInstance, strukshurApiService, message, pin) {
        $scope.loading = false;
        $scope.errorMessage = false;
        $scope.pin = pin;
        $scope.title = 'Confirm';
        $scope.message = message;

        $scope.confirm = function () {
            var data;

            if ($scope.loading) { return; }

            $scope.loading = true;
            $scope.errorMessage = false;
            data = {
                pin_id: $scope.pin.id
            };
            strukshurApiService.getPin.delete(data, function (d) {
                $uibModalInstance.close(true);
            }, function (e) {
                $scope.loading = false;
            });
        };

        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    })

    .controller('ConfirmProjectPinDeleteCtrl', function ConfirmProjectPinDeleteCtrl ($scope, $uibModalInstance, strukshurApiService, message, pin) {
        var vm = $scope;

        vm.loading = false;
        vm.errorMessage = false;
        vm.pin = pin;
        vm.title = 'Remove pin';
        vm.message = 'Are you sure you want to remove the pin?';

        vm.confirm = function () {
            if (vm.loading) { return; }

            vm.loading = true;
            vm.errorMessage = false;

            strukshurApiService.projectPin.delete({ pin_id: vm.pin.id }).$promise
                .then(function (res) {
                    $uibModalInstance.close(true);
                })
                .catch(function (res) {
                    if (res.status === 403) {
                        vm.errorMessage = 'You don\'t have the necessary permission to delete the pin.';
                    } else {
                        vm.errorMessage = 'There was an error trying to delete the pin.';
                    }
                })
                .finally(function () {
                    vm.loading = false;
                })
            ;
        };

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    })

    .controller('ConfirmProjectProgressItemDeleteCtrl', function ConfirmProjectProgressItemDeleteCtrl ($scope, $rootScope, $uibModalInstance, strukshurApiService, message, pin) {
        var vm = $scope;

        vm.loading = false;
        vm.errorMessage = false;
        vm.item = pin;
        vm.title = 'Remove item';
        vm.message = 'Are you sure you want to remove the progress item?';

        vm.confirm = function () {
            if (vm.loading) { return; }

            vm.loading = true;
            vm.errorMessage = false;

            strukshurApiService.projectProgressItem.delete({ item_id: vm.item.id }).$promise
                .then(function (res) {
                    $uibModalInstance.close(true);
                    $rootScope.$broadcast('progressItemRemoved', { item: vm.item });
                })
                .catch(function (res) {
                    if (res.status === 403) {
                        vm.errorMessage = 'You don\'t have the necessary permission to delete the item.';
                    } else {
                        vm.errorMessage = 'There was an error trying to delete the item.';
                    }
                })
                .finally(function () {
                    vm.loading = false;
                })
            ;
        };

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    })

    .controller('ConfirmPinTagRemoveCtrl', function ConfirmPinTagRemoveCtrl ($scope, $uibModalInstance, strukshurApiService, toastr) {
        var vm = $scope;

        vm.onConfirmChosen = function () {
            var $promise;

            // Removes the tag on the server according to its parent type
            if (vm.parentPin.type && vm.parentPin.type === 'projectPin' || vm.parentPin.type === 'projectFinish') {
                $promise = strukshurApiService.projectPinTag.delete({ tag_id: vm.tag.id }).$promise;
            } else {
                $promise = strukshurApiService.pinTag.delete({ id: vm.tag.id }).$promise;
            }

            vm.loading = true;
            $promise
                .then(function () {
                    $uibModalInstance.close();
                })
                .catch(function (err) {
                    console.error(err);
                    toastr.error('There was an error while deleting the tag.', 'Error!');
                })
                .finally(function () {
                    vm.loading = false;
                })
            ;
        };
    })
;
