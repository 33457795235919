import * as angular from 'angular';

enum MessageType { Danger = 'danger', Info = 'info', Success = 'success', Warning = 'warning' }

type MessageOpts = { message: string, type?: MessageType, container: string, duration?: number, dismissable?: boolean }

type ApiResponseErrorMessageOpts = { response: any, accessDeniedMessage?: string, defaultMessage: string, container: string, duration?: number, dismissable?: boolean }

var app = angular.module('strukshurFlashService', []);
app.factory('strukshurFlashService', (Flash: any) => {
    var service = {

        displayMessage: (opts: MessageOpts) => {
            const type = opts.type || MessageType.Info,
                  duration = opts.duration || 10000,
                  dismissable = opts.dismissable || true;

            Flash.create(type, opts.message, duration, { container: opts.container }, dismissable);
        },

        handleApiResponseErrorMessage: (opts: ApiResponseErrorMessageOpts) => {
            const duration = opts.duration || 10000,
                  dismissable = opts.dismissable || true,
                  accessDeniedMessage = opts.accessDeniedMessage || 'You don\'t have the necessary permission to access the resource.';

            // we first find the best error message to dislay to the user
            let message = opts.defaultMessage;
            if (opts.response.data && opts.response.data.message) {
                message = opts.response.data.message;
            } else if (opts.response.status && opts.response.status === 403) {
                message = accessDeniedMessage;
            }

            // And then we display that message
            service.displayMessage({
                message,
                type: MessageType.Danger,
                container: opts.container,
                duration,
                dismissable,
            });
        },
    };

    return service;
});
