import * as angular from 'angular';
import strukshurConfig from '../../config.ts';

angular.module('strukshurPinterestService', ['angularLocalStorage', 'ngResource'])

.factory('strukshurPinterestService', function ($http, $q, $resource, strukshurUserService, strukshurUtilService) {
    const service = {
        authenticate: () => {
            const deferred = $q.defer();
            const pinterestAppId = strukshurConfig.pinterest_api_id;
            const pinterestRedirectUri = encodeURIComponent(strukshurConfig.pinterest_redirect_uri);
            const pinterestRedirectState = btoa(`${strukshurUserService.getUserObj().id}:${strukshurUserService.getUserObj().slug}`);
            const url = `https://www.pinterest.com/oauth/?client_id=${pinterestAppId}&response_type=code&state=${pinterestRedirectState}&redirect_uri=${pinterestRedirectUri}`;

            strukshurUtilService.centeredPopup({ title: 'Pinterest Authentication', url: url, h: 480, w: 480 });

            return deferred.promise;
        },

        boards: $resource(strukshurConfig.api_url + '/pinterest-proxy/boards/', {},
            {
                list: { method: 'GET', params: { bookmark: '@bookmark', sort: '@sort' } },
            }
        ),

        // Returns the current user's Pinterest access token
        getAccessToken: (ignoreLoadingBar: boolean = false) => {
            return $http({
                url: strukshurConfig.api_url + '/me/pinterest-token',
                method: 'GET',
                params: {},
                headers: { 'X-Requested-with' : 'XMLHttpRequest' },
                responseType: 'json',
                ignoreLoadingBar: ignoreLoadingBar,
            });
        },

        getStrukshurBoards: $resource(strukshurConfig.api_url + '/pinterest-proxy/strukshur-boards/', {},
            {
                query: { method: 'GET', params: { bookmark: '@bookmark', sort: '@sort' } }
            }
        ),

        getStrukshurBoardPins: $resource(strukshurConfig.api_url + '/pinterest-proxy/strukshur-board/pins/', {},
            {
                query: { method: 'GET', params: { board_id: '@board_id', bookmark: '@bookmark' } }
            }
        ),

        public: $resource(strukshurConfig.domain + '/public/pinterest-proxy/strukshur-boards', {},
            {
                getStrukshurBoards: { method: 'GET', params: { bookmark: '@bookmark', sort: '@sort' } },
                getStrukshurBoardPins: { method: 'GET', params: { board_id: '@board_id', bookmark: '@bookmark' }, url: strukshurConfig.domain + '/public/pinterest-proxy/strukshur-board/pins' },
                getStrukshurPins: { method: 'GET', params: { board_id: '@board_id', bookmark: '@bookmark' }, url: strukshurConfig.domain + '/public/pinterest-proxy/strukshur-pins' },
            }
        ),

        pins: $resource(strukshurConfig.api_url + '/pinterest-proxy/board/pins/', {},
            {
                list: { method: 'GET', params: { board_id: '@board_id', bookmark: '@bookmark' } },
            }
        ),

        // Removes the current user's Pinterest access token
        removeAccessToken: () => {
            return $http({
                url: strukshurConfig.api_url + '/me/pinterest-token',
                method: 'DELETE',
                params: {},
                headers: { 'X-Requested-with' : 'XMLHttpRequest' },
                responseType: 'json',
            });
        },
    };

    return service;
});
