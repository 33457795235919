import * as angular from 'angular';

angular.module('strukshurApp.adminSkills', ['ui.router', 'ui.bootstrap', 'strukshurApp.admin'])

.config(function config( $stateProvider ) {
    $stateProvider

    .state( 'admin.skills', {
        url: '/skills',
        params: {
            page: null,
            new_id: null,
            item_edited: false
        },
        views: {
            "admin_middle_view": {
                controller: 'AdminSkillsCtrl',
                template: require('./skills/admin_skills.tpl.html')
            }
        },
        data:{ pageTitle: 'Admin Skills: List' }
    })
    .state( 'admin.skill-add', {
        url: '/skill/add',
        params: {
            page: null
        },
        views: {
            "admin_middle_view": {
                controller: 'AdminSkillAddCtrl',
                template: require('./skills/admin_skill_add.tpl.html')
            }
        },
        data:{ pageTitle: 'Admin Skill: Add' }
    })
    .state( 'admin.skill-edit', {
        url: '/skill/:id/edit',
        views: {
            "admin_middle_view": {
                controller: 'AdminSkillEditCtrl',
                template: require('./skills/admin_skill_edit.tpl.html')
            }
        },
        resolve: {
            skillResolve: function($q, $state, $stateParams, strukshurApiService){
                var deferred = $q.defer();
                strukshurApiService.getAdminSkill.query({id: $stateParams.id}, function(d){
                    if (d === undefined) {
                        $state.go('admin.skills');
                    }
                    else {
                        deferred.resolve(d.skill);
                    }
                }, function(e){
                    $state.go('admin.skills');
                });

                return deferred.promise;
            }
        },
        data:{ pageTitle: 'Admin Skill: Edit' }
    })


    ;
})
.controller( 'AdminSkillsCtrl', function AdminSkillsCtrl( $scope, $state, $stateParams, strukshurApiService, AdminPage, strukshurUserService, $uibModal ) {

    $scope.perPage = 5;
    $scope.page = 1;
    $scope.total = 0;
    $scope.skills = [];
    $scope.pages = [];
    $scope.terms = {};
    $scope.currentUser = {};
    $scope.successMessage = ($stateParams.new_id?'Skill added!':false);
    if($stateParams.item_edited){
        $scope.successMessage = 'Skill edited!';
    }
    $scope.isAdmin = false;

    strukshurUserService.getUser().then(function (d) {
        $scope.currentUser = d;
        $scope.isAdmin = strukshurUserService.isAdmin();
    });

    $scope.fetchSkills = function() {
        strukshurApiService.getAdminSkills.query({page: $scope.page, terms: JSON.stringify($scope.terms)}, function(d) {
            var i, tot;
            $scope.total = d.total;
            $scope.perPage = d.perPage;
            $scope.skills = d.skills;

            $scope.pages = [];
            tot = Math.ceil($scope.total / $scope.perPage);
            for(i=0;i<tot;i++){
                $scope.pages.push(i+1);
            }

            //$scope.page++;
            $scope.totalPages = $scope.calculateTotalPages();

        }, function(e) {

            return false;
        });
    };


    $scope.selectPage = function(page) {
        $scope.successMessage = false;
        if (page > 0 && (page <= $scope.totalPages)) {
            $scope.page = AdminPage.setPage(page);
            $scope.fetchSkills();
        }
    };

    $scope.noNext = function() {
        return Math.ceil($scope.total / $scope.perPage) <= $scope.page;
    };

    $scope.noPrevious = function() {
        return 1 >= $scope.page;
    };

    $scope.calculateTotalPages = function() {
        var totalPages = $scope.perPage < 1 ? 1 : Math.ceil($scope.total / $scope.perPage);
        return Math.max(totalPages || 0, 1);
    };

    $scope.deleteSkill = function (skill) {
        $scope.confirmMessage = false;
        $scope.successMessage = false;
        var modalInstance = $uibModal.open({
            animation: true,
            keyboard: true,
            scope: $scope,
            ariaLabelledBy: 'modal-title',
            ariaDescribedBy: 'modal-body',
            controller: 'ConfirmSkillDeleteCtrl',
            template: require('../../common/templates/confirm-modal.tpl.html'),
            resolve: {
                skill: function () {
                    return skill;
                },
                message: function () {
                    return 'Are you sure?';
                }
            }
        });
        modalInstance.result.then(function (selectedItem) {
            var tot, i;
            //remove from list
            $scope.total = $scope.total-1;
            tot = $scope.skills.length;
            for(i=0;i<tot;i++){
                if($scope.skills[i].id===selectedItem.id){
                    $scope.skills.splice(i, 1);
                    break;
                }
            }
            $scope.successMessage = 'Contractor Skill removed';
        }, function () {
            //cancel confirmation
        });
    };

    $scope.fetchSkills();

})
.controller( 'AdminSkillAddCtrl', function AdminSkillAddCtrl( $scope, $q, $state, strukshurApiService, strukshurAdminService, fileReaderService ) {

    $scope.loading = false;
    $scope.formError = false;
    $scope.imgs = [];

    // very important
    $scope.form = {SkillForm:{}};
    $scope.formData = {};
    $scope.regex = '[0-9a-zA-Z\\s\\- \']*';

    $scope.cancel = function () {
        $state.go('admin.skills');
    };

    $scope.getFile = function (el) {
        var elementId = el[0].id;
        $scope.$apply();
        fileReaderService.readAsDataUrl($scope.file, $scope)
            .then(function(result) {
                $scope.imgs[elementId] = {'imageSrc': result, 'imageSrcLoading': false};
                //$scope.imageSrcLoading = false;
            });
    };

    $scope.skillAdd = function(myform) {

        $scope.formError = false;
        if (!myform.$valid) {
            return;
        }
        if($scope.loading){
            return;
        }
        $scope.loading = true;

        strukshurApiService.postAdminSkillAdd.save({name: $scope.formData.name}, function(d){
            $scope.loading = false;
            $scope.formError = false;
            $state.go('admin.skills', {new_id: d.skill.id});
        }, function(e){
            $scope.formError = e.data.message;
            $scope.loading = false;
        });

    };
})

.controller( 'AdminSkillEditCtrl', function AdminSkillEditCtrl( $scope, $state, $q, $stateParams, skillResolve, strukshurAdminService, strukshurApiService, fileReaderService ) {

    $scope.loading = false;
    $scope.formError = false;
    $scope.imgs = [];

    $scope.regex = '[0-9a-zA-Z\\s\\- \']*';
    $scope.form = {SkillForm:{}};
    $scope.formData = {
        id: skillResolve.id,
        name: skillResolve.name
    };


    $scope.cancel = function () {
        $state.go('admin.skills');
    };

    $scope.getFile = function (el) {
        var elementId = el[0].id;
        $scope.$apply();
        fileReaderService.readAsDataUrl($scope.file, $scope)
            .then(function(result) {
                $scope.imgs[elementId] = {'imageSrc': result, 'imageSrcLoading': false};
                //$scope.imageSrc = result;
                //$scope.imageSrcLoading = false;
            });
    };

    $scope.skillEdit = function(myform) {

        $scope.formError = false;

        if (!myform.$valid) {
            $scope.formError = 'There was an error please check all required fields and try again.';
            return;
        }
        if($scope.loading){
            return;
        }
        $scope.loading = true;

        strukshurApiService.postAdminSkillEdit.save($scope.formData, function(d){
            $scope.loading = false;
            $scope.formError = false;
            $state.go('admin.skills', {item_edited: d.skill.id});
        }, function(e){
            console.log(e);
            $scope.formError = e.data.message;
            $scope.loading = false;
        });

    };


})

.controller( 'ConfirmSkillDeleteCtrl', function ConfirmSkillDeleteCtrl( $scope, $rootScope, $uibModalInstance, strukshurApiService, message, skill ) {
    $scope.loading = false;
    $scope.errorMessage = false;
    $scope.skill = skill;
    $scope.title = 'Confirm';
    $scope.message = message;
    $scope.confirm = function () {
        var data;
        if($scope.loading){
            return;
        }
        $scope.loading = true;
        $scope.errorMessage = false;
        data = {
            id: $scope.skill.id
        };
        strukshurApiService.getAdminSkill.delete(data, function (d) {
            $uibModalInstance.close(skill);
        }, function (e) {
            $scope.loading = false;
        });
    };
    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };
})

;
