import * as angular from 'angular';
import { saveAs } from 'file-saver';
import { isInteger as _isInteger, remove as _remove } from 'lodash';

import strukshurConfig from '../../config.ts';

angular.module('strukshurApp.adminUsers', ['ui.router', 'ui.bootstrap', 'strukshurApp.admin'])
    .config(function config( $stateProvider ) {
        $stateProvider
            .state('admin.users', {
                url: '/users?:page',
                params: {
                    page: null
                },
                views: {
                    'admin_middle_view': {
                        controller: 'AdminUsersCtrl',
                        template: require('./users/admin_users.tpl.html')
                    }
                },
                resolve: {
                    adminResolve: function($state, $q, strukshurUserService, InitService) {
                        return InitService.promise.then(function (d) {
                            if (strukshurUserService.canAdminUsers() === false) {
                                $state.go('admin-auth');
                                return false;
                            }
                        });
                    }
                },
                data: { pageTitle: 'Admin Users: List' }
            })

            .state('admin.user-add', {
                url: '/user/add',
                params: {
                    page: null
                },
                views: {
                    'admin_middle_view': {
                        controller: 'AdminUserAddCtrl',
                        template: require('./users/admin_user_add.tpl.html')
                    }
                },
                resolve: {
                    adminResolve: function($state, $q, strukshurUserService, InitService){
                        return InitService.promise.then(function(d){
                            if (strukshurUserService.canAdminUsers() === false) {
                                $state.go('admin-auth');

                                return false;
                            }
                        });
                    }
                },
                data: { pageTitle: 'Admin User: Add' }
            })

            .state('admin.user', {
                url: '/user/:id',
                views: {
                    'admin_middle_view': {
                        controller: 'AdminUserDetailCtrl',
                        template: require('./users/admin_user_detail.tpl.html')
                    }
                },
                data: { pageTitle: 'Admin User: Detail' }
            })

            .state('admin.user-edit', {
                url: '/user/:id/edit',
                views: {
                    'admin_middle_view': {
                        controller: 'AdminUserEditCtrl',
                        template: require('./users/admin_user_edit.tpl.html')
                    }
                },
                resolve: {
                    userResolve: function($q, $state, $stateParams, strukshurApiService){
                        var deferred = $q.defer();
                        console.log('start userResolve');
                        strukshurApiService.getAdminUser.query({id: $stateParams.id}, function(d){

                            if (d === undefined)
                            {
                                deferred.reject('User not found.');
                                $state.go('admin.users');
                            }
                            else {
                                // user and fos_user
                                deferred.resolve(d);
                            }
                        }, function(e){
                            deferred.reject('User not found.');
                            $state.go('admin.users');
                        });

                        return deferred.promise;
                    }
                },
                data: { pageTitle: 'Admin User: Edit' }
            })
        ;
    })

    .factory('AdminUserPage', function () {
        var page = 0;

        return {
            setPage: function (p) {
                page = p;

                return page;
            },
            getPage: function () {
                return page;
            }
        };
    })

    .controller('AdminUsersCtrl', function AdminUsersCtrl($http, $scope, $stateParams, $uibModal, $window, strukshurApiService, AdminUserPage, PricingService) {
        var vm = $scope;

        vm.total = 0;
        vm.pages = [];
        vm.users = [];
        vm.perPage = 20;
        vm.loading = false;
        vm.terms = { name: '' };
        vm.showPagination = false;
        vm.loadingSpreadsheet = false;
        vm.prices = PricingService.getNormal(true);
        vm.prices_pro = PricingService.getPro(true);

        let initialPage = parseInt($stateParams.page || 1, 10);
        if (!_isInteger(initialPage)) {
            initialPage = 1;
        }
        vm.page = initialPage;

        vm.init = () => {
            vm.setupPagination();
            vm.searchUsers(vm.page);
        };

        /**
         * Setup pagination logic depending on the device width
         */
         vm.setupPagination = () => {
            vm.showPrevNext = false;
            vm.showFirstLast = false;
            vm.adjacentPagination = 1;

            if ($window.innerWidth > 449) {
                vm.showPrevNext = true;
            }
            if ($window.innerWidth > 499) {
                vm.showFirstLast = true;
            }
            if ($window.innerWidth > 599) {
                vm.adjacentPagination = 2;
            }
        };

        vm.searchUsers = (page) => {
            strukshurApiService.getAdminUsers.query({ page: page, terms: JSON.stringify(vm.terms) }, function (d) {
                vm.page = page;
                vm.users = d.users;
                vm.total = d.total;
                vm.perPage = d.perPage;
                vm.showPagination = true;
            }, angular.noop);
        };

        vm.newSearchUsers = () => {
            vm.page = AdminUserPage.setPage(0);
            vm.searchUsers(vm.page);
        };

        vm.getPlanName = PricingService.getName;

        vm.deletePop = (selectedUser) => {
            vm.confirmMessage = false;
            const modalInstance = $uibModal.open({
                scope: vm,
                animation: true,
                keyboard: true,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                controller: 'AdminUserConfirmDeleteCtrl',
                template: require('../../common/templates/confirm-modal.tpl.html'),
                resolve: {
                    selectedUser: function () {
                        return selectedUser;
                    },
                    message: function () {
                        return 'Are you sure?';
                    }
                }
            });
            modalInstance.result.then((selectedItem) => selectedUser.deleted = true, angular.noop);
        };

        /**
         * Generates spreadsheet file for the most recent 100 users
         */
        vm.exportSpreadsheet = () => {
            vm.loadingSpreadsheet = true;

            // Downloads spreadsheet
            $http({
                url: strukshurConfig.api_url + '/admin/user/export',
                method: 'GET',
                params: {},
                headers: { 'Content-type': 'text/vnd.ms-excel; charset=utf-8' },
                responseType: 'arraybuffer'
            })
                .then((data) => {
                    vm.loadingSpreadsheet = false;
                    saveAs(new Blob([data.data], { type: 'text/vnd.ms-excel' }), 'strukshur_users.xls');
                })
                .catch(() => vm.loadingSpreadsheet = false)
            ;
        };

        vm.init();
    })

    .controller('AdminUserConfirmDeleteCtrl', function AdminUserConfirmDeleteCtrl($scope, $uibModalInstance, strukshurApiService, message, selectedUser) {
        $scope.loading = false;
        $scope.errorMessage = false;
        $scope.title = 'Confirm';
        $scope.message = message;
        $scope.selectedUser = selectedUser;
        $scope.confirm = function () {
            var data;
            if ($scope.loading) {
                return;
            }
            $scope.loading = true;
            $scope.errorMessage = false;
            data = {
                user_id: $scope.selectedUser.id
            };
            strukshurApiService.getAdminUser.remove(data, function (d) {
                $uibModalInstance.close('User Removed');
            }, function (e) {
                $scope.selectedUser.loading = false;
            });
        };
        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    })

    .controller('AdminUserDetailCtrl', function AdminUserDetailCtrl($scope, $stateParams, strukshurApiService, $uibModal, PricingService) {
        $scope.user = {};
        $scope.fos_user = {};
        $scope.success = false;
        $scope.error = false;
        $scope.processing = false;
        $scope.prices = PricingService.getNormal(true);
        $scope.prices_pro = PricingService.getPro(true);
        $scope.getPlanName = PricingService.getName;

        strukshurApiService.getAdminUser.query({ id: $stateParams.id }, function(d) {
            $scope.user = d.user;
            $scope.fos_user = d.fos_user;
        });

        $scope.resendEmail = function () {
            $scope.error = false;
            $scope.success = false;
            $scope.processing = true;
            strukshurApiService.postAdminUserResend.send({ user_id: $scope.user.id }, function (d) {
                $scope.processing = false;
                if (d.success.MessageId) {
                    $scope.success = 'Confirmation Email Sent.';
                } else {
                    $scope.error = 'Unexpected Error';
                }
            }, function (e) {
                $scope.processing = false;
                $scope.error = e.message?e.message:'Unknown Error';
            });
        };

        $scope.popupPassword = function (size) {
            $scope.success = false;
            var modalInstance = $uibModal.open({
                animation: true,
                keyboard: false,
                scope: $scope,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                template: require('../../common/templates/change-password-modal.tpl.html'),
                controller: 'AdminUserChangePasswordCtrl',
                size: size,
                resolve: {
                    user: function () {
                        return $scope.user;
                    },
                    fos_user: function () {
                        return $scope.fos_user;
                    }
                }
            });

            modalInstance.result.then(function (selectedItem) {
                $scope.success = selectedItem;
            }, function () {
                //$log.info('Modal dismissed at: ' + new Date());
            });
        };

        $scope.popupResetPassword = function (size) {
            $scope.success = false;
            var modalInstance = $uibModal.open({
                animation: true,
                keyboard: false,
                scope: $scope,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                template: require('../../common/templates/forgot-password-modal.tpl.html'),
                controller: 'AdminForgotPasswordCtrl',
                size: size,
                resolve: {
                    user: function () {
                        return $scope.user;
                    },
                    fos_user: function () {
                        return $scope.fos_user;
                    }
                }
            });

            modalInstance.result.then(function (selectedItem) {
                $scope.success = selectedItem;
            }, function () {
                //$log.info('Modal dismissed at: ' + new Date());
            });
        };

        $scope.popCancelSub = function () {
            $scope.confirmMessage = false;
            var modalInstance = $uibModal.open({
                animation: true,
                keyboard: true,
                scope: $scope,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                controller: 'ConfirmCancelAdmSubscriptionCtrl',
                template: require('../../common/templates/confirm-modal.tpl.html'),
                resolve: {
                    user: function () {
                        return $scope.user;
                    },
                    proFields: function () {
                        return $scope.fos_user;
                    },
                    message: function () {
                        return 'Are you sure?';
                    }
                }
            });
            modalInstance.result.then(function (selectedItem) {
                if(selectedItem.success){
                    $scope.user.subscriptionId = selectedItem.user.subscriptionId;
                    $scope.user.proConfirmed = selectedItem.user.proConfirmed;
                    $scope.user.plan = selectedItem.user.plan;
                    $scope.user.cancelSubscriptionDate = selectedItem.user.cancelSubscriptionDate;
                }
                $scope.successMessage = 'Subscription canceled';
            }, function () {
                //cancel confirmation
            });
        };

    })
    .controller( 'AdminUserChangePasswordCtrl', function AdminUserChangePasswordCtrl( $scope, strukshurApiService, $uibModalInstance, user, fos_user ) {

        $scope.loading = false;
        $scope.formError = false;
        $scope.formErrorMessage = '';
        $scope.carrier_error = false;
        $scope.fos_user = fos_user;
        $scope.user = user;
        $scope.fromAdmin = true;
        $scope.form = {};
        $scope.passwordCurrent = '';
        $scope.password1 = '';
        $scope.password2 = '';


        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
        $scope.formChangePassword = function () {
            $scope.loading = true;
            strukshurApiService.postAdminPassword.change({fos_user_id: $scope.fos_user.id, new_password: $scope.password1}, function (d) {
                $scope.loading = false;
                $uibModalInstance.close('Password Updated.');
            }, function (e) {
                $scope.loading = false;
            });
        };

    })
    .controller('AdminForgotPasswordCtrl', function AdminForgotPasswordCtrl( $scope, $http, $location, $state, storage, strukshurApiService, $uibModalInstance, user, fos_user) {

        $scope.user = user;
        $scope.fos_user = fos_user;
        $scope.email = angular.copy($scope.fos_user.email);
        // very important
        $scope.formAdmin = true;
        $scope.form = {};

        $scope.formSuccess = false;

        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.formForgotPassword = function() {

            strukshurApiService.userForgotPassword.save({email: this.email}, function(d) {

                $uibModalInstance.close('Password Reset Email Sent.');
                $scope.formSuccess = true;

            }, function (error) {

                var errorType = error.data.error;

                if (errorType === 'userNotFound') {
                    $scope.form.ForgotPasswordForm.email.$setValidity("notfound", false);
                }
                if (errorType === 'passwordAlreadyRequested') {
                    $scope.form.ForgotPasswordForm.email.$setValidity("alreadysent", false);
                    $scope.form.ForgotPasswordForm.email.$setValidity("notfound", true);
                }


            });

        };


    })
    .controller( 'AdminUserAddCtrl', function AdminUserAddCtrl( $scope, $state, $q, $stateParams, strukshurAdminService, strukshurApiService, strukshurUserService, PricingService, ContractorRoleService) {

        $scope.loading = false;
        $scope.formError = false;
        $scope.formErrorMessage = '';
        $scope.carrier_error = false;
        $scope.category_error = false;
        $scope.vendors = [];


        $scope.prices_pro = PricingService.getPro(true);
        $scope.prices = PricingService.getNormal(true);

        // very important
        $scope.form = {};
        $scope.user = {
            isSeller: false,
            reseller: '',
            is_fedex: 0,
            is_usps: 0,
            is_ups: 0,
            is_pickup: 0,
            isProConfirmed: false,
            plan: 'basic',
            category: ''
        };

        $scope.role = {
            name: 'standard'
        };


        $scope.formData = {};
        $scope.availableRoles = [];

        ContractorRoleService.getRoles().then(function (d) {
            $scope.availableRoles = d;
        });

        $scope.currentUser = {};
        $scope.isAdmin = false;
        strukshurUserService.getUser().then(function (d) {
            $scope.currentUser = d;
            $scope.isAdmin = strukshurUserService.isAdmin();
        });

        $scope.changePlan = function () {
            if ($scope.user.plan === 'basic' || !$scope.user.plan) {
                $scope.user.isProConfirmed = false;
            }
        };

        $scope.completeUrl = function (model) {
            if (typeof $scope.user[model] === 'string' && $scope.user[model] !== '') {
                if ($scope.user[model].indexOf('http') === -1) {
                    $scope.user[model] = 'http://'+$scope.user[model];
                }
            }
        };

        $scope.cancel = function () {
            $state.go('admin.users');
        };

        $scope.loadVendors = function($query) {
            var deferred = $q.defer();
            strukshurApiService.searchVendors.query({terms: $query}, function(d){
                deferred.resolve(d.vendors);
                return d.vendors;
            });
            return deferred.promise;
        };

        $scope.userAdd = function(myform) {
            var i,tot;

            $scope.loading = true;
            $scope.carrier_error = false;
            $scope.category_error = false;
            $scope.formError = false;

            if(myform.role.$viewValue!=='pro' && myform.role.$viewValue!=='admin'){
                $scope.user.isSeller = false;
                $scope.user.reseller = '';
                $scope.vendors = [];
                $scope.user.category = '';
                $scope.user.description = '';
                $scope.user.employees = null;
            }
            else if(!$scope.formData.category || !$scope.formData.category.name) {
                $scope.category_error = true;
                $scope.loading = false;
                return;
            }
            else{
                myform.category = $scope.formData.category.name;
                $scope.user.category = $scope.formData.category.name;
            }

            if($scope.vendors && $scope.vendors.length>1){
                return;
            }

            myform.isSeller = $scope.user.isSeller;
            myform.vendors = $scope.vendors;
            myform.reseller = $scope.user.reseller;
            myform.subscription = {
                isProConfirmed: $scope.user.isProConfirmed,
                plan: $scope.user.plan
            };
            if((myform.role.$viewValue==='pro' || myform.role.$viewValue==='admin') && $scope.user.isSeller && (!$scope.vendors || $scope.vendors.length===0)){
                myform.carriers = {
                    is_fedex: $scope.user.is_fedex,
                    is_usps: $scope.user.is_usps,
                    is_ups: $scope.user.is_ups,
                    is_pickup: $scope.user.is_pickup
                };
                if(!$scope.user.is_fedex && !$scope.user.is_usps && !$scope.user.is_ups && !$scope.user.is_pickup) {
                    $scope.carrier_error = true;
                    $scope.loading = false;
                    return;
                }
            }

            var promise = strukshurAdminService.userAdd(myform);

            promise.then(function(data) {
                $scope.loading = false;
                console.log('userAdd promise Success');
                console.log(data);
                $scope.formError = false;
                $scope.form = {};
                $state.go('admin.users');
            }, function(e) {
                $scope.formError = true;
                console.log('userAdd promise Failed:');
                if(e && e.data && e.data.message){
                    $scope.formErrorMessage = e.data.message;
                }
                else{
                    $scope.formErrorMessage = 'Invalid Form';
                }

                $scope.loading = false;
            });
        };
    })

    .controller( 'AdminUserEditCtrl', function AdminUserEditCtrl ($q, $scope, $state, ContractorRoleService, PricingService, strukshurAdminService, strukshurApiService, strukshurUserService, userResolve) {
        var vm = $scope;

        vm.loading = false;
        vm.carrier_error = false;
        vm.category_error = false;
        vm.formError = false;

        vm.form = {};
        vm.formData = {};

        vm.user = userResolve.fos_user;
        vm.vendors = userResolve.user.vendors?userResolve.user.vendors:[];
        vm.proFields = userResolve.user;

        vm.prices_pro = PricingService.getPro(true);
        vm.prices = PricingService.getNormal(true);

        vm.availableRoles = [];
        ContractorRoleService.getRoles().then(function (d) {
            var i,tot;
            vm.availableRoles = d;
            //select user category
            tot = vm.availableRoles.length;
            for(i=0;i<tot;i++){
                if(vm.availableRoles[i].name===vm.proFields.category){
                    vm.formData.category = vm.availableRoles[i];
                    break;
                }
            }
        });

        vm.changePlanEdit = function () {
            if (vm.proFields.plan === 'basic' || !vm.proFields.plan) {
                vm.proFields.proConfirmed = false;
            }
        };

        vm.completeUrl = function (model) {
            if (typeof vm.proFields[model] === 'string' && vm.proFields[model] !== '') {
                if (vm.proFields[model].indexOf('http') === -1) {
                    vm.proFields[model] = 'http://'+vm.proFields[model];
                }
            }
        };

        vm.cancel = function () {
            $state.go('admin.user', {id: userResolve.user.id});
        };

        vm.role = {
            name: userResolve.user.userType
        };

        vm.currentUser = {};
        vm.isAdmin = false;
        strukshurUserService.getUser().then(function (d) {
            vm.currentUser = d;
            vm.isAdmin = strukshurUserService.isAdmin();
        });

        vm.loadVendors = function($query) {
            var deferred = $q.defer();
            strukshurApiService.searchVendors.query({terms: $query}, function(d){
                deferred.resolve(d.vendors);
                return d.vendors;
            });
            return deferred.promise;
        };

        vm.userEdit = function(myform) {
            var i, tot;
            vm.carrier_error = false;
            vm.category_error = false;
            vm.formError = false;
            myform['id'] = userResolve.user.id;
            myform['role'] = vm.role.name;
            if(vm.role.name!=='pro' && vm.role.name!=='admin'){
                vm.vendors = [];
                vm.proFields.isSeller = false;
                vm.proFields.reseller = '';
                vm.proFields.category = '';
                vm.proFields.description = '';
                vm.proFields.employees = null;
            }
            else if(!vm.formData.category || !vm.formData.category.name) {
                vm.category_error = true;
                vm.loading = false;
                return;
            }
            else{
                myform.category = vm.formData.category.name;
                vm.proFields.category = vm.formData.category.name;
            }

            if(vm.vendors && vm.vendors.length>1){
                return;
            }

            if((vm.role.name==='pro' || vm.role.name==='admin') && vm.proFields.isSeller && (!vm.vendors || vm.vendors.length===0)){
                myform.carriers = {
                    is_fedex: vm.proFields.is_fedex,
                    is_usps: vm.proFields.is_usps,
                    is_ups: vm.proFields.is_ups,
                    is_pickup: vm.proFields.is_pickup
                };
                if(!vm.proFields.is_fedex && !vm.proFields.is_usps && !vm.proFields.is_ups && !vm.proFields.is_pickup) {
                    vm.carrier_error = true;
                    vm.loading = false;
                    return;
                }
            }

            myform['isSeller'] = vm.proFields.isSeller;
            myform['vendors'] = vm.vendors;
            myform['reseller'] = vm.proFields.reseller;
            myform['subscription'] = {
                proConfirmed: vm.proFields.proConfirmed,
                plan: vm.proFields.plan
            };

            var promise = strukshurAdminService.userEdit(myform);
            promise.then(function () {
                vm.loading = false;
                vm.formError = false;
                $state.go('admin.user', { id: userResolve.user.id });
            }, function (e) {
                vm.loading = false;
                vm.formError = true;
            });
        };
    })

    .controller('ConfirmCancelAdmSubscriptionCtrl', function ConfirmCancelAdmSubscriptionCtrl($scope, $uibModalInstance, strukshurApiService, message, user, proFields) {
        $scope.loading = false;
        $scope.errorMessage = false;
        $scope.user = user;
        $scope.proFields = proFields;
        $scope.title = 'Confirm';
        $scope.message = message;

        $scope.confirm = function () {
            if ($scope.loading) { return; }

            $scope.loading = true;
            $scope.errorMessage = false;

            var data = {
                user_id: $scope.proFields.id
            };

            strukshurApiService.adminSubscription.cancel(data, function (d) {
                $uibModalInstance.close({ success: d.success, user: d.user });
            }, function (e) {
                $scope.loading = false;
            });
        };

        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    })
;
