import * as angular from 'angular';

angular.module( 'strukshurApp.adminTaxonomy', ['ui.router', 'ui.bootstrap', 'strukshurApp.admin'])

    .config(function config($stateProvider) {
        $stateProvider
            .state('admin.taxonomy', {
                url: '/taxonomy',
                params: {
                    page: null
                },
                views: {
                    'admin_middle_view': {
                        controller: 'AdminTaxonomyCtrl',
                        template: require('./taxonomy/admin_taxonomy.tpl.html')
                    }
                },
                data: { pageTitle: 'Admin Taxonomy' }
            })

            .state('admin.taxonomy-list', {
                url: '/taxonomy/list',
                params: {
                    page: null
                },
                views: {
                    'admin_middle_view': {
                        controller: 'AdminTaxonomyListCtrl',
                        template: require('./taxonomy/admin_taxonomy_list.tpl.html')
                    }
                },
                data: { pageTitle: 'Admin Taxonomy: List' }
            })

            .state('admin.taxonomy-edit', {
                url: '/taxonomy/edit/{id}',
                params: {
                    page: null
                },
                views: {
                    'admin_middle_view': {
                        controller: 'AdminTaxonomyEditCtrl',
                        template: require('./taxonomy/admin_taxonomy_edit.tpl.html')
                    }
                },
                data: { pageTitle: 'Admin Taxonomy: Edit' }
            })

            .state('admin.taxonomy-add', {
                url: '/taxonomy/add',
                params: {
                    page: null
                },
                views: {
                    'admin_middle_view': {
                        controller: 'AdminTaxonomyAddCtrl',
                        template: require('./taxonomy/admin_taxonomy_add.tpl.html')
                    }
                },
                data: { pageTitle: 'Admin Taxonomy: Add' }
            })
        ;
    })

    .controller('AdminTaxonomyListCtrl', function AdminTaxonomyListCtrl($scope, $state, strukshurApiService) {
        var vm = $scope;

        strukshurApiService.getAdminRoomProps.query({}, function (d) {
            console.log(d);
            vm.roomResults = d.room_props;
        }, function (e) {
            console.log(e);
        });

        vm.editTaxonomy = function (prop) {
            $state.go('admin.taxonomy-edit', { id: prop.id });
        };
    })

    .controller('AdminTaxonomyAddCtrl', function AdminTaxonomyAddCtrl ($scope, $state, strukshurApiService) {
        var vm = $scope;

        vm.form = {};
        vm.loading = false;

        // get list of all rooms
        strukshurApiService.getRooms.query({}, function (d) {
            vm.rooms = d.rooms;
        });

        vm.addRoomProp = function (myform) {
            vm.loading = true;
            vm.formError = false;
            vm.formErrorMessage = '';

            if (myform.$valid) {
                var roomId = myform.selectedRoom.$viewValue.id;
                var propName = myform.propName.$viewValue;

                strukshurApiService.RoomProp.add({ room_id: roomId, prop_name: propName }, function (d) {
                    console.log('added prop');
                    console.log(d);
                    vm.formError = false;
                    vm.loading = false;
                    $state.go('admin.taxonomy-edit', { id: d.prop.id });
                }, function (e){
                    vm.loading = false;
                    vm.formError = true;
                    vm.formErrorMessage = e.data.message; //'There was an error. All fields are required.';
                });
            } else {
                vm.loading = false;
                vm.formError = true;
                vm.formErrorMessage = 'There was an error. All fields are required.';
            }
        };

        vm.cancel = function () {
            $state.go('admin.taxonomy-list');
        };
    })

    .controller('AdminTaxonomyEditCtrl', function AdminTaxonomyEditCtrl ($scope, $state, $stateParams, strukshurApiService) {
        var vm = $scope;

        vm.loading = true;
        vm.propId = $stateParams.id;
        var addedFieldId = 1;

        strukshurApiService.RoomProp.query({ id: $stateParams.id }, function (d) {
            vm.loading = false;
            vm.room = d.prop.room;
            vm.roomResults = d.room_props;
        }, function (e) {
            console.log(e);
        });

        vm.updatePropField = function (attr) {
            console.log('got this');
            console.log(attr);

            strukshurApiService.RoomProp.update({ prop_id: attr.attr_id, name: attr.prop_name }, function (d) {
                console.log('saved prop');
                console.log(d);
            });
        };

        vm.updateAttrField = function (attr) {
            console.log('got this');
            console.log(attr);

            if (attr.attr_add !== undefined) {
                console.log('going to add this' + vm.propId );
                // add attr
                strukshurApiService.RoomAttr.add({ prop_id: vm.propId, name: attr.attr_name }, function(d) {
                    console.log('saved attr');
                    console.log(d);
                    attr.attr_id = d.attr.id;
                });
            } else {
                strukshurApiService.RoomAttr.update({ attr_id: attr.attr_id, name: attr.attr_name }, function(d) {
                    console.log('saved attr');
                    console.log(d);
                });
            }
        };

        vm.deleteAttr = function (attr) {
            if (confirm('Are you sure you want to delete this attribute?')) {
                strukshurApiService.RoomAttr.delete({attr_id: attr.attr_id}, function (d) {
                    angular.forEach(vm.roomResults[vm.propId], function (value, key) {
                        if (value.attr_id === attr.attr_id) {
                            vm.roomResults[vm.propId].splice(key, 1);
                        }
                    });
                });
            }
        };

        vm.addPropField = function () {
            // needs to be a unique id even thought it has no official id yet....
            var obj = { attr_add: true, attr_id: addedFieldId, attr_name: '' };
            vm.roomResults[vm.propId].push(obj);
            addedFieldId++;
        };

        vm.cancel = function () {
            $state.go('admin.taxonomy-list');
        };
    })

    .controller('AdminTaxonomyCtrl', function AdminTaxonomyCtrl($scope, $state, AdminPage, strukshurApiService) {
        $scope.roomProps = [{id: 'choice1', props: [{ id: 'choice1prop1', tags: [{ id: 'tag1'}] }]}, {id: 'choice2', props: [{ id: 'choice2prop1', tags: [{ id: 'tag1'}] }]}];

        $scope.addRoomProp = function() {
            var newItemNo = $scope.roomProps.length+1;
            $scope.roomProps.push({'id':'choice'+newItemNo, 'props': [{ 'id': 'choice'+newItemNo+'prop1', tags: [{ id: 'tag1'}] }] });
        };

        $scope.addRoomPropAttr = function(roomProp) {
            var inc = roomProp.props.length + 1;
            roomProp.props.push({ 'id': roomProp.id+'prop' + inc, tags: [{ id: 'tag1'}] });
        };

        $scope.addTag = function(roomPropAttr) {
            var inc = roomPropAttr.tags.length + 1;
            roomPropAttr.tags.push({ 'id': 'tag' + inc });
        };

        $scope.removeChoice = function() {
            var lastItem = $scope.roomProps.length-1;
            $scope.roomProps.splice(lastItem);
        };
    })
;
