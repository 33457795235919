import * as angular from 'angular';

var app = angular.module('StateService', []);
app.factory('StateService', ['$http', '$rootScope', 'storage', 'strukshurApiService', '$q',  function($http, $rootScope, storage, strukshurApiService, $q) {

    var states = storage.get('states');
    var cities = storage.get('cities');
    if(!states){
        states = [];
    }
    if(!cities){
        cities = {};
    }

    return {
        getStates: function() {
            var deferred = $q.defer();
            if(states && states.length>0){
                deferred.resolve(states);
            }
            else{
                states = storage.get('states');
                if(!states){
                    states = [];
                }
                if(states && states.length>0){
                    deferred.resolve(states);
                }
                else{
                    strukshurApiService.getStates.list({}, function (d) {
                        states = d.states;
                        storage.set('states', angular.copy(states));
                        deferred.resolve(states);
                    }, function (e) {
                        deferred.reject(e);
                    });
                }
            }
            return deferred.promise;
        },
        getStatesObj: function() {
            return states;
        },
        getCities: function(state) {
            var deferred = $q.defer();
            if(cities && cities.hasOwnProperty(state) && cities[state].length>0){
                deferred.resolve(cities[state]);
            }
            else{
                cities = storage.get('cities');
                if(!cities){
                    cities = {};
                }
                if(cities && cities.hasOwnProperty(state) && cities[state].length>0){
                    deferred.resolve(cities[state]);
                }
                else{
                    strukshurApiService.getCities.list({state: state}, function (d) {
                        cities[state] = d.cities;
                        storage.set('cities', angular.copy(cities));
                        deferred.resolve(cities[state]);
                    }, function (e) {
                        deferred.reject(e);
                    });
                }
            }
            return deferred.promise;
        },
        getCitiesObj: function(state) {
            return cities[state];
        }


    };

}]);
