import * as angular from 'angular';

var app = angular.module('CartService', []);

app.factory('CartService', ['$http', '$rootScope', 'storage',  function ($http, $rootScope, storage) {
    var items = storage.get('cart');
    var project = storage.get('project');
    var approved = false;
    var shippingAddress = storage.get('shippingAddress');
    var taxes = storage.get('taxes');
    var shippingMethod = storage.get('shippingMethod');
    var vendorDelivery = storage.get('vendorDelivery');
    var strukshurFee = 0.06;
    if(!shippingMethod){
        shippingMethod = {};
    }
    if(!vendorDelivery){
        vendorDelivery = {};
    }

    if(typeof items==='string'){
        items = JSON.parse(items);
    }
    if(typeof project==='string'){
        project = JSON.parse(project);
    }
    if(typeof shippingAddress==='string'){
        shippingAddress = JSON.parse(shippingAddress);
    }
    if(typeof taxes==='string'){
        taxes = JSON.parse(taxes);
    }
    else if(taxes===null){
        taxes = {};
    }
    return {

        addItem: function(item, quantity) {
            var i, tot, added = false;
            quantity = parseInt(quantity);
            if(!items || items.length===0){
                items = [{item:item, quantity: quantity}];
            }
            else{
                //search if already added
                tot = items.length;
                for(i=0;i<tot;i++){
                    if(items[i].item.id===item.id){
                        items[i].quantity += quantity;
                        //make sure to not add more than available
                        if(items[i].quantity>parseInt(items[i].item.quantity)){
                            items[i].quantity = parseInt(items[i].item.quantity);
                        }
                        added = true;
                        break;
                    }
                }
                //if not added, then add it
                if(!added){
                    items.push({item:item, quantity:quantity});
                }
            }
            storage.set('cart', angular.copy(items));
            storage.set('shippingMethod', false);
            storage.set('vendorDelivery', false);
            shippingMethod = {};
            vendorDelivery = {};
            approved = false;
            $rootScope.$broadcast('cartUpdate', {});
        },
        calculateSubtotal: function () {
            var i, tot, subtotal = 0,
                subtotalPro = 0,
                subtotalTax = 0,
                subtotalProTax = 0,
                tax = 0,
                shipping = 0,
                user_id, vendor_id;
            if(!items || items.length===0){
                return {subtotal: subtotal, subtotalPro:subtotalPro, subtotalTax: subtotalTax, subtotalProTax: subtotalProTax, tax: tax, shipping: 0, fee: strukshurFee};
            }
            tot = items.length;

            for(i=0;i<tot;i++){
                subtotal += parseFloat(items[i].item.price)*items[i].quantity;
                subtotalPro += parseFloat(items[i].item.pricePro)*items[i].quantity;
                //todo: calculate tax exemption
                //if(!items[i].item.vendors || items[i].item.vendors.length===0){ //the sale is between states and there's no store on buyer's state: no taxes
                    subtotalTax += parseFloat(items[i].item.price)*items[i].quantity;
                    subtotalProTax += parseFloat(items[i].item.pricePro)*items[i].quantity;
                //}
            }

            if(shippingAddress && shippingAddress.zip && taxes[shippingAddress.zip]){
                tax = taxes[shippingAddress.zip].combinedRate;
            }

            for(user_id in shippingMethod){
                if(!shippingMethod.hasOwnProperty(user_id)){
                    continue;
                }
                shipping += shippingMethod[user_id].shippingPrice;
            }

            for(vendor_id in vendorDelivery){
                if(!vendorDelivery.hasOwnProperty(vendor_id)){
                    continue;
                }
                shipping += vendorDelivery[vendor_id];
            }


            return {
                subtotal: Math.round(subtotal*100)/100,
                subtotalPro: Math.round(subtotalPro*100)/100,
                subtotalTax: Math.round(subtotalTax*tax*100)/100,
                subtotalProTax: Math.round(subtotalProTax*tax*100)/100,
                tax: tax,
                shipping: shipping,
                fee: strukshurFee
            };
        },
        clearCart: function() {
            items = false;
            storage.set('cart', false);
            storage.set('project', false);
            project = false;
            storage.set('shippingMethod', false);
            storage.set('vendorDelivery', false);
            shippingMethod = {};
            vendorDelivery = {};
            approved = false;
            $rootScope.$broadcast('cartUpdate', {});
        },
        hasItems: function() {
            return items;
        },
        updateQuantity: function (item) {
            var i, tot;
            tot = items.length;
            for(i=0;i<tot;i++){
                if(item.item.id===items[i].item.id){
                    items[i].quantity = parseInt(item.quantity);
                    if(items[i].quantity>parseInt(items[i].item.quantity)){
                        items[i].quantity = parseInt(items[i].item.quantity);
                    }
                    break;
                }
            }
            storage.set('cart', angular.copy(items));
            storage.set('shippingMethod', false);
            storage.set('vendorDelivery', false);
            shippingMethod = {};
            vendorDelivery = {};
            $rootScope.$broadcast('cartUpdate', {});
        },
        removeItem: function (item) {
            var i, tot;
            tot = items.length;
            for(i=0;i<tot;i++){
                if(item.item.id===items[i].item.id){
                    items.splice(i, 1);
                    break;
                }
            }
            storage.set('cart', angular.copy(items));
            storage.set('shippingMethod', false);
            storage.set('vendorDelivery', false);
            shippingMethod = {};
            vendorDelivery = {};
            $rootScope.$broadcast('cartUpdate', {});
        },
        getApproved: function() {

            return approved;
        },
        setApproved: function() {

            approved = true;

            $rootScope.$broadcast('cartUpdate', {});
        },
        getProject: function() {
            return project;
        },
        setProject: function(data) {
            project = data;
            storage.set('project', angular.copy(project));
        },
        getShippingAddress: function() {
            if(!shippingAddress){
                return {};
            }
            return shippingAddress;
        },
        setShippingAddress: function(data) {
            shippingAddress = data;
            storage.set('shippingAddress', angular.copy(shippingAddress));
        },
        getTaxes: function(zip) {
            if(typeof taxes!=='object' || taxes===false){
                return false;
            }
            return taxes[zip];
        },
        setTaxes: function(data, zip) {
            taxes[zip] = data;
            storage.set('taxes', angular.copy(taxes));
        },
        setShippingMethod: function (carrier, seller_user_id, serviceType, shippingPrice) {
            shippingMethod[seller_user_id] = {
                carrier: carrier,
                seller_user_id: seller_user_id,
                serviceType: serviceType,
                shippingPrice: shippingPrice
            };
            storage.set('shippingMethod', shippingMethod);
        },
        setVendorShippingMethod: function (vendor_id, deliveryPrice) {
            if(!vendorDelivery){
                vendorDelivery = {};
            }
            if(!vendorDelivery[vendor_id]){
                vendorDelivery[vendor_id] = 0;
            }
            if(deliveryPrice && deliveryPrice.miles){
                vendorDelivery[vendor_id] = deliveryPrice.dumpTruck + deliveryPrice.surchargeDump + deliveryPrice.flatBed + deliveryPrice.surchargeFlat;
            }
            storage.set('vendorDelivery', vendorDelivery);
        },
        getShippingMethod: function () {
            return shippingMethod;
        },
        getVendorShippingMethod: function () {
            return vendorDelivery;
        },
        getStrukshurFee: function () {
            return strukshurFee;
        }

    };

}]);
