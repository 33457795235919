import * as angular from 'angular';
import { isInteger as _isInteger, find as _find } from 'lodash';
import strukshurConfig from '../../config.ts';

angular.module('strukshurApp.store', ['ui.router', 'ui.bootstrap'])
    .config(function config($stateProvider) {
        $stateProvider
            .state('store', {
                url: '/shop',
                views: {
                    main: {
                        controller: 'StoreCtrl',
                        template: require('./store.tpl.html')
                    }
                },
                resolve: {},
                data: { pageTitle: 'Shop', class: 'store', fluidContainer: true }
            })

            .state('store-category', {
                url: '/shop/category/{slug}?page',
                views: {
                    main: {
                        controller: 'StoreCategoryCtrl',
                        template: require('./store.category.tpl.html')
                    }
                },
                resolve: {},
                data: { pageTitle: 'Shop Category', class: 'store page-store-category', fluidContainer: true }
            })

            .state('store-vendor', {
                url: '/shop/vendor/{slug}?page',
                views: {
                    main: {
                        controller: 'StoreVendorCtrl',
                        template: require('./store.vendor.tpl.html')
                    }
                },
                resolve: {},
                data: { pageTitle: 'Shop Vendor', class: 'store page-store-vendor', fluidContainer: true }
            })

            .state('store-product', {
                url: '/shop/product/{id}/{slug}?origin,parent_id',
                views: {
                    main: {
                        controller: 'StoreProductCtrl',
                        template: require('./store.product.tpl.html')
                    }
                },
                resolve: {
                    productResolve: function ($q, $state, $stateParams, strukshurApiService) {
                        const deferred = $q.defer();
                        strukshurApiService.product.get({ 'id': $stateParams.id }, function (d) {
                            if (d === undefined) {
                                deferred.reject('Product not found.');
                                $state.go('home');
                            } else {
                                deferred.resolve(d);
                            }
                        }, function (e) {
                            deferred.reject('Product not found.');
                            $state.go('home');
                        });

                        return deferred.promise;
                    }
                },
                data: { pageTitle: 'Shop Product', class: 'store page-store-product', fluidContainer: true }
            })

            .state('cart', {
                url: '/cart',
                views: {
                    main: {
                        controller: 'CartCtrl',
                        template: require('./cart.tpl.html')
                    }
                },
                resolve: {},
                data: { pageTitle: 'Cart', class: 'store page-cart' }
            })

            .state('checkout-project', {
                url: '/checkout/project',
                views: {
                    main: {
                        controller: 'CheckoutProjectCtrl',
                        template: require('./checkout-project.tpl.html')
                    }
                },
                resolve: {
                    userResolve: function(userRouterResolveService){
                        return userRouterResolveService.userMustBeLoggedin();
                    }
                },
                data: { pageTitle: 'Checkout', class: 'store page-checkout' }
            })

            .state('checkout-address', {
                url: '/checkout/address',
                views: {
                    main: {
                        controller: 'CheckoutAddressCtrl',
                        template: require('./checkout-address.tpl.html')
                    }
                },
                resolve: {
                    userResolve: function(userRouterResolveService){
                        return userRouterResolveService.userMustBeLoggedin();
                    }
                },
                data: { pageTitle: 'Checkout', class: 'store page-checkout' }
            })

            .state('checkout-shipping', {
                url: '/checkout/shipping',
                views: {
                    main: {
                        controller: 'CheckoutShippingCtrl',
                        template: require('./checkout-shipping.tpl.html')
                    }
                },
                resolve: {
                    userResolve: function(userRouterResolveService){
                        return userRouterResolveService.userMustBeLoggedin();
                    }
                },
                data: { pageTitle: 'Shipping Method', class: 'store page-checkout' }
            })

            .state('checkout-payment', {
                url: '/checkout/payment',
                views: {
                    main: {
                        controller: 'CheckoutPaymentCtrl',
                        template: require('./checkout-payment.tpl.html')
                    }
                },
                resolve: {
                    userResolve: function($q, strukshurUserService, $state, userRouterResolveService){
                        var deferred = $q.defer(), logged_in;
                        return strukshurUserService.getUser().then(function(d) {

                            // to prevent checkout without paying subscription
                            if (strukshurUserService.isMissingPayment()) {
                                //deferred.reject('Pro not activated yet.');
                                $state.go('pro-register-payment');
                                return false;
                            }
                            logged_in = userRouterResolveService.userMustBeLoggedin();
                            deferred.resolve(logged_in);
                            return deferred.promise;
                        });
                    }
                },
                data: { pageTitle: 'Payment Method', class: 'store page-checkout' }
            })

            .state('store-confirmation', {
                url: '/checkout/confirmation',
                views: {
                    main: {
                        controller: 'CheckoutConfirmationCtrl',
                        template: require('./checkout.confirmation.tpl.html')
                    }
                },
                data: { pageTitle: 'Checkout Confirmation', class: 'store page-checkout' }
            })

            .state('my-orders', {
                url: '/my-orders?:terms&:page',
                views: {
                    main: {
                        controller: 'MyOrdersCtrl',
                        template: require('./my-orders.tpl.html')
                    }
                },
                resolve: {
                    userResolve: function(userRouterResolveService){
                        return userRouterResolveService.userMustBeLoggedin();
                    }
                },
                data: { pageTitle: 'My Orders', class: 'my-orders' }
            })

            .state('my-order-detail', {
                url: '/my-order/:suborder_id/detail',
                views: {
                    main: {
                        controller: 'MyOrderDetailCtrl',
                        template: require('./my-order.detail.tpl.html')
                    }
                },
                resolve: {
                    userResolve: function(userRouterResolveService){
                        return userRouterResolveService.userMustBeLoggedin();
                    }
                },
                data: { pageTitle: 'My Order', class: 'my-orders' }
            })
        ;
    })

    .factory('productCategoriesService', function($cacheFactory, $q, strukshurApiService) {
        var cache = $cacheFactory('productCategories');

        function fetchCategories(d) {
            strukshurApiService.getProductCategories.query({}, function(data){
                cache.put('productCategories', data.cats);
                d.resolve(data.cats);
            });
        }

        return {
            getCategories : function () {
                var d = $q.defer();

                var cats = cache.get('productCategories');
                if (cats){
                    d.resolve(cats);
                } else {
                    fetchCategories(d);
                }

                return d.promise;
            }
        };
    })

    .factory('vendorService', function ($cacheFactory, $q, strukshurApiService) {
        var cache = $cacheFactory('storeVendors');

        function fetchVendors (d) {
            strukshurApiService.getStoreVendors.query({}, function(data){
                cache.put('storeVendors', data.vendors);
                d.resolve(data.vendors);
            });
        }

        return {
            getVendors : function () {
                var d = $q.defer();

                var vendors = cache.get('storeVendors');
                if (vendors) {
                    d.resolve(vendors);
                } else {
                    fetchVendors(d);
                }

                return d.promise;
            }
        };
    })

    .factory('ProductPage', function () {
        var page = 0;

        return {
            setPage: function (p) {
                page = p;
                return page;
            },

            getPage: function () {
                return page;
            }
        };
    })

    .controller('StoreCtrl', function StoreCtrl ($aside, $scope, $state, $window, angularGridInstance, strukshurApiService) {
        var vm = $scope;

        vm.vendors = [];
        vm.gridSize = 130;
        vm.categories = [];
        vm.showVendors = true;

        /**
         * Controller bootstrap logic
         */
        vm.init = () => {
            strukshurApiService.productCategories.list().$promise.then((data) => vm.categories = data.cats);

            strukshurApiService.productVendors.list().$promise.then((data) => vm.vendors = data.vendors);

            // responsively resize the gridWidth
            angular.element($window).bind('resize', resizeGrid);

            resizeGrid();
        };

        vm.viewCategoryProducts = (category) => {
            $state.go('store-category', { slug: category.slug });
        };

        vm.refresh = () => angularGridInstance.gallery.refresh();

        /**
         * Opens the shop categories menu aside
         */
        vm.openShopCategoriesMenu = () => {
            vm.categoriesMenu = $aside({
                title: 'Categories Menu',
                template: require('./categories.menu.tpl.html'),
                controller: angular.noop,
                placement: 'left',
                animation: 'am-fade-and-slide-left',
                scope: vm,
                show: true
            });
        };

        /**
         * Navigates to the given product category page
         *
         * @param  {string}  category  The category
         */
        vm.goToCategory = (category) => {
            vm.categoriesMenu.hide();
            $state.go('store-category', { slug: category.slug });
        };

        /**
         * Navigates to the given product vendor page
         *
         * @param  {string}  vendor  The vendor
         */
        vm.goToVendor = (vendor) => {
            vm.categoriesMenu.hide();
            $state.go('store-vendor', { slug: vendor.slug });
        };

        function resizeGrid() {
            var gridSize = 130, gutterSize = 20;
            if ($window.innerWidth > 991) {
                gridSize = 200;
                gutterSize = 40;
            } else if ($window.innerWidth > 767) {
                gridSize = 170;
                gutterSize = 40;
            }

            vm.angularGridOptions = {
                gridWidth: gridSize,
                gutterSize: gutterSize,
                refreshOnImgLoad: false
            };

            vm.gridSize = gridSize;
        }

        vm.init();
    })

    .controller('StoreVendorCtrl', function StoreVendorCtrl ($aside, $scope, $state, $stateParams, $timeout, $window, productCategoriesService, smoothScroll, strukshurApiService, strukshurFlashService, vendorService) {
        var vm = $scope;

        vm.total = 0;
        vm.page = null;
        vm.vendor = {};
        vm.vendors = [];
        vm.products = [];
        vm.pageSize = 25;
        vm.gridSize = 130;
        vm.loading = false;
        vm.categories = [];
        vm.headerHeight = '250px';
        vm.showPagination = false;

        let initialPage = parseInt($stateParams.page || 1, 10);
        if (!_isInteger(initialPage)) {
            initialPage = 1;
            strukshurFlashService.displayMessage({
                type: 'danger',
                container: 'store-vendor-flash',
                message: 'The given value for the page parameter is invalid.',
            });
        }
        vm.page = initialPage;

        /**
         * Controller bootstrap logic
         */
        vm.init = function () {
            vm.setupPagination();

            // Fetch product categories
            strukshurApiService.productCategories.list().$promise.then((data) => vm.categories = data.cats);

            // Fetch product vendors
            strukshurApiService.productVendors.list().$promise.then((data) => vm.vendors = data.vendors);

            // Fetch selected vendor info
            strukshurApiService.productVendor.get({ slug: $stateParams.slug }).$promise
                .then (res => vm.vendor = res.vendor)
                .catch(angular.noop)
            ;

            // Fetch selected vendor products
            vm.fetchProducts(vm.page);

            angular.element($window).bind('resize', () => {
                vm.setupPagination();
            });
        };

        /**
         * Setup pagination logic depending on the device width
         */
        vm.setupPagination = () => {
            vm.showPrevNext = false;
            vm.showFirstLast = false;
            vm.adjacentPagination = 1;

            if ($window.innerWidth > 449) {
                vm.showPrevNext = true;
            }
            if ($window.innerWidth > 499) {
                vm.showFirstLast = true;
            }
            if ($window.innerWidth > 599) {
                vm.adjacentPagination = 2;
            }
        };

        /**
         * Updates the page parameter for the current page
         */
        vm.updatePageParams = () => {
            $state.go($state.current, { page: vm.page }, { location: true, inherit: true, notify: false });
        };

        vm.fetchProducts = (page: number) => {
            let oldPage = vm.page;

            vm.page = page;
            vm.loading = true;
            strukshurApiService.productVendor.listProducts({ vendor: $stateParams.slug, page: vm.page }).$promise
                .then((res: any) => {
                    vm.total = res.total;
                    vm.vendor = res.vendor;
                    vm.products = res.products;
                    vm.showPagination = true;
                    vm.updatePageParams();

                    smoothScroll(document.querySelector('.products'), { offset: 80 });
                })
                .catch((err: any) => {
                    vm.page = oldPage;
                    strukshurFlashService.handleApiResponseErrorMessage({
                        response: err,
                        container: 'store-category-flash',
                        defaultMessage: 'There was an error while trying to fetch products.',
                        accessDeniedMessage: 'You don\'t have the necessary permission to list products.',
                    });
                })
                .finally(() => vm.loading = false)
            ;
        };

        /**
         * Navigates to the correct product page based on its type
         *
         * @param  {object}  product  The product
         * @param  {object}  vendor   The vendor
         */
        vm.goToProduct = function (product, vendor) {
            switch (product.type) {
                case 'amazon':
                    $window.open(product.link, '_blank');
                break;

                // case 'vendor':
                //     $state.go('store-vendor', { origin: 'vendor', parent_id: vendor.id, id: product.id, slug: product.slug });
                // break;

                case 'product':
                default:
                    $state.go('store-product', { origin: 'vendor', parent_id: vendor.id, id: product.id, slug: product.slug });
                break;
            }
        };

        /**
         * Opens the shop categories menu aside
         */
        vm.openShopCategoriesMenu = function () {
            vm.categoriesMenu = $aside({
                title: 'Categories Menu',
                template: require('./categories.menu.tpl.html'),
                controller: angular.noop,
                placement: 'left',
                animation: 'am-fade-and-slide-left',
                scope: vm,
                show: true
            });
        };

        /**
         * Navigates to the given product category page
         *
         * @param  {string}  category  The category
         */
        vm.goToCategory = function (category) {
            vm.categoriesMenu.hide();
            $state.go('store-category', { slug: category.slug });
        };

        /**
         * Navigates to the given product vendor page
         *
         * @param  {string}  vendor  The vendor
         */
        vm.goToVendor = function (vendor) {
            vm.categoriesMenu.hide();
            $state.go('store-vendor', { slug: vendor.slug });
        };

        vm.init();
    })

    .controller('StoreCategoryCtrl', function StoreCategoryCtrl ($aside, $scope, $state, $stateParams, $window, smoothScroll, strukshurApiService, strukshurFlashService) {
        var vm = $scope;

        vm.total = 0;
        vm.page = null;
        vm.vendors = [];
        vm.pageSize = 25;
        vm.products = [];
        vm.gridSize = 130;
        vm.loading = false;
        vm.categories = [];
        vm.showVendors = true;
        vm.showPagination = false;

        let initialPage = parseInt($stateParams.page || 1, 10);
        if (!_isInteger(initialPage)) {
            initialPage = 1;
            strukshurFlashService.displayMessage({
                type: 'danger',
                container: 'store-category-flash',
                message: 'The given value for the page parameter is invalid.',
            });
        }
        vm.page = initialPage;

        /**
         * Controller bootstrap logic
         */
        vm.init = () => {
            vm.setupPagination();

            // Fetch product categories
            strukshurApiService.productCategories.list().$promise.then((data) => vm.categories = data.cats);

            // Fetch product vendors
            strukshurApiService.productVendors.list().$promise.then((data) => vm.vendors = data.vendors);

            vm.fetchProducts(vm.page);
            angular.element($window).bind('resize', () => {
                vm.setupPagination();
            });
        };

        /**
         * Setup pagination logic depending on the device width
         */
        vm.setupPagination = () => {
            vm.showPrevNext = false;
            vm.showFirstLast = false;
            vm.adjacentPagination = 1;

            if ($window.innerWidth > 449) {
                vm.showPrevNext = true;
            }
            if ($window.innerWidth > 499) {
                vm.showFirstLast = true;
            }
            if ($window.innerWidth > 599) {
                vm.adjacentPagination = 2;
            }
        };

        /**
         * Updates the page parameter for the current page
         */
        vm.updatePageParams = () => {
            $state.go($state.current, { page: vm.page }, { location: true, inherit: true, notify: false });
        };

        /**
         * Fetches a list of products from the server based on the controller's state
         */
        vm.fetchProducts = (page) => {
            let oldPage = vm.page;

            vm.page = page;
            vm.loading = true;
            strukshurApiService.productCategory.listProducts({ category: $stateParams.slug, page: vm.page }).$promise
                .then((res: any) => {
                    vm.total = res.total;
                    vm.category = res.category;
                    vm.products = res.products;
                    vm.showPagination = true;
                    vm.updatePageParams();

                    smoothScroll(document.querySelector('.products'), { offset: 80 });
                })
                .catch((err: any) => {
                    vm.page = oldPage;
                    strukshurFlashService.handleApiResponseErrorMessage({
                        response: err,
                        container: 'store-category-flash',
                        defaultMessage: 'There was an error while trying to fetch products.',
                        accessDeniedMessage: 'You don\'t have the necessary permission to list products.',
                    });
                })
                .finally(() => vm.loading = false)
            ;
        };

        /**
         * Opens the shop categories menu aside
         */
        vm.openShopCategoriesMenu = function () {
            vm.categoriesMenu = $aside({
                title: 'Categories Menu',
                template: require('./categories.menu.tpl.html'),
                controller: angular.noop,
                placement: 'left',
                animation: 'am-fade-and-slide-left',
                scope: vm,
                show: true
            });
        };

        /**
         * Navigates to the given product category page
         *
         * @param  {string}  category  The category
         */
        vm.goToCategory = function (category) {
            vm.categoriesMenu.hide();
            $state.go('store-category', { slug: category.slug });
        };

        /**
         * Navigates to the given product vendor page
         *
         * @param  {string}  vendor  The vendor
         */
        vm.goToVendor = function (vendor) {
            vm.categoriesMenu.hide();
            $state.go('store-vendor', { slug: vendor.slug });
        };

        /**
         * Navigates to the correct product page based on its type
         *
         * @param  {object}  product   The product
         * @param  {object}  category  The category
         */
        vm.goToProduct = function (product, category) {
            switch (product.type) {
                case 'amazon':
                    $window.open(product.link, '_blank');
                break;

                // case 'vendor':
                //     $state.go('store-vendor', { origin: 'category', parent_id: category.id, id: product.id, slug: product.slug });
                // break;

                case 'product':
                default:
                    $state.go('store-product', { origin: 'category', parent_id: category.id, id: product.id, slug: product.slug });
                break;
            }
        };

        vm.init();
    })

    .controller('StoreProductCtrl', function StoreProductCtrl ($aside, $scope, $state, $stateParams, $uibModal, $window, CartService, productResolve, strukshurApiService, strukshurUserService) {
        var vm = $scope;

        vm.quantity = 1;
        vm.parentName = '';
        vm.parentSlug = '';
        vm.showVendors = true;
        vm.headerHeight = '250px';
        vm.similarProducts = [];
        vm.max_val = window.Math.max;
        vm.similarProductsError = false;
        vm.product = productResolve.product;
        vm.pro = strukshurUserService.isPro();
        vm.origin = $stateParams.origin === 'vendor' ? 'vendor' : 'category';
        vm.parentId = parseInt($stateParams.parent_id, 10);

        /**
         * Controller initialization logic
         */
        vm.init = () => {
            vm.setProductParent();

            // Fetch product categories
            strukshurApiService.productCategories.list().$promise.then((data) => vm.categories = data.cats);

            // Fetch product vendors
            strukshurApiService.productVendors.list().$promise.then((data) => vm.vendors = data.vendors);

            // Fetch similar products
            strukshurApiService.product.getSimilar({ id: vm.product.id }).$promise
                .then((data) => vm.similarProducts = data.products)
                .catch(() => vm.similarProductsError = true)
            ;
        };

        /**
         * Opens the shop categories menu aside
         */
        vm.openShopCategoriesMenu = function () {
            vm.categoriesMenu = $aside({
                scope: vm,
                show: true,
                title: 'Categories Menu',
                template: require('./categories.menu.tpl.html'),
                controller: angular.noop,
                placement: 'left',
                animation: 'am-fade-and-slide-left',
            });
        };

        /**
         * Sets the product's parent based on the current state params
         */
        vm.setProductParent = () => {
            if (vm.origin === 'category') {

                let parent = _find(vm.product.productCats, { id: vm.parentId });
                if (!parent) {
                    parent = vm.product.productCats[0];
                }

                vm.parentId = parent.id;
                vm.parentName = parent.title;
                vm.parentSlug = parent.slug;
            } else {
                let parent = _find(vm.product.vendors, { id: vm.parentId });
                if (!parent) {
                    parent = vm.product.vendors[0];
                }

                vm.parentId = parent.id;
                vm.parentName = parent.name;
                vm.parentSlug = parent.slug;
            }
        };

        /**
         * Navigates to the given product category page
         *
         * @param  {string}  category  The category
         */
        vm.goToCategory = function (category) {
            vm.categoriesMenu.hide();
            $state.go('store-category', { slug: category.slug });
        };

        /**
         * Navigates to the given product vendor page
         *
         * @param  {string}  vendor  The vendor
         */
        vm.goToVendor = function (vendor) {
            vm.categoriesMenu.hide();
            $state.go('store-vendor', { slug: vendor.slug });
        };

        /**
         * Navigates to the correct product page based on its type
         *
         * @param  {object}  product   The product
         */
         vm.goToProduct = function (product) {
            switch (product.type) {
                case 'amazon':
                    $window.open(product.link, '_blank');
                break;

                // case 'vendor':
                //     $state.go('store-vendor', { origin: 'category', parent_id: product.productCats[0].id, id: product.id, slug: product.slug });
                // break;

                case 'product':
                default:
                    $state.go('store-product', { origin: 'category', parent_id: product.productCats[0].id, id: product.id, slug: product.slug });
                break;
            }
        };

        /**
         * Decreases product quantity
         */
        vm.decQuantity = () => {
            if (vm.quantity == 1) { return; }

            vm.quantity -= 1;
        };

        /**
         * Increases product quantity
         */
         vm.incQuantity = () => {
            if (vm.product.quantity <= vm.quantity) { return; }

            vm.quantity += 1;
        };

        vm.addItem = () => {
            if (typeof vm.quantity === 'undefined') {
                vm.quantity = 1;
            }

            if (parseInt(vm.quantity)>parseInt(vm.product.quantity)) {
                vm.quantity = vm.product.quantity;
            }

            CartService.addItem(vm.product, vm.quantity);

            const modalInstance = $uibModal.open({
                scope: vm,
                size: 'lg',
                keyboard: true,
                animation: vm.animationsEnabled,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                template: require('../../common/templates/cart-add-modal.tpl.html'),
                controller: 'ProductAddModalCtrl',
                resolve: {}
            });

            modalInstance.result.then(angular.noop, angular.noop);
        };

        vm.init();
    })

    .controller('ProductAddModalCtrl', function ProductAddModalCtrl($scope, $uibModalInstance) {
        var vm = $scope;

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    })

    .controller('CartCtrl', function CartCtrl ($scope, $state, CartService, strukshurUserService, userResolve) {
        var vm = $scope;

        vm.approved = false;
        vm.currentUser = userResolve;
        vm.items = CartService.hasItems();
        vm.pro = strukshurUserService.isPro();
        vm.subtotals = CartService.calculateSubtotal();

        vm.rangeQty = function (count) {
            var ratings = [];
            count = parseInt(count);
            for (var i = 0; i < count; i++) {
                ratings.push(i+1);
            }
            return ratings;
        };

        vm.updateQty = function (item) {
            if (typeof item.quantity === 'undefined') {
                item.quantity = 1;
            }
            CartService.updateQuantity(item);
        };

        vm.removeFromCart = function (item) {
            CartService.removeItem(item);
            vm.items = CartService.hasItems();
            vm.subtotals = CartService.calculateSubtotal();
        };

        vm.sendForApproval = function(){
            vm.approved = true;
            CartService.setApproved();
        };

        vm.checkoutProject = function(){
            $state.go('checkout-project');
        };
    })

    .controller('CheckoutProjectCtrl', function CheckoutProjectCtrl ($scope, $state, $uibModal, CartService, strukshurApiService, strukshurUserService) {
        var vm = $scope;

        vm.items = CartService.hasItems();
        vm.subtotals = CartService.calculateSubtotal();
        vm.pro = strukshurUserService.isPro();
        vm.currentUser = {};

        vm.projects = [];
        vm.existing_project = '';
        vm.max_val = window.Math.max;

        vm.no_project = 0;
        vm.new_project = {
            title: '',
            address1: '',
            address2: '',
            city: '',
            state: '',
            zip: '',
            details: ''
        };

        strukshurUserService.getUser().then(function (d) {
            vm.currentUser = d;
        });

        vm.project = CartService.getProject();
        if (vm.project && vm.project.id) {
            vm.existing_project = vm.project.id;
        } else if (vm.project && vm.project.title) {
            vm.new_project = vm.project;
        } else if (vm.project) {
            vm.no_project = 1;
        }

        strukshurApiService.projectprojects.query({ page: 1, limit: 50, optional_permission: 'purchase_list_item_add' }, function (d) {
            vm.projects = d.projects;
        });

        vm.returnCart = function() {
            $state.go('cart');
        };

        vm.continueAddress = function() {
            var modalInstance;

            if (vm.no_project === 1) {
                CartService.setProject({ id: false });
                $state.go('checkout-address');
            } else if (vm.existing_project !== '') {
                if (vm.form_existing.$invalid) {
                    vm.existing_project_error = true;
                    return;
                }

                CartService.setProject({ id: vm.existing_project });
                $state.go('checkout-address');
            } else {
                if (!strukshurUserService.hasAvailableProjects(vm.currentUser.plan, vm.currentUser.projectsOwned)) {
                    modalInstance = $uibModal.open({
                        animation: true,
                        keyboard: true,
                        scope: vm,
                        ariaLabelledBy: 'modal-title',
                        ariaDescribedBy: 'modal-body',
                        controller: 'UpgradeModalCtrl',
                        template: require('../../common/templates/upgrade-modal.tpl.html'),
                        resolve: {
                        }
                    });
                    return;
                }

                vm.project_name_error = false;
                vm.project_address1_error = false;
                vm.project_address2_error = false;
                vm.project_city_error = false;
                vm.project_state_error = false;
                vm.project_zip_error = false;

                if (vm.form_new.$invalid) {
                    if (vm.new_project.title === '') {
                        vm.project_name_error = true;
                    }

                    if (vm.new_project.address1 === '') {
                        vm.project_address1_error = true;
                    }

                    if (vm.new_project.city === '') {
                        vm.project_city_error = true;
                    }

                    if (vm.new_project.state === '' || vm.form_new.project_state.$invalid) {
                        vm.project_state_error = true;
                    }

                    if (vm.new_project.zip === '' || vm.form_new.project_zip.$invalid) {
                        vm.project_zip_error = true;
                    }

                    if (vm.new_project.details === '') {
                        vm.project_description_error = true;
                    }

                    return;
                }

                CartService.setProject(vm.new_project);
                $state.go('checkout-address');
            }
        };

        vm.toggleForms = function (form) {
            vm.existing_project_error = false;
            vm.project_name_error = false;
            vm.project_description_error = false;

            if (form === 'new') {
                if (vm.new_project.title !== '') {
                    vm.existing_project = '';
                }
            } else if (form === 'existing') {
                vm.new_project.title = '';
                vm.new_project.address1 = '';
                vm.new_project.address2 = '';
                vm.new_project.city = '';
                vm.new_project.state = '';
                vm.new_project.zip = '';
                vm.new_project.details = '';
            } else {
                if (vm.no_project === 1) {
                    vm.new_project.title = '';
                    vm.new_project.address1 = '';
                    vm.new_project.address2 = '';
                    vm.new_project.city = '';
                    vm.new_project.state = '';
                    vm.new_project.zip = '';
                    vm.new_project.details = '';
                    vm.existing_project = '';
                }
            }
        };
    })

    .controller('CheckoutAddressCtrl', function CheckoutAddressCtrl($scope, $state, CartService, strukshurApiService, strukshurUserService) {

        $scope.items = CartService.hasItems();
        $scope.subtotals = CartService.calculateSubtotal();
        $scope.pro = strukshurUserService.isPro();

        $scope.first_name_error = false;
        $scope.last_name_error = false;
        $scope.address1_error = false;
        $scope.city_error = false;
        $scope.state_error = false;
        $scope.zip_error = false;
        $scope.shippingAddress = CartService.getShippingAddress();
        $scope.zipReg = /^\d{5}$/i;
        $scope.stateReg = /^[a-zA-Z]{2}$/i;
        $scope.updatingTax = {};


        $scope.updateTax = function () {
            if ($scope.shippingAddress && $scope.shippingAddress.zip && $scope.shippingAddress.zip.length === 5) {
                if ($scope.updatingTax[$scope.shippingAddress.zip]) {
                    return;
                }

                $scope.updatingTax[$scope.shippingAddress.zip] = true;
                $scope.zip_error = false;
                $scope.tax = CartService.getTaxes($scope.shippingAddress.zip);

                if (typeof $scope.tax === 'undefined') {
                    strukshurApiService.taxes.query({ zip: $scope.shippingAddress.zip }, function (d) {
                        $scope.tax = d.tax;
                        if ($scope.tax === false) {
                            $scope.zip_error = true;
                        } else {
                            $scope.shippingAddress.state = $scope.tax.state;
                            $scope.shippingAddress.city = $scope.tax.city;
                        }

                        CartService.setTaxes($scope.tax, $scope.shippingAddress.zip);
                        $scope.subtotals = CartService.calculateSubtotal();
                        $scope.updatingTax[$scope.shippingAddress.zip] = false;
                    });
                } else {
                    if ($scope.tax === false) {
                        $scope.zip_error = true;
                    } else {
                        $scope.shippingAddress.state = $scope.tax.state;
                        $scope.shippingAddress.city = $scope.tax.city;
                    }

                    $scope.subtotals = CartService.calculateSubtotal();
                    $scope.updatingTax[$scope.shippingAddress.zip] = false;
                }
            }
        };

        $scope.updateTax();

        $scope.returnProject = function () {
            $state.go('checkout-project');
        };

        $scope.continueShipping = function () {
            $scope.city_error = false;
            $scope.state_error = false;
            $scope.address1_error = false;
            $scope.last_name_error = false;
            $scope.first_name_error = false;

            if ($scope.form_address.$invalid) {
                if ($scope.shippingAddress.first_name === '') {
                    $scope.first_name_error = true;
                }
                if ($scope.shippingAddress.last_name === '') {
                    $scope.last_name_error = true;
                }
                if($scope.shippingAddress.address1===''){
                    $scope.address1_error = true;
                }
                if($scope.shippingAddress.city===''){
                    $scope.city_error = true;
                }
                if($scope.shippingAddress.state==='' || !$scope.stateReg.test($scope.shippingAddress.state)){
                    $scope.state_error = true;
                }
                if($scope.shippingAddress.zip==='' || !$scope.zipReg.test($scope.shippingAddress.zip)){
                    $scope.zip_error = true;
                }
                return;
            }

            if ($scope.zip_error === true) {
                if ($scope.shippingAddress.zip==='' || !$scope.zipReg.test($scope.shippingAddress.zip)) {
                    $scope.zip_error = true;
                }
                return;
            }

            CartService.setShippingAddress($scope.shippingAddress);
            $state.go('checkout-shipping');
        };
    })

    .controller('CheckoutShippingCtrl', function CheckoutShippingCtrl($scope, $window, $state, CartService, strukshurApiService, strukshurUserService, userResolve) {

        var i,tot,products = [], user_id, vendor_id, item, data;
        $scope.items = CartService.hasItems();
        $scope.subtotals = CartService.calculateSubtotal();
        $scope.pro = strukshurUserService.isPro();

        $scope.shippingAddress = CartService.getShippingAddress();
        $scope.shippingMethod = CartService.getShippingMethod();
        $scope.shippingMethodSelected = {};
        $scope.options = [];
        $scope.selected_error = false;
        $scope.loading = true;

        tot = $scope.items.length;
        for(i=0;i<tot;i++){
            products.push({id: $scope.items[i].item.id, quantity: $scope.items[i].quantity});
        }

        for(user_id in $scope.shippingMethod){
            if(!$scope.shippingMethod.hasOwnProperty(user_id)){
                continue;
            }

            $scope.shippingMethodSelected[user_id] = $scope.shippingMethod[user_id].carrier+'_'+$scope.shippingMethod[user_id].serviceType+'-'+$scope.shippingMethod[user_id].seller_user_id;
        }

        //calculate shipping methods
        if($scope.shippingAddress.zip) {
            strukshurApiService.shippingMethods.calculate({
                address1: $scope.shippingAddress.address1,
                address2: $scope.shippingAddress.address2,
                city: $scope.shippingAddress.city,
                state: $scope.shippingAddress.state,
                zip: $scope.shippingAddress.zip,
                products: JSON.stringify(products)
            }, function (d) {
                $scope.rates = d.rates;
                $scope.loading = false;


                $scope.options = [];
                for (user_id in $scope.rates.user) {
                    if (!$scope.rates.user.hasOwnProperty(user_id)) {
                        continue;
                    }

                    if (typeof $scope.shippingMethodSelected[user_id] === 'undefined') {
                        $scope.shippingMethodSelected[user_id] = '';
                    }

                    item = $scope.rates.user[user_id];

                    data = {
                        user: item.user,
                        show: false,
                        products: item.products,
                        carriers: []
                    };
                    if (item.user.is_fedex) {
                        if (item.fedex_rate.ground) {
                            data.carriers.push({
                                rate: item.fedex_rate.ground,
                                carrier: 'fedex',
                                seller_user_id: user_id,
                                serviceType: 'ground',
                                shippingPrice: $scope.calculatePrice(user_id, 'ground')
                            });
                        }
                        if (item.fedex_rate['2day']) {
                            data.carriers.push({
                                rate: item.fedex_rate['2day'],
                                carrier: 'fedex',
                                seller_user_id: user_id,
                                serviceType: '2day',
                                shippingPrice: $scope.calculatePrice(user_id, '2day')
                            });
                        }
                        if (item.fedex_rate.overnight) {
                            data.carriers.push({
                                rate: item.fedex_rate.overnight,
                                carrier: 'fedex',
                                seller_user_id: user_id,
                                serviceType: 'overnight',
                                shippingPrice: $scope.calculatePrice(user_id, 'overnight')
                            });
                        }
                    }
                    if (item.user.is_usps) {
                        if (item.usps_rate.ground) {
                            data.carriers.push({
                                rate: item.usps_rate.ground,
                                carrier: 'usps',
                                seller_user_id: user_id,
                                serviceType: 'ground',
                                shippingPrice: $scope.calculatePrice(user_id, 'ground')
                            });
                        }
                        if (item.usps_rate['2day']) {
                            data.carriers.push({
                                rate: item.usps_rate['2day'],
                                carrier: 'usps',
                                seller_user_id: user_id,
                                serviceType: '2day',
                                shippingPrice: $scope.calculatePrice(user_id, '2day')
                            });
                        }
                        if (item.usps_rate.overnight) {
                            data.carriers.push({
                                rate: item.usps_rate.overnight,
                                carrier: 'usps',
                                seller_user_id: user_id,
                                serviceType: 'overnight',
                                shippingPrice: $scope.calculatePrice(user_id, 'overnight')
                            });
                        }
                    }
                    if (item.user.is_ups) {
                        if (item.ups_rate.ground) {
                            data.carriers.push({
                                rate: item.ups_rate.ground,
                                carrier: 'ups',
                                seller_user_id: user_id,
                                serviceType: 'ground',
                                shippingPrice: $scope.calculatePrice(user_id, 'ground')
                            });
                        }
                        if (item.ups_rate['2day']) {
                            data.carriers.push({
                                rate: item.ups_rate['2day'],
                                carrier: 'ups',
                                seller_user_id: user_id,
                                serviceType: '2day',
                                shippingPrice: $scope.calculatePrice(user_id, '2day')
                            });
                        }
                        if (item.ups_rate.overnight) {
                            data.carriers.push({
                                rate: item.ups_rate.overnight,
                                carrier: 'ups',
                                seller_user_id: user_id,
                                serviceType: 'overnight',
                                shippingPrice: $scope.calculatePrice(user_id, 'overnight')
                            });
                        }
                    }
                    $scope.options.push(data);
                }

                for (vendor_id in $scope.rates.vendor) {
                    if (!$scope.rates.vendor.hasOwnProperty(vendor_id)) {
                        continue;
                    }
                    CartService.setVendorShippingMethod(vendor_id, $scope.rates.vendor[vendor_id].deliveryPrice);
                }
                $scope.subtotals = CartService.calculateSubtotal();
            }, function (e) {
                if (e && e.data && typeof e.data.message === 'string' && e.data.message.indexOf('Invalid address') === 0 && e.status === 422) {
                    $scope.selected_error = e.data.message;
                }
                else if (e && e.data && e.data.message) {
                    $scope.selected_error = 'A shipping method is missing'; //e.data.message;
                }
            });
        }

        $scope.calculatePrice = function (user_id, serviceType) {
            var i, tot, shipping = 0;
            tot = $scope.rates.user[user_id].products.length;
            for(i=0;i<tot;i++){
                if(serviceType==='ground'){
                    shipping += parseFloat($scope.rates.user[user_id].products[i].product.shipping)*$scope.rates.user[user_id].products[i].quantity;
                }
                else if(serviceType==='2day'){
                    if($scope.rates.user[user_id].products[i].product.enable_shipping_2day){
                        shipping += parseFloat($scope.rates.user[user_id].products[i].product.shipping_2day)*$scope.rates.user[user_id].products[i].quantity;
                    }
                    else{
                        shipping += parseFloat($scope.rates.user[user_id].products[i].product.shipping)*$scope.rates.user[user_id].products[i].quantity;
                    }
                }
                else if(serviceType==='overnight'){
                    if($scope.rates.user[user_id].products[i].product.enable_shipping_overnight){
                        shipping += parseFloat($scope.rates.user[user_id].products[i].product.shipping_overnight)*$scope.rates.user[user_id].products[i].quantity;
                    }
                    else{
                        shipping += parseFloat($scope.rates.user[user_id].products[i].product.shipping)*$scope.rates.user[user_id].products[i].quantity;
                    }
                }
            }
            return shipping;
        };


        $scope.updateSubtotals = function (selected) {

            $scope.selected_error = false;
            CartService.setShippingMethod(selected.carrier, selected.seller_user_id, selected.serviceType, selected.shippingPrice);
            $scope.subtotals = CartService.calculateSubtotal();
        };


        $scope.returnAddress = function(){
            $state.go('checkout-address');
        };
        $scope.continuePayment = function(){
            $scope.selected_error = false;

            for (user_id in $scope.rates.user) {
                if (!$scope.rates.user.hasOwnProperty(user_id)) {
                    continue;
                }

                if(!$scope.shippingMethodSelected[user_id]){
                    $scope.selected_error = true;
                    break;
                }
            }

            if ($scope.selected_error) {
                return;
            }

            $state.go('checkout-payment');
        };
    })

    .controller('CheckoutPaymentCtrl', function CheckoutPaymentCtrl($scope, $window, $state, CartService, strukshurApiService, strukshurUserService, userResolve, $uibModal) {

        var i,i2, tot, tot2, stripe, elements, card;
        $scope.items = CartService.hasItems();
        $scope.subtotals = CartService.calculateSubtotal();
        $scope.shippingMethod = CartService.getShippingMethod();
        $scope.vendorShippingMethod = CartService.getVendorShippingMethod();
        $scope.address = CartService.getShippingAddress();
        $scope.project = CartService.getProject();
        $scope.pro = strukshurUserService.isPro();
        $scope.order_id = '';

        $scope.loading = false;
        $scope.paymentError = false;
        $scope.paymentErrorMessage = '';
        $scope.needs_card = true; //always needs a card
        $scope.needs_vendors = false;
        $scope.vendors = {};
        $scope.isPro = (userResolve.userType==='pro');
        $scope.userResolve = userResolve;

        tot = $scope.items.length;
        if(tot===0){
            $state.go('store');
            return;
        }
        for(i=0;i<tot;i++){
            tot2 = $scope.items[i].item.vendors.length;
            if(tot2===0) {
                $scope.needs_card = true;
            }
            for(i2=0;i2<tot2;i2++){
                if(!$scope.vendors[$scope.items[i].item.vendors[i2].id]){
                    $scope.vendors[$scope.items[i].item.vendors[i2].id] = angular.copy($scope.items[i].item.vendors[i2]);
                    $scope.needs_vendors = true;
                }
            }
        }

        $scope.formAccount = function () {
            var plaidHandler = $window.Plaid.create({
                env: strukshurConfig.env === 'production' ? 'production' : 'sandbox',
                clientName: 'Stripe/Plaid Test',
                key: strukshurConfig.plaid_key,
                product: ['auth'],
                selectAccount: true,
                onSuccess: function(public_token, metadata) {
                    // Send the public_token and account ID to your app server.
                    console.log('public_token: ' + public_token);
                    console.log('account ID: ' + metadata.account_id);
                },
                onExit: function(err, metadata) {
                    // The user exited the Link flow.
                    if (err != null) {
                        // The user encountered a Plaid API error prior to exiting.
                    }
                }
            });
        };

        $scope.popupUpgradeUser = function () {
            var modalInstance = $uibModal.open({
                animation: true,
                keyboard: false,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                template: require('../../common/templates/account-settings-modal.tpl.html'),
                controller: 'AccountSettingsModalCtrl',
                resolve: {
                    user: function () {
                        return $scope.userResolve;
                    },
                    active_tab: 2 //billing tab
                }
            });

            modalInstance.result.then(
                function () {
                    if (strukshurUserService.isPro() && strukshurUserService.isMissingPayment()) {
                        //deferred.reject('Pro not activated yet.');
                        $state.go('pro-register-payment');
                    }
                },

                function () {
                    //$log.info('Modal dismissed at: ' + new Date());
                }
            );
        };

        if($scope.needs_card){
            stripe = $window.Stripe(strukshurConfig.stripe_key);
            elements = stripe.elements();

            card = elements.create('card', {
                style: {
                    base: {
                        iconColor: '#869657',
                        color: '#31325F',
                        lineHeight: '40px',
                        fontWeight: 300,
                        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                        fontSize: '15px',

                        '::placeholder': {
                            color: '#CFD7E0'
                        }
                    }
                }
            });

            card.mount('#card-element');

            card.on('change', function(event) {
                setOutcome(event);
            });
        }


        $scope.cc = {name: userResolve.fullName, phone: userResolve.phoneNumber};

        function setOutcome(result) {
            var errorElement = document.querySelector('.error');

            errorElement.classList.remove('visible');

            if (result.token) {

                $scope.savePurchase(result.token.id);



            } else if (result.error) {
                errorElement.textContent = result.error.message;
                errorElement.classList.add('visible');
            }
        }


        $scope.formPaymentStore = function(myform) {
            var extraDetails;
            $scope.paymentErrorMessage = '';
            $scope.paymentError = false;

            if(myform.$invalid){
                $scope.paymentErrorMessage = 'All fields are required';
                $scope.paymentError = true;
                return;
            }
            if($scope.needs_card) {
                extraDetails = {
                    name: myform.ccName.$viewValue,
                    phone: myform.ccPhone.$viewValue
                };
                stripe.createToken(card, extraDetails).then(setOutcome);
            }
            else
            {
                $scope.savePurchase();
            }
        };

        $scope.confirmHasVendor = function (vendor) {
            var modalInstance;
            if(vendor.already_has){
                modalInstance = $uibModal.open({
                    animation: true,
                    keyboard: true,
                    scope: $scope,
                    ariaLabelledBy: 'modal-title',
                    ariaDescribedBy: 'modal-body',
                    controller: 'ConfirmHasVendorCtrl',
                    template: require('../../common/templates/confirm-modal.tpl.html'),
                    resolve: {
                        message: function () {
                            return '<div class="alert alert-warning" role="alert"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> The vendor <strong>'+vendor.name+'</strong> will contact you to confirm your account.<br>'+
                                'Also, we\'ll still need your credit card info<br>'+
                                'Are you sure you want to continue?</div>';
                        }
                    }
                });
                modalInstance.result.then(function (selectedItem) {
                    //confirmed
                    vendor.already_has = true;
                    document.getElementById('account_'+vendor.id).focus();
                }, function () {
                    //cancel confirmation
                    vendor.already_has = false;
                    vendor.account = '';
                });
            }
        };

        $scope.savePurchase = function (token_id) {
            var i, tot, products = [], vendorAccounts = [];
            if(typeof token_id ==='undefined'){
                token_id = null;
            }
            tot = $scope.items.length;
            for(i=0;i<tot;i++){
                products.push({id: $scope.items[i].item.id, quantity: $scope.items[i].quantity});
            }
            for(i in $scope.vendors){
                if(!$scope.vendors.hasOwnProperty(i)){
                    continue;
                }
                vendorAccounts.push({id: i, account: $scope.vendors[i].account, already_has: $scope.vendors[i].already_has});
            }

            // Use the token to create a charge
            $scope.loading = true;
            $scope.paymentError = false;
            $scope.paymentErrorMessage = '';
            strukshurApiService.checkoutPaymentCard.save({
                token: token_id,
                products: JSON.stringify(products),
                shipping_method: JSON.stringify($scope.shippingMethod),
                vendor_shipping: JSON.stringify($scope.vendorShippingMethod),
                shipping_address: JSON.stringify($scope.address),
                project: JSON.stringify($scope.project),
                vendorAccounts: JSON.stringify(vendorAccounts),
                order_id: $scope.order_id
            }, function(d) {
                $scope.loading = false;

                if(!$scope.order_id && d.payment.order.id){
                    $scope.order_id = d.payment.order.id;
                }
                if(!$scope.project.id && d.payment.project && d.payment.project.id){
                    $scope.project.id = d.payment.project.id;
                }

                if(d.payment.error){
                    $scope.paymentError = true;
                    $scope.paymentErrorMessage = d.payment.error;
                }
                else if(d.payment.totalPaid>0 && !d.payment.order.paid){
                    $scope.paymentError = true;
                    $scope.paymentErrorMessage = 'Payment not processed. Please try again.';
                }
                else{
                    //clear cart
                    CartService.clearCart();
                    $state.go('store-confirmation');
                }
            }, function (e) {
                console.log('Error');
                console.log(e);
                $scope.loading = false;
                $scope.paymentError = true;
                if(e && e.data && e.data.message){
                    $scope.paymentErrorMessage = e.data.message;
                }
                else{
                    $scope.paymentErrorMessage = 'Unknown Error, please try again';
                }

            });
        };




        $scope.returnShippingMethod = function(){
            $state.go('checkout-shipping');
        };

    })
    .controller( 'CheckoutConfirmationCtrl', function ProRegisterCompleteCtrl( $scope ) {

    })
    .controller('SelectRoomCtrl', function SelectRoomCtrl($scope, $uibModalInstance, idea, strukshurApiService, roomService) {

        $scope.rooms = roomService.getRooms();
        $scope.idea = idea;
        $scope.loading = true;
        $scope.productRoomsError = false;
        $scope.productRooms = {name: 'Select Room', slug:''};
        if($scope.rooms.length===0){
            strukshurApiService.getRooms.query({}, function (d) {
                $scope.rooms = d.rooms;
                roomService.setRooms($scope.rooms);
                $scope.loading = false;
            });
        }
        else{
            $scope.loading = false;
        }

        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.productAdd = function (form) {
            $scope.productRoomsError = false;
            if(!$scope.productRooms.slug || $scope.productRooms.slug==='' || form.$invalid){
                $scope.productRoomsError = true;
                form.$invalid = true;
                return;
            }
            strukshurApiService.getRoomProduct.add({room_slug: $scope.productRooms.slug, product_slug: $scope.idea.slug}, function (d) {
                $uibModalInstance.dismiss('cancel');
            });
        };
    })

    .controller('ConfirmHasVendorCtrl', function ConfirmHasVendorCtrl($scope, $rootScope, $uibModalInstance, strukshurApiService, message) {
        $scope.loading = false;
        $scope.errorMessage = false;
        $scope.title = 'Confirm';
        $scope.message = message;
        $scope.confirm = function () {
            var data;
            if($scope.loading){
                return;
            }
            $scope.loading = true;
            $scope.errorMessage = false;

            $uibModalInstance.close(true);
        };
        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    })

    .controller('MyOrdersCtrl', function MyOrdersCtrl($scope, $state, $stateParams, $window, productCategoriesService, vendorService, strukshurApiService, CartService) {

        $scope.perPage = 10;
        $scope.page = ($stateParams.page?parseInt($stateParams.page):1);
        $scope.pages = [];
        $scope.total = 0;
        $scope.terms = {
            status: ['processing', 'pending']
        };
        $scope.orders = [];
        $scope.strukshurFee = CartService.getStrukshurFee();

        if(!$stateParams.terms || $stateParams.terms==='all'){
            $scope.terms.status = [];
        }
        else if($stateParams.terms==='active'){
            $scope.terms.status = ['processing', 'pending'];
        }
        else if($stateParams.terms==='completed'){
            $scope.terms.status = ['completed', 'refunded'];
        }
        else{
            $scope.terms.status = [$stateParams.terms];
        }
        $scope.menuSelected = $stateParams.terms;

        $scope.totalSuborderBuyerList = function (order) {
            var i, tot, amount = 0.0;

            tot = order.suborder.productOrders.length;
            for(i=0;i<tot;i++){
                amount = amount + (parseFloat(order.suborder.productOrders[i].price)*parseFloat(order.suborder.productOrders[i].quantity)) + parseFloat(order.suborder.productOrders[i].tax) + parseFloat(order.suborder.productOrders[i].shippingPrice);
            }
            return Math.round(amount*(1+$scope.strukshurFee)*100)/100;
        };


        $scope.searchOrders = function() {
            strukshurApiService.getMyOrders.query({page: $scope.page, terms: JSON.stringify($scope.terms)}, function(d) {

                var i, tot;
                $scope.total = d.total;
                $scope.perPage = d.perPage;
                $scope.orders = d.orders;

                $scope.pages = [];
                tot = Math.ceil($scope.total / $scope.perPage);
                for(i=0;i<tot;i++){
                    $scope.pages.push(i+1);
                }
                //$scope.page++;
                $scope.totalPages = $scope.calculateTotalPages();

            }, function(e) {

                return false;
            });
        };


        $scope.noNext = function() {
            return Math.ceil($scope.total / $scope.perPage) <= $scope.page;
        };

        $scope.noPrevious = function() {
            return 1 >= $scope.page;
        };

        $scope.calculateTotalPages = function() {
            var totalPages = $scope.perPage < 1 ? 1 : Math.ceil($scope.total / $scope.perPage);
            return Math.max(totalPages || 0, 1);
        };

        // start fetching data
        $scope.searchOrders();
    })
    .controller('MyOrderDetailCtrl', function MyOrderDetailCtrl($scope, $state, $stateParams, $timeout, $uibModal, strukshurApiService, InboxService, CartService, strukshurUserService ) {

        $scope.suborder_id = $stateParams.suborder_id;
        $scope.order = {};
        $scope.suborder = {productOrders:[]};
        $scope.charges = [];
        $scope.isUrl = InboxService.isUrl;
        $scope.messages = [];
        $scope.page = 0;
        $scope.savingOrder = false;
        $scope.orderStatus = '';
        $scope.confirmMessage = false;
        $scope.quantityEdit = false;
        $scope.strukshurFee = CartService.getStrukshurFee();
        $scope.grand_total = 0;
        $scope.hasRefund = false;
        $scope.hasReturn = false;
        $scope.currentUser = {};
        strukshurUserService.getUser().then(function (d) {
            $scope.currentUser = d;
            $scope.isAdmin = strukshurUserService.isAdmin();
        });

        $scope.errorMessage = false;
        $scope.loading = false;
        $scope.uploading = false;
        $scope.timeDelay = 100000;
        //timeout chat sync
        $scope.timeoutPromise = false;

        $scope.getNextPageOrderMessage = function () {
            //timeout chat sync
            if($scope.timeoutPromise){
                $timeout.cancel($scope.timeoutPromise);
                $scope.timeoutPromise = false;
            }
            $scope.page++;
            $scope.loading = true;
            strukshurApiService.thread.query({thread_id: $scope.suborder.thread_id, last_message_id: 0, page: $scope.page}, function (d) {
                var i, tot, date;
                $scope.loading = false;
                $scope.total = d.total;
                tot = d.messages.length;
                for(i=0;i<tot;i++){
                    date = new Date(d.messages[i].creationDate);
                    d.messages[i].dayString = String(date.getYear())+String(date.getMonth())+String(date.getDay());
                    $scope.messages.push(d.messages[i]);
                }
                //timeout chat sync
                $scope.timeoutPromise = $timeout($scope.getPreviousOrderMessage, $scope.timeDelay);
            }, function (e) {
                $scope.loading = false;
                $scope.errorMessage = e.message;
            });
        };
        $scope.getPreviousOrderMessage = function (showLoad) {
            var first_message_id = 0;
            if(typeof showLoad==='undefined'){
                showLoad = false;
            }
            //timeout chat sync
            if($scope.timeoutPromise){
                $timeout.cancel($scope.timeoutPromise);
                $scope.timeoutPromise = false;
            }
            if(!document.getElementById('main_chat_container_my_order_'+$scope.suborder_id)){ //so when it goes to another page it stops loading
                return;
            }
            if($scope.messages && $scope.messages.length>0){
                first_message_id = $scope.messages[0].id;
            }
            if(showLoad){
                $scope.loading = true;
            }

            strukshurApiService.thread.query({thread_id: $scope.suborder.thread_id, last_message_id: first_message_id, page: 0,ignoreLoadingBar: true}, function (d) {
                var i, tot, date;
                $scope.loading = false;
                $scope.total = d.total;
                tot = d.messages.length;
                for(i=tot-1;i>=0;i--){
                    date = new Date(d.messages[i].creationDate);
                    d.messages[i].dayString = String(date.getYear())+String(date.getMonth())+String(date.getDay());
                    $scope.messages.unshift(d.messages[i]);
                }
                //timeout chat sync
                $scope.timeoutPromise = $timeout($scope.getPreviousOrderMessage, $scope.timeDelay);
            }, function (e) {
                $scope.loading = false;
                $scope.errorMessage = e.message;
            });
        };

        $scope.sendMessageOrder = function () {
            if($scope.loading){
                return;
            }
            //timeout chat sync
            if($scope.timeoutPromise){
                $timeout.cancel($scope.timeoutPromise);
                $scope.timeoutPromise = false;
            }
            $scope.loading = true;
            $scope.errorMessage = false;
            strukshurApiService.message.save({thread_id: $scope.suborder.thread_id, message: $scope.newMessage}, function (d) {
                $scope.loading = false;
                $scope.errorMessage = false;
                $scope.newMessage = '';
                $scope.getPreviousOrderMessage(true);
            }, function (e) {
                $scope.loading = false;
                $scope.errorMessage = e.data.message;
            });
        };

        $scope.uploadMessageOrder = function () {
            $scope.uploading = true;

            var data = {
                suborder_id: $scope.suborder_id,
                file: 'data:'+$scope.attach_file.filetype+';base64,'+$scope.attach_file.base64,
                filename: $scope.attach_file.filename,
                type: 'DEFAULT'
            };

            strukshurApiService.suborderFiles.create(data).$promise
                .then(function (res) {
                    $scope.uploading = false;
                    $scope.attach_file = null;
                    $scope.newMessage = res.suborderFile.file;
                    $scope.sendMessageOrder();
                })
                .catch(function () {
                    $scope.uploading = false;
                })
            ;
        };

        $scope.getStatus = function (status) {
            if(status==='failed'){
                return 'Failed';
            }
            else if(status==='processing'){
                return 'Processing';
            }
            else if(status==='completed'){
                return 'Completed';
            }
            else if(status==='cancelled'){
                return 'Cancelled';
            }
            else if(status==='refunded'){
                return 'Refunded';
            }
            else{
                return 'Pending payment';
            }
        };

        $scope.getStatusDescription = function (status) {
            if(status==='failed'){
                return 'Payment failed or was declined (unpaid). Note that this status may not show immediately and instead show as Pending until verified';
            }
            else if(status==='processing'){
                return 'Payment received and stock has been reduced - the order is awaiting fulfillment. All product orders require processing, except those that are Digital and Downloadable.';
            }
            else if(status==='completed'){
                return 'Order fulfilled and complete - requires no further action';
            }
            else if(status==='cancelled'){
                return 'Cancelled by an admin or the customer - no further action required (Cancelling an order does not affect stock quantity by default)';
            }
            else if(status==='refunded'){
                return 'Refunded by an admin - no further action required';
            }
            else{
                return 'Awaiting payment - stock is reduced, but you need to confirm payment (unpaid)';
            }
        };

        $scope.totalSuborderBuyer = function (productOrders) {
            var i, tot, amount = 0.0, returned = 0.0;

            if(!productOrders || productOrders.length===0){
                return amount;
            }
            tot = productOrders.length;
            for(i=0;i<tot;i++){
                if(parseInt(productOrders[i].quantity)===0){
                    continue;
                }
                amount = amount + (parseFloat(productOrders[i].price)*parseFloat(productOrders[i].quantity)) + parseFloat(productOrders[i].tax) + parseFloat(productOrders[i].shippingPrice);
                if(productOrders[i].amountReturned){
                    returned = returned + parseFloat(productOrders[i].amountReturned);
                }
            }
            return (Math.round((amount+($scope.suborder.shippingVendor?$scope.suborder.shippingVendor:0))*(1+$scope.strukshurFee)*100)/100)-returned;
        };

        $scope.calculateChargesBuyer = function () {
            var i, tot, amount = 0.0;

            if(!$scope.charges || $scope.charges.length===0){
                return amount;
            }
            tot = $scope.charges.length;
            for(i=0;i<tot;i++){
                amount = amount + parseFloat($scope.charges[i].amount);
                if($scope.charges[i].refund){
                    amount = amount - parseFloat($scope.charges[i].refund.totConnect) - parseFloat($scope.charges[i].refund.totStrukshur);
                }
            }
            return Math.round(amount*100)/100;
        };

        $scope.reOrder = function () {

        };


        strukshurApiService.myOrderDetails.query({suborder_id: $scope.suborder_id}, function(d) {
            var tot, i;
            $scope.order = d.order;
            $scope.suborder = d.suborder;
            $scope.charges = d.charges;
            $scope.suborder.creationDate = new Date($scope.suborder.creationDate);
            $scope.orderStatus = $scope.suborder.status;

            $scope.grand_total = $scope.totalSuborderBuyer($scope.suborder.productOrders);
            $scope.total_charge = $scope.calculateChargesBuyer();

            tot = $scope.charges.length;
            for(i=0;i<tot;i++){
                if($scope.charges[i].refund && $scope.charges[i].refund.totConnect+$scope.charges[i].refund.totStrukshur>0){
                    $scope.hasRefund = true;
                    break;
                }
            }
            tot = $scope.suborder.productOrders.length;
            for(i=0;i<tot;i++){
                if($scope.suborder.productOrders[i].quantityReturned){
                    $scope.hasReturn = true;
                    break;
                }
            }

            $scope.getNextPageOrderMessage();

        }, function(e) {

            return false;
        });
    })
;
