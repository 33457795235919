import * as angular from 'angular';
import strukshurConfig from '../../config.ts';

angular.module('strukshurAuthUserService', ['angularLocalStorage'])
    .factory('strukshurAuthUserService', function ($http, $httpParamSerializer, $q, $rootScope, $state, $window, roomService, storage, strukshurApiService, strukshurUtilService) {

        // Defining user logged status
        var userDetails = {};
        var authloggedin = false;
        var userIsConnectedFacebook = false;

        function isEmpty(obj) {
            for (var key in obj) {
                if (obj.hasOwnProperty(key)) {
                    return false;
                }
            }

            return true;
        }

        function redirectAfterAuthSuccess(role, slug) {

            // Redirects user to previously accessed url if one was given
            if ($rootScope.originalUrl !== '' && $rootScope.originalPathname !== '/' && $rootScope.originalPathname !== '/home') {
                window.location.href = $rootScope.originalUrl;
            } else {
                var redirectUrl = $state.get('ideas').url;
                if (role === 'pro') {
                    redirectUrl = $state.get('pros-profile').url.replace(':userSlug', slug);
                }
                window.location.href = redirectUrl;
            }
        }

        function redirectAfterAuthFail(userType) {
            if (userType === 'pro') {
                $state.go('register-auth-pro');
            } else {
                $state.go('register-auth');
            }
        }

        return {
            getLoginStatus: function() {
                // Facebook.getLoginStatus(function(response) {
                //     if (response.status === 'connected') {
                //         userIsConnectedFacebook = true;
                //     }
                // });
            },

            setUser: function(user) {
                userDetails = user;

                return userDetails;
            },

            getUser: function() {
                return userDetails;
            },

            setJwt: function(token) {
                storage.set('jwt', token);
                strukshurApiService.setJwt(token);
            },

            getAuthLoggedin: function () {
                return authloggedin;
            },

            isLoggedIn: function() {
                return !isEmpty(userDetails);
            },

            removeUser: function() {
                authloggedin = false;
                userIsConnectedFacebook = false;

                if (userDetails instanceof Array) {
                    userDetails.splice(0, userDetails.length);
                } else {
                    userDetails = {};
                }
            },

            getFacebookUserId: function () {
                return userDetails['facebook'] !== undefined && userDetails['facebook']['auth'] !== undefined && userDetails['facebook']['auth'].id !== undefined ? userDetails['facebook']['auth'].id : '';
            },

            getFacebookAccessToken: function () {
                return userDetails['facebook'] !== undefined && userDetails['facebook']['accessToken'] !== undefined ? userDetails['facebook']['accessToken'] : '';
            },

            getPinterestUserId: function () {
                return userDetails['pinterest'] !== undefined && userDetails['pinterest']['auth'] !== undefined && userDetails['pinterest']['auth'].id !== undefined ? userDetails['pinterest']['auth'].id : '';
            },

            getPinterestAccessToken: function () {
                return userDetails['pinterest'] !== undefined && userDetails['pinterest']['accessToken'] !== undefined ? userDetails['pinterest']['accessToken'] : '';
            },

            hasAccessToken: function () {
                var _this = this;
                return _this.getFacebookAccessToken() !== '' || _this.getPinterestAccessToken() !== '';
            },

            getEitherAccessToken: function () {
                var _this = this;
                if (_this.getFacebookAccessToken() !== '') {
                    return _this.getFacebookAccessToken();
                }

                if (_this.getPinterestAccessToken() !== '') {
                    return _this.getPinterestAccessToken();
                }

                return '';
            },

            getAuthConnectionType: function () {
                var _this = this;

                if (_this.getFacebookAccessToken() !== '') {
                    return 'facebook';
                }

                if (_this.getPinterestAccessToken() !== '') {
                    return 'pinterest';
                }

                return '';
            },

            getUserFirstName: function () {
                console.log('checking userDetails first');
                console.log(userDetails);
                if (userDetails['pinterest'] !== undefined && userDetails['pinterest']['auth'] !== undefined && userDetails['pinterest']['auth'].first_name !== undefined) {
                    return userDetails['pinterest']['auth'].first_name;
                } else {
                    var fullName = userDetails['facebook'] !== undefined && userDetails['facebook']['auth'] !== undefined && userDetails['facebook']['auth'].name !== undefined ? userDetails['facebook']['auth'].name : '';

                    return fullName.split(' ').slice(0, -1).join(' ');
                }
            },

            getUserLastName: function () {
                console.log('checking userDetails last_name');
                console.log(userDetails);
                if (isEmpty(userDetails)) {
                    return '';
                }

                if (userDetails['pinterest'] !== undefined && userDetails['pinterest']['auth'] !== undefined && userDetails['pinterest']['auth'].last_name !== undefined) {
                    return userDetails['pinterest']['auth'].last_name;
                } else {
                    var fullName = userDetails['facebook']['auth'] !== undefined && userDetails['facebook']['auth'].name !== undefined ? userDetails['facebook']['auth'].name : '';

                    return fullName.split(' ').slice(-1).join(' ');
                }
            },

            getUserEmail: function () {
                var userEmail = userDetails['facebook'] !== undefined && userDetails['facebook']['auth'].email !== undefined ? userDetails['facebook']['auth'].email : '';

                return userEmail;
            },

            redirectLogin: function () {
                var stateCurrent = storage.get('saveStateCurrent');
                var stateParams = storage.get('saveStateParams');
                if (stateCurrent !== '') {
                    stateCurrent = JSON.parse(stateCurrent);
                }

                if (stateParams !== '') {
                    stateParams = JSON.parse(stateParams);
                }

                // clear redirect state
                storage.set('saveStateCurrent', '');
                storage.set('saveStateParams', '');

                if (stateCurrent !== null && stateCurrent !== undefined && stateCurrent.name !== undefined && stateCurrent !== '') {
                    if (stateCurrent.name === 'home') {
                        $state.go('home');
                    } else {
                        // reload to keep user in same state
                        $state.go(stateCurrent.name, stateParams, {reload: true});
                    }
                } else {
                    console.log(stateCurrent);
                    console.log(stateParams);
                    $state.go('setup-interior', {welcome: true});
                }
            },

            logout: function () {
                $window.PDK.logout({}, angular.noop);
            },

            login: function (myform) {
                var _this = this;
                console.log('Login click from the service');

                var deferred = $q.defer();

                if (!myform.$valid) {
                    console.log('Login Form Error');
                    //$scope.loading = false;
                    myform.email.$setValidity('invalidlogin', false);
                    deferred.reject('Login Form Error');

                    return deferred.promise;
                }

                var formDataObj = {
                    _username: myform.email.$viewValue,
                    _password: myform._password.$viewValue,
                    _remember_me: true
                };

                $http({
                    method: 'POST',
                    url: strukshurConfig.domain + '/login_check',
                    headers : {
                        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                        'X-Requested-with': 'XMLHttpRequest'
                    },
                    data: $httpParamSerializer(formDataObj)
                })
                    .then(function onSuccess (response) {
                        var data = response.data;

                        // hide errors
                        myform.email.$setValidity('invalidlogin', true);

                        _this.setJwt(data.token);

                        // Reset user rooms' list
                        roomService.setRooms(null);

                        if (_this.getFacebookAccessToken() !== '') {

                            // connect account to facebook
                            strukshurApiService.postAccountLinkFacebook.save({fbid: _this.getFacebookUserId(), fbtoken: _this.getFacebookAccessToken()}, function(d){

                                // account link success
                                deferred.resolve(response);
                            }, function (e) {
                                console.log('error with fb link account');
                                console.log(e);
                                deferred.reject(e);
                            });
                        } else if (_this.getPinterestAccessToken() !== '') {
                            // connect account to facebook
                            strukshurApiService.postAccountLinkPinterest.save({pid: _this.getPinterestUserId(), ptoken: _this.getPinterestAccessToken()}, function(d){

                                // account link success
                                deferred.resolve(response);

                            }, function (e) {
                                console.log('error with pinterest link account');
                                console.log(e);
                                deferred.reject(e);
                            });
                        } else {

                            // get user details
                            // @TODO get user details from original login response
                            strukshurApiService.getUserDetails.query({}, function(data){
                                _this.setUser(data.user);
                                deferred.resolve(data);
                            }, function (e) {
                                console.log('error with getting user details');
                                console.log(e);
                                deferred.reject(e);
                            });
                        }
                    })
                    .catch(function onError(response) {
                        console.log(response);

                        if (response.data && response.data.code == 500 && response.code == 401) {
                            console.log('Server error: ' + response.data.message);
                            deferred.reject();
                        }

                        if (response.data && response.data.errors === undefined) {
                            deferred.reject();
                        }

                        myform.email.$setValidity('invalidlogin', false);
                        deferred.reject('Form Errors');
                    })
                ;

                return deferred.promise;
            },

            subscribePro: function(token, coupon) {
                var deferred = $q.defer();
                if (typeof coupon === 'undefined') {
                    coupon = '';
                }

                strukshurApiService.userSubscription.save({ token: token, coupon: coupon }, function (d) {
                    console.log('Success');
                    console.log(d);
                    deferred.resolve(d);
                }, function(e) {
                    console.log('Error');
                    console.log(e);
                    deferred.reject(e.data.message);
                });

                return deferred.promise;
            },

            registerPro: function(myform) {
                var _this = this;

                console.log('Register pro click from the service');

                var deferred = $q.defer();

                if (!myform.$valid)
                {
                    console.log('Registration pro Form Error');
                    console.log(myform);
                    deferred.reject('Registration pro Form Error');
                    return deferred.promise;
                }

                var accessToken = _this.getEitherAccessToken(); // use part of token to set default password
                var password1 = myform.mypassword1 === undefined ? accessToken.substring(0, 31) : myform.mypassword1.$viewValue;
                var password2 = myform.mypassword2 === undefined ? accessToken.substring(0, 31) : myform.mypassword1.$viewValue;

                var registrationData = {
                    email: myform.email.$viewValue.toLowerCase(),
                    first_name: myform.first_name.$viewValue,
                    last_name: myform.last_name.$viewValue,
                    zip_code: myform.zip_code.$viewValue,
                    category: myform.category,


                    biz_name: myform.biz_name.$viewValue,
                    phone_number: myform.phone_number.$viewValue,
                    fax_number: myform.fax_number.$viewValue,
                    state_lic: myform.state_lic.$viewValue,
                    founded: myform.founded.$viewValue,
                    hours: myform.hours.$viewValue,
                    bbb: myform.bbb.$viewValue,
                    description: myform.description.$viewValue,
                    website: myform.website.$viewValue,
                    fb: myform.fb.$viewValue,
                    yelp: myform.yelp.$viewValue,
                    employees: parseInt(myform.employees.$viewValue),

                    customer_id: myform.customerId,
                    plan: myform.plan_id,

                    facebook_id: _this.getFacebookUserId(),
                    facebook_access_token: _this.getFacebookAccessToken(),
                    pinterest_id: _this.getPinterestUserId(),
                    pinterest_access_token: _this.getPinterestAccessToken(),
                    plainPassword:  {
                        'first': password1,
                        'second': password2
                    }
                };

                $http({
                    method: 'POST',
                    url: strukshurConfig.domain + '/user/register/pro',
                    headers : {'X-Requested-with' : 'XMLHttpRequest'},
                    dataType: 'json',
                    data: {'fos_user_registration_form': registrationData }
                }).then(function onSuccess(response) {
                    //$scope.loading = false;

                    console.log('success');
                    console.log(response);

                    var data = response.data;

                    _this.setJwt(data.token);

                    deferred.resolve(data);

                }).catch(function onError(response) {
                    // $scope.loading = false;
                    console.log('fail');
                    console.log(response);

                    if (response.data.code === '500') {
                        console.log('Server error: ' + response.data.message);
                        deferred.reject();
                    }

                    if (response.data.errors === undefined) {
                        deferred.reject();
                        return;
                    }

                    myform.first_name.$setValidity('invalid', response.data.errors.first_name === undefined);
                    myform.last_name.$setValidity('invalid', response.data.errors.last_name === undefined);
                    myform.email.$setValidity('duplicate', response.data.errors.email === undefined);
                    myform.zip_code.$setValidity('invalidzip', response.data.errors.zip_code === undefined);

                    if (myform.mypassword1 !== undefined) {
                        myform.mypassword1.$setValidity('invalid', response.data.errors.password_match === undefined && response.data.errors.password_length === undefined);
                        myform.mypassword1.$setValidity('match', response.data.errors.password_match === undefined);
                        myform.mypassword1.$setValidity('pwlength', response.data.errors.password_length === undefined);
                    }


                    deferred.reject('Form Errors');
                });

                return deferred.promise;
            },

            register: function (myform, login) {
                var _this = this,
                    doLogin = login || false;

                console.log('Register click from the service');

                var deferred = $q.defer();

                if (!myform.$valid) {
                    console.log('Registration Form Error');
                    console.log(myform);
                    deferred.reject('Registration Form Error');

                    return deferred.promise;
                }

                var accessToken = _this.getEitherAccessToken(); // use part of token to set default password
                var password1 = myform.mypassword1 === undefined ? accessToken.substring(0, 31) : myform.mypassword1.$viewValue;
                var password2 = myform.mypassword2 === undefined ? accessToken.substring(0, 31) : myform.mypassword1.$viewValue;
                var registrationData: any = {
                    email: myform.email.$viewValue.toLowerCase(),
                    first_name: myform.first_name.$viewValue,
                    last_name: myform.last_name.$viewValue,
                    zip_code: myform.zip_code.$viewValue,
                    phone_number: myform.phone_number.$viewValue,
                    facebook_id: _this.getFacebookUserId(),
                    facebook_access_token: _this.getFacebookAccessToken(),
                    pinterest_id: _this.getPinterestUserId(),
                    pinterest_access_token: _this.getPinterestAccessToken(),
                    plainPassword:  {
                        'first': password1,
                        'second': password2
                    }
                };

                if (myform.plan_id) {
                    registrationData.plan = myform.plan_id;
                }

                $http({
                    method: 'POST',
                    url: strukshurConfig.domain + '/user/register',
                    headers : {'X-Requested-with' : 'XMLHttpRequest'},
                    dataType: 'json',
                    data: {'fos_user_registration_form': registrationData }
                }).then(function onSuccess (response) {
                    console.log('success');
                    console.log(response);

                    var data = response.data;

                    // Also logs the user in after a successful registration
                    if (doLogin) {
                        _this.setJwt(data.token);

                        // get user details
                        strukshurApiService.getUserDetails.query({}).$promise
                            .then(function (res) {
                                _this.setUser(res.user);
                                deferred.resolve(data);
                            })
                            .catch(function (e) {
                                deferred.reject(e);
                            })
                        ;
                    } else {
                        deferred.resolve(data);
                    }
                }).catch(function onError (response) {
                   // $scope.loading = false;
                    console.log('fail');
                    console.log(response);

                    if (response.data.code === '500') {
                        console.log('Server error: ' + response.data.message);
                        deferred.reject();
                    }

                    if (response.data.errors === undefined) {
                        deferred.reject();
                        return;
                    }

                    myform.first_name.$setValidity('invalid', response.data.errors.first_name === undefined);
                    myform.last_name.$setValidity('invalid', response.data.errors.last_name === undefined);
                    myform.email.$setValidity('duplicate', response.data.errors.email === undefined);
                    myform.zip_code.$setValidity('invalidzip', response.data.errors.zip_code === undefined);

                    if (myform.mypassword1 !== undefined) {
                        myform.mypassword1.$setValidity('invalid', response.data.errors.password_match === undefined && response.data.errors.password_length === undefined);
                        myform.mypassword1.$setValidity('match', response.data.errors.password_match === undefined);
                        myform.mypassword1.$setValidity('pwlength', response.data.errors.password_length === undefined);
                    }

                    deferred.reject('Form Errors');
                });

                return deferred.promise;
            },

            resetPassword: function (myform) {
                var deferred = $q.defer();

                if (!myform.$valid) {
                    console.log('resetPassword Form Error');
                    myform.mypassword1.$setValidity('invalidlogin', false);
                    deferred.reject('Login Form Error');

                    return deferred.promise;
                }

                var resetPasswordData = {
                    token: myform.token,
                    'fos_user_resetting_form': {
                        plainPassword:  {
                            'first': myform.mypassword1.$viewValue,
                            'second': myform.mypassword2.$viewValue
                        }
                    }
                };

                $http({
                    method: 'POST',
                    dataType: 'json',
                    data: resetPasswordData,
                    headers: { 'X-Requested-with' : 'XMLHttpRequest' },
                    url: strukshurConfig.domain + '/user/password/' + myform.token + '/reset'
                }).then(function onSuccess(response) {
                    console.log('Success');
                    deferred.resolve(response);
                }).catch(function onError(response) {
                    console.log(response);
                    deferred.reject('Form Errors');
                });

                return deferred.promise;
            },

            userRedirect: function (user) {
                redirectAfterAuthSuccess(user.userType, user.slug);
            },

            isUserConnectedFacebook: function(userType) {
                // if (userType === undefined) {
                //     userType = 'standard';
                // }

                // if (!userIsConnectedFacebook) {
                //     this.facebookAuth(userType);
                // }
            },

            facebookMe: function (accessToken, userType) {
                var _this = this;

                // Facebook.api('/me?fields=name,email', function(response) {
                //     console.log('FB me response');
                //     console.log(response);
                //
                //     // @TODO storing user details is confusing!! need to fix this
                //
                //     var userDetails = [];
                //     //userDetails['me'] = response;
                //     //userDetails['accessToken'] = accessToken;
                //     userDetails['facebook'] = [];
                //     userDetails['facebook']['auth'] = response;
                //     userDetails['facebook']['accessToken'] = accessToken;
                //
                //     _this.setUser(userDetails);
                //
                //     // check if this user has registered
                //     strukshurApiService.userAuthFacebookCheck.query({ fbid: response.id, fbtoken: accessToken }, function(data) {
                //         //console.log(data);
                //
                //         // @TODO token fail
                //         if (data.token !== undefined) {
                //             _this.setJwt(data.token);
                //         }
                //
                //         strukshurUserService.setUser(data.user);
                //
                //         // user is already registered using fb
                //         // redirect to original state
                //         //_this.redirectLogin();
                //
                //         //$state.go('setup-interior', {userSlug: data.user.slug});
                //         redirectAfterAuthSuccess(data.user.userType, data.user.slug);
                //     }, function(e) {
                //         //console.log(e);
                //         // fb id not found, ask to register or link account
                //         //console.log('checking user email to see if it exists');
                //
                //         // linking social account
                //
                //         strukshurApiService.userEmailCheck.query({email: _this.getUserEmail()}, function (d) {
                //             console.log('email was found. strukshur account exists');
                //             console.log(d);
                //
                //             // @TODO not sure what to do with pro linking accounts
                //             $state.go('register-link');
                //         }, function(e) {
                //             console.log('email not found');
                //             console.log(e);
                //
                //             redirectAfterAuthFail(userType);
                //         });
                //     });
                // });
            },

            facebookAuth: function(userType) {
                var _this = this;

                // // @TODO check which service to connect
                // Facebook.login(
                //     function(response) {
                //         console.log('FB response');
                //         console.log(response);
                //
                //         // response.authResponse.accessToken
                //         //console.log(response.authResponse.accessToken);
                //
                //         if (response.authResponse !== null) {
                //             var accessToken = response.authResponse.accessToken;
                //
                //             // response.authResponse.userID
                //             //console.log(response.authResponse.userID);
                //             if (response.status == 'connected') {
                //                 authloggedin = true;
                //                 _this.facebookMe(accessToken, userType);
                //             }
                //         }
                //     },
                //     { scope: 'email' }
                // );
            }
        };
    })
;
