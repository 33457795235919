import {
    find as _find,
    map as _map,
    max as _max,
    pull as _pull,
    remove as _remove,
} from 'lodash';
import * as angular from 'angular';
import * as moment from 'moment';

angular.module('strukshurApp.projects.inbox', [])

.config(function config($stateProvider, $uibModalProvider) {
    $uibModalProvider.options = { animation: true, backdrop: 'static', keyboard: false, ariaLabelledBy: 'modal-title', ariaDescribedBy: 'modal-body' };

    $stateProvider
        .state('projects-detail.inbox', {
            url: '/inbox',
            views: {
                '': {
                    controller: 'ProjectDetailInboxCtrl',
                    template: require('./project.inbox.tpl.html')
                },
            },
            resolve: {
                userResolve: function(userRouterResolveService) {
                    return userRouterResolveService.userMustBeLoggedin();
                }
            },
            data: { pageTitle: 'Project / Inbox', class: 'projects projects-detail-inbox' }
        })
    ;
})

.controller('ProjectDetailInboxCtrl', function ProjectDetailInboxCtrl ($scope, $state, $stateParams, strukshurApiService, InboxService, userResolve, $timeout, $uibModal) {
    var vm = $scope;

    // Check if the user can access the current page or not
    if (!$scope.$parent.checkPermission('project_inbox_view') && !$scope.$parent.projectIsOpenForBidsAndUserCanViewIt()) {
        return $state.go('projects-detail.access-denied', { section: 'Inbox' });
    }

    // Updates current route info on the project menu scope
    vm.$parent.currentRoute = $state.current.name;

    vm.page = 0;
    vm.total = 0;
    vm.channels = [];
    vm.messages = [];
    vm.loading = true;
    vm.newMessage = '';
    vm.uploading = false;
    vm.timeDelay = 10000;
    vm.attach_file = null;
    vm.upgadeLink = false;
    vm.errorMessage = false;
    vm.isUrl = InboxService.isUrl;
    vm.project_id = $stateParams.project_id;
    vm.currentUser = vm.$parent.currentUser;
    vm.selectedChannel = InboxService.getLastChannel(vm.project_id);
    vm.currentUserIsProAndNotMember = vm.$parent.currentUserIsProAndNotMember;

    //timeout chat sync
    vm.timeoutPromise = false;

    /**
     * Checks if the current user has the given permission on the project
     *
     * @param  {String}  permission  The permission key
     *
     * @return {Boolean}
     */
    vm.checkPermission = function (permission) {
        return vm.$parent.checkPermission(permission);
    };

    //get conversation
    vm.getNextPage = function () {

        //timeout chat sync
        if (vm.timeoutPromise) {
            $timeout.cancel(vm.timeoutPromise);
            vm.timeoutPromise = false;
        }

        vm.page++;
        vm.loading = true;
        strukshurApiService.thread.query({ thread_id: vm.selectedChannel.id, last_message_id: 0, page: vm.page }).$promise
            .then(function (res) {
                vm.total = res.total;
                res.messages.forEach(function (message) {
                    message.dayString = moment(message.creationDate).format('YMMDD');

                    // Check if the message already exists before inserting it
                    if (_find(vm.messages, { id: message.id }) === undefined) {
                        vm.messages.push(message);
                    }
                });

                // Timeout chat sync
                vm.timeoutPromise = $timeout(vm.getPrevious, vm.timeDelay);
            })
            .catch(function (e) {
                vm.errorMessage = e.message;
            })
            .finally(function () {
                vm.loading = false;
            })
        ;
    };

    vm.getPrevious = function (showLoad) {
        var last_message_id = 0;

        if (typeof showLoad === 'undefined') {
            showLoad = false;
        }

        //timeout chat sync
        if (vm.timeoutPromise) {
            $timeout.cancel(vm.timeoutPromise);
            vm.timeoutPromise = false;
        }

         // So when it goes to another page it stops loading
        if (!document.getElementById('main_chat_container_' + vm.selectedChannel.id)) {
            return;
        }

        // If the messages list is not empty we find which was the latest message
        if (vm.messages && vm.messages.length > 0) {
            last_message_id = _max(_map(vm.messages, 'id'));
        }

        if (showLoad) {
            vm.loading = true;
        }

        strukshurApiService.thread.query({ thread_id: vm.selectedChannel.id, last_message_id: last_message_id, page: 0 }).$promise
            .then(function (res) {
                vm.total = res.total;
                res.messages.forEach(function (message) {
                    message.dayString = moment(message.creationDate).format('YMMDD');

                    // Check if the message already exists before inserting it
                    if (_find(vm.messages, { id: message.id }) === undefined) {
                        vm.messages.unshift(message);
                    }
                });

                // Timeout chat sync
                vm.timeoutPromise = $timeout(vm.getPrevious, vm.timeDelay);
            })
            .catch(function (e) {
                vm.errorMessage = e.message;
            })
            .finally(function () {
                vm.loading = false;
            })
        ;
    };

    vm.changeChannel = function (channel) {
        if (vm.selectedChannel && vm.selectedChannel.id === channel.id) {
            return;
        }

        vm.selectedChannel = channel;
        InboxService.setLastChannel(vm.selectedChannel, vm.project_id);
        vm.page = 0;
        vm.messages = [];
        vm.getNextPage();
    };

    vm.getChannelName = function (channel) {
        var channelName, i, tot;

        if (channel && channel.users && channel.users.length >= 2) {
            if ((!channel.name || channel.name.indexOf(',') >= 0) && channel.users.length === 2) {
                if (channel.users[0].id === userResolve.id) {
                    return channel.users[1].fullName;
                } else {
                    return channel.users[0].fullName;
                }
            } else if(channel.name === '') {
                channelName = [];
                tot = channel.users.length;
                for (i=0;i<tot;i++) {
                    channelName.push(channel.users[i].fullName);
                }

                return channelName.join(', ');
            }
        }

        return (!channel || !channel.name)?'':channel.name;
    };

    vm.sendMessage = function () {
        if (vm.loading) { return; }

        //timeout chat sync
        if (vm.timeoutPromise) {
            $timeout.cancel(vm.timeoutPromise);
            vm.timeoutPromise = false;
        }

        vm.loading = true;
        vm.errorMessage = false;
        strukshurApiService.message.save({ thread_id: vm.selectedChannel.id, message: vm.newMessage }).$promise
            .then(function (res) {
                vm.newMessage = '';
                vm.getPrevious(true);
            })
            .catch(function (e) {
                vm.errorMessage = e.data.message;
            })
            .finally(function () {
                vm.loading = false;
            })
        ;
    };

    /**
     * Deletes the received group on the backend
     *
     * @param  {Object}  channel  The channel
     * @param  {Object}  message  The message
     */
    vm.deleteMessage = function (channel, message) {
        var $childScope = vm.$new();
        $childScope.channel = channel;
        $childScope.channelMessage = message;
        $childScope.title = 'Delete message';
        $childScope.message = 'Are you sure you want to delete the message?';

        var modalInstance = $uibModal.open({
            keyboard: true,
            scope: $childScope,
            controller: 'ProjectDetailInboxMessageDeleteCtrl',
            template: require('../../../common/templates/base.confirm-modal.tpl.html')
        });

        modalInstance.result.then(

            // Resolved callback
            function (data) {
                _pull(vm.messages, message);
            },

            // Rejected callback
            angular.noop
        );
    };

    vm.createGroupPop = function () {
        var modalInstance = $uibModal.open({
            scope: vm,
            keyboard: true,
            controller: 'ProjectDetailNewChannelCtrl',
            template: require('../../../common/templates/create-channel-modal.tpl.html'),
            resolve: {
                project_id: function () {
                    return vm.project_id;
                }
            }
        });
        modalInstance.result.then(function (selectedItem) {

            // New thread
            if (selectedItem && selectedItem.id) {
                vm.channelList()
                    .then(function () {

                        // Redirects the user to the new channel
                        var newChannel = _find(vm.channels, { id: selectedItem.id });
                        if (newChannel) {
                            vm.changeChannel(newChannel);
                        }
                    })
                ;
            }
        }, angular.noop);
    };

    vm.directMessagePop = function () {
        var modalInstance = $uibModal.open({
            scope: vm,
            keyboard: true,
            controller: 'ProjectDetailDirectMessageCtrl',
            template: require('../../../common/templates/direct-message-modal.tpl.html'),
            resolve: {
                project_id: function () {
                    return vm.project_id;
                },
                new_channel: function () {
                    return true;
                }
            }
        });
        modalInstance.result.then(function (selectedItem) {

            // New thread
            if (selectedItem && selectedItem.id) {
                vm.channelList()
                    .then(function () {

                        // Redirects the user to the new direct message
                        var newChannel = _find(vm.channels, { id: selectedItem.id });
                        if (newChannel) {
                            vm.changeChannel(newChannel);
                        }
                    })
                ;
            }
        }, angular.noop);
    };

    /**
     * Deletes the received group on the backend
     *
     * @param  {Object}  channel  The channel
     */
    vm.deleteChannel = function (channel) {
        var $childScope = vm.$new();
        $childScope.thread = channel;
        $childScope.title = 'Delete channel';
        $childScope.message = 'Are you sure you want to delete the channel?';

        var modalInstance = $uibModal.open({
            keyboard: true,
            scope: $childScope,
            controller: 'ProjectDetailInboxChannelDeleteCtrl',
            template: require('../../../common/templates/base.confirm-modal.tpl.html')
        });

        modalInstance.result.then(

            // Resolved callback
            function (data) {
                vm.selectedChannel = null;
                _pull(vm.channels, channel);

                // Selects first channel to be the active one
                if (vm.channels.length > 0) {
                    vm.changeChannel(vm.channels[0]);
                }
            },

            // Rejected callback
            angular.noop
        );
    };

    vm.upgradeSettingsAccount = function () {
        vm.$parent.upgradeSettingsAccount();
    };

    vm.uploadMessage = function () {
        var modalInstance;

        vm.uploading = true;
        vm.upgadeLink = false;
        vm.errorMessage = false;

        modalInstance = $uibModal.open({
            scope: vm,
            animation: false,
            controller: 'ProjectProgressModalCtrl',
            template: require('../../../common/templates/progress-modal.tpl.html'),
            resolve: {}
        });
        modalInstance.result.then(angular.noop, angular.noop);

        var data = {
            project_id: vm.project_id,
            file: 'data:'+vm.attach_file.filetype+';base64,'+vm.attach_file.base64,
            filename: vm.attach_file.filename,
            type: 'DEFAULT'
        };

        strukshurApiService.projectFile.create(data).$promise
            .then(function (res) {
                modalInstance.close();
                vm.uploading = false;
                vm.attach_file = null;
                vm.newMessage = res.projectFile.file;
                vm.sendMessage();
            })
            .catch(function (res) {
                modalInstance.close();
                vm.uploading = false;
                if (res && res.data && res.data.message) {
                    vm.errorMessage = res.data.message;
                    if (vm.errorMessage.indexOf('enough storage') !== -1 && vm.errorMessage.indexOf('Upgrade') !== -1) {
                        vm.upgadeLink = true;
                    }
                } else {
                    vm.errorMessage = 'An error occurred uploading the file.';
                }
            })
        ;
    };

    vm.channelList = function () {
        return strukshurApiService.getProjectThreads.query({ project_id: vm.project_id }).$promise
            .then(function (d) {
                vm.loading = false;
                vm.total = d.total;
                vm.channels = d.threads;
                if (!vm.selectedChannel.id) {
                    vm.selectedChannel = vm.channels[0];
                    InboxService.setLastChannel(vm.selectedChannel, vm.project_id);
                }
                vm.getNextPage();
            })
            .catch(function (e) {
                vm.loading = false;
                vm.errorMessage = e.message;
            })
        ;
    };

    vm.addUserPop = function (channel) {

        // Only allows adding new users if the user can edit the group
        if (!vm.checkPermission('project_inbox_group_edit')) { return; }

        if (!channel.isChannel) { return; }

        var modalInstance = $uibModal.open({
            scope: vm,
            keyboard: true,
            controller: 'ProjectDetailDirectMessageCtrl',
            template: require('../../../common/templates/direct-message-modal.tpl.html'),
            resolve: {
                project_id: function () {
                    return vm.project_id;
                },
                new_channel: function () {
                    return channel;
                }
            }
        });
        modalInstance.result.then(
            function (selectedItem) {

                //new thread
                if (selectedItem && selectedItem.id) {
                    vm.channelList();
                }
            },

            angular.noop
        );
    };
    vm.channelList();
})

.controller('ProjectDetailNewChannelCtrl', function ProjectDetailNewChannelCtrl($scope, $uibModalInstance, project_id, strukshurApiService, $q) {
    $scope.loading = false;
    $scope.project_id = project_id;
    $scope.userSearchProject = [];
    $scope.userSearchStrukshur = [];
    $scope.userSearchEmail = [];
    $scope.errorMessage = false;
    $scope.outsideProject = false;
    $scope.channel_name = '';

    $scope.loadUsers = function (query, source) {
        var deferred = $q.defer();
        if (query.length >= 3) {
            strukshurApiService.userFind.query({terms: query, project_id: $scope.project_id, source: source}, function(d) {
                deferred.resolve(d.users);

                return d.users;
            });
        }

        return deferred.promise;
    };

    $scope.addUser = function (tag) {
        var email_reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (tag && tag.text && !email_reg.test(tag.text)) { //only add a valid email
            return false;
        }
        $scope.errorMessage = false;

        return tag;
    };

    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };

    $scope.createChannel = function () {
        var data, usersProject = [], usersStrukshur = [], usersEmail = [], i, tot;

        if ($scope.loading) { return; }

        if ($scope.channel_name === '') {
            $scope.errorMessage = 'Group name is required';
            return;
        }

        if ($scope.userSearchProject.length+$scope.userSearchStrukshur.length+$scope.userSearchEmail.length===0) {
            $scope.errorMessage = 'Please add users';
            return;
        }

        //send only IDs
        tot = $scope.userSearchProject.length;
        for (i=0;i<tot;i++) {
            usersProject.push($scope.userSearchProject[i].id);
        }

        tot = $scope.userSearchStrukshur.length;
        for(i=0;i<tot;i++) {
            usersStrukshur.push($scope.userSearchStrukshur[i].id);
        }

        tot = $scope.userSearchEmail.length;
        for (i=0;i<tot;i++) {
            usersEmail.push($scope.userSearchEmail[i].text);
        }

        $scope.loading = true;
        $scope.errorMessage = false;
        data = {
            project_id: $scope.project_id,
            channel_name: $scope.channel_name,
            usersProject: JSON.stringify(usersProject),
            usersStrukshur: JSON.stringify(usersStrukshur),
            usersEmail: JSON.stringify(usersEmail),
            isChannel: 1
        };

        strukshurApiService.thread.add(data, function (d) {
            $scope.loading = false;
            $uibModalInstance.close(d.thread);
        }, function (e) {
            $scope.loading = false;
            $scope.errorMessage = e.data.message;
        });
    };
})

.controller('ProjectDetailDirectMessageCtrl', function ProjectDetailDirectMessageCtrl( $scope, $uibModalInstance, project_id, strukshurApiService, $q, new_channel) {
    $scope.loading = false;
    $scope.project_id = project_id;
    $scope.userSearchProject = [];
    $scope.userSearchStrukshur = [];
    $scope.userSearchEmail = [];
    $scope.errorMessage = false;
    $scope.outsideProject = false;
    $scope.new_channel = new_channel; //to add a new user or create a direct message


    $scope.loadUsers = function (query, source) {
        var deferred = $q.defer();
        if(query.length>=3) {
            strukshurApiService.userFind.query({terms: query, project_id: $scope.project_id, source: source}, function(d) {
                deferred.resolve(d.users);
                return d.users;
            });
        }
        return deferred.promise;
    };
    $scope.addUser = function (tag) {
        var email_reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(tag && tag.text && !email_reg.test(tag.text)){ //only add a valid email
            return false;
        }
        $scope.errorMessage = false;
        return tag;
    };

    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };

    $scope.createDirectMessage = function () {
        var data, usersProject = [], usersStrukshur = [], usersEmail = [], i, tot;
        if($scope.loading){
            return;
        }
        if($scope.userSearchProject.length+$scope.userSearchStrukshur.length+$scope.userSearchEmail.length===0){
            $scope.errorMessage = 'Please add users';
            return;
        }
        if($scope.userSearchProject.length+$scope.userSearchStrukshur.length+$scope.userSearchEmail.length>1){
            $scope.errorMessage = 'Only one user for direct message, you can create a group with the other option.';
            return;
        }
        //send only IDs
        tot = $scope.userSearchProject.length;
        for(i=0;i<tot;i++){
            usersProject.push($scope.userSearchProject[i].id);
        }
        tot = $scope.userSearchStrukshur.length;
        for(i=0;i<tot;i++){
            usersStrukshur.push($scope.userSearchStrukshur[i].id);
        }
        tot = $scope.userSearchEmail.length;
        for(i=0;i<tot;i++){
            usersEmail.push($scope.userSearchEmail[i].text);
        }
        $scope.loading = true;
        $scope.errorMessage = false;
        if($scope.new_channel===true) {
            data = {
                project_id: $scope.project_id,
                channel_name: 'direct message , ',
                usersProject: JSON.stringify(usersProject),
                usersStrukshur: JSON.stringify(usersStrukshur),
                usersEmail: JSON.stringify(usersEmail),
                isChannel: 0
            };
            strukshurApiService.thread.add(data, function (d) {
                $scope.loading = false;
                $uibModalInstance.close(d.thread);
            }, function (e) {
                $scope.loading = false;
                $scope.errorMessage = e.data.message;
            });
        }
        else{
            data = {
                thread_id: $scope.new_channel.id,
                usersProject: JSON.stringify(usersProject),
                usersStrukshur: JSON.stringify(usersStrukshur),
                usersEmail: JSON.stringify(usersEmail)
            };
            strukshurApiService.thread.update(data, function (d) {
                $scope.loading = false;
                $uibModalInstance.close(d.thread);
            }, function (e) {
                $scope.loading = false;
                $scope.errorMessage = e.data.message;
            });
        }
    };
})

.controller('ProjectDetailInboxChannelDeleteCtrl', function ProjectDetailInboxChannelDeleteCtrl($scope, $uibModalInstance, strukshurApiService, $q) {
    var vm = $scope;

    vm.loading = false;

    vm.onConfirmChosen = function () {

        // Deletes the thread on the server
        vm.loading = true;
        strukshurApiService.thread.delete({ thread_id: vm.thread.id }).$promise
            .then(function (res) {
                $uibModalInstance.close();
            })
            .catch(function (res) {
                if (res.status === 403) {
                    vm.errorMessage = 'You don\'t have the necessary permission to delete the channel.';
                } else {
                    vm.errorMessage = 'There was an error trying to delete the channel.';
                }
            })
            .finally(function () {
                vm.loading = false;
            })
        ;
    };
})

.controller('ProjectDetailInboxMessageDeleteCtrl', function ProjectDetailInboxMessageDeleteCtrl ($q, $scope, $uibModalInstance, strukshurApiService) {
    var vm = $scope;

    vm.loading = false;

    vm.onConfirmChosen = function () {

        // Deletes the message on the server
        vm.loading = true;
        strukshurApiService.message.delete({ message_id: vm.channelMessage.id }).$promise
            .then(function (res) {
                $uibModalInstance.close();
            })
            .catch(function (res) {
                if (res && res.data && res.data.message) {
                    vm.errorMessage = res.data.message;
                } else {
                    vm.errorMessage = 'There was an error trying to delete the message.';
                }
            })
            .finally(function () {
                vm.loading = false;
            })
        ;
    };
})

;
