import {
    clone as _clone,
    differenceBy as _differenceBy,
    find as _find,
    pull as _pull,
    remove as _remove,
} from 'lodash';
import * as angular from 'angular';
import * as isMobile from 'ismobilejs';
import * as EXIF from 'exif-js';

angular.module('strukshurApp.projects.estimates', [])

.config(function config($stateProvider, $uibModalProvider) {
    $uibModalProvider.options = { animation: true, backdrop: 'static', keyboard: false, ariaLabelledBy: 'modal-title', ariaDescribedBy: 'modal-body' };

    $stateProvider
        .state('projects-detail.estimates', {
            url: '/estimates',
            controller: 'ProjectDetailEstimatesCtrl',
            template: require('./project.estimates.tpl.html'),
            data: { pageTitle: 'Project / Estimates', class: 'projects projects-detail-estimates' }
        })
    ;
})

.controller('ProjectDetailEstimatesCtrl', function ProjectDetailEstimatesCtrl ($scope, $state, $timeout, $uibModal, FileService, smoothScroll, strukshurApiService) {
    var vm = $scope;

    // Check if the user can access the current page or not
    if (!vm.$parent.checkPermission('project_estimate_view') && !vm.$parent.projectIsOpenForBidsAndUserCanViewIt()) {
        return $state.go('projects-detail.access-denied', { section: 'Estimates' });
    }

    vm.loading = true;
    vm.estimates = [];
    vm.searchTerm = '';
    vm.currentUser = {};
    vm.errorMessage = '';
    vm.availableCategories = [];
    vm.currentUserIsProAndNotMember = vm.$parent.currentUserIsProAndNotMember;

    /**
     * Controller init logic
     */
    vm.init = () => {

        // Updates current route info on the project menu scope
        vm.$parent.currentRoute = $state.current.name;

        // Loads all available estimate categories
        strukshurApiService.estimateCategories.list().$promise
            .then((res) => {
                vm.availableCategories = res.estimateCategories;

                // Wether to only load pro etimates or not
                var onlyProEstimates = vm.currentUserIsProAndNotMember ? 'y' : 'n';

                // Loads project estimates
                strukshurApiService.projectEstimates.list({ project_id: vm.$parent.project.id, only_pro_estimates: onlyProEstimates }).$promise
                    .then((res) => {
                        vm.loading = false;
                        vm.currentUser = vm.$parent.currentUser;
                        res.projectEstimates.forEach((estimate) => {
                            if (!estimate.categories) { estimate.categories = []; }

                            // Remove from the available categories list the categories
                            // that were already added to the estimate
                            estimate.availableCategories = _differenceBy(vm.availableCategories, estimate.categories, 'title');

                            vm.calculateEstimateTotal(estimate);

                            vm.estimates.push(estimate);
                        });

                        vm.updatePageItems();
                    })
                    .catch((res) => {
                        if (res.status === 403) {
                            vm.errorMessage = 'You don\'t have the necessary permission to list estimates.';
                        } else {
                            vm.errorMessage = 'There was an error trying to list the estimates.';
                        }
                    })
                ;
            })
        ;
    };

    /**
     * Checks if the current user has the given permission on the project
     *
     * @param  {String}  permission  The permission key
     *
     * @return {Boolean}
     */
    vm.checkPermission = function (permission) {
        return vm.$parent.checkPermission(permission);
    };

    /**
     * Wether the current user is an estimate collaborator
     *
     * @param  {Object}  estimate  The estimate
     *
     * @return {Boolean}
     */
    vm.isEstimateCollaborator = function (estimate) {
        var isCollaborator = false;

        estimate.collaborators.forEach(function (collaborator) {
            if (collaborator.id == vm.currentUser.id) {
                isCollaborator = true;
                return;
            }
        });

        return isCollaborator;
    };

    /**
     * Wether the current user is an estimate category collaborator
     *
     * @param  {Object}  category  The category
     *
     * @return {Boolean}
     */
    vm.isEstimateCategoryCollaborator = function (category) {
        var isCollaborator = false;

        category.collaborators.forEach(function (collaborator) {
            if (collaborator.id == vm.currentUser.id) {
                isCollaborator = true;
                return;
            }
        });

        return isCollaborator;
    };

    /**
     * Wether the current user is an estimate sub category collaborator
     *
     * @param  {Object}  subCategory  The sub category
     *
     * @return {Boolean}
     */
    vm.isEstimateSubCategoryCollaborator = function (subCategory) {
        var isCollaborator = false;

        subCategory.collaborators.forEach(function (collaborator) {
            if (collaborator.id == vm.currentUser.id) {
                isCollaborator = true;
                return;
            }
        });

        return isCollaborator;
    };

    /**
     * Wether the current user can view the estimate or not
     *
     * @param  {Object}  estimate  The estimate
     *
     * @return {Boolean}
     */
    vm.canViewEstimate = function (estimate) {
        if (estimate.createdById == vm.currentUser.id || vm.checkPermission('project_estimate_view_others')) {
            return true;
        }

        // Allows estimate collaborator to view the estimate
        if (vm.isEstimateCollaborator(estimate)) {
            return true;
        }

        // Allows all estimate category and sub category collaborators to view the estimate
        var canView = false;
        estimate.categories.forEach(function (category) {

            // First check if the user is a category collaborator
            if (vm.isEstimateCategoryCollaborator(category)) {
                canView = true;
                return;
            }

            // Then check if the user is a collaborator in any sub category
            category.subCategories.forEach(function (subCategory) {
                if (vm.isEstimateSubCategoryCollaborator(subCategory)) {
                    canView = true;
                    return;
                }
            });

            // Early exit since we already have a positive check
            if (canView) { return; }
        });

        return canView;
    };

    /**
     * Wether the current user can edit the estimate or not
     *
     * @param  {Object}  estimate  The estimate
     *
     * @return {Boolean}
     */
    vm.canEditEstimate = function (estimate) {
        if (estimate.createdById == vm.currentUser.id || vm.checkPermission('project_estimate_edit')) {
            return true;
        }

        return vm.isEstimateCollaborator(estimate);
    };

    /**
     * Wether the current user can mark the estimate as final
     *
     * @param  {Object}  estimate  The estimate
     *
     * @return {Boolean}
     */
    vm.canMarkEstimateAsFinal = function (estimate) {
        if (estimate.createdById == vm.currentUser.id || vm.checkPermission('project_estimate_mark_as_final')) {
            return true;
        }

        return false;
    };

    /**
     * Wether the current user can delete the estimate or not
     *
     * @param  {Object}  estimate  The estimate
     *
     * @return {Boolean}
     */
    vm.canDeleteEstimate = function (estimate) {
        if (vm.checkPermission('project_estimate_delete') || (vm.currentUserIsProAndNotMember && estimate.createdById == vm.currentUser.id)) {
            return true;
        }

        return false;
    };

    /**
     * Wether the current user can view the estimate or not
     *
     * @param  {Object}  estimate  The estimate
     * @param  {Object}  category  The estimate category
     *
     * @return {Boolean}
     */
    vm.canViewEstimateCategory = function (estimate, category) {
        if (estimate.createdById == vm.currentUser.id || vm.checkPermission('project_estimate_view_others')) {
            return true;
        }

        // Allows estimate collaborator or category collaborator to view the category
        if (vm.isEstimateCollaborator(estimate) || vm.isEstimateCategoryCollaborator(category)) {
            return true;
        }

        // Check if the user is a collaborator in any sub category
        var canView = false;
        category.subCategories.forEach(function (subCategory) {
            if (vm.isEstimateSubCategoryCollaborator(subCategory)) {
                canView = true;
                return;
            }
        });

        return canView;
    };

    /**
     * Wether the current user can add a new estimate category
     *
     * @param  {Object}  estimate  The estimate
     *
     * @return {Boolean}
     */
    vm.canAddEstimateCategory = function (estimate) {
        if (estimate.createdById == vm.currentUser.id || vm.checkPermission('project_estimate_category_add')) {
            return true;
        }

        return vm.isEstimateCollaborator(estimate);
    };

    /**
     * Wether the current user can edit an estimate category
     *
     * @param  {Object}  estimate  The estimate
     * @param  {Object}  category  The estimate category
     *
     * @return {Boolean}
     */
    vm.canEditEstimateCategory = function (estimate, category) {
        if (estimate.createdById == vm.currentUser.id || vm.checkPermission('project_estimate_category_edit')) {
            return true;
        }

        return (vm.isEstimateCollaborator(estimate) || vm.isEstimateCategoryCollaborator(category));
    };

    /**
     * Wether the current user can delete an estimate category
     *
     * @param  {Object}  estimate  The estimate
     *
     * @return {Boolean}
     */
    vm.canDeleteEstimateCategory = function (estimate) {
        if (estimate.createdById == vm.currentUser.id || vm.checkPermission('project_estimate_category_delete')) {
            return true;
        }

        return vm.isEstimateCollaborator(estimate);
    };

    /**
     * Wether the current user can view the estimate or not
     *
     * @param  {Object}  estimate     The estimate
     * @param  {Object}  category     The estimate category
     * @param  {Object}  subCategory  The estimate sub category
     *
     * @return {Boolean}
     */
    vm.canViewEstimateSubCategory = function (estimate, category, subCategory) {
        if (estimate.createdById == vm.currentUser.id || vm.checkPermission('project_estimate_view_others')) {
            return true;
        }

        // Allows estimate collaborator or category collaborator to view the category
        if (vm.isEstimateCollaborator(estimate) || vm.isEstimateCategoryCollaborator(category) || vm.isEstimateSubCategoryCollaborator(subCategory)) {
            return true;
        }
    };

    /**
     * Wether the current user can add a new estimate subcategory
     *
     * @param  {Object}  estimate  The estimate
     * @param  {Object}  category  The estimate category
     *
     * @return {Boolean}
     */
    vm.canAddEstimateSubCategory = function (estimate, category) {
        if (estimate.createdById == vm.currentUser.id || vm.checkPermission('project_estimate_subcategory_add')) {
            return true;
        }

        return (vm.isEstimateCollaborator(estimate) || vm.isEstimateCategoryCollaborator(category));
    };

    /**
     * Wether the current user can edit a new estimate subcategory
     *
     * @param  {Object}  estimate     The estimate
     * @param  {Object}  category     The estimate category
     * @param  {Object}  subCategory  The estimate sub category
     *
     * @return {Boolean}
     */
    vm.canEditEstimateSubCategory = function (estimate, category, subCategory) {
        if (estimate.createdById == vm.currentUser.id || vm.checkPermission('project_estimate_subcategory_edit')) {
            return true;
        }

        return (vm.isEstimateCollaborator(estimate) || vm.isEstimateCategoryCollaborator(category) || vm.isEstimateSubCategoryCollaborator(subCategory));
    };

    /**
     * Wether the current user can delete a new estimate subcategory
     *
     * @param  {Object}  estimate  The estimate
     * @param  {Object}  category  The estimate category
     *
     * @return {Boolean}
     */
    vm.canDeleteEstimateSubCategory = function (estimate, category) {
        if (estimate.createdById == vm.currentUser.id || vm.checkPermission('project_estimate_subcategory_delete')) {
            return true;
        }

        return (vm.isEstimateCollaborator(estimate) || vm.isEstimateCategoryCollaborator(category));
    };

    /**
     * Wether the current user can add a new estimate subcategory file
     *
     * @param  {Object}  estimate     The estimate
     * @param  {Object}  category     The estimate category
     * @param  {Object}  subCategory  The estimate sub category
     *
     * @return {Boolean}
     */
    vm.canAddEstimateSubCategoryFile = function (estimate, category, subCategory) {
        if (estimate.createdById == vm.currentUser.id || vm.checkPermission('project_estimate_subcategory_file_add')) {
            return true;
        }

        return (vm.isEstimateCollaborator(estimate) || vm.isEstimateCategoryCollaborator(category) || vm.isEstimateSubCategoryCollaborator(subCategory));
    };

    /**
     * Wether the current user can open an estimate subcategory file
     *
     * @param  {Object}  estimate     The estimate
     * @param  {Object}  category     The estimate category
     * @param  {Object}  subCategory  The estimate sub category
     *
     * @return {Boolean}
     */
    vm.canOpenEstimateSubCategoryFile = function (estimate, category, subCategory) {
        if (estimate.createdById == vm.currentUser.id || vm.checkPermission('project_estimate_subcategory_file_open')) {
            return true;
        }

        return (vm.isEstimateCollaborator(estimate) || vm.isEstimateCategoryCollaborator(category) || vm.isEstimateSubCategoryCollaborator(subCategory));
    };

    /**
     * Wether the current user can delete an estimate subcategory file
     *
     * @param  {Object}  estimate     The estimate
     * @param  {Object}  category     The estimate category
     * @param  {Object}  subCategory  The estimate sub category
     *
     * @return {Boolean}
     */
    vm.canDeleteEstimateSubCategoryFile = function (estimate, category, subCategory) {
        if (estimate.createdById == vm.currentUser.id || vm.checkPermission('project_estimate_subcategory_file_delete')) {
            return true;
        }

        return (vm.isEstimateCollaborator(estimate) || vm.isEstimateCategoryCollaborator(category) || vm.isEstimateSubCategoryCollaborator(subCategory));
    };

    /**
     * Opens a modal for creating a new estimate for the project
     */
    vm.newEstimate = function () {
        var modalInstance = $uibModal.open({
            scope: vm,
            keyboard: true,
            controller: 'ProjectDetailNewEstimateCtrl',
            template: require('../../../common/templates/projects.newEstimateModal.tpl.html'),
            resolve: {
                project: vm.$parent.project
            }
        });

        modalInstance.result.then(

            // Resolved callback
            function (data) {
                if (data.estimate) {
                    data.estimate.availableCategories = _clone(vm.availableCategories);
                    if (!data.estimate.categories) {
                        data.estimate.categories = [];
                    }
                    data.estimate.createdById = vm.currentUser.id;
                    data.estimate.openPanelOnInit = true;
                    vm.estimates.push(data.estimate);

                    // Updates sidebar
                    vm.updatePageItems();

                    // Scrolls to newly created estimate
                    $timeout(function () {
                        smoothScroll(document.getElementById('estimate-'+data.estimate.id), { offset: 80 });
                    }, 150);
                }
            },

            // Rejected callback
            angular.noop
        );
    };

    /**
     * Opens a modal for editing an existing estimate on the project
     */
    vm.editEstimate = function (estimate) {
        var modalInstance = $uibModal.open({
            scope: vm,
            keyboard: true,
            controller: 'ProjectDetailEditEstimateCtrl',
            template: require('../../../common/templates/projects.editEstimateModal.tpl.html'),
            resolve: {
                estimate: estimate,
                project: vm.$parent.project
            }
        });

        modalInstance.result.then(

            // Resolved callback
            function (data) {
                if (data.estimate) {
                    estimate.title = data.estimate.title;
                    estimate.collaborators = data.estimate.collaborators;

                    vm.updatePageItems();
                }
            },

            // Rejected callback
            angular.noop
        );
    };

    /**
     * Opens a modal to duplicate the estimate
     *
     * @param  {Object}  estimate  The estimate
     */
    vm.duplicateEstimate = function (estimate) {
        var modalInstance = $uibModal.open({
            scope: vm,
            keyboard: true,
            controller: 'ProjectDetailDuplicateEstimateCtrl',
            template: require('../../../common/templates/projects.duplicateEstimateModal.tpl.html'),
            resolve: {
                estimate: estimate,
                project: vm.$parent.project
            }
        });

        modalInstance.result.then(

            // Resolved callback
            function (data) {

                // Adds estimate to the list if it's a part of the current project
                if (data.estimate && data.estimate.project_id === vm.$parent.project.id) {
                    data.estimate.availableCategories = _clone(vm.availableCategories);
                    if (!data.estimate.categories) {
                        data.estimate.categories = [];
                    }
                    data.estimate.createdById = vm.currentUser.id;
                    data.estimate.openPanelOnInit = true;
                    vm.estimates.push(data.estimate);

                    // Updates sidebar
                    vm.updatePageItems();

                    // Scrolls to newly created estimate
                    $timeout(function () {
                        smoothScroll(document.getElementById('estimate-'+data.estimate.id), { offset: 80 });
                    }, 150);
                }
            },

            // Rejected callback
            angular.noop
        );
    };

    /**
     * Removes the received estimate from the server
     *
     * @param  {object}  estimate  The estimate
     */
    vm.removeEstimate = function (estimate) {
        var $childScope = vm.$new();
        $childScope.estimate = estimate;
        $childScope.project = vm.$parent.project;

        var modalInstance = $uibModal.open({
            keyboard: true,
            scope: $childScope,
            controller: 'ProjectDetailDeleteEstimateCtrl',
            template: require('../../../common/templates/projects.deleteEstimateConfirmationModal.tpl.html')
        });

        modalInstance.result.then(

            // Resolved callback
            function (data) {
                _pull(vm.estimates, estimate);
                vm.updatePageItems();
            },

            // Rejected callback
            angular.noop
        );
    };

    /**
     * Adds a new estimate category based on it's parent category
     *
     * @param  {Object}  estimate  The project estimate
     * @param  {Object}  category  The parent category
     * @param  {Object}  $select   The ui select component
     */
    vm.addNewCategory = function (estimate, category, $select) {
        $select.selected = null;

        var data = {
            project_id: vm.$parent.project.id,
            estimate_id: estimate.id,
            parent_category_id: category.id
        };

        // Save the new category for the estimate on the server
        estimate.savingNewCategory = true;
        estimate.errorMessage = '';
        strukshurApiService.projectEstimateCategory.add(data).$promise
            .then(function (res) {
                if (res.projectEstimateCategory) {

                    // Initializes files and images list for all subCategories
                    res.projectEstimateCategory.subCategories.forEach(function (subCategory) {
                        subCategory.files = [];
                        subCategory.images = [];
                    });

                    estimate.categories.push(res.projectEstimateCategory);
                    _remove(estimate.availableCategories, category);
                }
            })
            .catch(function (res) {
                if (res.status === 403) {
                    estimate.errorMessage = 'You don\'t have the necessary permission to add the new category.';
                } else {
                    estimate.errorMessage = 'There was an error trying to add the new category.';
                }

                vm.goToEstimateError(estimate);
            })
            .finally(function () {
                estimate.savingNewCategory = false;
            })
        ;
    };

    /**
     * Updates the sub category description on the server with the current client value
     *
     * @param  {object}  estimate     The estimate
     * @param  {object}  subCategory  The sub category
     */
    vm.changeSubCategoryDescription = function (estimate, subCategory) {
        var data = {
            sub_category_id: subCategory.id,
            description: subCategory.description
        };

        strukshurApiService.projectEstimateSubCategory.update(data).$promise
            .then(function (res) { })
            .catch(function (res) {
                if (res.status === 403) {
                    subCategory.errorMessage = 'You don\'t have the necessary permission to update the description.';
                } else {
                    subCategory.errorMessage = 'There was an error updating the sub category description.';
                }

                vm.goToEstimateSubCategoryError(subCategory);
            })
        ;
    };

    /**
     * Updates the sub category value on the server with the current client value
     *
     * @param  {object}  estimate     The estimate
     * @param  {object}  category     The category
     * @param  {object}  subCategory  The sub category
     */
    vm.changeSubCategoryValue = function (estimate, category, subCategory) {
        var data = {
            sub_category_id: subCategory.id,
            value: (subCategory.value) ? subCategory.value : 0,
        };

        subCategory.saving = true;
        strukshurApiService.projectEstimateSubCategory.update(data).$promise
            .then(function (res) {
                vm.calculateEstimateTotal(estimate);
            })
            .catch(function (res) {
                if (res.status === 403) {
                    subCategory.errorMessage = 'You don\'t have the necessary permission to update the sub category value.';
                } else {
                    subCategory.errorMessage = 'There was an error updating the sub category value.';
                }

                vm.goToEstimateSubCategoryError(subCategory);
            })
            .finally(function () {
                subCategory.saving = false;
            })
        ;
    };

    /**
     * The total amount for the estimate
     *
     * @param  {object}  estimate  The estimate
     */
    vm.calculateEstimateTotal = function (estimate) {
        var total = 0;
        var profit = estimate.profit || 0;
        var overhead = estimate.overhead || 0;

        estimate.categories.forEach(function (category) {
            category.subCategories.forEach(function (subCategory) {
                if (subCategory.value) {
                    total += subCategory.value;
                }
            });
        });

        estimate.profitValue = total * (profit / 100);
        estimate.overheadValue = total * (overhead / 100);

        total = total + (total * (profit / 100)) + (total * (overhead / 100));
        estimate.total = total;
    };

    /**
     * @param  {object}  estimate  The estimate
     */
    vm.changeEstimateProfit = (estimate) => {
        const data = {
            estimate_id: estimate.id,
            profit: estimate.profit,
        };

        estimate.savingProfit = true;
        strukshurApiService.projectEstimate.update(data).$promise
            .then(() => vm.calculateEstimateTotal(estimate))
            .catch(res => {
                if (res.status === 403) {
                    estimate.errorMessage = 'You don\'t have the necessary permission to update the estimate.';
                } else {
                    estimate.errorMessage = 'There was an error updating the estimate profit.';
                }

                vm.goToEstimateError(estimate);
            })
            .finally(() => estimate.savingProfit = false)
        ;
    };

    /**
     * @param  {object}  estimate  The estimate
     */
    vm.changeEstimateOverhead = (estimate) => {
        const data = {
            estimate_id: estimate.id,
            overhead: estimate.overhead,
        };

        estimate.savingOverhead = true;
        strukshurApiService.projectEstimate.update(data).$promise
            .then((res) => vm.calculateEstimateTotal(estimate))
            .catch(res => {
                if (res.status === 403) {
                    estimate.errorMessage = 'You don\'t have the necessary permission to update the estimate.';
                } else {
                    estimate.errorMessage = 'There was an error updating the estimate overhead.';
                }

                vm.goToEstimateError(estimate);
            })
            .finally(() => estimate.savingOverhead = false)
        ;
    };

    /**
     * Updates the profit/overhead description for the estimate
     *
     * @param  {object}  estimate  The estimate
     */
    vm.changeEstimateProfitDescription = function (estimate) {
        var data = {
            estimate_id: estimate.id,
            profit_description: estimate.profitDescription
        };

        strukshurApiService.projectEstimate.update(data).$promise
            .then(function (res) { })
            .catch(function (res) {
                if (res.status === 403) {
                    estimate.errorMessage = 'You don\'t have the necessary permission to update the estimate.';
                } else {
                    estimate.errorMessage = 'There was an error updating the profit/overhead description.';
                }

                vm.goToEstimateError(estimate);
            })
        ;
    };

    /**
     * Opens modal to confirm marking the received estimate as final
     *
     * @param  {object}  estimate  The estimate
     */
    vm.markAsFinal = function (estimate) {
        var $childScope = vm.$new();
        $childScope.estimate = estimate;
        $childScope.project = vm.$parent.project;
        $childScope.title = 'Mark estimate as final?';
        $childScope.message = 'When an estimate is marked as final, users won\'t be able to modify it unless a change order is created.';

        var modalInstance = $uibModal.open({
            keyboard: true,
            scope: $childScope,
            controller: 'ProjectDetailEstimateMarkAsFinalCtrl',
            template: require('../../../common/templates/base.confirm-modal.tpl.html')
        });

        modalInstance.result.then(

            // Resolved callback
            function (data) {
                estimate.status = 'final';
            },

            // Rejected callback
            angular.noop
        );
    };

    /**
     * Opens a modal for editing the category
     */
    vm.editCategory = function (estimate, category) {
        var modalInstance = $uibModal.open({
            scope: vm,
            keyboard: true,
            controller: 'ProjectDetailEditEstimateCategoryCtrl',
            template: require('../../../common/templates/projects.editEstimateCategoryModal.tpl.html'),
            resolve: {
                project: vm.$parent.project,
                estimate: estimate,
                category: category
            }
        });

        modalInstance.result.then(

            // Resolved callback
            function (data) {
                if (data.projectEstimateCategory) {
                    category.title = data.projectEstimateCategory.title;
                    category.collaborators = data.projectEstimateCategory.collaborators;
                }
            },

            // Rejected callback
            angular.noop
        );
    };

    /**
     * Removes the received category from the server
     *
     * @param  {object}  estimate  The estimate
     * @param  {object}  category  The category
     */
    vm.removeCategory = function (estimate, category) {
        var $childScope = vm.$new();
        $childScope.category = category;
        $childScope.project = vm.$parent.project;
        $childScope.title = 'Delete category';
        $childScope.message = 'Are you sure you want to delete the category?';

        var modalInstance = $uibModal.open({
            keyboard: true,
            scope: $childScope,
            controller: 'ProjectDetailDeleteEstimateCategoryCtrl',
            template: require('../../../common/templates/base.confirm-modal.tpl.html')
        });

        modalInstance.result.then(

            // Resolved callback
            function (data) {

                // Remove category from the estimate
                _pull(estimate.categories, category);

                // Re-add the category to the list of catgories that can be
                // selected for estimate
                var parentCategory = _find(vm.availableCategories, { title: category.title });
                if (parentCategory) {
                    estimate.availableCategories.push(parentCategory);
                }

                // Recalculate estimate total after removing category
                vm.calculateEstimateTotal(estimate);
            },

            // Rejected callback
            angular.noop
        );
    };

    /**
     * Opens a modal for adding a new sub category
     */
    vm.addSubCategory = function (estimate, category) {
        var modalInstance = $uibModal.open({
            scope: vm,
            keyboard: true,
            controller: 'ProjectDetailAddEstimateSubCategoryCtrl',
            template: require('../../../common/templates/projects.newSubCategoryModal.tpl.html'),
            resolve: {
                project: vm.$parent.project,
                estimate: estimate,
                category: category
            }
        });

        modalInstance.result.then(

            // Resolved callback
            function (data) {
                if (data.projectEstimateSubCategory) {
                    data.projectEstimateSubCategory.files = [];
                    data.projectEstimateSubCategory.images = [];

                    category.subCategories.push(data.projectEstimateSubCategory);
                }
            },

            // Rejected callback
            angular.noop
        );
    };

    /**
     * Opens a modal for editing the sub category
     */
    vm.editSubCategory = function (estimate, category, subCategory) {
        var modalInstance = $uibModal.open({
            scope: vm,
            keyboard: true,
            controller: 'ProjectDetailEditEstimateSubCategoryCtrl',
            template: require('../../../common/templates/projects.editEstimateSubCategoryModal.tpl.html'),
            resolve: {
                project: vm.$parent.project,
                estimate: estimate,
                category: category,
                subCategory: subCategory
            }
        });

        modalInstance.result.then(

            // Resolved callback
            function (data) {
                if (data.projectEstimateSubCategory) {
                    subCategory.title = data.projectEstimateSubCategory.title;
                    subCategory.collaborators = data.projectEstimateSubCategory.collaborators;
                }
            },

            // Rejected callback
            angular.noop
        );
    };

    /**
     * Removes the received category from the server
     *
     * @param  {object}  estimate     The estimate
     * @param  {object}  category     The category
     * @param  {object}  subCategory  The sub category
     */
    vm.removeSubCategory = function (estimate, category, subCategory) {
        var $childScope = vm.$new();
        $childScope.subCategory = subCategory;
        $childScope.project = vm.$parent.project;
        $childScope.title = 'Delete sub category';
        $childScope.message = 'Are you sure you want to delete the sub category?';

        var modalInstance = $uibModal.open({
            keyboard: true,
            scope: $childScope,
            controller: 'ProjectDetailDeleteEstimateSubCategoryCtrl',
            template: require('../../../common/templates/base.confirm-modal.tpl.html')
        });

        modalInstance.result.then(

            // Resolved callback
            function (data) {
                _pull(category.subCategories, subCategory);

                // Recalculate estimate total after removing category
                vm.calculateEstimateTotal(estimate);
            },

            // Rejected callback
            angular.noop
        );
    };

    /**
     * Triggers a file select event for the given sub category
     *
     * @param  {object}  subCategory  The sub category to select the file for
     */
     vm.selectFile = (subCategory) => {
        angular.element('#sub-category-' + subCategory.id + '_file').click();
    };

    /**
     * Handles selection of a new image for the given sub category
     */
    vm.selectSubCategoryFile = function (event, files) {

        // We first need to find the subcategory that received the file because
        // the uplod component does not support passing the model object when
        // calling event listeners
        var input = angular.element(event.target),
            estimateId = parseInt(input.attr('data-estimate'), 10),
            categoryId = parseInt(input.attr('data-category'), 10),
            subCategoryId = parseInt(input.attr('data-sub-category'), 10);

        var estimate = _find(vm.estimates, { id: estimateId });
        var category = _find(estimate.categories, { id: categoryId });
        var subCategory = _find(category.subCategories, { id: subCategoryId });

        // Delegate the handling of the file according to its type
        var filetype = subCategory.currentFile.filetype;
        if (filetype.split('/')[0] === 'image') {
            vm.subCategoryImageSelected(estimate, category, subCategory);
        } else {
            vm.subCategoryFileSelected(estimate, category, subCategory);
        }
    };

    /**
     * Handles selection of a new sub category file
     *
     * @param  {Object}  estimate     The estimate
     * @param  {Object}  category     The category
     * @param  {Object}  subCategory  The sub category
     */
    vm.subCategoryFileSelected = function(estimate, category, subCategory) {
        var modalInstance, data, fileData;

        fileData = 'data:'+subCategory.currentFile.filetype+';base64,'+subCategory.currentFile.base64;
        data = {
            project_id: vm.$parent.project.id,
            sub_category_id: subCategory.id,
            file: fileData,
            filename: subCategory.currentFile.filename
        };

        // Open upload progress modal
        modalInstance = $uibModal.open({
            scope: $scope,
            animation: false,
            controller: 'ProjectProgressModalCtrl',
            template: require('../../../common/templates/progress-modal.tpl.html'),
            resolve: {}
        });
        modalInstance.result.then(angular.noop, angular.noop);

        subCategory.uploading = true;
        strukshurApiService.projectEstimateSubCategoryFile.create(data).$promise
            .then(function (res) {
                if (!subCategory.files) {
                    subCategory.files = [];
                }

                subCategory.currentFile = null;
                subCategory.files.push(res.file);
            })
            .catch(function (res) {
                if (res.status === 403) {
                    subCategory.errorMessage = 'You don\'t have the necessary permission to add new files.';
                } else {
                    subCategory.errorMessage = 'There was an error trying to add the file.';
                }

                vm.goToEstimateSubCategoryError(subCategory);
            })
            .finally(function () {
                modalInstance.close();
                subCategory.uploading = false;
            })
        ;
    };

    /**
     * Handles selection of a new sub category image file
     *
     * @param  {Object}  estimate     The estimate
     * @param  {Object}  category     The category
     * @param  {Object}  subCategory  The sub category
     */
    vm.subCategoryImageSelected = function(estimate, category, subCategory) {
        var modalInstance, data, img, width, height, MAX_WIDTH, MAX_HEIGHT, canvas, ctx, data_image;
        MAX_WIDTH = 2048;
        MAX_HEIGHT = 2048;

        // Resize image
        img = document.createElement('img');
        img.setAttribute('crossOrigin', 'anonymous');
        img.onload = function (ev) {
            var resize,dataUrlImage;
            width = img.width;
            height = img.height;
            if (width > height) {
                if (width > MAX_WIDTH) {
                    height *= MAX_WIDTH / width;
                    width = MAX_WIDTH;
                }
            } else {
                if (height > MAX_HEIGHT) {
                    width *= MAX_HEIGHT / height;
                    height = MAX_HEIGHT;
                }
            }

            // Resize only if needed
            if (width !== img.width || height !== img.height) {
                canvas = document.createElement('canvas');
                canvas.width = width;
                canvas.height = height;
                ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0, width, height);

                canvas.setAttribute('crossOrigin', 'anonymous');

                data_image = canvas.toDataURL(subCategory.currentFile.filetype);
            } else {
                data_image = 'data:'+subCategory.currentFile.filetype+';base64,'+subCategory.currentFile.base64;
            }

            //if iOS rotate if needed
            if (isMobile.apple.device || isMobile.apple.ipod || isMobile.apple.phone || isMobile.apple.tablet) {
                resize = document.createElement('img');
                resize.setAttribute('crossOrigin', 'anonymous');

                resize.onload = function(event){
                    width = resize.width;
                    height = resize.height;

                    EXIF.getData(img, function(){
                        var orientation = EXIF.getTag(this, "Orientation");
                        canvas = document.createElement('canvas');
                        ctx = canvas.getContext('2d');
                        if (orientation && orientation>4){
                            canvas.width = height;
                            canvas.height = width;
                        } else {
                            canvas.width = width;
                            canvas.height = height;
                        }

                        switch(orientation){
                            case 2:
                                // horizontal flip
                                ctx.translate(width, 0);ctx.scale(-1, 1);
                                break;
                            case 3:
                                // 180° rotate left
                                ctx.translate(width, height);ctx.rotate(Math.PI);
                                break;
                            case 4:
                                // vertical flip
                                ctx.translate(0, height);ctx.scale(1, -1);
                                break;
                            case 5:
                                // vertical flip + 90 rotate right
                                ctx.rotate(0.5 * Math.PI);ctx.scale(1, -1);
                                break;
                            case 6:
                                // 90° rotate right
                                ctx.rotate(0.5 * Math.PI);ctx.translate(0, -height);
                                break;
                            case 7:
                                // horizontal flip + 90 rotate right
                                ctx.rotate(0.5 * Math.PI);ctx.translate(width, -height);ctx.scale(-1, 1);
                                break;
                            case 8:
                                // 90° rotate left
                                ctx.rotate(-0.5 * Math.PI);ctx.translate(-width, 0);
                                break;
                            default:

                                // Now that we have the correct subCategory, we can then upload the
                                // selected file to the server
                                data = {
                                    project_id: vm.$parent.project.id,
                                    sub_category_id: subCategory.id,
                                    image: data_image,
                                    filename: subCategory.currentFile.filename
                                };

                                vm.uploadSubCategoryImage(data, estimate, subCategory);

                                dataUrlImage = null;
                                resize = null;
                                img = null;
                                canvas = null;
                                ctx = null;

                                return;
                        }

                        if (orientation && orientation>4) {
                            ctx.drawImage(resize, 0, 0);
                        } else {
                            ctx.drawImage(resize, 0, 0, width, height);
                        }

                        canvas.setAttribute('crossOrigin', 'anonymous');
                        dataUrlImage = canvas.toDataURL('image/jpeg');

                        data_image = 'data:image/jpeg;base64,'+dataUrlImage.substr(dataUrlImage.indexOf(',')+1);

                        dataUrlImage = null;
                        resize = null;
                        img = null;
                        canvas = null;
                        ctx = null;


                        // Now that we have the correct subCategory, we can then upload the
                        // selected file to the server
                        data = {
                            project_id: vm.$parent.project.id,
                            sub_category_id: subCategory.id,
                            image: data_image,
                            filename: subCategory.currentFile.filename
                        };

                        vm.uploadSubCategoryImage(data, estimate, subCategory);

                        data_image = null;

                        vm.$apply();
                    });
                };
                resize.src = data_image;
            } else {
                img = null;

                // Now that we have the correct subCategory, we can then upload the
                // selected file to the server
                data = {
                    project_id: vm.$parent.project.id,
                    sub_category_id: subCategory.id,
                    image: data_image,
                    filename: subCategory.currentFile.filename
                };

                vm.uploadSubCategoryImage(data, estimate, subCategory);

                data_image = null;
                canvas = null;
                ctx = null;
            }
        };

        img.src = 'data:'+subCategory.currentFile.filetype+';base64,'+subCategory.currentFile.base64;
    };

    /**
     * Opens the modal to select a file to add to the sub category
     *
     * @param  {object}  subCategory  The sub category
     */
     vm.openFilePickerModal = (subCategory) => {
        const modalInstance = $uibModal.open({
            keyboard: true,
            scope: vm.$new(),
            controller: 'FilePickerModal',
            template: require('../../../common/templates/projects.filePickerModal.tpl.html'),
            resolve: {
                options: { project: vm.$parent.project },
            },
        });

        modalInstance.result.then(

            // Resolved callback
            res => {
                const file = res.selected[0],
                    isImage = FileService.supportsImagePreviewExtension(FileService.getFileExtension(file.filename));

                // Open upload progress modal
                const progressModal = $uibModal.open({
                    scope: $scope,
                    controller: 'ProjectProgressModalCtrl',
                    template: require('../../../common/templates/progress-modal.tpl.html'),
                    resolve: {}
                });
                progressModal.result.then(angular.noop, angular.noop);

                const data = {
                    project_id: vm.$parent.project.id,
                    sub_category_id: subCategory.id,
                    file_id: file.id,
                };

                // Delegate the handling of the file according to its type
                let promise;
                if (isImage) {
                    promise = strukshurApiService.projectEstimateSubCategoryImage.createFromFile(data).$promise
                } else {
                    promise = strukshurApiService.projectEstimateSubCategoryFile.createFromFile(data).$promise
                }

                subCategory.uploading = true;
                subCategory.errorMessage = '';
                promise
                    .then(res => {
                        if (!subCategory.files) { subCategory.files = []; }
                        if (!subCategory.images) { subCategory.images = []; }

                        subCategory.currentFile = null;

                        if (isImage) {
                            subCategory.images.push(res.image);
                        } else {
                            subCategory.files.push(res.file);
                        }
                    })
                    .catch(res => {
                        subCategory.errorMessage = (res.data.message?res.data.message:'');

                        if (res.status === 403) {
                            subCategory.errorMessage = 'You don\'t have the necessary permission to add the file.';
                        } else if (subCategory.errorMessage.indexOf('enough storage') !==-1 && subCategory.errorMessage.indexOf('Upgrade') !== -1) {
                            subCategory.upgradeLink = true;
                        } else {
                            subCategory.errorMessage = 'There was an error trying to add the file.';
                        }
                    })
                    .finally(() => {
                        progressModal.close();
                        subCategory.uploading = false;
                    })
                ;
            },

            // Rejected callback
            angular.noop
        );
    }

    vm.uploadSubCategoryImage = function (data, estimate, subCategory) {
        var modalInstance;
        // Open upload progress modal
        modalInstance = $uibModal.open({
            scope: $scope,
            animation: false,
            controller: 'ProjectProgressModalCtrl',
            template: require('../../../common/templates/progress-modal.tpl.html'),
            resolve: {}
        });
        modalInstance.result.then(angular.noop, angular.noop);

        subCategory.uploading = true;
        strukshurApiService.projectEstimateSubCategoryImage.create(data).$promise
            .then(function (res) {
                if (!subCategory.images) {
                    subCategory.images = [];
                }

                subCategory.currentFile = null;
                subCategory.images.push(res.image);
            })
            .catch(function (res) {
                if (res.status === 403) {
                    subCategory.errorMessage = 'You don\'t have the necessary permission to add the image.';
                } else {
                    subCategory.errorMessage = 'There was an error trying to add the image.';
                }

                vm.goToEstimateSubCategoryError(subCategory);
            })
            .finally(function () {
                modalInstance.close();
                subCategory.uploading = false;
            })
        ;
    };

    /**
     * Removes the received sub category file from the server
     *
     * @param  {object}  subCategory  The sub category
     * @param  {object}  file         The file
     */
    vm.removeFile = function (subCategory, file) {
        var $childScope = vm.$new();
        $childScope.file = file;
        $childScope.project = vm.$parent.project;
        $childScope.title = 'Delete file';
        $childScope.message = 'Are you sure you want to delete the file?';

        var modalInstance = $uibModal.open({
            keyboard: true,
            scope: $childScope,
            controller: 'ProjectDetailDeleteEstimateSubCategoryFileCtrl',
            template: require('../../../common/templates/base.confirm-modal.tpl.html')
        });

        modalInstance.result.then(

            // Resolved callback
            function (data) {
                _pull(subCategory.files, file);
            },

            // Rejected callback
            angular.noop
        );
    };

    /**
     * Removes the received sub category image from the server
     *
     * @param  {object}  subCategory  The sub category
     * @param  {object}  image        The image
     */
    vm.removeImage = function (subCategory, image) {
        var $childScope = vm.$new();
        $childScope.image = image;
        $childScope.project = vm.$parent.project;
        $childScope.title = 'Delete image';
        $childScope.message = 'Are you sure you want to delete the image?';

        var modalInstance = $uibModal.open({
            keyboard: true,
            scope: $childScope,
            controller: 'ProjectDetailDeleteEstimateSubCategoryImageCtrl',
            template: require('../../../common/templates/base.confirm-modal.tpl.html')
        });

        modalInstance.result.then(

            // Resolved callback
            function (data) {
                _pull(subCategory.images, image);
            },

            // Rejected callback
            angular.noop
        );
    };

    /**
     * Scrolls to the estimate error message
     *
     * @param  {Object}  estimate  The estimate
     */
    vm.goToEstimateError = function (estimate) {
        smoothScroll(document.getElementById('estimate-error-' + estimate.id), { offset: 80 });
    };

    /**
     * Scrolls to the estimate sub category error message
     *
     * @param  {Object}  estimate  The estimate
     */
    vm.goToEstimateSubCategoryError = function (subCategory) {
        smoothScroll(document.getElementById('estimate-sub-category-error-' + subCategory.id), { offset: 80 });
    };

    /**
     * Updates the page items to show on the project sidebar
     */
    vm.updatePageItems = function() {
        var items = [];
        vm.estimates.forEach(function (item) {
            items.push({ title: item.title, href: 'estimate-' + item.id });
        });
        vm.$parent.setPageItems($state.current.name, items);
    };

    vm.init();
})

.controller('ProjectDetailNewEstimateCtrl', function ProjectDetailNewEstimateCtrl ($scope, $uibModalInstance, project, strukshurApiService) {
    var vm = $scope;

    vm.members = [];
    vm.loading = true;
    vm.errorMessage = '';
    vm.newEstimate = { title: '', collaborators: [] };

    // Load project members if necessary
    if (!vm.currentUserIsProAndNotMember) {
        strukshurApiService.projectTeamMembers
            .list({ project_id: project.id }).$promise
            .then(function (res) {
                res.members.forEach(function (member) {

                    // We don't add the current user or the project owner as collaborators for the estimate
                    if (![vm.$parent.currentUser.id, vm.$parent.$parent.project.owner_id].includes(member.id)) {
                        vm.members.push(member);
                    }
                });
            })
            .catch(function (res) {
                if (res.status === 403) {
                    vm.errorMessage = 'You don\'t have the necessary permission to load the collaborators.';
                } else {
                    vm.errorMessage = 'There was an error trying to load the collaborators.';
                }
            })
            .finally(() => vm.loading = false)
        ;
    } else {
        vm.loading = false;
    }

    /**
     * Saves the estimate
     *
     * @param  {Object}  form  The form
     */
    vm.saveEstimate = function (form) {
        vm.errorMessage = '';

        // Validates if a user or email was selected
        if (!vm.newEstimate.title) {
            vm.errorMessage = 'You need to give a name to the estimate.';
            return;
        }

        // Add the selected collaborators
        var collaborators = [];
        if (vm.newEstimate.collaborators) {
            vm.newEstimate.collaborators.forEach(function (collaborator) {
                collaborators.push(collaborator.id);
            });
        }

        var data = {
            project_id: project.id,
            title: vm.newEstimate.title,
            collaborators: '[' + collaborators.join(',') + ']'
        };

        // Invites the user to the team
        vm.loading = true;
        strukshurApiService.projectEstimate.create(data).$promise
            .then(function (res) {
                $uibModalInstance.close({ estimate: res.projectEstimate });
            })
            .catch(function (res) {
                if (res.status === 403) {
                    vm.errorMessage = 'You don\'t have the necessary permission to save the estimate.';
                } else {
                    vm.errorMessage = 'There was an error trying to save the estimate.';
                }
            })
            .finally(() => vm.loading = false)
        ;
    };
})

.controller('ProjectDetailEditEstimateCtrl', function ProjectDetailEditEstimateCtrl ($scope, $uibModalInstance, estimate, project, strukshurApiService) {
    var vm = $scope;

    vm.members = [];
    vm.loading = true;
    vm.errorMessage = '';
    vm.editEstimate = { title: estimate.title, collaborators: [] };

    // Loads project members if necessary
    if (!vm.currentUserIsProAndNotMember) {
        strukshurApiService.projectTeamMembers
            .list({ project_id: project.id }).$promise
            .then(function (res) {
                res.members.forEach(function (member) {

                    // We don't add the current user or the project owner as collaborators for the estimate
                    if (![vm.$parent.currentUser.id, vm.$parent.$parent.project.owner_id].includes(member.id)) {
                        vm.members.push(member);
                    }
                });

                if (estimate.collaborators) {
                    estimate.collaborators.forEach(function (user) {
                        var collaborator = _find(vm.members, { id: user.id });

                        if (collaborator) {
                            vm.editEstimate.collaborators.push(collaborator);
                        }
                    });
                }
            })
            .catch(function (res) {
                if (res.status === 403) {
                    vm.errorMessage = 'You don\'t have the necessary permission to load the collaborators.';
                } else {
                    vm.errorMessage = 'There was an error trying to load the collaborators.';
                }
            })
            .finally(() => vm.loading = false)
        ;
    } else {
        vm.loading = false;
    }

    /**
     * Saves the estimate
     *
     * @param  {Object}  form  The form
     */
    vm.saveEstimate = function (form) {
        vm.errorMessage = '';

        // Validates if a user or email was selected
        if (!vm.editEstimate.title) {
            vm.errorMessage = 'You need to give a name to the estimate.';
            return;
        }

        // Add the selected collaborators
        var collaborators = [];
        if (vm.editEstimate.collaborators) {
            vm.editEstimate.collaborators.forEach(function (collaborator) {
                collaborators.push(collaborator.id);
            });
        }

        var data = {
            estimate_id: estimate.id,
            title: vm.editEstimate.title,
            collaborators: '[' + collaborators.join(',') + ']',
            update_collaborators: 'y'
        };

        // Invites the user to the team
        vm.loading = true;
        strukshurApiService.projectEstimate.update(data).$promise
            .then(function (res) {
                $uibModalInstance.close({ estimate: res.projectEstimate });
            })
            .catch(function (res) {
                if (res.status === 403) {
                    vm.errorMessage = 'You don\'t have the necessary permission to update the estimate.';
                } else {
                    vm.errorMessage = 'There was an error trying to update the estimate.';
                }
            })
            .finally(() => vm.loading = false)
        ;
    };
})

.controller('ProjectDetailDuplicateEstimateCtrl', function ProjectDetailDuplicateEstimateCtrl ($scope, $uibModalInstance, estimate, project, strukshurApiService) {
    var vm = $scope;

    vm.loading = true;
    vm.projects = [];
    vm.errorMessage = '';
    vm.estimate = estimate;
    vm.loadingProjects = true;
    vm.newEstimate = { project: null, title: estimate.title + ' (duplicate)' };

    // Search list of available projects for the user
    strukshurApiService.projectprojects.query({ page: 1, limit: 50 }).$promise
        .then(function (res) {
            vm.loading = false;
            vm.projects = res.projects;
            vm.loadingProjects = false;
            vm.newEstimate.title = estimate.title + ' (duplicate)';
            vm.newEstimate.project = _find(vm.projects, { id: project.id });

            // Select the first project and disable the field if the user only
            // has one project to choose from
            if (vm.projects.length === 1) {
                vm.newEstimate.project = vm.projects[0];
            }
        })
        .catch(function (res) {
            if (res.status === 403) {
                vm.errorMessage = 'You don\'t have the necessary permission to load the projects.';
            } else {
                vm.errorMessage = 'There was an error trying to load the projects.';
            }
        })
    ;

    /**
     * Opens the modal to display the selected estimate on the server
     *
     * @param  {Object}  estimate  The estimate to duplicate
     */
    vm.duplicateEstimate = function (form) {
        if (!vm.newEstimate.title) {
            vm.errorMessage = 'The new estimate needs to have a title.';
            return;
        }

        if (!vm.newEstimate.project.id) {
            vm.errorMessage = 'You need to select a project to duplicate the estimate.';
            return;
        }

        var data = {
            project_id: vm.newEstimate.project.id,
            estimate_id: vm.estimate.id,
            title: vm.newEstimate.title
        };

        // Duplicates the current estimate
        vm.loading = true;
        vm.errorMessage = '';
        strukshurApiService.projectEstimate.duplicate(data).$promise
            .then(function (res) {
                $uibModalInstance.close({ estimate: res.projectEstimate });
            })
            .catch(function (res) {
                if (res.status === 403) {
                    vm.errorMessage = 'You don\'t have the necessary permission to duplicate the estimate.';
                } else {
                    vm.errorMessage = 'There was an error trying to duplicate the estimate.';
                }
            })
            .finally(() => vm.loading = false)
        ;
    };
})

.controller('ProjectDetailDeleteEstimateCtrl', function ProjectDetailDeleteEstimateCtrl ($scope, $uibModalInstance, strukshurApiService) {
    var vm = $scope;

    vm.onConfirmChosen = function () {

        // Deletes the estimate on the server
        vm.loading = true;
        strukshurApiService.projectEstimate.delete({ estimate_id: vm.estimate.id }).$promise
            .then(function (res) {
                $uibModalInstance.close();
            })
            .catch(function (res) {
                if (res.status === 403) {
                    vm.errorMessage = 'You don\'t have the necessary permission to delete the estimate.';
                } else {
                    vm.errorMessage = 'There was an error trying to delete the estimate.';
                }
            })
            .finally(() => vm.loading = false)
        ;
    };
})

.controller('ProjectDetailEstimateMarkAsFinalCtrl', function ProjectDetailEstimateMarkAsFinalCtrl ($scope, $uibModalInstance, strukshurApiService) {
    var vm = $scope;

    vm.onConfirmChosen = function () {

        // Deletes the estimate on the server
        vm.loading = true;
        strukshurApiService.projectEstimate.markAsFinal({ estimate_id: vm.estimate.id }).$promise
            .then(function (res) {
                $uibModalInstance.close();
            })
            .catch(function (res) {
                if (res.status === 403) {
                    vm.errorMessage = 'You don\'t have the necessary permission to mark the estimate as final.';
                } else {
                    vm.errorMessage = 'There was an error trying to mark the estimate as final.';
                }
            })
            .finally(() => vm.loading = false)
        ;
    };
})

.controller('ProjectDetailEditEstimateCategoryCtrl', function ProjectDetailEditEstimateCategoryCtrl ($scope, $uibModalInstance, category, estimate, project, strukshurApiService) {
    var vm = $scope;

    vm.members = [];
    vm.loading = true;
    vm.errorMessage = '';
    vm.category = { title: category.title, collaborators: [] };

    // Loads project members if necessary
    if (!vm.currentUserIsProAndNotMember) {
        strukshurApiService.projectTeamMembers
            .list({ project_id: project.id }).$promise
            .then(function (res) {
                res.members.forEach(function (member) {

                    // We don't add the current user or the project owner as collaborators for the estimate
                    if (![vm.$parent.currentUser.id, vm.$parent.$parent.project.owner_id].includes(member.id)) {
                        vm.members.push(member);
                    }
                });

                if (category.collaborators) {
                    category.collaborators.forEach(function (user) {
                        var collaborator = _find(vm.members, { id: user.id });

                        if (collaborator) {
                            vm.category.collaborators.push(collaborator);
                        }
                    });
                }
            })
            .catch(function (res) {
                if (res.status === 403) {
                    vm.errorMessage = 'You don\'t have the necessary permission to load the collaborators.';
                } else {
                    vm.errorMessage = 'There was an error trying to load the collaborators.';
                }
            })
            .finally(() => vm.loading = false)
        ;
    } else {
        vm.loading = false;
    }

    /**
     * Saves the estimate category
     */
    vm.save = function () {

        // Add the selected collaborators
        var collaborators = [];
        if (vm.category.collaborators) {
            vm.category.collaborators.forEach(function (collaborator) {
                collaborators.push(collaborator.id);
            });
        }

        var data = {
            category_id: category.id,
            title: vm.category.title,
            collaborators: '[' + collaborators.join(',') + ']',
            update_collaborators: 'y'
        };

        vm.loading = true;
        vm.errorMessage = '';
        strukshurApiService.projectEstimateCategory.update(data).$promise
            .then(function (res) {
                $uibModalInstance.close(res);
            })
            .catch(function (res) {
                if (res.status === 403) {
                    vm.errorMessage = 'You don\'t have the necessary permission to save the category.';
                } else {
                    vm.errorMessage = 'There was an error trying to save the category.';
                }
            })
            .finally(() => vm.loading = false)
        ;
    };
})

.controller('ProjectDetailDeleteEstimateCategoryCtrl', function ProjectDetailDeleteEstimateCategoryCtrl ($scope, $uibModalInstance, strukshurApiService) {
    var vm = $scope;

    vm.onConfirmChosen = function () {

        // Deletes the estimate on the server
        vm.loading = true;
        strukshurApiService.projectEstimateCategory.delete({ category_id: vm.category.id }).$promise
            .then(function (res) {
                $uibModalInstance.close();
            })
            .catch(function (res) {
                if (res.status === 403) {
                    vm.errorMessage = 'You don\'t have the necessary permission to delete the category.';
                } else {
                    vm.errorMessage = 'There was an error trying to delete the category.';
                }
            })
            .finally(() => vm.loading = false)
        ;
    };
})

.controller('ProjectDetailAddEstimateSubCategoryCtrl', function ProjectDetailAddEstimateSubCategoryCtrl ($scope, $uibModalInstance, category, estimate, project, strukshurApiService) {
    var vm = $scope;

    vm.members = [];
    vm.loading = false;
    vm.errorMessage = '';
    vm.title = '';

    /**
     * Saves the collaborators
     *
     * @param  {Object}  form  The form
     */
    vm.save = function (form) {
        vm.loading = true;
        vm.errorMessage = '';

        var data = {
            project_id: project.id,
            estimate_id: estimate.id,
            category_id: category.id,
            title: vm.title
        };

        strukshurApiService.projectEstimateSubCategory.create(data).$promise
            .then(function (res) {
                $uibModalInstance.close(res);
            })
            .catch(function (res) {
                if (res.status === 403) {
                    vm.errorMessage = 'You don\'t have the necessary permission to save the new sub category.';
                } else {
                    vm.errorMessage = 'There was an error trying to save the new sub category.';
                }
            })
            .finally(() => vm.loading = false)
        ;
    };
})

.controller('ProjectDetailEditEstimateSubCategoryCtrl', function ProjectDetailEditEstimateSubCategoryCtrl ($scope, $uibModalInstance, estimate, project, subCategory, strukshurApiService) {
    var vm = $scope;

    vm.members = [];
    vm.loading = true;
    vm.errorMessage = '';
    vm.subCategory = { title: subCategory.title, collaborators: [] };

    // Loads project members if necessary
    if (!vm.currentUserIsProAndNotMember) {
        strukshurApiService.projectTeamMembers
            .list({ project_id: project.id }).$promise
            .then(function (res) {
                res.members.forEach(function (member) {

                    // We don't add the current user or the project owner as collaborators for the estimate
                    if (![vm.$parent.currentUser.id, vm.$parent.$parent.project.owner_id].includes(member.id)) {
                        vm.members.push(member);
                    }
                });

                if (subCategory.collaborators) {
                    subCategory.collaborators.forEach(function (user) {
                        var collaborator = _find(vm.members, { id: user.id });

                        if (collaborator) {
                            vm.subCategory.collaborators.push(collaborator);
                        }
                    });
                }
            })
            .catch(function (res) {
                if (res.status === 403) {
                    vm.errorMessage = 'You don\'t have the necessary permission to load the collaborators.';
                } else {
                    vm.errorMessage = 'There was an error trying to load the collaborators.';
                }
            })
            .finally(() => vm.loading = false)
        ;
    } else {
        vm.loading = false;
    }

    /**
     * Saves the collaborators
     *
     * @param  {Object}  form  The form
     */
    vm.save = function (form) {

        // Add the selected collaborators
        var collaborators = [];
        if (vm.subCategory.collaborators) {
            vm.subCategory.collaborators.forEach(function (collaborator) {
                collaborators.push(collaborator.id);
            });
        }

        var data = {
            sub_category_id: subCategory.id,
            title: vm.subCategory.title,
            collaborators: '[' + collaborators.join(',') + ']',
            update_collaborators: 'y'
        };

        vm.loading = true;
        vm.errorMessage = '';
        strukshurApiService.projectEstimateSubCategory.update(data).$promise
            .then(function (res) {
                $uibModalInstance.close(res);
            })
            .catch(function (res) {
                if (res.status === 403) {
                    vm.errorMessage = 'You don\'t have the necessary permission to save the sub category.';
                } else {
                    vm.errorMessage = 'There was an error trying to save the sub category.';
                }
            })
            .finally(() => vm.loading = false)
        ;
    };
})

.controller('ProjectDetailDeleteEstimateSubCategoryCtrl', function ProjectDetailDeleteEstimateSubCategoryCtrl ($scope, $uibModalInstance, strukshurApiService) {
    var vm = $scope;

    vm.onConfirmChosen = function () {

        // Deletes the estimate on the server
        vm.loading = true;
        strukshurApiService.projectEstimateSubCategory.delete({ sub_category_id: vm.subCategory.id }).$promise
            .then(function (res) {
                $uibModalInstance.close();
            })
            .catch(function (res) {
                if (res.status === 403) {
                    vm.errorMessage = 'You don\'t have the necessary permission to delete the sub category.';
                } else {
                    vm.errorMessage = 'There was an error trying to delete the sub category.';
                }
            })
            .finally(() => vm.loading = false)
        ;
    };
})

.controller('ProjectDetailDeleteEstimateSubCategoryImageCtrl', function ProjectDetailDeleteEstimateSubCategoryImageCtrl ($scope, $uibModalInstance, strukshurApiService) {
    var vm = $scope;

    vm.onConfirmChosen = function () {

        // Deletes the estimate on the server
        vm.loading = true;
        strukshurApiService.projectEstimateSubCategoryImage.delete({ image_id: vm.image.id }).$promise
            .then(function (res) {
                $uibModalInstance.close();
            })
            .catch(function (res) {
                if (res.status === 403) {
                    vm.errorMessage = 'You don\'t have the necessary permission to delete the image.';
                } else {
                    vm.errorMessage = 'There was an error trying to delete the sub category image.';
                }
            })
            .finally(() => vm.loading = false)
        ;
    };
})

.controller('ProjectDetailDeleteEstimateSubCategoryFileCtrl', function ProjectDetailDeleteEstimateSubCategoryFileCtrl ($scope, $uibModalInstance, strukshurApiService) {
    var vm = $scope;

    vm.onConfirmChosen = function () {

        // Deletes the estimate on the server
        vm.loading = true;
        strukshurApiService.projectEstimateSubCategoryFile.delete({ file_id: vm.file.id }).$promise
            .then(() => $uibModalInstance.close())
            .catch(function (res) {
                if (res.status === 403) {
                    vm.errorMessage = 'You don\'t have the necessary permission to delete the file.';
                } else {
                    vm.errorMessage = 'There was an error trying to delete the sub category file.';
                }
            })
            .finally(() => vm.loading = false)
        ;
    };
})

;
