import * as angular from 'angular';

angular.module('strukshurApp.estimate', ['ui.router', 'ui.bootstrap'])

.config(function config($stateProvider) {
    $stateProvider
        .state('estimate', {
            url: '/estimate/:estimate_id',
            views: {
                'main': {
                    controller: 'EstimateViewCtrl',
                    template: require('./estimate.tpl.html')
                }
            },
            resolve: {
                isLoggedIn: function($state, $q, strukshurUserService, InitService) {
                    return InitService.promise.then(function (d) {
                        if (strukshurUserService.isLoggedIn() === false) {
                            $state.go('home');

                            return false;
                        }

                        return true;
                    });
                }
            },
            data: { pageTitle: 'Estimate', class: 'estimate' }
        })

        .state('estimate.view', {
            url: '/view',
            views: {
                'content': {
                    controller: 'EstimateViewCtrl',
                    template: require('./view.tpl.html')
                }
            },
            data: { pageTitle: 'View Estimate', class: 'estimate estimate-view' }
        })

        .state('estimate.version', {
            url: '/version/:version_id',
            views: {
                'content': {
                    controller: 'EstimateVersionViewCtrl',
                    template: require('./version_view.tpl.html')
                }
            },
            data: { pageTitle: 'View Estimate Version', class: 'estimate estimate-version-view' }
        })
    ;
})

.controller('EstimateCtrl', angular.noop)

.controller('EstimateViewCtrl', function EstimateViewCtrl($scope, $stateParams, strukshurApiService) {
    var vm = $scope;

    vm.loading = true;
    vm.estimate = null;
    vm.errorMessage = '';

    // Load the project estimate
    strukshurApiService.projectEstimate.get({ estimate_id: $stateParams.estimate_id }).$promise
        .then(function (res) {
            vm.estimate = res.projectEstimate;
        })
        .catch(function () {
            vm.errorMessage = 'There was an error while loading the project estimate.';
        })
        .finally(function () {
            vm.loading = false;
        })
    ;
})

.controller('EstimateVersionViewCtrl', angular.noop)

;
