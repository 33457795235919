import { remove as _remove } from 'lodash';
import * as angular from 'angular';
import * as isMobile from 'ismobilejs';
import * as EXIF from 'exif-js';
import * as moment from 'moment';
import strukshurConfig from '../../config.ts';

angular.module('strukshurApp.settings', ['ui.router', 'ui.bootstrap'])

.config(function config($stateProvider) {
    $stateProvider.state('account-settings', {
        url: '/account-settings?active_tab',
        views: {
            main: {
                controller: 'AccountSettingsCtrl',
                template: require('./account-settings.tpl.html'),
                resolve: {
                    user: function (strukshurUserService) {
                        return strukshurUserService.getUser();
                    }
                }
            }
        },
        data: { pageTitle: 'Account Settings', class: 'account-settings' }
    });
})

.controller('AccountSettingsCtrl', function AccountSettingsCtrl ($aside, $scope, $state, $stateParams, $timeout, $uibModal, ContractorRoleService, Flash, PricingService, strukshurApiService, strukshurAuthUserService, strukshurPinterestService, strukshurUserService, TimezoneService, toastr, user) {
    var vm = $scope;
    var i, tot, tmpProfileImg, tmpBusinessLogo;

    vm.form = {};
    vm.user = user;
    vm.formData = {};
    vm.timezones = [];
    vm.loading = false;
    vm.user_backup = {};
    vm.integrations = [];
    vm.organizations = [];
    vm.availableRoles = [];
    vm.frequency = 'annual';
    vm.user.plan_index = 0;
    vm.changingEmail = false;
    vm.successMessage = false;
    vm.accountSettingsMenu = null;
    vm.loadingProfileImage = false;
    vm.loadingBusinessLogo = false;
    vm.passwordInfoChanged = false;
    vm.basicSettingsChanged = false;
    vm.loadingOrganizations = false;
    vm.businessSettingsChanged = false;
    vm.notificationSettingsChanged = false;
    vm.prices = PricingService.getNormal();
    vm.prices_pro = PricingService.getPro();
    vm.active_tab = $stateParams.active_tab || 'basic';
    vm.stripeClientId = strukshurConfig.stripe_client_id;
    vm.isMissingPayment = strukshurUserService.isMissingPayment();

    /**
     * Bootstrap logic for the current controller
     */
    vm.init = () => {

        // Loads list of available timezones from the server
        TimezoneService.getTimezones().then((res) => {
            vm.timezones = res;
            vm.formData.timezone = TimezoneService.getTimezone(vm.user.timezone);
        });

        // Makes a copy of the avatar images in order for the  previous one to not
        // be changed unless the user updates their avatar
        tmpProfileImg = vm.user.profileImage;
        vm.user.profileImageTmp = tmpProfileImg;
        vm.profileImage = tmpProfileImg;

        tmpBusinessLogo = vm.user.bgImage;
        vm.user.proBusinessLogoTmp = tmpBusinessLogo;
        vm.companyLogo = tmpBusinessLogo;

        // Upgrade vs change my account
        if (vm.user.userType === 'pro') {
            tot = vm.prices_pro.length;
            for (i = 0; i < tot; i++) {
                if (vm.prices_pro[i].plan === vm.user.plan) {
                    vm.user.plan_index = i;
                    break;
                }
            }
        } else {
            tot = vm.prices.length;
            for (i = 0; i < tot; i++) {
                if (vm.prices[i].plan === vm.user.plan) {
                    vm.user.plan_index = i;
                    break;
                }
            }
        }

        // Loads the list of available roles if the user is a pro
        if (vm.user.userType === 'pro' || vm.user.userType === 'admin') {
            ContractorRoleService.getRoles().then(function (res) {
                vm.availableRoles = res;

                // Set selected category object based on the category name chosen by the user
                vm.availableRoles.forEach(function (role) {
                    if (role.name === vm.user.category) {
                        vm.formData.category = role;
                        return;
                    }
                });
            });
        }

        // Saves copy in case user cancels before saving
        vm.user_backup = {};
        angular.copy(vm.user, vm.user_backup);

        // Setup available integrations
        if (vm.user.pinterestAccessToken) {
            let integration: any = { id: 'pinterest', name: 'Pinterest', iconClass: 'fa-pinterest' };
            if (vm.user.pinterestAccessTokenUpdatedAt) {
                integration.linkedAt = vm.user.pinterestAccessTokenUpdatedAt;
            }
            vm.integrations.push(integration);
        }

        // Fixed float issue for the tabset container after loading since we can't
        // just add a clearfix element on its template due to the component's lack
        // of transclusion support
        $timeout(() => angular.element('.tabset-container').append('<div class="clearfix"></div>'), 50);

        vm.$watch('user.receiveProjectInvitationsByEmail', vm.checkNotificationSettingsChanged);
        vm.$watch('user.receiveProjectNotificationsByEmail', vm.checkNotificationSettingsChanged);
    };

    /**
     * Marks the basic settings info section as changed if needed
     */
    vm.checkBasicSettingsChanged = function () {
        var wasChanged = (
            (vm.user.fullName !== vm.user_backup.fullName) ||
            (vm.user.basicCity !== vm.user_backup.basicCity) ||
            (vm.user.basicState !== vm.user_backup.basicState) ||
            (vm.user.phoneNumber !== vm.user_backup.phoneNumber) ||
            (vm.user.profileImage != vm.user_backup.profileImage) ||
            (vm.formData.timezone.value !== vm.user_backup.timezone)
        );

        vm.basicSettingsChanged = wasChanged;
    };

    /**
     * Remove the received integration
     *
     * @param integration The integration to remove
     */
    vm.removeIntegration = (integration) => {
        if (integration.id === 'pinterest') {
            strukshurPinterestService.removeAccessToken()
                .then(() => {
                    strukshurUserService.setPinterestAccessToken(null);
                    _remove(vm.integrations, { id: integration.id });
                })
                .catch((err) => {
                    if (err && err.data && err.data.message) {
                        Flash.create('danger', err.data.message, 10000, { container: 'integrations-section-flash' }, true);
                    } else {
                        Flash.create('danger', 'There was an error removing the integration.', 10000, { container: 'integrations-section-flash' }, true);
                    }
                })
            ;
        }
    };

    /**
     * Marks the business settings info section as changed if needed
     */
    vm.checkBusinessSettingsChanged = function () {
        var wasChanged = (
            (vm.user.businessName !== vm.user_backup.businessName) ||
            (vm.user.companyLogo !== undefined && vm.user.companyLogo != vm.user_backup.companyLogo) ||
            (vm.user.phoneNumber !== vm.user_backup.phoneNumber) ||
            (vm.user.faxNumber !== vm.user_backup.faxNumber) ||
            (vm.user.founded !== vm.user_backup.founded) ||
            (vm.user.stateContractorLic !== vm.user_backup.stateContractorLic) ||
            (vm.user.hours !== vm.user_backup.hours) ||
            (vm.user.bbb !== vm.user_backup.bbb) ||
            (vm.user.employees !== vm.user_backup.employees) ||
            (vm.user.description !== vm.user_backup.description) ||
            (vm.user.website !== vm.user_backup.website) ||
            (vm.user.fbUrl !== vm.user_backup.fbUrl) ||
            (vm.user.yelpUrl !== vm.user_backup.yelpUrl) ||
            (vm.formData.category.name !== vm.user_backup.category)
        );

        vm.businessSettingsChanged = wasChanged;
    };

    /**
     * Marks the notification settings section as changed if needed
     */
    vm.checkNotificationSettingsChanged = function () {
        var wasChanged = (
            (vm.user.receiveProjectInvitationsByEmail !== vm.user_backup.receiveProjectInvitationsByEmail) ||
            (vm.user.receiveProjectNotificationsByEmail !== vm.user_backup.receiveProjectNotificationsByEmail)
        );

        vm.notificationSettingsChanged = wasChanged;
    };

    /**
     * Marks the password info as changed if needed
     */
    vm.checkPasswordInfoChanged = function () {
        var wasChanged = (
            (vm.user.currentPassword != '') ||
            (vm.user.newPassword != '') ||
            (vm.user.newPasswordConfirmation != '')
        );

        vm.passwordInfoChanged = wasChanged;
    };

    /**
     * Navigates to the tab with the given index
     *
     * @param  {string}  index  The tab index
     */
    vm.goToTab = function (index) {

        // Does not navigate to the given tab since it's already the current one
        if (vm.active_tab === index) { return; }

        vm.active_tab = index;
        vm.accountSettingsMenu.hide();
    };

    /**
     * Opens the account settings menu aside
     */
    vm.openAccountSettingsMenu = function () {
        vm.accountSettingsMenu = $aside({
            title: 'Account Settings Menu',
            template: require('../../common/templates/accountSettingsMenu.tpl.html'),
            controller: angular.noop,
            placement: 'left',
            animation: 'am-fade-and-slide-left',
            scope: vm,
            show: true
        });
    };

    /**
     * Returns the formatted price of the given plan
     *
     * @param  {object}  plan  The plan
     *
     * @return {float}
     */
    vm.getPrice = function (plan) {
        return Math.floor(plan.price);
    };

    vm.getCents = function (plan) {
        var price = Math.round((plan.price - Math.floor(plan.price)) * 100);

        if (price > 0 && price < 10) {
            return '0' + String(price);
        } else {
            return price;
        }
    };

    vm.popCancelSub = function () {
        vm.confirmMessage = false;
        var modalInstance = $uibModal.open({
            animation: true,
            keyboard: true,
            scope: vm,
            ariaLabelledBy: 'modal-title',
            ariaDescribedBy: 'modal-body',
            controller: 'ConfirmCancelSubscriptionCtrl',
            template: require('../../common/templates/confirm-modal.tpl.html'),
            resolve: {
                user: function () {
                    return vm.user;
                },
                message: function () {
                    return 'Are you sure you\'d like to cancel your subscription?';
                }
            }
        });
        modalInstance.result.then(function (selectedItem) {

            // Updates global user
            if (selectedItem.success) {
                strukshurUserService.setUser({});
                strukshurUserService.getUser();

                $state.go('home');
            }
            toastr.success('The subscription was cancelled successfully.', 'Success!');
        }, function () {
            // cancel confirmation
        });
    };

    vm.upgradePlan = function (plan) {
        var modalInstance, planType = PricingService.getPlanUserType(plan);

        // from standard to pro we need company info
        vm.confirmMessage = false;
        if (vm.user.userType === 'standard' && planType === 'pro') {
            modalInstance = $uibModal.open({
                animation: true,
                keyboard: true,
                scope: vm,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                controller: 'UpgradeToProCtrl',
                template: require('../../common/templates/pro-details-modal.tpl.html'),
                resolve: {
                    plan: function () {
                        return plan;
                    },
                    user: function () {
                        return vm.user;
                    }
                }
            });
        } else {

            // just a normal confirmation popup
            modalInstance = $uibModal.open({
                animation: true,
                keyboard: true,
                scope: vm,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                controller: 'ConfirmUpgradeSubscriptionCtrl',
                template: require('../../common/templates/confirm-modal.tpl.html'),
                resolve: {
                    plan: function () {
                        return plan;
                    },
                    message: function () {
                        return 'Are you sure you\'d like to change your current subscription?';
                    }
                }
            });
        }

        modalInstance.result.then(function (data) {
            if (data.success) {
                var user = data.user;

                // Update global user
                vm.user = user;
                strukshurUserService.setUser(user);
                strukshurAuthUserService.setUser(user);
                toastr.success('The subscription was updated successfully.', 'Success!');

                // Redirects user to payment registration screen if we need to place a new charge
                if (!user.proConfirmed && user.plan !== 'basic' && user.plan !== 'pro-basic' && (
                        (user.userType === 'standard') ||
                        (user.userType === 'pro' && moment(new Date()).diff(user.createdAt, 'days') > 30) ||
                        (user.plan !== 'pro-monthly-tier-1' &&  user.plan !== 'pro-annual-tier-1')
                    ))
                {
                    if (user.userType === 'standard') {
                        $state.go('register-payment');
                    } else {
                        $state.go('pro-register-payment');
                    }
                }
            }
        }, function () {
            //cancel confirmation
        });
    };

    /**
     * Retrieves plan name
     */
    vm.getPlan = PricingService.getName;

    /**
     * Shows fields to change account email
     */
    vm.changeEmail = function () {
        vm.changingEmail = true;
    };

    /**
     * To prevent default form action
     */
    vm.formSubEnter = function () {
        return false;
    };

    /**
     * Shows fields to change account password
     */
    vm.changePassword = function () {
        vm.changingPassword = true;
        delete vm.user.currentPassword;
        delete vm.user.newPassword;
        delete vm.user.newPasswordConfirmation;
    };

    /**
     * Shows fields to change account password
     */
    vm.hidePassword = function () {
        vm.changingPassword = false;
        delete vm.user.currentPassword;
        delete vm.user.newPassword;
        delete vm.user.newPasswordConfirmation;
    };

    /**
     * Handles image selection to be the new profile image
     */
    vm.selectProfileImage = function (eventObject, fileReader, file) {
        vm.loadingProfileImage = false;

        if (!file || file.length === 0) {
            return;
        }

        if (vm.user.profileImageTmp && !vm.user.profileImageTmp.base64) {
            vm.user.profileImageTmp = null;
            Flash.create('danger', 'The selected image is invalid.', 10000, { container: 'basic-section-flash' }, true);
            return;
        }

        if (file[0].size > 1 * 1000 * 1000) {
            vm.user.profileImageTmp = null;
            Flash.create('danger', 'Image size must be less than 1MB.', 10000, { container: 'basic-section-flash' }, true);
            return;
        }

        if (file[0].type.substr(0, 5) !== 'image') {
            vm.user.profileImageTmp = null;
            Flash.create('danger', 'The selected file is not an image.', 10000, { container: 'basic-section-flash' }, true);
            return;
        } else {

            // Resize
            var dataUrlImage;
            var canvas, MAX_WIDTH, MAX_HEIGHT, width, height, ctx, img;
            MAX_WIDTH = 2048;
            MAX_HEIGHT = 2048;

            if (vm.user.profileImageTmp && vm.user.profileImageTmp.base64) {

                // Compress image
                vm.loadingProfileImage = true;
                img = document.createElement('img');
                img.setAttribute('crossOrigin', 'anonymous');

                img.onload = function (ev) {
                    var resize;
                    width = img.width;
                    height = img.height;

                    if (width > height) {
                        if (width > MAX_WIDTH) {
                            height *= MAX_WIDTH / width;
                            width = MAX_WIDTH;
                        }
                    } else {
                        if (height > MAX_HEIGHT) {
                            width *= MAX_HEIGHT / height;
                            height = MAX_HEIGHT;
                        }
                    }

                    // Resize only if needed
                    if (width !== img.width || height !== img.height) {
                        canvas = document.createElement('canvas');
                        canvas.width = width;
                        canvas.height = height;
                        ctx = canvas.getContext('2d');
                        ctx.drawImage(img, 0, 0, width, height);

                        canvas.setAttribute('crossOrigin', 'anonymous');

                        dataUrlImage = canvas.toDataURL(vm.user.profileImageTmp.filetype);
                        vm.user.profileImageTmp.base64 = dataUrlImage.substr(dataUrlImage.indexOf(',') + 1);
                        vm.profileImage = 'data:'+vm.user.profileImageTmp.filetype+';base64,'+vm.user.profileImageTmp.base64;
                        dataUrlImage = null;
                        canvas = null;
                        ctx = null;
                    }

                    //if iOS rotate if needed
                    if (isMobile.apple.device || isMobile.apple.ipod || isMobile.apple.phone || isMobile.apple.tablet) {
                        resize = document.createElement('img');
                        resize.setAttribute('crossOrigin', 'anonymous');

                        resize.onload = function (event) {
                            width = resize.width;
                            height = resize.height;
                            EXIF.getData(img, function () {
                                var orientation = EXIF.getTag(this, 'Orientation');
                                canvas = document.createElement('canvas');
                                ctx = canvas.getContext('2d');
                                if (orientation && orientation > 4) {
                                    canvas.width = height;
                                    canvas.height = width;
                                } else {
                                    canvas.width = width;
                                    canvas.height = height;
                                }
                                switch (orientation) {
                                    case 2:
                                        // horizontal flip
                                        ctx.translate(width, 0);
                                        ctx.scale(-1, 1);
                                        break;
                                    case 3:
                                        // 180° rotate left
                                        ctx.translate(width, height);
                                        ctx.rotate(Math.PI);
                                        break;
                                    case 4:
                                        // vertical flip
                                        ctx.translate(0, height);
                                        ctx.scale(1, -1);
                                        break;
                                    case 5:
                                        // vertical flip + 90 rotate right
                                        ctx.rotate(0.5 * Math.PI);
                                        ctx.scale(1, -1);
                                        break;
                                    case 6:
                                        // 90° rotate right
                                        ctx.rotate(0.5 * Math.PI);
                                        ctx.translate(0, -height);
                                        break;
                                    case 7:
                                        // horizontal flip + 90 rotate right
                                        ctx.rotate(0.5 * Math.PI);
                                        ctx.translate(width, -height);
                                        ctx.scale(-1, 1);
                                        break;
                                    case 8:
                                        // 90° rotate left
                                        ctx.rotate(-0.5 * Math.PI);
                                        ctx.translate(-width, 0);
                                        break;
                                    default:
                                        vm.profileImage = 'data:'+vm.user.profileImageTmp.filetype+';base64,'+vm.user.profileImageTmp.base64;
                                        vm.basicSettingsChanged = true;
                                        dataUrlImage = null;
                                        resize = null;
                                        img = null;
                                        canvas = null;
                                        ctx = null;
                                        vm.$apply();
                                        return;
                                }
                                if (orientation && orientation > 4) {
                                    ctx.drawImage(resize, 0, 0);
                                } else {
                                    ctx.drawImage(resize, 0, 0, width, height);
                                }

                                canvas.setAttribute('crossOrigin', 'anonymous');
                                dataUrlImage = canvas.toDataURL('image/jpeg');
                                vm.user.profileImageTmp.filetype = 'image/jpeg';
                                vm.user.profileImageTmp.base64 = dataUrlImage.substr(dataUrlImage.indexOf(',') + 1);
                                vm.profileImage = 'data:'+vm.user.profileImageTmp.filetype+';base64,'+vm.user.profileImageTmp.base64;
                                dataUrlImage = null;
                                resize = null;
                                img = null;
                                canvas = null;
                                ctx = null;
                                vm.$apply();
                            });
                        };
                        resize.src = 'data:' + vm.user.profileImageTmp.filetype + ';base64,' + vm.user.profileImageTmp.base64;
                    } else {
                        vm.profileImage = 'data:'+vm.user.profileImageTmp.filetype+';base64,'+vm.user.profileImageTmp.base64;
                        img = null;
                    }

                    vm.loadingProfileImage = false;
                    vm.basicSettingsChanged = true;
                };
                img.src = 'data:' + vm.user.profileImageTmp.filetype + ';base64,' + vm.user.profileImageTmp.base64;
            }
        }
    };

    /**
     * Handles image selection to be the new business logo
     */
    vm.selectBusinessLogo = function (eventObject, fileReader, file) {
        vm.loadingBusinessLogo = false;

        if (!file || file.length === 0) {
            return;
        }

        if (vm.user.proBusinessLogoTmp && !vm.user.proBusinessLogoTmp.base64) {
            vm.user.proBusinessLogoTmp = null;
            Flash.create('danger', 'The selected image is invalid.', 10000, { container: 'basic-section-flash' }, true);
            return;
        }
        if (file[0].size > 2 * 1000 * 1000) {
            vm.user.proBusinessLogoTmp = null;
            Flash.create('danger', 'Image must be less than 2MB.', 10000, { container: 'basic-section-flash' }, true);
            return;
        }

        if (file[0].type.substr(0, 5) !== 'image') {
            vm.user.proBusinessLogoTmp = null;
            Flash.create('danger', 'The selected file is not an image.', 10000, { container: 'basic-section-flash' }, true);
            return;
        } else {

            // Resize
            var dataUrlImage;
            var canvas, MAX_WIDTH, MAX_HEIGHT, width, height, ctx, img;
            MAX_WIDTH = 2048;
            MAX_HEIGHT = 2048;

            if (vm.user.proBusinessLogoTmp && vm.user.proBusinessLogoTmp.base64) {

                //compress image
                vm.loadingBusinessLogo = true;
                img = document.createElement('img');
                img.setAttribute('crossOrigin', 'anonymous');

                img.onload = function (ev) {
                    var resize;
                    width = img.width;
                    height = img.height;

                    if (width > height) {
                        if (width > MAX_WIDTH) {
                            height *= MAX_WIDTH / width;
                            width = MAX_WIDTH;
                        }
                    } else {
                        if (height > MAX_HEIGHT) {
                            width *= MAX_HEIGHT / height;
                            height = MAX_HEIGHT;
                        }
                    }

                    // Resize only if needed
                    if (width !== img.width || height !== img.height) {
                        canvas = document.createElement('canvas');
                        canvas.width = width;
                        canvas.height = height;
                        ctx = canvas.getContext('2d');
                        ctx.drawImage(img, 0, 0, width, height);

                        canvas.setAttribute('crossOrigin', 'anonymous');

                        dataUrlImage = canvas.toDataURL(vm.user.proBusinessLogoTmp.filetype);
                        vm.user.proBusinessLogoTmp.base64 = dataUrlImage.substr(dataUrlImage.indexOf(',') + 1);
                        vm.companyLogo = 'data:'+vm.user.proBusinessLogoTmp.filetype+';base64,'+vm.user.proBusinessLogoTmp.base64;
                        dataUrlImage = null;
                        canvas = null;
                        ctx = null;
                    }

                    //if iOS rotate if needed
                    if (isMobile.apple.device || isMobile.apple.ipod || isMobile.apple.phone || isMobile.apple.tablet) {
                        resize = document.createElement('img');
                        resize.setAttribute('crossOrigin', 'anonymous');

                        resize.onload = function (event) {
                            width = resize.width;
                            height = resize.height;
                            EXIF.getData(img, function () {
                                var orientation = EXIF.getTag(this, 'Orientation');
                                canvas = document.createElement('canvas');
                                ctx = canvas.getContext('2d');
                                if (orientation && orientation > 4) {
                                    canvas.width = height;
                                    canvas.height = width;
                                } else {
                                    canvas.width = width;
                                    canvas.height = height;
                                }
                                switch (orientation) {
                                    case 2:
                                        // horizontal flip
                                        ctx.translate(width, 0);
                                        ctx.scale(-1, 1);
                                        break;
                                    case 3:
                                        // 180° rotate left
                                        ctx.translate(width, height);
                                        ctx.rotate(Math.PI);
                                        break;
                                    case 4:
                                        // vertical flip
                                        ctx.translate(0, height);
                                        ctx.scale(1, -1);
                                        break;
                                    case 5:
                                        // vertical flip + 90 rotate right
                                        ctx.rotate(0.5 * Math.PI);
                                        ctx.scale(1, -1);
                                        break;
                                    case 6:
                                        // 90° rotate right
                                        ctx.rotate(0.5 * Math.PI);
                                        ctx.translate(0, -height);
                                        break;
                                    case 7:
                                        // horizontal flip + 90 rotate right
                                        ctx.rotate(0.5 * Math.PI);
                                        ctx.translate(width, -height);
                                        ctx.scale(-1, 1);
                                        break;
                                    case 8:
                                        // 90° rotate left
                                        ctx.rotate(-0.5 * Math.PI);
                                        ctx.translate(-width, 0);
                                        break;
                                    default:
                                        vm.companyLogo = 'data:'+vm.user.proBusinessLogoTmp.filetype+';base64,'+vm.user.proBusinessLogoTmp.base64;
                                        vm.businessSettingsChanged = true;
                                        dataUrlImage = null;
                                        resize = null;
                                        img = null;
                                        canvas = null;
                                        ctx = null;
                                        vm.$apply();
                                        return;
                                }

                                if (orientation && orientation > 4) {
                                    ctx.drawImage(resize, 0, 0);
                                } else {
                                    ctx.drawImage(resize, 0, 0, width, height);
                                }

                                canvas.setAttribute('crossOrigin', 'anonymous');
                                dataUrlImage = canvas.toDataURL('image/jpeg');
                                vm.user.proBusinessLogoTmp.filetype = 'image/jpeg';
                                vm.user.proBusinessLogoTmp.base64 = dataUrlImage.substr(dataUrlImage.indexOf(',') + 1);
                                vm.companyLogo = 'data:'+vm.user.proBusinessLogoTmp.filetype+';base64,'+vm.user.proBusinessLogoTmp.base64;
                                dataUrlImage = null;
                                resize = null;
                                img = null;
                                canvas = null;
                                ctx = null;
                                vm.$apply();
                            });
                        };
                        resize.src = 'data:' + vm.user.proBusinessLogoTmp.filetype + ';base64,' + vm.user.proBusinessLogoTmp.base64;
                    } else {
                        vm.companyLogo = 'data:'+vm.user.proBusinessLogoTmp.filetype+';base64,'+vm.user.proBusinessLogoTmp.base64;
                        img = null;
                    }

                    vm.loadingBusinessLogo = false;
                    vm.businessSettingsChanged = true;
                };
                img.src = 'data:' + vm.user.proBusinessLogoTmp.filetype + ';base64,' + vm.user.proBusinessLogoTmp.base64;
            }
        }
    };

    /**
     * Add http to url
     *
     * @param model model name
     */
    vm.completeUrl = function (model) {
        if (typeof vm.user[model] === 'string' && vm.user[model] !== '') {
            if (vm.user[model].indexOf('http') === -1) {
                vm.user[model] = 'http://' + vm.user[model];
            }
        }
    };

    /**
     * Saves basic account settings info
     *
     * @param  {object}  myform  The user account form
     */
    vm.saveBasicInfo = function (myform) {
        var modalInstance;

        // Exit early if there's a form validation error
        if (myform.$invalid) { return; }

        // Handles profile image upload progress if it was changed
        if ((myform['profile-image'] && myform['profile-image'].$viewValue && myform['profile-image'].$viewValue.base64)) {
            modalInstance = $uibModal.open({
                scope: vm,
                keyboard: false,
                backdrop: 'static',
                animation: false,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                controller: 'ProfileProgressModalCtrl',
                template: require('../../common/templates/progress-modal.tpl.html'),
                resolve: {}
            });
            modalInstance.result.then(angular.noop, angular.noop);
        }

        // Adds timezone info if the user set one
        if (vm.formData.timezone && vm.formData.timezone.value) {
            myform.timezone = vm.formData.timezone.value;
            vm.user.timezone = myform.timezone;
        }

        vm.loading = true;
        strukshurUserService.updateBasicSettings(myform)
            .then(function (res) {
                vm.basicSettingsChanged = false;
                vm.user.profileImage = res.user.profileImage;
                Flash.create('success', 'Basic info updated successfully!', 10000, { container: 'basic-section-flash' }, true);
            })
            .catch(function (e) {
                if (e && e.data && e.data.message) {
                    Flash.create('danger', e.data.message, 10000, { container: 'basic-section-flash' }, true);
                } else {
                    Flash.create('danger', 'There was an error updating the user info.', 10000, { container: 'basic-section-flash' }, true);
                }
            })
            .finally(function () {
                vm.loading = false;

                if (modalInstance) {
                    modalInstance.close();
                }
            })
        ;
    };

    /**
     * Saves business account settings info
     *
     * @param  {object}  myform  The user account form
     */
    vm.saveBusinessInfo = function (myform) {
        var modalInstance;

        // Exit early if there's a form validation error
        if (myform.$invalid) { return; }

        // Handles business logo upload progress if it was changed
        if ((myform['business-logo'] && myform['business-logo'].$viewValue && myform['business-logo'].$viewValue.base64)) {
            modalInstance = $uibModal.open({
                animation: false,
                keyboard: false,
                backdrop: 'static',
                scope: vm,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                controller: 'ProfileProgressModalCtrl',
                template: require('../../common/templates/progress-modal.tpl.html'),
                resolve: {}
            });
            modalInstance.result.then(angular.noop, angular.noop);
        }

        if (vm.formData.category && vm.formData.category.name) {
            vm.user.category = vm.formData.category.name;
            myform.category = vm.formData.category.name;
        }

        vm.loading = true;
        strukshurUserService.updateBusinessSettings(myform)
            .then(function (res) {
                vm.businessSettingsChanged = false;
                vm.user.companyLogo = res.user.companyLogo;
                Flash.create('success', 'Company info updated successfully!', 10000, { container: 'business-section-flash' }, true);
            })
            .catch(function (e) {
                console.error(e);
                if (e && e.data && e.data.message) {
                    Flash.create('danger', e.data.message, 10000, { container: 'business-section-flash' }, true);
                } else {
                    Flash.create('danger', 'There was an error updating the company info.', 10000, { container: 'business-section-flash' }, true);
                }
            })
            .finally(function () {
                vm.loading = false;

                if (modalInstance) {
                    modalInstance.close();
                }
            })
        ;
    };

    /**
     * Updates the user's notifications settings
     */
    vm.updateNotifications = function () {
        var data = {
            'receive_project_email_invitations': (vm.user.receiveProjectInvitationsByEmail) ? 'y' : 'n',
            'receive_project_email_notifications': (vm.user.receiveProjectNotificationsByEmail) ? 'y' : 'n',
        };

        vm.loading = true;
        strukshurApiService.user.updateNotificationSettings(data).$promise
            .then(function () {
                vm.notificationSettingsChanged = false;
                vm.user_backup.receiveProjectInvitationsByEmail = vm.user.receiveProjectInvitationsByEmail;
                vm.user_backup.receiveProjectNotificationsByEmail = vm.user.receiveProjectNotificationsByEmail;
                Flash.create('success', 'Notification settings updated!', 10000, { container: 'notification-section-flash' }, true);
            })
            .catch(function (e) {
                Flash.create('danger', 'There was an error while trying to update your settings.', 10000, { container: 'notification-section-flash' }, true);
            })
            .finally(function () {
                vm.loading = false;
            })
        ;
    };

    /**
     * Updates the user's password
     *
     * @param  {object}  myform  The user account form
     */
    vm.updatePassword = function (myform) {

        // Exit early if there's a form validation error
        if (myform.$invalid) { return; }

        // Password validations
        if (myform['current-password']) {
            if (myform['current-password'].$viewValue.length < 8) {
                return;
            } else if (myform['new_password'].$viewValue.length < 8 || myform['new_password'].$invalid || myform['new-password-confirmation'].$invalid) {
                return;
            }
        }

        Flash.clear();
        vm.loading = true;
        var promise = strukshurUserService.changePassword(myform);
        promise.then(function (data) {
            vm.loading = false;
            vm.passwordInfoChanged = false;
            Flash.create('success', 'Password updated successfully!', 10000, { container: 'password-section-flash' }, true);
        }, function (e) {
            vm.loading = false;
            if (e && e.data && e.data.message) {
                Flash.create('danger', e.data.message, 10000, { container: 'password-section-flash' }, true);
                if (e.data.data && e.data.data.errors && e.data.data.errors.invalid) {
                    myform['current-password'].$setValidity('invalid', false);
                }
            } else {
                Flash.create('danger', 'There was an error while updating your password.', 10000, { container: 'password-section-flash' }, true);
            }
        });
    };

    /**
     * Refresh list of organizations
     */
    vm.getOrganizations = function () {
        if (vm.loadingOrganizations) { return; }

        // Set loading organizations timer based on wether it's a first load or not
        var timer = (vm.organizations.length === 0) ? 2000 : 0;

        vm.loadingOrganizations = true;
        $timeout(function () {
            strukshurApiService.organizations.list({}).$promise
            .then(function (res) {
                vm.organizations = res.organizations;
            })
            .catch(function (e) {
                Flash.create('danger', e.message, 10000, { container: 'organizations-section-flash' }, true);
            })
            .finally(function () {
                vm.loadingOrganizations = false;
            })
            ;
        }, timer);
    };

    /**
     * Opens modal to create a new organization
     */
    vm.openNewOrganizationModal = function () {
        var modalInstance = $uibModal.open({
            scope: vm,
            keyboard: true,
            controller: 'OrganizationsNewOrganizationCtrl',
            template: require('../../common/templates/organizations.newOrganizationModal.tpl.html'),
        });

        modalInstance.result.then(

            // Resolved callback
            function (data) {
                if (data.organization) {
                    vm.organizations.push(data.organization);
                    strukshurUserService.addOrganization(data.organization);
                }
            },

            // Rejected callback
            angular.noop
        );
    };

    /**
     * Confirms the action of leaving an organization
     *
     * @param  {object}  organization  The organization to leave
     */
    vm.leaveOrganizationConfirmation = function (organization) {
        var modalInstance = $uibModal.open({
            scope: vm,
            keyboard: true,
            controller: 'OrganizationsLeaveOrganizationCtrl',
            template: require('../../common/templates/confirm-modal.tpl.html'),
            resolve: {
                organization: function () {
                    return organization;
                },
                message: function () {
                    return 'Are you sure you want to leave the organization? Another member will have to invite you again if you want to join the organization in the future.';
                }
            }
        });

        modalInstance.result.then(function () {

            // Confirmed
            _remove(vm.organizations, organization);
            strukshurUserService.removeOrganization(organization);
        }, angular.noop);
    };

    /**
     * Popup to confirm disconnect from stripe
     */
    vm.disconnectPop = function (account) {
        vm.confirmMessage = false;
        var modalInstance = $uibModal.open({
            animation: true,
            keyboard: true,
            scope: vm,
            ariaLabelledBy: 'modal-title',
            ariaDescribedBy: 'modal-body',
            controller: 'ConfirmDisconnectStripeCtrl',
            template: require('../../common/templates/confirm-modal.tpl.html'),
            resolve: {
                message: function () {
                    return 'You won\'t be able to process payments from the store.<br>You can reconnect at any time.<br>Are you sure?';
                }
            }
        });
        modalInstance.result.then(function (selectedItem) {
            vm.successMessage = 'Disconnected from stripe';
            account.stripePublishableKey = null;
        }, function () {
            //cancel confirmation
        });
    };

    // Bootstraps intialization logic
    vm.init();
})

.controller('ProfileProgressModalCtrl', function ProfileProgressModalCtrl ($scope) {
    var vm = $scope;

    vm.progress = 0;
    vm.title = 'Uploading';

    vm.$on('updateProgress', function (event, args) {
        if (args.name === 'user.update') {
            vm.progress = args.progress;
            if (vm.progress >= 100) {
                vm.title = 'Saving...';
            }
        }
    });
})

.controller('ConfirmCancelSubscriptionCtrl', function ConfirmCancelSubscriptionCtrl ($scope, $uibModalInstance, strukshurApiService, message, user) {
    var vm = $scope;

    vm.user = user;
    vm.title = 'Confirm';
    vm.loading = false;
    vm.message = message;
    vm.errorMessage = false;

    vm.confirm = function () {
        if (vm.loading) { return; }

        vm.loading = true;
        vm.errorMessage = false;
        strukshurApiService.userSubscription.cancel({}, function (d) {
            $uibModalInstance.close({ success: d.success, user: d.user });
        }, function (e) {
            vm.loading = false;
        });
    };

    vm.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };
})

.controller('ConfirmUpgradeSubscriptionCtrl', function ConfirmUpgradeSubscriptionCtrl ($scope, $uibModalInstance, strukshurApiService, message, plan) {
    var vm = $scope;

    vm.plan = plan;
    vm.title = 'Confirm';
    vm.loading = false;
    vm.message = message;
    vm.errorMessage = false;

    vm.confirm = function () {
        if (vm.loading) { return; }

        vm.loading = true;
        vm.errorMessage = false;
        strukshurApiService.upgradeUser.upgrade({ plan: vm.plan }, function (res) {
            $uibModalInstance.close(res);
        }, function (e) {
            vm.loading = false;
        });
    };

    vm.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };
})

.controller('UpgradeToProCtrl', function UpgradeToProCtrl ($scope, $uibModalInstance, strukshurApiService, plan, user, ContractorRoleService) {
    var vm = $scope;

    vm.form = {};
    vm.plan = plan;
    vm.user = user;
    vm.formData = {};
    vm.loading = false;
    vm.errorMessage = false;
    vm.availableRoles = [];

    ContractorRoleService.getRoles().then(function (d) {
        vm.availableRoles = d;
    });

    /**
     * Add http to url
     * @param model model name
     */
    vm.completeUrl = function (model) {
        if (typeof vm.user[model] === 'string' && vm.user[model] !== '') {
            if (vm.user[model].indexOf('http') === -1) {
                vm.user[model] = 'http://'+vm.user[model];
            }
        }
    };

    vm.formUpgradeEnter = function () {
        if (vm.loading) { return; }
        if (vm.form.$invalid) { return; }
        if(!vm.formData || !vm.formData.category || !vm.formData.category.name) { return; }

        var post_data = {
            plan: vm.plan,
            business_name: user.businessName,
            phone_number: user.phoneNumber,
            fax_number: user.faxNumber,
            state_lic: user.stateContractorLic,
            founded: user.founded,
            hours: user.hours,
            bbb: user.bbb,
            employees: user.employees,
            description: user.description,
            website: user.website,
            fb: user.fbUrl,
            yelp: user.yelpUrl,
            category: vm.formData.category.name
        };

        vm.loading = true;
        vm.errorMessage = false;
        strukshurApiService.upgradePro.upgrade(post_data, function (d) {
            $uibModalInstance.close({ success: d.success });
        }, function (e) {
            vm.loading = false;
        });
    };

    vm.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };
})

.controller('ConfirmDisconnectStripeCtrl', function ConfirmDisconnectStripeCtrl($scope, $rootScope, $uibModalInstance, strukshurApiService, message) {
    var vm = $scope;

    vm.title = 'Confirm';
    vm.loading = false;
    vm.message = message;
    vm.errorMessage = false;
    vm.stripe_client_id = strukshurConfig.stripe_client_id;

    vm.confirm = function () {
        if (vm.loading) { return; }

        vm.loading = true;
        vm.errorMessage = false;
        strukshurApiService.stripeDisconnect.save({ stripeClientId: vm.stripe_client_id }, function (d) {
            $rootScope.$broadcast('userUpdate', d.user);
            $uibModalInstance.close({ success: d.success, user: d.user });
        }, function (e) {
            vm.loading = false;
        });
    };

    vm.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };
})

;
