import * as angular from 'angular';

angular.module('strukshurUserService', ['angularLocalStorage'])
    .factory('strukshurUserService', function ($http, $q, $rootScope, PricingService, storage, strukshurApiService) {
        var userPromise = null;
        var housesList = [];
        var userDetails: any = {};
        var houseDetails: any = {};
        var userProjects = [];
        var userOrganizations = [];

        var roleAdmin  = 'admin';
        var roleStaff  = 'staff';
        var rolePro  = 'pro';
        var roleStandard  = 'standard';

        var permissionsCache = {};

        function isEmpty(obj) {
            for (var key in obj) {
                if (obj.hasOwnProperty(key)) {
                    return false;
                }
            }

            return true;
        }

        return {
            setUser: function(user) {
                userDetails = user;
                $rootScope.$broadcast('userUpdate', userDetails);

                return userDetails;
            },

            setUserObj: function(user) {
                userDetails = user;
            },

            getUserObj: function() {
                return userDetails;
            },

            /**
             * Returns the logged user data
             *
             * @param  bool  forceRefresh  Wether to retrieve fresh user data from the server
             *
             * @return {Promise}
             */
            getUser: function (forceRefresh) {

                // Returns existing promise if it was already setup before
                if (userPromise) {
                    return userPromise;
                }

                console.log('checking user object');
                var _this = this;
                var deferred = $q.defer();
                userPromise = deferred.promise;

                if (!isEmpty(userDetails) && !forceRefresh) {
                    console.log('user obj exists');
                    deferred.resolve(userDetails);
                } else {
                    if (storage.get('jwt') !== null && storage.get('jwt') !== '') {
                        console.log('fetching user obj');
                        strukshurApiService.getUserDetails.query({}, function (res) {
                            $rootScope.loggedin = true;
                            _this.setUser(res.user);
                            // _this.setHouse(res.houses[0]);
                            // _this.setHouses(res.houses);

                            if (res.projects && res.projects.length > 0) {
                                _this.setProjects(res.projects);
                            }

                            if (res.organizations && res.organizations.length > 0) {
                                _this.setOrganizations(res.organizations);
                            }

                            // console.log('user details found');
                            // console.log(res);

                            deferred.resolve(userDetails);
                        }, function (e) {
                            $rootScope.loggedin = false;

                            console.log('error getting user details');
                            console.log(e);
                            if (e && e.status && e.status === 401 && e.statusText && e.statusText === 'Unauthorized' && e.data && typeof e.data.message === 'string' && e.data.message.indexOf('Username') === 0 && e.data.message.indexOf('does not exist') !== -1) {
                                storage.set('jwt', null);
                                deferred.reject({ data: { message: 'Expired JWT Token' } });
                            } else {
                                deferred.reject(e);
                            }
                        });
                    } else {
                        deferred.reject({ data: { message: 'Expired JWT Token' } });
                    }
                }

                return userPromise;
            },

            /**
             * Refreshes local user data with the latest data from the server
             *
             * @return {Promise}
             */
            refreshUser: function () {
                var userPromise = this.getUser();

                userPromise.then(function (data) {
                    console.log(data);
                });

                return userPromise;
            },

            updateBasicSettings: function (myform) {
                var deferred = $q.defer();

                // If there is a profile image
                var profileImageFileBase64 = '';
                if (myform['profile-image'] && myform['profile-image'].$viewValue && myform['profile-image'].$viewValue.base64) {
                    profileImageFileBase64 = myform['profile-image'].$viewValue.base64;
                }

                var formData: any = {};
                formData.full_name = myform['full-name'].$viewValue;
                formData.profile_image = profileImageFileBase64;
                formData.phone_number = myform.hasOwnProperty('phone-number') ? myform['phone-number'].$viewValue : '';
                formData.basic_state = myform.hasOwnProperty('basic-state') ? myform['basic-state'].$viewValue : '';
                formData.basic_city = myform.hasOwnProperty('basic-city') ? myform['basic-city'].$viewValue : '';
                formData.timezone = myform.hasOwnProperty('timezone') ? myform.timezone : '';

                strukshurApiService.user.updateBasicSettings(formData, function (res) {
                    deferred.resolve(res);
                }, function (e) {
                    deferred.reject(e);
                });

                return deferred.promise;
            },

            updateBusinessSettings: function (myform) {
                var deferred = $q.defer();

                // If there is a business logo
                var businessLogoFileBase64 = '';
                if (myform['business-logo'] && myform['business-logo'].$viewValue && myform['business-logo'].$viewValue.base64) {
                    businessLogoFileBase64 = myform['business-logo'].$viewValue.base64;
                }

                // Set info to send to the server based on the selected section
                var formData: any = {};
                formData.business_name = myform.hasOwnProperty('business-name') ? myform['business-name'].$viewValue : '';
                formData.business_logo = businessLogoFileBase64;
                formData.phone_number = myform.hasOwnProperty('phone-number') ? myform['phone-number'].$viewValue : '';
                formData.fax_number = myform.hasOwnProperty('fax-number') ? myform['fax-number'].$viewValue : '';
                formData.state_lic = myform.hasOwnProperty('state-lic') ? myform['state-lic'].$viewValue : '';
                formData.founded = myform.hasOwnProperty('founded') ? myform.founded.$viewValue : '';
                formData.hours = myform.hasOwnProperty('hours') ? myform.hours.$viewValue : '';
                formData.bbb = myform.hasOwnProperty('bbb') ? myform.bbb.$viewValue : '';
                formData.employees = myform.hasOwnProperty('employees') ? parseInt(myform.employees.$viewValue) : '';
                formData.description = myform.hasOwnProperty('description') ? myform.description.$viewValue : '';
                formData.website = myform.hasOwnProperty('website') ? myform.website.$viewValue : '';
                formData.fb = myform.hasOwnProperty('fb') ? myform.fb.$viewValue : '';
                formData.yelp = myform.hasOwnProperty('yelp') ? myform.yelp.$viewValue : '';
                formData.category = myform.hasOwnProperty('category') ? myform.category : '';

                strukshurApiService.user.updateBusinessSettings(formData, function (res) {
                    deferred.resolve(res);
                }, function (e) {
                    deferred.reject(e);
                });

                return deferred.promise;
            },

            changePassword: function (myform) {
                var deferred = $q.defer();

                var formData = {
                    current_password: (myform['current-password']) ? myform['current-password'].$viewValue : '',
                    new_password: (myform['new_password']) ? myform['new_password'].$viewValue : '',
                };

                strukshurApiService.user.changePassword(formData, function (res) {
                    deferred.resolve(res);
                }, function (e) {
                    deferred.reject(e);
                });

                return deferred.promise;
            },

            setHouse: function(house) {
                houseDetails = house;
                $rootScope.$broadcast('houseUpdate', houseDetails);
                return houseDetails;
            },

            getHouse: function() {
                return houseDetails;
            },

            setHouses: function(houses) {
                housesList = houses;
                return housesList;
            },

            getHouses: function() {
                return housesList;
            },

            getHouseId: function() {
                return houseDetails.id;
            },

            setLoggedOut: function() {
                storage.remove('jwt');
                storage.remove('taxes');
                storage.remove('timezones');
                storage.remove('cart');
                storage.remove('shippingAddress');
                storage.remove('shippingMethod');
                storage.remove('vendorDelivery');

                delete $http.defaults.headers.common.Authorization;

                // delete userDetails
                var props = Object.keys(userDetails);
                for (var i = 0; i < props.length; i++) {
                    delete userDetails[props[i]];
                }

                $rootScope.$broadcast('userUpdate', {});
            },

            isLoggedIn: function() {
                return !isEmpty(userDetails);
            },

            isEnabled: function() {
                return userDetails.enabled !== undefined ? userDetails.enabled : false;
            },

            canAccessAdmin: function () {

                if (!isEmpty(userDetails)) {
                    if (userDetails.userType !== undefined && (userDetails.userType === roleAdmin || userDetails.userType === roleStaff)) {
                        return true;
                    }
                }

                return false;
            },

            canAccessVendor: function () {

                if (!isEmpty(userDetails)) {
                    if (userDetails.userType !== undefined && userDetails.isSeller && userDetails.userType === rolePro) {
                        return true;
                    }
                }

                return false;
            },

            canAdminUsers: function () {
                var _this = this;

                if (!isEmpty(userDetails)) {
                    if (this.isAdmin()) {
                        return true;
                    }
                }

                return false;
            },

            isAdmin: function () {
                if (!isEmpty(userDetails)) {
                    if (userDetails.userType !== undefined && userDetails.userType === roleAdmin) {
                        return true;
                    }
                }
                return false;
            },

            isStaff: function () {
                if (!isEmpty(userDetails)) {
                    if (userDetails.userType !== undefined && userDetails.userType === roleStaff)
                    {
                        return true;
                    }
                }
                return false;
            },

            isPro: function () {
                if (!isEmpty(userDetails)) {
                    if (userDetails.userType !== undefined && userDetails.userType === rolePro) {
                        if (userDetails.proConfirmed === true || userDetails.proConfirmed === 'true' || userDetails.plan === 'basic' || userDetails.plan === 'pro-basic') {
                            return true;
                        }
                    }
                }

                return false;
            },

            isVendor: function () {
                if (!isEmpty(userDetails)) {
                    if (userDetails.userType !== undefined && userDetails.isSeller && userDetails.userType === rolePro) {
                        if (userDetails.vendors && userDetails.vendors.length > 0) {
                            return true;
                        }

                    }
                }

                return false;
            },

            /**
             * Returns wether or not the current user is missing a payment
             *
             * @return {boolean}
             */
            isMissingPayment: function () {
                if (!isEmpty(userDetails)) {
                    if (userDetails.userType !== undefined && userDetails.userType === rolePro) {
                        if (userDetails.plan !== 'basic' && userDetails.plan !== 'pro-basic' && (userDetails.proConfirmed === false || userDetails.proConfirmed === 'false')) {
                            return true;
                        }
                    } else if (userDetails.userType !== undefined && userDetails.userType === roleStandard) {
                        if (userDetails.plan !== 'basic' && userDetails.plan !== 'pro-basic' && (userDetails.proConfirmed === false || userDetails.proConfirmed === 'false')) {
                            return true;
                        }
                    }
                }

                return false;
            },

            setProConfirmed: function() {
                if (!isEmpty(userDetails)) {
                    userDetails.proConfirmed = true;
                }
            },

            isProReg: function () {
                // Edge case that user is pro but not paid

                if (!isEmpty(userDetails)) {
                    if (userDetails.userType !== undefined && userDetails.userType === rolePro) {
                        return true;
                    }
                }
                return false;
            },

            isHomeReg: function () {

                // Edge case that user is pro but not paid
                if (!isEmpty(userDetails)) {
                    if (userDetails.userType !== undefined && userDetails.userType === roleStandard) {
                        return true;
                    }
                }

                return false;
            },

            isSelf: (userSlug) => userDetails.slug !== undefined && userDetails.slug === userSlug ? true : false,

            getUserId: () => userDetails.id !== undefined ? userDetails.id : '',

            getFosUserId: () => userDetails.fos_user_id !== undefined ? userDetails.fos_user_id : '',

            getUserSlug: () => userDetails.slug !== undefined ? userDetails.slug : '',

            /**
             * Wether the received plan still has available projects or not
             *
             * @param  {string}  id             The plan id
             * @param  {number}  projectsCount  The project count
             *
             * @return {boolean}
             */
            hasAvailableProjects: (id: string, projectsCount: number) => {
                const plans = PricingService.getAll();
                for (const plan of plans) {
                    if (plan.plan === id) {
                        return (projectsCount < plan.projects || plan.projects === 'unlimited');
                    }
                }

                return false; // plan not found
            },

            setProjects: function (projects) {
                userProjects = projects;
                $rootScope.$broadcast('projectsUpdate', userProjects);
            },

            removeProject: function (project) {
                var i, tot;

                tot = userProjects.length;
                for (i = 0; i < tot; i++) {
                    if (userProjects[i].id === project.id) {
                        userProjects.splice(i, 1);
                        break;
                    }
                }

                $rootScope.$broadcast('projectsUpdate', userProjects);
                this.refreshOwnedProjectsCount();
            },

            updateProject: function (project) {
                var i, tot;

                tot = userProjects.length;
                for (i = 0; i < tot; i++) {
                    if (userProjects[i].id === project.id) {
                        userProjects[i] = project;
                        break;
                    }
                }
                $rootScope.$broadcast('projectsUpdate', userProjects);
            },

            addProject: function (project) {
                userProjects.unshift(project);
                $rootScope.$broadcast('projectsUpdate', userProjects);
                this.refreshOwnedProjectsCount();
            },

            getProjects: function () {
                return userProjects;
            },

            setOrganizations: function (organizations) {
                userOrganizations = organizations;
                $rootScope.$broadcast('organizationsUpdate', userOrganizations);
            },

            removeOrganization: function (organization) {
                var i, tot;

                tot = userOrganizations.length;
                for (i = 0; i < tot; i++) {
                    if (userOrganizations[i].id === organization.id) {
                        userOrganizations.splice(i, 1);
                        break;
                    }
                }

                $rootScope.$broadcast('organizationsUpdate', userOrganizations);
            },

            updateOrganization: function (organization) {
                var i, tot;

                tot = userOrganizations.length;
                for (i = 0; i < tot; i++) {
                    if (userOrganizations[i].id === organization.id) {
                        userOrganizations[i] = organization;
                        break;
                    }
                }
                $rootScope.$broadcast('organizationsUpdate', userOrganizations);
            },

            addOrganization: function (organization) {
                userOrganizations.unshift(organization);
                $rootScope.$broadcast('organizationsUpdate', userOrganizations);
            },

            getOrganizations: function () {
                return userOrganizations;
            },

            markMyHomeTourAsSeen: function () {
                strukshurApiService.tour.setUserHasSeenMyHomeTour().$promise
                    .then(function () {
                        userDetails.hasSeenMyHomeTour = true;
                    })
                    .catch(angular.noop)
                ;
            },

            /**
             * Returns the current user's permissions on the given organization
             *
             * @param  {object}  organization  The organization
             *
             * @return {Promise}
             */
            getOrganizationPermissions: function (organization) {
                var deferred = $q.defer();

                // Return the permissions list if it's already in the cache
                if (typeof permissionsCache['p_'+organization.id] !== 'undefined') {
                    deferred.resolve(permissionsCache['p_'+organization.id]);
                } else {

                    // Gets the user permissions on the server side
                    strukshurApiService.organizationMember.get({ organization_id: organization.id, member_id: organization.current_member_id }).$promise
                        .then(function (res) {
                            permissionsCache['p_'+organization.id] = (res.member.permissions) ? res.member.permissions.split(',') : [];

                            deferred.resolve(permissionsCache['p_'+organization.id]);
                        })
                        .catch(function (e) { deferred.reject(e); })
                    ;
                }

                return deferred;
            },

            /**
             * Returns the current user's permissions on the given project
             *
             * @param  {object}  project  The project
             *
             * @return {Promise}
             */
            getProjectPermissions: function (project) {
                var deferred = $q.defer();

                // Return the permissions list if it's already in the cache
                if (typeof permissionsCache['p_'+project.id] !== 'undefined') {
                    deferred.resolve(permissionsCache['p_'+project.id]);
                } else {

                    // Gets the user permissions on the server side
                    strukshurApiService.projectTeamMember.get({ project_id: project.id, member_id: userDetails.id }).$promise
                        .then(function (res) {
                            permissionsCache['p_'+project.id] = (res.teamMember.permissions) ? res.teamMember.permissions.split(',') : [];

                            deferred.resolve(permissionsCache['p_'+project.id]);
                        })
                        .catch(function (e) { deferred.reject(e); })
                    ;
                }

                return deferred;
            },

            /**
             * Sets the organization permissions for the current user
             *
             * @param  {object}  organization  The organization
             * @param  {string}  permissions   The permissions list
             */
            setOrganizationPermissions: function (organization, permissions) {
                permissionsCache['p_'+organization.id] = (permissions) ? permissions.split(',') : [];
            },

            /**
             * Sets the project permissions for the current user
             *
             * @param  {object}  project      The project
             * @param  {string}  permissions  The permissions list
             */
            setProjectPermissions: function (project, permissions) {
                permissionsCache['p_'+project.id] = (permissions) ? permissions.split(',') : [];
            },

            /**
             * Returns if the user has the necessary permission to execute it
             *
             * @param  {string}  permission  The permission id
             * @param  {object}  project     The project
             *
             * @return {Boolean}
             */
            hasPermission: function (permission, project) {

                // We first check if the current user is the project owner
                if (userDetails.id == project.owner_id) {
                    return true;
                }

                // If that's not the case then we proceed with checking for the
                // user permissions on the project
                var permissions = permissionsCache['p_' + project.id];

                // Always returns true if the user has the 'project_owner' permission
                if (permissions.includes('project_owner')) {
                    return true;
                }

                return permissions.includes(permission);
            },

            /**
             * Returns if the user has any of the necessary permissions on the project
             *
             * @param  {array}   permissions  The permission ids
             * @param  {object}  project      The project
             *
             * @return {boolean}
             */
            hasAnyPermissions: function (permissions, project) {
                var service = this,
                    hasPermission = false;

                // We first check if the current user is the project owner
                if (userDetails.id == project.owner_id) {
                    return true;
                }

                permissions.forEach(permission => {
                    if (service.hasPermission(permission, project)) {
                        hasPermission = true;
                        return;
                    }
                });

                return hasPermission;
            },

            /**
             * Refreshes the current user owned projects count with info from the backend
             */
            refreshOwnedProjectsCount: function () {
                strukshurApiService.projects.ownedProjectsCount().$promise
                    .then(function (res) {
                        userDetails.projectsOwned = res.projectsCount;
                    })
                    .catch(angular.noop)
                ;
            },

            /**
             * Sets Pinterest access token to the local user info
             */
            setPinterestAccessToken: (token: string) => userDetails.pinterestAccessToken = token,
        };
    })
;
