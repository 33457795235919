import * as angular from 'angular';

angular.module( 'strukshurApp.admin', ['ui.router', 'ui.bootstrap'])

.config(function config($stateProvider) {
    $stateProvider
        .state( 'admin', {
            url: '/admin',
            views: {
                main: {
                    controller: 'AdminCtrl',
                    template: require('./admin.tpl.html')
                }
            },
            resolve: {
                adminResolve: function($state, strukshurUserService, InitService) {
                    return InitService.promise.then(function () {
                        if (strukshurUserService.canAccessAdmin() === false) {
                            $state.go('admin-auth');

                            return false;
                        }
                    });
                }
            },
            data: { pageTitle: 'Admin', class: 'admin' }
        })

        .state( 'admin-auth', {
            url: '/admin/auth',
            params: {
                page: null
            },
            views: {
                main: {
                    controller: 'AdminAuthCtrl',
                    template: require('./admin_auth.tpl.html')
                }
            },
            data: { pageTitle: 'Admin Authentication', class: 'admin admin-auth' }
        })

        .state( 'admin.dashboard', {
            url: '/dashboard',
            params: {
                page: null
            },
            views: {
                'admin_middle_view': {
                    controller: 'AdminDashboardCtrl',
                    template: require('./admin_dashboard.tpl.html')
                }
            },
            data: { pageTitle: 'Admin Dashboard', class: 'admin' }
        })
    ;
})

.controller('AdminCtrl', function AdminCtrl ($scope, $state, strukshurUserService) {
    $scope.canAdminUsers = strukshurUserService.canAdminUsers();

    if ($state.current.name === 'admin') {
        $state.go('admin.dashboard');
    }
})

.controller('AdminAuthCtrl', function AdminAuthCtrl ($scope, $state, storage, strukshurAuthUserService, strukshurUserService) {
    var vm = $scope;

    vm.form = {};
    vm.loading = false;

    var auth = storage.get('jwt') || false;

    // @TODO if already logged in
    if (auth) {
        $state.go('admin');
    }

    vm.login = function (myform) {
        vm.loading = true;

        var promise = strukshurAuthUserService.login(myform);
        promise.then(function (data) {
            vm.loading = false;

            strukshurUserService.setUser(data.user);

            if (strukshurUserService.canAccessAdmin()) {
                $state.go('admin', {});
            } else {
                $state.go('ideas');
            }
        }, function (reason) {
            console.log('promise Failed: ');
            console.log(reason);

            vm.loading = false;
            vm.error = 'Invalid Credentials. Please try again.';
        });
    };
})

.controller('AdminDashboardCtrl', angular.noop)
;
