import * as angular from 'angular';

declare global {
    interface Window { ImageBlobReduce: any; }
}

var app = angular.module('fileService', []);

app.factory('FileService', [() => {
    const allowedImages = ['image/x-png', 'image/png', 'image/gif', 'image/jpeg', 'image/bmp', 'image/webp', 'image/ico'];
    const allowedVideos = ['video/mp4', 'video/x-m4v', 'video/webm', 'video/quicktime', 'video/x-msvideo', 'video/x-ms-wmv'];
    const supportedImagedPreviewExtensions = ['bmp', 'gif', 'jpg', 'jpeg', 'png', 'webp'];
    const supportedColorExtensions = {
        documents: ['doc', 'docx', 'html', 'pages', 'txt'],
        pdf: ['pdf'],
        slides: ['keynote', 'ppt', 'pptx'],
        spreadsheets: ['csv', 'dif', 'numbers', 'ods', 'tsv', 'xls', 'xlsx']
    };

    const service = {
        supportedImagedPreviewExtensions: supportedImagedPreviewExtensions,

        /**
         * Converts a file blob into a data url
         *
         * @param  {Blob}  blob  The file blob
         *
         * @return {Promise}
         */
        blobToDataURL(blob: Blob): Promise<string> {
            return new Promise<string>((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = _e => resolve(reader.result as string);
                reader.onerror = _e => reject(reader.error);
                reader.onabort = _e => reject(new Error('Read aborted'));
                reader.readAsDataURL(blob);
            });
        },

        /**
         * Returns encoded file url specifically for S3 urls
         *
         * @param  {string}  url  The file url
         */
        encodeFileUrlForS3: (url: string) => {
            let parts = url.split('/');
            parts[parts.length-1] = encodeURIComponent(parts[parts.length-1]);

            return parts.join('/');
        },

        /**
         * Returns the extension for the given filename
         *
         * @param  {string}  filename  The filename
         *
         * @return {string}
         */
        getFileExtension: (filename) => {

            // Retrieve file extension
            var parts = filename.split('.'),
                ext = parts[parts.length-1];

            return ext;
        },

        /**
         * Returns the color for the given file extension
         *
         * @param  {string}  filename  The filename
         *
         * @return {string}
         */
        getFileExtensionColor: (filename) => {

            // Retrieve file extension
            var parts = filename.split('.'),
                ext = parts[parts.length-1];

            if (supportedColorExtensions.documents.includes(ext)) {
                return '#5b7697';
            } else if (supportedColorExtensions.pdf.includes(ext)) {
                return '#c3453a';
            } else if (supportedColorExtensions.slides.includes(ext)) {
                return '#d26015';
            } else if (supportedColorExtensions.spreadsheets.includes(ext)) {
                return '#849455';
            }

            return '#8a9298';
        },

        /**
         * Returns the file type of the given filename
         *
         * @param  {string}  filename  The filename
         *
         * @return {string}
         */
        getFileType: (filename) => {
            const ext = service.getFileExtension(filename);

            if (supportedColorExtensions.documents.includes(ext)) {
                return 'document';
            } else if (supportedColorExtensions.pdf.includes(ext)) {
                return 'pdf';
            } else if (supportedColorExtensions.slides.includes(ext)) {
                return 'presentation';
            } else if (supportedColorExtensions.spreadsheets.includes(ext)) {
                return 'spreadsheet';
            }

            return 'file';
        },

        /**
         * Wether the given file is an allowed image or video file
         *
         * @param  {File}  file  The file
         *
         * @return {boolean}
         */
        isImageOrVideoFilter: (file) => {
            var fileTypeAllowed = (allowedImages.includes(file.type) || allowedVideos.includes(file.type));
            var sizeAllowed = (file.size <= 100 * 1024 * 1024); // 100 MB

            return fileTypeAllowed && sizeAllowed;
        },

        /**
         * Wether the given file is an image or not
         *
         * @param  {File}  file  The file
         */
        isImage: (file) => (file.type.substr(0, 5) === 'image'),

        /**
         * Returns wether or not the given file type is an allowed image or not
         *
         * @param  {string}  file  The image file
         *
         * @return {boolean}
         */
        isAllowedImageType: (file) => allowedImages.includes(file.type),

        /**
         * Returns wether or not the given file type is an allowed video or not
         *
         * @param  {string}  file  The video file
         *
         * @return {boolean}
         */
        isAllowedVideoType: (file) => allowedVideos.includes(file.type),

        /**
         * Wether the given file is a video or not
         *
         * @param  {File}  file  The file
         */
        isVideo: (file) => (file.type.substr(0, 5) === 'video'),

        /**
         * Resizes the given image blob
         *
         * @param  {File}    file     The file to resize
         * @param  {number}  maxSize  The max width and height for the resized image
         *
         * @return {Promise}
         */
        resizeImageBlob: (file, maxSize = 2048) => {
            return (new window.ImageBlobReduce()).toBlob(file, { max: maxSize });
        },

        /**
         * Returns wether the given extension supports in-browser preview
         *
         * @param  {string}  ext  The file extension
         *
         * @return {boolean}
         */
        supportsImagePreviewExtension: (ext) => supportedImagedPreviewExtensions.includes(ext),
    };

    return service;
}]);
