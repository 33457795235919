import * as angular from 'angular';

var app = angular.module('strukshurProService', ['ngResource']);
app.factory('strukshurProService', function ($q, strukshurApiService, $timeout) {
    var th = this;

    this.galleryVideoStatus = function(gallery){
        $timeout(function(data){
            var gallery = data.gallery, th = data.th;
            strukshurApiService.getGalleryVideoStatus.query({gallery_id: gallery.id}, function(d){
                var $gallery_images;
                if (!d.isProcessing) {
                    gallery.isProcessing = d.isProcessing;
                    gallery.width = d.width;
                    gallery.height = d.height;
                } else {
                    $gallery_images = document.getElementById('gallery_images');
                    if ($gallery_images) {
                        th.galleryVideoStatus(gallery);
                    }
                }
            });
        }, 5000, true, {gallery: gallery, th: th});
    };

    return {
        galleryVideoStatus: this.galleryVideoStatus,
        proGalleries: function (userSlug){
            var deferred = $q.defer();

            strukshurApiService.proGalleries.query({user_slug: userSlug}, function(d){
                deferred.resolve(d);
            }, function(e){
                deferred.reject(e);
            });

            return deferred.promise;

        },
        profileEdit: function (myform){
            var deferred = $q.defer();

            // if there is an updated image
            var profileImageFileBase64 = '';
            if (myform.profile_image && myform.profile_image.$viewValue !== undefined)
            {
                profileImageFileBase64 = myform.profile_image.$viewValue.base64;
            }

            var formData = {
                pro_id: myform.pro_id,
                profile_image: profileImageFileBase64,
                biz_name: myform.biz_name.$viewValue,
                phone_number: myform.phone_number.$viewValue,
                fax_number: myform.fax_number.$viewValue,
                state_lic: myform.state_lic.$viewValue,
                founded: myform.founded.$viewValue,
                hours: myform.hours.$viewValue,
                bbb: myform.bbb.$viewValue,
                website: myform.website.$viewValue,
                fb: myform.fb.$viewValue,
                yelp: myform.yelp.$viewValue
            };

            strukshurApiService.proDetails.update(formData, function(d) {
                console.log('proEdit promise Success');
                deferred.resolve(d);
            }, function(e) {
                console.log('proEdit promise Failed:');
                deferred.reject(e);
            });

            return deferred.promise;
        }
    };
});
