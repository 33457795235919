import {
    find as _find,
    pull as _pull,
} from 'lodash';
import * as angular from 'angular';

angular.module('strukshurApp.projects.team', [])

.config(function config($stateProvider, $uibModalProvider) {
    $uibModalProvider.options = { animation: true, backdrop: 'static', keyboard: false, ariaLabelledBy: 'modal-title', ariaDescribedBy: 'modal-body' };

    $stateProvider
        .state('projects-detail.team', {
            url: '/team',
            controller: 'ProjectDetailTeamCtrl',
            template: require('./project.team.tpl.html'),
            resolve: {
                teamMembers: function ($stateParams, strukshurApiService) {
                    return strukshurApiService.projectTeamMembers
                        .list({ project_id: $stateParams.project_id }).$promise
                        .then(function (res) { return res.members; })
                    ;
                }
            },
            data: { pageTitle: 'Project / Teams', class: 'projects projects-detail-team' }
        })
    ;
})

.controller('ProjectDetailTeamCtrl', function ProjectDetailTeamCtrl($scope, $state, $uibModal, teamMembers, strukshurApiService, strukshurUserService, toastr) {
    var vm = $scope;

    // Check if the user can access the current page or not
    if (!vm.$parent.checkPermission('project_team_member_view')) {
        return $state.go('projects-detail.access-denied', { section: 'Team' });
    }

    vm.loading = true;
    vm.searchTerm = '';
    vm.pendingInvites = [];
    vm.teamMembers = teamMembers;
    vm.currentUser = strukshurUserService.getUserObj();

    /**
     * Controller init logic
     */
    vm.init = () => {

        // Updates current route info on the project menu scope
        vm.$parent.currentRoute = $state.current.name;

        // Fetches list of pending invites
        strukshurApiService.projectInvites.list({ project_id: vm.$parent.project.id, status: 'pending' }).$promise
            .then((res) => {
                vm.loading = false;
                vm.pendingInvites = res.invites;
            })
        ;
    };

    /**
     * Checks if the current user has the given permission on the project
     *
     * @param  {String}  permission  The permission key
     *
     * @return {Boolean}
     */
    vm.checkPermission = function (permission) {
        return vm.$parent.checkPermission(permission);
    };

    /**
     * Opens the modal to invite a new member to the project team
     */
    vm.inviteMember = function () {
        var modalInstance = $uibModal.open({
            scope: vm,
            keyboard: true,
            controller: 'ProjectDetailInviteMemberCtrl',
            template: require('../../../common/templates/projects.inviteMemberModal.tpl.html'),
            resolve: {
                project: vm.$parent.project
            }
        });

        modalInstance.result.then(

            // Resolved callback
            function (data) {
                if (data.member) {
                    vm.teamMembers.push(data.member);
                } else if (data.invite) {
                    vm.pendingInvites.push(data.invite);
                }
            },

            // Rejected callback
            angular.noop
        );
    };

    /**
     * Resends the received invitation by email
     *
     * @param  {object}  invite  The invite
     */
    vm.resendInvite = function (invite) {
        if (invite.resendingInvite) { return; }

        invite.resendingInvite = true;
        strukshurApiService.projectTeamMember.resendInvite({ project_id: vm.$parent.project.id, invite_id: invite.id }).$promise
            .then(function () {
                toastr.success('The invitation e-mail was sent successfully.', 'Success!');
            })
            .catch(function (err) {
                toastr.error('There was an error trying to send the invitation e-mail. Please try again later.', 'Error');
            })
            .finally(function () {
                invite.resendingInvite = false;
            })
        ;
    };

    /**
     * Opens the modal to edit an existing member on the project
     */
    vm.editMember = function (member) {
        var modalInstance = $uibModal.open({
            scope: vm,
            keyboard: true,
            controller: 'ProjectDetailEditMemberCtrl',
            template: require('../../../common/templates/projects.editMemberModal.tpl.html'),
            resolve: {
                project: vm.$parent.project,
                member: member
            }
        });

        modalInstance.result.then(

            // Resolved callback
            function (data) {
                if (data.member) {
                    member.role = data.member.role;
                    member.phoneNumber = data.member.phoneNumber;
                    member.permissions = (Array.isArray(data.member.permissions)) ? data.member.permissions.join(',') : data.member.permissions;
                }
            },

            // Rejected callback
            angular.noop
        );
    };

    /**
     * Removes the received member from the project
     *
     * @param  {object}  member  The member to remove
     */
    vm.removeMember = function (member) {
        var $childScope = vm.$new();
        $childScope.member = member;
        $childScope.project = vm.$parent.project;
        $childScope.title = 'Remove member';
        $childScope.message = 'Are you sure you want to remove the member from the project?';

        var modalInstance = $uibModal.open({
            keyboard: true,
            scope: $childScope,
            controller: 'ProjectDetailRemoveMemberCtrl',
            template: require('../../../common/templates/base.confirm-modal.tpl.html')
        });

        modalInstance.result.then(

            // Resolved callback
            function (data) {
                _pull(vm.teamMembers, member);
            },

            // Rejected callback
            angular.noop
        );
    };

    /**
     * Removes the received invite from the project
     *
     * @param  {Object}  invite  The invite to remove
     */
    vm.removeInvite = function (invite) {
        var $childScope = vm.$new();
        $childScope.invite = invite;
        $childScope.project = vm.$parent.project;
        $childScope.title = 'Remove invite';
        $childScope.message = 'Are you sure you want to remove this invite from the project?';

        var modalInstance = $uibModal.open({
            keyboard: true,
            scope: $childScope,
            controller: 'ProjectDetailRemoveInviteCtrl',
            template: require('../../../common/templates/base.confirm-modal.tpl.html')
        });

        modalInstance.result.then(

            // Resolved callback
            function (data) {
                _pull(vm.pendingInvites, invite);
            },

            // Rejected callback
            angular.noop
        );
    };

    vm.init();
})

.controller('ProjectDetailInviteMemberCtrl', function ($scope, $uibModalInstance, ivhTreeviewMgr, project, strukshurApiService, strukshurProjectService) {
    var vm = $scope;

    vm.loading = false;
    vm.foundUsers = [];
    vm.errorMessage = '';
    vm.availableRoles = [];
    vm.newMember = { user: '', email: '', role: '' };

    // Set all available project permissions
    vm.permissions = strukshurProjectService.getAllAvailablePermissions();

    // Define permissions tree options
    vm.permissionOpts = {
        twistieExpandedTpl: '<i class="fa fa-minus-circle"></i>',
        twistieCollapsedTpl: '<i class="fa fa-plus-circle"></i>',
        twistieLeafTpl: '&nbsp;&nbsp;',
    };

    // Load available roles
    strukshurApiService.searchContractorRoles.list().$promise
        .then(function (res) {
            vm.availableRoles = res.roles;
        })
    ;

    /**
     * Invites the member to be a part of the project
     *
     * @param  {Object}  form  The form
     */
    vm.inviteMember = function (form) {
        vm.errorMessage = '';

        // Validates if a user or email was selected
        if (!vm.newMember.email && !vm.newMember.user) {
            vm.errorMessage = 'You need to select a user or input an email.';
            return;
        }

        // Validates if a role was selected
        if (!vm.newMember.role) {
            vm.errorMessage = 'You need to select a role for the team member.';
            return;
        }

        // Get all selected permissions
        var selectedPermissions = [];
        vm.permissions.forEach(function (permission) {

            // First level
            if (permission.selected) {
                selectedPermissions.push(permission.value);
            }

            if (permission.children) {

                // Second level
                permission.children.forEach(function (permission) {

                    if (permission.selected) {
                        selectedPermissions.push(permission.value);
                    }

                    if (permission.children) {

                        // Third level
                        permission.children.forEach(function (permission) {

                            if (permission.selected) {
                                selectedPermissions.push(permission.value);
                            }
                        });
                    }
                });
            }
        });

        // Check if at least one permission was selected
        if (selectedPermissions.length === 0) {
            vm.errorMessage = 'You need to select at least one permission for the new member.';
            return;
        }

        var data: any = {
            project_id: project.id,
            role_id: vm.newMember.role.id,
            permissions: selectedPermissions.join(',')
        };

        if (vm.newMember.user) {
            data.user_id = vm.newMember.user.id;
        }
        if (vm.newMember.email) {
            data.email = vm.newMember.email;
        }

        // Invites the user to the team
        vm.loading = true;
        strukshurApiService.projectTeamMember.invite(data).$promise
            .then(function (res) {
                if (res.member) {
                    $uibModalInstance.close({ member: res.member });
                } else {
                    $uibModalInstance.close({ invite: res.invite });
                }
            })
            .catch(function (res) {
                if (res.status === 403) {
                    vm.errorMessage = 'You don\'t have the necessary permission to invite a team member.';
                } else {
                    if (res && res.data && res.data.message) {
                        vm.errorMessage = res.data.message;
                    } else {
                        vm.errorMessage = 'There was an error trying to invite the member to the team.';
                    }
                }
            })
            .finally(function () {
                vm.loading = false;
            })
        ;
    };

    /**
     * Find users based on the typed text
     */
    vm.findUsers = function (search) {
        if (search == '') {
            vm.foundUsers = [];
            return;
        }

        strukshurApiService.userFind
            .query({ terms: search, project_id: project.id, source: 'strukshur' }).$promise
            .then(function (res) {
                vm.foundUsers = res.users;
            })
        ;
    };

    /**
     * Select all user permissions
     */
    vm.selectAllPermissions = function () {
        ivhTreeviewMgr.selectAll(vm.permissions);
    };

    /**
     * Deselect all user permissions
     */
    vm.deselectAllPermissions = function () {
        ivhTreeviewMgr.deselectAll(vm.permissions);
    };
})

.controller('ProjectDetailEditMemberCtrl', function ($scope, $timeout, $uibModalInstance, ivhTreeviewMgr, project, member, strukshurApiService, strukshurProjectService) {
    var vm = $scope;

    vm.member = member;
    vm.loading = false;
    vm.errorMessage = '';
    vm.availableRoles = [];
    vm.editMember = { role: null, phoneNumber: member.phoneNumber };

    // Get all available project permissions
    vm.permissions = strukshurProjectService.getAllAvailablePermissions();

    // Only mark the permissions the team member has as selected
    if (member.permissions) {
        var memberPermissions = member.permissions.split(',');

        /**
         * Returns the permission of the given id
         *
         * @param  {Array}   permissions  The permission list
         * @param  {String}  id           The permision id
         *
         * @return {Object}
         */
        var findPermission = function (permissions, id) {
            var foundPermission = null;

            // We first try to find the permission at the root level
            permissions.forEach(function (permission) {
                if (permission.id == id) {
                    foundPermission = permission;
                    return;
                }

                // If we didn't find the permission at the root level, then we try to
                // search the permission children
                if (permission.children) {
                    let childPermission = findPermission(permission.children, id);

                    if (childPermission) {
                        foundPermission = childPermission;
                        return;
                    }
                }
            });

            return foundPermission;
        };

        // First we deselect all permissions
        ivhTreeviewMgr.deselectAll(vm.permissions);

        // Then we go through all member permissions and select them, but in an
        // async call as we need to allow the digest cycle to propagate, otherwise
        // partial select node states won't be rendered on the component
        $timeout(function() {
            memberPermissions.forEach(function (permission) {
                ivhTreeviewMgr.select(vm.permissions, findPermission(vm.permissions, permission));
            });
        }, 300);
    }

    // Define permissions tree options
    vm.permissionOpts = {
        twistieExpandedTpl: '<i class="fa fa-minus-circle"></i>',
        twistieCollapsedTpl: '<i class="fa fa-plus-circle"></i>',
        twistieLeafTpl: '&nbsp;&nbsp;',
    };

    // Load available roles
    strukshurApiService.searchContractorRoles.list().$promise
        .then(function (res) {
            vm.availableRoles = res.roles;
            vm.editMember.role = _find(vm.availableRoles, { name: member.role });
            vm.editMember.phoneNumber = member.phoneNumber;
        })
    ;

    /**
     * Invites the member to be a part of the project
     *
     * @param  {Object}  form  The form
     */
    vm.editMember = function (form) {
        vm.errorMessage = '';

        // Validates if a role was selected
        if (!vm.editMember.role) {
            vm.errorMessage = 'You need to select a role for the team member.';
            return;
        }

        // Get all selected permissions
        var selectedPermissions = [];
        vm.permissions.forEach(function (permission) {

            // First level
            if (permission.selected) {
                selectedPermissions.push(permission.value);
            }

            if (permission.children) {

                // Second level
                permission.children.forEach(function (permission) {

                    if (permission.selected) {
                        selectedPermissions.push(permission.value);
                    }

                    if (permission.children) {

                        // Third level
                        permission.children.forEach(function (permission) {

                            if (permission.selected) {
                                selectedPermissions.push(permission.value);
                            }
                        });
                    }
                });
            }
        });

        // Check if at least one permission was selected
        if (selectedPermissions.length === 0) {
            vm.errorMessage = 'You need to select at least one permission for the member.';
            return;
        }

        var data = {
            project_id: project.id,
            member_id: member.id,
            role_id: vm.editMember.role.id,
            phone_number: vm.editMember.phoneNumber,
            permissions: selectedPermissions.join(',')
        };

        // Updates the team member on the project
        vm.loading = true;
        strukshurApiService.projectTeamMember.update(data).$promise
            .then(function (res) {
                $uibModalInstance.close({ member: res.teamMember });
            })
            .catch(function (res) {
                if (res.status === 403) {
                    vm.errorMessage = 'You don\'t have the necessary permission to edit the team member.';
                } else {
                    if (res && res.data && res.data.message) {
                        vm.errorMessage = res.data.message;
                    } else {
                        vm.errorMessage = 'There was an error trying to edit the team member.';
                    }
                }
            })
            .finally(function () {
                vm.loading = false;
            })
        ;
    };

    /**
     * Select all user permissions
     */
    vm.selectAllPermissions = function () {
        ivhTreeviewMgr.selectAll(vm.permissions);
    };

    /**
     * Deselect all user permissions
     */
    vm.deselectAllPermissions = function () {
        ivhTreeviewMgr.deselectAll(vm.permissions);
    };
})

.controller('ProjectDetailRemoveMemberCtrl', function ProjectDetailRemoveMemberCtrl ($scope, $uibModalInstance, strukshurApiService) {
    var vm = $scope;

    vm.onConfirmChosen = function () {

        // Removes the member from the project
        vm.loading = true;
        strukshurApiService.projectTeamMember.remove({ project_id: vm.project.id, member_id: vm.member.id }).$promise
            .then(function (res) {
                $uibModalInstance.close();
            })
            .catch(function (res) {
                if (res.status === 403) {
                    vm.errorMessage = 'You don\'t have the necessary permission to remove the team member.';
                } else {
                    vm.errorMessage = 'There was an error trying to remove the member from the team.';
                }
            })
            .finally(function () {
                vm.loading = false;
            })
        ;
    };
})

.controller('ProjectDetailRemoveInviteCtrl', function ProjectDetailRemoveInviteCtrl ($scope, $uibModalInstance, strukshurApiService) {
    var vm = $scope;

    vm.onConfirmChosen = function () {

        // Removes the invite from the project
        vm.loading = true;
        strukshurApiService.projectTeamMember.removeInvite({ project_id: vm.project.id, invite_id: vm.invite.id }).$promise
            .then(function (res) {
                $uibModalInstance.close();
            })
            .catch(function (res) {
                if (res.status === 403) {
                    vm.errorMessage = 'You don\'t have the necessary permission to remove the invite.';
                } else {
                    vm.errorMessage = 'There was an error trying to remove the invite from the project.';
                }
            })
            .finally(function () {
                vm.loading = false;
            })
        ;
    };
})

;
