import * as angular from 'angular';

var app = angular.module('ContractorRoleService', []);

app.factory('ContractorRoleService', ['$http', 'strukshurApiService', '$q',  function ($http, strukshurApiService, $q) {
    var roles = [];

    return {
        getRoles: function () {
            var deferred = $q.defer();
            if (roles.length > 0) {
                deferred.resolve(roles);
            } else {
                strukshurApiService.searchContractorRoles.list({}, function (d) {
                    roles = d.roles;
                    deferred.resolve(roles);
                }, function (e) {
                    deferred.reject(e);
                });
            }

            return deferred.promise;
        }
    };
}]);
