import {
    filter as _filter,
    find as _find,
    has as _has,
    orderBy as _orderBy,
    pull as _pull,
    remove as _remove,
} from 'lodash';
import { findDeep } from 'deepdash-es/standalone';
import { filterDeep } from 'deepdash-es/standalone';
import * as angular from 'angular';
import { saveAs } from 'file-saver';

import strukshurConfig from '../../../config.ts';

angular.module('strukshurApp.projects.files', [])

.config(function config($stateProvider, $uibModalProvider) {
    $uibModalProvider.options = { animation: true, backdrop: 'static', keyboard: false, ariaLabelledBy: 'modal-title', ariaDescribedBy: 'modal-body' };

    $stateProvider
        .state('projects-detail.files', {
            url: '/files?folder_id&file_id',
            controller: 'ProjectDetailFilesCtrl',
            template: require('./project.files.tpl.html'),
            data: { pageTitle: 'Project / Files', class: 'projects projects-detail-files' }
        })
    ;
})

.controller('ProjectDetailFilesCtrl', function ProjectDetailFilesCtrl ($rootScope, $scope, $state, $stateParams, $timeout, $uibModal, FileService, smoothScroll, strukshurApiService, strukshurUploadService, toastr) {
    var vm = $scope;

    // Check if the user can access the current page or not
    if (!vm.$parent.checkPermission('project_file_view')) {
        return $state.go('projects-detail.access-denied', { section: 'Files' });
    }

    vm.files = [];
    vm.folders = [];
    vm.selected = [];
    vm.loading = false;
    vm.uploader = null;
    vm.searchTerm = '';
    vm.currentUser = {};
    vm.queuedFiles = [];
    vm.uploading = false;
    vm.sortBy = 'name-asc';
    vm.errorMessage = false;
    vm.currentFolder = null;
    vm.displayMethod = null;
    vm.showManagedInfo = false;
    vm.hasFilesToUpload = false;
    vm.userHasFilePermission = {};
    vm.fileId = $stateParams.file_id;
    vm.folderId = $stateParams.folder_id;
    vm.baseFileUploadUrl = strukshurConfig.api_url + '/project/file/upload';

    /**
     * Controller bootstrap logic
     */
    vm.init = () => {

        // Updates current route info on the project menu scope
        vm.$parent.currentRoute = $state.current.name;

        // Creates search request based on the received folder id
        let promise;
        if (vm.folderId) {
            promise = strukshurApiService.projectFolder.get({ folder_id: vm.folderId }).$promise;
        } else {
            promise = strukshurApiService.projectFolders.listRootAssets({ project_id: vm.$parent.project.id }).$promise;
        }

        vm.loading = true;
        promise
            .then((res) => {
                vm.currentUser = vm.$parent.currentUser;

                if (vm.folderId) {
                    vm.showManagedInfo = res.projectFolder.managed;
                    vm.currentFolder = res.projectFolder;
                    res.files = res.projectFolder.files;
                    res.folders = res.projectFolder.folders;
                }

                // Set files cover based on their extension
                res.files.forEach(file => {
                    file.isFile = true;
                    file.selected = false;
                    file.file = FileService.encodeFileUrlForS3(file.file);
                    file.extension = FileService.getFileExtension(file.file);
                    file.fileType = FileService.getFileType(file.file);
                    vm.setFileCover(file);
                });

                // Marks all folder objects accordingly
                res.folders.forEach(folder => {
                    folder.files = [];
                    folder.isFolder = true;
                    folder.selected = false;
                });

                vm.files = res.files;
                vm.folders = res.folders;

                vm.sortItems(vm.sortBy);

                // Scroll to the project file if one was passed as a parameter
                if (vm.fileId) {
                    $timeout(() => {
                        const fileEl = document.querySelector(`#file-${vm.fileId}`);

                        angular.element(fileEl).addClass('animate-bg');
                        angular.element(fileEl).addClass('highlighted');
                        smoothScroll(fileEl);

                        $timeout(() => angular.element(fileEl).removeClass('highlighted'), 2500);
                    }, 50);
                }
            })
            .finally(() => vm.loading = false)
        ;

        vm.setListDisplay();

        // Setup uploader logic
        vm.setupUploader();
    };

    /**
     * Sets grid as the active display method
     */
    vm.setGridDisplay = () => {
        if (vm.displayMethod === 'grid') { return; }

        vm.displayMethod = 'grid';
        angular.element('body').removeClass('list-view-enabled');
        angular.element('.files-container').addClass('grid-view');
        angular.element('.files-container').removeClass('list-view');
    };

    /**
     * Sets list as the active display method
     */
     vm.setListDisplay = () => {
        if (vm.displayMethod === 'list') { return; }

        vm.displayMethod = 'list';
        angular.element('body').addClass('list-view-enabled');
        angular.element('.files-container').addClass('list-view');
        angular.element('.files-container').removeClass('grid-view');
    };

    /**
     * Hides the managed info alert message
     */
    vm.hideManagedInfoMessage = () => {
        vm.showManagedInfo = false;
    };

    /**
     * Setups file uploader logic
     */
    vm.setupUploader = () => {
        strukshurUploadService.setup({
            subscribeEvents: [{
                scope: vm,
                name: 'upload-items-added',
                callback: vm.processAddedFiles,
            }, {
                scope: vm,
                name: 'upload-item-complete',
                callback: vm.processUploadedFile,
            }],
        });
        vm.uploader = strukshurUploadService.getUploader();
    };

    /**
     * Sets cover image for the given file
     *
     * @param  {object}  file  The file
     */
    vm.setFileCover = (file) => {
        const ext = FileService.getFileExtension(file.file);

        if (FileService.supportsImagePreviewExtension(ext)) {
            file.imgSrc = file.file;
            file.elStyle = { 'background-image': `url('${file.imgSrc}');` };
            file.isSupportedImagePreview = true;
            file.fileClass = { 'no-filter': true };
        } else {
            file.isSupportedImagePreview = false;
            file.imgSrc = 'assets/icons/file-filled.svg';
        }
    };

    /**
     * Sets the sort order for the current files
     *
     * @param  {string}  sortBy  The field to sort by
     */
    vm.setSortMethod = (sortBy) => {
        if (vm.sortBy === sortBy) { return; }

        vm.sortBy = sortBy;
        vm.sortItems(sortBy);
    };

    /**
     * Sorts files based on the given field
     *
     * @param  {string}  sortBy  The field to sort by
     */
    vm.sortItems = (sortBy) => {
        const sort = sortBy.split('-')[0],
            order = sortBy.split('-')[1];

        // Sort by name
        if (sort === 'name') {
            vm.folders = _orderBy(vm.folders, ['favorite', 'name'], ['desc', order]);
            vm.files = _orderBy(vm.files, ['favorite', 'filename'], ['desc', order]);
        } else if (sort === 'date') {
            vm.files = _orderBy(vm.files, ['favorite', 'createdAt'], ['desc', order]);
        } else if (sort === 'size') {
            vm.files = _orderBy(vm.files, ['favorite', 'size'], ['desc', order]);
        }
    };

    /**
     * Toggle the selection state of the given item
     *
     * @param  {object}  item  The item
     */
    vm.toggleSelection = (item) => {
        item.selected = !item.selected;

        if (item.selected) {
            vm.selected.push(item);
        } else {
            _pull(vm.selected, item);
        }
    };

    /**
     * Checks if the current user has the given permission on the project
     *
     * @param  {string}  permission  The permission key
     *
     * @return {boolean}
     */
    vm.checkPermission = (permission) => vm.$parent.checkPermission(permission);

    /**
     * Wether the current user has any of the given permissions on the project
     *
     * @param  {array}  permissions  The permission keys
     *
     * @return {boolean}
     */
    vm.hasAnyPermissions = (permissions) => vm.$parent.hasAnyPermissions(permissions);

    /**
     * Checks wether the user can upload files of the given type
     *
     * @return {boolean}
     */
    vm.canUploadFile = () => vm.$parent.hasAnyPermissions(['project_file_add', 'project_file_all']);

    /**
     * Wether the current user can open a given file or not
     *
     * @param  {object}  file  The file
     *
     * @return {boolean}
     */
    vm.canOpenFile = (file) => (vm.checkPermission('project_file_open') || vm.checkPermission('project_file_all'));

    /**
     * Wether the current user can edit a given file or not
     *
     * @param  {object}  file  The file
     *
     * @return {boolean}
     */
    vm.canEditFile = (file) => (!file.readonly && vm.checkPermission('project_file_edit') || vm.checkPermission('project_file_all'));

    /**
     * Wether the current user can delete a given file or not
     *
     * @param  {object}  file  The file
     *
     * @return {boolean}
     */
    vm.canDeleteFile = (file) => (!file.readonly && vm.checkPermission('project_file_delete') || vm.checkPermission('project_file_all'));

    /**
     * Wether the current user can open a given folder or not
     *
     * @param  {object}  folder  The folder
     *
     * @return {boolean}
     */
    vm.canOpenFolder = (folder) => (vm.checkPermission('project_file_open') || vm.checkPermission('project_file_all'));

    /**
     * Wether the current user can edit a given folder or not
     *
     * @param  {object}  folder  The folder
     *
     * @return {boolean}
     */
    vm.canEditFolder = (folder) => (!folder.readonly && vm.checkPermission('project_file_edit') || vm.checkPermission('project_file_all'));

    /**
     * Wether the current user can delete a given folder or not
     *
     * @param  {object}  folder  The folder
     *
     * @return {boolean}
     */
    vm.canDeleteFolder = (folder) => (!folder.readonly && vm.checkPermission('project_file_delete') || vm.checkPermission('project_file_all'));

    /**
     * Wether ot not the given type allows file upload or not
     *
     * @param  {string}  type  The type
     *
     * @return {boolean}
     */
    vm.typeAllowsFileUpload = (type) => (!['CHANGE_ORDER', 'ESTIMATE'].includes(type));

    /**
     * Hides the file search field
     */
    vm.hideSearch = () => {
        angular.element('.input-search-group').removeClass('focused');
    };

    /**
     * Shows the file search field
     */
     vm.showSearch = () => {
        angular.element('.input-search-group').addClass('focused');
        $timeout(() => (<HTMLInputElement>document.querySelector('.input-search')).focus(), 300);
    };

    /**
     * Callback fired when an item is dropped into a folder
     *
     * @param  {object}  folder  The target folder
     * @param  {object}  item    The dropped item
     * @param  {string}  type    The item type
     */
    vm.itemDropped = (folder, item, type) => {

        // Ignore drop if an item was dropped inside itself
        if (folder.id === item.id) { return; }

        // We should not allow managed folders to be dropped
        if (type === 'folder' && item.managed) {
            return vm.openManagedFolderMoveAttemptModal();
        }

        // Triggers move action depending on the item type
        if (type === 'file') {
            return vm.openMoveFileConfirmModal(folder, item);
        } else if (type === 'folder') {
            return vm.openMoveFolderConfirmModal(folder, item);
        }
    };

    /**
     * Opens the rename modal for the selected item
     */
    vm.renameSelected = () => {
        const item = vm.selected[0];
        if (item.isFile) {
            return vm.renameFile(item);
        }

        return vm.renameFolder(item);
    };

    /**
     * Opens the delete modal for the selected item
     */
    vm.deleteSelected = () => {
        const item = vm.selected[0];
        if (item.isFile) {
            return vm.removeFile(item);
        }

        return vm.removeFolder(item);
    };

    /**
     * Downloads the given file
     *
     * @param  {object}  file  The file
     */
    vm.downloadFile = (file) => {
        saveAs(file.file, file.filename);
    };

    /**
     * Opens a modal for adding a new project folder
     */
    vm.newFolder = () => {
        const modalInstance = $uibModal.open({
            scope: vm,
            keyboard: true,
            controller: 'ProjectDetailNewFolderCtrl',
            template: require('../../../common/templates/projects.newFolderModal.tpl.html'),
            resolve: {
                parentFolder: vm.currentFolder,
                project: vm.$parent.project,
            }
        });

        modalInstance.result.then(

            // Resolved callback
            (data) => { vm.folders.push(data.folder) },

            // Rejected callback
            angular.noop
        );
    };

    /**
     * Redirects the user to the given project folder
     *
     * @param  {object}  folder  The folder
     */
    vm.openFolder = (folder) => {
        if (!vm.canOpenFolder(folder)) { return; }

        $state.go('projects-detail.files', { folder_id: folder.id });
    };

    /**
     * Opens the modal for reming a project folder
     *
     * @param  {object}  folder  The folder
     */
    vm.renameFolder = (folder) => {
        if (!vm.canEditFile(folder)) { return; }

        const modalInstance = $uibModal.open({
            scope: vm,
            keyboard: true,
            controller: 'ProjectDetailEditFolderCtrl',
            template: require('../../../common/templates/projects.editFolderModal.tpl.html'),
            resolve: {
                folder: folder
            }
        });

        modalInstance.result.then(

            // Resolved callback
            (data) => folder.name = data.folder.name,

            // Rejected callback
            angular.noop
        );
    };

    /**
     * Opens modal to move a folder to another destination
     *
     * @param  {object}  folder  The folder to move
     */
    vm.openMoveFolderModal = (folder) => {
        if (!vm.canEditFile(folder)) { return; }

        const modalInstance = $uibModal.open({
            scope: vm.$new(),
            keyboard: true,
            controller: 'ProjectDetailMoveFolderCtrl',
            template: require('../../../common/templates/projects.moveFolderModal.tpl.html'),
            resolve: {
                folder: folder,
                project: vm.$parent.project,
            }
        });

        modalInstance.result.then(

            // Resolved callback
            () => _remove(vm.folders, folder),

            // Rejected callback
            angular.noop
        );
    };

    /**
     * Opens modal to confirm a move folder action
     *
     * @param  {object}  destFolder  The destination folder
     * @param  {object}  folder      The file to move
     */
    vm.openMoveFolderConfirmModal = (destFolder, folder) => {
        if (!vm.canEditFile(folder)) { return; }

        var $childScope = vm.$new();
        $childScope.title = 'Move folder';
        $childScope.message = `Are you sure you want to move the selected folder to "${destFolder.name}"?`;

        const modalInstance = $uibModal.open({
            keyboard: true,
            scope: $childScope,
            controller: 'ProjectDetailConfirmMoveFolderCtrl',
            template: require('../../../common/templates/base.confirm-modal.tpl.html'),
            resolve: {
                folder: folder,
                destFolder: destFolder,
                project: vm.$parent.project,
            }
        });

        modalInstance.result.then(

            // Resolved callback
            () => _remove(vm.folders, folder),

            // Rejected callback
            angular.noop
        );
    };

    /**
     * Favorites the received folder
     *
     * @param  {object}  folder  The folder
     */
    vm.favoriteFolder = (folder) => {
        if (!vm.canEditFile(folder)) { return; }

        strukshurApiService.projectFolder.favorite({ folder_id: folder.id }).$promise
            .then(() => {
                folder.favorite = true;
                vm.sortItems(vm.sortBy);
            })
            .catch((res: any) => {
                if (res.status === 403) {
                    toastr.error('You don\'t have the necessary permission to update the folder.', 'Error');
                } else {
                    toastr.error('There was an error trying to favorite the folder.', 'Error');
                }
            })
        ;
    };

    /**
     * Unfavorites the received folder
     *
     * @param  {object}  folder  The folder
     */
    vm.unfavoriteFolder = (folder) => {
        if (!vm.canEditFile(folder)) { return; }

        strukshurApiService.projectFolder.unfavorite({ folder_id: folder.id }).$promise
            .then(() => {
                folder.favorite = false;
                vm.sortItems(vm.sortBy);
            })
            .catch((res: any) => {
                if (res.status === 403) {
                    toastr.error('You don\'t have the necessary permission to update the folder.', 'Error');
                } else {
                    toastr.error('There was an error trying to unfavorite the folder.', 'Error');
                }
            })
        ;
    };

    /**
     * Removes the received folder from the server
     *
     * @param  {object}  folder  The folder
     */
    vm.removeFolder = (folder) => {
        if (!vm.canDeleteFolder(folder)) { return; }

        const $childScope = vm.$new();
        $childScope.folder = folder;
        $childScope.title = 'Delete folder';
        $childScope.message = 'Are you sure you want to delete the folder?';

        const modalInstance = $uibModal.open({
            keyboard: true,
            scope: $childScope,
            controller: 'ProjectDetailDeleteFolderCtrl',
            template: require('../../../common/templates/base.confirm-modal.tpl.html')
        });

        modalInstance.result.then(

            // Resolved callback
            () => _pull(vm.folders, folder),

            // Rejected callback
            angular.noop
        );
    };

    /**
     * Triggers file upload input click event
     */
    vm.selectFiles = () => {
        angular.element('#file-upload-input').click();
    };

    /**
     * Processes a file list selection coming from the user
     *
     * @param  {array}  files  The list of files to be processed
     */
    vm.processAddedFiles = ($event, files) => {

        // Check if the user can upload files to the dropped section
        if (!vm.canUploadFile()) { return; }

        // First we retrieve a referene to the type which the files were added to
        let parent = vm.currentFolder;

        // Then we initialize the queue properly for the correct file
        vm.errorMessage = '';

        // Process added files
        let hasFileToUpload = false;
        files.forEach((file) => {

            // Don't add to queue files that are larger than the upload limit
            if ((file.file.size / 1024 / 1024) > strukshurConfig.max_upload_size) {
                vm.errorMessage = 'The file "\' + file.file.name + \'" is larger than the limit of '+strukshurConfig.max_upload_size+'MB.';
                return;
            }

            file.parent = parent;
            file.url = `${vm.baseFileUploadUrl}?project_id=${vm.$parent.project.id}&filename=${file.name}`;
            if (file.parent) {
                file.url = file.url + `&parent_id=${parent.id}`;
            }

            hasFileToUpload = true;
        });

        if (hasFileToUpload) {
            $rootScope.showUploadManagerModal();
        }
    };

    /**
     * Event fired when a file upload is completed successfully
     *
     * @param  {Event}     event  The event object
     * @param  {FileItem}  file   The native file object
     * @param  {object}    res    The response object
     */
    vm.processUploadedFile = (event, file, res) => {
        const uploaded = res.file;

        // Only adds file to the list if the uploaded project is the same of the current project
        if (uploaded && uploaded.project_id == vm.$parent.project.id) {

            // Adds file if it was uploaded to the folder being currently displayed
            if ((vm.currentFolder && vm.currentFolder.id == uploaded.parent_id) ||
                (!vm.currentFolder && !uploaded.parent_id)) {

                uploaded.isFile = true;
                uploaded.selected = false;
                uploaded.file = FileService.encodeFileUrlForS3(uploaded.file);
                uploaded.extension = FileService.getFileExtension(uploaded.file);
                uploaded.fileType = FileService.getFileType(uploaded.file);
                vm.setFileCover(uploaded);
                vm.files.push(uploaded);
                vm.sortItems(vm.sortBy);
            }
        }
    };

    /**
     * Sets if there's still files to be uploaded or not
     */
    vm.setHasFilesToUploadState = () => {
        vm.hasFilesToUpload = (_find(vm.queuedFiles, { isUploading: false, isUploaded: false, isError: false }));
    };

    /**
     * Cancels the currently given file upload
     *
     * @param  {object}  type  The queue type
     * @param  {File}    file  The file
     */
    vm.cancelUpload = (type, file) => {

        // Cancels the file being currently uploaded
        vm.fileBeingUploaded.cancel();

        file.isError = true;
        file.isUploading = false;
        file.errorMessage = 'The upload was cancelled by the user.';
    };

    /**
     * Clears the current file queue
     *
     * @param  {object}  type  The queue type
     */
    vm.clearQueue = (type) => {
        if (type.uploading) { return; }

        type.queuedFiles = [];
        type.hasFilesToUpload = false;
    };

    /**
     * Cancels all the uploads currently queued
     *
     * @param  {object}  type  The queue type
     */
    vm.cancelUploadQueue = (type) => {
        if (type.fileBeingUploaded) {

            // Cancels the file being currently uploaded
            vm.fileBeingUploaded.cancel();
        }

        // Cancels all remaining upload requests
        vm.queuedFiles.forEach((file) => {
            if (!file.isUploaded) {
                file.isError = true;
                file.isUploading = false;
                file.errorMessage = 'The upload was cancelled by the user.';
            }
        });

        vm.uploading = false;
        vm.uploadQueueError = false;
        vm.fileBeingUploaded = null;
        type.uploading = false;
        type.fileBeingUploaded = null;
    };

    /**
     * Returns the next file to be uploaded from the queue
     *
     * @param  {object}  type  The queue type
     *
     * @return {File}
     */
    vm.getNextFile = (type) => {
        return _find(type.queuedFiles, { isUploading: false, isUploaded: false, isError: false });
    };

    /**
     * Removes the added file from the current list of files to upload
     *
     * @param  {object}  type  The queue type
     * @param  {File}    file  The file to remove
     */
    vm.removeQueuedFile = (type, file) => {

        // Do not remove if the file is being uploaded or was already uploaded
        if (file.isUploading || file.isUploaded || file.folder.uploading) { return; }

        // Remove it from the local type queue
        _remove(type.queuedFiles, file);

        // And also remove it from the general upload queue
        vm.uploader.removeFromQueue(file);
    };

    /**
     * Returns the given file extension
     *
     * @param  {string}  filename  The filename
     *
     * @return {string}
     */
    vm.getFileExtension = (filename) => {
        const parts = filename.split('.');

        return parts[parts.length-1].substr(0, 4);
    };

    /**
     * Returns the color for the given file extension
     *
     * @param  {object}  file  The file
     *
     * @return {string}
     */
    vm.getExtensionColor = (file) => FileService.getFileExtensionColor(file.name);

    /**
     * Opens modal to upgrade a user account
     */
    vm.upgradeSettingsAccount = () => {
        vm.$parent.upgradeSettingsAccount();
    };

    /**
     * Opens modal to explain the managed folder info
     */
    vm.openManagedInfoModal = () => {
        const $childScope = vm.$new();
        $childScope.title = 'Managed folders';
        $childScope.message = 'Managed folders are folders automatically created by Strukshur based on the content added to other sections of this project.';

        const modalInstance = $uibModal.open({
            keyboard: true,
            scope: $childScope,
            controller: 'ProjectDetailNotEnoughRoomsMessageCtrl',
            template: require('../../../common/templates/base.info-modal.tpl.html')
        });

        modalInstance.result.then(angular.noop, angular.noop);
    };

    /**
     * Opens modal to display a warning when a managed folder is moved
     */
    vm.openManagedFolderMoveAttemptModal = () => {
        const $childScope = vm.$new();
        $childScope.title = 'Warning';
        $childScope.message = 'Managed folders cannot be moved from their original destination.';

        const modalInstance = $uibModal.open({
            keyboard: true,
            scope: $childScope,
            controller: 'BaseInfoModalController',
            template: require('../../../common/templates/base.info-modal.tpl.html')
        });

        modalInstance.result.then(angular.noop, angular.noop);
    };

    /**
     * Opens the modal for editing a project file
     *
     * @param  {object}  file  The file
     */
    vm.editFile = (file) => {
        if (!vm.canEditFile(file)) { return; }

        // The user can't edit a file which is part of a final estimate
        if (file.isEstimateMarkedAsFinal) { return; }

        const modalInstance = $uibModal.open({
            scope: vm,
            keyboard: true,
            controller: 'ProjectDetailEditFileCtrl',
            template: require('../../../common/templates/projects.projectFileEditModal.tpl.html'),
            resolve: {
                file: file
            }
        });

        modalInstance.result.then(

            // Resolved callback
            (data) => file.filename = data.file.filename,

            // Rejected callback
            angular.noop
        );
    };

    /**
     * Opens modal to copy a file to another destination
     *
     * @param  {object}  file  The file to copy
     */
    vm.copyFile = (file) => {
        if (!vm.canOpenFile(file)) { return; }

        const modalInstance = $uibModal.open({
            scope: vm.$new(),
            keyboard: true,
            controller: 'ProjectDetailCopyFileCtrl',
            template: require('../../../common/templates/projects.copyFileModal.tpl.html'),
            resolve: {
                file: file,
                project: vm.$parent.project,
            }
        });

        modalInstance.result.then(

            // Resolved callback
            (res) => {
                //
            },

            // Rejected callback
            angular.noop
        );
    };

    /**
     * Opens modal to move a file to another destination
     *
     * @param  {object}  file  The file to move
     */
    vm.openMoveFileModal = (file) => {
        if (!vm.canEditFile(file)) { return; }

        const modalInstance = $uibModal.open({
            scope: vm.$new(),
            keyboard: true,
            controller: 'ProjectDetailMoveFileCtrl',
            template: require('../../../common/templates/projects.moveFileModal.tpl.html'),
            resolve: {
                file: file,
                project: vm.$parent.project,
            }
        });

        modalInstance.result.then(

            // Resolved callback
            () => _remove(vm.files, file),

            // Rejected callback
            angular.noop
        );
    };

    /**
     * Opens modal to confirm a move file action
     *
     * @param  {object}  folder  The folder to move the file to
     * @param  {object}  file    The file to move
     */
    vm.openMoveFileConfirmModal = (folder, file) => {
        if (!vm.canEditFile(file)) { return; }

        var $childScope = vm.$new();
        $childScope.title = 'Move file';
        $childScope.message = `Are you sure you want to move the selected file to "${folder.name}"?`;

        const modalInstance = $uibModal.open({
            keyboard: true,
            scope: $childScope,
            controller: 'ProjectDetailConfirmMoveFileCtrl',
            template: require('../../../common/templates/base.confirm-modal.tpl.html'),
            resolve: {
                file: file,
                folder: folder,
                project: vm.$parent.project,
            }
        });

        modalInstance.result.then(

            // Resolved callback
            () => _remove(vm.files, file),

            // Rejected callback
            angular.noop
        );
    };

    /**
     * Opens the modal for sharing a project file
     *
     * @param  {Object}  file  The file
     */
    vm.shareFile = (file: any) => {
        const modalInstance = $uibModal.open({
            scope: vm,
            keyboard: true,
            controller: 'ProjectDetailShareFileCtrl',
            template: require('../../../common/templates/projects.shareFileModal.tpl.html'),
            resolve: {
                file: file,
            }
        });

        modalInstance.result.then(

            // Resolved callback
            (data: any) => {
                file.sharedFile = data.sharedFile;
                toastr.success('The file was shared successfully.', 'File shared');
            },

            // Rejected callback
            angular.noop
        );
    };

    /**
     * Favorites the received file
     *
     * @param  {object}  file  The file
     */
    vm.favoriteFile = (file) => {
        if (!vm.canEditFile(file)) { return; }

        strukshurApiService.projectFile.favorite({ file_id: file.id }).$promise
            .then(() => {
                file.favorite = true;
                vm.sortItems(vm.sortBy);
            })
            .catch((res: any) => {
                if (res.status === 403) {
                    toastr.error('You don\'t have the necessary permission to update the file.', 'Error');
                } else {
                    toastr.error('There was an error trying to favorite the file.', 'Error');
                }
            })
        ;
    };

    /**
     * Unfavorites the received file
     *
     * @param  {object}  file  The file
     */
    vm.unfavoriteFile = (file) => {
        if (!vm.canEditFile(file)) { return; }

        strukshurApiService.projectFile.unfavorite({ file_id: file.id }).$promise
            .then(() => {
                file.favorite = false;
                vm.sortItems(vm.sortBy);
            })
            .catch((res: any) => {
                if (res.status === 403) {
                    toastr.error('You don\'t have the necessary permission to update the file.', 'Error');
                } else {
                    toastr.error('There was an error trying to unfavorite the file.', 'Error');
                }
            })
        ;
    };

    /**
     * Marks the received file as active
     *
     * @param  {Object}  file  The file
     */
    vm.activateFile = function (file) {
        if (!vm.canEditFile(file)) { return; }

        var $childScope = vm.$new();
        $childScope.file = file;
        $childScope.project = vm.$parent.project;
        $childScope.errorMessage = '';
        $childScope.title = 'Activate file';
        $childScope.message = 'Are you sure you want to mark the file as active?';

        var modalInstance = $uibModal.open({
            keyboard: true,
            scope: $childScope,
            controller: 'ProjectDetailActivateFileCtrl',
            template: require('../../../common/templates/base.confirm-modal.tpl.html')
        });

        modalInstance.result.then(

            // Resolved callback
            () => file.active = true,

            // Rejected callback
            angular.noop
        );
    };

    /**
     * Marks the received file as inactive
     *
     * @param  {Object}  file  The file
     */
    vm.inactivateFile = function (file) {
        if (!vm.canEditFile(file)) { return; }

        var $childScope = vm.$new();
        $childScope.file = file;
        $childScope.project = vm.$parent.project;
        $childScope.errorMessage = '';
        $childScope.title = 'Inactivate file';
        $childScope.message = 'Are you sure you want to mark the file as inactive?';

        var modalInstance = $uibModal.open({
            keyboard: true,
            scope: $childScope,
            controller: 'ProjectDetailInactivateFileCtrl',
            template: require('../../../common/templates/base.confirm-modal.tpl.html')
        });

        modalInstance.result.then(

            // Resolved callback
            () => file.active = false,

            // Rejected callback
            angular.noop
        );
    };

    /**
     * Removes the received file from the server
     *
     * @param  {Object}  file  The file
     */
    vm.removeFile = (file) => {

        // The user can't remove a file which is part of a final estimate
        if (file.isEstimateMarkedAsFinal) { return; }

        if (!vm.canDeleteFile(file)) { return; }

        const $childScope = vm.$new();
        $childScope.file = file;
        $childScope.project = vm.$parent.project;
        $childScope.errorMessage = '';
        $childScope.title = 'Delete file';
        $childScope.message = 'Are you sure you want to delete the file?';

        const modalInstance = $uibModal.open({
            keyboard: true,
            scope: $childScope,
            controller: 'ProjectDetailDeleteFileCtrl',
            template: require('../../../common/templates/projects.deleteFileConfirmationModal.tpl.html')
        });

        modalInstance.result.then(

            // Resolved callback
            (data) => _pull(vm.files, file),

            // Rejected callback
            angular.noop
        );
    };

    /**
     * Updates the page items to show on the project sidebar
     */
    vm.updatePageItems = () => {
        //
    };

    vm.init();
})

.controller('ProjectDetailNewFolderCtrl', function ($scope, $uibModalInstance, parentFolder, project, strukshurApiService) {
    var vm = $scope;

    vm.loading = false;
    vm.errorMessage = '';
    vm.newFolder = { name: '' };

    vm.saveFolder = (form) => {
        if (form.folderForm.$valid) {
            vm.loading = true;
            vm.errorMessage = '';

            const data = {
                project_id: project.id,
                name: vm.newFolder.name,
                parent_id: (parentFolder) ? parentFolder.id : null,
            };

            strukshurApiService.projectFolder.create(data).$promise
                .then((res) => {
                    let folder = res.projectFolder;

                    if (!folder.childFiles) {
                        folder.childFiles = [];
                    }
                    if (!folder.childFolders) {
                        folder.childFolders = [];
                    }

                    $uibModalInstance.close({ folder: folder });
                })
                .catch((res) => {
                    if (res.status === 403) {
                        vm.errorMessage = 'You don\'t have the necessary permission to create the folder.';
                    } else {
                        vm.errorMessage = 'There was an error trying to create the project folder.';
                    }
                })
                .finally(() => vm.loading = false)
            ;
        }
    };
})

.controller('ProjectDetailEditFolderCtrl', function ($scope, $uibModalInstance, folder, strukshurApiService) {
    var vm = $scope;

    vm.loading = false;
    vm.errorMessage = '';
    vm.editFolder = { name: folder.name };

    vm.saveFolder = (form) => {
        if (form.folderForm.$valid) {
            vm.loading = true;
            vm.errorMessage = '';

            const data = {
                folder_id: folder.id,
                name: vm.editFolder.name,
            };

            strukshurApiService.projectFolder.update(data).$promise
                .then((res) => $uibModalInstance.close({ folder: res.projectFolder }))
                .catch((res) => {
                    if (res.status === 403) {
                        vm.errorMessage = 'You don\'t have the necessary permission to edit the folder.';
                    } else {
                        vm.errorMessage = 'There was an error trying to edit the project folder.';
                    }
                })
                .finally(() =>  vm.loading = false)
            ;
        }
    };
})

.controller('ProjectDetailMoveFolderCtrl', function ($scope, $uibModalInstance, folder, project, strukshurApiService) {
    var vm = $scope;

    vm.folders = [];
    vm.saving = false;
    vm.folder = folder;
    vm.loading = false;
    vm.breadcrumb = [];
    vm.errorMessage = '';
    vm.currentFolders = [];
    vm.selectedFolder = null;

    /**
     * Controller initialization
     */
    vm.init = () => {

        // Loads project folder structure so the user can pick the destination folder
        vm.loading = true;
        strukshurApiService.projectFolder.paths({ project_id: project.id, from_folder: 'ROOT', depth: 5 }).$promise
            .then((res) => {

                // Filter out target folder and it's children since we should not let the user move if inside itself
                vm.folders = filterDeep(res.paths, (item) => item.id !== vm.folder.id, { pathFormat: 'array', childrenPath: ['children'], onFalse: { skipChildren: true } });
                vm.selectedFolder = 'ROOT';
                vm.currentFolders = vm.folders;
            })
            .catch((err) => {
                if (err.status === 403) {
                    vm.errorMessage = 'You don\'t have the necessary permission to list folders in this project.';
                } else {
                    if (err && err.data && err.data.message) {
                        vm.errorMessage = err.data.message;
                    } else {
                        vm.errorMessage = 'There was an error trying to list the project\'s folders.';
                    }
                }
            })
            .finally(() => vm.loading = false)
        ;
    };

    /**
     * Navigates to the current project's root folder
     */
    vm.goToRoot = () => {
        vm.breadcrumb = [];
        vm.selectedFolder = 'ROOT';
        vm.currentFolders = vm.folders;
    };

    /**
     * Navigates to the given folder on the selection view
     *
     * @param  {object}  folder  The folder
     */
    vm.goToFolder = (folder) => {
        const found = findDeep(vm.folders, (item) => item.id === folder.id, { pathFormat: 'array', childrenPath: ['children'] });
        if (found) {
            vm.selectedFolder = found.value;
            vm.currentFolders = found.value.children;

            // Pops out all elements from the breadcrumb after the selected folder
            vm.breadcrumb = vm.breadcrumb.splice(0, vm.breadcrumb.indexOf(folder)+1);
        }
    };

    /**
     * Handles folder selection
     *
     * @param  {object}  folder  The folder
     */
    vm.folderSelected = (folder) => {
        if (folder.selected) { return; }

        vm.currentFolders.forEach(folder => { folder.selected = false });

        vm.selectedFolder = folder;
        if (folder.children.length === 0) {
            folder.selected = true;
        } else {
            vm.currentFolders = folder.children;
            vm.breadcrumb.push({ id: folder.id, name: folder.name });
        }
    };

    /**
     * Moves the current folder to the selected destination
     */
    vm.moveFolder = () => {
        vm.errorMessage = '';

        let data: any = {
            folder_id: folder.id,
            project_id: project.id,
        };

        // Only adds selected folder information if it's a child folder.
        // The selected folder will be the current folder's new parent.
        if (vm.selectedFolder && vm.selectedFolder !== 'ROOT') {
            data.parent_id = vm.selectedFolder.id;
        }

        // Invites the user to the team
        vm.saving = true;
        strukshurApiService.projectFolder.move(data).$promise
            .then((res) => $uibModalInstance.close())
            .catch((res) => {
                if (res.status === 403) {
                    vm.errorMessage = 'You don\'t have the necessary permission to move the folder.';
                } else {
                    if (res && res.data && res.data.message) {
                        vm.errorMessage = res.data.message;
                    } else {
                        vm.errorMessage = 'There was an error trying to move the folder.';
                    }
                }
            })
            .finally(() => vm.saving = false)
        ;
    };

    vm.init();
})

.controller('ProjectDetailConfirmMoveFolderCtrl', function ($scope, $uibModalInstance, destFolder, folder, project, strukshurApiService) {
    var vm = $scope;

    vm.folder = folder;
    vm.loading = false;
    vm.project = project;
    vm.destFolder = destFolder;

    /**
     * Moves the current file to the selected destination
     */
    vm.onConfirmChosen = () => {
        vm.errorMessage = '';

        let data: any = {
            folder_id: folder.id,
            parent_id: destFolder.id,
            project_id: project.id,
        };

        // Invites the user to the team
        vm.loading = true;
        strukshurApiService.projectFolder.move(data).$promise
            .then((res) => $uibModalInstance.close())
            .catch((res) => {
                if (res.status === 403) {
                    vm.errorMessage = 'You don\'t have the necessary permission to move the folder.';
                } else {
                    if (res && res.data && res.data.message) {
                        vm.errorMessage = res.data.message;
                    } else {
                        vm.errorMessage = 'There was an error trying to move the folder.';
                    }
                }
            })
            .finally(() => vm.loading = false)
        ;
    };
})

.controller('ProjectDetailDeleteFolderCtrl', function ($scope, $uibModalInstance, strukshurApiService) {
    var vm = $scope;

    vm.onConfirmChosen = () => {

        // Deletes the folder on the server
        vm.loading = true;
        strukshurApiService.projectFolder.delete({ folder_id: vm.folder.id }).$promise
            .then(() => $uibModalInstance.close())
            .catch((res) => {
                if (res.status === 403) {
                    vm.errorMessage = 'You don\'t have the necessary permission to delete the folder.';
                } else {
                    vm.errorMessage = 'There was an error trying to delete the folder.';
                }
            })
            .finally(() => vm.loading = false)
        ;
    };
})

.controller('ProjectDetailEditFileCtrl', function ProjectDetailEditFileCtrl ($scope, $uibModalInstance, file, strukshurApiService) {
    var vm = $scope;

    vm.loading = false;
    vm.errorMessage = '';
    vm.editFile = { name: file.filename };

    /**
     * Saves the file on the backend
     *
     * @param  {object}  form  The form
     */
    vm.saveFile = function (form: any) {
        let promise: any;

        if (form.fileForm.$valid) {
            vm.loading = true;
            vm.errorMessage = '';

            let data = {
                file_id: file.id,
                name: vm.editFile.name,
            };

            if (file.isEstimateSubCategoryFile) {
                promise = strukshurApiService.projectEstimateSubCategoryFile.update(data).$promise
            } else {
                promise = strukshurApiService.projectFile.update(data).$promise
            }

            promise
                .then((res: any) => $uibModalInstance.close({ file: res.file }))
                .catch((res: any) => {
                    if (res.status === 403) {
                        vm.errorMessage = 'You don\'t have the necessary permission to edit the file.';
                    } else {
                        vm.errorMessage = 'There was an error trying to edit the project file.';
                    }
                })
                .finally(() => vm.loading = false)
            ;
        }
    };
})

.controller('ProjectDetailCopyFileCtrl', function ($scope, $uibModalInstance, file, project, strukshurApiService) {
    var vm = $scope;

    vm.file = file;
    vm.folders = [];
    vm.saving = false;
    vm.loading = false;
    vm.breadcrumb = [];
    vm.errorMessage = '';
    vm.currentFolders = [];
    vm.selectedFolder = null;

    /**
     * Controller initialization
     */
    vm.init = () => {

        // Loads project folder structure so the user can pick the destination folder
        vm.loading = true;
        strukshurApiService.projectFolder.paths({ project_id: project.id, from_folder: 'ROOT', depth: 5 }).$promise
            .then((res) => {
                vm.folders = res.paths;
                vm.selectedFolder = 'ROOT';
                vm.currentFolders = vm.folders;
            })
            .catch((err) => {
                if (err.status === 403) {
                    vm.errorMessage = 'You don\'t have the necessary permission to list folders in this project.';
                } else {
                    if (err && err.data && err.data.message) {
                        vm.errorMessage = err.data.message;
                    } else {
                        vm.errorMessage = 'There was an error trying to list the project\'s folders.';
                    }
                }
            })
            .finally(() => vm.loading = false)
        ;
    };

    /**
     * Navigates to the current project's root folder
     */
    vm.goToRoot = () => {
        vm.breadcrumb = [];
        vm.selectedFolder = 'ROOT';
        vm.currentFolders = vm.folders;
    };

    /**
     * Navigates to the given folder on the selection view
     *
     * @param  {object}  folder  The folder
     */
    vm.goToFolder = (folder) => {
        const found = findDeep(vm.folders, (item) => item.id === folder.id, { pathFormat: 'array', childrenPath: ['children'] });
        if (found) {
            vm.selectedFolder = found.value;
            vm.currentFolders = found.value.children;

            // Pops out all elements from the breadcrumb after the selected folder
            vm.breadcrumb = vm.breadcrumb.splice(0, vm.breadcrumb.indexOf(folder)+1);
        }
    };

    /**
     * Handles folder selection
     *
     * @param  {object}  folder  The folder
     */
    vm.folderSelected = (folder) => {
        if (folder.selected) { return; }

        vm.currentFolders.forEach(folder => { folder.selected = false });

        vm.selectedFolder = folder;
        if (folder.children.length === 0) {
            folder.selected = true;
        } else {
            vm.currentFolders = folder.children;
            vm.breadcrumb.push({ id: folder.id, name: folder.name });
        }
    };

    /**
     * Copies the current file to the selected destination
     */
    vm.copyFile = () => {
        vm.errorMessage = '';

        let data: any = {
            file_id: file.id,
            project_id: project.id,
        };

        // Only adds selected folder information if it's a child folder.
        // The selected folder will be the current folder's new parent.
        if (vm.selectedFolder && vm.selectedFolder !== 'ROOT') {
            data.parent_id = vm.selectedFolder.id;
        }

        // Invites the user to the team
        vm.saving = true;
        strukshurApiService.projectFile.copy(data).$promise
            .then((res) => $uibModalInstance.close())
            .catch((res) => {
                if (res.status === 403) {
                    vm.errorMessage = 'You don\'t have the necessary permission to copy the file.';
                } else {
                    if (res && res.data && res.data.message) {
                        vm.errorMessage = res.data.message;
                    } else {
                        vm.errorMessage = 'There was an error trying to copy the file.';
                    }
                }
            })
            .finally(() => vm.saving = false)
        ;
    };

    vm.init();
})

.controller('ProjectDetailMoveFileCtrl', function ($scope, $uibModalInstance, file, project, strukshurApiService) {
    var vm = $scope;

    vm.file = file;
    vm.folders = [];
    vm.saving = false;
    vm.loading = false;
    vm.breadcrumb = [];
    vm.errorMessage = '';
    vm.currentFolders = [];
    vm.selectedFolder = null;

    /**
     * Controller initialization
     */
    vm.init = () => {

        // Loads project folder structure so the user can pick the destination folder
        vm.loading = true;
        strukshurApiService.projectFolder.paths({ project_id: project.id, from_folder: 'ROOT', depth: 5 }).$promise
            .then((res) => {
                vm.folders = res.paths;
                vm.selectedFolder = 'ROOT';
                vm.currentFolders = vm.folders;
            })
            .catch((err) => {
                if (err.status === 403) {
                    vm.errorMessage = 'You don\'t have the necessary permission to list folders in this project.';
                } else {
                    if (err && err.data && err.data.message) {
                        vm.errorMessage = err.data.message;
                    } else {
                        vm.errorMessage = 'There was an error trying to list the project\'s folders.';
                    }
                }
            })
            .finally(() => vm.loading = false)
        ;
    };

    /**
     * Navigates to the current project's root folder
     */
    vm.goToRoot = () => {
        vm.breadcrumb = [];
        vm.selectedFolder = 'ROOT';
        vm.currentFolders = vm.folders;
    };

    /**
     * Navigates to the given folder on the selection view
     *
     * @param  {object}  folder  The folder
     */
    vm.goToFolder = (folder) => {
        const found = findDeep(vm.folders, (item) => item.id === folder.id, { pathFormat: 'array', childrenPath: ['children'] });
        if (found) {
            vm.selectedFolder = found.value;
            vm.currentFolders = found.value.children;

            // Pops out all elements from the breadcrumb after the selected folder
            vm.breadcrumb = vm.breadcrumb.splice(0, vm.breadcrumb.indexOf(folder)+1);
        }
    };

    /**
     * Handles folder selection
     *
     * @param  {object}  folder  The folder
     */
    vm.folderSelected = (folder) => {
        if (folder.selected) { return; }

        vm.currentFolders.forEach(folder => { folder.selected = false });

        vm.selectedFolder = folder;
        if (folder.children.length === 0) {
            folder.selected = true;
        } else {
            vm.currentFolders = folder.children;
            vm.breadcrumb.push({ id: folder.id, name: folder.name });
        }
    };

    /**
     * Moves the current file to the selected destination
     */
    vm.moveFile = () => {
        vm.errorMessage = '';

        let data: any = {
            file_id: file.id,
            project_id: project.id,
        };

        // Only adds selected folder information if it's a child folder.
        // The selected folder will be the current folder's new parent.
        if (vm.selectedFolder && vm.selectedFolder !== 'ROOT') {
            data.parent_id = vm.selectedFolder.id;
        }

        // Invites the user to the team
        vm.saving = true;
        strukshurApiService.projectFile.move(data).$promise
            .then((res) => $uibModalInstance.close())
            .catch((res) => {
                if (res.status === 403) {
                    vm.errorMessage = 'You don\'t have the necessary permission to move the file.';
                } else {
                    if (res && res.data && res.data.message) {
                        vm.errorMessage = res.data.message;
                    } else {
                        vm.errorMessage = 'There was an error trying to move the file.';
                    }
                }
            })
            .finally(() => vm.saving = false)
        ;
    };

    vm.init();
})

.controller('ProjectDetailConfirmMoveFileCtrl', function ($scope, $uibModalInstance, file, folder, project, strukshurApiService) {
    var vm = $scope;

    vm.file = file;
    vm.folder = folder;
    vm.loading = false;
    vm.project = project;

    /**
     * Moves the current file to the selected destination
     */
    vm.onConfirmChosen = () => {
        vm.errorMessage = '';

        let data: any = {
            file_id: file.id,
            parent_id: folder.id,
            project_id: project.id,
        };

        // Invites the user to the team
        vm.loading = true;
        strukshurApiService.projectFile.move(data).$promise
            .then((res) => $uibModalInstance.close())
            .catch((res) => {
                if (res.status === 403) {
                    vm.errorMessage = 'You don\'t have the necessary permission to move the file.';
                } else {
                    if (res && res.data && res.data.message) {
                        vm.errorMessage = res.data.message;
                    } else {
                        vm.errorMessage = 'There was an error trying to move the file.';
                    }
                }
            })
            .finally(() => vm.loading = false)
        ;
    };
})

.controller('ProjectDetailActivateFileCtrl', function ProjectDetailActivateFileCtrl ($scope, $uibModalInstance, strukshurApiService) {
    let vm = $scope;

    vm.onConfirmChosen = () => {
        let promise;

        // Deletes the file on the server
        vm.loading = true;
        if (vm.file.isEstimateSubCategoryFile) {
            promise = strukshurApiService.projectEstimateSubCategoryFile.activate({ file_id: vm.file.id }).$promise
        } else {
            promise = strukshurApiService.projectFile.activate({ file_id: vm.file.id }).$promise
        }

        promise
            .then((res: any) => $uibModalInstance.close(res))
            .catch((res: any) => {
                if (res.status === 403) {
                    vm.errorMessage = 'You don\'t have the necessary permission to update the file.';
                } else {
                    vm.errorMessage = 'There was an error trying to update the file.';
                }
            })
            .finally(() => vm.loading = false)
        ;
    };
})

.controller('ProjectDetailInactivateFileCtrl', function ProjectDetailInactivateFileCtrl ($scope, $uibModalInstance, strukshurApiService) {
    let vm = $scope;

    vm.onConfirmChosen = () => {
        let promise;

        // Deletes the file on the server
        vm.loading = true;
        if (vm.file.isEstimateSubCategoryFile) {
            promise = strukshurApiService.projectEstimateSubCategoryFile.inactivate({ file_id: vm.file.id }).$promise
        } else {
            promise = strukshurApiService.projectFile.inactivate({ file_id: vm.file.id }).$promise
        }

        promise
            .then((res: any) => $uibModalInstance.close(res))
            .catch((res: any) => {
                if (res.status === 403) {
                    vm.errorMessage = 'You don\'t have the necessary permission to update the file.';
                } else {
                    vm.errorMessage = 'There was an error trying to update the file.';
                }
            })
            .finally(() => vm.loading = false)
        ;
    };
})

.controller('ProjectDetailShareFileCtrl', function ProjectDetailShareFileCtrl ($scope, $uibModalInstance, file, ngClipboard, toastr, strukshurApiService) {
    var vm = $scope;

    vm.file = file;
    vm.emails = '';
    vm.loading = false;
    vm.loadingLink = false;
    vm.errorMessage = '';
    vm.canShareFile = (file.sharedFile != null);

    /**
     * Creates a new share link for the current file
     */
    vm.createShareLink = () => {
        if (vm.loadingLink) { return; }

        vm.loadingLink = true;
        vm.errorMessage = '';

        strukshurApiService.projectFile.createShareLink({ file_id: vm.file.id }).$promise
            .then((res: any) => {
                vm.canShareFile = true;
                vm.file.sharedFile = res.sharedFile;
            })
            .catch((res: any) => {
                if (res.status === 403) {
                    vm.errorMessage = 'You don\'t have the necessary permission to edit the file.';
                } else {
                    vm.errorMessage = 'There was an error trying to edit the project file.';
                }
            })
            .finally(() => vm.loadingLink = false)
        ;
    };

    /**
     * Copy shared file url
     */
    vm.copyLink = () => {
        ngClipboard.toClipboard(vm.file.sharedFile.shareUrl);
        toastr.success('The share link was copied to the clipboard.', 'Link copied!');
    };

    /**
     * Saves the file on the backend
     *
     * @param  {object}  form  The form
     */
    vm.shareFile = (form: any) => {
        if (vm.loading) { return; }

        if (form.shareFile.$valid) {
            vm.loading = true;
            vm.errorMessage = '';

            const data = {
                file_id: file.id,
                receipients: vm.emails,
            };

            strukshurApiService.projectFile.shareFile(data).$promise
                .then((res: any) => {
                    $uibModalInstance.close({ sharedFile: vm.file.sharedFile })
                })
                .catch((res: any) => {
                    if (res.status === 403) {
                        vm.errorMessage = 'You don\'t have the necessary permission to share the file.';
                    } else {
                        vm.errorMessage = 'There was an error trying to share the project file.';
                    }
                })
                .finally(() => vm.loading = false)
            ;
        }
    };
})

.controller('ProjectDetailDeleteFileCtrl', function ProjectDetailDeleteFileCtrl ($scope, $uibModalInstance, strukshurApiService) {
    var vm = $scope;

    vm.removeRelated = false;

    vm.onConfirmChosen = function () {
        let promise: any,
            removeRelated = (vm.removeRelated) ? 1 : 0;

        // Deletes the file on the server
        vm.loading = true;
        if (vm.file.isEstimateSubCategoryFile) {
            promise = strukshurApiService.projectEstimateSubCategoryFile.delete({ file_id: vm.file.id }).$promise
        } else {
            promise = strukshurApiService.projectFile.delete({ file_id: vm.file.id, remove_related: removeRelated }).$promise
        }

        promise
            .then(() => $uibModalInstance.close())
            .catch((res: any) => {
                if (res.status === 403) {
                    vm.errorMessage = 'You don\'t have the necessary permission to delete the file.';
                } else {
                    vm.errorMessage = 'There was an error trying to delete the file.';
                }
            })
            .finally(() => vm.loading = false)
        ;
    };
})

;
