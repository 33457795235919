import * as angular from 'angular';

var app = angular.module('strukshurAdminService', ['ngResource']);
app.factory('strukshurAdminService', function ($q, strukshurApiService, $timeout) {
    return {
        userAdd: function (myform) {
            var i,tot,vendor_ids = [], formData,
                deferred = $q.defer();

            if (!myform.$valid) {
                console.log('productAdd Form Error');
                console.log(myform);
                deferred.reject();

                return deferred.promise;
            }

            formData = {
                first_name: myform.first_name.$viewValue,
                last_name: myform.last_name.$viewValue,
                zip_code: myform.zip_code.$viewValue,
                password: myform.mypassword1.$viewValue,
                email: myform.email.$viewValue,
                role: myform.role.$viewValue,
                plan: myform.subscription.plan,
                isProConfirmed: myform.subscription.isProConfirmed
            };

            if (myform.role.$viewValue === 'pro' || myform.role.$viewValue === 'admin') {
                tot = myform.vendors.length;
                for (i = 0; i < tot; i++) {
                    vendor_ids.push(myform.vendors[i].id);
                }
                formData.isSeller = myform.isSeller;
                formData.vendor_ids = JSON.stringify(vendor_ids);
                formData.reseller = myform.reseller;
                formData.biz_name = myform.biz_name.$viewValue;
                formData.phone_number = myform.phone_number.$viewValue;
                formData.fax_number = myform.fax_number.$viewValue;
                formData.state_lic = myform.state_lic.$viewValue;
                formData.founded = myform.founded.$viewValue;
                formData.hours = myform.hours.$viewValue;
                formData.bbb = myform.bbb.$viewValue;
                formData.website = myform.website.$viewValue;
                formData.fb = myform.fb.$viewValue;
                formData.yelp = myform.yelp.$viewValue;
                formData.category = myform.category;
                formData.description = myform.description.$viewValue;
                formData.employees = parseInt(myform.employees.$viewValue);
                if(tot===0 && formData.isSeller){
                    formData.shipping_address1 = myform.shipping_address1.$viewValue;
                    formData.shipping_address2 = myform.shipping_address2.$viewValue;
                    formData.shipping_city = myform.shipping_city.$viewValue;
                    formData.shipping_state = myform.shipping_state.$viewValue;
                    formData.shipping_zip = myform.shipping_zip.$viewValue;
                    formData.is_fedex = myform.carriers.is_fedex;
                    formData.is_usps = myform.carriers.is_usps;
                    formData.is_ups = myform.carriers.is_ups;
                    formData.is_pickup = myform.carriers.is_pickup;
                }
            }

            strukshurApiService.postAdminUserAdd.save(formData, function(data){
                console.log('productAdd Success');
                console.log(data);
                deferred.resolve(data);
            }, function(e){
                console.log('productAdd Failed');
                console.log(e);
                deferred.reject(e);
            });

            return deferred.promise;
        },

        userEdit: function (myform) {
            var i,tot,vendor_ids = [], formData,
                deferred = $q.defer();

            if (!myform.$valid) {
                console.log('userEdit Form Error');
                console.log(myform);
                deferred.reject();

                return deferred.promise;
            }

            formData = {
                id: myform.id,
                first_name: myform.first_name.$viewValue,
                last_name: myform.last_name.$viewValue,
                zip_code: myform.zip_code.$viewValue,
                email: myform.email.$viewValue,
                role: myform.role,
                plan: myform.subscription.plan,
                proConfirmed: myform.subscription.proConfirmed
            };

            if (myform.role === 'pro' || myform.role === 'admin') {
                tot = myform.vendors.length;
                for (i = 0; i < tot; i++) {
                    vendor_ids.push(myform.vendors[i].id);
                }
                formData.isSeller = myform.isSeller;
                formData.vendor_ids = JSON.stringify(vendor_ids);
                formData.reseller = myform.reseller;
                formData.biz_name = myform.biz_name.$viewValue;
                formData.phone_number = myform.phone_number.$viewValue;
                formData.fax_number = myform.fax_number.$viewValue;
                formData.state_lic = myform.state_lic.$viewValue;
                formData.founded = myform.founded.$viewValue;
                formData.hours = myform.hours.$viewValue;
                formData.bbb = myform.bbb.$viewValue;
                formData.website = myform.website.$viewValue;
                formData.fb = myform.fb.$viewValue;
                formData.yelp = myform.yelp.$viewValue;
                formData.category = myform.category;
                formData.employees = parseInt(myform.employees.$viewValue);
                formData.description = myform.description.$viewValue;
                if (tot === 0 && formData.isSeller) {
                    formData.shipping_address1 = myform.shipping_address1.$viewValue;
                    formData.shipping_address2 = myform.shipping_address2.$viewValue;
                    formData.shipping_city = myform.shipping_city.$viewValue;
                    formData.shipping_state = myform.shipping_state.$viewValue;
                    formData.shipping_zip = myform.shipping_zip.$viewValue;
                    formData.is_fedex = myform.carriers.is_fedex;
                    formData.is_usps = myform.carriers.is_usps;
                    formData.is_ups = myform.carriers.is_ups;
                    formData.is_pickup = myform.carriers.is_pickup;
                }
            }

            strukshurApiService.postAdminUserEdit.save(formData, function (data) {
                console.log('userEdit Success');
                console.log(data);
                deferred.resolve(data);
            }, function (e) {
                console.log('userEdit Failed');
                console.log(e);
                deferred.reject();
            });

            return deferred.promise;
        },

        productEdit: function (myform, roomTypes, roomAttrs, productCats, vendors) {
            var deferred = $q.defer();

            if (!myform.$valid) {
                console.log('productEdit Form Error');
                console.log(myform);
                deferred.reject();

                return deferred.promise;
            }

            // if there is an updated image
            var fileBase64 = '';
            if (myform.product_image && myform.product_image.$viewValue !== undefined) {
                fileBase64 = myform.product_image.$viewValue.base64;
            }

            var formData = {
                id: myform.id,
                title: myform.title.$viewValue,
                note: myform.note.$viewValue,
                description: myform.description.$viewValue,
                status: myform.status,
                sku: myform.sku.$viewValue,
                quantity: myform.quantity.$viewValue,
                weight: myform.weight.$viewValue,
                unit: myform.unit.$viewValue,
                price: myform.price.$viewValue,
                price_pro: myform.price_pro.$viewValue,
                mfg: myform.mfg.$viewValue,

                shipping: myform.shipping.$viewValue,
                enable_shipping_2day: myform.enable_shipping_2day,
                shipping_2day: myform.shipping_2day,
                enable_shipping_overnight: myform.enable_shipping_overnight,
                shipping_overnight: myform.shipping_overnight,
                width: myform.width.$viewValue,
                height: myform.height.$viewValue,
                depth: myform.depth.$viewValue,
                leadtime: myform.leadtime.$viewValue,

                product_image: fileBase64,
                room_types: roomTypes,
                room_attrs: roomAttrs,
                product_cats: productCats,
                vendors: vendors
            };

            strukshurApiService.postAdminProductEdit.save(formData, function (data) {
                console.log('productEdit Success');
                console.log(data);
                deferred.resolve(data);
            }, function (e) {
                console.log('productEdit Failed');
                console.log(e);
                deferred.reject();
            });

            return deferred.promise;
        },

        productAdd: function (myform, roomTypes, roomAttrs, productCategories, vendors) {
            console.log('Register click from the service');
            console.log(roomTypes);

            var deferred = $q.defer();

            if (!myform.$valid) {
                console.log('productAdd Form Error');
                console.log(myform);
                deferred.reject();

                return deferred.promise;
            }

            var fileBase64 = '';
            //console.log('upload');
            //console.log(myform.product_image);
            if (myform.product_image) {
                fileBase64 = myform.product_image.$viewValue.base64;
            }

            var formData = {
                title: myform.title.$viewValue,
                note: myform.note.$viewValue,
                description: myform.description.$viewValue,
                status: myform.status.$viewValue,
                sku: myform.sku.$viewValue,
                quantity: myform.quantity.$viewValue,
                weight: myform.weight.$viewValue,
                unit: myform.unit.$viewValue,
                price: myform.price.$viewValue,
                price_pro: myform.price_pro.$viewValue,
                mfg: myform.mfg.$viewValue,

                shipping: myform.shipping.$viewValue,
                enable_shipping_2day: myform.enable_shipping_2day,
                shipping_2day: myform.shipping_2day,
                enable_shipping_overnight: myform.enable_shipping_overnight,
                shipping_overnight: myform.shipping_overnight,
                width: myform.width.$viewValue,
                height: myform.height.$viewValue,
                depth: myform.depth.$viewValue,
                leadtime: myform.leadtime.$viewValue,

                product_image: fileBase64,
                room_types: roomTypes,
                room_attrs: roomAttrs,
                product_cats: productCategories,
                vendors: vendors
            };
            strukshurApiService.postAdminProductAdd.save(formData, function(data){
                console.log('productAdd Success');
                console.log(data);
                deferred.resolve(data);
            }, function(e){
                console.log('productAdd Failed');
                console.log(e);
                deferred.reject();
            });

            return deferred.promise;

        },


        vendorAdd: function(myform) {

            var deferred = $q.defer();

            if (!myform.$valid) {
                deferred.reject();
                return deferred.promise;
            }

            var fileBase64Logo = '';
            if (myform.logo) {
                fileBase64Logo = myform.logo.$viewValue.base64;
            }

            var fileBase64Header = '';
            if (myform.header_image) {
                fileBase64Header = myform.header_image.$viewValue.base64;
            }

            var formData = {
                name: myform.name.$viewValue,
                email: myform.email.$viewValue,
                description: myform.description.$viewValue,
                tagline: myform.tagline.$viewValue,
                status: myform.status.$viewValue,
                header_image: fileBase64Header,
                logo: fileBase64Logo
            };
            strukshurApiService.postAdminVendorAdd.save(formData, function(data){
                deferred.resolve(data);
            }, function(e){
                console.log('vendorAdd Failed');
                console.log(e);
                deferred.reject();
            });

            return deferred.promise;
        },

        vendorEdit: function(myform) {
            var deferred = $q.defer();

            if (!myform.$valid) {
                console.log('vendorEdit Form Error');
                console.log(myform);
                deferred.reject();

                return deferred.promise;
            }

            var fileBase64Logo = '';
            if (myform.logo && myform.logo.$viewValue) {
                fileBase64Logo = myform.logo.$viewValue.base64;
            }

            var fileBase64Header = '';
            if (myform.header_image && myform.header_image.$viewValue) {
                fileBase64Header = myform.header_image.$viewValue.base64;
            }

            var formData = {
                id: myform.id,
                name: myform.name.$viewValue,
                email: myform.email.$viewValue,
                description: myform.description.$viewValue,
                tagline: myform.tagline.$viewValue,
                status: myform.status,
                header_image: fileBase64Header,
                logo: fileBase64Logo
            };

            strukshurApiService.postAdminVendorEdit.save(formData, function (data) {
                console.log('postAdminVendorEdit Success');
                console.log(data);
                deferred.resolve(data);
            }, function (e) {
                console.log('postAdminVendorEdit Failed');
                console.log(e);
                deferred.reject();
            });

            return deferred.promise;
        }
    };
});
