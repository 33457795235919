import * as angular from 'angular';

angular.module('strukshurApp.productAdminOrders', ['ui.router', 'ui.bootstrap'])

.config(function config($stateProvider) {
    $stateProvider
        .state('productAdmin.orders', {
            url: '/orders/list?:status&:customer&:order_id',
            params: {
                page: null
            },
            views: {
                productAdmin_middle_view: {
                    controller: 'ProductAdminOrdersCtrl',
                    template: require('./admin_orders.tpl.html')
                }
            },
            data: { pageTitle: 'Orders: List' }
        })
        .state( 'productAdmin.order', {
            url: '/order/:suborder_id/details',
            views: {
                productAdmin_middle_view: {
                  controller: 'ProductAdminOrderDetailCtrl',
                  template: require('./admin_order_details.tpl.html')
                }
            },
            data: { pageTitle: 'Order: Detail' }
        })
    ;
})

.controller( 'ProductAdminOrdersCtrl', function ProductAdminOrdersCtrl($scope, $state, $stateParams, strukshurApiService, AdminPage, CartService, strukshurUserService) {
    $scope.perPage = 5;
    $scope.page = 1;
    $scope.total = 0;
    $scope.orders = [];
    $scope.terms = {
        status: $stateParams.status?$stateParams.status:'',
        customer: $stateParams.customer?$stateParams.customer:'',
        suborder_id: $stateParams.order_id?$stateParams.order_id:''
    };
    $scope.hasParams = ($scope.terms.status || $scope.terms.customer || $scope.terms.suborder_id);
    $scope.strukshurFee = CartService.getStrukshurFee();
    $scope.currentUser = {};
    $scope.isAdmin = false;
    strukshurUserService.getUser().then(function (d) {
        $scope.currentUser = d;
        $scope.isAdmin = strukshurUserService.isAdmin();
    });

    $scope.clearSearchOrders = function () {
        if($scope.isAdmin){
            $state.go('admin.orders', {status: '', customer: '', order_id: ''});
        }
        else{
            $state.go('productAdmin.orders', {status: '', customer: '', order_id: ''});
        }
    };
    $scope.formFetchOrders = function () {
        if($scope.terms.suborder_id){
            $scope.terms.suborder_id = $scope.terms.suborder_id.replace(/[^0-9\-]/, '');
        }
        $scope.hasParams = ($scope.terms.status || $scope.terms.customer || $scope.terms.suborder_id);
        if($scope.isAdmin){
            $state.go('admin.orders', {status: $scope.terms.status, customer: $scope.terms.customer, order_id: $scope.terms.suborder_id});
        }
        else{
            $state.go('productAdmin.orders', {status: $scope.terms.status, customer: $scope.terms.customer, order_id: $scope.terms.suborder_id});
        }

    };

    $scope.fetchOrders = function() {
        strukshurApiService.ordersSeller.query({page: $scope.page, terms: JSON.stringify($scope.terms)}, function(d) {
            var i, tot, i2, tot2;
            $scope.total = d.total;
            $scope.perPage = d.perPage;
            $scope.orders = d.orders;

            //calculate total items
            tot2 = $scope.orders.length;
            for(i2=0;i2<tot2;i2++){
                tot = $scope.orders[i2].suborder.productOrders.length;
                $scope.orders[i2].suborder.tot_items = 0;
                for(i=0;i<tot;i++){
                    $scope.orders[i2].suborder.tot_items = $scope.orders[i2].suborder.tot_items + $scope.orders[i2].suborder.productOrders[i].quantity;
                }
            }


            //$scope.page++;
            $scope.totalPages = $scope.calculateTotalPages();

        }, function(e) {

            return false;
        });
    };


    $scope.selectPage = function(page) {
        if (page > 0 && (page <= $scope.totalPages)) {
            $scope.page = AdminPage.setPage(page);
            $scope.fetchOrders();
        }
    };

    $scope.noNext = function() {
        return Math.ceil($scope.total / $scope.perPage) <= $scope.page;
    };

    $scope.noPrevious = function() {
        return 1 >= $scope.page;
    };

    $scope.calculateTotalPages = function() {
        var totalPages = $scope.perPage < 1 ? 1 : Math.ceil($scope.total / $scope.perPage);
        return Math.max(totalPages || 0, 1);
    };

    $scope.totalSuborderList = function (order) {
        var i, tot, amount = 0.0;

        tot = order.suborder.productOrders.length;
        for(i=0;i<tot;i++){
            amount = amount + (parseFloat(order.suborder.productOrders[i].price)*parseFloat(order.suborder.productOrders[i].quantity)) + parseFloat(order.suborder.productOrders[i].tax) + parseFloat(order.suborder.productOrders[i].shippingPrice);
        }
        return Math.round(amount*(1+$scope.strukshurFee)*100)/100;
    };
    $scope.fetchOrders();
})
.controller( 'ProductAdminOrderDetailCtrl', function ProductAdminOrderDetailCtrl( $scope, $state, $stateParams, $timeout, $uibModal, strukshurApiService, InboxService, CartService, strukshurUserService, smoothScroll ) {

    $scope.suborder_id = $stateParams.suborder_id;
    $scope.order = {};
    $scope.suborder = {productOrders:[]};
    $scope.notes = [];
    $scope.charges = [];
    $scope.buyer = {};
    $scope.isUrl = InboxService.isUrl;
    $scope.messages = [];
    $scope.page = 0;
    $scope.savingOrder = false;
    $scope.orderStatus = '';
    $scope.confirmMessage = false;
    $scope.quantityEdit = false;
    $scope.strukshurFee = CartService.getStrukshurFee();
    $scope.grand_total = 0;
    $scope.questionForm = {};
    $scope.messageData = {};
    $scope.currentUser = {};
    $scope.isAdmin = false;
    $scope.hasReturn = false;

    var element = document.getElementById('top');
    smoothScroll(element);
    strukshurUserService.getUser().then(function (d) {
        $scope.currentUser = d;
        $scope.isAdmin = strukshurUserService.isAdmin();
    });

    //datepicker configuration
    $scope.format = 'MM/dd/yyyy';
    $scope.dateOptions = {
        formatYear: 'yy',
        startingDay: 1
    };
    $scope.datePicker = function() {
        $scope.creationDateOpened = true;
    };
    $scope.creationDateOpened = false;

    $scope.errorMessage = false;
    $scope.loading = false;
    $scope.uploading = false;
    $scope.timeDelay = 100000;
    //timeout chat sync
    $scope.timeoutPromise = false;
    $scope.accessDennied = false;

    $scope.getNextPage = function () {
        //timeout chat sync
        if($scope.timeoutPromise){
            $timeout.cancel($scope.timeoutPromise);
            $scope.timeoutPromise = false;
        }
        $scope.page++;
        $scope.loading = true;
        strukshurApiService.thread.query({thread_id: $scope.suborder.thread_id, last_message_id: 0, page: $scope.page}, function (d) {
            var i, tot, date;
            $scope.loading = false;
            $scope.total = d.total;
            tot = d.messages.length;
            for(i=0;i<tot;i++){
                date = new Date(d.messages[i].creationDate);
                d.messages[i].dayString = String(date.getYear())+String(date.getMonth())+String(date.getDay());
                $scope.messages.push(d.messages[i]);
            }
            //timeout chat sync
            $scope.timeoutPromise = $timeout($scope.getPrevious, $scope.timeDelay);
        }, function (e) {
            $scope.loading = false;
            $scope.errorMessage = e.message;
        });
    };
    $scope.getPrevious = function (showLoad) {
        var first_message_id = 0;
        if(typeof showLoad==='undefined'){
            showLoad = false;
        }
        //timeout chat sync
        if($scope.timeoutPromise){
            $timeout.cancel($scope.timeoutPromise);
            $scope.timeoutPromise = false;
        }
        if(!document.getElementById('main_chat_container_'+$scope.suborder_id)){ //so when it goes to another page it stops loading
            return;
        }
        if($scope.messages && $scope.messages.length>0){
            first_message_id = $scope.messages[0].id;
        }
        if(showLoad){
            $scope.loading = true;
        }

        strukshurApiService.thread.query({thread_id: $scope.suborder.thread_id, last_message_id: first_message_id, page: 0,ignoreLoadingBar: true}, function (d) {
            var i, tot, date;
            $scope.loading = false;
            $scope.total = d.total;
            tot = d.messages.length;
            for(i=tot-1;i>=0;i--){
                date = new Date(d.messages[i].creationDate);
                d.messages[i].dayString = String(date.getYear())+String(date.getMonth())+String(date.getDay());
                $scope.messages.unshift(d.messages[i]);
            }
            //timeout chat sync
            $scope.timeoutPromise = $timeout($scope.getPrevious, $scope.timeDelay);
        }, function (e) {
            $scope.loading = false;
            $scope.errorMessage = e.message;
        });
    };

    $scope.sendMessage = function () {
        if($scope.loading){
            return;
        }
        //timeout chat sync
        if($scope.timeoutPromise){
            $timeout.cancel($scope.timeoutPromise);
            $scope.timeoutPromise = false;
        }
        $scope.loading = true;
        $scope.errorMessage = false;
        strukshurApiService.message.save({thread_id: $scope.suborder.thread_id, message: $scope.messageData.newMessage}, function (d) {
            $scope.loading = false;
            $scope.errorMessage = false;
            $scope.messageData.newMessage = '';
            $scope.getPrevious(true);
        }, function (e) {
            $scope.loading = false;
            $scope.errorMessage = e.data.message;
        });
    };

    $scope.uploadMessage = function () {
        $scope.uploading = true;

        var data = {
            suborder_id: $scope.suborder_id,
            file: 'data:'+$scope.messageData.attach_file.filetype+';base64,'+$scope.messageData.attach_file.base64,
            filename: $scope.messageData.attach_file.filename,
            type: 'DEFAULT'
        };

        strukshurApiService.suborderFiles.create(data).$promise
            .then(function (res) {
                $scope.uploading = false;
                $scope.messageData.attach_file = null;
                $scope.messageData.newMessage = res.suborderFile.file;
                $scope.sendMessage();
                if(res.note){
                    $scope.notes.push(res.note);
                }
            })
            .catch(function () {
                $scope.uploading = false;
            })
        ;
    };

    $scope.getStatus = function (status) {
        if(status==='failed'){
            return 'Failed';
        }
        else if(status==='processing'){
            return 'Processing';
        }
        else if(status==='completed'){
            return 'Completed';
        }
        else if(status==='cancelled'){
            return 'Cancelled';
        }
        else if(status==='refunded'){
            return 'Refunded';
        }
        else{
            return 'Pending payment';
        }
    };

    $scope.getStatusDescription = function (status) {
        if(status==='failed'){
            return 'Payment failed or was declined (unpaid). Note that this status may not show immediately and instead show as Pending until verified';
        }
        else if(status==='processing'){
            return 'Payment received and stock has been reduced - the order is awaiting fulfillment. All product orders require processing, except those that are Digital and Downloadable.';
        }
        else if(status==='completed'){
            return 'Order fulfilled and complete - requires no further action';
        }
        else if(status==='cancelled'){
            return 'Cancelled by an admin or the customer - no further action required (Cancelling an order does not affect stock quantity by default)';
        }
        else if(status==='refunded'){
            return 'Refunded by an admin - no further action required';
        }
        else{
            return 'Awaiting payment - stock is reduced, but you need to confirm payment (unpaid)';
        }
    };

    $scope.toggleQuantity = function (product) {
        if(typeof product.quantityEdit==='undefined'){
            product.quantityEdit = false;
        }
        product.quantityEdit = (!product.quantityEdit);
    };

    $scope.updateQuantitySuborder = function (product) {
        var data, old_total;
        if(product.loading){
            return;
        }
        product.loading = true;

        data = {
            suborder_id: $scope.suborder.id,
            product_order_id: product.id,
            quantity: product.quantityEdited,
            price: product.priceEdited
        };

        old_total = $scope.grand_total;
        strukshurApiService.productOrderDetails.save(data, function (d) {
            product.quantity = product.quantityEdited;
            product.price = product.priceEdited;
            product.quantityEdit = false;
            product.loading = false;
            if(product.quantity>0){
                product.tax = d.productOrder.tax;
            }
            if(d && d.note){
                $scope.notes.push(d.note);
            }
            $scope.grand_total = $scope.totalSuborder($scope.suborder.productOrders);
            if(old_total<$scope.grand_total && $scope.grand_total>$scope.total_charge){
                $scope.chargePop();
            }
        }, function (e) {
            product.loading = false;
            $scope.grand_total = $scope.totalSuborder($scope.suborder.productOrders);
        });
    };

    $scope.chargePop = function () {
        $scope.confirmMessage = false;
        var modalInstance = $uibModal.open({
            animation: true,
            keyboard: true,
            scope: $scope,
            ariaLabelledBy: 'modal-title',
            ariaDescribedBy: 'modal-body',
            controller: 'ProductAdminSubOrderChargeCtrl',
            template: require('../../../common/templates/suborder.charge-modal.tpl.html'),
            resolve: {
                suborder: function () {
                    return $scope.suborder;
                },
                charges: function () {
                    return $scope.charges;
                },
                notes: function () {
                    return $scope.notes;
                },
                total: function () {
                    return $scope.totalSuborder($scope.suborder.productOrders);
                }
            }
        });
        modalInstance.result.then(function (selectedItem) {
            $scope.total_charge = $scope.calculateCharges();
        }, function () {

        });
    };

    $scope.returnPop = function (suborder) {
        var modalInstance = $uibModal.open({
            animation: true,
            keyboard: true,
            scope: $scope,
            ariaLabelledBy: 'modal-title',
            ariaDescribedBy: 'modal-body',
            controller: 'ReturnModalCtrl',
            template: require('../../../common/templates/suborder.return-modal.tpl.html'),
            resolve: {
                suborder: function () {
                    return suborder;
                },
                order: function () {
                    return $scope.order;
                }
            }
        });
        modalInstance.result.then(function (selectedItem) {
            //send email
            $scope.confirmMessage = selectedItem.message;
            $scope.suborder = selectedItem.suborder;
            $scope.total_charge = $scope.calculateCharges();
            $scope.hasReturn = true;
        }, function () {
            //cancel confirmation
        });
    };

    $scope.refundPop = function (charge) {
        $scope.confirmMessage = false;
        var modalInstance = $uibModal.open({
            animation: true,
            keyboard: true,
            scope: $scope,
            ariaLabelledBy: 'modal-title',
            ariaDescribedBy: 'modal-body',
            controller: 'RefundModalCtrl',
            template: require('../../../common/templates/suborder.refund-modal.tpl.html'),
            resolve: {
                suborder: function () {
                    return $scope.suborder;
                },
                charge: function () {
                    return charge;
                },
                notes: function () {
                    return $scope.notes;
                },
                total: function () {
                    return $scope.totalSuborder($scope.suborder.productOrders);
                }
            }
        });
        modalInstance.result.then(function (selectedItem) {
            $scope.total_charge = $scope.calculateCharges();
        }, function () {

        });
    };

    $scope.deletePopOrder = function (product) {
        $scope.confirmMessage = false;
        var modalInstance = $uibModal.open({
            animation: true,
            keyboard: true,
            scope: $scope,
            ariaLabelledBy: 'modal-title',
            ariaDescribedBy: 'modal-body',
            controller: 'ConfirmOrderProductDeleteCtrl',
            template: require('../../../common/templates/confirm-modal.tpl.html'),
            resolve: {
                suborder: function () {
                    return $scope.suborder;
                },
                product: function () {
                    return product;
                },
                message: function () {
                    return 'Are you sure?';
                }
            }
        });
        modalInstance.result.then(function (selectedItem) {
            //send email
            $scope.confirmMessage = selectedItem;
            product.quantity = 0;
            $scope.grand_total = $scope.totalSuborder($scope.suborder.productOrders);
        }, function () {
            //cancel confirmation
        });
    };

    $scope.totalSuborder = function (productOrders) {
        var i, tot, amount = 0.0, returned = 0.0;

        if(!productOrders || productOrders.length===0){
            return amount;
        }
        tot = productOrders.length;
        for(i=0;i<tot;i++){
            if(parseInt(productOrders[i].quantity)===0){
                continue;
            }
            amount = amount + (parseFloat(productOrders[i].price)*parseFloat(productOrders[i].quantity)) + parseFloat(productOrders[i].tax) + parseFloat(productOrders[i].shippingPrice);
            if(productOrders[i].amountReturned){
                returned = returned + parseFloat(productOrders[i].amountReturned);
            }
        }
        return Math.round((((amount+($scope.suborder.shippingVendor?$scope.suborder.shippingVendor:0))*(1+$scope.strukshurFee))-returned)*100)/100;
    };

    $scope.calculateCharges = function () {
        var i, tot, amount = 0.0;

        if(!$scope.charges || $scope.charges.length===0){
            return amount;
        }
        tot = $scope.charges.length;
        for(i=0;i<tot;i++){
            amount = amount + parseFloat($scope.charges[i].amount);
            if($scope.charges[i].refund){
                amount = amount - parseFloat($scope.charges[i].refund.totConnect) - parseFloat($scope.charges[i].refund.totStrukshur);
            }
        }
        return Math.round(amount*100)/100;
    };

    $scope.sendOrderEmail = function () {
        $scope.confirmMessage = false;
        var modalInstance = $uibModal.open({
            animation: true,
            keyboard: true,
            scope: $scope,
            ariaLabelledBy: 'modal-title',
            ariaDescribedBy: 'modal-body',
            controller: 'ConfirmEmailCtrl',
            template: require('../../../common/templates/confirm-modal.tpl.html'),
            resolve: {
                suborder: function () {
                    return $scope.suborder;
                },
                message: function () {
                    return 'Are you sure?';
                }
            }
        });
        modalInstance.result.then(function (selectedItem) {
            //send email
            $scope.confirmMessage = selectedItem;
        }, function () {
            //cancel confirmation
        });
    };

    $scope.editOrderPop = function () {
        $scope.confirmMessage = false;
        var modalInstance = $uibModal.open({
            animation: true,
            keyboard: true,
            scope: $scope,
            ariaLabelledBy: 'modal-title',
            ariaDescribedBy: 'modal-body',
            controller: 'ProductAdminSubOrderEditCtrl',
            template: require('../../../common/templates/suborder.edit-modal.tpl.html'),
            resolve: {
                suborder: function () {
                    return $scope.suborder;
                }
            }
        });
        modalInstance.result.then(function (selectedItem) {
            $scope.suborder = selectedItem;
            $scope.grand_total = $scope.totalSuborder($scope.suborder.productOrders);
        }, function () {

        });
    };


    strukshurApiService.orderDetails.query({suborder_id: $scope.suborder_id}, function(d) {
        var i, tot;
        $scope.order = d.order;
        $scope.suborder = d.suborder;
        $scope.notes = d.notes;
        $scope.charges = d.charges;
        $scope.buyer = d.buyer;
        $scope.suborder.creationDate = new Date($scope.suborder.creationDate);
        $scope.orderStatus = $scope.suborder.status;

        $scope.grand_total = $scope.totalSuborder($scope.suborder.productOrders);
        $scope.total_charge = $scope.calculateCharges();

        tot = $scope.suborder.productOrders.length;
        for(i=0;i<tot;i++){
            if($scope.suborder.productOrders[i].quantityReturned){
                $scope.hasReturn = true;
                break;
            }
        }
        $scope.getNextPage();

    }, function(e) {
        if(e.status===403){
            $scope.accessDennied = true;
        }
        return false;
    });
})

.controller( 'ProductAdminSubOrderEditCtrl', function ProductAdminSubOrderEditCtrl( $scope, $uibModalInstance, $uibModal, suborder, strukshurApiService ) {
    $scope.loading = false;
    $scope.suborder = suborder;
    $scope.errorMessage = false;
    $scope.orderStatus = suborder.status;
    $scope.carrier = suborder.carrier;
    $scope.tracking = suborder.tracking;
    $scope.shippingVendor1 = parseFloat(suborder.shippingVendor);


    $scope.sendEmail = function () {
        if($scope.orderStatus!=='pending'){
            $scope.confirmMessage = false;
            var modalInstance = $uibModal.open({
                animation: true,
                keyboard: true,
                scope: $scope,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                controller: 'ConfirmEmailCtrl',
                template: require('../../../common/templates/confirm-modal.tpl.html'),
                resolve: {
                    suborder: function () {
                        return $scope.suborder;
                    },
                    message: function () {
                        return 'Do you want to notify the customer?';
                    }
                }
            });
            modalInstance.result.then(function (selectedItem) {
                $scope.confirmMessage = selectedItem;
                $uibModalInstance.close($scope.suborder);
            }, function () {
                $uibModalInstance.close($scope.suborder);
            });
        }
        else{
            $uibModalInstance.close($scope.suborder);
        }

    };
    $scope.saveOrder = function () {
        var data;
        if($scope.loading){
            return;
        }
        if($scope.orderStatus==='completed'){
            if($scope.carrier===''){
                $scope.errorMessage = 'Carrier is required';
                return;
            }
            if($scope.tracking===''){
                $scope.errorMessage = 'Tracking Number is required';
                return;
            }
        }
        if($scope.suborder.vendor) {
            $scope.shippingVendor1 = $scope.form.OrderForm.shippingVendor1.$viewValue; //why is not updating from the form??
            if ($scope.shippingVendor1 === '' || isNaN($scope.shippingVendor1) || $scope.shippingVendor1 < 0) {
                $scope.errorMessage = 'Shipping Amount must be numeric';
                return;
            }
            $scope.shippingVendor1 = parseFloat($scope.shippingVendor1);
        }
        $scope.loading = true;
        $scope.errorMessage = false;
        data = {
            suborder_id: $scope.suborder.id,
            status: $scope.orderStatus,
            carrier: $scope.carrier,
            tracking: $scope.tracking,
            shippingVendor: $scope.shippingVendor1
        };
        strukshurApiService.orderDetails.save(data, function(d) {
            $scope.suborder.status = $scope.orderStatus;
            $scope.suborder.carrier = $scope.carrier;
            $scope.suborder.tracking = $scope.tracking;
            $scope.suborder.shippingVendor = $scope.shippingVendor1;
            $scope.loading = false;
            if(d && d.note && $scope.notes){
                $scope.notes.push(d.note);
            }
            $scope.sendEmail();
        }, function(e) {
            $scope.errorMessage = e.message;
            $scope.loading = false;
            return false;
        });
    };

    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };

})

.controller( 'ProductAdminSubOrderChargeCtrl', function ProductAdminSubOrderChargeCtrl( $scope, $uibModalInstance, $uibModal, suborder, charges, notes, total, strukshurApiService ) {
    var i, tot;
    $scope.loading = false;
    $scope.suborder = suborder;
    $scope.notes = notes;
    $scope.charges = charges;
    $scope.errorMessage = false;
    $scope.form = {};
    $scope.maximumCharge = 999999.99;
    $scope.charge = {
        option: 'pending',
        amount: 0
    };

    $scope.totalCharges = 0;
    $scope.total = total;

    tot = $scope.charges.length;
    for(i=0;i<tot;i++){
        $scope.totalCharges = $scope.totalCharges + $scope.charges[i].amount - $scope.charges[i].refund.totStrukshur - $scope.charges[i].refund.totConnect;
    }

    $scope.difference = 0;
    if($scope.total>$scope.totalCharges){
        $scope.difference = Math.round(($scope.total - $scope.totalCharges)*100)/100;
    }
    $scope.charge.amount = Math.min($scope.difference,$scope.maximumCharge);

    $scope.saveCharge = function () {
        var data;
        if($scope.loading){
            return;
        }
        if($scope.charge.option==='custom' && (!$scope.charge.amount || isNaN($scope.charge.amount))){
            $scope.errorMessage = 'Invalid Amount';
            return;
        }
        if($scope.charge.option==='custom' && $scope.charge.amount<0.5){
            $scope.errorMessage = 'Minimum amount allowed is $0.50';
            return;
        }
        else if($scope.charge.option==='pending' && $scope.difference===0){
            $uibModalInstance.dismiss();
            return;
        }
        else if($scope.charge.option==='pending' && $scope.difference<0.5){
            $scope.errorMessage = 'Minimum amount allowed is $0.50';
            return;
        }
        $scope.loading = true;
        $scope.errorMessage = false;
        data = {
            suborder_id: $scope.suborder.id,
            amount: $scope.charge.option==='pending'?$scope.difference:$scope.charge.amount
        };
        strukshurApiService.chargeSuborder.save(data, function(d) {
            var tot, i;
            tot = d.notes.length;
            for(i=0;i<tot;i++){
                $scope.notes.push(d.notes[i]);
            }
            if(d.suborderCharge && d.suborderCharge.id){
                $scope.charges.push(d.suborderCharge);
            }
            $scope.loading = false;
            $uibModalInstance.close($scope.suborder);
        }, function(e) {
            $scope.errorMessage = e.data.message;
            $scope.loading = false;
            return false;
        });
    };

    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };

})

.controller( 'RefundModalCtrl', function RefundModalCtrl( $scope, $uibModalInstance, $uibModal, suborder, charge, notes, total, strukshurApiService ) {
    var i, tot;
    $scope.loading = false;
    $scope.suborder = suborder;
    $scope.notes = notes;
    $scope.charge = charge;
    $scope.errorRefund = false;
    $scope.form = {};
    $scope.refund = {
        option: 'pending',
        amount: 0,
        refundReason: 'requested_by_customer'
    };


    if(!$scope.charge.refund.totConnect){
        $scope.charge.refund.totConnect = 0;
    }
    if(!$scope.charge.refund.totStrukshur){
        $scope.charge.refund.totStrukshur = 0;
    }

    $scope.difference = 0;
    if($scope.charge.amount>($scope.charge.refund.totConnect + $scope.charge.refund.totStrukshur)){
        $scope.difference = Math.round(($scope.charge.amount - ($scope.charge.refund.totConnect + $scope.charge.refund.totStrukshur))*100)/100;
    }
    $scope.refund.amount = $scope.difference;

    $scope.saveRefund = function () {
        var data;
        if($scope.loading){
            return;
        }
        if($scope.charge.option==='custom' && (!$scope.charge.amount || isNaN($scope.charge.amount))){
            $scope.errorRefund = 'Invalid Amount';
            return;
        }
        if($scope.charge.option==='custom' && $scope.charge.amount<=0){
            $scope.errorRefund = 'Invalid Amount';
            return;
        }
        else if($scope.charge.option==='pending' && $scope.difference===0){
            $uibModalInstance.dismiss();
            return;
        }
        else if($scope.charge.option==='pending' && $scope.difference<=0){
            $scope.errorRefund = 'Invalid Amount';
            return;
        }
        $scope.loading = true;
        $scope.errorRefund = false;
        data = {
            suborder_id: $scope.suborder.id,
            charge_id: $scope.charge.id,
            amount: $scope.refund.option==='pending'?$scope.difference:$scope.refund.amount,
            reason: $scope.refund.refundReason
        };
        strukshurApiService.refundCharge.save(data, function(d) {
            var tot, i;
            tot = d.notes.length;
            for(i=0;i<tot;i++){
                $scope.notes.push(d.notes[i]);
            }
            $scope.charge.refund = {
                totConnect: d.totConnect,
                totStrukshur: d.totStrukshur
            };
            $scope.loading = false;
            $uibModalInstance.close($scope.charge);
        }, function(e) {
            $scope.errorRefund = e.data.message;
            $scope.loading = false;
        });
    };

    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };

})

.controller( 'ConfirmEmailCtrl', function ConfirmEmailCtrl( $scope, $uibModalInstance, suborder, strukshurApiService, message ) {
    $scope.loading = false;
    $scope.errorMessage = false;
    $scope.suborder = suborder;
    $scope.title = 'Confirm';
    $scope.message = message;
    $scope.confirm = function () {
        var data;
        if($scope.loading){
            return;
        }
        $scope.loading = true;
        $scope.errorMessage = false;
        data = {
            suborder_id: $scope.suborder.id
        };
        strukshurApiService.orderEmail.save(data, function(d) {
            if(d && d.note){
                $scope.notes.push(d.note);
            }
            $uibModalInstance.close('Email Sent');
        }, function(e) {
            $scope.errorMessage = e.message;
            $scope.loading = false;
        });
    };
    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };

})

.controller( 'ConfirmOrderProductDeleteCtrl', function ConfirmOrderProductDeleteCtrl( $scope, $uibModalInstance, strukshurApiService, message, product, suborder ) {
    $scope.loading = false;
    $scope.errorMessage = false;
    $scope.suborder = suborder;
    $scope.product = product;
    $scope.title = 'Confirm';
    $scope.message = message;
    $scope.confirm = function () {
        var data;
        if($scope.loading){
            return;
        }
        $scope.loading = true;
        $scope.errorMessage = false;
        data = {
            suborder_id: $scope.suborder.id,
            product_order_id: $scope.product.id,
            quantity: 0,
            price: product.price
        };
        strukshurApiService.productOrderDetails.save(data, function (d) {
            $uibModalInstance.close('Product Removed');
            if(d.note && d.note.id){
                $scope.notes.push(d.note);
            }
        }, function (e) {
            product.loading = false;
        });
    };
    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };
})

.controller( 'ReturnModalCtrl', function ReturnModalCtrl( $scope, $uibModalInstance, strukshurApiService, order, suborder, CartService ) {
    var i,tot;
    $scope.loading = false;
    $scope.errorMessage = false;
    $scope.selectedSuborder = suborder;
    $scope.order = order;
    $scope.returnReason = '';
    $scope.strukshurFee = CartService.getStrukshurFee();
    $scope.taxRate = CartService.getTaxes(order.zip);

    if(!$scope.taxRate){
        strukshurApiService.taxes.query({zip: $scope.order.zip}, function (d) {
            $scope.taxRate = d.tax;
            CartService.setTaxes($scope.taxRate, $scope.order.zip);
        });
    }
    tot = $scope.selectedSuborder.productOrders.length;
    for(i=0;i<tot;i++){
        $scope.selectedSuborder.productOrders[i].newQuantityReturned = 0;
    }
    $scope.returnOrder = function () {
        var data, i, tot, product, productOrders = [];
        if($scope.totalReturnAmount($scope.selectedSuborder)===0){
            $scope.errorMessage = 'No items selected';
            return;
        }
        if($scope.returnReason===''){
            $scope.errorMessage = 'Reason must be set';
            return;
        }
        if($scope.loading){
            return;
        }
        tot = $scope.selectedSuborder.productOrders.length;
        for(i=0;i<tot;i++){
            product = $scope.selectedSuborder.productOrders[i];
            if(typeof product.newQuantityReturned==='undefined'){
                product.newQuantityReturned = 0;
            }
            if(product.newQuantityReturned===0){
                continue;
            }
            productOrders.push({
                id: product.id,
                quantityReturned: product.newQuantityReturned,
                amountReturned: $scope.itemReturnAmount(product)
            });
        }
        $scope.loading = true;
        $scope.errorMessage = false;
        data = {
            suborder_id: $scope.selectedSuborder.id,
            productOrders: JSON.stringify(productOrders),
            reason: $scope.returnReason
        };
        strukshurApiService.productOrderReturn.save(data, function (d) {
            var i, tot;
            $scope.loading = false;

            $scope.selectedSuborder = d.suborder;
            if(d.notes){
                tot = d.notes.length;
                for(i=0;i<tot;i++){
                    $scope.notes.push(d.notes[i]);
                }
            }
            $uibModalInstance.close({suborder: $scope.selectedSuborder,message: 'Item returned'});

        }, function (e) {
            $scope.errorMessage = e.message;
            $scope.loading = false;
        });
    };
    $scope.totalReturnAmount = function (suborder) {
        var total, i, tot, product, itemTotal, fees, taxes;
        if (!suborder || !suborder.productOrders || suborder.productOrders.length === 0) {
            return 0;
        }
        total = 0.0;
        tot = suborder.productOrders.length;
        for (i = 0; i < tot; i++) {
            product = suborder.productOrders[i];
            if (typeof product.newQuantityReturned === 'undefined') {
                product.newQuantityReturned = 0;
            }
            if (product.newQuantityReturned === 0) {
                continue;
            }
            itemTotal = product.newQuantityReturned * parseFloat(product.price); //not include shipping
            total += itemTotal;
        }
        total = Math.round(total * 100) / 100;
        taxes = Math.round(($scope.taxRate.combinedRate ? $scope.taxRate.combinedRate : 0) * total * 100) / 100;
        fees = Math.round($scope.strukshurFee * (total + taxes) * 100) / 100;
        total = total + taxes + fees;
        return Math.round(total * 100) / 100;
    };
    $scope.itemReturnAmount = function (productOrder) {
        var itemTotal, taxes, fees;

        if (typeof productOrder.newQuantityReturned === 'undefined') {
            productOrder.newQuantityReturned = 0;
        }
        if (productOrder.newQuantityReturned === 0) {
            return 0;
        }
        itemTotal = productOrder.newQuantityReturned * parseFloat(productOrder.price); //not include shipping

        taxes = Math.round(($scope.taxRate.combinedRate ? $scope.taxRate.combinedRate : 0) * itemTotal * 100) / 100;
        fees = Math.round($scope.strukshurFee * (itemTotal + taxes) * 100) / 100;
        itemTotal = itemTotal + taxes + fees;
        return Math.round(itemTotal * 100) / 100;
    };
    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };
})
;
