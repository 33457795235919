import * as angular from 'angular';
import { shuffle as _shuffle } from 'lodash';

import strukshurConfig from '../../config.ts';

angular.module('HeaderNavDirective', [])
    .directive('headerNav', function (NotificationService, strukshurApiService, strukshurUserService, strukshurUtilService, $aside, $rootScope, $state, $uibModal, $timeout, $window) {
        return {
            restrict: 'EA',
            template: require('./header-nav.tpl.html'),
            scope: {
                state: '='
            },
            controllerAs: "dir",
            bindToController: true,
            controller: function () {
                var vm = this;

                vm.user = {};
                vm.terms = '';
                vm.projects = [];
                vm.mainMenu = null;
                vm.categories = [];
                vm.organizations = [];
                vm.userFirstName = '';
                vm.userMenu = { isOpen: false };
                vm.notificationList = null;
                vm.notificationCounter = 0;
                vm.current = $state.current;
                vm.isMissingPayment = false;
                vm.adminUrl = strukshurConfig.admin_url;
                vm.showMaxProjectsProgressBar = false;

                // Listens for user update broadcast
                $rootScope.$on('userUpdate', function (event, userDetails) {

                    // Check if the current user is empty, so it means it's the first
                    // time the user details where loaded
                    if (typeof vm.user.id === 'undefined' && typeof userDetails.id !== 'undefined') {
                        // Bootstraps notification service
                        NotificationService.bootstrap();
                    } else if (typeof userDetails.id === 'undefined' && NotificationService.isActive()) {
                        NotificationService.shutdown();
                    }

                    // empty means user just logged out
                    vm.user = userDetails;
                    $rootScope.user = vm.user;
                    vm.userFirstName = vm.user.firstName;
                    vm.isMissingPayment = strukshurUserService.isMissingPayment();
                    vm.showMaxProjectsProgressBar = (vm.user.projectsAllowed !== 999999);
                });

                // Listens for projects update broadcast
                $rootScope.$on('projectsUpdate', function (event, projects) {
                    // empty means user just logged out
                    vm.projects = projects;
                    $rootScope.userProjects = projects || [];
                });

                // Listens for organizations update broadcast
                $rootScope.$on('organizationsUpdate', function (event, organizations) {
                    vm.organizations = organizations;
                    $rootScope.userOrganizations = organizations || [];
                });

                // Updates local product categories when the root categories is updated
                $rootScope.$watch(
                    () => $rootScope.productCategories,
                    () => vm.categories = _shuffle($rootScope.productCategories),
                    true
                );

                vm.showSmHomeNav = false;
                vm.ClickSmHomeNav = () => vm.showSmHomeNav = !vm.showSmHomeNav;

                vm.gotoProjects = () => {

                    // Opens modal for the user to login, if anonymous
                    if (!vm.user.slug) {
                        return vm.openLogin();
                    }

                    $state.go('projects');
                };

                /**
                 * Opens modal to create a new project
                 */
                vm.createProjectPop = () => {
                    let modalInstance: any;

                    // Opens modal for the user to login, if anonymous
                    if (!vm.user.slug) {
                        return vm.openLogin();
                    }

                    // We first check if we're currently in a scope that already has a createProject
                    // method defined and if so we use it instead
                    let view = document.querySelector('#main-view');
                    if (view && typeof (angular.element(view).scope() as any).createProjectPop === 'function') {
                        return (angular.element(view).scope() as any).createProjectPop();
                    }

                    // if user has a basic plan then only allow 1 project to be created otherwise
                    // show popup to upgrade account
                    if (!strukshurUserService.hasAvailableProjects(vm.user.plan, vm.user.projectsOwned)) {
                        modalInstance = $uibModal.open({
                            scope: $rootScope.$new(),
                            keyboard: true,
                            controller: 'UpgradeModalCtrl',
                            template: require('../templates/upgrade-modal.tpl.html'),
                            resolve: {}
                        });
                    } else {
                        modalInstance = $uibModal.open({
                            scope: $rootScope.$new(),
                            keyboard: true,
                            controller: 'ProjectModalInstanceCtrl',
                            template: require('../templates/project-create-modal.tpl.html'),
                            resolve: {
                                allowOwnerChange: true,
                                organization: null,
                                project: function () {
                                    return {};
                                },
                                title: function () {
                                    return 'Create project';
                                },
                            }
                        });

                        modalInstance.result.then(function (newProject) {
                            if (newProject && newProject.id) {

                                // Refreshes user projects' in-memory list
                                strukshurUserService.addProject(newProject);

                                $state.go('projects-detail', { 'project_id': newProject.id, 'project_slug': strukshurUtilService.slugify(newProject.title) });
                            }
                        }, angular.noop);
                    }
                };

                /**
                 * Opens modal to create a new organization
                 */
                vm.openNewOrganizationModal = () => {
                    if (!(vm.user.userType === 'pro') || vm.isMissingPayment) { return; }

                    const modalInstance = $uibModal.open({
                        keyboard: true,
                        scope: $rootScope.$new(),
                        controller: 'OrganizationsNewOrganizationCtrl',
                        template: require('../templates/organizations.newOrganizationModal.tpl.html'),
                    });

                    modalInstance.result.then(

                        // Resolved callback
                        (data) => {
                            if (data.organization) {
                                strukshurUserService.addOrganization(data.organization);
                            }
                        },

                        // Rejected callback
                        angular.noop
                    );
                };

                /**
                 * Opens the main menu aside
                 */
                vm.openMainMenu = () => {
                    var scope = $rootScope.$new();
                    scope.user = vm.user;

                    scope.mainMenu = $aside({
                        title: 'Main Menu',
                        template: require('../templates/mainMenu.tpl.html'),
                        controller: 'MainMenuCtrl',
                        placement: 'left',
                        animation: 'am-fade-and-slide-left',
                        scope: scope
                    });
                };

                vm.searchFunc = () => {
                    $state.go('search', { terms: (vm.terms && String(vm.terms))?String(vm.terms).toLowerCase():'' });
                };

                /**
                 * Handles navbar mobile search
                 */
                vm.searchFuncMobile = () => {
                    vm.closeSearch();
                    $state.go('search', { terms: vm.terms });
                    $timeout(() => {
                        (<HTMLInputElement>document.querySelector('.nav-search-bar .search-bar input')).blur();
                    }, 50);
                };

                vm.onFocusMobileSearch = () => {
                    vm.currentSearchTerm = vm.terms;

                    // We use this flag to determine if the mobile searchbar
                    // was closed by the user
                    vm.mobileSearchClosed = false;
                };

                vm.onBlurMobileSearch = () => {
                    $timeout(() => {

                        // The check for vm.mobileSearchClosed is used here to
                        // ignore the search if the user tapped on the X button
                        // besides the searchbar
                        if (vm.terms !== '' && vm.currentSearchTerm !== vm.terms && !vm.mobileSearchClosed) {
                            vm.searchFuncMobile();
                        }
                    }, 50);
                };

                vm.openLogin = () => {
                    var modalInstance = $uibModal.open({
                        size: 'lg',
                        keyboard: true,
                        animation: true,
                        ariaLabelledBy: 'modal-title',
                        ariaDescribedBy: 'modal-body',
                        template: require('../templates/login-modal.tpl.html'),
                        controller: 'ModalLoginCtrl',
                        resolve: {}
                    });

                    modalInstance.result.then(angular.noop, angular.noop);
                };

                /**
                 * Display the current notifications list
                 */
                vm.showNotifications = () => {
                    vm.notificationList = $aside({
                        title: 'Notifications',
                        template: require('../templates/notifications.tpl.html'),
                        controller: 'NotificationsListCtrl',
                        show: true
                    });
                };

                vm.showMainSearch = () => {
                    angular.element(document.querySelector('.mobile-nav')).addClass('searchbar-active');

                    $timeout(() => {
                        (<HTMLInputElement>document.querySelector('.nav-search-bar .search-bar input')).focus();
                    }, 300);
                };

                vm.closeSearch = () => {
                    angular.element(document.querySelector('.mobile-nav')).removeClass('searchbar-active');
                    vm.mobileSearchClosed = true;
                };

                // Subscribe to notification updates
                $rootScope.$on('notificationsUpdated', (event, notifications) => {
                    var unreadCount = 0;

                    notifications.forEach((notification) => {
                        if (notification.read === false) {
                            unreadCount += 1;
                        }
                    });

                    vm.notificationCounter = unreadCount;
                });
            }
        };
    })

    .controller('NotificationsListCtrl', function NotificationsListCtrl($rootScope, $scope, $state, $timeout, NotificationService, strukshurApiService, strukshurUserService, strukshurUtilService, toastr) {
        var vm = $scope;

        // Get current notification list from the server
        vm.notifications = NotificationService.getNotifications();

        // Create timeout function to mark notifications as read
        vm.markAsReadPromise = $timeout(function () {
            NotificationService.markNotificationsAsRead();
        }, 10000);

        // Hides the notification list aside if necessary
        $rootScope.$on('toastTapped', function(event, toast) {
            $scope.$parent.$hide();
        });

        // Cancel mark as read timeout function when the aside is closed
        vm.$on('$destroy', function () {
            $timeout.cancel(vm.markAsReadPromise);
        });

        /**
         * Closes the notification list
         */
        vm.close = function () {
            $scope.$parent.$hide();
        };

        /**
         * Expands the received notification to show it's full content
         *
         * @param  {Object}  notification  The notification to expand
         */
        vm.showMore = function (notification) {
            notification.showMore = true;
        };

        /**
         * Opens the project associated with the received notification
         *
         * @param  {Object}  notification  The notification
         */
        vm.goToProject = function (notification) {
            NotificationService.resolveAction(notification);
            vm.close();
        };

        /**
         * Opens the project estimates associated with the received notification
         *
         * @param  {Object}  notification  The notification
         */
        vm.goToProjectEstimates = function (notification) {
            NotificationService.resolveAction(notification);
            vm.close();
        };

        /**
         * Opens the project file associated with the received notification
         *
         * @param  {Object}  notification  The notification
         */
        vm.goToProjectFiles = function (notification) {
            NotificationService.resolveAction(notification);
            vm.close();
        };

        /**
         * Opens the project finish associated with the received notification
         *
         * @param  {Object}  notification  The notification
         */
        vm.goToProjectFinishes = function (notification) {
            NotificationService.resolveAction(notification);
            vm.close();
        };

        /**
         * Opens the project inspiration board associated with the received notification
         *
         * @param  {Object}  notification  The notification
         */
        vm.goToProjectInspirationBoard = function (notification) {
            NotificationService.resolveAction(notification);
            vm.close();
        };

        /**
         * Opens the project progress items associated with the received notification
         *
         * @param  {Object}  notification  The notification
         */
        vm.goToProjectProgress = function (notification) {
            NotificationService.resolveAction(notification);
            vm.close();
        };

        /**
         * Opens the project purchase lists associated with the received notification
         *
         * @param  {Object}  notification  The notification
         */
        vm.goToProjectPurchaseLists = function (notification) {
            NotificationService.resolveAction(notification);
            vm.close();
        };

        /**
         * Opens the project tasks associated with the received notification
         *
         * @param  {Object}  notification  The notification
         */
        vm.goToProjectTasks = function (notification) {
            NotificationService.resolveAction(notification);
            vm.close();
        };

        /**
         * Opens the project bids associated with the received notification
         *
         * @param  {Object}  notification  The notification
         */
        vm.goToProjectBids = function (notification) {
            NotificationService.resolveAction(notification);
            vm.close();
        };

        /**
         * Accepts the received invitation to the organization
         *
         * @param  {object}  notification  The notification
         */
        vm.acceptOrganizationInvite = function (notification) {
            if (notification.processing) { return; }

            var data = {
                code: notification.details.info.invitee.code,
                member_id: notification.details.info.invitee.id,
                user_slug: vm.$parent.user.slug,
            };

            notification.processing = true;
            strukshurApiService.organizationMember.acceptInvite(data).$promise
                .then(function () {
                    var inviterName = notification.details.info.inviter.fullName,
                        organizationName = notification.details.info.organization.name;

                    // Hides the notification actions for the user since it has already been accepted
                    notification.hideActions = true;

                    // Updates notification content to reflect its new state
                    notification.details.content = 'You have accepted the invitation from <b>'+inviterName+'</b> to join the organization <b>'+organizationName+'</b>';

                    toastr.success('The invitation was accepted successfully.', 'Success!');
                    $state.go('organization', { id: notification.details.info.organization.id, slug: notification.details.info.organization.slug });

                    // Closes notifications list
                    vm.close();

                    // Refreshes the local organizations list after the invite was
                    // accepted in order to display the new option to the user
                    strukshurApiService.organizations.list().$promise
                        .then(function (res) {
                            strukshurUserService.setOrganizations(res.organizations);
                        })
                        .catch(angular.noop)
                    ;
                })
                .catch(function (res) {
                    if (res.status === 403) {
                        toastr.error('You don\'t have the necessary permission to accept the invite.', 'Error');
                    } else if (res.status === 404) {
                        toastr.error('The invite for the organization has either expired or been deleted.', 'Error');
                    } else {
                        toastr.error('There was an error trying to accept the invite.', 'Error');
                    }
                })
                .finally(function () {
                    notification.processing = false;
                })
            ;
        };

        /**
         * Rejects the received invitation to the organization
         *
         * @param  {object}  notification  The notification
         */
        vm.rejectOrganizationInvite = function (notification) {
            if (notification.processing) { return; }

            var data = {
                code: notification.details.info.invitee.code,
                member_id: notification.details.info.invitee.id,
            };

            notification.processing = true;
            strukshurApiService.organizationMember.rejectInvite(data).$promise
                .then(function () {
                    var inviterName = notification.details.info.inviter.fullName,
                        organizationName = notification.details.info.organization.name;

                    toastr.success('The invitation was rejected successfully.', 'Success!');

                    // Hides the notification actions for the user since it has already been rejected
                    notification.hideActions = true;

                    // Updates notification content to reflect its new state
                    notification.details.content = 'You have rejected the invitation from <b>'+inviterName+'</b> to join the organization <b>'+organizationName+'</b>';
                })
                .catch(function () {
                    toastr.error('There was an error trying to reject the invite. Please try again later.', 'Error');
                })
                .finally(function () {
                    notification.processing = false;
                })
            ;
        };

        /**
         * Returns wether the notification is for a supported image file
         *
         * @param  {Object}  notification  The notification
         */
        vm.isSupportedImageNotification = function (notification) {
            return strukshurUtilService.supportedImageFormats.includes(notification.details.info.file.mimetype);
        };
    })

    .controller('MainMenuCtrl', function MainMenuCtrl ($scope, $state, $uibModal) {
        var vm = $scope;

        vm.accountSettingsOpen = false;

        /**
         * Navigates to the received page
         *
         * @param  {string}  page  The page route
         */
        vm.goToAuthenticatedPage = function (page) {
            vm.mainMenu.hide();

            // Opens modal for the user to login, if anonymous
            if (!vm.user.slug) {
                return vm.openLogin();
            }

            if (page === 'my-home') {
                $state.go('setup-interior', { userSlug: vm.user.slug });
            } else if (page === 'pros-profile') {
                $state.go('pros-profile', { userSlug: vm.user.slug });
            } else if (page === 'organizations') {
                $state.go('account-settings', { active_tab: 'organizations' });
            } else {
                $state.go(page);
            }
        };

        /**
         * Navigates to the received page
         *
         * @param  {string}  page  The page route
         */
        vm.goToPage = function (page) {
            vm.mainMenu.hide();

            if (page === 'my-home') {
                $state.go('setup-interior', { userSlug: vm.user.slug });
            } else if (page === 'pros-profile') {
                $state.go('pros-profile', { userSlug: vm.user.slug });
            } else if (page === 'organizations') {
                $state.go('account-settings', { active_tab: 'organizations' });
            } else {
                $state.go(page);
            }
        };

        /**
         * Opens the account settings modal
         */
        vm.openAccountSettings = function () {
            if (vm.accountSettingsOpen) { return true; }

            vm.mainMenu.hide();

            // Opens modal for the user to login, if anonymous
            if (!vm.user.slug) {
                return vm.openLogin();
            }

            vm.accountSettingsOpen = true;

            $state.go('account-settings');
        };

        vm.openLogin = function (size) {
            var modalInstance = $uibModal.open({
                size: 'lg',
                keyboard: true,
                animation: true,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                template: require('../templates/login-modal.tpl.html'),
                controller: 'ModalLoginCtrl',
                resolve: {}
            });

            modalInstance.result.then(angular.noop, angular.noop);
        };
    })
;
