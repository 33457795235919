import {
    find as _find,
    pull as _pull,
} from 'lodash';
import * as angular from 'angular';

angular.module('strukshurApp.projects.blueprints', [])

.config(function config($stateProvider, $uibModalProvider) {
    $uibModalProvider.options = { animation: true, backdrop: 'static', keyboard: false, ariaLabelledBy: 'modal-title', ariaDescribedBy: 'modal-body' };

    $stateProvider
        .state('projects-detail.blueprints', {
            url: '/blueprints',
            views: {
                '': {
                    controller: 'ProjectDetailBlueprintsCtrl',
                    template: require('./project.blueprints.tpl.html'),
                },
            },
            resolve: {},
            data: { pageTitle: 'Project / Blueprints', class: 'projects projects-detail-blueprints' }
        })

        .state('projects-detail.blueprints.list-blueprints', {
            url: '/list',
            views: {
                '': {
                    controller: 'ProjectDetailBlueprintsListCtrl',
                    template: require('./project.blueprints.list.tpl.html'),
                },
            },
            resolve: {},
            data: { pageTitle: 'Project / Blueprints', class: 'projects projects-detail-blueprints' }
        })

        .state('projects-detail.blueprints.view-blueprint', {
            url: '/:room_id/view',
            views: {
                '': {
                    controller: 'ProjectDetailBlueprintsViewCtrl',
                    template: require('./project.blueprints.view.tpl.html'),
                },
            },
            resolve: {
                room: ($stateParams, strukshurApiService) => {
                    return strukshurApiService.projectBlueprint.get({ room_id: $stateParams.room_id }).$promise;
                },
            },
            data: { pageTitle: 'Project / Blueprint', class: 'projects projects-detail-blueprints' }
        })
    ;
})

.controller('ProjectDetailBlueprintsCtrl', function ProjectDetailBlueprintsCtrl($scope, $state) {
    var vm = $scope;

    // Check if the user can access the current page or not
    if (!vm.$parent.checkPermission('project_room_blueprint_view')) {
        return $state.go('projects-detail.access-denied', { section: 'Blueprints' });
    }

    /**
     * Controller init logic
     */
    vm.init = () => {

        // Updates current route info on the project menu scope
        vm.$parent.currentRoute = $state.current.name;

        $state.go('projects-detail.blueprints.list-blueprints');
    };

    /**
     * Checks if the current user has the given permission on the project
     *
     * @param  {string}  permission  The permission key
     *
     * @return {boolean}
     */
    vm.checkPermission = (permission) => vm.$parent.checkPermission(permission);

    vm.init();
})

.controller('ProjectDetailBlueprintsListCtrl', function ProjectDetailBlueprintsListCtrl($scope, $state, $uibModal, strukshurApiService, strukshurUserService, toastr) {
    var vm = $scope;

    // Check if the user can access the current page or not
    if (!vm.$parent.checkPermission('project_room_blueprint_view')) {
        return $state.go('projects-detail.access-denied', { section: 'Blueprints' });
    }

    vm.rooms = [];
    vm.loading = false;
    vm.searchTerm = '';
    vm.errorMessage = '';
    vm.currentUser = strukshurUserService.getUserObj();

    /**
     * Controller init logic
     */
    vm.init = () => {

        // Load all project rooms with blueprint availability info
        vm.loading = true;
        strukshurApiService.projectRooms.listForBlueprints({ project_id: vm.$parent.project.id }).$promise
            .then((res) => {
                const permissions = {
                    canEdit: vm.$parent.checkPermission('project_room_blueprint_edit'),
                    canDelete: vm.$parent.checkPermission('project_room_blueprint_delete'),
                    canView: vm.$parent.checkPermission('project_room_blueprint_view'),
                };

                res.rooms.forEach((room) => {
                    room.permissions = permissions;
                    vm.rooms.push(room);
                });
            })
            .catch((res) => {
                let errorMessage = (res.data.message ? res.data.message : null);
                if (res.status === 403) {
                    vm.errorMessage = 'You don\'t have the necessary permission to view the blueprints.';
                } else if (errorMessage) {
                    vm.errorMessage = errorMessage;
                } else {
                    vm.errorMessage = 'There was an error trying to load the blueprints.';
                }
            })
            .finally(() => vm.loading = false)
        ;
    };

    /**
     * Checks if the current user has the given permission on the project
     *
     * @param  {string}  permission  The permission key
     *
     * @return {boolean}
     */
    vm.checkPermission = (permission) => vm.$parent.checkPermission(permission);

    /**
     * Opens the modal for adding a new project room
     */
     vm.newRoom = () => {
        if (!vm.$parent.checkPermission('project_room_add')) { return; }

        const modalInstance = $uibModal.open({
            scope: vm,
            keyboard: true,
            controller: 'ProjectDetailNewRoomCtrl',
            template: require('../../../common/templates/projects.newRoomModal.tpl.html'),
            resolve: {
                project: vm.$parent.project
            }
        });

        modalInstance.result.then(

            // Resolved callback
            (data) => {
                if (data.room) {
                    data.room.finishes = [];
                    vm.rooms.push(data.room);
                    vm.updatePageItems();
                }
            },

            // Rejected callback
            angular.noop
        );
    };

    /**
     * Clears the blueprint saved to the given project room
     *
     * @param  {object}  room  The room to clear the blueprint from
     */
    vm.clearBlueprint = (room, event) => {
        event.preventDefault();
        event.stopPropagation();

        if (!vm.$parent.checkPermission('project_room_blueprint_delete')) { return; }

        const $childScope = vm.$new();
        $childScope.room = room;
        $childScope.title = 'Clear blueprint';
        $childScope.message = 'Are you sure you want to clear this room\'s blueprint?';

        const modalInstance = $uibModal.open({
            keyboard: true,
            scope: $childScope,
            controller: 'ProjectDetailBlueprintClearCtrl',
            template: require('../../../common/templates/base.confirm-modal.tpl.html')
        });

        modalInstance.result.then(

            // Resolved callback
            () => {
                room.hasBlueprint = false;
                toastr.success('The blueprint was cleared successfully.', 'Success!');
            },

            // Rejected callback
            angular.noop
        );
    };

    vm.init();
})

.controller('ProjectDetailBlueprintsViewCtrl', function ProjectDetailBlueprintsViewCtrl($scope, $state, room) {
    var vm = $scope;

    // Check if the user can access the current page or not
    if (!vm.$parent.checkPermission('project_room_blueprint_view')) {
        return $state.go('projects-detail.access-denied', { section: 'Blueprints' });
    }

    /**
     * Controller init logic
     */
    vm.init = () => {
        const permissions = {
            canEdit: vm.$parent.checkPermission('project_room_blueprint_edit'),
            canDelete: vm.$parent.checkPermission('project_room_blueprint_delete'),
            canView: vm.$parent.checkPermission('project_room_blueprint_view'),
        };

        room.permissions = permissions;

        vm.room = room;
    };

    /**
     * Checks if the current user has the given permission on the project
     *
     * @param  {string}  permission  The permission key
     *
     * @return {boolean}
     */
    vm.checkPermission = (permission) => vm.$parent.checkPermission(permission);

    vm.init();
})

.controller('ProjectDetailBlueprintClearCtrl', function ProjectDetailBlueprintClearCtrl ($scope, $uibModalInstance, strukshurApiService) {
    var vm = $scope;

    vm.loading = false;
    vm.errorMessage = '';

    vm.onConfirmChosen = () => {

        // Removes the blueprint from the room
        vm.loading = true;
        strukshurApiService.projectBlueprint.remove({ room_id: vm.room.id }).$promise
            .then($uibModalInstance.close)
            .catch((res) => {
                if (res.status === 403) {
                    vm.errorMessage = 'You don\'t have the necessary permission to remove the blueprint.';
                } else {
                    vm.errorMessage = 'There was an error trying to remove the blueprint from the room.';
                }
            })
            .finally(() => vm.loading = false)
        ;
    };
})

;
