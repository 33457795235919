import * as angular from 'angular';

angular.module('userRouterResolveService', ['ngResource'])

.factory('userRouterResolveService', ['$state', 'strukshurUserService', 'InitService', function ($state, strukshurUserService, InitService) {
    return {
        userMustBeLoggedin: function () {
            return InitService.promise.then(function (res) {
                if (strukshurUserService.isLoggedIn() === false) {
                    $state.go('home');

                    return false;
                }

                return strukshurUserService.getUserObj();
            });
        },

        userMustBeProReg: function () {

            // special case use is pro but has not paid
            return strukshurUserService.getUser().then(function (res) {
                if (strukshurUserService.isProReg() === true) {
                    return strukshurUserService.getUserObj();
                }

                if (res) {
                    $state.go('setup-interior', { userSlug: res.slug });

                    return false;
                }

                $state.go('home');

                return false;
            });
        },

        userMustBeReg: function () {

            // special case use is pro but has not paid
            return strukshurUserService.getUser().then(function (res) {
                if (strukshurUserService.isHomeReg() === true) {
                    return strukshurUserService.getUserObj();
                }

                $state.go('home');

                return false;
            });
        },

        userMustBePro: function () {
            return InitService.promise.then(function (res) {
                if (strukshurUserService.isPro() === true) {
                    return strukshurUserService.getUserObj();
                } else {
                    $state.go('home');

                    return false;
                }
            });
        },

        getUser: function () {
            return strukshurUserService.getUser().then(function (res) {
                if (strukshurUserService.isLoggedIn() === true) {
                    return strukshurUserService.getUserObj();
                }

                return {};
            });
        },
    };
}]);
