const admin_url = process.env.adminUrl;
const api_path = process.env.apiPath;
const domain = process.env.domain;
const environment = process.env.name;
const max_upload_size = parseInt(process.env.maxUploadSize, 10);
const pinterest_api_id = process.env.pinterestAppId;
const pinterest_redirect_uri = process.env.pinterest_redirect_uri;
const plaid_key = process.env.plaidKey;
const stripe_client_id = process.env.stripeClientId;
const stripe_key = process.env.stripeKey;

export default {
    admin_url: admin_url,
    api_url: domain + api_path,
    domain: domain,
    env: environment,
    max_upload_size: max_upload_size,
    pinterest_api_id: pinterest_api_id,
    pinterest_redirect_uri: pinterest_redirect_uri,
    plaid_key: plaid_key,
    stripe_client_id: stripe_client_id,
    stripe_key: stripe_key,
};
