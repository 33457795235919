import * as angular from 'angular';
import strukshurConfig from '../../config.ts';
declare var fbq;

angular.module('strukshurApp.prosRegister', ['ui.router', 'ui.bootstrap'])

    .config(function config($stateProvider) {
        $stateProvider
            .state('pro-register-complete', {
                url: '/pro/register/complete/:plan',
                views: {
                    main: {
                        controller: 'ProRegisterCompleteCtrl',
                        template: require('./pros.register.complete.tpl.html')
                    }
                },
                data: { pageTitle: 'Pro Account Registration', class: 'register-confirm' }
            })

            .state('pro-register-payment', {
                url: '/pro/register/payment',
                params: {
                    emailConfirmed: false
                },
                views: {
                    main: {
                        controller: 'ProRegisterPaymentCtrl',
                        template: require('./pros.register.payment.tpl.html')
                    }
                },
                resolve: {
                    userResolve: (userRouterResolveService) => userRouterResolveService.userMustBeProReg(),
                },
                data: { pageTitle: 'Pro Account Registration - Payment', class: 'pro-register' }
            })

            .state('pro-register', {
                url: '/pro/register/:name/:plan_id',
                views: {
                    main: {
                        controller: 'ProRegisterCtrl',
                        template: require('./pros.register.tpl.html')
                    }
                },
                data: { pageTitle: 'Pro Account Registration', class: 'pro-register template-page-simple' }
            })
        ;
    })

    .controller('ProRegisterCtrl', function ProRegisterCtrl($scope, $state, $stateParams, $timeout, strukshurAuthUserService, ContractorRoleService, smoothScroll) {
        var vm = $scope;

        vm.form = {};
        vm.formData = {};
        vm.loading = false;
        vm.availableRoles = [];
        vm.categoryError = false;
        vm.plan = $stateParams.name;
        vm.plan_id = $stateParams.plan_id;
        vm.authloggedin = strukshurAuthUserService.getAuthLoggedin();

        vm.init = () => {
            smoothScroll(document.getElementById('top_page_register'), { duration: 0, offset: 87 });

            ContractorRoleService.getRoles().then((roles) => {
                roles.forEach((role) => vm.availableRoles.push(role));
            });

            $timeout(() => {
                if (typeof fbq !== 'undefined') {
                    fbq('track', 'ViewContent', { custom_param: 'pro_registration' });
                }
            }, 500);
        };

        vm.IntentLogin = () => strukshurAuthUserService.isUserConnectedFacebook('pro');

        vm.IntentLogout = () => {
            strukshurAuthUserService.removeUser();
            vm.authloggedin = false;
        };

        vm.completeUrl = (model) => {
            if (typeof vm.formData[model] === 'string' && vm.formData[model] !== '') {
                if (vm.formData[model].indexOf('http') === -1) {
                    vm.formData[model] = 'http://' + vm.formData[model];
                }
            }
        };

        vm.formRegister = (myform) => {
            if (vm.loading) { return; }

            vm.categoryError = false;
            if (!vm.formData || !vm.formData.category || !vm.formData.category.name) {
                vm.categoryError = true;
                return;
            }

            vm.loading = true;
            myform.plan_id = vm.plan_id;
            myform.category = vm.formData.category.name;

            var promise = strukshurAuthUserService.registerPro(myform);
            promise.then(() => {
                    vm.loading = false;
                    window.location.href = $state.get('pro-register-complete').url.replace(':plan', vm.plan_id);
                },
                () => vm.loading = false,
            );
        };

        vm.init();
    })

    .controller('ProRegisterCompleteCtrl', function ProRegisterCompleteCtrl ($scope, $stateParams, $timeout, PricingService) {
        var vm = $scope;

        vm.plan = PricingService.getPlan($stateParams.plan);
        vm.plan_name = PricingService.getName($stateParams.plan);

        vm.init = () => {
            if (typeof gtag !== 'undefined' && vm.plan.gtagHash) {
                $timeout(gtag('event', 'conversion', { 'send_to': vm.plan.gtagHash }), 250);
            }

            $timeout(() => {
                if (typeof fbq !== 'undefined') {
                    fbq('track', 'SubmitApplication');
                }
            }, 500);
        };


        vm.init();
    })

    .controller('ProRegisterPaymentCtrl', function ProRegisterPaymentCtrl ($scope, $stateParams, $window, PricingService, strukshurApiService, strukshurAuthUserService, strukshurFlashService, strukshurUserService, userResolve) {
        var vm = $scope, stripe = $window.Stripe(strukshurConfig.stripe_key);

        vm.form = {};
        vm.discount = 0;
        vm.loading = false;
        vm.paymentError = false;
        vm.paymentErrorMessage = '';
        vm.current_user = userResolve;
        vm.plan_tial = PricingService.geTrial(vm.current_user.plan);
        vm.totalPrice = PricingService.getTotalPrice(vm.current_user.plan);
        vm.plan_frequency = PricingService.getFrequency(vm.current_user.plan);

        // flag for showing confirmation message
        vm.emailConfirmed = $stateParams.emailConfirmed;

        var elements = stripe.elements();
        var card = elements.create('card', {
            style: {
                base: {
                    iconColor: '#3e704b',
                    color: '#31325F',
                    lineHeight: '40px',
                    fontWeight: 300,
                    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                    fontSize: '15px',

                    '::placeholder': {
                        color: '#CFD7E0'
                    }
                }
            }
        });

        card.mount('#card-element');

        vm.cc = { name: userResolve.fullName, phone: userResolve.phoneNumber, coupon: '' };
        vm.code_sent = [];
        vm.coupon_terms = false;
        vm.coupon_valid = false;
        vm.coupon_invalid = false;
        vm.loading_coupon = false;

        vm.reviewCode = function (code) {
            var i, tot;
            tot = vm.code_sent.length;
            for (i = 0; i < tot; i++) {
                if (vm.code_sent[i].code === code) {
                    return vm.code_sent[i];
                }
            }

            return false;
        };

        vm.setViewCode = function (d) {
            vm.loading_coupon = false;
            if (d.valid) {
                vm.coupon_terms = (d.amount_off ? d.amount_off : d.percent_off + '%') + ' ' + d.duration + ' ' + (d.duration === 'repeating' ? d.duration_in_months + ' months' : '');
                vm.discount = Math.round((d.amount_off ? parseFloat(d.amount_off) : parseFloat(d.percent_off) * vm.totalPrice / 100) * 100) / 100;
                vm.coupon_valid = true;
                vm.coupon_invalid = false;
            } else {
                vm.coupon_valid = false;
                vm.coupon_invalid = true;
                if (d.tries && d.tries > 5) {
                    vm.paymentError = true;
                    vm.paymentErrorMessage = d.message;
                }
            }
        };

        vm.validate_coupon = function () {
            if (vm.loading_coupon) {
                return;
            }

            if (!vm.cc.coupon) {
                vm.coupon_terms = false;
                vm.coupon_valid = false;
                vm.coupon_invalid = false;

                return;
            }

            vm.coupon_terms = false;
            vm.paymentError = false;
            vm.paymentErrorMessage = '';

            var reviewResult = vm.reviewCode(vm.cc.coupon);
            if (reviewResult && reviewResult.code) {
                vm.setViewCode(reviewResult);
                return;
            }

            vm.loading_coupon = true;
            strukshurApiService.couponValidate.query({ coupon: vm.cc.coupon }).$promise
                .then((res: any) => {

                    // Check ifthe coupon code is valid or not
                    if (typeof res.valid !== 'undefined' && res.valid === false && res.message) {
                        vm.paymentError = true;
                        vm.paymentErrorMessage = res.message;
                    }

                    vm.code_sent.push(res);
                    vm.setViewCode(res);
                })
                .catch((err: any) => {
                    strukshurFlashService.handleApiResponseErrorMessage({
                        response: err,
                        container: 'payment-section-flash',
                        defautMessage: 'There was an error while validating the coupon.',
                        accessDeniedMessage: 'You don\'t have the necessary permission to validate the coupon.',
                    });
                })
                .finally(() => vm.loading_coupon = false)
            ;
        };

        function setOutcome (result) {
            var errorElement = document.querySelector('.error');

            errorElement.classList.remove('visible');

            if (result.token) {

                // Use the token to create a charge or a customer
                //console.log('token is');
                //console.log(result.token.id);
                //successElement.querySelector('.token').textContent = result.token.id;
                //successElement.classList.add('visible');
                vm.loading = true;

                var promiseSubscribe = strukshurAuthUserService.subscribePro(result.token.id, vm.cc.coupon);
                promiseSubscribe.then(function () {
                    //customerId = res.customerId;
                    // set user as valid pro user
                    strukshurUserService.setProConfirmed();
                    strukshurUserService.getUser().then(function (d) {
                        strukshurAuthUserService.userRedirect(d);
                    });

                    vm.loading = false;
                }, function (e) {
                    console.log('Error');
                    console.log(e);
                    vm.loading = false;
                    vm.paymentError = true;
                    vm.paymentErrorMessage = e;
                });
            } else if (result.error) {
                vm.loading = false;
                errorElement.textContent = result.error.message;
                errorElement.classList.add('visible');
                vm.$apply();
            }
        }

        card.on('change', function (event) {
            setOutcome(event);
        });

        vm.formPayment = function (myform) {
            var $submit = document.getElementById('submit');

            $submit.classList.add('disabled');
            $submit.setAttribute('disabled','disabled');
            vm.loading = true;

            var extraDetails = {
                name: myform.ccName.$viewValue,
                phone: myform.ccPhone.$viewValue
            };
            stripe.createToken(card, extraDetails).then(setOutcome);
        };
    })
;
