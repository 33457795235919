import * as angular from 'angular';
import { shuffle as _shuffle, sample as _sample } from 'lodash';
declare var fbq;

/**
 * Each section of the site has its own module. It probably also has
 * submodules, though this boilerplate is too simple to demonstrate it. Within
 * `src/app/home`, however, could exist several additional folders representing
 * additional modules that would then be listed as dependencies of this one.
 * For example, a `note` section could have the submodules `note.create`,
 * `note.delete`, `note.edit`, etc.
 *
 * Regardless, so long as dependencies are managed correctly, the build process
 * will automatically take take of the rest.
 *
 * The dependencies block here is also where component dependencies should be
 * specified, as shown below.
 */
angular.module('strukshurApp.home', ['ui.router'])

.config(function config($stateProvider) {
    $stateProvider
        .state('home', {
            url: '/home',
            views: {
                'main': {
                    controller: 'HomeCtrl',
                    template: require('./home.tpl.html')
                }
            },
            resolve: {
                categories: ($rootScope) => {
                    return $rootScope.productCategoriesResolver.promise;
                },
            },
            data: { pageTitle: 'Home', class: 'home landing-page', headerLogoFull: true, fluidContainer: true }
        })

        .state('terms', {
            url: '/user_agreement',
            views: {
                'main': {
                    controller: 'PrivacyPolicyCtrl',
                    template: require('./privacy_policy.tpl.html')
                }
            },
            data: { pageTitle: 'User Agreement', class: 'home', headerLogoFull: true }
        })

        .state('pricing-pro', {
            url: '/pro/pricing?project_id&member_id&code',
            views: {
                'main': {
                    controller: 'PricingCtrl',
                    template: require('./pricing.tpl.html')
                }
            },
            data: { pageTitle: 'Pricing', class: 'home', headerLogoFull: true },
            resolve: {
                plans: () => 'pro',
            }
        })

        .state('pricing', {
            url: '/home/pricing?project_id&member_id&code',
            views: {
                'main': {
                    controller: 'PricingCtrl',
                    template: require('./pricing.tpl.html')
                }
            },
            data: { pageTitle: 'Pricing', class: 'home', headerLogoFull: true },
            resolve: {
                plans: () => 'home',
            }
        })
    ;
})

    .controller('HomeCtrl', function HomeController($scope, $state, $uibModal, $window, categories, smoothScroll, strukshurApiService, strukshurPinterestService, strukshurUserService) {
        var vm = $scope;

        vm.user = {};
        vm.displayPage = false;
        vm.categories = categories;
        vm.instagramImages = [];
        vm.popularProducts = [];
        vm.recentGalleries = [];
        vm.animationsEnabled = true;
        vm.popularPinterestPins = [];
        vm.mainCarousel = {
            active: 1,
            noWrap: false,
            interval: 8000,
        };
        vm.heroBackgroundImage = _sample([
            'assets/images/landing-page/kitchen-background.jpg',
            'assets/images/landing-page/linda-background.jpg',
            'assets/images/landing-page/sonoma-background-02.jpg',
            'assets/images/landing-page/sonoma-background-03.jpg',
        ]);
        vm.featuredCategories = _shuffle(vm.categories.filter((cat) => (cat.img && cat.featured)).slice(0, 10));

        vm.init = () => {

            // Loads latest Strukshur pins
            strukshurPinterestService.public.getStrukshurPins().$promise
                .then(res => {
                    let randomChunk = _shuffle(res.data).slice(0, 4)
                    randomChunk.forEach(pin => {
                        vm.popularPinterestPins.push({
                            id: pin.id,
                            img: pin.image_large_url,
                            title: pin.title || 'Imported from Pinterest',
                            note: pin.description,
                            height: pin.image_large_size_pixels.height,
                            width: pin.image_large_size_pixels.width,
                            source_id: pin.id,
                            source_type: 'pinterest',
                            siteName: pin.domain,
                            pinterest_id: pin.id,
                            originalLink: pin.link,
                        });
                    });
                })
            ;

            strukshurApiService.products.listPopular({}).$promise
                .then(res => { vm.popularProducts = _shuffle(res.products).slice(0, 6) })
            ;

            strukshurApiService.proGalleries.mostRecent({ count: 10 }).$promise
                .then(res => { vm.recentGalleries = _shuffle(res.galleries).slice(0, 4) })
            ;

            strukshurUserService.getUser().then(user => vm.user = user);

            vm.instagramImages = [
                { thumb: 'assets/images/landing-page/instapost-01.jpg' },
                { thumb: 'assets/images/landing-page/instapost-02.jpg' },
                { thumb: 'assets/images/landing-page/instapost-03.jpg' },
                { thumb: 'assets/images/landing-page/instapost-04.jpg' },
                { thumb: 'assets/images/landing-page/instapost-05.jpg' },
                { thumb: 'assets/images/landing-page/instapost-06.jpg' },
                { thumb: 'assets/images/landing-page/instapost-07.jpg' },
                { thumb: 'assets/images/landing-page/instapost-08.jpg' },
                { thumb: 'assets/images/landing-page/instapost-09.jpg' },
                { thumb: 'assets/images/landing-page/instapost-10.jpg' },
            ];

            // Sets up instagram feed lib to load Strukshur's images
            // $timeout(() => {
            //     new InstagramFeed({
            //         'username': 'strukshur',
            //         'callback': (data) => {
            //             if (data && data.edge_owner_to_timeline_media) {
            //                 data.edge_owner_to_timeline_media.edges.forEach(entry => {

            //                     // Limits the number of feed items to display
            //                     if (vm.instagramImages.length === 10) { return; }

            //                     vm.instagramImages.push({
            //                         id: entry.node.id,
            //                         src: entry.node.display_url,
            //                         thumb: entry.node.thumbnail_src,
            //                         location: entry.node.location,
            //                         timestamp: entry.node.taken_at_timestamp,
            //                     });
            //                 });
            //             }
            //         },
            //     });
            // }, 100);
        };

        /**
         * Displays the login modal
         */
        vm.openLogin = () => {
            const modalInstance = $uibModal.open({
                size: 'lg',
                scope: vm,
                keyboard: true,
                animation: vm.animationsEnabled,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                template: require('../../common/templates/login-modal.tpl.html'),
                controller: 'ModalLoginCtrl',
                resolve: {
                    items: () => vm.items,
                }
            });

            modalInstance.result.then(angular.noop, angular.noop);
        };

        /**
         * Opens modal to create a new project
         */
        vm.createProjectPop = () => {

            // Opens modal for the user to login, if anonymous
            if (!vm.user.slug) {
                return vm.openLogin();
            }

            $state.go('projects');
        };

        vm.goToIdeas = () => {
            $state.go('ideas');
        };

        /**
         * Navigates to the correct product page based on its type
         *
         * @param  {object}  product   The product
         */
         vm.goToProduct = (product) => {
            switch (product.type) {
                case 'amazon':
                    $window.open(product.link, '_blank');
                break;

                // case 'vendor':
                //     $state.go('store-vendor', { origin: 'category', parent_id: product.productCats[0].id, id: product.id, slug: product.slug });
                // break;

                case 'product':
                default:
                    $state.go('store-product', { origin: 'category', parent_id: product.productCats[0].id, id: product.id, slug: product.slug });
                break;
            }
        };

        /**
         * Goes to the pro page
         */
        vm.goToProPage = () => {
            smoothScroll(document.querySelector('.navbar-top'));
            $state.go('pro');
        };

        /**
         * Handles saving the item to the user favorites
         *
         * @param  {object}  item  The item
         * @param  {string}  type  The item type
         */
        vm.saveFavorite = (item, type, event) => {
            event.preventDefault();
            event.stopPropagation();

            // Opens modal for the user to login, if anonymous
            if (!vm.user.slug) {
                return vm.openLogin();
            }

            // We need to update the received item depending on the given type
            if (type === 'galleryImage') {
                item.img = item.cover;
                item.note = item.description;
                item.originalLink = item.link;
            }

            const modalInstance = $uibModal.open({
                scope: vm,
                keyboard: true,
                animation: vm.animationsEnabled,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                template: require('../../common/templates/save-favorite-modal.tpl.html'),
                controller: 'SaveFavoriteModalCtrl',
                resolve: {
                    item: () => item,
                    type: () => type,
                    user: () => vm.user,
                }
            });

            modalInstance.result.then(angular.noop, angular.noop);
        };

        vm.init();
    })

    .controller('PrivacyPolicyCtrl', function PrivacyPolicyCtrl ($scope) {
        $scope.animationsEnabled = true;
    })

    .controller('PricingCtrl', function PricingCtrl ($scope, $state, $stateParams, $timeout, $uibModal, plans, PricingService, smoothScroll, strukshurApiService, toastr) {
        var vm = $scope;

        vm.code = null;
        vm.email = null;
        vm.plans = plans; //pro or home
        vm.memberId = null;
        vm.thread_id = null;
        vm.project_id = null;
        vm.organization = null;
        vm.frequency = 'annual';
        vm.fromInvitation = false;
        vm.displayProBasic = false;
        vm.animationsEnabled = true;
        vm.fromProjectInvitation = false;
        vm.fromOrganizationInvitation = false;

        vm.faqs = [{
            title: 'Can I set permissions for my team members?',
            desc: 'Yes, we currently have over 100 permission sets to allow you to choose what your team members can see or interact with.'
        },{
            title: 'How can I share my project with others?',
            desc: 'To add a user to your project, simply provide their email address and we’ll send them a sharing invitation.\n' +
            'Your team members will be able to view your projects for free.'
        },{
            title: 'Can I use Strukshur for free?',
            desc: 'Yes, you can begin using Strukshur for free. Once you\'ve setup a project you can share it with your team.\n' +
            'Go Pro or Premium to expand your options, storage and Projects.'
        },{
            title: 'How many projects can I create?',
            desc: 'You can create One project for free. To create more projects upgrade to our tier 1, tier 2, Pro or Premium packages to get access to as many projects as you need.'
        },{
            title: 'What is storage space used for?',
            desc: 'When you create a project, everything is saved to your account. This includes all of your photos, pins, notes, blueprints, and bids.\n' +
            'With all of your data stored in one location, planning for your next project and sharing it with others is a breeze.'
        }];

        /**
         * Controller init logic
         */
        vm.init = () => {
            vm.handleInvitationData();

            vm.loadPricingInfo();

            smoothScroll(document.getElementById('top_page_pricing'), { duration: 0, offset: 87 });

            $timeout(() => {
                if (typeof fbq !== 'undefined' && plans === 'pro') {
                    fbq('track', 'ViewContent', { custom_param: 'pro_pricing' });
                }
            }, 500);
        };

        /**
         * Setup logic for the pricing page when invitation info is present
         */
        vm.handleInvitationData = () => {

            // Handles project invitation
            if ($stateParams.project_id && $stateParams.code) {

                // Make the email readonly
                vm.displayProBasic = true;
                vm.code = $stateParams.code;
                vm.thread_id = $stateParams.thread_id;
                vm.project_id = $stateParams.project_id;

                if ($stateParams.thread_id) {

                    // Validate invitation from channel
                    strukshurApiService.validateInvite.query({
                        code: vm.code,
                        thread_id: vm.thread_id,
                        project_id: vm.project_id,
                    }, (res) => {
                        if (res.invitation && res.invitation.email) {
                            vm.email = res.invitation.email;
                            vm.fromInvitation = true;
                            if (res.invitation.status === 'completed') {
                                vm.openLogin();
                            } else {
                                vm.openRegister({ plan: 'basic' });
                            }
                        }
                    });
                } else {

                    // Validate invitation from project
                    strukshurApiService.validateInvite.query({
                        code: vm.code,
                        project_id: vm.project_id,
                    }, (res) => {
                        if (res.invitation && res.invitation.email) {
                            vm.email = res.invitation.email;
                            vm.fromProjectInvitation = true;

                            if (res.invitation.status === 'completed') {
                                vm.openLogin();
                            } else {
                                vm.openRegister({ plan: 'basic' });
                            }
                        }
                    });
                }
            } else if ($stateParams.member_id && $stateParams.code) {

                // Handles organization invitation
                vm.displayProBasic = true;
                vm.code = $stateParams.code;
                vm.memberId = $stateParams.member_id;

                //validate invitation
                strukshurApiService.organizationMember.validateInvite({ member_id: vm.memberId, code: vm.code }).$promise
                    .then((res) => {
                        if (res.member && res.member.email) {
                            vm.email = res.member.email;
                            vm.organization = res.organization;
                            vm.fromOrganizationInvitation = true;

                            if (res.member.status === 'active') {
                                vm.openLogin();
                            } else {
                                vm.openRegister({ plan: 'pro-basic' });
                            }
                        }
                    })
                    .catch((res) => {
                        if (res.status === 403) {
                            toastr.error('You don\'t have the necessary permission to accept the invite.', 'Error');
                        } else if (res.status === 404) {
                            toastr.error('The invite for the organization has either expired or been deleted.', 'Error');
                        } else {
                            toastr.error('There was an error trying to accept the invite.', 'Error');
                        }
                    })
                ;
            }
        };

        vm.loadPricingInfo = () => {
            vm.prices = PricingService.getNormal();
            vm.prices_pro = PricingService.getPro(vm.displayProBasic);
        };

        vm.getPrice = (plan) => Math.floor(plan.price);

        vm.getCents = (plan) => {
            const price = Math.round((plan.price - Math.floor(plan.price)) * 100);
            if (price > 0 && price < 10) {
                return '0' + String(price);
            }

            return price;
        };

        vm.loadMore = () => [];

        vm.openLogin = () => {
            const modalInstance = $uibModal.open({
                scope: vm,
                size: 'lg',
                keyboard: true,
                animation: vm.animationsEnabled,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                template: require('../../common/templates/login-modal.tpl.html'),
                controller: 'ModalLoginCtrl',
                resolve: {
                    items: () => vm.items,
                }
            });

            modalInstance.result.then(angular.noop, angular.noop);
        };

        vm.openRegister = (plan) => {
            if (typeof plan === 'undefined') {
                plan = { plan: 'basic' };
            }

            const modalInstance = $uibModal.open({
                scope: vm,
                size: 'lg',
                keyboard: true,
                animation: vm.animationsEnabled,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                template: require('../../common/templates/register-modal.tpl.html'),
                controller: 'ModalRegisterController',
                resolve: {
                    items: () => vm.items,
                    plan_id: () => (plan) ? plan.plan : false,
                }
            });

            modalInstance.result.then(angular.noop, angular.noop);
        };

        vm.homePricing = () => {
            $state.go('pricing');
        };

        vm.proPricing = () => {
            $state.go('pricing-pro');
        };

        vm.init();
    })

    .controller('HomeModalInstanceCtrl', function HomeModalInstanceCtrl ($scope, $state, $uibModalInstance) {
        $scope.ok = function () {
            $uibModalInstance.close();
            $state.go('setup-interior');
        };

        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    })

    .controller('ModalLoginCtrl', function ModalLoginCtrl ($scope, $timeout, $uibModalInstance, $window, ngDialog, strukshurAuthUserService, strukshurModalService, strukshurUserService) {
        var vm = $scope;

        vm.form = {};

        vm.cancel = () => $uibModalInstance.dismiss('cancel');

        vm.authloggedin = strukshurAuthUserService.getAuthLoggedin();

        vm.IntentLogin = () => {
            strukshurAuthUserService.isUserConnectedFacebook();
            ngDialog.close();
        };

        vm.IntentLogout = () => {
            strukshurAuthUserService.removeUser();
            vm.authloggedin = false;
        };

        vm.ForgotPassword = () => {
            $uibModalInstance.dismiss('cancel');
            strukshurModalService.openForgotPasswordModal();
        };

        vm.formLogin = (myform) => {
            vm.loading = true;
            const promise = strukshurAuthUserService.login(myform);
            promise.then(data => {
                vm.loading = false;
                console.log('promise Success');
                console.log(data);

                if ($window.ga) {
                    $window.ga('send', 'event', 'Login Form', 'submit', 'email', myform.email.$viewValue);
                }
                $uibModalInstance.close();

                strukshurUserService.setUser(data.user);
                if (data.projects && data.projects.length > 0) {
                    strukshurUserService.setProjects(data.projects);
                }
                if (data.organizations && data.organizations.length > 0) {
                    strukshurUserService.setOrganizations(data.organizations);
                }

                $timeout(() => strukshurAuthUserService.userRedirect(data.user), 350);
            }, reason => {
                vm.loading = false;
                console.log('promise Failed: ' + reason);
            });
        };
    })

    .controller('ModalForgotPasswordCtrl', function ModalForgotPasswordCtrl($scope, strukshurApiService, $uibModalInstance) {

        // very important
        $scope.form = {};
        $scope.formSuccess = false;

        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.formForgotPassword = function() {
            strukshurApiService.userForgotPassword.save({email: this.email}, function(d) {

                //$uibModalInstance.dismiss('cancel');
                $scope.formSuccess = true;
            }, function (error) {
                var errorType = error.data.error;

                if (errorType == 'userNotFound') {
                    $scope.form.ForgotPasswordForm.email.$setValidity('notfound', false);
                }

                if (errorType == 'passwordAlreadyRequested') {
                    $scope.form.ForgotPasswordForm.email.$setValidity('alreadysent', false);
                    $scope.form.ForgotPasswordForm.email.$setValidity('notfound', true);
                }
            });
        };
    })

    .controller('ModalRegisterController', function ModalRegisterController ($scope, $uibModalInstance, $state, strukshurApiService, strukshurAuthUserService, ngDialog, plan_id, toastr) {
        var vm = $scope;

        vm.form = {};
        vm.plan_id = plan_id;

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        vm.authloggedin = strukshurAuthUserService.getAuthLoggedin();

        vm.IntentLogin = function() {
            strukshurAuthUserService.isUserConnectedFacebook();
            ngDialog.close();
        };

        vm.IntentLogout = function() {
            // @TODO
            strukshurAuthUserService.removeUser();
            vm.authloggedin = false;
        };

        vm.formRegister = function (myform) {
            var promise, shouldLogin = (vm.fromOrganizationInvitation);

            if (vm.plan_id) {
                myform.plan_id = vm.plan_id;
            }

            vm.loading = true;
            promise = strukshurAuthUserService.register(myform, shouldLogin);
            promise.then(function (data) {
                if (vm.fromInvitation) {
                    strukshurApiService.acceptInvite.save({ project_id: vm.project_id, thread_id: vm.thread_id, code: vm.code, user_slug: data.user.slug }, function (d) {
                        vm.loading = false;
                        ngDialog.close();
                        $state.go('home');
                    });
                } else if (vm.fromOrganizationInvitation) {
                    strukshurApiService.organizationMember.acceptInvite({ member_id: vm.memberId, code: vm.code, user_slug: data.user.slug }).$promise
                        .then(function (res) {
                            var organization = res.organization;

                            vm.loading = false;
                            window.location.href = $state.get('organization').url.replace(':slug', organization.slug).replace(':id', organization.id);
                        })
                        .catch(function (res) {
                            if (res.status === 403) {
                                toastr.error('You don\'t have the necessary permission to accept the invite.', 'Error');
                            } else if (res.status === 404) {
                                toastr.error('The invite for the organization has either expired or been deleted.', 'Error');
                            } else {
                                toastr.error('There was an error trying to accept the invite.', 'Error');
                            }
                        })
                    ;
                } else if (vm.fromProjectInvitation) {
                    strukshurApiService.acceptInvite.save({ project_id: vm.project_id, code: vm.code, user_slug: data.user.slug }, function (d) {
                        vm.loading = false;
                        ngDialog.close();
                        $state.go('home');
                    });
                } else {
                    vm.loading = false;
                    ngDialog.close();
                    $state.go('confirm', { plan: vm.plan_id });
                }
            }, function (reason) {
                vm.loading = false;
                console.log('promise Failed: ' + reason);
            });
        };
    })

    .controller('ContactUsController', function ContactUsController ($scope, $uibModalInstance, strukshurApiService) {
        var vm = $scope;

        vm.form = {};
        vm.contact = {};
        vm.loading = false;
        vm.hasError = false;
        vm.formSuccess = false;

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        vm.sendContactForm = function () {
            var data = {};

            if (vm.loading) { return; }

            if (vm.form.$invalid) { return; }

            vm.loading = true;
            vm.hasError = false;

            data = {
                name: vm.contact.name,
                email: vm.contact.email,
                phone: vm.contact.phone,
                message: vm.contact.message
            };

            //send
            strukshurApiService.contact.save(data, function (d) {
                vm.loading = false;
                if (d.success) {
                    vm.formSuccess = true;
                } else {
                    vm.hasError = true;
                    console.log(d);
                }
            }, function (e) {
                vm.loading = false;
                vm.hasError = true;
                console.log(e);
            });
        };
    })
;
