import * as angular from 'angular';
import Masonry from 'masonry-layout';
import imagesLoaded from 'imagesloaded';

angular.module( 'strukshurApp.ideas', ['ui.router', 'ui.bootstrap'])

.config(function config($stateProvider) {
    $stateProvider.state('ideas', {
        url: '/ideas',
        views: {
            'main': {
                controller: 'IdeasCtrl',
                template: require('./ideas.tpl.html')
            }
        },
        data: { pageTitle: 'Ideas', class: 'ideas', fluidContainer: true }
    });
})

.controller('IdeasCtrl', function IdeasCtrl ($interval, $scope, $uibModal, strukshurApiService, $window, $timeout, strukshurPinterestService, strukshurUserService, uiTourService, strukshurUtilService) {
    var vm = $scope;
    var idx_pinterest = 0;
    var currentPageYOffset;

    vm.page = 1;
    vm.ideas = [];
    vm.boards = [];
    vm.nextPage = {};
    vm.products = [];
    vm.loading = false;
    vm.ideasGrid = null;
    vm.currentUser = {};
    vm.hasMorePins = true;
    vm.initialLoad = true;
    vm.masonrySetup = false;

    /**
     * Controller initialization logic
     */
    vm.init = () => {
        strukshurUserService.getUser().then((res: any) => {
            vm.currentUser = (res.user ? res.user : res);
        });

        // Check if the button should appear only every 2 seconds so we don't overload the browser
        $interval(vm.showScrollToTopButton, 500);

        // vm.setupTour();
        vm.loadMorePins();
    };

    /**
     * Wether to show the scroll to top button or not
     */
     vm.showScrollToTopButton = () => {
        if (currentPageYOffset !== window.pageYOffset) {
            currentPageYOffset = window.pageYOffset;

            if (currentPageYOffset > 500) {
                console.log('show');
                angular.element(document.getElementById('backtop')).addClass('visible');
            } else {
                angular.element(document.getElementById('backtop')).removeClass('visible');
                console.log('hide');
            }
        }
    };

    vm.fillPinterestPins = (data: any) => {
        strukshurPinterestService.public.getStrukshurBoardPins(data, function (res) {
            var i, tot, pin, tot_prod, tot2, i2;

            res.data = strukshurUtilService.shuffle(res.data);
            tot = res.data.length;
            tot_prod = vm.products.length;
            if (res.bookmark) {
                vm.nextPage[res.data[0].board.id] = res.bookmark;
            }

            for (i = 0; i < tot; i++) {
                pin = {
                    id: res.data[i].id,
                    type: 'pinterest',
                    url: res.data[i].link,
                    rand: Math.random(),
                    title: res.data[i].title,
                    description: res.data[i].description,
                    img: res.data[i].image_large_url,
                };

                if (vm.ideas.length > (idx_pinterest + 1) * 3) {
                    vm.ideas.splice((idx_pinterest + 1) * 3, 0, pin);
                } else {
                    vm.ideas.push(pin);
                }

                if (i !== 0 && (i % 3) === 0) {
                    // random product
                    tot2 = Math.floor(tot_prod * Math.random());
                    for (i2 = 0; i2 < tot2; i2++) {
                        vm.ideas.push(angular.copy(vm.products[Math.floor(Math.random() * tot_prod)]));
                    }
                }

                idx_pinterest++;
            }
        });
    };

    vm.loadMorePins = () => {
        if (vm.loading) { return; }

        vm.loading = true;
        strukshurApiService.ideas.search({ page: vm.page }).$promise
            .then((res: any) => {
                vm.page++;

                if (res.ideas.length === 0) {
                    vm.hasMorePins = false;
                }

                res.ideas.forEach((result: any) => {
                    if (result.type === 'product') {
                        vm.products.push(result);
                    }
                });

                var ideasTmp = angular.copy(vm.ideas);
                ideasTmp = ideasTmp.concat(res.ideas);
                vm.ideas = ideasTmp;

                // Fill it with pinterest pins
                strukshurPinterestService.public.getStrukshurBoards({ sort: 'alphabetical' }, (res: any) => {
                    vm.boards = res.data;

                    // Only uses a random board from the returned boards
                    let ele = Math.floor(Math.random() * vm.boards.length);
                    vm.fillPinterestPins({ board_id: vm.boards[ele].id });
                    vm.boards.splice(ele, 1);
                });

                if (!vm.masonrySetup) {
                    $timeout(vm.setupIdeasGrid, 100);
                }

                vm.initialLoad = false;
            })
            .finally(() => vm.loading = false)
        ;
    };

    /**
     * Sets up the grid for the ideas board
     */
    vm.setupIdeasGrid = () => {

        // Setups Masonry grid
        vm.masonrySetup = true;
        vm.ideasGrid = new Masonry('.ideas', { itemSelector: '.grid-item' });

        // Adds event to suffle ideas grid after every image load
        const imgLoad = imagesLoaded('.ideas');
        imgLoad.on('progress', () => vm.ideasGrid.layout());
    };

    vm.ideaOpen = function (picture) {
        if (picture.type === 'product') {
            $window.location.href = `/shop/product/${picture.id}/${picture.slug}`;
        } else if (picture.type === 'amazon') {
            $window.open(picture.link);
        } else if (picture.type === 'pinterest') {
            var modalInstance = $uibModal.open({
                scope: vm,
                keyboard: true,
                animation: true,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                controller: 'PinImageCtrl',
                template: require('../../common/templates/pin-image-modal.tpl.html'),
                resolve: {
                    idea: () => { return picture },
                }
            });

            modalInstance.result.then(angular.noop, angular.noop);
        } else {
            //get gallery and open popup
            strukshurApiService.proGallery.query({ id: picture.gallery_id }, function (d) {
                vm.galleryOpen(d.gallery, picture.position);
            });
        }
    };

    vm.ideaAdd = function (picture) {
        var modalInstance;
        if (picture.type === 'amazon') {
            vm.gallery = { id: picture.id };

            vm.selectedImage = picture.img;
            vm.url = picture.url;
            modalInstance = $uibModal.open({
                scope: vm,
                keyboard: false,
                animation: true,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                controller: 'PinGalleryCtrl',
                template: require('../../common/templates/board-modal-save.tpl.html'),
                resolve: {}
            });

            modalInstance.result.then(function (selectedItem) {
                //vm.selected = selectedItem;
                // if (selectedItem.project.id) {
                //     $state.go('projects-detail.board', { project_id: selectedItem.project.id, project_slug: selectedItem.project.slug }, { reload: true });
                // } else {
                //     $state.go('room', { userSlug: vm.currentUser.slug, houseSlug: 'my-house', roomSlug: selectedItem.roomSlug }, { reload: true });
                // }
            }, function () {
                //$log.info('Modal dismissed at: ' + new Date());
            });
        } else if (picture.type === 'product') {
            //open popup to select room
            modalInstance = $uibModal.open({
                scope: vm,
                keyboard: true,
                animation: true,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                controller: 'SelectRoomCtrl',
                template: require('../../common/templates/room-select-modal.tpl.html'),
                resolve: {
                    idea: () => { return picture },
                }
            });

            modalInstance.result.then(angular.noop, angular.noop);
        } else if (picture.type === 'pinterest') {
            vm.gallery = {
                id: picture.id
            };

            vm.selectedImage = picture.img;
            vm.url = picture.url;
            modalInstance = $uibModal.open({
                scope: vm,
                animation: true,
                keyboard: false,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                controller: 'PinGalleryCtrl',
                template: require('../../common/templates/board-modal-save.tpl.html'),
                resolve: {},
            });

            modalInstance.result.then(angular.noop, angular.noop);
        } else {
            strukshurApiService.proGallery.query({ id: picture.gallery_id }, function (d) {
                vm.gallery = d.gallery;

                vm.selectedImage = picture.img;
                modalInstance = $uibModal.open({
                    scope: vm,
                    keyboard: false,
                    animation: true,
                    ariaLabelledBy: 'modal-title',
                    ariaDescribedBy: 'modal-body',
                    controller: 'PinGalleryCtrl',
                    template: require('../../common/templates/board-modal-save.tpl.html'),
                    resolve: {}
                });

                modalInstance.result.then(angular.noop, angular.noop);
            });
        }
    };

    vm.galleryOpen = function (gallery, position) {
        if (typeof position === 'undefined') {
            position = 1;
        }
        vm.gallery = gallery;

        // fetch all the images
        var modalInstance = $uibModal.open({
            scope: vm,
            size: 'slide',
            keyboard: true,
            animation: true,
            ariaLabelledBy: 'modal-title',
            ariaDescribedBy: 'modal-body',
            controller: 'ProGalleryModalInstanceCtrl',
            template: require('../../common/templates/gallery-modal.tpl.html'),
            resolve: {
                gallery: () => { return gallery },
                position: () => { return position },
            }
        });

        modalInstance.result.then(angular.noop, angular.noop);
    };

    /**
     * Handles saving the item to the user favorites
     *
     * @param  {object}  item  The item
     * @param  {string}  type  The item type
     */
    vm.saveFavorite = (item, type, event) => {
        event.preventDefault();
        event.stopPropagation();

        // Opens modal for the user to login, if anonymous
        if (!vm.user.slug) {
            return vm.openLogin();
        }

        // We need to update the received item depending on the given type
        item.note = item.note || item.description;
        if (type === 'galleryImage') {
            item.originalLink = item.link;
        }
        if (type === 'pinterest') {
            item.originalLink = item.url;
        }

        const modalInstance = $uibModal.open({
            scope: vm,
            keyboard: true,
            animation: vm.animationsEnabled,
            ariaLabelledBy: 'modal-title',
            ariaDescribedBy: 'modal-body',
            template: require('../../common/templates/save-favorite-modal.tpl.html'),
            controller: 'SaveFavoriteModalCtrl',
            resolve: {
                item: () => item,
                type: () => type,
                user: () => vm.user,
            }
        });

        modalInstance.result.then(angular.noop, angular.noop);
    };

    /**
     * Setup and initialize product tour for this page
     */
    vm.setupTour = function () {
        var steps = [
            { selector: '.angular-grid-item', order: 0, popupClass: 'ideas-board-step', content: 'Hover over an image, video or product to view it and/or pin it to a room or project.' },
            { selector: '.btn-arrow.buy', order: 1, popupClass: 'btn-buy-step', content: 'Click the buy button to be directed to the appropriate vendor\'s product page to complete your purchase.' },
        ];

        // First we setup the product tour
        vm.tour = uiTourService.createDetachedTour('ideas-tour', { appendToBody: true, backdrop: true });

        // Then we add the defined steps to the tour
        steps.forEach((step: any) => {
            vm.tour.createStep(step);
        });

        vm.tour.on('stepChanged', () => {
            vm.tour.pause();
            $timeout(vm.tour.resume, 100);
        });
    };

    /**
     * Initializes product tour
     */
    vm.initTour = function () {

        // And finally display the product tour overview modal to get the flow started
        var modalInstance = $uibModal.open({
            scope: vm,
            keyboard: true,
            animation: true,
            ariaLabelledBy: 'modal-title',
            ariaDescribedBy: 'modal-body',
            controller: angular.noop,
            template: require('../../common/templates/product-tour/ideas-board-overview.tpl.html')
        });
        modalInstance.result.then(angular.noop, angular.noop);
    };

    /**
     * Starts the tour by showing the first step
     */
    vm.startTour = () => vm.tour.start();

    vm.init();
})

.controller('PinImageCtrl', function PinImageCtrl($scope, $uibModalInstance, $uibModal, strukshurUserService, $state, idea) {
    var vm = $scope;

    vm.idea = idea;
    vm.loading = true;
    vm.currentUser = {};
    strukshurUserService.getUser().then(function (d) {
        vm.currentUser = (d.user?d.user:d);
    });

    vm.galleryMainImageStyle = {
        'background-image': 'url('+vm.idea.img+')'
    };

    vm.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };

    vm.pinImage = function (selectedImage) {
        var modalInstance;

        vm.gallery = { id: vm.idea.id };

        vm.selectedImage = selectedImage;
        vm.url = vm.idea.url;
        modalInstance = $uibModal.open({
            scope: vm,
            keyboard: false,
            animation: true,
            ariaLabelledBy: 'modal-title',
            ariaDescribedBy: 'modal-body',
            template: require('../../common/templates/board-modal-save.tpl.html'),
            controller: 'PinGalleryCtrl',
            resolve: { }
        });

        modalInstance.result.then(function (selectedItem) {
            $uibModalInstance.dismiss('cancel');
            // vm.selected = selectedItem;
            // $state.go('room', { userSlug: vm.currentUser.slug, houseSlug: 'my-house', roomSlug: selectedItem }, { reload: true });
        }, function () {
            // $log.info('Modal dismissed at: ' + new Date());
        });
    };
})

;
