import * as angular from 'angular';

angular.module('QuickHelpDirective', [])
    .directive('quickHelp', function ($rootScope, $state) {
        return {
            restrict: 'E',
            template: require('./quick-help.tpl.html'),
            scope: {
                state: '='
            },
            controllerAs: 'qh',
            bindToController: true,
            controller: function () {
                var vm = this;

                // Wether the panel is open or not
                vm.closed = true;

                // Wether the current page has a product tour to display
                vm.pageHasTour = false;

                // The list of urls which have product tours to display
                vm.urlsWithTours = [
                    /^\/projects$/,  // Projects list
                    /^\/ideas$/, // Ideas board
                    /^\/.+\/setup\/interior$/gm, // My Home
                    /^\/.+\/.+\/room\/.+\/.+$/gm, // Room board
                ];

                /**
                 * Display quick help widget
                 */
                vm.openQuickHelp = function () {
                    vm.closed = false;
                    vm.checkPageHasTour();
                };

                /**
                 * Closes quick help widget
                 */
                vm.closeQuickHelp = function () {
                    vm.closed = true;
                };

                /**
                 * Opens contact us modal
                 */
                vm.openContactUs = function () {
                    vm.closeQuickHelp();
                    $rootScope.openContactUs();
                };

                /**
                 * Toggles the state of the quick help widget
                 */
                vm.toggle = function () {
                    if (vm.closed) {
                        vm.openQuickHelp();
                    } else {
                        vm.closeQuickHelp();
                    }
                };

                /**
                 * Check if the page has a product tour or not
                 */
                vm.checkPageHasTour = function () {
                    vm.pageHasTour = false;

                    vm.urlsWithTours.forEach(function (re) {
                        if (re.test($state.current.url)) {
                            vm.pageHasTour = true;
                            return;
                        }
                    });
                };

                /**
                 * Opens the product tour associated with the current page
                 */
                vm.openCurrentPageTour = function () {
                    vm.closeQuickHelp();

                    var url = $state.current.url;

                    // Projects list
                    if (/^\/projects$/.test(url)) {
                        $state.$current.locals['main@'].$scope.initTour();
                    }

                    // Ideas board
                    if (/^\/ideas$/.test(url)) {
                        $state.$current.locals['main@'].$scope.initTour();
                    }

                    // My home
                    if ((/^\/.+\/setup\/interior$/gm).test(url)) {
                        $state.$current.locals['main@'].$scope.initTour();
                    }

                    // Room board
                    if ((/^\/.+\/.+\/room\/.+\/.+$/gm).test(url)) {
                        $state.$current.locals['main@'].$scope.initTour();
                    }
                };
            }
        };
    })
;
