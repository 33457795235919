import * as angular from 'angular';
import { find as _find } from 'lodash';

var app = angular.module('NotificationService', []);

app.factory('NotificationService', function($interval, $rootScope, $state, strukshurApiService, strukshurUtilService, toastr) {
    var vm = this;

    vm.active = false;
    vm.refreshing = false;
    vm.notifications = [];
    vm.refreshPromise = null;
    vm.refreshInterval = 30; // seconds

    function getNotificationTitle(notification) {
        switch (notification.type) {
            case 'PROJECT_OWNER_NEW':
                return 'New Project Owner';

            case 'PROJECT_TASK_NEW':
                return 'New Project Task';

            case 'PROJECT_TASK_COMPLETED':
                return 'Project Task Completed';

            case 'PROJECT_COMMENT_NEW':
                return 'New Task Comment';

            case 'PROJECT_TASK_REMINDER':
                return 'Task Reminder';

            case 'PROJECT_MEMBER_INVITE':
                return 'Project Invitation';

            case 'PROJECT_MEMBER_INVITE_ACCEPTED':
                return 'Project Invitation Accepted';

            case 'PROJECT_MEMBER_INVITE_REJECTED':
                return 'Project Invitation Rejected';

            case 'PROJECT_MEMBER_NEW':
                return 'New Project Member';

            case 'PROJECT_PURCHASE_LIST_NEW':
                return 'New Project Purchase List';

            case 'PROJECT_PIN_NEW':
                return 'New Project Pin';

            case 'PROJECT_PROGRESS_NEW_ITEM':
                return 'New Project Progress';

            case 'PROJECT_FINISH_NEW':
                return 'New Project Finish';

            case 'PROJECT_FINISH_FILE_NEW':
                return 'New Project Finish File';

            case 'PROJECT_FINISH_NOTE_NEW':
                return 'New Project Finish Note';

            case 'PROJECT_ESTIMATE_NEW':
                return 'New Project Estimate';

            case 'PROJECT_ESTIMATE_MARKED_AS_FINAL':
                return 'Estimate Marked as Final';

            default:
                return '';
        }
    }

    var service = {

        /**
         * Notification service init logic
         */
        bootstrap: function () {

            // Exits early if the service is already active
            if (vm.active === true) { return; }

            // Retrieves initial notification list
            vm.active = true;
            service.refreshNotifications(false)
                .then(function () {

                    // Setups up refresh interval for new notifications
                    vm.refreshPromise = $interval(function () {
                        service.refreshNotifications(true);
                    }, vm.refreshInterval * 1000);
                })
            ;
        },

        /**
         * Returns current notifications list
         *
         * @return {Array}
         */
        getNotifications: function () {
            return vm.notifications;
        },

        /**
         * Wether the notification service is active or not
         */
        isActive: function () {
            return vm.active;
        },

        /**
         * Marks the received notifications as read on the server
         *
         * @param  {Object}  list  The notifications to mark as read
         */
        markNotificationsAsRead: function (list) {
            var notifications = list || vm.notifications;

            var ids = [];
            notifications.forEach(function (notification) {
                if (!notification.read) {
                    ids.push(notification.id);
                }
            });

            if (ids.length > 0) {
                strukshurApiService.notifications.markAsRead({ ids: ids.join(',') }).$promise
                    .then(function () {
                        vm.notifications.forEach(notification => {
                            notification.read = true;
                        });
                        $rootScope.$broadcast('notificationsUpdated', vm.notifications);
                    })
                ;
            }
        },

        /**
         * Callback fired when a toast is tapped
         *
         * @param  {Object}  toast  The toast object
         */
        notificationTapped: function(toast) {
            var extraData = toast.scope.extraData;

            service.resolveAction(extraData.notification);

            // We broadcast the event to any interested listener
            $rootScope.$broadcast('toastTapped', toast);
        },

        /**
         * Shows the received notification to the user
         *
         * @param  {Object}  notification  The notification data
         */
        notify: function (notification, broadcastUpdate) {
            var shouldBroadcast = typeof broadcastUpdate === 'undefined' ? true : broadcastUpdate;
            var options = {
                allowHtml: true,
                iconClass: 'general',
                onTap: service.notificationTapped,
                extraData: { notification: notification }
            };

            toastr.info(notification.details.summary, notification.details.title, options);
            vm.notifications.push(notification);

            if (shouldBroadcast) {
                $rootScope.$broadcast('notificationsUpdated', vm.notifications);
            }
        },

        /**
         * Refreshes user notification list from the server
         *
         * @param  {Boolean}  displayNew  Wether to display new notifications or not
         *
         * @return {Promise}
         */
        refreshNotifications: function (displayNew) {

            // Do not try to refresh the notification list if the service is not active
            // or if another refresh is already happening
            if (vm.active === false || vm.refreshing === true) { return; }

            vm.refreshing = true;

            return strukshurApiService.notifications.list({}).$promise
                .then(function (res) {
                    var hasNewNotifications = false;

                    res.notifications.forEach(function (notification) {
                        var existingNotification = _find(vm.notifications, { id: notification.id });
                        if (!existingNotification) {
                            var newNotification = notification;
                            newNotification.showMore = false;
                            newNotification.details = JSON.parse(newNotification.details);
                            newNotification.details.title = (typeof newNotification.details.title !== 'undefined') ? newNotification.details.title : getNotificationTitle(newNotification);

                            // Handle creators for notifications that don't have
                            // a createdBy field
                            if (!newNotification.details.info.createdBy) {
                                if (notification.type === 'PROJECT_MEMBER_NEW') {
                                    newNotification.details.info.createdBy = newNotification.details.info.invitee;
                                }
                            }

                            hasNewNotifications = true;

                            if (displayNew) {
                                service.notify(newNotification, false);
                            } else {
                                vm.notifications.unshift(newNotification);
                            }
                        } else {

                            // The norification already exists so we only update its
                            // hideActions field since it's the only one which can
                            // be updated after a notification is created
                            existingNotification.hideActions = notification.hideActions;
                        }
                    });

                    if (hasNewNotifications) {
                        $rootScope.$broadcast('notificationsUpdated', vm.notifications);
                    }
                })
                .catch(angular.noop)
                .finally(function () {
                    vm.refreshing = false;
                })
            ;
        },

        /**
         * Resolves the notification action according to its type
         *
         * @param  {Object}  notification  The notification
         */
        resolveAction: function(notification) {
            if (notification.type === 'PROJECT_OWNER_NEW' || notification.type === 'PROJECT_BID_ACCEPTED') {
                return $state.go('projects-detail', {
                    'project_id': notification.details.info.project.id,
                    'project_slug': strukshurUtilService.slugify(notification.details.info.project.title)
                });
            }

            if (['PROJECT_ESTIMATE_NEW', 'PROJECT_ESTIMATE_MARKED_AS_FINAL'].includes(notification.type)) {
                return $state.go('projects-detail.estimates', {
                    'project_id': notification.details.info.project.id,
                    'project_slug': strukshurUtilService.slugify(notification.details.info.project.title)
                });
            }

            if (notification.type === 'PROJECT_FILE_NEW') {
                return $state.go('projects-detail.assets', {
                    'project_id': notification.details.info.project.id,
                    'project_slug': strukshurUtilService.slugify(notification.details.info.project.title)
                });
            }

            if (['PROJECT_FINISH_NEW', 'PROJECT_FINISH_FILE_NEW', 'PROJECT_FINISH_NOTE_NEW'].includes(notification.type)) {
                return $state.go('projects-detail.finishes', {
                    'project_id': notification.details.info.project.id,
                    'project_slug': strukshurUtilService.slugify(notification.details.info.project.title)
                });
            }

            if (notification.type === 'PROJECT_PIN_NEW') {
                return $state.go('projects-detail.board', {
                    'project_id': notification.details.info.project.id,
                    'project_slug': strukshurUtilService.slugify(notification.details.info.project.title)
                });
            }

            if (notification.type === 'PROJECT_PROGRESS_NEW_ITEM') {
                return $state.go('projects-detail.progress', {
                    'project_id': notification.details.info.project.id,
                    'project_slug': strukshurUtilService.slugify(notification.details.info.project.title)
                });
            }

            if (notification.type === 'PROJECT_PURCHASE_LIST_NEW') {
                return $state.go('projects-detail.lists', {
                    'project_id': notification.details.info.project.id,
                    'project_slug': strukshurUtilService.slugify(notification.details.info.project.title)
                });
            }

            if (notification.type === 'PROJECT_TASK_NEW' || notification.type === 'PROJECT_TASK_COMPLETED' || notification.type === 'PROJECT_COMMENT_NEW' || notification.type === 'PROJECT_TASK_REMINDER') {
                return $state.go('projects-detail.tasks', {
                    'project_id': notification.details.info.project.id,
                    'project_slug': strukshurUtilService.slugify(notification.details.info.project.title),
                    'taskId': notification.details.info.task.id,
                });
            }

            if (notification.type === 'PROJECT_BID_NEW' || notification.type === 'PROJECT_BID_UPDATED') {
                return $state.go('projects-detail.bids', {
                    'project_id': notification.details.info.project.id,
                    'project_slug': strukshurUtilService.slugify(notification.details.info.project.title)
                });
            }
        },

        /**
         * Handles the shutdown of the notification service
         */
        shutdown: function () {
            vm.active = false;
            $interval.cancel(vm.refreshPromise);
        }
    };

    return service;
});
