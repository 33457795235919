// Main stylesheet
import './scss/main.scss'
import './less/main.less';

// -------------------
// Vendor dependencies
// -------------------

// Misc. dependencies
import 'jquery';
import * as isMobile from 'ismobilejs';
import * as moment from 'moment';
import BrowserDetect from './common/lib/browser_detect';

// angular dependencies
import * as angular from 'angular';
import * as ngAnimate from 'angular-animate';
import * as ngCookies from 'angular-cookies';
import * as ngResource from 'angular-resource';
import * as ngSanitize from 'angular-sanitize';
import './common/directives/angular-autogrow.js';
import './common/lib/angular-toastr.custom.js';
import './common/lib/ngClipboard.js';
import './common/lib/ngFloatingLabels.js';
import './common/lib/pdk.js';
import 'angular-avatar';
import 'angular-backtop';
import 'angular-bind-html-compile';
import 'angular-contenteditable';
import 'angular-drag-and-drop-lists';
import 'angular-file-upload';
import 'angular-flash-alert';
import 'angular-hotkeys';
import 'angular-inview';
import 'angular-ivh-treeview';
import 'angular-loading-bar';
import 'angular-moment';
import 'angular-paging';
import 'angular-scroll';
import 'angular-sticky-plugin';
import 'angular-strap';
import 'angular-svg-round-progressbar';
import 'angular-tablesort';
// import 'angular-ui-bootstrap';
import 'angular-ui-router';
import 'angular-ui-switch';
import 'angular-ui-tour';
import 'angular-validation-match';
import 'angular-xeditable';
import 'angulargrid';
import 'angularLocalStorage';
// import 'bootstrap5';
import 'hone';
import 'imagesloaded';
import 'masonry-layout';
import 'ng-currency';
import 'ng-dialog';
import 'ng-tags-input';
import 'ngSmoothScroll';
import 'tether';
import 'ui-bootstrap4';
import 'ui-select';

// ----------------
// App dependencies
// ----------------

// Strukshur modules
import './common/services/index.ts';
import './common/directives/index.ts';
import './modules/account/index.ts';
import './modules/admin/index.ts';
import './modules/auth/index.ts';
import './modules/estimate/index.ts';
import './modules/favorite/index.ts';
import './modules/home/index.ts';
import './modules/ideas/index.ts';
import './modules/organizations/index.ts';
import './modules/productAdmin/index.ts';
import './modules/projects/index.ts';
import './modules/pros/index.ts';
import './modules/room/index.ts';
import './modules/search/index.ts';
import './modules/settings/index.ts';
import './modules/setup/index.ts';
import './modules/store/index.ts';
import './modules/upload/index.ts';

const strukshurApp = angular.module('strukshurApp', [

    // Core AngularJS modules
    ngAnimate,
    ngCookies,
    ngResource,
    ngSanitize,

    // 3rd-party modules
    'angular-inview',
    'angular-loading-bar',
    'angular-svg-round-progressbar',
    'angular.backtop',
    'angularFileUpload',
    'angularGrid',
    'angularjs-autogrow',
    'angularLocalStorage',
    'angularMoment',
    'bm.uiTour',
    'bw.paging',
    'contenteditable',
    // 'de.ng-sortable',
    'dndLists',
    'hl.sticky',
    'ivh.treeview',
    'mgcrea.ngStrap',
    'ng-currency',
    'ngAvatar',
    'ngClipboard',
    'ngDialog',
    'ngFlash',
    'ngFloatingLabels',
    'ngTagsInput',
    'smoothScroll',
    'tableSort',
    'toastr',
    'ui.bootstrap',
    'ui.router',
    'ui.select',
    'uiSwitch',
    'validation.match',
    'xeditable',

    // --------------------
    //  Strukshur scripts
    // --------------------

    // Services
    'CartService',
    'ContractorRoleService',
    'fileReaderService',
    'fileService',
    'InboxService',
    'NotificationService',
    'PricingService',
    'StateService',
    'strukshurAdminService',
    'strukshurApiService',
    'strukshurAuthUserService',
    'strukshurFlashService',
    'strukshurModalService',
    'strukshurOrganizationService',
    'strukshurPinterestService',
    'strukshurProjectService',
    'strukshurProService',
    'strukshurUploadService',
    'strukshurUserService',
    'strukshurUtilService',
    'TimezoneService',
    'userResolveService',
    'userRouterResolveService',

    // Directives
    'BlueprintDirective',
    'BoardDirective',
    'CartDirective',
    'ChangeOnBlurDirective',
    'FileBase64Directive',
    'HeaderNavDirective',
    'QuickHelpDirective',

    // Modules
    'strukshurApp.account',
    'strukshurApp.admin',
    'strukshurApp.adminCategories',
    'strukshurApp.adminDeliveries',
    'strukshurApp.adminEstimateCategories',
    'strukshurApp.adminOrders',
    'strukshurApp.adminProducts',
    'strukshurApp.adminSkills',
    'strukshurApp.adminTaxonomy',
    'strukshurApp.adminUsers',
    'strukshurApp.adminVendors',
    'strukshurApp.auth',
    'strukshurApp.estimate',
    'strukshurApp.favorite',
    'strukshurApp.home',
    'strukshurApp.ideas',
    'strukshurApp.organizations',
    'strukshurApp.productAdmin',
    'strukshurApp.productAdminDeliveries',
    'strukshurApp.productAdminOrders',
    'strukshurApp.projects',
    'strukshurApp.pros',
    'strukshurApp.prosRegister',
    'strukshurApp.room',
    'strukshurApp.search',
    'strukshurApp.settings',
    'strukshurApp.setup',
    'strukshurApp.store',
    'strukshurApp.upload',
])

    .config(function myAppConfig($locationProvider, $uibModalProvider, $urlRouterProvider, FlashProvider) {
        $urlRouterProvider.otherwise('/home');
        $locationProvider.html5Mode(true);

        // Replace angular-flash-alert template to use Bootstrap 5 style
        FlashProvider.setTemplate(`
            <div role="alert" id="{{flash.config.id}}" class="alert {{flash.config.class}}
                alert-{{flash.type}} alert-dismissible alertIn alertOut">
                <span dynamic="flash.text"></span>
                <button type="button" class="btn-close float-end" ng-show="flash.showClose" close-flash="{{flash.id}}" aria-label="Close"></button>
            </div>
        `);
    })

    .service('InitService', ['$q', ($q) => {
        let d = $q.defer();

        return {
            defer: d,
            promise: d.promise
        };
    }])

    .run(['$rootScope', '$location', 'storage', 'strukshurUserService', 'InitService', 'editableOptions', '$state',
        ($rootScope, $location, storage, strukshurUserService, InitService, editableOptions, $state) => {
            moment.locale('en-us');
            editableOptions.theme = 'bs2'; // bootstrap3 theme. Can be also 'bs2', 'default'
            editableOptions.activate = 'select';

            // global login flag
            $rootScope.loggedin = false;

            // only fetch user details from api one time
            $rootScope.authApiUserCheck = false;

            // temporary security site login
            $rootScope.auth = storage.get('strukauth') || false;

            // Add device type specific class to the root element of the page
            if (isMobile.phone === true) {
                angular.element(document.getElementsByTagName('html')[0]).addClass('is-phone');
            } else if (isMobile.tablet === true) {
                angular.element(document.getElementsByTagName('html')[0]).addClass('is-tablet');
            }

            // Add user agent specific class to the root element of the page
            angular.element(document.getElementsByTagName('html')[0]).addClass('is-' + BrowserDetect.browser.toLowerCase());

            $rootScope.$on('$locationChangeStart', (event, next, current) => {
                const token = storage.get('jwt');
                $rootScope.hasToken = !(token === '' || token === null || token === undefined);
                $rootScope.pathname = $location.path();
                $rootScope.originalUrl = $rootScope.originalUrl || $location.url();
                $rootScope.originalPathname = $rootScope.originalPathname || $location.path();
                $rootScope.home_active = ($rootScope.pathname.indexOf('/home') === 0 || $rootScope.pathname === '/');
                $rootScope.pro_active = ($rootScope.pathname.indexOf('/pro') === 0);

                if (!$rootScope.authApiUserCheck && $rootScope.hasToken) {
                    $rootScope.authApiUserCheck = true;

                    strukshurUserService.getUser().then((res) => {
                        InitService.defer.resolve(res);
                    }, (err) => {
                        console.error(err);
                        if (err && err.data && err.data.message && err.data.message === 'Expired JWT Token') {
                            storage.set('jwt', null);
                            if ($location.path() !== '/home') {
                                $state.go('home');
                            }
                        }
                    });
                } else {
                    console.log('app user not resolved');
                    InitService.defer.resolve({ auth: false });
                }
            });
        }]
    )

    .controller('AppCtrl', function AppCtrl($q, $scope, $state, $uibModal, $rootScope, strukshurApiService, strukshurUploadService) {
        var vm = $scope;

        vm.fluidContainer = false;
        vm.uploadInProgress = false;

        /**
         * Controller initialization logic
         */
        vm.init = () => {
            vm.$on('$stateChangeSuccess', (event, toState, toParams, fromState, fromParams) => {
                if (angular.isDefined(toState.data.pageTitle)) {
                    vm.pageTitle = toState.data.pageTitle + ' | Strukshur';
                }

                if (angular.isDefined(toState.data.fluidContainer)) {
                    vm.fluidContainer = true;
                } else {
                    vm.fluidContainer = false;
                }

                // access in body class
                vm.state = $state;
            });

            // Listens for user update broadcast
            vm.$on('userUpdate', (event, args) => {
                vm.user = args;
                if (vm.user && vm.user.id && window.FS && window.FS.identify) {
                    FS.identify(vm.user.id, {
                        displayName: vm.user.fullName,
                        email: vm.user.email,
                        role_str: vm.user.userType
                    });
                }
            });

            $rootScope.$on('upload-items-added', () => vm.uploadInProgress = true);

            $rootScope.$on('upload-queue-complete', () => vm.uploadInProgress = false);

            $rootScope.$on('upload-queue-cleared', () => vm.uploadInProgress = false);
        };

        /**
         * Shows the upload manager modal
         */
        vm.showUploadManagerModal = () => {
            $rootScope.showUploadManagerModal();
        };

        // Makes product categories available throughout the system
        const deferred = $q.defer();
        $rootScope.productCategories = [];
        $rootScope.productCategoriesResolver = deferred;
        strukshurApiService.productCategories.list().$promise
            .then(res => {
                $rootScope.productCategories = res.cats;
                deferred.resolve(res.cats);
            })
            .catch(res => deferred.reject(res))
        ;

        $rootScope.openContactUs = () => {
            const modalInstance = $uibModal.open({
                scope: vm,
                size: 'lg',
                keyboard: true,
                animation: true,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                controller: 'ContactUsController',
                template: require('./common/templates/contact_us-modal.tpl.html'),
                resolve: {}
            });

            modalInstance.result.then(angular.noop, angular.noop);
        };

        /**
         * Opens upload manager modal
         */
        $rootScope.openUploadManagerModal = () => {
            $rootScope.uploadManagerModal = $uibModal.open({
                scope: vm.$new(),
                keyboard: true,
                backdropClass: 'modal-upload-manager-backdrop',
                openedClass: 'modal-open upload-manager-open',
                windowClass: 'upload-manager-modal',
                controller: 'DefaultUploadManagerModal',
                template: require('./modules/upload/upload-manager-modal.tpl.html'),
                resolve: {}
            });

            $rootScope.uploadManagerModal.result.then(

                // Resolved callback
                () => $rootScope.uploadManagerModal = null,

                // Rejected callback
                () => $rootScope.uploadManagerModal = null
            );
        };

        /**
         * Closes upload manager modal
         */
        $rootScope.closeUploadManagerModal = () => {
            if ($rootScope.uploadManagerModal) {
                $rootScope.uploadManagerModal.dismiss();
            }
        };

        /**
         * Shows upload manager modal
         */
        $rootScope.showUploadManagerModal = () => {

            // Check if the modal exists in the first place
            if (!$rootScope.isUploadManagerModalOpen()) {
                $rootScope.openUploadManagerModal();
            }
        };

        /**
         * Returns wether or not the upload manager modal is visible
         *
         * @return boolean
         */
        $rootScope.isUploadManagerModalOpen = () => {
            return ($rootScope.uploadManagerModal);
        };

        vm.init();
    })

    .filter('truncate', () => {
        return (text, length, end) => {
            if (isNaN(length)) {
                length = 50;
            }

            if (end === undefined) {
                end = "...";
            }

            if (text === undefined) {
                return '';
            }

            text = text.replace(/(<([^>]+)>)/ig,"");
            if (text.length <= length || text.length - end.length <= length) {
                return text;
            } else {
                return String(text).substring(0, length-end.length) + end;
            }
        };
    })

    .filter('nl2br', ['$sce', ($sce) => {
        return (input) => {
            if (input !== void 0) {
                return $sce.trustAsHtml(input.replace(/\n/g, '<br>'));
            }
        };
    }])

    .filter('filesize', () => {
        return (bytes) => {
            let unitIndex, value,
                units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
            bytes = parseFloat(bytes);

            if (isNaN(bytes)) {
                return '-';
            }
            if (bytes < 1) {
                return '0 B';
            }

            bytes = parseFloat(bytes);
            unitIndex = Math.floor(Math.log(bytes) / Math.log(1000));
            value = bytes / Math.pow(1000, unitIndex);

            return value.toFixed(0) +' '+units[unitIndex];
        };
    })

    .filter('random', ['$window', ($window) => {
        return (input) => {
            if (input && !isNaN(input) && parseInt(input) > 0) {
                return $window.Math.floor($window.Math.random() * $window.Math.floor(parseInt(input)));
            } else {
                return $window.Math.random();
            }
        };
    }])

    .filter('tel', () => {
        return (tel) => {
            if (!tel) { return ''; }

            var value = tel.toString().trim().replace(/^\+/, '');

            if (value.match(/[^0-9]/)) {
                return tel;
            }

            let country, city, number;

            switch (value.length) {
                case 10: // +1PPP####### -> C (PPP) ###-####
                    country = 1;
                    city = value.slice(0, 3);
                    number = value.slice(3);
                    break;

                case 11: // +CPPP####### -> CCC (PP) ###-####
                    country = value[0];
                    city = value.slice(1, 4);
                    number = value.slice(4);
                    break;

                case 12: // +CCCPP####### -> CCC (PP) ###-####
                    country = value.slice(0, 3);
                    city = value.slice(3, 5);
                    number = value.slice(5);
                    break;

                default:
                    return tel;
            }

            if (country === 1) {
                country = "";
            }

            number = number.slice(0, 3) + '-' + number.slice(3);

            return (country + " (" + city + ") " + number).trim();
        };
    })
;

export default strukshurApp;
