import {
    clone as _clone,
    remove as _remove,
} from 'lodash';
import * as angular from 'angular';
import * as EXIF from 'exif-js';
import * as isMobile from 'ismobilejs';

angular.module('strukshurApp.projects.purchaseLists', ['strukshurApp.projects.rooms'])

.config(function config($stateProvider, $uibModalProvider) {
    $uibModalProvider.options = { animation: true, backdrop: 'static', keyboard: false, ariaLabelledBy: 'modal-title', ariaDescribedBy: 'modal-body' };

    $stateProvider
        .state('projects-detail.lists', {
            url: '/lists',
            views: {
                '': {
                    controller: 'ProjectDetailPurchaseListsCtrl',
                    template: require('./project.purchaseLists.tpl.html')
                },
            },
            data: { pageTitle: 'Project / Purchase Lists', class: 'projects projects-detail-lists' }
        })
    ;
})

.controller('ProjectDetailPurchaseListsCtrl', function ProjectDetailPurchaseListsCtrl($scope, $state, $uibModal, strukshurApiService) {
    var vm = $scope;

    // Check if the user can access the current page or not
    if (!vm.$parent.checkPermission('purchase_list_view')) {
        return $state.go('projects-detail.access-denied', { section: 'Purchases' });
    }

    vm.loading = false;
    vm.errorMessage = '';
    vm.currentUser = {};
    vm.purchaseLists = [];
    vm.processingDelete = false;

    /**
     * Controller init logic
     */
    vm.init = () => {
        vm.updatePageItems();

        // Updates current route info on the project menu scope
        vm.$parent.currentRoute = $state.current.name;

        // Loads project purchase lists
        vm.loading = true;
        strukshurApiService.purchaseLists.list({ project_id: vm.$parent.project.id }).$promise
            .then((res) => {
                vm.loading = false;
                vm.currentUser = vm.$parent.currentUser;
                vm.purchaseLists = res.purchaseLists;
            })
        ;
    };


    /**
     * Checks if the current user has the given permission on the project
     *
     * @param  {String}  permission  The permission key
     *
     * @return {Boolean}
     */
    vm.checkPermission = function (permission) {
        return vm.$parent.checkPermission(permission);
    };

    /**
     * Opens the modal for adding a new purchase list
     */
    vm.newList = function () {
        var modalInstance = $uibModal.open({
            scope: vm,
            keyboard: true,
            controller: 'ProjectDetailNewPurchaseListCtrl',
            template: require('../../../common/templates/projects.newPurchaseListModal.tpl.html'),
            resolve: {
                project: vm.$parent.project
            }
        });

        modalInstance.result.then(

            // Resolved callback
            function (data) {
                vm.purchaseLists.push(data.purchaseList);
                vm.updatePageItems();
            },

            // Rejected callback
            angular.noop
        );
    };

    /**
     * Opens the modal for renaming the received purchase list
     *
     * @param  {object}  list  The list to be renamed
     */
    vm.editList = function (list) {
        var modalInstance = $uibModal.open({
            scope: vm,
            keyboard: true,
            controller: 'ProjectDetailEditPurchaseListCtrl',
            template: require('../../../common/templates/projects.editPurchaseListModal.tpl.html'),
            resolve: {
                list: function () { return list; }
            }
        });

        modalInstance.result.then(

            // Resolved callback
            function (data) {
                list.title = data.purchaseList.title;
                vm.updatePageItems();
            },

            // Rejected callback
            angular.noop
        );
    };

    /**
     * Opens the modal for removing the received purchase list
     *
     * @param  {object}  list  The list to be removed
     */
    vm.removeList = function (list) {
        var $childScope = vm.$new();
        $childScope.project = vm.$parent.project;
        $childScope.purchaseList = list;
        $childScope.title = 'Delete purchase list';
        $childScope.message = 'Are you sure you want to delete the purchase list?';

        var modalInstance = $uibModal.open({
            keyboard: true,
            scope: $childScope,
            controller: 'ProjectDetailDeletePurchaseListCtrl',
            template: require('../../../common/templates/base.confirm-modal.tpl.html')
        });

        modalInstance.result.then(

            // Resolved callback
            function (data) {
                _remove(vm.purchaseLists, list);
                vm.updatePageItems();
            },

            // Rejected callback
            angular.noop
        );
    };

    /**
     * Adds a new item to the received list
     *
     * @param  {object}  list  The list
     */
    vm.addItem = function (list) {
        var modalInstance = $uibModal.open({
            scope: vm,
            keyboard: true,
            controller: 'ProjectDetailNewPurchaseListItemCtrl',
            template: require('../../../common/templates/projects.newPurchaseListItemModal.tpl.html'),
            resolve: {
                purchaseList: function () { return list; }
            }
        });

        modalInstance.result.then(

            // Resolved callback
            function (data) {
                if (data.purchaseListItem) {
                    if (!list.items) {
                        list.items = [];
                    }

                    list.items.push(data.purchaseListItem);
                }
            },

            // Rejected callback
            angular.noop
        );
    };

    /**
     * Moves the received item from the current list to the newly selected one
     *
     * @param  {object}  item  The item to be moved
     */
    vm.moveItem = function (item) {
        //
    };

    /**
     * Opens the modal for removing the received purchase list item
     *
     * @param  {object}  list  The list the item is a part of
     * @param  {object}  item  The list item to be removed
     */
    vm.deleteItem = function (list, item) {
        if (vm.processingDelete) { return; }

        vm.errorMessage = '';
        vm.processingDelete = true;

        strukshurApiService.purchaseListItem
            .delete({ item_id: item.id }).$promise
            .then(function () {
                _remove(list.items, item);
            })
            .catch(function () {
                vm.errorMessage = 'There was an error trying to delete the list item.';
            })
            .finally(function () {
                vm.processingDelete = false;
            })
        ;
    };

    /**
     * Updates the page items to show on the project sidebar
     */
    vm.updatePageItems = function() {
        var items = [];
        vm.purchaseLists.forEach(function (item) {
            items.push({ title: item.title, href: 'list-' + item.id });
        });
        vm.$parent.setPageItems($state.current.name, items);
    };

    vm.init();
})

.controller('ProjectDetailEditPurchaseListCtrl', function ProjectDetailEditPurchaseListCtrl ($scope, $uibModalInstance, list, strukshurApiService) {
    var vm = $scope;

    vm.loading = false;
    vm.list = _clone(list);
    vm.errorMessage = '';

    vm.saveList = function (form) {
        if (form.editListForm.$valid) {
            vm.loading = true;
            vm.errorMessage = '';

            var data = {
                list_id: list.id,
                title: vm.list.title
            };

            strukshurApiService.purchaseList.update(data).$promise
                .then(function (res) {
                    var newList = res.purchaseList;
                    newList.items = [];

                    $uibModalInstance.close({ purchaseList: newList });
                })
                .catch(function (res) {
                    if (res.status === 403) {
                        vm.errorMessage = 'You don\'t have the necessary permission to update the list.';
                    } else {
                        vm.errorMessage = 'There was an error trying to update the list.';
                    }
                })
                .finally(function () {
                    vm.loading = false;
                })
            ;
        }
    };
})

.controller('ProjectDetailNewPurchaseListCtrl', function ProjectDetailNewPurchaseListCtrl ($scope, $uibModalInstance, project, strukshurApiService) {
    var vm = $scope;

    vm.loading = false;
    vm.newList = { title: '' };
    vm.searchTerm = '';
    vm.errorMessage = '';

    vm.addPurchaseList = function (form) {
        if (form.newListForm.$valid) {
            vm.loading = true;
            vm.errorMessage = '';

            var data = {
                project_id: project.id,
                title: vm.newList.title
            };

            strukshurApiService.purchaseList.create(data).$promise
                .then(function (res) {
                    var list = res.purchaseList;

                    if (!list.items) {
                        list.items = [];
                    }

                    $uibModalInstance.close({ purchaseList: list });
                })
                .catch(function (res) {
                    if (res.status === 403) {
                        vm.errorMessage = 'You don\'t have the necessary permission to create the list.';
                    } else {
                        vm.errorMessage = 'There was an error trying to create the list.';
                    }
                })
                .finally(function () {
                    vm.loading = false;
                })
            ;
        }
    };
})

.controller('ProjectDetailDeletePurchaseListCtrl', function ProjectDetailDeletePurchaseListCtrl ($scope, $uibModalInstance, strukshurApiService) {
    var vm = $scope;

    vm.onConfirmChosen = function () {

        // Deletes the purchase list on the server
        vm.loading = true;
        strukshurApiService.purchaseList.delete({ list_id: vm.purchaseList.id }).$promise
            .then(function (res) {
                $uibModalInstance.close();
            })
            .catch(function (res) {
                if (res.status === 403) {
                    vm.errorMessage = 'You don\'t have the necessary permission to delete the list.';
                } else {
                    vm.errorMessage = 'There was an error trying to delete the purchase list.';
                }
            })
            .finally(function () {
                vm.loading = false;
            })
        ;
    };
})

.controller('ProjectDetailNewPurchaseListItemCtrl', function ProjectDetailNewPurchaseListItemCtrl ($scope, $uibModalInstance, purchaseList, strukshurApiService, $uibModal) {
    var vm = $scope;

    vm.image = {};
    vm.imageFail = false;
    vm.loading = false;
    vm.loadingImage = false;
    vm.errorMessage = '';
    vm.upgadeLink = false;
    vm.newItem = { title: '', description: '', price: '' };

    vm.addItem = function (form) {
        var modalInstance, dataUrlImage, data;
        vm.imageFail = false;
        if (form.$valid) {
            if (!vm.image || !vm.image.base64) {
                vm.imageFail = 'You must include an image';
                return;
            }

            dataUrlImage = 'data:' + vm.image.filetype + ';base64,' + vm.image.base64;

            data = {
                list_id: purchaseList.id,
                title: vm.newItem.title,
                image: dataUrlImage,
                description: vm.newItem.description,
                price: vm.newItem.price
            };

            modalInstance = $uibModal.open({
                scope: $scope,
                keyboard: false,
                controller: 'ProjectProgressModalCtrl',
                template: require('../../../common/templates/progress-modal.tpl.html'),
                resolve: {}
            });
            modalInstance.result.then(angular.noop, angular.noop);

            vm.loading = true;
            vm.errorMessage = '';
            vm.upgadeLink = false;

            strukshurApiService.purchaseListItem.create(data).$promise
                .then(function (res) {
                    $uibModalInstance.close({ purchaseListItem: res.purchaseListItem });
                })
                .catch(function (res) {
                    vm.errorMessage = (res.data.message?res.data.message:'');
                    if (res.status === 403) {
                        vm.errorMessage = 'You don\'t have the necessary permission to save the pin.';
                    } else if (vm.errorMessage.indexOf('enough storage') !== -1 && vm.errorMessage.indexOf('Upgrade') !== -1) {
                        vm.upgadeLink = true;
                    } else {
                        vm.errorMessage = 'There was an error trying to save the pin to the room.';
                    }
                })
                .finally(function () {
                    modalInstance.close();
                    vm.loading = false;
                })
            ;
            dataUrlImage = null;
        }
    };

    vm.upgradeSettingsAccount = function () {
        vm.$parent.upgradeSettingsAccount();
    };

    vm.cancel = function() {
        $uibModalInstance.dismiss();
    };

    vm.loadStart = function () {
        vm.imageFail = false;
        vm.loadingImage = true;
    };

    vm.loadEnd = function () {
        vm.imageFail = false;
        vm.loadingImage = false;
    };
    vm.afterValidate = function (event) {
        var dataUrlImage;
        var canvas, MAX_WIDTH, MAX_HEIGHT, width, height, ctx, img;
        MAX_WIDTH = 2048;
        MAX_HEIGHT = 2048;
        vm.loadingImage = false;

        if(vm.image && !vm.image.base64){
            vm.imageFail = 'Invalid Image';
            if(vm.image.filesize>150*1000*1000){
                vm.imageFail = 'Image must be less than 150MB';
            }
            return;
        }

        if (vm.image && vm.image.base64) {

            //compress image
            img = document.createElement('img');
            img.setAttribute('crossOrigin', 'anonymous');

            img.onload = function(ev){
                var resize;
                width = img.width;
                height = img.height;

                if (width > height) {
                    if (width > MAX_WIDTH) {
                        height *= MAX_WIDTH / width;
                        width = MAX_WIDTH;
                    }
                } else {
                    if (height > MAX_HEIGHT) {
                        width *= MAX_HEIGHT / height;
                        height = MAX_HEIGHT;
                    }
                }

                // Resize only if needed
                if (width !== img.width || height !== img.height) {
                    canvas = document.createElement('canvas');
                    canvas.width = width;
                    canvas.height = height;
                    ctx = canvas.getContext('2d');
                    ctx.drawImage(img, 0, 0, width, height);

                    canvas.setAttribute('crossOrigin', 'anonymous');

                    dataUrlImage = canvas.toDataURL(vm.image.filetype);
                    vm.image.base64 = dataUrlImage.substr(dataUrlImage.indexOf(',')+1);
                    dataUrlImage = null;
                    canvas = null;
                    ctx = null;
                }

                //if iOS rotate if needed
                if(isMobile.apple.device || isMobile.apple.ipod || isMobile.apple.phone || isMobile.apple.tablet){
                    resize = document.createElement('img');
                    resize.setAttribute('crossOrigin', 'anonymous');

                    resize.onload = function(event){
                        width = resize.width;
                        height = resize.height;
                        EXIF.getData(img, function(){
                            var orientation = EXIF.getTag(this, "Orientation");
                            canvas = document.createElement('canvas');
                            ctx = canvas.getContext('2d');
                            if(orientation && orientation>4){
                                canvas.width = height;
                                canvas.height = width;
                            }
                            else{
                                canvas.width = width;
                                canvas.height = height;
                            }
                            switch(orientation){
                                case 2:
                                    // horizontal flip
                                    ctx.translate(width, 0);ctx.scale(-1, 1);
                                    break;
                                case 3:
                                    // 180° rotate left
                                    ctx.translate(width, height);ctx.rotate(Math.PI);
                                    break;
                                case 4:
                                    // vertical flip
                                    ctx.translate(0, height);ctx.scale(1, -1);
                                    break;
                                case 5:
                                    // vertical flip + 90 rotate right
                                    ctx.rotate(0.5 * Math.PI);ctx.scale(1, -1);
                                    break;
                                case 6:
                                    // 90° rotate right
                                    ctx.rotate(0.5 * Math.PI);ctx.translate(0, -height);
                                    break;
                                case 7:
                                    // horizontal flip + 90 rotate right
                                    ctx.rotate(0.5 * Math.PI);ctx.translate(width, -height);ctx.scale(-1, 1);
                                    break;
                                case 8:
                                    // 90° rotate left
                                    ctx.rotate(-0.5 * Math.PI);ctx.translate(-width, 0);
                                    break;
                                default:
                                    dataUrlImage = null;
                                    resize = null;
                                    img = null;
                                    canvas = null;
                                    ctx = null;
                                    return;
                            }
                            if(orientation && orientation>4){
                                ctx.drawImage(resize, 0, 0);
                            }
                            else{
                                ctx.drawImage(resize, 0, 0, width, height);
                            }


                            canvas.setAttribute('crossOrigin', 'anonymous');
                            dataUrlImage = canvas.toDataURL('image/jpeg');
                            vm.image.filetype = 'image/jpeg';
                            vm.image.base64 = dataUrlImage.substr(dataUrlImage.indexOf(',')+1);
                            dataUrlImage = null;
                            resize = null;
                            img = null;
                            canvas = null;
                            ctx = null;
                            vm.$apply();
                        });
                    };
                    resize.src = 'data:' + vm.image.filetype + ';base64,' + vm.image.base64;
                }
                else{
                    img = null;
                }
            };
            img.src = 'data:' + vm.image.filetype + ';base64,' + vm.image.base64;
        }
    };
})

;
