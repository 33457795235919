import * as angular from 'angular';

var app = angular.module('strukshurOrganizationService', []);
app.factory('strukshurOrganizationService', function (strukshurApiService, strukshurProjectService) {
    return {

        /**
         * Returns a list of organizations for the current user
         *
         * @return {array}
         */
        getUserOrganizations: () => {
            return strukshurApiService.organizations.list({ with_permissions: true }).$promise;
        },

        /**
         * Returns the list of all available organization permissions on Strukshur
         *
         * @return {array}
         */
        getAllAvailablePermissions: () => {
            return [
                {
                    label: 'Organization permissions',
                    id: 'organization',
                    value: 'organization',
                    children: [
                        { label: 'View organization', id: 'organization_view', value: 'organization_view' },
                        { label: 'Edit organization', id: 'organization_edit', value: 'organization_edit' },
                        {
                            label: 'Organization files',
                            id: 'organization_file_all',
                            value: 'organization_file_all',
                            children: [
                                { label: 'View organization files', id: 'organization_file_view', value: 'organization_file_view' },
                                { label: 'Create organization files', id: 'organization_file_add', value: 'organization_file_add' },
                                { label: 'Edit organization files ', id: 'organization_file_edit', value: 'organization_file_edit' },
                                { label: 'Delete organization files ', id: 'organization_file_delete', value: 'organization_file_delete' },
                            ]
                        },
                        {
                            label: 'Organization members',
                            id: 'organization_member_all',
                            value: 'organization_member_all',
                            children: [
                                { label: 'View organization members', id: 'organization_member_view', value: 'organization_member_view' },
                                { label: 'Invite organization members', id: 'organization_member_invite', value: 'organization_member_invite' },
                                { label: 'Edit organization members ', id: 'organization_member_edit', value: 'organization_member_edit' },
                                { label: 'Delete organization members ', id: 'organization_member_delete', value: 'organization_member_delete' },
                            ]
                        },
                        {
                            label: 'Organization projects',
                            id: 'organization_project_all',
                            value: 'organization_project_all',
                            children: [
                                { label: 'View organization projects', id: 'organization_project_view', value: 'organization_project_view' },
                                { label: 'Create organization projects', id: 'organization_project_add', value: 'organization_project_add' },
                                { label: 'Edit organization projects ', id: 'organization_project_edit', value: 'organization_project_edit' },
                                { label: 'Delete organization projects ', id: 'organization_project_delete', value: 'organization_project_delete' },
                            ]
                        }
                    ]
                }, {
                    label: 'Project permissions',
                    id: 'project_all',
                    value: 'project_all',
                    children: strukshurProjectService.getAllAvailablePermissions()
                }
            ];
        },
    };
});
