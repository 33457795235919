import * as angular from 'angular';

angular.module('strukshurApp.adminOrders', ['ui.router', 'ui.bootstrap'])

.config(function config($stateProvider) {
    $stateProvider
        .state( 'admin.orders', {
            url: '/orders/list?:status&:customer&:order_id',
            params: {
                page: null
            },
            views: {
                "admin_middle_view": {
                    controller: 'ProductAdminOrdersCtrl',
                    template: require('../productAdmin/orders/admin_orders.tpl.html')
                }
            },
            data: { pageTitle: 'Orders: List' }
        })
        .state( 'admin.order', {
            url: '/order/:suborder_id/details',
            views: {
                "admin_middle_view": {
                    controller: 'ProductAdminOrderDetailCtrl',
                    template: require('../productAdmin/orders/admin_order_details.tpl.html')
                }
            },
            data: { pageTitle: 'Order: Detail' }
        })
    ;
})

;
