import * as angular from 'angular';

angular.module('TimezoneService', [])

.factory('TimezoneService', ['$http', 'strukshurApiService', '$q', 'storage',  function($http, strukshurApiService, $q, storage) {

    var timezones = storage.get('timezones');
    if(!timezones){
        timezones = [];
    }


    return {
        getTimezones: function() {
            var deferred = $q.defer();

            if (timezones.length>0){
                deferred.resolve(timezones);
            } else {
                strukshurApiService.timezones.list({}, function (d) {
                    timezones = d.timezones;
                    storage.set('timezones', angular.copy(timezones));
                    deferred.resolve(timezones);
                }, function (e) {
                    deferred.reject(e);
                });
            }

            return deferred.promise;
        },

        getTimezone: function (value) {
            var i, tot;

            tot = timezones.length;
            for (i=0;i<tot;i++) {
                if (timezones[i].value === value) {
                    return timezones[i];
                }
            }

            return '';
        }
    };
}]);
