import * as angular from 'angular';

angular.module('strukshurApp.adminEstimateCategories', ['ui.router', 'ui.bootstrap', 'strukshurApp.admin'])

.config(function config($stateProvider) {
    $stateProvider

    .state('admin.estimate-categories', {
        url: '/estimate-categories',
        params: {
            page: null
        },
        views: {
            'admin_middle_view': {
                controller: 'AdminEstimateCategoriesCtrl',
                template: require('./estimate-categories/admin_categories.tpl.html')
            }
        },
        data: { pageTitle: 'Admin Estimate Categories: List' }
    })

    .state('admin.estimate-category-add', {
        url: '/estimate-category/add',
        params: {
            page: null
        },
        views: {
            'admin_middle_view': {
                controller: 'AdminEstimateCategoryAddCtrl',
                template: require('./estimate-categories/admin_category_add.tpl.html')
            }
        },
        data: { pageTitle: 'Admin Estimate Category: Add' }
    })

    .state('admin.estimate-category', {
        url: '/estimate-category/:id',
        views: {
            'admin_middle_view': {
                controller: 'AdminEstimateCategoryDetailCtrl',
                template: require('./estimate-categories/admin_category_detail.tpl.html')
            }
        },
        data: { pageTitle: 'Admin Estimate Category: Detail' }
    })

    .state('admin.estimate-category-edit', {
        url: '/estimate-category/:id/edit',
        views: {
            'admin_middle_view': {
                controller: 'AdminEstimateCategoryEditCtrl',
                template: require('./estimate-categories/admin_category_edit.tpl.html')
            }
        },
        resolve: {
            categoryResolve: function ($q, $state, $stateParams, strukshurApiService) {
                var deferred = $q.defer();

                strukshurApiService.adminEstimateCategory.get({ id: $stateParams.id }, function (d) {
                    if (d === undefined) {
                        deferred.reject('Category not found.');
                        $state.go('admin.estimate-categories');
                    } else {
                        deferred.resolve(d.estimateCategory);
                    }
                }, function(e) {
                    deferred.reject('Category not found.');
                    $state.go('admin.estimate-categories');
                });

                return deferred.promise;
            }
        },
        data: { pageTitle: 'Admin Estimate Category: Edit' }
    })
    ;
})

.controller('AdminEstimateCategoriesCtrl', function AdminEstimateCategoriesCtrl($scope, $state, strukshurApiService, AdminPage) {
    $scope.perPage = 5;
    $scope.page = 1;
    $scope.total = 0;
    $scope.products = [];

    function fetchCategories() {
        strukshurApiService.adminEstimateCategories.list({ page: $scope.page }, function(d) {
            $scope.total = d.total;
            $scope.perPage = d.perPage;
            $scope.categories = d.categories;
            $scope.totalPages = $scope.calculateTotalPages();
        }, function(e) {
            return false;
        });
    }
    fetchCategories();

    $scope.selectPage = function(page) {
        if (page > 0 && (page <= $scope.totalPages)) {
            $scope.page = AdminPage.setPage(page);
            fetchCategories();
        }
    };

    $scope.noNext = function() {
        return Math.ceil($scope.total / $scope.perPage) <= $scope.page;
    };

    $scope.noPrevious = function() {
        return 1 >= $scope.page;
    };

    $scope.calculateTotalPages = function() {
        var totalPages = $scope.perPage < 1 ? 1 : Math.ceil($scope.total / $scope.perPage);
        return Math.max(totalPages || 0, 1);
    };
})

.controller('AdminEstimateCategoryDetailCtrl', function AdminEstimateCategoryDetailCtrl($scope, $state, $stateParams, strukshurApiService, AdminPage)  {
    strukshurApiService.adminEstimateCategory.get({ id: $stateParams.id }, function(d) {
        $scope.category = d.estimateCategory;
    });

    $scope.edit = function() {
        $state.go('admin.estimate-category-edit', { id: $stateParams.id });
    };
})

.controller('AdminEstimateCategoryAddCtrl', function AdminEstimateCategoryAddCtrl($scope, $q, $state, strukshurApiService, strukshurAdminService, fileReaderService) {
    $scope.loading = false;
    $scope.formError = false;
    $scope.imgs = [];

    // very important
    $scope.form = {};
    $scope.status = {
        name: 'active'
    };

    $scope.cancel = function () {
        $state.go('admin.categories');
    };

    $scope.getFile = function (el) {
        var elementId = el[0].id;
        $scope.$apply();
        fileReaderService.readAsDataUrl($scope.file, $scope)
            .then(function(result) {
                $scope.imgs[elementId] = { 'imageSrc': result, 'imageSrcLoading': false };
                //$scope.imageSrcLoading = false;
            })
        ;
    };

    $scope.categoryAdd = function(myform) {
        $scope.loading = true;
        $scope.formError = false;
        if (!myform.$valid) {
            return;
        }

        var fileBase64Logo = '';
        if (myform.img) {
            fileBase64Logo = myform.img.$viewValue.base64;
        }

        var formData = {
            title: myform.title.$viewValue,
            status: myform.status.$viewValue,
            img: fileBase64Logo
        };

        strukshurApiService.postAdminCategoryAdd.save(formData, function(data) {
            $scope.loading = false;
            console.log('promise Success');
            console.log(data);
            $scope.formError = false;
            $state.go('admin.categories');
        }, function(e) {
            console.log('categoryAdd Failed');
            console.log(e);
            $scope.formError = true;
            console.log('promise Failed:');
            $scope.loading = false;
        });
    };
})

.controller('AdminEstimateCategoryEditCtrl', function AdminEstimateCategoryEditCtrl($scope, $state, $q, $stateParams, categoryResolve, strukshurAdminService, strukshurApiService, fileReaderService) {
    $scope.loading = false;
    $scope.formError = false;
    $scope.imgs = [];
    $scope.form = {};
    $scope.category = categoryResolve;

    $scope.imgs['img'] = {'imageSrc': categoryResolve.img?categoryResolve.img:('public/images/store-category-' + categoryResolve.slug + '.png'), 'imageSrcLoading': false};

    $scope.categoryStatus = {
        status: categoryResolve.active === false ? 'hidden' : 'active'
    };

    $scope.cancel = function () {
        $state.go('admin.estimate-category', {id: $scope.category.id});
    };

    $scope.getFile = function (el) {
        var elementId = el[0].id;
        $scope.$apply();
        fileReaderService.readAsDataUrl($scope.file, $scope)
            .then(function(result) {
                $scope.imgs[elementId] = {'imageSrc': result, 'imageSrcLoading': false};
            })
        ;
    };

    $scope.categoryEdit = function(myform) {
        $scope.loading = true;
        $scope.formError = false;

        myform['id'] = categoryResolve.id;
        myform['status'] = $scope.categoryStatus.status;

        if (!myform.$valid) {
            console.log('categoryEdit Form Error');
            console.log(myform);
            $scope.formError = true;

            return;
        }

        var fileBase64Logo = '';
        if (myform.img) {
            fileBase64Logo = myform.img.$viewValue.base64;
        }

        var formData = {
            id: myform.id,
            title: myform.title.$viewValue,
            status: myform.status,
            img: fileBase64Logo
        };

        strukshurApiService.postAdminCategoryEdit.save(formData, function(data) {
            console.log('postAdminCategoryEdit Success');
            console.log(data);
            $scope.loading = false;
            console.log('promise Success');
            console.log(data);
            $scope.formError = false;
            $state.go('admin.estimate-category', { id: $scope.category.id });
        }, function(e) {
            console.log('postAdminCategoryEdit Failed');
            console.log(e);
            console.log('promise Failed:');
            $scope.formError = true;
            $scope.loading = false;
        });

    };
})
;
