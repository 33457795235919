import * as angular from 'angular';

angular.module( 'strukshurApp.productAdminDeliveries', ['ui.router', 'ui.bootstrap' ])

    .config(function config( $stateProvider ) {
        $stateProvider

            .state( 'productAdmin.deliveries', {
                url: '/delivery/list?:city&:state',
                params: {
                    page: null
                },
                views: {
                    "productAdmin_middle_view": {
                        controller: 'AdminDeliveriesCtrl',
                        template: require('./admin_deliveries.tpl.html')
                    }
                },
                data: { pageTitle: 'Delivery Prices: List' }
            })
            .state( 'productAdmin.delivery-add', {
                url: '/delivery/:vendor_id/add',
                views: {
                    "productAdmin_middle_view": {
                        controller: 'AdminDeliveryCtrl',
                        template: require('./admin_delivery.tpl.html')
                    }
                },
                data: { pageTitle: 'Delivery: Add' },
                resolve: {
                    states: function (StateService) {
                        return StateService.getStates().then(function (d) {
                            return d;
                        });
                    }
                }
            })
            .state( 'productAdmin.delivery-edit', {
                url: '/delivery/:vendor_id/:delivery_id/edit',
                views: {
                    "productAdmin_middle_view": {
                        controller: 'AdminDeliveryCtrl',
                        template: require('./admin_delivery.tpl.html')
                    }
                },
                data: { pageTitle: 'Delivery: Add' },
                resolve: {
                    states: function (StateService) {
                        return StateService.getStates().then(function (d) {
                            return d;
                        });
                    }
                }
            })
        ;
    })

    .controller('AdminDeliveriesCtrl', function AdminDeliveriesCtrl( $scope, $state, $stateParams, $uibModal, strukshurApiService, AdminPage, strukshurUserService) {

        $scope.perPage = 5;
        $scope.page = 1;
        $scope.total = 0;
        $scope.deliveryPrices = [];
        $scope.filter = {vendor_id:false};
        $scope.vendors = [];
        $scope.terms = {
            city: $stateParams.city?$stateParams.city:'',
            state: $stateParams.state?$stateParams.state:''
        };
        $scope.currentUser = {};
        $scope.isAdmin = false;
        strukshurUserService.getUser().then(function (d) {
            $scope.currentUser = d;
            $scope.isAdmin = strukshurUserService.isAdmin();
            if(d.vendors && d.vendors.length>0){
                $scope.filter.vendor_id = d.vendors[0].id;
            }
            if($scope.isAdmin){
                strukshurApiService.getAdminVendors.query({page: $scope.page}, function(d) {
                    $scope.vendors = d.vendors;
                    if(!$scope.filter.vendor_id){
                        $scope.filter.vendor_id = String($scope.vendors[0].id);
                    }
                    $scope.fetchDeliveries();
                });
            }
            else{
                $scope.fetchDeliveries();
            }

        });

        $scope.formFetchDeliveries = function () {
            if($scope.isAdmin){
                $state.go('admin.deliveries', {city: $scope.terms.city, state: $scope.terms.state});
            }
            else{
                $state.go('productAdmin.deliveries', {city: $scope.terms.city, state: $scope.terms.state});
            }
        };

        $scope.fetchDeliveries = function() {
            strukshurApiService.getDeliveryPrices.list({page: $scope.page, terms: JSON.stringify($scope.terms), vendor_id: $scope.filter.vendor_id}, function(d) {
                $scope.total = d.total;
                $scope.perPage = d.perPage;
                $scope.deliveryPrices = d.deliveryPrices;

                //$scope.page++;
                $scope.totalPages = $scope.calculateTotalPages();

            }, function(e) {

                return false;
            });
        };


        $scope.selectPage = function(page) {
            if (page > 0 && (page <= $scope.totalPages)) {
                $scope.page = AdminPage.setPage(page);
                $scope.fetchDeliveries();
            }
        };

        $scope.noNext = function() {
            return Math.ceil($scope.total / $scope.perPage) <= $scope.page;
        };

        $scope.noPrevious = function() {
            return 1 >= $scope.page;
        };

        $scope.calculateTotalPages = function() {
            var totalPages = $scope.perPage < 1 ? 1 : Math.ceil($scope.total / $scope.perPage);
            return Math.max(totalPages || 0, 1);
        };

        $scope.deleteDelivery = function (item) {
            $scope.confirmMessage = false;
            $scope.successMessage = false;
            var modalInstance = $uibModal.open({
                animation: true,
                keyboard: true,
                scope: $scope,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                controller: 'ConfirmDeliveryDeleteCtrl',
                template: require('../../../common/templates/confirm-modal.tpl.html'),
                resolve: {
                    deliveryPrice: function () {
                        return item;
                    },
                    message: function () {
                        return 'Are you sure?';
                    }
                }
            });
            modalInstance.result.then(function (selectedItem) {
                var tot, i;
                //remove from list
                $scope.total = $scope.total-1;
                tot = $scope.deliveryPrices.length;
                for(i=0;i<tot;i++){
                    if($scope.deliveryPrices[i].id===selectedItem.id){
                        $scope.deliveryPrices.splice(i, 1);
                        break;
                    }
                }
                $scope.successMessage = 'Delivery Price removed';
            }, function () {
                //cancel confirmation
            });
        };
    })

    .controller( 'AdminDeliveryCtrl', function AdminDeliveryCtrl( $scope, $state, $stateParams, strukshurApiService, strukshurUserService, states, StateService ) {

        $scope.delivery_id = $stateParams.delivery_id;
        $scope.states = states;
        $scope.cities = [];
        $scope.confirmMessage = false;
        $scope.currentUser = {};
        $scope.deliveryPrice = {};
        $scope.form = {};
        $scope.formData = {};
        $scope.isAdmin = false;
        $scope.vendor_id = $stateParams.vendor_id;
        $scope.hasReturn = false;
        strukshurUserService.getUser().then(function (d) {
            $scope.currentUser = d;
            $scope.isAdmin = strukshurUserService.isAdmin();
            if(d.vendors && d.vendors.length>0){
                if(!$scope.delivery_id && $scope.currentUser.shipping_state){
                    $scope.formData.state = $scope.currentUser.shipping_state;
                    $scope.getCitiesState($scope.formData.state);
                }
            }
        });

        $scope.errorMessage = false;
        $scope.loading = false;
        //timeout chat sync
        $scope.accessDennied = false;

        $scope.saveDelivery = function () {
            if($scope.loading){
                return;
            }
            if($scope.form.$invalid){
                return;
            }
            $scope.loading = true;
            if($scope.delivery_id){
                $scope.formData.delivery_id = $scope.delivery_id;
                strukshurApiService.deliveryPriceSave.update(angular.copy($scope.formData), function(d) {
                    $scope.deliveryPrice = d.deliveryPrice;
                    $scope.loading = false;
                    if($scope.isAdmin){
                        $state.go('admin.deliveries');
                    }
                    else{
                        $state.go('productAdmin.deliveries');
                    }
                }, function(e) {
                    $scope.loading = false;
                    if(e.status===403){
                        $scope.accessDennied = true;
                    }
                    return false;
                });
            }
            else{
                $scope.formData.vendor_id = $scope.vendor_id;
                strukshurApiService.deliveryPriceSave.create(angular.copy($scope.formData), function(d) {
                    $scope.deliveryPrice = d.deliveryPrice;
                    $scope.delivery_id = d.deliveryPrice.id;
                    $scope.loading = false;
                    if($scope.isAdmin){
                        $state.go('admin.deliveries');
                    }
                    else{
                        $state.go('productAdmin.deliveries');
                    }
                }, function(e) {
                    $scope.loading = false;
                    if(e.status===403){
                        $scope.accessDennied = true;
                    }
                    return false;
                });
            }
        };

        $scope.getCitiesState = function (state) {
            var used_cities;
            $scope.cities = [];
            strukshurApiService.getCitiesUsed.list({state: state, vendor_id: $scope.vendor_id}, function (d) {
                used_cities = d.cities;
                StateService.getCities(state).then(function (d) {
                    var i, tot, i2, tot2, has_item;
                    tot = d.length;
                    tot2 = used_cities.length;
                    for(i=0;i<tot;i++){
                        has_item = false;
                        for(i2=0;i2<tot2;i2++){
                            if(used_cities[i2]===d[i]){
                                has_item = true;
                                used_cities.splice(i2, 1);
                                break;
                            }
                        }
                        if(!has_item){
                            $scope.cities.push(d[i]);
                        }
                    }

                    // re add current city
                    if ($scope.delivery_id && $scope.deliveryPrice.state === state) {
                        $scope.cities.push($scope.deliveryPrice.city);
                    }
                });
            });
        };

        $scope.cancel = function () {
            if ($scope.isAdmin) {
                $state.go('admin.deliveries');
            } else {
                $state.go('productAdmin.deliveries');
            }
        };

        if ($scope.delivery_id) {
            strukshurApiService.getDeliveryPrice.query({ delivery_id: $scope.delivery_id }, function (d) {
                $scope.deliveryPrice = d.deliveryPrice;
                $scope.formData.state = $scope.deliveryPrice.state;
                $scope.formData.city = $scope.deliveryPrice.city;
                $scope.formData.miles = $scope.deliveryPrice.miles;
                $scope.formData.dumpTruck = $scope.deliveryPrice.dumpTruck;
                $scope.formData.surchargeDump = $scope.deliveryPrice.surchargeDump;
                $scope.formData.flatBed = $scope.deliveryPrice.flatBed;
                $scope.formData.surchargeFlat = $scope.deliveryPrice.surchargeFlat;
                $scope.getCitiesState($scope.formData.state);
            }, function (e) {
                if (e.status === 403) {
                    $scope.accessDennied = true;
                }

                return false;
            });
        }
    })

    .controller( 'ConfirmDeliveryDeleteCtrl', function ConfirmDeliveryDeleteCtrl( $scope, $uibModalInstance, strukshurApiService, message, deliveryPrice ) {
        $scope.loading = false;
        $scope.errorMessage = false;
        $scope.deliveryPrice = deliveryPrice;
        $scope.title = 'Confirm';
        $scope.message = message;
        $scope.confirm = function () {
            if ($scope.loading) { return; }

            var data = {
                id: $scope.deliveryPrice.id
            };

            $scope.loading = true;
            $scope.errorMessage = false;
            strukshurApiService.deliveryPriceSave.remove(data, function (d) {
                $uibModalInstance.close(deliveryPrice);
            }, function (e) {
                $scope.loading = false;
            });
        };

        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    })
;
