import { find as _find } from 'lodash';
import * as angular from 'angular';
import * as isMobile from 'ismobilejs';
import * as EXIF from 'exif-js';

angular.module('strukshurApp.pros', ['ui.router', 'ui.bootstrap'])
    .config(function config($stateProvider) {
        $stateProvider
            .state('pros-home', {
                url: '/pros?category&zip&radius&page&terms&is_other',
                views: {
                    'main': {
                        controller: 'ProsHomeCtrl',
                        template: require('./pros.home.tpl.html')
                    }
                },
                resolve: {
                    userResolve: function (userRouterResolveService, userResolveService) {
                        userRouterResolveService.userMustBeLoggedin();

                        return userResolveService();
                    }
                },
                data: { pageTitle: 'Pros', class: 'pros-home' }
            })

            .state('pro-bids', {
                url: '/pro/bids',
                views: {
                    'main': {
                        controller: 'ProBidsCtrl',
                        template: require('./pros.bids.tpl.html')
                    }
                },
                resolve: {
                    userResolve: function (userRouterResolveService) {
                        return userRouterResolveService.userMustBePro();
                    },
                    proResolve: function ($q, $state, strukshurUserService) {
                        var deferred = $q.defer();

                        return strukshurUserService.getUser().then(function(d) {

                            // prevent access to user's profile page until pro paid account is active
                            if (strukshurUserService.isMissingPayment()) {
                                $state.go('pro-register-payment');

                                return false;
                            } else {
                                deferred.resolve(d);
                            }

                            return deferred.promise;
                        }, function(e) {
                            $state.go('home');

                            return false;
                        });
                    }
                },
                data: { pageTitle: 'Bids', class: 'bids' }
            })

            .state('pro-setup', {
                url: '/pro/setup',
                views: {
                    'main': {
                        controller: 'ProsSetupCtrl',
                        template: require('./pros.setup.tpl.html')
                    }
                },
                resolve: {
                    userResolve: function (userRouterResolveService) {
                        return userRouterResolveService.userMustBePro();
                    }
                },
                data: { pageTitle: 'Pro - Setup', class: 'pros-setup' }
            })

            .state('pro-stripe', {
                url: '/pro/stripe?:scope&:code&:error&:error_description',
                views: {
                    'main': {
                        controller: 'ProsStripeCtrl',
                        template: require('./pros.stripe.complete.tpl.html')
                    }
                },
                resolve: {
                    userResolve: function (userRouterResolveService) {
                        return userRouterResolveService.userMustBePro();
                    }
                },
                data: { pageTitle: 'Pro - Stripe', class: 'pros-setup' }
            })

            .state('pros-profile', {
                url: '/pros/:userSlug',
                views: {
                    'main': {
                        controller: 'ProsProfileCtrl',
                        template: require('./pros.profile.tpl.html')
                    }
                },
                resolve: {
                    proResolve: function ($q, $state, $stateParams, strukshurApiService, strukshurUserService, InitService) {
                        var deferred = $q.defer();

                        return strukshurUserService.getUser().then(function(d){
                            // prevent access to user's profile page until pro paid account is active
                            if (strukshurUserService.isSelf($stateParams.userSlug) && strukshurUserService.isPro() === false) {
                                //deferred.reject('Pro not activated yet.');
                                $state.go('pro-register-payment');

                                return false;
                            } else {
                                strukshurApiService.proDetails.query({ 'user_slug': $stateParams.userSlug }, function (d) {
                                    deferred.resolve(d);
                                }, function(e){
                                    //deferred.reject('Pro not found.');
                                    $state.go('home');

                                    return false;
                                });
                            }

                            return deferred.promise;
                        }, function(e) {
                            $state.go('home');

                            return false;
                        });
                    }
                },
                data: { pageTitle: 'Pros', class: 'pros-profile' }
            })

            .state('pros-profile-confirmation', {
                url: '/pros/:userSlug/confirmation',
                views: {
                    'main': {
                        controller: 'ProsProfileCtrl',
                        template: require('./pros.profile.tpl.html')
                    }
                },
                resolve: {
                    proResolve: function ($q, $state, $stateParams, strukshurApiService, strukshurUserService, InitService) {
                        var deferred = $q.defer();

                        return strukshurUserService.getUser().then(function(d){

                            // prevent access to user's profile page until pro paid account is active
                            if (strukshurUserService.isSelf($stateParams.userSlug) && strukshurUserService.isPro() === false) {
                                //deferred.reject('Pro not activated yet.');
                                $state.go('pro-register-payment');

                                return false;
                            } else {
                                strukshurApiService.proDetails.query({ 'user_slug': $stateParams.userSlug }, function(d) {
                                    deferred.resolve(d);
                                }, function(e) {
                                    //deferred.reject('Pro not found.');
                                    $state.go('home');

                                    return false;
                                });
                            }

                            return deferred.promise;
                        }, function(e){
                            $state.go('home');

                            return false;
                        });
                    }
                },
                data: { pageTitle: 'Pros', class: 'pros-profile' }
            })

            .state('pros-profile.gallery', {
                url: '/gallery',
                views: {
                    'profile_view': {
                        controller: 'ProsGalleryCtrl',
                        template: require('./pros.profile.gallery.tpl.html')
                    }
                },
                resolve: {
                    proGResolve: function (proResolve) {
                        return proResolve;
                    }
                },
                data: { pageTitle: 'Pro Gallery' }
            })

            .state('pros-profile.reviews', {
                url: '/reviews',
                views: {
                    'profile_view': {
                        controller: 'ProsReviewsCtrl',
                        template: require('./pros.profile.reviews.tpl.html')
                    }
                },
                data: { pageTitle: 'Pro Reviews' }
            })

            .state('pros-profile.activity', {
                url: '/activity',
                views: {
                    'profile_view': {
                        controller: 'ProsActivityCtrl',
                        template: require('./pros.profile.activity.tpl.html')
                    }
                },
                data: { pageTitle: 'Pro Activity' }
            })

            .state('pros-profile.careers', {
                url: '/careers',
                views: {
                    'profile_view': {
                        controller: 'ProsCareersCtrl',
                        template: require('./pros.profile.careers.tpl.html')
                    }
                },
                data: { pageTitle: 'Pro Careers' }
            })
        ;
    })

    .factory('galleryService', function ($q, strukshurApiService) {
        var images = [];
        var imageNames = [];

        return {
            clearImages: function() {
                images = [];
                imageNames = [];
            },

            setImages: function(galleryImages) {
                angular.forEach(galleryImages, function(value, key) {
                    images.push(value);
                    imageNames.push(value.image);
                });

                return images;
            },

            isDuplicate: function (fileName) {
                var duplicate = false;
                for (let i = 0; i < imageNames.length; i++) {
                    if (imageNames[i] === fileName) {
                        duplicate = true;
                    }
                }

                return duplicate;
            },

            addImage: function(result64, galleryImage, size) {
                var isVideo = (result64.indexOf('data:video') === 0);
                imageNames.push(galleryImage); // to prevent dups

                images.push({image: result64, isVideo: isVideo, size: size, new_gallery: true}); // to show in modal
            },

            removeImage: function (index) {
                var i;
                console.log('going to remove ' + index);

                for (i = 0; i < images.length; i++) {
                    if (i === index) {
                        images.splice(index, 1);
                        imageNames.splice(index, 1);
                    }
                }
            }
        };
    })

    .controller('ProsHomeCtrl', function ProsHomeCtrl ($scope, $state, $stateParams, smoothScroll, strukshurApiService, ContractorRoleService) {
        var vm = $scope;

        vm.search = {
            zip: $stateParams.zip || null,
            page: $stateParams.page || 1,
            keyword: $stateParams.terms || '',
            radius: $stateParams.radius || '',
            category: $stateParams.category || ''
        };
        vm.total = 999;
        vm.formData = {};
        vm.proUsers = [];
        vm.loading = false;
        vm.availableRoles = [];
        vm.show_categories = false;
        vm.currentPage = vm.search.page - 1;
        vm.categoryName = decodeURI(vm.search.category).toLowerCase();
        vm.isOtherCategory = ($stateParams.is_other && $stateParams.is_other === 'y');

        // Set the main categories to display on the nav menu
        vm.categories = [
            { id: '', name: 'All', icon: 'fa fa-globe', isOther: false },
            { id: 'ELECTRICIAN', name: 'Electrician', icon: 'fa fa-plug', isOther: false },
            { id: 'GENERAL CONTRACTOR', name: 'General Contractor', icon: 'fa fa-home', isOther: false },
            { id: 'HOMEBUILDER', name: 'Homebuilder', icon: 'fa fa-home', isOther: false },
            { id: 'HOUSE CLEANER', name: 'House Cleaner', icon: 'fa fa-shower', isOther: false },
            { id: 'LANDSCAPER/INSTALLER', name: 'Landscaper', icon: 'fa fa-picture-o', isOther: false },
            { id: 'MOVER', name: 'Mover', icon: 'fa fa-truck', isOther: false },
            { id: 'PAINTER', name: 'Painter', icon: 'fa fa-paint-brush', isOther: false },
            { id: 'PLUMBER', name: 'Plumber', icon: 'fa fa-bath', isOther: false },
            { id: 'TREE CONTRACTOR', name: 'Tree Contractor', icon: 'fa fa-tree', isOther: false }
        ];

        // Retrieves the remaining pro categories to display in the other option
        ContractorRoleService.getRoles().then(function (d) {
            var i, tot, i2, tot2, found;
            tot = d.length;
            tot2 = vm.categories.length;
            for (i = 0; i < tot; i++) {
                found = false;
                for (i2 = 0; i2 < tot2; i2++) {
                    if (vm.categories[i2].id === d[i].name) {
                        found = true;
                        break;
                    }
                }

                if (!found) {
                    d[i].isOther = true;
                    vm.availableRoles.push(d[i]);
                }
            }

            // Handles selection of other categories on mobile
            if (vm.isOtherCategory) {
                vm.search.category = 'other';
                document.getElementById('category').setAttribute('category', 'other');
                vm.formData.category = _find(vm.availableRoles, { name: decodeURI($stateParams.category) });
            }
        }, function (e) {
            console.log('Unknown error:', e);
        });

        /**
         * Triggers the initial logic when the page is rendered
         */
        vm.initPage = function () {

            // Scrolls page to top in order to improve UX on big lists
            var element = document.getElementById('top');
            smoothScroll(element);

            // Triggers initial search for the selected search criteria
            vm.searchPros();
        };

        /**
         * Clears the search filter fields
         */
        vm.clearSearchFields = function () {
            vm.search.zip = null;
            vm.search.radius = '';
            vm.search.keyword = '';
            vm.search.category = '';

            // Triggers a new search after clearing the fields
            vm.filterAll();
        };

        vm.showCategories = function () {
            vm.show_categories = true;
            vm.$broadcast('SetFocus');
        };

        /**
         * Triggers a page refresh by the category selected by the user
         */
        vm.filterCategory = function () {
            $state.go('pros-home', { category: vm.formData.category.name, zip: vm.search.zip, radius: vm.search.radius, terms: vm.search.keyword, is_other: (vm.formData.category.isOther) ? 'y' : 'n' });
        };

        /**
         * Searches for pros on the backend based on the selected criteria
         */
        vm.searchPros = function () {

            // Does not trigger a server search if all current results has already
            // been retrieved
            if (vm.proUsers.length >= vm.total) { return; }

            // Does not trigger a new search if one is still in progress
            if (vm.loading) { return; }

            // Searches for pros on the backend
            vm.loading = true;
            vm.currentPage += 1;
            strukshurApiService.proFind.query({ terms: vm.search.keyword, category: vm.search.category, zip: vm.search.zip, radius: vm.search.radius, page: vm.currentPage }).$promise
                .then(function (res) {
                    vm.total = res.total;
                    res.results.forEach(function (result) {
                        vm.proUsers.push(result);
                    });
                })
                .finally(function () {
                    vm.loading = false;
                })
            ;
        };

        /**
         * Triggers a page refresh to filter the pros list by all selected filters
         */
        vm.filterAll = function () {
            var search_category = '';
            if (vm.search.category === 'other' && vm.formData.category && vm.formData.category.name) {
                search_category = vm.formData.category.name;
            } else if (vm.search.category !== 'other') {
                search_category = vm.search.category;
            }

            $state.go('pros-home', { category: search_category, zip: vm.search.zip, radius: vm.search.radius, terms: vm.search.keyword, is_other: (vm.search.category === 'other') ? 'y' : 'n' });
        };

        // Triggers the initial logic when the page is rendered
        vm.initPage();
    })

    .controller('ProsSetupCtrl', function ProsSetupCtrl ($scope, userResolve) {
        $scope.user = userResolve;
    })

    .controller('ProsStripeCtrl', function ProsStripeCtrl ($scope, userResolve, $stateParams, strukshurApiService, $rootScope, strukshurUserService) {
        $scope.user = userResolve;
        $scope.error = $stateParams.error;
        $scope.error_description = $stateParams.error_description;
        $scope.code = $stateParams.code;
        $scope.loading = false;

        if (!$scope.error) {
            $scope.loading = true;
            strukshurApiService.stripeEnrollment.save({code: $scope.code}, function (d) {
                $scope.loading = false;
                $rootScope.$broadcast('userUpdate', d.user);
                strukshurUserService.setUserObj(d.user);
            }, function (e) {
                $scope.loading = false;
                $scope.code = false;
                $scope.error = true;
                $scope.error_description = e.data.message;
            });
        }
    })

    .controller('ProBidsCtrl', function ProBidsCtrl ($scope, $state, strukshurApiService, strukshurUserService, userResolve) {
        var vm = $scope;

        vm.projects = [];
        vm.loading = true;
        vm.currentUser = userResolve;
        vm.availableRoles = [];
        vm.filters = { role: null, constructionType: null };

        // Sets construction types
        vm.constructionTypes = [
            { id: 'REMODEL', name: 'Remodel / Renovation' },
            { id: 'NEW_CONSTRUCTION', name: 'New Construction' },
            { id: 'GENERAL_LABOR', name: 'General Labor' },
        ];

        // Loads available roles
        strukshurApiService.searchContractorRoles.list().$promise
            .then(function (res) {
                res.roles.unshift({ id: -1, name: 'ALL' });
                vm.availableRoles = res.roles;

                // Triggers initial search for open for bids projects
                vm.searchProjects();
            })
        ;

        /**
         * Returns the friendly construction type name for the given project
         *
         * @param  {Object}  project  The project
         *
         * @return {String}
         */
        vm.getConstructionTypeName = function (project) {
            if (project.projectConstructionTypeForBids === 'REMODEL') {
                return 'Remodel / Renovation';
            } else if (project.projectConstructionTypeForBids === 'NEW_CONSTRUCTION') {
                return 'New Construction';
            } else if (project.projectConstructionTypeForBids === 'GENERAL_LABOR') {
                return 'General Labor';
            }
        };

        /**
         * Event fired after the user selected a role in the filters sidebar
         *
         * @param  {Object}  role  The role
         */
        vm.roleSelected = function (role) {
            vm.searchProjects();
        };

        /**
         * Event fired after the user selected a construction type in the filters sidebar
         *
         * @param  {Object}  type  The type
         */
        vm.constructionTypeSelected = function (type) {
            vm.searchProjects();
        };

        /**
         * Searches for the projects that are open for bid based on the current filters
         */
        vm.searchProjects = function () {
            var filters: any = {};

            if (vm.filters.role && vm.filters.role.id > 0) {
                filters.role_id = vm.filters.role.id;
            }

            if (vm.filters.constructionType && vm.filters.constructionType.id !== '') {
                filters.construction_type = vm.filters.constructionType.id;
            }

            strukshurApiService.projectsNear.query(filters).$promise
                .then(function (res) {
                    vm.loading = false;
                    vm.projects = [];

                    res.projects.forEach(function (project) {

                        // Adds the contractor roles objects to the project
                        project.bidRoles = [];
                        project.projectRolesForBids.split(',').forEach(function (roleId) {
                            var role = _find(vm.availableRoles, { id: parseInt(roleId, 10) });
                            if (role) {
                                project.bidRoles.push(role);
                            }
                        });

                        // Adds the project to the current list
                        vm.projects.push(project);
                    });
                })
            ;
        };

        /**
         * Opens the current project bids section
         *
         * @param  {Object}  project  The project
         */
        vm.goToProjectBids = function (project) {
            $state.go('projects-detail.bids', { project_id: project.id, project_slug: project.project_slug });
        };

        /**
         * Opens the current project inbox section
         *
         * @param  {Object}  project  The project
         */
        vm.goToProjectInbox = function (project) {
            $state.go('projects-detail.inbox', { project_id: project.id, project_slug: project.project_slug });
        };
    })

    .controller('ProBidModalInstanceCtrl', function ProBidModalInstanceCtrl ($scope, $state, $uibModal, $uibModalInstance, strukshurBidService) {
        var days_90;

        $scope.form = {};
        $scope.loading = false;

        //datepicker configuration
        days_90 = new Date();
        days_90.setDate(days_90.getDate() + 90);
        $scope.format = 'MM/dd/yyyy';
        $scope.validDate = days_90;
        $scope.dateOptions = {
            formatYear: 'yy',
            maxDate: days_90,
            minDate: new Date(),
            startingDay: 1
        };
        $scope.datePicker = function() {
            $scope.validDateOpened = true;
        };
        $scope.validDateOpened = false;

        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.placeBid = function(myForm) {
            console.log('myForm');
            console.log(myForm);
            console.log($scope.projectId);
            $scope.loading = true;

            var promise = strukshurBidService.createBid(myForm, $scope.projectId);

            promise.then(function(data) {
                var i, tot;
                $scope.loading = false;
                console.log('promise Success');
                console.log(data);
                //$state.go('pro-bids');

                // remove project from view since user has already bid on it

                tot = $scope.projects.length;
                for(i=0;i<tot;i++){
                    if ($scope.projects[i].id === $scope.projectId) {
                        $scope.projects[i].bid_id = data.bid.id;
                        $scope.projects[i].bid_amount = data.bid.amount;
                        $scope.projects[i].bid_details = data.bid.details;
                        break;
                    }
                }

                $uibModalInstance.dismiss('cancel');
            }, function(e) {
                $scope.loading = false;
                console.log('promise Failed:');
            });
        };
    })

    .controller('ProQuestionModalInstanceCtrl', function ProQuestionModalInstanceCtrl ($scope, $state, $uibModal, $uibModalInstance, strukshurApiService) {
        $scope.message = '';
        $scope.loading = true;
        $scope.errorMessage = false;
        $scope.messages = [];
        $scope.page = 0;

        $scope.askQuestion = function () {
            if ($scope.loading === true) {
                return;
            }

            if ($scope.message === '') {
                $scope.errorMessage = 'Question is required';
                return;
            }

            $scope.loading = true;
            $scope.errorMessage = false;
            strukshurApiService.postQuestion.save({project_id: $scope.selectedProject.id, message: $scope.message}, function (d) {
                $scope.loading = false;
                $scope.errorMessage = false;
                $uibModalInstance.close();
                $uibModalInstance.dismiss('cancel');
            }, function (e) {
                $scope.loading = false;
                $scope.errorMessage = e.data.message;
            });
        };

        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        //get conversation
        $scope.getNextPage = function () {
            $scope.page++;
            strukshurApiService.getProjectProQuestions.query({project_id: $scope.selectedProject.id, page:$scope.page}, function (d) {
                var i, tot, date;
                $scope.loading = false;
                $scope.total = d.total;
                tot = d.messages.length;
                for(i=0;i<tot;i++){
                    date = new Date(d.messages[i].creationDate);
                    d.messages[i].dayString = String(date.getYear())+String(date.getMonth())+String(date.getDay());
                    $scope.messages.push(d.messages[i]);
                }
            }, function (e) {
                $scope.loading = false;
                $scope.errorMessage = e.message;
            });
        };
        $scope.getNextPage();
    })


    .controller('ProsProfileCtrl', function ProsProfileCtrl($scope, $state, $stateParams, strukshurApiService, proResolve, $uibModal) {
        var vm = $scope;

        vm.stars = 4;
        vm.following = false;
        vm.bgImgloading = false;
        vm.imageFail = false;

        vm.pro = proResolve.pro;
        if (vm.pro.bgImage === '') {
            vm.pro.bgImage = '';
        }
        vm.owner = proResolve.owner;
        vm.form = {};

        vm.backgroundUpload = function (eeventObject, fileReader, file) {
            var modalInstance, fileBase64, dataUrlImage;
            var img, width, height, MAX_WIDTH, MAX_HEIGHT, canvas, ctx;

            vm.bgImgloading = false;

            if(!file || file.length===0){
                return;
            }

            if(vm.pro_bg && !vm.pro_bg.base64){
                vm.imageFail = 'Invalid Image';
                if(vm.pro_bg.filesize>150*1000*1000){
                    vm.imageFail = 'Image must be less than 150MB';
                }
                return;
            }

            if(file[0].type.substr(0,5)!=='image'){
                vm.pro_bg = null;
            }
            else{

                MAX_WIDTH = 2342;
                MAX_HEIGHT = 9999;

                // Resize image
                img = document.createElement('img');
                img.setAttribute('crossOrigin', 'anonymous');
                img.onload = function (ev) {
                    var resize;
                    width = img.width;
                    height = img.height;
                    if (width > height) {
                        if (width > MAX_WIDTH) {
                            height *= MAX_WIDTH / width;
                            width = MAX_WIDTH;
                        }
                    } else {
                        if (height > MAX_HEIGHT) {
                            width *= MAX_HEIGHT / height;
                            height = MAX_HEIGHT;
                        }
                    }
                    // Resize only if needed
                    if (width !== img.width || height !== img.height) {
                        canvas = document.createElement('canvas');
                        canvas.width = width;
                        canvas.height = height;
                        ctx = canvas.getContext('2d');
                        ctx.drawImage(img, 0, 0, width, height);
                        canvas.setAttribute('crossOrigin', 'anonymous');

                        dataUrlImage = canvas.toDataURL(vm.pro_bg.filetype);
                        vm.pro_bg.base64 = dataUrlImage.substr(dataUrlImage.indexOf(',')+1);
                        dataUrlImage = null;
                        canvas = null;
                        ctx = null;
                    }

                    //if iOS rotate if needed
                    if(isMobile.apple.device || isMobile.apple.ipod || isMobile.apple.phone || isMobile.apple.tablet){
                        resize = document.createElement('img');
                        resize.setAttribute('crossOrigin', 'anonymous');

                        resize.onload = function(event){
                            width = resize.width;
                            height = resize.height;
                            EXIF.getData(img, function(){
                                var orientation = EXIF.getTag(this, "Orientation");
                                canvas = document.createElement('canvas');
                                ctx = canvas.getContext('2d');
                                if(orientation && orientation>4){
                                    canvas.width = height;
                                    canvas.height = width;
                                }
                                else{
                                    canvas.width = width;
                                    canvas.height = height;
                                }
                                switch(orientation){
                                    case 2:
                                        // horizontal flip
                                        ctx.translate(width, 0);ctx.scale(-1, 1);
                                        break;
                                    case 3:
                                        // 180° rotate left
                                        ctx.translate(width, height);ctx.rotate(Math.PI);
                                        break;
                                    case 4:
                                        // vertical flip
                                        ctx.translate(0, height);ctx.scale(1, -1);
                                        break;
                                    case 5:
                                        // vertical flip + 90 rotate right
                                        ctx.rotate(0.5 * Math.PI);ctx.scale(1, -1);
                                        break;
                                    case 6:
                                        // 90° rotate right
                                        ctx.rotate(0.5 * Math.PI);ctx.translate(0, -height);
                                        break;
                                    case 7:
                                        // horizontal flip + 90 rotate right
                                        ctx.rotate(0.5 * Math.PI);ctx.translate(width, -height);ctx.scale(-1, 1);
                                        break;
                                    case 8:
                                        // 90° rotate left
                                        ctx.rotate(-0.5 * Math.PI);ctx.translate(-width, 0);
                                        break;
                                    default:
                                        dataUrlImage = null;
                                        resize = null;
                                        img = null;
                                        canvas = null;
                                        ctx = null;
                                        vm.uploadBackground('data:' + vm.pro_bg.filetype + ';base64,' + vm.pro_bg.base64);
                                        return;
                                }
                                if(orientation && orientation>4){
                                    ctx.drawImage(resize, 0, 0);
                                }
                                else{
                                    ctx.drawImage(resize, 0, 0, width, height);
                                }

                                canvas.setAttribute('crossOrigin', 'anonymous');
                                dataUrlImage = canvas.toDataURL('image/jpeg');
                                vm.pro_bg.filetype = 'image/jpeg';
                                vm.pro_bg.base64 = dataUrlImage.substr(dataUrlImage.indexOf(',')+1);
                                dataUrlImage = null;
                                resize = null;
                                img = null;
                                canvas = null;
                                ctx = null;
                                vm.uploadBackground('data:' + vm.pro_bg.filetype + ';base64,' + vm.pro_bg.base64);
                                vm.$apply();
                            });
                        };
                        resize.src = 'data:' + vm.pro_bg.filetype + ';base64,' + vm.pro_bg.base64;
                    }
                    else{
                        img = null;
                        vm.uploadBackground('data:' + vm.pro_bg.filetype + ';base64,' + vm.pro_bg.base64);
                    }
                };
                img.src = 'data:' + vm.pro_bg.filetype + ';base64,' + vm.pro_bg.base64;

            }
        };

        vm.loadStart = function () {
            vm.imageFail = false;
            vm.bgImgloading = true;
        };

        vm.loadEnd = function () {
            vm.imageFail = false;
            vm.bgImgloading = false;
        };

        vm.uploadBackground = function (fileBase64) {
            var modalInstance;
            modalInstance = $uibModal.open({
                animation: false,
                keyboard: false,
                backdrop: 'static',
                scope: vm,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                controller: 'ProsProgressModalCtrl',
                template: require('../../common/templates/progress-modal.tpl.html'),
                resolve: {}
            });
            modalInstance.result.then(function (data) {},function (data) {});
            strukshurApiService.proBackgroundImage.save({bg_image: fileBase64}, function(d){
                modalInstance.close();
                //console.log('proBackgroundImage');
                //console.log(d);
                vm.pro.bgImage = d.image;
                vm.bgImgloading = false;
            }, function(e){
                modalInstance.close();
                //console.log('upload error');
                //console.log(e);
                vm.bgImgloading = false;
            });
        };

        vm.editProfile = function (size) {

            var modalInstance = $uibModal.open({
                animation: true,
                keyboard: false,
                scope: vm,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                template: require('../../common/templates/profile-edit-modal.tpl.html'),
                controller: 'ProfileEditModalInstanceCtrl',
                size: size
            });

            modalInstance.result.then(function (selectedItem) {
                //vm.selected = selectedItem;
            }, function () {
                //$log.info('Modal dismissed at: ' + new Date());
            });
        };

        $state.transitionTo('pros-profile.gallery', {userSlug: $stateParams.userSlug});

        vm.messageOpen = function (size) {
            var modalInstance = $uibModal.open({
                animation: true,
                keyboard: false,
                scope: vm,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                template: require('../../common/message-modal.tpl.html'),
                controller: 'ProMessageModalInstanceCtrl',
                size: size,
                resolve: {
                    pro: function () {
                        return vm.pro;
                    },
                    current_user: function (strukshurUserService) {
                        return strukshurUserService.getUser().then(function (d) {
                            return d;
                        });
                    }
                }
            });

            modalInstance.result.then(function (selectedItem) {
                //vm.selected = selectedItem;
            }, function () {
                //$log.info('Modal dismissed at: ' + new Date());
            });
        };

        vm.reviewOpen = function (size) {
            var modalInstance = $uibModal.open({
                animation: true,
                keyboard: false,
                scope: vm,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                template: require('../../common/review-modal.tpl.html'),
                controller: 'ProReviewModalInstanceCtrl',
                size: size
            });

            modalInstance.result.then(function (selectedItem) {
                //vm.selected = selectedItem;
            }, function () {
                //$log.info('Modal dismissed at: ' + new Date());
            });
        };
    })

    .controller('ProfileEditModalInstanceCtrl', function ProfileEditModalInstanceCtrl($scope, $state, $uibModal, $uibModalInstance, strukshurProService, fileReaderService) {
        $scope.form = {};
        $scope.loading = false;
        $scope.formError = false;

        // make a copy of the profile image so previous is not updated until profile is saved
        var tmpImg = $scope.pro.profileImage;
        $scope.pro.profileImageTmp = tmpImg;
        $scope.imageSrc = tmpImg;

        $scope.getFile = function () {
            $scope.$apply();
            fileReaderService.readAsDataUrl($scope.file, $scope)
                .then(function(result) {
                    $scope.imageSrc = result;
                    $scope.imageSrcLoading = false;
                })
            ;
        };

        $scope.proEdit = function (myform) {

            $scope.loading = true;
            myform['pro_id'] = $scope.pro.id;

            var promise = strukshurProService.profileEdit(myform);

            promise.then(function(data) {
                $scope.loading = false;

                // save image back
                $scope.pro.profileImage = data.user.profileImage;

                // show the save form data on the page
                $scope.formError = false;
                $uibModalInstance.close();
            }, function(e) {
                //console.log('proEdit promise Failed:');
                $scope.formError = true;
                $scope.loading = false;
            });
        };

        $scope.send = function () {
            $uibModalInstance.close();
        };

        // save copy in case user cancels before saving
        $scope.pro_backup = {};
        angular.copy($scope.pro, $scope.pro_backup);

        $scope.cancel = function () {
            angular.copy($scope.pro_backup, $scope.pro);
            $uibModalInstance.dismiss('cancel');
        };
    })

    .controller('ProMessageModalInstanceCtrl', function ProMessageModalInstanceCtrl($scope, $state, $uibModal, $uibModalInstance, strukshurApiService, pro, current_user) {
        $scope.pro = pro;
        $scope.current_user = current_user;
        $scope.loading = false;
        $scope.error = false;
        $scope.messageForm = {};
        $scope.formData = {};
        $scope.sendMessagePro = function () {
            if($scope.loading){
                return;
            }
            if($scope.messageForm.$invalid){
                return;
            }
            $scope.loading = true;
            strukshurApiService.proMessage.save({pro_slug: $scope.pro.slug, message: $scope.formData.message}).$promise
            .then(function (res) {
                $uibModalInstance.close();
            })
            .catch(function (e) {
                $scope.error = e.message;
            })
            .finally(function () {
                $scope.loading = false;
            });
        };

        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    })

    .controller('ProReviewModalInstanceCtrl', function ProReviewModalInstanceCtrl($scope, $state, $uibModal, $uibModalInstance) {
        $scope.send = function () {
            $uibModalInstance.close();
        };

        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    })

    // List of User Galleries
    .controller('ProsGalleryCtrl', function ProsGalleryCtrl($scope, $state, $stateParams, angularGridInstance, proGResolve, strukshurProService, $uibModal, strukshurUserService) {
        $scope.galleries = [];
        $scope.galleriesLoading = true;
        $scope.userIsPro = strukshurUserService.isPro();

        var promise = strukshurProService.proGalleries($stateParams.userSlug);

        promise.then(function(data) {
            var i, tot;
            $scope.galleriesLoading = false;
            $scope.galleries = data.galleries;
            tot = $scope.galleries.length;
            for (i = 0; i < tot; i++) {
                if($scope.galleries[i].isProcessing){
                    strukshurProService.galleryVideoStatus($scope.galleries[i]);
                }
            }
            //console.log(data);
        }, function(e) {
            $scope.galleriesLoading = false;
        });

        $scope.addGallery = function (size) {
            // new empty gallery
            $scope.gallery = [];

            var modalInstance = $uibModal.open({
                animation: true,
                keyboard: false,
                scope: $scope,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                template: require('../../common/templates/gallery-edit-modal.tpl.html'),
                controller: 'ProGalleryEditModalInstanceCtrl',
                size: size
            });

            modalInstance.result.then(function (selectedItem) {
                //$scope.selected = selectedItem;
                if (selectedItem && selectedItem.title !== undefined) {
                    $scope.galleries.push(selectedItem);
                    if(selectedItem.isVideo && selectedItem.isProcessing){
                        strukshurProService.galleryVideoStatus(selectedItem);
                    }
                }
            }, function (gallery) {
                // append new gallery
            });
        };

        $scope.editGallery = function (gallery) {
            $scope.gallery = gallery;

            var modalInstance = $uibModal.open({
                animation: true,
                keyboard: false,
                scope: $scope,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                template: require('../../common/templates/gallery-edit-modal.tpl.html'),
                controller: 'ProGalleryEditModalInstanceCtrl'
            });

            modalInstance.result.then(function (selectedItem) {
                //$scope.selected = selectedItem;
                $state.go('pros-profile.gallery', { userSlug: $stateParams.userSlug }, { reload: true });
            }, function (gallery) {

            });
        };

        $scope.pinGallery = function (selectedImage, gallery) {
            $scope.selectedImage = selectedImage;
            $scope.gallery = gallery;

            var modalInstance = $uibModal.open({
                animation: true,
                keyboard: false,
                scope: $scope,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                template: require('../../common/templates/board-modal-save.tpl.html'),
                controller: 'PinGalleryCtrl',
                resolve: { }
            });

            modalInstance.result.then(function (selectedItem) {
                // $scope.selected = selectedItem;
                // $state.go('room', { userSlug: $scope.currentUser.slug, houseSlug: 'my-house', roomSlug: selectedItem }, { reload: true });
            }, function () {
                // $log.info('Modal dismissed at: ' + new Date());
            });
        };

        $scope.galleryOpen = function (gallery, size) {
            $scope.gallery = gallery;

            // fetch all the images
            var modalInstance = $uibModal.open({
                animation: $scope.animationsEnabled,
                keyboard: true,
                scope: $scope,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                controller: 'ProGalleryModalInstanceCtrl',
                template: require('../../common/templates/gallery-modal.tpl.html'),
                size: size,
                resolve: {
                    gallery: function () {
                        return gallery;
                    },
                    position: function () {
                        return 1;
                    }
                }
            });

            modalInstance.result.then(function (selectedItem) {
                //$scope.selected = selectedItem;
            }, function () {

            });
        };
    })

    .controller('PinGalleryCtrl', function PinGalleryCtrl($scope, $state, $uibModal, $uibModalInstance, roomService, strukshurApiService, strukshurUserService) {
        var vm = $scope;

        vm.loading = false;
        vm.userRooms = [];
        vm.productRooms = {};
        vm.item = { img: vm.selectedImage };

        vm.projects = [];
        vm.errorMessage = '';
        vm.projectRooms = [];
        vm.projectSelected = {};
        vm.projectSelectedError = false;

        strukshurUserService.getUser().then(function (d) {
            vm.projects = angular.copy(strukshurUserService.getProjects());

            if (vm.projects.length > 0) {
                vm.projects.unshift({ id: -1, title: '-- Add to home --', slug: '' });
                vm.projectSelected = vm.projects[0];
            }
        });

        // Load available user rooms
        vm.roomsLoaded = function (d) {
            vm.rooms = d.rooms;

            // Add select option to use as placeholder
            if (vm.rooms[0].slug !== '') {
                vm.rooms.unshift({ 'name': ' Select a Room', 'slug': '' });
            }
            vm.productRooms = vm.rooms[0];
        };

        if (!vm.rooms || vm.rooms.length === 0) {
            // strukshurApiService.getRooms.query({}, vm.roomsLoaded);
            strukshurApiService.userRooms.list({ house_id: strukshurUserService.getHouseId() }).$promise
                .then(function (res) {
                    vm.roomsLoaded(res);
                    vm.userRooms = res.rooms;
                })
            ;
        } else {
            vm.roomsLoaded({ rooms: vm.rooms });
        }

        // only required field for now
        vm.productRoomsError = false;

        vm.roomChanged = function (selectedRoom) {
            if (selectedRoom && typeof selectedRoom.id !== 'undefined') {
                vm.errorMessage = '';
                vm.productRoomsError = false;
            } else {
                vm.errorMessage = 'You need to select a room to proceed.';
                vm.productRoomsError = true;
            }
        };

        vm.projectChanged = function (selectedProject) {

            // Set user rooms if the user selected the home option
            if (selectedProject.id == -1) {
                vm.productRoomsError = false;
                vm.roomsLoaded({ rooms: vm.userRooms });
            } else {

                // Search project rooms for the selected project
                vm.errorMessage = '';
                vm.productRoomsError = false;
                vm.rooms = [];
                vm.loadingRooms = true;
                strukshurApiService.projectRooms.list({ project_id: selectedProject.id }).$promise
                    .then(function (res) {
                        vm.loadingRooms = false;
                        res.rooms.unshift({ 'id': -1, 'name': ' Select a Room', 'slug': '' });
                        vm.rooms = res.rooms;
                        vm.productRooms = vm.rooms[0];
                    })
                    .catch(function (res) {
                        vm.loadingRooms = false;
                        vm.rooms = [{ 'id': -1, 'name': ' Select a Room', 'slug': '' }];
                        vm.productRooms = vm.rooms[0];
                        vm.productRoomsError = true;

                        if (res.status === 403) {
                            vm.errorMessage = 'You don\'t have the necessary permission to list the project rooms.';
                        } else {
                            vm.errorMessage = 'There was an error trying to list the project rooms.';
                        }
                    })
                ;
            }
        };

        vm.cancel = function () {
            vm.rooms.splice(0, 1);
            $uibModalInstance.dismiss(false);
        };

        vm.cancelGoRoom = function (roomSlug) {
            // $uibModalInstance.dismiss(false);
            var data = {
                'roomSlug': roomSlug,
                'project': vm.projectSelected
            };
            $uibModalInstance.close(data);
        };

        vm.pinIdeaAdd = function(myForm) {
            var data;

            // Simple form validation
            if (typeof vm.productRooms.id === 'undefined') {
                vm.productRoomsError = true;
                vm.errorMessage = 'You need to select a room to proceed.';
                return;
            }

            vm.projectSelected = (myForm.projectSelected) ? myForm.projectSelected.$viewValue : null;
            data = {
                'project_id': (vm.projectSelected) ? vm.projectSelected.id : null,
                'room_id': vm.productRooms.id,
                'pins': [{
                    'url': vm.item.img,
                    'note': myForm.note.$viewValue,
                    'site_name': (vm.url?vm.url:window.location.href),
                    'id': vm.gallery.id
                }]
            };

            vm.loading = true;
            vm.errorMessage = '';
            vm.productRoomsError = false;
            strukshurApiService.postRoomBoardImport.save(data).$promise
                .then(function (res) {
                    vm.cancelGoRoom(vm.productRooms.slug); // close modal
                })
                .catch(function (res) {
                    vm.productRoomsError = true;

                    if (res.status === 403) {
                        vm.errorMessage = 'You don\'t have the necessary permission to add the pin.';
                    } else {
                        vm.errorMessage = 'There was an error trying to add the pin to the room.';
                    }
                })
                .finally(function () {
                    vm.loading = false;
                })
            ;
        };
    })

    .controller('ProGalleryEditModalInstanceCtrl', function ProGalleryEditModalInstanceCtrl ($scope, $uibModal, $uibModalInstance, galleryService, strukshurApiService, strukshurUtilService) {
        $scope.form = {};
        $scope.isMobile = isMobile;

        $scope.formSubEnter = function () {
            $scope.loading = false;

            return false;
        };

        // @TODO save a deep copy of the gallery, to edit in form only because it displays behind the modal even thought not saved

        galleryService.clearImages();

        if ($scope.gallery.images !== undefined) {
            $scope.images = galleryService.setImages($scope.gallery.images);
        } else {
            $scope.images = galleryService.setImages([]);
        }

        $scope.addImageClicked = function () {
            angular.element('#gallery_image').click();
        };

        $scope.toggleEditLinkPopover = function (image) {
            image.popoverOpened = true;
        };

        $scope.loadStart = function () {
            $scope.imageFail = false;
            $scope.imageSrcLoading = true;
        };

        $scope.loadEnd = function () {
            $scope.imageFail = false;
            $scope.imageSrcLoading = false;
        };

        $scope.afterValidate = function (eventObject, fileReader, file) {
            var duplicate;

            $scope.loadingImage = false;
            if(!file || file.length===0){
                return;
            }
            duplicate = galleryService.isDuplicate(file[0].name);
            if(duplicate){
                $scope.galleryImage = null;
                return;
            }
            $scope.isVideo = (file[0].type.substr(0,5) !== 'image');

            if($scope.galleryImage && !$scope.galleryImage.base64){
                $scope.imageFail = 'Invalid '+($scope.isVideo?'Video':'Image');
                $scope.galleryImage = null;
                return;
            }
            if(file[0].size>150*1000*1000){
                $scope.imageFail = ($scope.isVideo?'Video':'Image')+' must be less than 150MB';
                $scope.galleryImage = null;
                return;
            }
            if($scope.isVideo){
                galleryService.addImage('data:' + $scope.galleryImage.filetype + ';base64,' + $scope.galleryImage.base64, $scope.galleryImage.filename, $scope.galleryImage.filesize);
                $scope.galleryImage = null;
            }
            else if(file[0].type.substr(0,5)!=='video' && file[0].type.substr(0,5)!=='image'){
                $scope.galleryImage = null;
            }
            else{
                //resize

                var dataUrlImage;
                var canvas, MAX_WIDTH, MAX_HEIGHT, width, height, ctx, img;
                MAX_WIDTH = 2048;
                MAX_HEIGHT = 2048;


                if ($scope.galleryImage && $scope.galleryImage.base64) {

                    //compress image
                    img = document.createElement('img');
                    img.setAttribute('crossOrigin', 'anonymous');

                    img.onload = function(ev){
                        var resize;
                        width = img.width;
                        height = img.height;

                        if (width > height) {
                            if (width > MAX_WIDTH) {
                                height *= MAX_WIDTH / width;
                                width = MAX_WIDTH;
                            }
                        } else {
                            if (height > MAX_HEIGHT) {
                                width *= MAX_HEIGHT / height;
                                height = MAX_HEIGHT;
                            }
                        }

                        // Resize only if needed
                        if (width !== img.width || height !== img.height) {
                            canvas = document.createElement('canvas');
                            canvas.width = width;
                            canvas.height = height;
                            ctx = canvas.getContext('2d');
                            ctx.drawImage(img, 0, 0, width, height);

                            canvas.setAttribute('crossOrigin', 'anonymous');

                            dataUrlImage = canvas.toDataURL($scope.galleryImage.filetype);
                            $scope.galleryImage.base64 = dataUrlImage.substr(dataUrlImage.indexOf(',')+1);
                            dataUrlImage = null;
                            canvas = null;
                            ctx = null;
                        }

                        //if iOS rotate if needed
                        if(isMobile.apple.device || isMobile.apple.ipod || isMobile.apple.phone || isMobile.apple.tablet){
                            resize = document.createElement('img');
                            resize.setAttribute('crossOrigin', 'anonymous');

                            resize.onload = function(event){
                                width = resize.width;
                                height = resize.height;
                                EXIF.getData(img, function(){
                                    var orientation = EXIF.getTag(this, "Orientation");
                                    canvas = document.createElement('canvas');
                                    ctx = canvas.getContext('2d');
                                    if(orientation && orientation>4){
                                        canvas.width = height;
                                        canvas.height = width;
                                    }
                                    else{
                                        canvas.width = width;
                                        canvas.height = height;
                                    }
                                    switch(orientation){
                                        case 2:
                                            // horizontal flip
                                            ctx.translate(width, 0);ctx.scale(-1, 1);
                                            break;
                                        case 3:
                                            // 180° rotate left
                                            ctx.translate(width, height);ctx.rotate(Math.PI);
                                            break;
                                        case 4:
                                            // vertical flip
                                            ctx.translate(0, height);ctx.scale(1, -1);
                                            break;
                                        case 5:
                                            // vertical flip + 90 rotate right
                                            ctx.rotate(0.5 * Math.PI);ctx.scale(1, -1);
                                            break;
                                        case 6:
                                            // 90° rotate right
                                            ctx.rotate(0.5 * Math.PI);ctx.translate(0, -height);
                                            break;
                                        case 7:
                                            // horizontal flip + 90 rotate right
                                            ctx.rotate(0.5 * Math.PI);ctx.translate(width, -height);ctx.scale(-1, 1);
                                            break;
                                        case 8:
                                            // 90° rotate left
                                            ctx.rotate(-0.5 * Math.PI);ctx.translate(-width, 0);
                                            break;
                                        default:
                                            galleryService.addImage('data:' + $scope.galleryImage.filetype + ';base64,' + $scope.galleryImage.base64, $scope.galleryImage.filename, $scope.galleryImage.filesize);
                                            $scope.galleryImage = null;
                                            dataUrlImage = null;
                                            resize = null;
                                            img = null;
                                            canvas = null;
                                            ctx = null;
                                            $scope.$apply();
                                            return;
                                    }
                                    if(orientation && orientation>4){
                                        ctx.drawImage(resize, 0, 0);
                                    }
                                    else{
                                        ctx.drawImage(resize, 0, 0, width, height);
                                    }


                                    canvas.setAttribute('crossOrigin', 'anonymous');
                                    dataUrlImage = canvas.toDataURL('image/jpeg');
                                    $scope.galleryImage.filetype = 'image/jpeg';
                                    $scope.galleryImage.base64 = dataUrlImage.substr(dataUrlImage.indexOf(',')+1);
                                    galleryService.addImage('data:' + $scope.galleryImage.filetype + ';base64,' + $scope.galleryImage.base64, $scope.galleryImage.filename, $scope.galleryImage.filesize);
                                    $scope.galleryImage = null;
                                    dataUrlImage = null;
                                    resize = null;
                                    img = null;
                                    canvas = null;
                                    ctx = null;
                                    $scope.$apply();
                                });
                            };
                            resize.src = 'data:' + $scope.galleryImage.filetype + ';base64,' + $scope.galleryImage.base64;
                        }
                        else{
                            img = null;
                            galleryService.addImage('data:' + $scope.galleryImage.filetype + ';base64,' + $scope.galleryImage.base64, $scope.galleryImage.filename, $scope.galleryImage.filesize);
                            $scope.galleryImage = null;
                            $scope.$apply();
                        }
                    };
                    img.src = 'data:' + $scope.galleryImage.filetype + ';base64,' + $scope.galleryImage.base64;
                }
            }
        };

        $scope.galleryDelete = function () {

            if (confirm('Are you sure you want to delete this gallery?')) {

                $scope.loading = true;

                var formData = {
                    id: $scope.gallery.id
                };
                strukshurApiService.proGallery.delete(formData, function (d) {
                    $scope.loading = false;
                    // setting total to zero will hide the gallery from list view
                    $scope.gallery.total = 0;
                    // close modal
                    $uibModalInstance.close($scope.gallery);
                }, function (e) {
                    console.log(e);
                    $scope.loading = false;
                });
            }

        };

        $scope.galleryUpdate = function (myform) {
            var modalInstance;

            $scope.loading = true;
            $scope.galleryErrorMessage = null;

            var formData: any;
            if ($scope.gallery.id !== undefined) {
                // edit / update
                formData = {
                    id: $scope.gallery.id,
                    title: myform.galleryForm.title.$viewValue,
                    description: myform.galleryForm.description.$viewValue,
                    images: $scope.images
                };
            } else {
                // add
                formData = {
                    title: myform.galleryForm.title.$viewValue,
                    description: myform.galleryForm.description.$viewValue,
                    images: $scope.images
                };
            }

            if ($scope.images.length === 0) {
                $scope.loading = false;
                $scope.galleryErrorMessage = 'You must include at least one image in your gallery.';

                return false;
            }

            if (formData.title === undefined || formData.title === '') {
                $scope.loading = false;
                myform.galleryForm.title.$setValidity('invalid', false);

                return false;
            }

            // Validate image links
            var imageErrorExists = false;
            $scope.images.forEach(function (image) {
                if (image.link && !strukshurUtilService.isValidURL(image.link)) {
                    imageErrorExists = true;
                    image.popoverOpened = true;
                    $scope.galleryErrorMessage = 'The provided link for the image is invalid.';

                    return false;
                }
            });
            if (imageErrorExists) { return; }

            modalInstance = $uibModal.open({
                animation: false,
                keyboard: false,
                backdrop: 'static',
                scope: $scope,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                controller: 'ProsProgressModalCtrl',
                template: require('../../common/templates/progress-modal.tpl.html'),
                resolve: {}
            });
            modalInstance.result.then(angular.noop, angular.noop);

            // add or update
            strukshurApiService.proGallery.update(formData, function(d) {
                modalInstance.close();
                $scope.loading = false;
                $uibModalInstance.close(d.gallery);

            }, function (e) {
                console.log(e);
                modalInstance.close();
                $scope.loading = false;
                $scope.galleryErrorMessage = 'There was an error while saving the gallery.';
            });
        };

        $scope.removeImage = function (index) {
            galleryService.removeImage(index);
        };

        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    })

    .controller('ProGalleryModalInstanceCtrl', function ProGalleryModalInstanceCtrl($scope, $uibModal, $uibModalInstance, strukshurUserService, position) {
        var i;

        $scope.currentUser = {};
        $scope.popupImage = {};
        $scope.more_text = 'more';
        strukshurUserService.getUser().then(function (d) {
            $scope.currentUser = (d.user) ? d.user : d;
        });

        $scope.selectedImage = {
            image: $scope.gallery.cover,
            thumb: $scope.gallery.thumb,
            width: $scope.gallery.width,
            height: $scope.gallery.height,
            isVideo: $scope.gallery.isVideo,
            isProcessing: $scope.gallery.isProcessing,
            link: ($scope.gallery.images && $scope.gallery.images[0]) ? $scope.gallery.images[0].link : null
        };

        $scope.galleryMainImageCover = $scope.gallery.cover;
        $scope.galleryMainImageStyle = { 'background-image': 'url('+$scope.gallery.cover+')' };

        $scope.setMainGalleryImage = function (img) {
            $scope.selectedImage = img;
            $scope.galleryMainImageCover = img.image;
            $scope.galleryMainImageStyle = { 'background-image': 'url(' + img.image + ')' };
        };

        $scope.galleryLoadImage = function(){
            var th = this, element = document.getElementById('popupImage');
            $scope.selectedImage.height = th.thisImage.height;
            $scope.popupImage.height = element.clientHeight;
        };

        if (position) {
            for (i in $scope.gallery.images) {
                if (!$scope.gallery.images.hasOwnProperty(i)) {
                    continue;
                }

                if ($scope.gallery.images[i].position === position) {
                    $scope.setMainGalleryImage($scope.gallery.images[i]);
                    break;
                }
            }
        }

        $scope.toggleMoreText = function () {
            if ($scope.more_text === 'more') {
                $scope.more_text = 'less';
            } else {
                $scope.more_text = 'more';
            }
        };

        $scope.ok = function () {
            $uibModalInstance.close();
            $uibModalInstance.dismiss('cancel');
        };

        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.pinGallery = function (selectedImage, gallery) {
            $scope.gallery = gallery;
            $scope.selectedImage = selectedImage;

            var modalInstance = $uibModal.open({
                animation: true,
                keyboard: false,
                scope: $scope,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                template: require('../../common/templates/board-modal-save.tpl.html'),
                controller: 'PinGalleryCtrl',
            });

            modalInstance.result.then(function () {
                $uibModalInstance.dismiss('cancel');
            }, function () {
                // $log.info('Modal dismissed at: ' + new Date());
            });
        };
    })

    .controller('ProsReviewsCtrl', function ProsReviewsCtrl($scope) {

    })

    .controller('ProsActivityCtrl', function ProsActivityCtrl($scope) {

    })

    .controller('ProsCareersCtrl', function ProsCareersCtrl($scope) {

    })

    .controller('ProsProgressModalCtrl', function ProsProgressModalCtrl($scope) {
        $scope.progress = 0;
        $scope.title = 'Uploading';
        $scope.$on('updateProgress', function (event, args) {
            if(args.name==='proGallery.update'){
                $scope.progress = args.progress;
                if($scope.progress>=100){
                    $scope.title = 'Saving...';
                }
            }
        });
    })
;
