import * as angular from 'angular';

angular.module('strukshurApp.adminCategories', ['ui.router', 'ui.bootstrap', 'strukshurApp.admin'])

.config(function config($stateProvider) {
    $stateProvider
        .state('admin.categories', {
            url: '/categories',
            params: {
                page: null
            },
            views: {
                'admin_middle_view': {
                    controller: 'AdminCategoriesCtrl',
                    template: require('./categories/admin_categories.tpl.html')
                }
            },
            data: { pageTitle: 'Admin Categories: List' }
        })

        .state('admin.category-add', {
            url: '/category/add',
            params: {
                page: null
            },
            views: {
                'admin_middle_view': {
                    controller: 'AdminCategoryAddCtrl',
                    template: require('./categories/admin_category_add.tpl.html')
                }
            },
            data: { pageTitle: 'Admin Category: Add' }
        })

        .state('admin.category', {
            url: '/category/:id',
            views: {
                'admin_middle_view': {
                    controller: 'AdminCategoryDetailCtrl',
                    template: require('./categories/admin_category_detail.tpl.html')
                }
            },
            data: { pageTitle: 'Admin Category: Detail' }
        })

        .state('admin.category-edit', {
            url: '/category/:id/edit',
            views: {
                'admin_middle_view': {
                    controller: 'AdminCategoryEditCtrl',
                    template: require('./categories/admin_category_edit.tpl.html')
                }
            },
            resolve: {
                categoryResolve: function($q, $state, $stateParams, strukshurApiService){
                    var deferred = $q.defer();
                    console.log('start categoryResolve');
                    strukshurApiService.getAdminCategory.query({id: $stateParams.id}, function(d){

                        if (d === undefined) {
                            deferred.reject('Category not found.');
                            $state.go('admin.categories');
                        } else {
                            deferred.resolve(d.category);
                        }
                    }, function (e) {
                        deferred.reject('Category not found.');
                        $state.go('admin.categories');
                    });

                    return deferred.promise;
                }
            },
            data: { pageTitle: 'Admin Category: Edit' }
        })
    ;
})

.controller('AdminCategoriesCtrl', function AdminCategoriesCtrl ($scope, AdminPage, strukshurApiService) {
    var vm = $scope;

    vm.page = 1;
    vm.total = 0;
    vm.perPage = 5;
    vm.products = [];

    function fetchUsers() {
        strukshurApiService.getAdminCategories.query({ page: vm.page }, function (d) {
            vm.total = d.total;
            vm.perPage = d.perPage;
            vm.categories = d.categories;

            //vm.page++;
            vm.totalPages = vm.calculateTotalPages();

        }, function (e) {
            return false;
        });
    }
    fetchUsers();

    vm.selectPage = function (page) {
        if (page > 0 && (page <= vm.totalPages)) {
            vm.page = AdminPage.setPage(page);
            fetchUsers();
        }
    };

    vm.noNext = function () {
        return Math.ceil(vm.total / vm.perPage) <= vm.page;
    };

    vm.noPrevious = function () {
        return 1 >= vm.page;
    };

    vm.calculateTotalPages = function () {
        var totalPages = vm.perPage < 1 ? 1 : Math.ceil(vm.total / vm.perPage);
        return Math.max(totalPages || 0, 1);
    };
})

.controller('AdminCategoryDetailCtrl', function AdminCategoryDetailCtrl ($scope, $state, $stateParams, strukshurApiService) {
    strukshurApiService.getAdminCategory.query({ id: $stateParams.id }, function (d){
        $scope.category = d.category;
    });

    $scope.edit = function () {
        $state.go('admin.category-edit', { id: $stateParams.id });
    };
})

.controller('AdminCategoryAddCtrl', function AdminCategoryAddCtrl ($scope, $state, fileReaderService, strukshurApiService) {
    var vm = $scope;

    vm.imgs = [];
    vm.loading = false;
    vm.formError = false;

    // very important
    vm.form = {};
    vm.status = { name: 'active' };

    vm.cancel = function () {
        $state.go('admin.categories');
    };

    vm.getFile = function (el) {
        var elementId = el[0].id;

        vm.$apply();
        fileReaderService
            .readAsDataUrl(vm.file, vm)
            .then(function (result) {
                vm.imgs[elementId] = { 'imageSrc': result, 'imageSrcLoading': false };
            })
        ;
    };

    vm.categoryAdd = function (myform) {
        if (!myform.$valid) { return; }

        vm.loading = true;
        vm.formError = false;

        var base64Image = '';
        if (myform.img && myform.img.$viewValue) {
            base64Image = myform.img.$viewValue.base64;
        }

        var base64Banner = null;
        if (myform.banner && myform.banner.$viewValue) {
            base64Banner = myform.banner.$viewValue.base64;
        }

        var formData: any = {
            title: myform.title.$viewValue,
            description: myform.description.$viewValue,
            status: myform.status.$viewValue,
            featured: myform.featured.$modelValue,
            img: base64Image,
        };

        if (base64Banner) {
            formData.banner = base64Banner;
        }

        strukshurApiService.postAdminCategoryAdd.save(formData, function (data) {
            vm.loading = false;
            vm.formError = false;
            $state.go('admin.categories');
        }, function (e) {
            vm.formError = true;
            vm.loading = false;
        });
    };
})

.controller('AdminCategoryEditCtrl', function AdminCategoryEditCtrl ($scope, $state, categoryResolve, fileReaderService, strukshurApiService) {
    var vm = $scope;

    vm.form = {};
    vm.imgs = [];
    vm.loading = false;
    vm.formError = false;
    vm.category = categoryResolve;
    vm.imageChanged = false;
    vm.imgs['img'] = {
        'imageSrc': categoryResolve.img ? categoryResolve.img : ('public/images/store-category-' + categoryResolve.slug + '.png'),
        'imageSrcLoading': false
    };
    vm.imgs['banner'] = { 'imageSrc': categoryResolve.banner, 'imageSrcLoading': false };
    vm.categoryStatus = {
        status: categoryResolve.active === false ? 'hidden' : 'active'
    };

    vm.cancel = function () {
        $state.go('admin.category', { id: vm.category.id });
    };

    vm.getFile = function (el) {
        var elementId = el[0].id;
        vm.imageChanged = true;

        vm.$apply();
        fileReaderService.readAsDataUrl(vm.file, vm)
            .then(function (result) {
                vm.imgs[elementId] = { 'imageSrc': result, 'imageSrcLoading': false };
            })
        ;
    };

    vm.categoryEdit = function (myform) {
        vm.formError = false;

        myform['id'] = categoryResolve.id;
        myform['status'] = vm.categoryStatus.status;

        if (!myform.$valid) {
            vm.formError = true;
            return;
        }

        var formData: any = {
            id: myform.id,
            title: myform.title.$viewValue,
            description: myform.description.$viewValue,
            status: myform.status,
            featured: myform.featured.$modelValue,
        };

        if (vm.imageChanged) {
            if (myform.img && myform.img.$viewValue) {
                formData.img = myform.img.$viewValue.base64;
            }

            if (myform.banner && myform.banner.$viewValue) {
                formData.banner = myform.banner.$viewValue.base64;
            }
        }

        vm.loading = true;
        strukshurApiService.postAdminCategoryEdit.save(formData, function () {
            vm.loading = false;
            vm.formError = false;
            $state.go('admin.category', { id: vm.category.id });
        }, function (e) {
            vm.formError = true;
            vm.loading = false;
        });
    };
})
;
