import * as angular from 'angular';
import * as isMobile from 'ismobilejs';
import * as EXIF from 'exif-js';
import { isInteger as _isInteger, remove as _remove } from 'lodash';
import strukshurConfig from '../../config.ts';

angular.module('strukshurApp.productAdmin', ['ui.router', 'ui.bootstrap'])

.config(function config( $stateProvider ) {
    $stateProvider
        .state('productAdmin', {
            url: '/productAdmin',
            views: {
                main: {
                    controller: 'ProductAdminCtrl',
                    template: require('./productAdmin.tpl.html')
                }
            },
            resolve: {
                productAdminResolve: function($state, $q, strukshurUserService, InitService){
                    return InitService.promise.then(function(d){
                        if (strukshurUserService.canAccessVendor() === false) {
                            $state.go('productAdmin-auth');
                            return false;
                        }
                    });
                }
            },
            data: { pageTitle: 'Admin', class: 'admin' }
        })
        .state('productAdmin-auth', {
            url: '/auth',
            params: {
                page: null
            },
            views: {
                main: {
                    controller: 'ProductAdminAuthCtrl',
                    template: require('./productAdmin_auth.tpl.html')
                }
            },
            data: { pageTitle: 'Authentication', class: 'admin' }
        })
        .state('productAdmin.dashboard', {
            url: '/dash/',
            params: {
                page: null
            },
            views: {
                productAdmin_middle_view: {
                    controller: 'ProductAdminDashboardCtrl',
                    template: require('./productAdmin_dashboard.tpl.html')
                }
            },
            data: { pageTitle: 'Dashboard', class: 'admin' }
        })
        .state('productAdmin.products', {
            url: '/products/list?page',
            params: {
                page: null
            },
            views: {
                productAdmin_middle_view: {
                    controller: 'ProductAdminProductsCtrl',
                    template: require('../admin/products/admin_products.tpl.html')
                }
            },
            data: { pageTitle: 'Products: List' }
        })
        .state('productAdmin.product', {
            url: '/product/:id/details',
            views: {
                productAdmin_middle_view: {
                    controller: 'ProductAdminProductDetailCtrl',
                    template: require('../admin/products/admin_product_detail.tpl.html')
                }
            },
            data: { pageTitle: 'Admin Product: Detail' }
        })
        .state( 'productAdmin.product-edit', {
            url: '/edit/product/:id',
            views: {
                productAdmin_middle_view: {
                    controller: 'ProductAdminProductEditCtrl',
                    template: require('../admin/products/admin_product_edit.tpl.html')
                }
            },
            resolve: {
                productResolve: function($q, $state, $stateParams, strukshurApiService){
                    var deferred = $q.defer();
                    console.log('start productResolve');
                    strukshurApiService.getVendorProduct.query({id: $stateParams.id}, function(d){

                        if (d === undefined) {
                            deferred.reject('Product not found.');
                            $state.go('productAdmin.products');
                        }
                        else {
                            deferred.resolve(d.product);
                        }
                    }, function(e){
                        deferred.reject('Product not found.');
                        $state.go('productAdmin.products');
                    });

                    return deferred.promise;
                }
            },
            data: { pageTitle: 'Admin Product: Edit' }
        })
        .state( 'productAdmin.product-add', {
            url: '/add/product',
            params: {
                page: null
            },
            views: {
                productAdmin_middle_view: {
                    controller: 'ProductAdminProductAddCtrl',
                    template: require('../admin/products/admin_product_add.tpl.html')
                }
            },
            data: { pageTitle: 'Admin Product: Add' }
        })
    ;
})

.controller( 'ProductAdminCtrl', function ProductAdminCtrl( $scope, $state, $stateParams, strukshurUserService ) {

    $scope.currentUser = {};
    $scope.canProductAdminUsers = strukshurUserService.canAccessVendor();
    $scope.isVendor = strukshurUserService.isVendor();

    strukshurUserService.getUser().then(function (d) {
        $scope.currentUser = d;
    });

    if($scope.canProductAdminUsers){
        if($state.current.name==='productAdmin'){
            $state.go('productAdmin.dashboard');
        }
    }
    else{
        $state.go('home');
    }

})

.controller('ProductAdminAuthCtrl', function ProductAdminAuthCtrl ($scope, $state, storage, strukshurAuthUserService, strukshurUserService) {

    // very important
    $scope.form = {};

    var auth = storage.get('jwt') || false;

    // @TODO if already logged in
    if (auth) {
        console.log('already logged in');
        $state.go('productAdmin');
        return;
    }

    $scope.login = function (myform) {

        $scope.loading = true;

        var promise = strukshurAuthUserService.login(myform);

        promise.then(function(data) {
            $scope.loading = false;

            // @TODO only let in productAdmins login here ?

            strukshurUserService.setUser(data.user);

            if (strukshurUserService.canAccessVendor()) {
                console.log('going to productAdmin admin');
                $state.go('productAdmin', {});
            } else {
                $state.go('ideas');
            }
        }, function(reason) {
            console.log('promise Failed: ');
            console.log(reason);
            $scope.error = 'Invalid Credentials. Please try again.';
            $scope.loading = false;
        });
    };
})


.controller( 'ProductAdminDashboardCtrl', function ProductAdminDashboardCtrl($scope, $state, $uibModal, strukshurUserService, strukshurApiService) {
    $scope.currentUser = {};
    $scope.product_totals = {};
    $scope.orders_totals = {};
    $scope.isAdmin = false;
    $scope.isMissingPayment = strukshurUserService.isMissingPayment();
    $scope.stripe_client_id = strukshurConfig.stripe_client_id;
    strukshurUserService.getUser().then(function (d) {
        $scope.currentUser = d;
        $scope.isAdmin = strukshurUserService.isAdmin();
    });

    strukshurApiService.dashboardTotals.query({}, function (d) {
        $scope.product_totals = d.product_totals;
        $scope.orders_totals = d.orders_totals;
    });

    /**
     * Popup to confirm disconnect from stripe
     */
    $scope.disconnectPopAdmin = function (account) {
        $scope.confirmMessage = false;
        var modalInstance = $uibModal.open({
            animation: true,
            keyboard: true,
            scope: $scope,
            ariaLabelledBy: 'modal-title',
            ariaDescribedBy: 'modal-body',
            controller: 'ConfirmDisconnectStripeCtrl',
            template: require('../../common/templates/confirm-modal.tpl.html'),
            resolve: {
                message: function () {
                    return 'You won\'t be able to process payments from the store.<br>You can reconnect at any time.<br>Are you sure?';
                }
            }
        });
        modalInstance.result.then(function (selectedItem) {
            $scope.successMessage = 'Disconnected from stripe';
            account.stripePublishableKey = null;
        }, angular.noop);
    };
})

.controller('ProductAdminProductsCtrl', function ProductAdminProductsCtrl ($scope, $state, $stateParams, $uibModal, $window, smoothScroll, strukshurApiService, strukshurFlashService, strukshurUserService) {
    var vm = $scope;

    vm.page = null;
    vm.total = 0;
    vm.search = '';
    vm.pageSize = 20;
    vm.products = [];
    vm.isAdmin = false;
    vm.loading = false;
    vm.currentUser = {};
    vm.showPagination = false;

    let initialPage = parseInt($stateParams.page || 1, 10);
    if (!_isInteger(initialPage)) {
        initialPage = 1;
    }
    vm.page = initialPage;

    vm.init = () => {
        vm.setupPagination();
        vm.fetchProducts(vm.page);

        strukshurUserService.getUser().then((res: any) => {
            vm.currentUser = res;
            vm.isAdmin = strukshurUserService.isAdmin();
        });
    };

    /**
     * Setup pagination logic depending on the device width
     */
    vm.setupPagination = () => {
        vm.showPrevNext = false;
        vm.showFirstLast = false;
        vm.adjacentPagination = 1;

        if ($window.innerWidth > 449) {
            vm.showPrevNext = true;
        }
        if ($window.innerWidth > 499) {
            vm.showFirstLast = true;
        }
        if ($window.innerWidth > 599) {
            vm.adjacentPagination = 2;
        }
    };

    /**
     * Updates the page parameter for the current page
     */
    vm.updatePageParams = () => {
        $state.go($state.current, { page: vm.page }, { location: true, inherit: true, notify: false });
    };

    vm.parseFloat = (data: any) => { return parseFloat(data) };

    /**
     * Fetches a list of products for the given page
     */
    vm.fetchProducts = (page: number) => {
        if (vm.loading) { return; }

        let oldPage = vm.page;

        vm.page = page;
        vm.loading = true;
        strukshurApiService.getVendorProducts.query({ page: vm.page, terms: JSON.stringify({ terms: vm.search }) }).$promise
            .then((res: any) => {
                vm.total = res.total;
                vm.products = res.products;
                vm.showPagination = true;
                vm.updatePageParams();

                smoothScroll(document.querySelector('.table'), { offset: 80 });
            })
            .catch((err: any) => {
                vm.page = oldPage;
                strukshurFlashService.handleApiResponseErrorMessage({
                    response: err,
                    container: 'admin-products-flash',
                    defaultMessage: 'There was an error while trying to fetch the products.',
                    accessDeniedMessage: 'You don\'t have the necessary permission to list products.',
                });
            })
            .finally(() => vm.loading = false)
        ;
    };

    vm.addOrderPop = (product: any) => {
        vm.confirmMessage = false;
        let modalInstance = $uibModal.open({
            animation: true,
            keyboard: true,
            scope: vm,
            ariaLabelledBy: 'modal-title',
            ariaDescribedBy: 'modal-body',
            controller: 'OrderSelectModalCtrl',
            template: require('../../common/templates/order-select-modal.tpl.html'),
            resolve: {
                product: () => { return product },
            }
        });
        modalInstance.result.then(angular.noop, angular.noop);
    };

    vm.deleteProductPop = (product: any) => {
        vm.confirmMessage = false;
        let modalInstance = $uibModal.open({
            scope: vm,
            keyboard: true,
            animation: true,
            ariaLabelledBy: 'modal-title',
            ariaDescribedBy: 'modal-body',
            controller: 'ConfirmProductAdminDeleteCtrl',
            template: require('../../common/templates/confirm-modal.tpl.html'),
            resolve: {
                product: () => { return product },
                message: () => { return 'Are you sure?' },
            }
        });

        modalInstance.result.then((selectedItem: any) => {
            _remove(vm.products, { id: selectedItem.id });

            strukshurFlashService.displayMessage({
                type: 'success',
                duration: 5000,
                container: 'admin-products-flash',
                message: 'Product successfully removed.',
            });
        }, angular.noop);
    };

    vm.init();
})

.controller('ConfirmProductAdminDeleteCtrl', function ConfirmProductAdminDeleteCtrl ($scope, $uibModalInstance, message, product, strukshurApiService) {
    $scope.loading = false;
    $scope.errorMessage = false;
    $scope.product = product;
    $scope.title = 'Confirm';
    $scope.message = message;
    $scope.confirm = function () {
        var data;
        if($scope.loading){
            return;
        }
        $scope.loading = true;
        $scope.errorMessage = false;
        data = {
            product_id: $scope.product.id
        };
        strukshurApiService.productAdminDelete.delete(data, function (d) {
            $uibModalInstance.close(product);
        }, function (e) {
            $scope.loading = false;
        });
    };
    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };
})
.controller( 'ProductAdminProductDetailCtrl', function ProductAdminProductDetailCtrl( $scope, $state, $stateParams, strukshurApiService, AdminPage ) {

    strukshurApiService.getVendorProduct.query({id: $stateParams.id}, function(d){
        $scope.product = d.product;

    });

    $scope.productEdit = function() {
        $state.go('productAdmin.product-edit', {id: $stateParams.id});
    };

    $scope.productDelete = function()
    {

    };

})
.controller( 'ProductAdminProductEditCtrl', function AdminProductEditCtrl( $scope, $state, $q, $stateParams, productResolve, strukshurApiService, fileReaderService, RoomAttrsService, strukshurUserService ) {

    $scope.loading = false;
    $scope.formError = false;

    $scope.currentUser = {};
    $scope.isAdmin = false;
    strukshurUserService.getUser().then(function (d) {
        $scope.currentUser = d;
        $scope.isAdmin = strukshurUserService.isAdmin();
    });
    $scope.form = {};
    //console.log(productResolve);

    $scope.product = productResolve;

    $scope.form = productResolve;

    $scope.imageSrc = productResolve.img;

    $scope.prodStatus = {
        status: productResolve.active === false ? 'hidden' : 'active'
    };

    $scope.fetchRoomAttrs = function() {
        $scope.roomsTax = [];
        //console.log('roomTypes');console.log($scope.roomTypes);
        angular.forEach($scope.roomTypes, function(value, key) {
            //console.log('sending');console.log(value);
            strukshurApiService.searchVendorRoomProps.query({terms: '', rooms: [value]}, function(d){
                // Pre-Check room attributes
                var roomProps = d.room_props;
                angular.forEach(roomProps, function(value,key) {
                    angular.forEach(value, function(valueP,keyP) {
                        roomProps[key][keyP].selected = false;
                        angular.forEach($scope.roomAttrs, function(valueA, keyA) {
                            if (valueA.id === valueP.attr_id) {
                                roomProps[key][keyP].selected = true;
                            }
                        });
                    });
                });

                $scope.roomsTax.push({'roomName': value.name, 'roomResults': roomProps});
            });
        });
    };

    $scope.roomTypes = productResolve.types;
    //$scope.roomTags = productResolve.roomTags;
    $scope.roomAttrs = productResolve.roomAttrs;
    $scope.productCats = productResolve.productCats;
    $scope.vendors = productResolve.vendors;


    $scope.roomAdded = function($tag) {
        $scope.fetchRoomAttrs();
    };

    // add initial room attrs
    $scope.fetchRoomAttrs();


    $scope.cancel = function () {
        $state.go('productAdmin.product', {id: $scope.product.id});
    };

    $scope.loadEnd = function () {
        $scope.imageSrcLoading = false;
    };

    $scope.afterValidate = function (eventObject, fileReader, file) {
        $scope.imageSrcLoading = false;
        if(!file || file.length===0){
            $scope.product_image = null;
            $scope.imageSrc = null;
            return;
        }

        if($scope.product_image && !$scope.product_image.base64){
            $scope.pinFail = 'Invalid Image';
            $scope.product_image = null;
            $scope.imageSrc = null;
            return;
        }
        if(file[0].size>150*1000*1000){
            $scope.pinFail = 'Image must be less than 150MB';
            $scope.product_image = null;
            $scope.imageSrc = null;
            return;
        }

        if(file[0].type.substr(0,5)!=='image'){
            $scope.product_image = null;
            $scope.imageSrc = null;
        }
        else{
            //resize

            var dataUrlImage;
            var canvas, MAX_WIDTH, MAX_HEIGHT, width, height, ctx, img;
            MAX_WIDTH = 2048;
            MAX_HEIGHT = 2048;


            if ($scope.product_image && $scope.product_image.base64) {

                //compress image
                img = document.createElement('img');
                img.setAttribute('crossOrigin', 'anonymous');

                img.onload = function(ev){
                    var resize;
                    width = img.width;
                    height = img.height;

                    if (width > height) {
                        if (width > MAX_WIDTH) {
                            height *= MAX_WIDTH / width;
                            width = MAX_WIDTH;
                        }
                    } else {
                        if (height > MAX_HEIGHT) {
                            width *= MAX_HEIGHT / height;
                            height = MAX_HEIGHT;
                        }
                    }

                    // Resize only if needed
                    if (width !== img.width || height !== img.height) {
                        canvas = document.createElement('canvas');
                        canvas.width = width;
                        canvas.height = height;
                        ctx = canvas.getContext('2d');
                        ctx.drawImage(img, 0, 0, width, height);

                        canvas.setAttribute('crossOrigin', 'anonymous');

                        dataUrlImage = canvas.toDataURL($scope.product_image.filetype);
                        $scope.product_image.base64 = dataUrlImage.substr(dataUrlImage.indexOf(',')+1);
                        $scope.imageSrc = 'data:' + $scope.product_image.filetype + ';base64,' + $scope.product_image.base64;
                        dataUrlImage = null;
                        canvas = null;
                        ctx = null;
                        $scope.$apply();
                    }

                    //if iOS rotate if needed
                    if(isMobile.apple.device || isMobile.apple.ipod || isMobile.apple.phone || isMobile.apple.tablet){
                        resize = document.createElement('img');
                        resize.setAttribute('crossOrigin', 'anonymous');

                        resize.onload = function(event){
                            width = resize.width;
                            height = resize.height;
                            EXIF.getData(img, function(){
                                var orientation = EXIF.getTag(this, "Orientation");
                                canvas = document.createElement('canvas');
                                ctx = canvas.getContext('2d');
                                if(orientation && orientation>4){
                                    canvas.width = height;
                                    canvas.height = width;
                                }
                                else{
                                    canvas.width = width;
                                    canvas.height = height;
                                }
                                switch(orientation){
                                    case 2:
                                        // horizontal flip
                                        ctx.translate(width, 0);ctx.scale(-1, 1);
                                        break;
                                    case 3:
                                        // 180° rotate left
                                        ctx.translate(width, height);ctx.rotate(Math.PI);
                                        break;
                                    case 4:
                                        // vertical flip
                                        ctx.translate(0, height);ctx.scale(1, -1);
                                        break;
                                    case 5:
                                        // vertical flip + 90 rotate right
                                        ctx.rotate(0.5 * Math.PI);ctx.scale(1, -1);
                                        break;
                                    case 6:
                                        // 90° rotate right
                                        ctx.rotate(0.5 * Math.PI);ctx.translate(0, -height);
                                        break;
                                    case 7:
                                        // horizontal flip + 90 rotate right
                                        ctx.rotate(0.5 * Math.PI);ctx.translate(width, -height);ctx.scale(-1, 1);
                                        break;
                                    case 8:
                                        // 90° rotate left
                                        ctx.rotate(-0.5 * Math.PI);ctx.translate(-width, 0);
                                        break;
                                    default:
                                        $scope.imageSrc = 'data:' + $scope.product_image.filetype + ';base64,' + $scope.product_image.base64;
                                        dataUrlImage = null;
                                        resize = null;
                                        img = null;
                                        canvas = null;
                                        ctx = null;
                                        $scope.$apply();
                                        return;
                                }
                                if(orientation && orientation>4){
                                    ctx.drawImage(resize, 0, 0);
                                }
                                else{
                                    ctx.drawImage(resize, 0, 0, width, height);
                                }


                                canvas.setAttribute('crossOrigin', 'anonymous');
                                dataUrlImage = canvas.toDataURL('image/jpeg');
                                $scope.product_image.filetype = 'image/jpeg';
                                $scope.product_image.base64 = dataUrlImage.substr(dataUrlImage.indexOf(',')+1);
                                $scope.imageSrc = 'data:' + $scope.product_image.filetype + ';base64,' + $scope.product_image.base64;
                                dataUrlImage = null;
                                resize = null;
                                img = null;
                                canvas = null;
                                ctx = null;
                                $scope.$apply();
                            });
                        };
                        resize.src = 'data:' + $scope.product_image.filetype + ';base64,' + $scope.product_image.base64;
                    }
                    else{
                        $scope.imageSrc = 'data:' + $scope.product_image.filetype + ';base64,' + $scope.product_image.base64;
                        img = null;
                        $scope.$apply();
                    }
                };
                img.src = 'data:' + $scope.product_image.filetype + ';base64,' + $scope.product_image.base64;
            }
        }
    };

    $scope.loadStart = function () {
        $scope.pinFail = false;
        $scope.imageSrcLoading = true;
    };

    $scope.productEdit = function(myform) {

        var i, tot, vendor_ids = [];
        $scope.loading = true;

        var roomAttrs = RoomAttrsService.getAttrIds(myform);

        myform['id'] = productResolve.id;
        myform['status'] = $scope.prodStatus.status;


        if (!myform.$valid) {
            console.log('productEdit Form Error');
            return;
        }

        var fileBase64 = '';
        // if there is an updated image
        if (myform.product_image && myform.product_image.$viewValue !== undefined) {
            fileBase64 = myform.product_image.$viewValue.base64;
        }

        tot = $scope.vendors.length;
        for(i=0;i<tot;i++){
            vendor_ids.push($scope.vendors[i].id);
        }
        if(!$scope.product.enable_shipping_2day){
            $scope.product.shipping_2day = '';
        }
        if(!$scope.product.enable_shipping_overnight){
            $scope.product.shipping_overnight = '';
        }
        var formData = {
            id: myform.id,
            title: myform.title.$viewValue,
            note: myform.note.$viewValue,
            description: myform.description.$viewValue,
            status: myform.status,
            sku: myform.sku.$viewValue,
            quantity: myform.quantity.$viewValue,
            weight: myform.weight.$viewValue,
            unit: myform.unit.$viewValue,
            price: myform.price.$viewValue,
            price_pro: myform.price_pro.$viewValue,
            mfg: myform.mfg.$viewValue,

            shipping: myform.shipping.$viewValue,
            enable_shipping_2day: $scope.product.enable_shipping_2day,
            shipping_2day: $scope.product.shipping_2day,
            enable_shipping_overnight: $scope.product.enable_shipping_overnight,
            shipping_overnight: $scope.product.shipping_overnight,
            width: myform.width.$viewValue,
            height: myform.height.$viewValue,
            depth: myform.depth.$viewValue,
            leadtime: myform.leadtime.$viewValue,

            product_image: fileBase64,
            room_types: $scope.roomTypes,
            room_attrs: roomAttrs,
            product_cats: $scope.productCats,
            vendor_ids: JSON.stringify(vendor_ids)
        };

        strukshurApiService.postVendorProductEdit.save(formData, function(data){
            console.log('productEdit Success');
            console.log(data);
            $scope.loading = false;
            console.log('promise Success');
            console.log(data);
            $scope.formError = false;
            $state.go('productAdmin.product', {id: $scope.product.id});
        }, function(e){
            console.log('productEdit Failed');
            console.log(e);
            console.log('promise Failed:');
            $scope.formError = true;
            $scope.loading = false;
        });
    };

    $scope.loadVendors = function($query) {
        var deferred = $q.defer();
        var currentUser = strukshurUserService.getUser();
        currentUser.then(function (d) {
            deferred.resolve(d.vendors);
            return d.vendors;
        });

        return deferred.promise;
    };

    $scope.loadRoomTypes = function($query) {
        var deferred = $q.defer();
        strukshurApiService.searchVendorProductRoomTypes.query({terms: $query}, function(d){
            //deferred.reject();
            //$scope.tags = d.room_types;
            deferred.resolve(d.room_types);
            return d.room_types;
        });
        return deferred.promise;
    };


    $scope.loadRoomTags = function($query) {
        var deferred = $q.defer();
        strukshurApiService.searchRoomTags.query({terms: $query, rooms: $scope.roomTypes}, function(d){
            //deferred.reject();
            deferred.resolve(d.room_tags);
            return d.room_tags;
        });
        return deferred.promise;
    };

    $scope.loadProductCategories = function($query) {
        var deferred = $q.defer();
        strukshurApiService.getVendorProductCategories.query({}, function(d){
            //deferred.reject();
            deferred.resolve(d.cats);
            return d.cats;
        });
        return deferred.promise;
    };

})
.controller( 'ProductAdminProductAddCtrl', function ProductAdminProductAddCtrl( $scope, $q, $state, strukshurApiService, fileReaderService, RoomAttrsService, strukshurUserService ) {

    $scope.loading = false;
    $scope.formError = false;
    $scope.enable_shipping_2day = 0;
    $scope.enable_shipping_overnight = 0;
    $scope.other = {
        shipping_2day: '',
        shipping_overnight: ''
    };

    $scope.currentUser = {};
    $scope.isAdmin = false;
    strukshurUserService.getUser().then(function (d) {
        $scope.currentUser = d;
        $scope.isAdmin = strukshurUserService.isAdmin();
    });

    // very important
    $scope.form = {};
    $scope.status = {
        name: 'active'
    };

    $scope.roomTypes = [];
    $scope.productCats = [];
    $scope.vendors = [];

    $scope.cancel = function () {
        $state.go('productAdmin.products');
    };

    $scope.getFile = function () {
        //$scope.progress = 0;
        $scope.$apply();
        fileReaderService.readAsDataUrl($scope.file, $scope)
            .then(function(result) {
                $scope.imageSrc = result;
                $scope.imageSrcLoading = false;
            });
    };

    $scope.loadEnd = function () {
        $scope.imageSrcLoading = false;
    };

    $scope.afterValidate = function (eventObject, fileReader, file) {
        $scope.imageSrcLoading = false;
        if(!file || file.length===0){
            return;
        }

        if($scope.product_image && !$scope.product_image.base64){
            $scope.pinFail = 'Invalid Image';
            $scope.product_image = null;
            $scope.imageSrc = null;
            return;
        }
        if(file[0].size>150*1000*1000){
            $scope.pinFail = 'Image must be less than 150MB';
            $scope.product_image = null;
            $scope.imageSrc = null;
            return;
        }

        if(file[0].type.substr(0,5)!=='image'){
            $scope.product_image = null;
            $scope.imageSrc = null;
        }
        else{
            //resize

            var dataUrlImage;
            var canvas, MAX_WIDTH, MAX_HEIGHT, width, height, ctx, img;
            MAX_WIDTH = 2048;
            MAX_HEIGHT = 2048;


            if ($scope.product_image && $scope.product_image.base64) {

                //compress image
                img = document.createElement('img');
                img.setAttribute('crossOrigin', 'anonymous');

                img.onload = function(ev){
                    var resize;
                    width = img.width;
                    height = img.height;

                    if (width > height) {
                        if (width > MAX_WIDTH) {
                            height *= MAX_WIDTH / width;
                            width = MAX_WIDTH;
                        }
                    } else {
                        if (height > MAX_HEIGHT) {
                            width *= MAX_HEIGHT / height;
                            height = MAX_HEIGHT;
                        }
                    }

                    // Resize only if needed
                    if (width !== img.width || height !== img.height) {
                        canvas = document.createElement('canvas');
                        canvas.width = width;
                        canvas.height = height;
                        ctx = canvas.getContext('2d');
                        ctx.drawImage(img, 0, 0, width, height);

                        canvas.setAttribute('crossOrigin', 'anonymous');

                        dataUrlImage = canvas.toDataURL($scope.product_image.filetype);
                        $scope.product_image.base64 = dataUrlImage.substr(dataUrlImage.indexOf(',')+1);
                        $scope.imageSrc = 'data:' + $scope.product_image.filetype + ';base64,' + $scope.product_image.base64;
                        dataUrlImage = null;
                        canvas = null;
                        ctx = null;
                        $scope.$apply();
                    }

                    //if iOS rotate if needed
                    if(isMobile.apple.device || isMobile.apple.ipod || isMobile.apple.phone || isMobile.apple.tablet){
                        resize = document.createElement('img');
                        resize.setAttribute('crossOrigin', 'anonymous');

                        resize.onload = function(event){
                            width = resize.width;
                            height = resize.height;
                            EXIF.getData(img, function(){
                                var orientation = EXIF.getTag(this, "Orientation");
                                canvas = document.createElement('canvas');
                                ctx = canvas.getContext('2d');
                                if(orientation && orientation>4){
                                    canvas.width = height;
                                    canvas.height = width;
                                }
                                else{
                                    canvas.width = width;
                                    canvas.height = height;
                                }
                                switch(orientation){
                                    case 2:
                                        // horizontal flip
                                        ctx.translate(width, 0);ctx.scale(-1, 1);
                                        break;
                                    case 3:
                                        // 180° rotate left
                                        ctx.translate(width, height);ctx.rotate(Math.PI);
                                        break;
                                    case 4:
                                        // vertical flip
                                        ctx.translate(0, height);ctx.scale(1, -1);
                                        break;
                                    case 5:
                                        // vertical flip + 90 rotate right
                                        ctx.rotate(0.5 * Math.PI);ctx.scale(1, -1);
                                        break;
                                    case 6:
                                        // 90° rotate right
                                        ctx.rotate(0.5 * Math.PI);ctx.translate(0, -height);
                                        break;
                                    case 7:
                                        // horizontal flip + 90 rotate right
                                        ctx.rotate(0.5 * Math.PI);ctx.translate(width, -height);ctx.scale(-1, 1);
                                        break;
                                    case 8:
                                        // 90° rotate left
                                        ctx.rotate(-0.5 * Math.PI);ctx.translate(-width, 0);
                                        break;
                                    default:
                                        $scope.imageSrc = 'data:' + $scope.product_image.filetype + ';base64,' + $scope.product_image.base64;
                                        dataUrlImage = null;
                                        resize = null;
                                        img = null;
                                        canvas = null;
                                        ctx = null;
                                        $scope.$apply();
                                        return;
                                }
                                if(orientation && orientation>4){
                                    ctx.drawImage(resize, 0, 0);
                                }
                                else{
                                    ctx.drawImage(resize, 0, 0, width, height);
                                }


                                canvas.setAttribute('crossOrigin', 'anonymous');
                                dataUrlImage = canvas.toDataURL('image/jpeg');
                                $scope.product_image.filetype = 'image/jpeg';
                                $scope.product_image.base64 = dataUrlImage.substr(dataUrlImage.indexOf(',')+1);
                                $scope.imageSrc = 'data:' + $scope.product_image.filetype + ';base64,' + $scope.product_image.base64;
                                dataUrlImage = null;
                                resize = null;
                                img = null;
                                canvas = null;
                                ctx = null;
                                $scope.$apply();
                            });
                        };
                        resize.src = 'data:' + $scope.product_image.filetype + ';base64,' + $scope.product_image.base64;
                    }
                    else{
                        $scope.imageSrc = 'data:' + $scope.product_image.filetype + ';base64,' + $scope.product_image.base64;
                        img = null;
                        $scope.$apply();
                    }
                };
                img.src = 'data:' + $scope.product_image.filetype + ';base64,' + $scope.product_image.base64;
            }
        }
    };

    $scope.loadStart = function () {
        $scope.pinFail = false;
        $scope.imageSrcLoading = true;
    };

    //load from user object
    $scope.loadVendors = function($query) {
        var deferred = $q.defer();
        var currentUser = strukshurUserService.getUser();
        currentUser.then(function (d) {
            deferred.resolve(d.vendors);
            return d.vendors;
        });

        return deferred.promise;
    };

    $scope.loadRoomTypes = function($query) {
        var deferred = $q.defer();
        strukshurApiService.searchVendorProductRoomTypes.query({terms: $query}, function(d){
            //deferred.reject();
            //$scope.tags = d.room_types;
            deferred.resolve(d.room_types);
            return d.room_types;
        });
        return deferred.promise;
    };


    $scope.roomsTax = [];

    $scope.roomAdded = function($tag) {
        // clear and start over
        $scope.roomsTax = [];

        angular.forEach($scope.roomTypes, function(value, key) {
            console.log('sending');
            console.log(value);
            strukshurApiService.searchVendorRoomProps.query({terms: '', rooms: [value]}, function(d){
                $scope.roomProps = d.room_props;
                $scope.roomsTax.push({'roomName': value.name, 'roomResults': d.room_props});
            });
        });
    };


    $scope.subAttrsSave = [];
    $scope.subAttrAdd = function($tag) {
        console.log('subAttrsSave');
        console.log($scope.subAttrsSave);
    };
    $scope.loadSubAttrs = function($query, subProperty) {
        var deferred = $q.defer();
        strukshurApiService.searchSubAttrs.query({terms: $query, sub_prop_id: subProperty.id}, function(d){
            //$scope.tags = d.room_types;
            deferred.resolve(d.attrs);
            return d.attrs;
        });
        return deferred.promise;
    };


    $scope.loadProductCategories = function($query) {
        var deferred = $q.defer();
        strukshurApiService.getVendorProductCategories.query({}, function(d){
            //deferred.reject();
            deferred.resolve(d.cats);
            return d.cats;
        });
        return deferred.promise;
    };

    $scope.productAdd = function(myform) {

        var i, tot, vendor_ids = [];

        // checked is
        // myform.attr-1161.$viewValue = true;

        var roomAttrs = RoomAttrsService.getAttrIds(myform);

        if (!myform.$valid) {
            return;
        }

        $scope.loading = true;

        var fileBase64 = '';
        if (myform.product_image) {
            fileBase64 = myform.product_image.$viewValue.base64;
        }

        tot = $scope.vendors.length;
        for(i=0;i<tot;i++){
            vendor_ids.push($scope.vendors[i].id);
        }
        if(!$scope.enable_shipping_2day){
            $scope.other.shipping_2day = '';
        }
        if(!$scope.enable_shipping_overnight){
            $scope.other.shipping_overnight = '';
        }
        var formData = {
            title: myform.title.$viewValue,
            note: myform.note.$viewValue,
            description: myform.description.$viewValue,
            status: myform.status.$viewValue,
            sku: myform.sku.$viewValue,
            quantity: myform.quantity.$viewValue,
            weight: myform.weight.$viewValue,
            unit: myform.unit.$viewValue,
            price: myform.price.$viewValue,
            price_pro: myform.price_pro.$viewValue,
            mfg: myform.mfg.$viewValue,

            shipping: myform.shipping.$viewValue,
            enable_shipping_2day: $scope.enable_shipping_2day,
            shipping_2day: $scope.other.shipping_2day,
            enable_shipping_overnight: $scope.enable_shipping_overnight,
            shipping_overnight: $scope.other.shipping_overnight,
            width: myform.width.$viewValue,
            height: myform.height.$viewValue,
            depth: myform.depth.$viewValue,
            leadtime: myform.leadtime.$viewValue,

            product_image: fileBase64,
            room_types: $scope.roomTypes,
            room_attrs: roomAttrs,
            product_cats: $scope.productCats,
            vendor_ids: JSON.stringify(vendor_ids)
        };


        strukshurApiService.postVendorProductAdd.save(formData, function(data){
            console.log('productAdd Success');
            console.log(data);
            $scope.loading = false;
            console.log('promise Success');
            console.log(data);
            $scope.formError = false;
            $state.go('productAdmin.products');
        }, function(e){
            console.log('productAdd Failed');
            console.log(e);
            $scope.formError = true;
            console.log('promise Failed:');
            $scope.loading = false;
        });



    };
})
.controller( 'OrderSelectModalCtrl', function OrderSelectModalCtrl( $scope, $uibModalInstance, $state, strukshurApiService, product ) {
    $scope.loading = false;
    $scope.errorMessage = false;
    $scope.product = product;
    $scope.terms = {};
    $scope.total = 0;
    $scope.perPage = 5;
    $scope.orders = [];

    $scope.assignOrder = function (suborder) {
        var data;
        if($scope.loading){
            return;
        }
        $scope.loading = true;
        data = {
            suborder_id: suborder.suborder.id,
            product_id: product.id,
            quantity: 1
        };
        strukshurApiService.productOrderDetails.add(data, function (d) {
            $scope.loading = false;
            $state.go('productAdmin.order', {suborder_id: suborder.suborder.id});
            if(d && d.note && $scope.notes){
                $scope.notes.push(d.note);
            }
            $uibModalInstance.close('ok');
        }, function (e) {
            $scope.loading = false;
        });
    };

    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');

    };

    $scope.calculateTotalPages = function() {
        var totalPages = $scope.perPage < 1 ? 1 : Math.ceil($scope.total / $scope.perPage);
        return Math.max(totalPages || 0, 1);
    };

    $scope.totalSuborderSelect = function (order) {
        var i, tot, amount = 0.0;

        tot = order.suborder.productOrders.length;
        for(i=0;i<tot;i++){
            amount = amount + (parseFloat(order.suborder.productOrders[i].price)*parseFloat(order.suborder.productOrders[i].quantity)) + parseFloat(order.suborder.productOrders[i].tax) + parseFloat(order.suborder.productOrders[i].shippingPrice);
        }
        return amount;
    };

    //get pending suborders
    $scope.terms.status = ['pending','processing'];
    strukshurApiService.ordersSeller.query({page: 1, terms: JSON.stringify($scope.terms)}, function(d) {
        var i, tot, i2, tot2;
        $scope.total = d.total;
        $scope.perPage = d.perPage;
        $scope.orders = d.orders;
        //calculate total items
        tot2 = $scope.orders.length;
        for(i2=0;i2<tot2;i2++){
            tot = $scope.orders[i2].suborder.productOrders.length;
            $scope.orders[i2].suborder.tot_items = 0;
            for(i=0;i<tot;i++){
                $scope.orders[i2].suborder.tot_items = $scope.orders[i2].suborder.tot_items + $scope.orders[i2].suborder.productOrders[i].quantity;
            }
            $scope.orders[i2].tot_amount = $scope.totalSuborderSelect($scope.orders[i2]);
        }

        //$scope.page++;
        $scope.totalPages = $scope.calculateTotalPages();

    }, function(e) {

        return false;
    });
})
;
