import * as angular from 'angular';

angular.module('CartDirective', [])
.directive('cart', function (CartService) {
    return {
        restrict: 'EA',
        template: require('./cart_icon.tpl.html'),
        scope: {},
        controllerAs: "dir",
        bindToController: true,
        controller: function ($scope, $state, $element, $attrs) {
            var vm = this;

            vm.items = CartService.hasItems();
            vm.approved = CartService.getApproved();

            vm.getQuantity = function () {
                var i, tot, quantity = 0;
                if (vm.items === null || vm.items === false || vm.items.length === 0) {
                    return quantity;
                }

                tot = vm.items.length;
                for (i = 0; i < tot; i++) {
                    quantity += vm.items[i].quantity;
                }

                return quantity;
            };

            $scope.$on('cartUpdate', function (event, args) {
                console.log('cart was updated');
                vm.approved = CartService.getApproved();
                vm.items = CartService.hasItems();
            });
        }
    };
});
