import * as angular from 'angular';
import * as moment from 'moment';

angular.module('userResolveService', [])
.factory('userResolveService', function ($q, $state, strukshurUserService) {

    // Only allow enabled/validated users to view this page
    return function () {
        var deferred = $q.defer();

        return strukshurUserService.getUser().then(function (res) {
            if (strukshurUserService.isEnabled() === false) {
                $state.go('home');

                return false;
            } else if (res.userType !== 'admin' && !res.proConfirmed && res.plan !== 'basic' && res.plan !== 'pro-basic' &&
                (moment(new Date()).diff(res.createdAt, 'days') > 30 || res.userType === 'standard' ||
                (res.plan !== 'pro-monthly-tier-1' && res.plan !== 'pro-annual-tier-1')))
            {
                if (res.userType === 'standard') {
                    $state.go('register-payment');
                } else {
                    $state.go('pro-register-payment');
                }

                return false;
            }

            deferred.resolve(res);

            return deferred.$promise;
        }, function (e) {
            deferred.reject(e);

            return deferred.$promise;
        });
    };
});
