import {
    clone as _clone,
    filter as _filter,
    find as _find,
    pull as _pull,
    remove as _remove,
} from 'lodash';
import { findDeep } from 'deepdash-es/standalone';
import * as angular from 'angular';
import * as EXIF from 'exif-js';
import * as isMobile from 'ismobilejs';
import { saveAs } from 'file-saver';

import strukshurConfig from '../../../config.ts';

angular.module('strukshurApp.projects.finishes', ['strukshurApp.projects.rooms'])

.config(function config($stateProvider, $uibModalProvider) {
    $uibModalProvider.options = { animation: true, backdrop: 'static', keyboard: false, ariaLabelledBy: 'modal-title', ariaDescribedBy: 'modal-body' };

    $stateProvider
        .state('projects-detail.finishes', {
            url: '/finishes',
            views: {
                '': {
                    controller: 'ProjectDetailFinishesCtrl',
                    template: require('./project.finishes.tpl.html')
                },
            },
            data: { pageTitle: 'Project / Finishes', class: 'projects projects-detail-finishes' }
        })
    ;
})

.controller('ProjectDetailFinishesCtrl', function ProjectDetailFinishesCtrl ($http, $scope, $state, $timeout, $uibModal, FileService, roomService, smoothScroll, strukshurApiService, strukshurProjectService, strukshurUtilService) {
    var vm = $scope;

    // Check if the user can access the current page or not
    if (!vm.$parent.checkPermission('project_finish_view')) {
        return $state.go('projects-detail.access-denied', { section: 'Finishes' });
    }

    vm.rooms = [];
    vm.loading = true;
    vm.searchTerm = '';
    vm.currentUser = null;
    vm.loadingSpreadsheet = false;
    vm.availableStatus = [
        { id: 'I', title: 'Incomplete' },
        { id: 'A', title: 'Needs attention' },
        { id: 'C', title: 'Completed' },
    ];

    /**
     * Controller init logic
     */
    vm.init = () => {

        // Updates current route info on the project menu scope
        vm.$parent.currentRoute = $state.current.name;

        // Load project rooms
        strukshurApiService.projectRooms.list({ 'project_id': vm.$parent.project.id }).$promise
            .then((res) => {
                res.rooms.forEach((room) => {
                    room.finishes = [];
                });

                vm.loading = false;
                vm.rooms = res.rooms;
                vm.currentUser = vm.$parent.currentUser;

                vm.updatePageItems();

                // Load project finishes
                strukshurApiService.projectFinishes.list({ 'project_id': vm.$parent.project.id }).$promise
                    .then((res) => {

                        // Properly set the finishes for the correct project rooms
                        res.projectFinishes.forEach((finish) => {
                            const room = _find(vm.rooms, { id: finish.project_room_id });
                            if (room) {
                                finish.selectedStatus = _find(vm.availableStatus, (status) => status.id === finish.status);
                                room.finishes.push(finish);

                                // Handles video file transcoding status
                                finish.files.forEach((file) => {
                                    file.file = FileService.encodeFileUrlForS3(file.file);

                                    if (file.isVideo && file.isProcessing) {
                                        strukshurProjectService.finishesVideoStatus(file);
                                    }
                                });
                            }
                        });

                        // Sets finish and props based on the kind of project room
                        vm.rooms.forEach((room) => {
                            room.room.roomProps.forEach((prop) => {
                                prop.finishes = [];

                                // Add the finishes to the room prop if any exists
                                const finishes = _filter(room.finishes, { project_room_id: room.id, prop_id: prop.id });
                                if (finishes) {
                                    prop.finishes = (Array.isArray(finishes)) ? finishes : [finishes];
                                }
                            });
                        });
                    })
                ;
            })
        ;
    };


    /**
     * Checks if the current user has the given permission on the project
     *
     * @param  {string}  permission  The permission key
     *
     * @return {boolean}
     */
    vm.checkPermission = (permission) => {
        return vm.$parent.checkPermission(permission);
    };

    /**
     * Generates spreadsheet file for all finishes set on the project
     */
    vm.exportSpreadsheet = () => {
        vm.loadingSpreadsheet = true;

        // Downloads spreadsheet
        $http({
            url: strukshurConfig.api_url + '/project/finishes/spreadsheet?project_id=' + vm.$parent.project.id,
            method: 'GET',
            params: {},
            headers: { 'Content-type': 'text/vnd.ms-excel; charset=utf-8' },
            responseType: 'arraybuffer'
        })
            .then((data) => {
                vm.loadingSpreadsheet = false;

                saveAs(new Blob([data.data], { type: 'text/vnd.ms-excel' }), 'strukshur_' + vm.$parent.project.slug + '_finishes.xls');
            })
            .catch(() => {
                vm.loadingSpreadsheet = false;
            })
        ;
    };

    /**
     * Opens the modal for adding a new project room
     */
    vm.newRoom = () => {
        var modalInstance = $uibModal.open({
            scope: vm,
            keyboard: true,
            controller: 'ProjectDetailNewRoomCtrl',
            template: require('../../../common/templates/projects.newRoomModal.tpl.html'),
            resolve: {
                project: vm.$parent.project
            }
        });

        modalInstance.result.then(

            // Resolved callback
            (data) => {
                if (data.room) {
                    data.room.finishes = [];
                    vm.rooms.push(data.room);
                    vm.updatePageItems();
                }
            },

            // Rejected callback
            angular.noop
        );
    };

    /**
     * Opens the modal for editing a room
     */
    vm.editRoom = (room) => {
        var modalInstance = $uibModal.open({
            scope: vm,
            keyboard: true,
            controller: 'ProjectDetailEditRoomCtrl',
            template: require('../../../common/templates/projects.editRoomModal.tpl.html'),
            resolve: {
                room: () => room,
            }
        });

        modalInstance.result.then(

            // Resolved callback
            (data) => {
                if (data.room) {
                    room.name = data.room.name;
                    vm.updatePageItems();
                }
            },

            // Rejected callback
            angular.noop
        );
    };

    /**
     * Deletes the received room on the server
     *
     * @param  {object}  room  The user room
     */
    vm.deleteRoom = (room) => {
        var $childScope = vm.$new();
        $childScope.room = room;
        $childScope.title = 'Delete room';
        $childScope.message = 'Are you sure you want to delete the room and all of its related information?';

        var modalInstance = $uibModal.open({
            keyboard: true,
            scope: $childScope,
            controller: 'ProjectDetailDeleteRoomCtrl',
            template: require('../../../common/templates/base.confirm-modal.tpl.html')
        });

        modalInstance.result.then(

            // Resolved callback
            (data) => {
                _pull(vm.rooms, room);
                vm.updatePageItems();
            },

            // Rejected callback
            angular.noop
        );
    };

    /**
     * Handles the selection of a prop for a given room
     *
     * @param  {object}   room          The project room
     * @param  {object}   prop          The room property
     * @param  {Boolean}  shouldScroll  Wether we should scroll to the prop
     * @param  {Event}    $event        The event object
     */
    vm.selectProp = (room, prop, shouldScroll, $event) => {
        $event.preventDefault();
        $event.stopPropagation();

        if (!prop.finishes) { prop.finishes = []; }

        // Closes dropdown menu since we don't need it visible at this time
        room.isFinishMenuOpen = false;

        // Toggle active class for all room finishes
        room.room.roomProps.forEach((prop) => prop.active = false);
        prop.active = true;

        // Create list of available finishes based on the prop attrs and existing finishes
        prop.availableFinishes = _clone(prop.attrs);
        prop.finishes.forEach((finish) => {
            if (finish.attr_id) {
                _remove(prop.availableFinishes, (o: any) => o.id === finish.attr_id);
            }
        });

        // Add option for creating new custom finishes
        prop.availableFinishes.push({ id: -1, name: '- new custom finish -' });

        room.selectedProp = prop;

        if (shouldScroll) {
            smoothScroll(document.getElementById('room-'+room.id+'-selectedProp'), { offset: 80 });
        }
    };

    /**
     * Handles the selection of a finish for a given room
     *
     * @param  {Object}  room    The project room
     * @param  {Object}  prop    The room property
     * @param  {Object}  finish  The finish property
     * @param  {Event}   $event  The event object
     */
    vm.selectFinish = (room, prop, finish, $event) => {

        // Calls select prop to assemble basic detail view
        vm.selectProp(room, prop, false, $event);

        // Scrolls to the received finish instead
        $timeout(() => smoothScroll(document.getElementById('project-finish-'+finish.id), { offset: 80 }), 100);
    };

    /**
     * Deselect any finish from the given room
     *
     * @param  {Object}  room  The project room
     */
    vm.deselectFinish = (room) => {
        room.selectedProp = null;
        room.room.roomProps.forEach((prop) => prop.active = false);
    };

    /**
     * Adds a new finish inside the given project room
     *
     * @param  {Object}  projectRoom  The project room
     * @param  {Object}  attr         The room attr
     * @param  {Object}  $select      The ui select component
     */
    vm.addNewFinish = (projectRoom, attr, $select) => {
        $select.selected = null;

        // Handles the selection of the new custom finish option
        if (attr.id === -1) {
            projectRoom.selectedProp.addingNewFinish = true;
            $timeout(() => {
                let newFinishTitle = document.querySelector('#new-finish-title-' + projectRoom.id) as HTMLElement;
                newFinishTitle.focus();
            }, 200);
            return;
        }

        const data = {
            project_id: vm.$parent.project.id,
            room_id: projectRoom.id,
            prop_id: projectRoom.selectedProp.id,
            attr_id: attr.id,
            title: attr.name
        };

        // Save the new finish for the given project room
        projectRoom.saving = true;
        projectRoom.errorMessage = '';
        strukshurApiService.projectFinish.create(data).$promise
            .then((res) => {
                if (res.projectFinish) {
                    res.projectFinish.files = [];
                    res.projectFinish.notes = [];
                    res.projectFinish.showCopyAlert = true;
                    projectRoom.finishes.push(res.projectFinish);
                    projectRoom.selectedProp.finishes.push(res.projectFinish);

                    // Scrolls to newly created finish
                    $timeout(() => smoothScroll(document.getElementById('project-finish-' + res.projectFinish.id), { offset: 80 }), 100);

                    // Hides alert to copy newly added finish to other rooms
                    $timeout(() => res.projectFinish.showCopyAlert = false, 10000);

                    // Remove finish from list of available options
                    _remove(projectRoom.selectedProp.availableFinishes, { id: attr.id });
                }
            })
            .catch((res) => {
                if (res.status === 403) {
                    projectRoom.errorMessage = 'You don\'t have the necessary permission to add the finish to the room.';
                } else {
                    projectRoom.errorMessage = 'There was an error adding the finish to the room.';
                }
            })
            .finally(() => projectRoom.saving = false)
        ;
    };

    /**
     * Saves the new custom finish to the server
     *
     * @param  {object}  projectRoom  The project room
     */
    vm.saveNewCustomFinish = (projectRoom) => {
        if (projectRoom.saving) { return; }

        const data = {
            project_id: vm.$parent.project.id,
            room_id: projectRoom.id,
            prop_id: projectRoom.selectedProp.id,
            title: projectRoom.selectedProp.newFinishTitle,
        };

        // Save the new finish for the given project room
        projectRoom.saving = true;
        projectRoom.errorMessage = '';
        strukshurApiService.projectFinish.create(data).$promise
            .then((res) => {
                if (res.projectFinish) {
                    res.projectFinish.files = [];
                    res.projectFinish.notes = [];
                    res.projectFinish.selectedStatus = _find(vm.availableStatus, (status) => status.id === res.projectFinish.status);

                    projectRoom.finishes.push(res.projectFinish);
                    projectRoom.selectedProp.finishes.push(res.projectFinish);

                    // Scrolls to newly created finish
                    $timeout(() => smoothScroll(document.getElementById('project-finish-' + res.projectFinish.id), { offset: 80 }), 100);
                }
            })
            .catch((res) => {
                if (res.status === 403) {
                    projectRoom.errorMessage = 'You don\'t have the necessary permission to add the new finish to the room.';
                } else {
                    projectRoom.errorMessage = 'There was an error adding the new finish to the room.';
                }
            })
            .finally(() => {
                projectRoom.saving = false;
                projectRoom.selectedProp.addingNewFinish = false;
            })
        ;
    };

    /**
     * Cancels addition of new finish
     *
     * @param  {Object}  projectRoom  The project room
     */
    vm.cancelNewFinish = (projectRoom) => {
        projectRoom.selectedProp.addingNewFinish = false;
    };

    /**
     * Closes alert to copy new finish to other rooms
     *
     * @param  {Object}  finish  The newly added finish
     * @param  {Event}   $event  The event object
     */
    vm.closeFinishCopyAlert = (finish, $event) => {
        $event.preventDefault();
        $event.stopPropagation();
        finish.showCopyAlert = false;
    };

    /**
     * Opens modal to copy the received finish to other rooms
     *
     * @param  {Object}  room    The room the finish is a part of
     * @param  {Object}  finish  The finish to copy
     * @param  {Event}   $event  The event object
     */
    vm.copyFinish = (room, finish, $event) => {
        $event.preventDefault();

        // Early exit if we only have one room in the project
        if (vm.rooms.length === 1 && vm.$parent.availableProjects.length === 1) {
            return vm.openAddMoreRoomsInfoDialog();
        }

        const modalInstance = $uibModal.open({
            scope: vm,
            keyboard: true,
            controller: 'ProjectDetailCopyFinishCtrl',
            template: require('../../../common/templates/projects.copyProjectFinishModal.tpl.html'),
            resolve: {
                currentRoom: () => room,
                finish: () => finish,
                project: () => vm.$parent.project,
                projectRooms: () => vm.rooms,
            }
        });

        modalInstance.result.then(

            // Resolved callback
            (data) => {
                if (data.finishes && data.project.id === vm.$parent.project.id) {

                    // For now because of lack of ids in the return API method
                    // we're doing a second request to retrieve the newly added
                    // finishes when the destination project is the current one
                    strukshurApiService.projectFinishes.list({ project_id: vm.$parent.project.id }).$promise
                        .then((res) => {
                            res.projectFinishes.forEach((finish) => {

                                // We ignore the finishes already present in the UI since we're only
                                // interested in adding the new ones
                                let found = findDeep(vm.rooms, (item) => item.id === finish.id, { pathFormat: 'array', childrenPath: ['finishes'] });
                                if (found && found.value) { return; }

                                // Finds the room the finish has been copied to
                                const room = _find(vm.rooms, { id: finish.project_room_id });

                                // Adds required finishes attr if it's not present
                                if (!room.finishes) { room.finishes = []; }

                                // Adds the finish to the room
                                room.finishes.push(finish);

                                // Adds the finish to the room prop
                                var prop = _find(room.room.roomProps, { id: finish.prop_id });

                                // Adds required finishes attr if it's not present
                                if (!prop.finishes) { prop.finishes = []; }

                                prop.finishes.push(finish);
                            });
                        })
                    ;
                }
            },

            // Rejected callback
            angular.noop
        );
    };

    /**
     * Displays error message if there's only a room in the project
     */
    vm.openAddMoreRoomsInfoDialog = () => {
        const $childScope = vm.$new();
        $childScope.title = 'Not enough rooms';
        $childScope.message = 'You need to have more than one room in order to copy the finish.';

        const modalInstance = $uibModal.open({
            keyboard: true,
            scope: $childScope,
            controller: 'ProjectDetailNotEnoughRoomsMessageCtrl',
            template: require('../../../common/templates/base.info-modal.tpl.html')
        });

        modalInstance.result.then(angular.noop, angular.noop);
    };

    /**
     * Opens the modal for editing a finish
     *
     * @param  {Object}  room    The project room
     * @param  {Object}  finish  The project finish
     */
    vm.editFinish = (room, finish) => {
        var modalInstance = $uibModal.open({
            scope: vm,
            keyboard: true,
            controller: 'ProjectDetailEditFinishCtrl',
            template: require('../../../common/templates/projects.editProjectFinishModal.tpl.html'),
            resolve: {
                finish: () => finish,
            }
        });

        modalInstance.result.then(

            // Resolved callback
            (data) => {
                if (data.projectFinish) {
                    finish.title = data.projectFinish.title;
                }
            },

            // Rejected callback
            angular.noop
        );
    };

    /**
     * Removes the received finish from the server
     *
     * @param  {Object}  room    The project room
     * @param  {Object}  finish  The project finish
     */
    vm.removeFinish = (room, finish) => {
        const $childScope = vm.$new();
        $childScope.finish = finish;
        $childScope.project = vm.$parent.project;
        $childScope.title = 'Delete finish';
        $childScope.message = 'Are you sure you want to delete this finish?';

        const modalInstance = $uibModal.open({
            keyboard: true,
            scope: $childScope,
            controller: 'ProjectDetailFinishDeleteCtrl',
            template: require('../../../common/templates/confirm-modal.tpl.html')
        });

        modalInstance.result.then(

            // Resolved callback
            (data) => {

                // Removes finish from the UI
                _pull(room.finishes, finish);
                _pull(room.selectedProp.finishes, finish);

                // Re-add it to the available options list if it's an existing room finish
                if (finish.attr_id) {
                    const attr = _find(room.selectedProp.attrs, { id: finish.attr_id });
                    if (attr) {
                        room.selectedProp.availableFinishes.push(attr);
                    }
                }
            },

            // Rejected callback
            angular.noop
        );
    };

    /**
     * Updates the given finish description on the server
     *
     * @param  {object}  finish  The project finish
     */
    vm.updateFinishDescription = (finish) => {

        // Only proceed with the update if the description has indeed changed
        if (finish.currentDescription === finish.description) { return; }

        const data = {
            project_finish_id: finish.id,
            description: finish.description
        };

        finish.saving = true;
        finish.errorMessage = '';
        strukshurApiService.projectFinish.update(data).$promise
            .then(angular.noop)
            .catch((res) => {
                if (res.status === 403) {
                    finish.errorMessage = 'You don\'t have the necessary permission to update the finish description.';
                } else {
                    finish.errorMessage = 'There was an error trying to update the finish description.';
                }
            })
            .finally(() => finish.saving = false)
        ;
    };

    /**
     * Updates the given finish with the new status on the server
     *
     * @param  {object}  finish  The project finish
     */
    vm.updateFinishStatus = (finish) => {
        const data = {
            project_finish_id: finish.id,
            status: finish.selectedStatus.id,
        };

        finish.saving = true;
        finish.errorMessage = '';
        strukshurApiService.projectFinish.update(data).$promise
            .then(angular.noop)
            .catch((res) => {
                if (res.status === 403) {
                    finish.errorMessage = 'You don\'t have the necessary permission to update the finish description.';
                } else {
                    finish.errorMessage = 'There was an error trying to update the finish description.';
                }
            })
            .finally(() => finish.saving = false)
        ;
    };

    /**
     * Sets the current description to track if it was changed later
     *
     * @param  {Object}  finish  The project finish
     */
    vm.setFinishDescriptionFocus = (finish) => {
        finish.currentDescription = finish.description;
    };

    /**
     * Adds a new note to the finishes section
     *
     * @param  {Object}  projectRoom  The project room
     * @param  {Object}  finish       The project finish
     */
    vm.newFinishNote = function (projectRoom, finish) {

        // Does not allow to add a new note if a previous one is still being processed
        if (finish.addingNote) { return; }

        if (!finish.notes) { finish.notes = []; }

        // Create local note with temp id and add it to the finish
        var note = {
            id: (new Date()).getTime(),
            owner_id: null,
            content: '',
            saving: true,
            createdAt: new Date(),
        };
        finish.notes.push(note);

        // Scrolls to the
        $timeout(function() {
            smoothScroll(document.getElementById('project-finish-note-' + note.id), { offset: 80 });
        }, 100);

        var data = {
            content: '',
            project_id: vm.$parent.project.id,
            project_room_id: projectRoom.id,
            project_finish_id: finish.id,
        };

        // Saves the note on the server
        finish.addingNote = true;
        finish.errorMessage = '';
        strukshurApiService.projectFinishNote.create(data).$promise
            .then(function (res) {
                note.saving = false;
                note.id = res.projectFinishNote.id;
                note.owner_id = res.projectFinishNote.owner_id;
            })
            .catch(function (res) {
                if (res.status === 403) {
                    finish.errorMessage = 'You don\'t have the necessary permission to add the note.';
                } else {
                    finish.errorMessage = 'There was an error trying to add the new note.';
                }

                // Remove the note from the finish
                _pull(finish.notes, note);
            })
            .finally(function () {
                finish.addingNote = false;
            })
        ;
    };

    /**
     * Updates the given project finish note
     *
     * @param  {Object}  finish  The project finish
     * @param  {Object}  note    The project finish note
     */
    vm.updateFinishNote = function (finish, note) {

        // Only proceed with the update if the note has indeed changed
        if (note.currentContent === note.content) { return; }

        var data = {
            note_id: note.id,
            content: note.content
        };

        note.saving = true;
        finish.errorMessage = '';
        strukshurApiService.projectFinishNote.update(data).$promise
            .then(angular.noop)
            .catch(function (res) {
                if (res.status === 403) {
                    finish.errorMessage = 'You don\'t have the necessary permission to update the note.';
                } else {
                    finish.errorMessage = 'There was an error trying to update the note.';
                }
            })
            .finally(function () {
                note.saving = false;
            })
        ;
    };

    /**
     * Sets the current note content to track if it was changed later
     *
     * @param  {Object}  note  The project finish note
     */
    vm.setFinishNoteFocus = function (note) {
        note.currentContent = note.content;
    };

    /**
     * Removes the received finish note from the server
     *
     * @param  {Object}  finish  The project finish
     * @param  {Object}  note    The project finish note
     */
    vm.removeNote = function (finish, note) {
        if (note.saving) { return; }

        var $childScope = vm.$new();
        $childScope.note = note;
        $childScope.project = vm.$parent.project;
        $childScope.title = 'Delete note';
        $childScope.message = 'Are you sure you want to delete this note?';

        var modalInstance = $uibModal.open({
            keyboard: true,
            scope: $childScope,
            controller: 'ProjectDetailFinishNoteDeleteCtrl',
            template: require('../../../common/templates/confirm-modal.tpl.html')
        });

        modalInstance.result.then(

            // Resolved callback
            data => _pull(finish.notes, note),

            // Rejected callback
            angular.noop
        );
    };

    /**
     * Triggers a file select event for the given finish
     *
     * @param  {object}  finish  The finish to select the file for
     */
    vm.selectFile = (finish) => {
        angular.element('#file-' + finish.id).click();
    };

    /**
     * Handles selection of a new image for the given project finish
     */
    vm.projectFinishFileSelected = (eventObject, fileReader, file) => {
        var input = angular.element(eventObject.target),
            roomId = parseInt(input.attr('data-room'), 10),
            finishId = parseInt(input.attr('data-finish'), 10);

        var room = _find(vm.rooms, { id: roomId });
        var finish = _find(room.finishes, { id: finishId });

        const fileData = 'data:'+finish.newFile.filetype+';base64,'+finish.newFile.base64;
        var data = {
            project_id: vm.$parent.project.id,
            project_finish_id: finish.id,
            project_room_id: room.id,
            prop_id: finish.prop_id,
            attr_id: finish.attr_id,
            file: fileData,
            filename: finish.newFile.filename,
            mimetype: finish.newFile.filetype
        };

        // Resize and fix orientation if the file is a supported image
        if (FileService.isAllowedImageType(file[0])) {
            FileService.resizeImageBlob(file[0])
                .then((resized) => {
                    FileService.blobToDataURL(resized)
                        .then((dataUrl) => {
                            data.file = dataUrl;
                            vm.projectFinishesUpload(data, finish);
                        })
                    ;
                })
            ;
        } else {
            vm.projectFinishesUpload(data, finish);
        }
    };

    vm.projectFinishesUpload = (data, finish) => {
        var modalInstance;

        // Open upload progress modal
        modalInstance = $uibModal.open({
            scope: $scope,
            controller: 'ProjectProgressModalCtrl',
            template: require('../../../common/templates/progress-modal.tpl.html'),
            resolve: {}
        });
        modalInstance.result.then(angular.noop, angular.noop);

        finish.uploading = true;
        finish.errorMessage = '';
        finish.upgradeLink = false;
        strukshurApiService.projectFinishFile.create(data).$promise
            .then((res) => {
                if (!finish.files) { finish.files = []; }

                finish.newFile = null;
                res.projectFinishFile.file = FileService.encodeFileUrlForS3(res.projectFinishFile.file);
                finish.files.unshift(res.projectFinishFile);

                if (res.projectFinishFile.isVideo && res.projectFinishFile.isProcessing) {
                    strukshurProjectService.finishesVideoStatus(res.projectFinishFile);
                }
            })
            .catch((res) => {
                finish.errorMessage = (res.data.message?res.data.message:'');

                if (res.status === 403) {
                    finish.errorMessage = 'You don\'t have the necessary permission to add the file.';
                } else if (finish.errorMessage.indexOf('enough storage') !==-1 && finish.errorMessage.indexOf('Upgrade') !== -1) {
                    finish.upgradeLink = true;
                } else {
                    finish.errorMessage = 'There was an error trying to add the file.';
                }
            })
            .finally(() => {
                modalInstance.close();
                finish.uploading = false;
            })
        ;
    };

    /**
     * Opens the modal to select a file to add to the finish
     *
     * @param  {Object}  projectRoom  The project room
     * @param  {object}  finish       The finish
     */
    vm.openFilePickerModal = (projectRoom, finish) => {
        const modalInstance = $uibModal.open({
            keyboard: true,
            scope: vm.$new(),
            controller: 'FilePickerModal',
            template: require('../../../common/templates/projects.filePickerModal.tpl.html'),
            resolve: {
                options: { project: vm.$parent.project },
            },
        });

        modalInstance.result.then(

            // Resolved callback
            res => {
                const file = res.selected[0];

                // Open upload progress modal
                const progressModal = $uibModal.open({
                    scope: $scope,
                    controller: 'ProjectProgressModalCtrl',
                    template: require('../../../common/templates/progress-modal.tpl.html'),
                    resolve: {}
                });
                progressModal.result.then(angular.noop, angular.noop);

                const data = {
                    project_id: vm.$parent.project.id,
                    project_finish_id: finish.id,
                    project_file_id: file.id,
                    project_room_id: projectRoom.id,
                    prop_id: finish.prop_id,
                    attr_id: finish.attr_id,
                };

                finish.uploading = true;
                finish.errorMessage = '';
                finish.upgradeLink = false;
                strukshurApiService.projectFinishFile.createFromFile(data).$promise
                    .then((res) => {
                        if (!finish.files) { finish.files = []; }

                        finish.newFile = null;
                        finish.files.unshift(res.projectFinishFile);

                        if (res.projectFinishFile.isVideo && res.projectFinishFile.isProcessing) {
                            strukshurProjectService.finishesVideoStatus(res.projectFinishFile);
                        }
                    })
                    .catch((res) => {
                        finish.errorMessage = (res.data.message?res.data.message:'');

                        if (res.status === 403) {
                            finish.errorMessage = 'You don\'t have the necessary permission to add the file.';
                        } else if (finish.errorMessage.indexOf('enough storage') !==-1 && finish.errorMessage.indexOf('Upgrade') !== -1) {
                            finish.upgradeLink = true;
                        } else {
                            finish.errorMessage = 'There was an error trying to add the file.';
                        }
                    })
                    .finally(() => {
                        progressModal.close();
                        finish.uploading = false;
                    })
                ;
            },

            // Rejected callback
            angular.noop
        );
    }

    vm.upgradeSettingsAccount = () => {
        vm.$parent.upgradeSettingsAccount();
    };

    /**
     * Returns wether or not the given file is a supported image file
     *
     * @param  {Object}  file  The file
     */
    vm.isImage = function(file) {

        // Returns early if we have already evaluated if this file is an image
        if (typeof file.isSupportedImage !== 'undefined') {
            return file.isSupportedImage;
        }

        file.isSupportedImage = strukshurUtilService.supportedImageFormats.includes(file.mimetype);

        return file.isSupportedImage;
    };

    /**
     * Returns the given file extension
     *
     * @param  {Object}  file  The file
     *
     * @return {String}
     */
    vm.getFileExtension = function (file) {
        var parts = file.file.split('.');

        return parts[parts.length-1];
    };

    /**
     * Returns the color for the given file extension
     *
     * @param  {Object}  file  The file
     *
     * @return {String}
     */
    vm.getExtensionColor = function (file) {
        return FileService.getFileExtensionColor(file.file);
    };

    /**
     * Removes the received attr file from the server
     *
     * @param  {object}  attr  The attr
     * @param  {object}  file  The file
     */
    vm.removeFile = function (attr, file) {
        var $childScope = vm.$new();
        $childScope.file = file;
        $childScope.project = vm.$parent.project;
        $childScope.title = 'Delete file';
        $childScope.message = 'Are you sure you want to delete the file?';

        var modalInstance = $uibModal.open({
            keyboard: true,
            scope: $childScope,
            controller: 'ProjectDetailFinishesFileDeleteCtrl',
            template: require('../../../common/templates/projects.deleteFinishFileConfirmationModal.tpl.html')
        });

        modalInstance.result.then(

            // Resolved callback
            function (data) {
                _pull(attr.files, file);
            },

            // Rejected callback
            angular.noop
        );
    };

    vm.updatePageItems = function () {
        var items = [];
        vm.rooms.forEach(function (room) {
            items.push({ title: room.name, href: room.slug });
        });
        vm.$parent.setPageItems($state.current.name, items);
    };

    vm.boardFinishOpen = function (item, prop, projectRoom) {
        var modalInstance;
        $scope.item = item;
        $scope.item.img = item.file;
        $scope.item.type = 'projectFinish';
        if(!$scope.item.parent){
            $scope.item.parent = projectRoom.room;
        }
        $scope.item.note = item.filename;
        roomService.setCurrentRoomProps(projectRoom.room.roomProps);

        // either product or pin
        $scope.type = 'projectFinish';

        modalInstance = $uibModal.open({
            scope: $scope,
            keyboard: true,
            controller: 'ProjectPinBoardModalInstanceCtrl',
            template: require('../../../common/templates/board-modal.tpl.html'),
            size: 'full-width',
            resolve: {
                pin: function () {
                    return $scope.item;
                }
            }
        });

        modalInstance.result.then(function (selectedItem) {
            //$scope.selected = selectedItem;
        }, function () {
            //$log.info('Modal dismissed at: ' + new Date());
        });
    };

    vm.init();
})

.controller('ProjectDetailCopyFinishCtrl', function ProjectDetailCopyFinishCtrl ($scope, $uibModalInstance, currentRoom, finish, project, projectRooms, strukshurApiService) {
    var vm = $scope;

    vm.loading = true;
    vm.projects = [];
    vm.newFinish = {};
    vm.errorMessage = '';
    vm.selectedRooms = [];
    vm.loadingRooms = false;
    vm.selectedProject = {};
    vm.projectFinish = finish;
    vm.loadingProjects = true;
    vm.currentProject = project;
    vm.currentRoom = currentRoom;
    vm.availableRooms = _clone(projectRooms);

    // Remove current room from the list of rooms the user can copy the finish to
    _remove(vm.availableRooms, { id: currentRoom.id });

    // Starts local rooms cache with the current rooms
    vm.projectRoomsCache = [
        { id: project.id, rooms: _clone(vm.availableRooms) }
    ];

    // Searches list of available projects for the current user
    strukshurApiService.projectprojects.query({ page: 1, limit: 50 }).$promise
        .then(function (res) {
            vm.loading = false;
            vm.projects = res.projects;
            vm.loadingProjects = false;
            vm.newFinish.project = project;

            // Select the first project and disable the field if the user only
            // has one project to choose from
            if (vm.projects.length === 1) {
                vm.newFinish.project = vm.projects[0];
            }
        })
        .catch(function (res) {
            if (res.status === 403) {
                vm.errorMessage = 'You don\'t have the necessary permission to load the projects.';
            } else {
                vm.errorMessage = 'There was an error trying to load the projects.';
            }
        })
    ;

    /**
     * Event triggered when a project is selected by the user
     *
     * @param  {Object}  $select  The ui-select component object
     */
    vm.projectSelected = function ($select) {
        vm.errorMessage = '';
        vm.currentProject = $select.selected;

        // Checks if the project rooms list has already been loaded
        var cacheItem = _find(vm.projectRoomsCache, { id: vm.currentProject.id });
        if (cacheItem) {
            vm.availableRooms = cacheItem.rooms;
        } else {

            // Loads the list of rooms for the selected project from the server
            vm.loadingRooms = true;
            strukshurApiService.projectRooms.list({ 'project_id': vm.currentProject.id }).$promise
                .then(function (res) {

                    // If there's no room on the project, we display an error message
                    if (!res.rooms || res.rooms.length === 0) {
                        vm.availableRooms = [];
                        vm.loadingRooms = false;
                        vm.errorMessage = 'The selected project doesn\'t have any rooms';
                        return;
                    }

                    // Initially assemble the project rooms
                    vm.availableRooms = res.rooms;
                    vm.availableRooms.forEach(function (room) {
                        room.finishes = [];
                    });

                    // Load project finishes
                    strukshurApiService.projectFinishes.list({ 'project_id': vm.currentProject.id }).$promise
                        .then(function (res) {
                            vm.loadingRooms = false;

                            // Properly set the finishes for the correct project rooms
                            res.projectFinishes.forEach(function (finish) {
                                var room = _find(vm.availableRooms, { id: finish.project_room_id });
                                if (room) {
                                    room.finishes.push(finish);
                                }
                            });

                            // Properly set the finishes for the correct room props
                            vm.availableRooms.forEach(function (room) {
                                room.room.roomProps.forEach(function (prop) {
                                    var finishes = _filter(room.finishes, { project_room_id: room.id, prop_id: prop.id });

                                    // Add the finishes to the room prop if any exists
                                    if (finishes) {
                                        prop.finishes = (Array.isArray(finishes)) ? finishes : [finishes];
                                    }
                                });
                            });

                            // Adds room list to the local cache
                            vm.projectRoomsCache.push({ id: vm.currentProject.id, rooms: _clone(vm.availableRooms) });
                        })
                    ;
                })
            ;
        }
    };

    /**
     * Wether or not the given room has a category with the same name the finish is in
     *
     * @param  {Object}  selectedRoom  The selected room
     * @param  {Object}  finishRoom    The room the finish belongs to
     * @param  {Object}  finish        The finish
     *
     * @return {Boolean}
     */
    vm.roomHasFinishCategoryByName = function (selectedRoom, finishRoom, finish) {

        // Retrieve the category the received finish belongs to
        var category = _find(finishRoom.room.roomProps, { id: finish.prop_id });

        // Checks if a category with the same slug exists on the selected room
        return (typeof _find(selectedRoom.room.roomProps, { slug: category.slug }) !== 'undefined');
    };

    /**
     * Wether or not the given room already has a finish with the same name in it
     *
     * @param  {Object}  room    The room
     * @param  {Object}  finish  The finish
     *
     * @return {Boolean}
     */
    vm.roomHasFinishByName = function (room, finish) {

        // Checks if a category with the same slug exists on the selected room
        return (typeof _find(room.finishes, { title: finish.title }) !== 'undefined');
    };

    /**
     * Copies the finish to the selected rooms on the backend
     *
     * @param  {Object}  form  The form
     */
    vm.confirmCopy = function (form) {
        if (form.$valid) {
            vm.errorMessage = '';

            // Collect the selected rooms
            vm.selectedRooms = [];
            angular.forEach(form, function(value, index: string) {
                if (index.includes('room-')) {
                    if (value.$viewValue === true || value.$viewValue === 'true') {
                        var roomId = value.$$attr.value;

                        // Retrieve room object from the checked input id
                        var room = _find(vm.availableRooms, { id: parseInt(roomId, 10) });

                        // Check if the selected room is of the same type of the
                        // current room (the room the finish is in) or if the
                        // selected room has a finish category with the same
                        // name of the current finish category
                        if (room.room.id === vm.currentRoom.room.id ||
                            vm.roomHasFinishCategoryByName(room, currentRoom, finish)) {

                            // We then check if the finish hasn't been added to
                            // the selected room
                            if (!vm.roomHasFinishByName(room, finish)) {

                                // Adds the checked room id to the list to submit
                                vm.selectedRooms.push(roomId);
                            } else {
                                vm.errorMessage = 'The room "'+room.name+'" already has a finish with the same name in it.';
                            }
                        } else {
                            vm.errorMessage = 'The room "'+room.name+'" doesn\'t have the category the finish belongs to.';
                        }
                    }
                }
            });

            // Return early if we find an error in the previous validation
            if (vm.errorMessage !== '') {
                return;
            }

            // The user needs to have at least one valid room selected to copy the finish
            if (vm.selectedRooms.length === 0) {
                vm.errorMessage = 'You need to select at least one room to copy the finish to.';
                return;
            }

            var data = {
                project_id: vm.newFinish.project.id,
                project_finish_id: vm.projectFinish.id,
                selected_rooms: vm.selectedRooms.join(',')
            };

            // Copies the finish to the selected rooms
            vm.loading = true;
            strukshurApiService.projectFinish.copy(data).$promise
                .then(function (res) {
                    $uibModalInstance.close({ project: vm.currentProject, finishes: res.projectFinishes });
                })
                .catch(function (res) {
                    if (res.status === 403) {
                        vm.errorMessage = 'You don\'t have the necessary permission to copy the project finish.';
                    } else {
                        vm.errorMessage = 'There was an error trying to copy the project finish.';
                    }
                })
                .finally(function () {
                    vm.loading = false;
                })
            ;
        }
    };
})

.controller('ProjectDetailEditFinishCtrl', function ($scope, $uibModalInstance, finish, strukshurApiService) {
    var vm = $scope;

    vm.loading = false;
    vm.errorMessage = '';
    vm.projectFinish = { title: finish.title };

    vm.saveFinish = function (form) {
        if (form.$valid) {
            vm.loading = true;
            vm.errorMessage = '';

            var data = {
                project_finish_id: finish.id,
                title: vm.projectFinish.title
            };

            strukshurApiService.projectFinish.update(data).$promise
                .then(function (res) {
                    $uibModalInstance.close({ projectFinish: res.projectFinish });
                })
                .catch(function (res) {
                    if (res.status === 403) {
                        vm.errorMessage = 'You don\'t have the necessary permission to update the project finish.';
                    } else {
                        vm.errorMessage = 'There was an error trying to update the project finish.';
                    }
                })
                .finally(function () {
                    vm.loading = false;
                })
            ;
        }
    };
})

.controller('ProjectDetailFinishDeleteCtrl', function ($scope, $uibModalInstance, strukshurApiService) {
    var vm = $scope;

    vm.confirm = function () {

        // Deletes the project finish on the server
        vm.loading = true;
        strukshurApiService.projectFinish.delete({ project_finish_id: vm.finish.id }).$promise
            .then(function (res) {
                $uibModalInstance.close();
            })
            .catch(function (res) {
                if (res.status === 403) {
                    vm.errorMessage = 'You don\'t have the necessary permission to delete the finish.';
                } else {
                    vm.errorMessage = 'There was an error trying to delete the finish.';
                }
            })
            .finally(function () {
                vm.loading = false;
            })
        ;
    };

    vm.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };
})

.controller('ProjectDetailFinishesFileDeleteCtrl', function ($scope, $uibModalInstance, strukshurApiService) {
    var vm = $scope;

    vm.removeRelated = false;

    vm.onConfirmChosen = function () {
        var removeRelated = (vm.removeRelated) ? 1 : 0;

        // Deletes the finish on the server
        vm.loading = true;
        strukshurApiService.projectFinishFile.delete({ file_id: vm.file.id, remove_related: removeRelated }).$promise
            .then(function (res) {
                $uibModalInstance.close();
            })
            .catch(function (res) {
                if (res.status === 403) {
                    vm.errorMessage = 'You don\'t have the necessary permission to delete the file.';
                } else {
                    vm.errorMessage = 'There was an error trying to delete the file.';
                }
            })
            .finally(function () {
                vm.loading = false;
            })
        ;
    };
})

.controller('ProjectDetailFinishNoteDeleteCtrl', function ($scope, $uibModalInstance, strukshurApiService) {
    var vm = $scope;

    vm.confirm = function () {

        // Deletes the project note on the server
        vm.loading = true;
        strukshurApiService.projectFinishNote.delete({ note_id: vm.note.id }).$promise
            .then(function (res) {
                $uibModalInstance.close();
            })
            .catch(function (res) {
                if (res.status === 403) {
                    vm.errorMessage = 'You don\'t have the necessary permission to delete the note.';
                } else {
                    vm.errorMessage = 'There was an error trying to delete the note.';
                }
            })
            .finally(function () {
                vm.loading = false;
            })
        ;
    };

    vm.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };
})

.controller('TaxonomySelectModalCtrl', function TaxonomySelectModalCtrl ($scope, $state, $uibModal, $uibModalInstance, item, strukshurApiService, strukshurUserService) {
    var i, tot, row;
    $scope.selectedItem = item;
    $scope.loading = false;
    $scope.form = {};
    $scope.pinTags = [];
    $scope.itemAttrs = [];
    tot = $scope.selectedItem.attrs.length;
    for(i=0;i<tot;i++){
        row = $scope.selectedItem.attrs[i];
        row.checked = false;
        row.note = '';
        row.attr_name = row.name;
        $scope.itemAttrs.push(angular.copy(row));
    }

    strukshurApiService.getAllPinsRoom.query({room_slug: $scope.selectedRoom.slug, user_slug: strukshurUserService.getUserSlug()}, function(d) {
        var i, tot, row, i2, tot2, row2, cpy, idx;
        $scope.pinTags = (d.pinTags===null?[]:d.pinTags);
        tot = $scope.itemAttrs.length;
        tot2 = $scope.pinTags.length;
        for(i=0;i<tot;i++){
            row = $scope.itemAttrs[i];
            for(i2=0;i2<tot2;i2++){
                row2 = $scope.pinTags[i2];
                if(row2.attr_id===row.id){
                    idx = $scope.itemAttrs.indexOf(row);
                    if(idx>=0 && $scope.itemAttrs[idx].checked){
                        cpy = angular.copy(row);
                        cpy.pin_id = row2.id;
                        cpy.note = row2.note;
                        cpy.checked = true;
                        $scope.itemAttrs.splice(i, 0, cpy);
                        i++;
                        tot++;
                    }
                    else{
                        row.pin_id = row2.id;
                        row.note = row2.note;
                        row.checked = true;
                        $scope.itemAttrs[i] = row;
                    }
                }
            }
        }
    });

    $scope.saveChecklist = function (form) {
        var i, tot, selection = [];
        $scope.loading = true;

        tot = $scope.itemAttrs.length;
        for(i=0;i<tot;i++){
            selection.push(angular.copy($scope.itemAttrs[i]));
        }

        strukshurApiService.pinTag.update({
            prop_id: $scope.selectedItem.id,
            room_slug: $scope.selectedRoom.slug,
            checklist: JSON.stringify(selection)
        }, function (d) {
            $scope.loading = false;
            $scope.ok();
        });

    };

    $scope.ok = function () {
        $uibModalInstance.close();
    };

    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };
})

.controller('ProjectDetailNotEnoughRoomsMessageCtrl', function ($scope, $uibModalInstance) {
    var vm = $scope;

    /**
     * Closes the modal
     */
    vm.confirm = function () {
        $uibModalInstance.close();
    };
})

;
