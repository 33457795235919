import * as angular from 'angular';

var app = angular.module('strukshurModalService', []);

app.service('strukshurModalService', ['$uibModal', function ($uibModal) {
    var modalIsShown = false;
    var modalInstance: any = {};

    var openForgotPasswordModal = () => {
        modalInstance = $uibModal.open({
            size: 'lg',
            keyboard: true,
            ariaLabelledBy: 'modal-title',
            ariaDescribedBy: 'modal-body',
            template: require('../templates/forgot-password-modal.tpl.html'),
            controller: 'ModalForgotPasswordCtrl',
            resolve: {
                items: () => {},
            }
        });

        modalInstance.result.then(angular.noop, angular.noop);
    };

    var closeModal = function () {
        modalInstance.close();
        modalIsShown = false;
    };

    return {
        openForgotPasswordModal: openForgotPasswordModal,
        closeModal: closeModal
    };
}]);

app.controller('BaseInfoModalController', function ($scope, $uibModalInstance) {
    var vm = $scope;

    /**
     * Closes the modal
     */
    vm.confirm = () => $uibModalInstance.close();
})

;
