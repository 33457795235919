import * as angular from 'angular';

var app = angular.module('PricingService', []);
app.factory('PricingService', [() => {

    /**
     * Available plan information
     */
    const plans = {
        pro: {
            current: [
                { plan: 'pro-basic', title: 'Pro Basic', highlight: false, price: 0, frequency: 'monthly', save: false, members: 'unlimited', storage: '1 GB', projects: 1, trial: false, gtagHash: null },
                { plan: 'pro-monthly-tier-1_02', title: 'Tier 1', highlight: false, price: 29.99, frequency: 'monthly', save: false, members: 'unlimited', storage: '100 GB', projects: 5, trial: '30 days free trial', gtagHash: 'AW-800451263/c5-CCIzFuJUDEL_V1_0C' },
                { plan: 'pro-annual-tier-1_02', title: 'Tier 1', highlight: false, price: 26.95, frequency: 'annual', save: '10%', members: 'unlimited', storage: '100 GB', projects: 5, trial: '30 days free trial', gtagHash: 'AW-800451263/juH3CKaW6pIBEL_V1_0C' },
                { plan: 'pro-monthly-1_02', title: 'Pro', highlight: true, price: 69.99, frequency: 'monthly', save: false, members: 'unlimited', storage: '250 GB', projects: 20, trial: '30 days free trial', gtagHash: 'AW-800451263/NHL5CNnm8JUDEL_V1_0C' },
                { plan: 'pro-annual-1_02', title: 'Pro', highlight: true, price: 62.99, frequency: 'annual', save: '10%', members: 'unlimited', storage: '250 GB', projects: 20, trial: '30 days free trial', gtagHash: 'AW-800451263/zKP-CJeI55UDEL_V1_0C' },
                { plan: 'premium-monthly-1_02', title: 'Premium', highlight: false, price: 259.99, frequency: 'monthly', save: false, members: 'unlimited', storage: '1 TB', projects: 'unlimited', trial: '30 days free trial', gtagHash: 'AW-800451263/nFKaCJCK55UDEL_V1_0C' },
                { plan: 'premium-annual-1_02', title: 'Premium', highlight: false, price: 233.99, frequency: 'annual', save: '10%', members: 'unlimited', storage: '1 TB', projects: 'unlimited', trial: '30 days free trial', gtagHash: 'AW-800451263/VlVnCMiJ55UDEL_V1_0C' },
            ],

            legacy: [
                { plan: 'pro-basic', title: 'Pro Basic', highlight: false, price: 0, frequency: 'monthly', save: false, members: 'unlimited', storage: '1 GB', projects: 1, trial: false },
                { plan: 'pro-monthly-tier-1', title: 'Tier 1', highlight: false, price: 9.95, frequency: 'monthly', save: false, members: 'unlimited', storage: '100 GB', projects: 5, trial: '30 days free trial' },
                { plan: 'pro-annual-tier-1', title: 'Tier 1', highlight: false, price: 8.95, frequency: 'annual', save: '10%', members: 'unlimited', storage: '100 GB', projects: 5, trial: '30 days free trial' },
                { plan: 'pro-monthly-1', title: 'Pro', highlight: true, price: 69.95, frequency: 'monthly', save: false, members: 'unlimited', storage: '250 GB', projects: 20, trial: '30 days free trial' },
                { plan: 'pro-annual-1', title: 'Pro', highlight: true, price: 59.95, frequency: 'annual', save: '14%', members: 'unlimited', storage: '250 GB', projects: 20, trial: '30 days free trial' },
                { plan: 'premium-monthly-1', title: 'Premium', highlight: false, price: 160, frequency: 'monthly', save: false, members: 'unlimited', storage: '1 TB', projects: 'unlimited', trial: '30 days free trial' },
                { plan: 'premium-annual-1', title: 'Premium', highlight: false, price: 144, frequency: 'annual', save: '10%', members: 'unlimited', storage: '1 TB', projects: 'unlimited', trial: '30 days free trial' },
            ]
        },

        home: {
            current: [
                { plan: 'basic', title: 'Basic', highlight: false, price: 0, frequency: 'monthly', save: false, members: 'unlimited', storage: '1 GB', projects: 1, trial: false, gtagHash: null },
                { plan: 'monthly-tier-1', title: 'Tier 1', highlight: true, price: 11.06, frequency: 'monthly', save: false, members: 'unlimited', storage: '100 GB', projects: 3, trial: '30 days free trial', gtagHash: 'AW-800451263/1msjCNmYrpYDEL_V1_0C' },
                { plan: 'annual-tier-1', title: 'Tier 1', highlight: true, price: 9.95, frequency: 'annual', save: '10%', members: 'unlimited', storage: '100 GB', projects: 3, trial: '30 days free trial', gtagHash: 'AW-800451263/_fHNCPWJ15IBEL_V1_0C' },
                { plan: 'monthly-tier-2_02', title: 'Tier 2', highlight: false, price: 29.99, frequency: 'monthly', save: false, members: 'unlimited', storage: '250 GB', projects: 10, trial: '30 days free trial', gtagHash: 'AW-800451263/iA4vCKimrpYDEL_V1_0C' },
                { plan: 'annual-tier-2_02', title: 'Tier 2', highlight: false, price: 26.95, frequency: 'annual', save: '10%', members: 'unlimited', storage: '250 GB', projects: 10, trial: '30 days free trial', gtagHash: 'AW-800451263/NVswCNzH3JYDEL_V1_0C' },
            ],

            legacy: [
                { plan: 'basic', title: 'Basic', highlight: false, price: 0, frequency: 'monthly', save: false, members: 'unlimited', storage: '1 GB', projects: 1, trial: false },
                { plan: 'monthly-tier-1', title: 'Tier 1', highlight: true, price: 11.06, frequency: 'monthly', save: false, members: 'unlimited', storage: '100 GB', projects: 3, trial: '30 days free trial' },
                { plan: 'annual-tier-1', title: 'Tier 1', highlight: true, price: 9.95, frequency: 'annual', save: '10%', members: 'unlimited', storage: '100 GB', projects: 3, trial: '30 days free trial' },
                { plan: 'monthly-tier-2', title: 'Tier 2', highlight: false, price: 22.17, frequency: 'monthly', save: false, members: 'unlimited', storage: '250 GB', projects: 10, trial: '30 days free trial' },
                { plan: 'annual-tier-2', title: 'Tier 2', highlight: false, price: 19.95, frequency: 'annual', save: '10%', members: 'unlimited', storage: '250 GB', projects: 10, trial: '30 days free trial' },
            ]
        }
    };

    const service = {

        /**
         * Returns all available plans
         *
         * @return {array}
         */
        getAll: () => [...plans.home.current, ...plans.pro.current, ...plans.home.legacy, ...plans.pro.legacy],

        /**
         * Returns current pro plans
         *
         * @param  {boolean}  withBasic  Wether to add basic plan or not
         *
         * @return {array}
         */
        getPro: (withBasic) => {
            let planCopy;

            if (typeof withBasic === 'undefined') {
                withBasic = false;
            }

            if (withBasic) {
                return plans.pro.current;
            }

            planCopy = angular.copy(plans.pro.current);
            planCopy.shift(1);

            return planCopy;
        },

        /**
         * Returns current home plans
         *
         * @param  {boolean}  withBasic  Wether to add basic plan or not
         *
         * @return {array}
         */
        getNormal: (withBasic) => {
            let planCopy;

            if (typeof withBasic === 'undefined') {
                withBasic = false;
            }

            if (withBasic) {
                return plans.home.current;
            }

            planCopy = angular.copy(plans.home.current);
            planCopy.shift(1);

            return planCopy;
        },

        /**
         * Returns the name of the plan based on the given id
         *
         * @param  {string}  id  The plan id
         *
         * @return {string}
         */
        getName: (id) => {
            if (!id) { return ''; }

            if (id === 'basic') { return 'Basic'; }

            if (id === 'pro-basic') { return 'Pro Basic'; }

            const availablePlans = service.getAll();
            for (const plan of availablePlans) {
                if (plan.plan === id) {
                    return `${plan.title} (${plan.frequency})`;
                }
            }

            return '';
        },

        /**
         * Returns the plan with the given id
         *
         * @param  {string}  id  The plan id
         *
         * @return {object}
         */
        getPlan: (id) => {
            if (!id) { return null; }

            const availablePlans = service.getAll();
            for (const plan of availablePlans) {
                if (plan.plan === id) {
                    return plan;
                }
            }

            return null;
        },

        /**
         * Returns the total cost of the plan based on the given id
         *
         * @param  {string}  id  The plan id
         *
         * @return {number}
         */
        getTotalPrice: (id) => {
            if (!id) { return 0; }

            const availablePlans = service.getAll();
            for (const plan of availablePlans) {
                if (plan.plan === id) {
                    return Math.round(plan.price * (plan.frequency === 'monthly' ? 1 : 12) * 100) / 100;
                }
            }

            return 0;
        },

        /**
         * Returns the frequency of the plan based on the given id
         *
         * @param  {string}  id  The plan id
         *
         * @return {string}
         */
        getFrequency: (id) => {
            if (!id) { return ''; }

            const availablePlans = service.getAll();
            for (const plan of availablePlans) {
                if (plan.plan === id) {
                    return plan.frequency;
                }
            }

            return '';
        },

        /**
         * Returns the user type of the plan based on the given id
         *
         * @param  {string}  id  The plan id
         *
         * @return {string}
         */
        getPlanUserType: (id) => {
            if (!id) { return ''; }

            for (const plan of [...plans.home.current, ...plans.home.legacy]) {
                if (plan.plan === id) {
                    return 'standard';
                }
            }

            for (const plan of [...plans.pro.current, ...plans.pro.legacy]) {
                if (plan.plan === id) {
                    return 'pro';
                }
            }

            return '';
        },

        /**
         * Returns the trial string of the plan based on the given id
         *
         * @param  {string}  id  The plan id
         *
         * @return {string}
         */
        geTrial: (id) => {
            if (!id) { return ''; }

            const availablePlans = service.getAll();
            for (const plan of availablePlans) {
                if (plan.plan === id) {
                    return plan.trial;
                }
            }

            return '';
        }
    };

    return service;
}]);
