import * as angular from 'angular';

angular.module('strukshurApp.projects.rooms', [])

.controller('ProjectDetailNewRoomCtrl', function ($scope, $uibModalInstance, project, strukshurApiService) {
    var vm = $scope;

    vm.loading = false;
    vm.errorMessage = '';
    vm.projectRoom = { name: '', room: null };

    vm.saveRoom = (form) => {
        if (form.$valid) {
            vm.errorMessage = '';

            const data = {
                project_id: project.id,
                name: vm.projectRoom.name
            };

            vm.loading = true;
            strukshurApiService.projectRoom.create(data).$promise
                .then((res) => $uibModalInstance.close({ room: res.room }))
                .catch((res) => {
                    if (res.status === 403) {
                        vm.errorMessage = 'You don\'t have the necessary permission to create the project room.';
                    } else {
                        vm.errorMessage = 'There was an error trying to create the project room.';
                    }
                })
                .finally(() => vm.loading = false)
            ;
        }
    };
})

.controller('ProjectDetailEditRoomCtrl', function ($scope, $uibModalInstance, room, strukshurApiService) {
    var vm = $scope;

    vm.loading = false;
    vm.errorMessage = '';
    vm.projectRoom = { name: room.name };

    vm.saveRoom = (form) => {
        if (form.$valid) {
            vm.loading = true;
            vm.errorMessage = '';

            const data = {
                room_id: room.id,
                name: vm.projectRoom.name
            };

            strukshurApiService.projectRoom.update(data).$promise
                .then((res) => $uibModalInstance.close({ room: res.room }))
                .catch((res) => {
                    if (res.status === 403) {
                        vm.errorMessage = 'You don\'t have the necessary permission to update the project room.';
                    } else {
                        vm.errorMessage = 'There was an error trying to update the project room.';
                    }
                })
                .finally(() => vm.loading = false)
            ;
        }
    };
})

.controller('ProjectDetailDeleteRoomCtrl', function ProjectDetailDeleteRoomCtrl($scope, $uibModalInstance, strukshurApiService) {
    var vm = $scope;

    vm.loading = false;

    vm.onConfirmChosen = () => {

        // Deletes the user room on the server
        vm.loading = true;
        strukshurApiService.projectRoom.delete({ room_id: vm.room.id }).$promise
            .then((res) => $uibModalInstance.close())
            .catch((res) => {
                if (res.status === 403) {
                    vm.errorMessage = 'You don\'t have the necessary permission to delete the project room.';
                } else {
                    vm.errorMessage = 'There was an error trying to delete the room.';
                }
            })
            .finally(() => vm.loading = false)
        ;
    };
})

;
