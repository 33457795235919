/**
 *  @author Alex Vitari alexnoise79@gmail.com
 *  @description this module contains two directives which permits to validate and show labels through the floating label pattern.
 *  @global messages is a collection of labels to simplify the import from locale variables
 *  @see https://github.com/angular/bower-angular-i18n for details
 */
//{
//    "name": "ngFloatingLabels",
//    "private": false,
//    "main": "src/ngFloatingLabels.js",
//    "version": "0.0.1",
//    "description": "Floating Labels and Floating Validation for AngularJS",
//    "repository": "https://github.com/alexnoise79/ngFloatingLabels",
//    "license": "MIT",
//    "dependencies": {
//    "angular": "1.3.x",
//        "angular-animate": "~1.3.14"
//}

/* messages must be global for floating labels */
var messages = {
    required: 'This field is required',
    invalid: 'This field value is invalid',
    invalidlogin: 'Invalid login. Please try again.',
    invalidzip: 'Invalid zip code',
    pwlength: 'Password must be at least 8 characters',
    duplicate: 'Email already registered',
    minlength: 'min length of @value@ characters',
    pattern: 'must match the pattern',
    checkbox: 'You must agree to the Terms of Service',
    checkboxEmail: 'You must select that you understand to confirm email',
    email: 'Email address is not valid',
    match: 'Your passwords do not match',
    number: 'insert only numbers',
    custom: 'custom not valid type "@value@"',
    async: 'async not valid type "@value@"',
    min: 'Minimum value is "@value@"',
    max: 'Maximum value is "@value@"',
    invalidfax: 'Invalid Number',
    url: 'Invalid URL'
};

angular.module('ngFloatingLabels', ['ngAnimate'])
    .directive("floatingValidation", ['$animate', function($animate){

        return {
            scope: true,
            require: ['^form', 'ngModel'],
            restrict: "A",
            link: function(scope, element, attrs, controller){
                scope.formCtrl = controller[0];
                scope.inputCtrl = controller[1];

                var $float = angular.element('<label for="'+attrs.id+'" class="float">'+attrs.placeholder+'</span>');

                scope.showHide = function(show){
                    if(show){
                        if(!$float.hasClass('top')){
                            element.after($float);
                            $animate.addClass($float, 'top');
                        }
                    }else {
                        $animate.removeClass($float, 'top');
                    }
                };

                scope.showErrors = function(){
                    angular.forEach(scope.inputCtrl.$error, function (e, i) {
                        if (e) {
                            //$float.text(messages[i].replace("@value@", attrs[i]));
                            $float.html(attrs.placeholder + ' <span>' + messages[i].replace("@value@", attrs[i]) + '</span>');
                        }
                    });
                    scope.showHide(true);
                };

                scope.$watch('inputCtrl.$error', function (newValue) {
                    //if(JSON.stringify(newValue) !=='{}' && !scope.inputCtrl.$pristine){
                    if(JSON.stringify(newValue) !=='{}'){
                        scope.showErrors();
                    }
                },true);

                scope.$watch('inputCtrl.$valid', function (newValue) {
                    if(newValue && !scope.inputCtrl.$pristine){
                        $float.text(attrs.placeholder);
                        scope.showHide(true);
                    }
                });

                scope.$watch('inputCtrl.$pristine', function (newValue) {
                    if(newValue && scope.inputCtrl.$touched){
                        scope.showHide(false);
                        scope.inputCtrl.$setUntouched();
                        //immediately reset no debounce
                        scope.inputCtrl.$setViewValue(undefined, scope.inputCtrl.$options.updateOn);
                        scope.inputCtrl.$setPristine();
                    }
                });

                scope.$watch('formCtrl.$submitted', function (newValue) {
                    if(newValue && scope.inputCtrl.$invalid){
                        scope.showErrors();
                    }else if(!scope.inputCtrl.$dirty){
                        //reset if filled void
                        if (scope.inputCtrl.$viewValue !== undefined && scope.inputCtrl.$viewValue !== false)
                        {
                            scope.showHide(true);// if autofilled in controller, show the top placeholder
                        }
                        else {
                            scope.showHide(false);
                        }
                    }
                });
            }
        };
    }])
    .directive('termsValidator', function () {
        return {
            require: 'ngModel',
            link: function (scope, element, attrs, ngModel) {
                ngModel.$validators.checkbox = function (value) {
                    return value === true || value  === 'true';
                };
            }
        };
    })
    .directive('termsEmailValidator', function () {
        return {
            require: 'ngModel',
            link: function (scope, element, attrs, ngModel) {
                ngModel.$validators.checkboxEmail = function (value) {
                    return value === true || value  === 'true';
                };
            }
        };
    })
    .directive("floatingLabel", ['$animate', function($animate){
        return {
            scope: true,
            require: 'ngModel',
            restrict: "A",
            link: function(scope, element, attrs, controller){
                scope.inputCtrl = controller;

                var $float = angular.element('<label for="'+attrs.id+'" class="float">'+attrs.placeholder+'</span>');

                scope.showHide = function(show){
                    if(show){
                        if(!$float.hasClass('top')){
                            element.after($float);
                            $animate.addClass($float, 'top');
                        }
                    }else {
                        $animate.removeClass($float, 'top');
                    }
                };

                scope.$watch('inputCtrl.$modelValue', function (newValue) {
                    if(newValue !== undefined){
                        scope.showHide(newValue.length);

                        if(!newValue.length){
                            scope.inputCtrl.$setPristine();
                        }
                    }
                });

                scope.$watch('inputCtrl.$pristine', function (newValue) {
                    if(newValue && scope.inputCtrl.$touched){
                        scope.showHide(false);
                        scope.inputCtrl.$setUntouched();
                        //immediately reset no debounce
                        scope.inputCtrl.$setViewValue(undefined, scope.inputCtrl.$options.updateOn);
                        scope.inputCtrl.$setPristine();
                    }
                });
            }
        };
    }]);
