import * as angular from 'angular';
import Masonry from 'masonry-layout';
import imagesLoaded from 'imagesloaded';

angular.module('strukshurApp.search', ['ui.router', 'ui.bootstrap'])

.config(function config($stateProvider) {
    $stateProvider.state('search', {
        url: '/search?terms&page',
        params: { searchTerms: null },
        views: {
            main: {
                controller: 'SearchCtrl',
                template: require('./search.tpl.html')
            }
        },
        data: { pageTitle: 'Search', class: 'search' }
    });
})

.service('searchService', ['strukshurApiService', function (strukshurApiService) {
    this.search = (terms, filters, page) => {
        if (typeof filters === 'undefined') {
            filters = '';
        }

        return strukshurApiService.search.search({ terms: terms, page: page, filters: filters }).$promise;
    };
}])

.controller('SearchCtrl', function SearchCtrl($location, $scope, $state, $stateParams, $timeout, searchService) {
    var terms = '', facets = null, filters: any = [], vm = $scope;

    vm.loading = false;
    vm.resolved = false;
    vm.searchGrid = null;
    vm.searchResults = [];
    vm.masonrySetup = false;
    vm.hasMoreResults = false;
    vm.page = $stateParams.page || 1;

    /**
     * Controller startup logic
     */
    vm.init = () => {
        if ($location.search()['terms'] !== undefined) {
            terms = $location.search()['terms'];
        } else if ($stateParams.searchTerms !== null && $stateParams.searchTerms.terms !== undefined && $stateParams.searchTerms.terms !== '') {
            terms = $stateParams.searchTerms.terms;
        }

        if ($location.search()['filters'] !== undefined) {
            filters = $location.search()['filters'];

            if (filters !== '') {
                filters = JSON.parse(filters);
            } else {
                filters = [];
            }
        } else if ($stateParams.searchTerms !== null && $stateParams.searchTerms.filters !== undefined) {
            filters = $stateParams.searchTerms.filters;
        }

        vm.loadResults();
    };

    /**
     * Load search results based on the terms and current page
     */
    vm.loadResults = () => {
        if (vm.loading) { return; }

        vm.loading = true;
        searchService.search(terms, JSON.stringify(filters), vm.page)
            .then(data => {
                let i1, i2, i3, i4, tot4;

                vm.page++;
                vm.resolved = true;
                vm.facets = data.facets;
                vm.searchResults.push(...data.search);
                vm.hasMoreResults = (data.search.length >= 20);

                $timeout(vm.setupIdeasGrid, 100);

                tot4 = filters.length;
                if (tot4 > 0) {
                    //filters check
                    for (i1 in vm.facets) {
                        if (!vm.facets.hasOwnProperty(i1)) { continue; }

                        for (i2 in vm.facets[i1].props) {
                            if (!vm.facets[i1].props.hasOwnProperty(i2)) { continue; }

                            for (i3 in vm.facets[i1].props[i2].attrs) {
                                if (!vm.facets[i1].props[i2].attrs.hasOwnProperty(i3)) { continue; }

                                vm.facets[i1].props[i2].attrs[i3].checked = false;
                                for (i4 = 0; i4 < tot4; i4++) {
                                    if (filters[i4] === vm.facets[i1].props[i2].attrs[i3].id) {
                                        vm.facets[i1].props[i2].attrs[i3].checked = true;
                                        break;
                                    }
                                }
                            }
                        }
                    }
                }
            })
            .catch(angular.noop)
            .finally(() => vm.loading = false)
        ;
    };

    /**
     * Sets up the grid for the ideas board
     */
     vm.setupIdeasGrid = () => {

        // Setups Masonry grid
        vm.masonrySetup = true;
        vm.ideasGrid = new Masonry('.ideas', { itemSelector: '.grid-item' });

        // Adds event to suffle ideas grid after every image load
        const imgLoad = imagesLoaded('.ideas');
        imgLoad.on('progress', () => vm.ideasGrid.layout());
    };

    vm.filterFacet = function () {
        var i1,i2, i3;
        filters = [];
        vm.resolved = false;

        for (i1 in vm.facets) {
            if (!vm.facets.hasOwnProperty(i1)) {
                continue;
            }
            for (i2 in vm.facets[i1].props) {
                 if (!vm.facets[i1].props.hasOwnProperty(i2)){
                    continue;
                }
                for (i3 in vm.facets[i1].props[i2].attrs) {
                    if (!vm.facets[i1].props[i2].attrs.hasOwnProperty(i3)) {
                        continue;
                    }
                    if (vm.facets[i1].props[i2].attrs[i3].checked === true) {
                        filters.push(vm.facets[i1].props[i2].attrs[i3].id);
                    }
                }
            }
        }

        vm.searchUpdate();
    };

    vm.searchUpdate = () => {
        vm.searchResults= [];
        $state.go('search', { searchTerms: { terms: terms, page: vm.page, filters: filters } }, { reload: true });
    };

    vm.init();
})
;
