import {
    clone as _clone,
    filter as _filter,
    find as _find,
    remove as _remove,
} from 'lodash';
import * as angular from 'angular';
import * as moment from 'moment';

import strukshurConfig from '../../../config.ts';

angular.module('strukshurApp.projects.progress', [])

.config(function config($stateProvider, $uibModalProvider) {
    $uibModalProvider.options = { animation: true, backdrop: 'static', keyboard: false, ariaLabelledBy: 'modal-title', ariaDescribedBy: 'modal-body' };

    $stateProvider
        .state('projects-detail.progress', {
            url: '/progress',
            views: {
                '': {
                    controller: 'ProjectDetailProgressCtrl',
                    template: require('./project.progress.tpl.html')
                },
            },
            data: { pageTitle: 'Project / Progress Photos & Videos', class: 'projects projects-detail-progress' }
        })
    ;
})

.controller('ProjectDetailProgressCtrl', function ProjectDetailProgressCtrl ($scope, $state, $timeout, $uibModal, angularGridInstance, strukshurApiService, strukshurProjectService) {
    var vm = $scope;

    // Check if the user can access the current page or not
    if (!vm.$parent.checkPermission('project_progress_item_view')) {
        return $state.go('projects-detail.access-denied', { section: 'Progress' });
    }

    vm.items = [];
    vm.loading = true;
    vm.milestones = [];
    vm.currentUser = {};
    vm.filteredItems = [];
    vm.processingFilter = false;
    vm.startDatePopup = { opened: false };
    vm.endDatePopup = { opened: false };
    vm.filter = { milestoneOrTask: null, startDate: null, endDate: null };

    /**
     * Controller init logic
     */
    vm.init = () => {

        // Updates current route info on the project menu scope
        vm.$parent.currentRoute = $state.current.name;

        // Retrieve list of project milestones
        strukshurApiService.projectMilestones.list({ project_id: vm.$parent.project.id }).$promise
            .then(function (res) {
                var items = [];
                var existingIds = [];

                res.projectMilestones.forEach(function (milestone) {

                    // Adds the milestones
                    if (!existingIds.includes(milestone.id)) {
                        items.push({ id: milestone.id, title: milestone.title, type: 'milestone' });
                        existingIds.push(milestone.id);

                        // Adds the tasks
                        milestone.tasks.forEach(function (task) {
                            if (!existingIds.includes(task.id)) {
                                items.push({ id: task.id, title: ' ' + task.title, type: 'task' });
                                existingIds.push(task.id);
                            }
                        });
                    }
                });

                vm.milestones = items;
            })
            .catch(angular.noop)
        ;

        // Loads project progress itrms for the project
        strukshurApiService.projectProgressItems.list({ project_id: vm.$parent.project.id }).$promise
            .then((res) => {
                vm.loading = false;
                vm.items = res.progressItems;
                vm.currentUser = vm.$parent.currentUser;

                // Set extra item info to be used on the template
                vm.items.forEach((item) => {
                    item.type = 'projectProgressItem';
                    item.project = vm.$parent.project;
                    item.date = new Date(item.date);
                });

                // Add watchers to update processing items if necessary
                vm.items.forEach((item) => {
                    if (item.isProcessing) {
                        strukshurProjectService.progressVideoStatus(item);
                    }
                });

                // Discover min and max dates for the date filter
                var minDate = new Date(), maxDate = new Date();
                vm.items.forEach((item) => {
                    if (moment(item.date).isSameOrBefore(minDate)) {
                        minDate = item.date;
                    }
                    if (moment(item.date).isSameOrAfter(maxDate)) {
                        maxDate = item.date;
                    }
                });

                // Sets the dates to the filter
                vm.filter.startDate = minDate;
                vm.filter.endDate = maxDate;

                // Adds found items to the display grid
                vm.filteredItems = vm.items;
                angularGridInstance.gallery.refresh();
            })
            .catch(angular.noop)
        ;

        // Watch for changes to the milestone or task filter to update the items on the list
        vm.$watch('filter.milestoneOrTask', vm.filterItems);

        // Watch for changes to the date filters to update the items on the list
        vm.$watch('filter.startDate', vm.filterItems);
        vm.$watch('filter.endDate', vm.filterItems);

        // Remove item from the filtered list if the user deletes it from the main list
        vm.$on('progressItemRemoved', (event, args) => {
            vm.processingFilter = true;

            $timeout(() => {
                vm.processingFilter = false;
                _remove(vm.items, { id: args.item.id });
                _remove(vm.filteredItems, { id: args.item.id });
                angularGridInstance.gallery.refresh();
            }, 1000);
        });
    };

    /**
     * Displays start date picker
     */
    vm.showStartDate = () => {
        vm.startDatePopup.opened = true;
    };

    /**
     * Displays end date picker
     */
    vm.showEndDate = () => {
        vm.endDatePopup.opened = true;
    };

    /**
     * Filter the progress items based on the milestone or date selected
     */
    vm.filterItems = () => {
        vm.processingFilter = true;
        vm.filteredItems = vm.items.filter((item) => {
            if (vm.filter.milestoneOrTask == null && vm.filter.startDate == null && vm.filter.endDate == null) { return true; }

            // Filter by milestone or task
            if (vm.filter.milestoneOrTask) {
                if (vm.filter.milestoneOrTask.type === 'milestone') {

                    // Filter by milestone and start and end dates
                    if (vm.filter.startDate && vm.filter.endDate) {
                        return (item.milestone_id === vm.filter.milestoneOrTask.id && moment(item.date).isSameOrAfter(vm.filter.startDate) && moment(item.date).isSameOrBefore(vm.filter.endDate));
                    }

                    // Filter by milestone and start date
                    if (vm.filter.startDate) {
                        return (item.milestone_id === vm.filter.milestoneOrTask.id && moment(item.date).isSameOrAfter(vm.filter.startDate));
                    }

                    // Filter by milestone and end date
                    if (vm.filter.endDate) {
                        return (item.milestone_id === vm.filter.milestoneOrTask.id && moment(item.date).isSameOrBefore(vm.filter.endDate));
                    }

                    // Just filter by milestone
                    return (item.milestone_id === vm.filter.milestoneOrTask.id);
                } else {

                    // Filter by task and start and end dates
                    if (vm.filter.startDate && vm.filter.endDate) {
                        return (item.task_id === vm.filter.milestoneOrTask.id && moment(item.date).isSameOrAfter(vm.filter.startDate) && moment(item.date).isSameOrBefore(vm.filter.endDate));
                    }

                    // Filter by task and start date
                    if (vm.filter.startDate) {
                        return (item.task_id === vm.filter.milestoneOrTask.id && moment(item.date).isSameOrAfter(vm.filter.startDate));
                    }

                    // Filter by task and end date
                    if (vm.filter.endDate) {
                        return (item.task_id === vm.filter.milestoneOrTask.id && moment(item.date).isSameOrBefore(vm.filter.endDate));
                    }

                    // Just filter by task
                    return (item.task_id === vm.filter.milestoneOrTask.id);
                }
            } else if (vm.filter.startDate || vm.filter.endDate) {

                // Filter by start and end dates
                if (vm.filter.startDate && vm.filter.endDate) {
                    return (moment(item.date).isSameOrAfter(vm.filter.startDate) && moment(item.date).isSameOrBefore(vm.filter.endDate));
                }

                // Filter by start date
                if (vm.filter.startDate) {
                    return (moment(item.date).isSameOrAfter(vm.filter.startDate));
                }

                // Filter by end date
                if (vm.filter.endDate) {
                    return (moment(item.date).isSameOrBefore(vm.filter.endDate));
                }
            }
        });

        // Delay grid refresh so the component can properly calculate items' positions
        $timeout(() => {
            vm.processingFilter = false;
            angularGridInstance.gallery.refresh();
        }, 1000);
    };

    /**
     * Opens the modal to add a new item to the project progress section
     */
    vm.newItem = function () {
        var modalInstance = $uibModal.open({
            scope: $scope,
            controller: 'ProjectDetailProgressAddItemCtrl',
            template: require('../../../common/templates/projects.progressAddItemModal.tpl.html'),
            resolve: {
                project: function () { return vm.$parent.project; }
            }
        });

        modalInstance.result.then(

            // Resolved callback
            function (data) {

                // Reload the state to retrieve the new items
                $state.reload();
            },

            // Rejected callback
            function (data) {
                if (data && data.itemsAdded) {

                    // Reload the state to retrieve the new items
                    $state.reload();
                }
            }
        );
    };

    /**
     * Opens the modal for editing a room
     */
    vm.editItem = function (item) {
        var modalInstance = $uibModal.open({
            scope: vm,
            keyboard: true,
            controller: 'ProjectDetailProgressEditItemCtrl',
            template: require('../../../common/templates/projects.progressEditItemModal.tpl.html'),
            resolve: {
                project: function () { return vm.$parent.project; },
                item: function () { return item; }
            }
        });

        modalInstance.result.then(

            // Resolved callback
            function (data) {
                if (data.progressItem) {
                    item.title = data.progressItem.title;
                    item.description = data.progressItem.description;
                    item.milestone_id = data.progressItem.milestone_id;
                    item.task_id = data.progressItem.task_id;
                }
            },

            // Rejected callback
            angular.noop
        );
    };

    /**
     * Checks if the current user has the given permission on the project
     *
     * @param  {string}  permission  The permission key
     *
     * @return {boolean}
     */
    vm.checkPermission = function (permission) {
        return vm.$parent.checkPermission(permission);
    };

    vm.init();
})

.controller('ProjectDetailProgressAddItemCtrl', function ProjectDetailProgressAddItemCtrl($scope, $uibModalInstance, fileReaderService, FileService, FileUploader, Flash, project, strukshurApiService, strukshurUserService) {
    var vm = $scope;

    vm.form = {};
    vm.isVideo = false;
    vm.uploading = false;
    vm.milestones = [];
    vm.queuedFiles = [];
    vm.upgradeLink = false;
    vm.errorMessage = null;
    vm.uploadedFiles = [];
    vm.uploadPromise = null;
    vm.hasFilesToUpload = false;
    vm.totalUploadingItems = 0;
    vm.currentUploadingItemIndex = 0;

    // Loads current user info
    vm.currentUser = {};
    strukshurUserService.getUser().then(function (res) {
        vm.currentUser = (res.user) ? res.user : res;
    });

    // Retrieve list of project milestones
    strukshurApiService.projectMilestones.list({ project_id: project.id }).$promise
        .then(function (res) {
            var items = [];
            var existingIds = [];

            res.projectMilestones.forEach(function (milestone) {

                // Adds the milestones
                if (!existingIds.includes(milestone.id)) {
                    items.push({ id: milestone.id, title: milestone.title, type: 'milestone' });
                    existingIds.push(milestone.id);

                    // Adds the tasks
                    milestone.tasks.forEach(function (task) {
                        if (!existingIds.includes(task.id)) {
                            items.push({ id: task.id, title: ' ' + task.title, type: 'task' });
                            existingIds.push(task.id);
                        }
                    });
                }
            });

            vm.milestones = items;
        })
        .catch(function () { })
    ;

    /**
     * Processes a file list selection coming from the user
     *
     * @param  {Array}  files  The list of files to be processed
     */
    vm.proccessAddedFiles = function (files) {

        // Process added files
        var filesToAdd = [];
        files.forEach(function (file) {

            // Don't add to queue files that are larger than the upload limit
            if ((file.file.size / 1024 / 1024) > strukshurConfig.max_upload_size) {
                let message = `The file "${file._file.name}" is larger than the limit of ${strukshurConfig.max_upload_size}MB.`;
                Flash.create('danger', message, 10000, { container: 'error-message-flash' }, true);
                return;
            }

            file.name = file.file.name;
            file.size = file.file.size;
            file.title = file.file.name;
            file.id = Math.random() + '_' + file.name;
            file.isVideo = FileService.isVideo(file.file);

            // Loads preview if the file is an image
            if (!file.isVideo) {
                fileReaderService.readAsDataUrl(file._file, vm)
                    .then(function (result) {
                        document.getElementById(file.id).style.backgroundImage = 'url('+result+')';
                        result = null; // eagerly clean memory to improve memory performance
                    })
                ;
            }

            filesToAdd.push(file);
        });

        if (vm.queuedFiles.length === 0) {
            vm.queuedFiles = filesToAdd;
        } else {
            filesToAdd.forEach(function (file) {

                // Only add the file if it does not currently exit on the queue
                if (typeof _find(vm.queuedFiles, { name: file.name, size: file.size }) === 'undefined') {
                    vm.queuedFiles.push(file);
                }
            });
        }

        vm.setHasFilesToUploadState();
    };

    /**
     * Sets if there's still files to be uploaded or not
     */
    vm.setHasFilesToUploadState = function () {
        vm.hasFilesToUpload = (_find(vm.queuedFiles, { isUploading: false, isUploaded: false, isError: false }));
    };

    /**
     * Removes the added file from the current list of files to upload
     *
     * @param  {File}  file  The file to remove
     */
    vm.removeFile = function (file) {

        // Do not remove if the file is being uploaded or was already uploaded
        if (file.isUploading || file.isUploaded || vm.uploading) { return; }

        _remove(vm.queuedFiles, file);
    };

    /**
     * Dismisses the modal
     */
    vm.cancel = function () {
        $uibModalInstance.dismiss({ itemsAdded: (vm.uploadedFiles.length > 0) });
    };

    /**
     * Triggers file selection
     */
    vm.addFile = function () {
        if (vm.uploading) { return; }

        document.getElementById('select-progress-files').click();
    };

    /**
     * Cancels the currently given file upload
     *
     * @param  {File}  file  The file
     */
    vm.cancelUpload = function (file) {

        // Cancels the file being currently uploaded
        vm.uploadItemRequest.$cancelRequest();

        file.isError = true;
        file.isUploading = false;
        file.errorMessage = 'The upload was cancelled by the user.';
        vm.uploadNextFile();
    };

    /**
     * Clears the current file queue
     */
    vm.clearQueue = function () {
        if (vm.uploading) { return; }

        vm.queuedFiles = [];
        vm.hasFilesToUpload = false;
    };

    /**
     * Cancels all the uploads currently queued
     */
    vm.cancelUploadQueue = function () {
        if (vm.fileBeingUploaded) {

            // Cancels the file being currently uploaded
            vm.uploadItemRequest.$cancelRequest();

            // Cancels all remaining upload requests
            vm.queuedFiles.forEach(function (file) {
                if (!file.isUploaded) {
                    file.isError = true;
                    file.isUploading = false;
                    file.errorMessage = 'The upload was cancelled by the user.';
                }
            });

            vm.uploading = false;
            vm.fileBeingUploaded = null;
        }
    };

    /**
     * Quickstarts the upload proccess for the selected files
     */
    vm.startUpload = function () {

        // early return if the upload is already in progress
        if (vm.uploading) { return; }

        let processErrorMessage = null;

        vm.upgradeLink = false;
        vm.errorMessage = null;

        // Check if the file queue is empty
        if (vm.queuedFiles.length === 0) {
            processErrorMessage = 'You must include at least one file to upload.';
        }

        // The item titles cannot be empty
        vm.queuedFiles.forEach(function (file) {
            if (file.title.trim() === '') {
                processErrorMessage = 'The item title cannot be empty.';
                return;
            }
        });

        // Check if there's at least one file that hasn't been uploaded yet
        var nextFile = vm.getNextFile();
        if (!nextFile) {
            processErrorMessage = 'There are no more files to upload.';
        }

        // Do not proceed if an error has been found
        if (processErrorMessage) {
            Flash.create('danger', processErrorMessage, 10000, { container: 'error-message-flash' }, true);
            return;
        }

        // Process upload queue
        vm.uploading = true;
        vm.hasFilesToUpload = true;
        vm.fileBeingUploaded = null;
        vm.currentUploadingItemIndex = 0;
        vm.totalUploadingItems = _filter(vm.queuedFiles, { isUploading: false, isUploaded: false, isError: false }).length;
        vm.uploadNextFile();
    };

    /**
     * Uploads the next file on the queue
     */
    vm.uploadNextFile = function () {
        var fileToUpload = vm.getNextFile();
        if (fileToUpload) {
            var data: any = {
                project_id: project.id,
                title: fileToUpload.title,
                description: fileToUpload.description,
            };

            if (vm.form.milestone && vm.form.milestone.id) {
                data.milestone_or_task_id = vm.form.milestone.id;
                data.milestone_or_task_type = vm.form.milestone.type;
            }

            // Sets upload control variables
            fileToUpload.isUploading = true;
            vm.currentUploadingItemIndex++;
            vm.fileBeingUploaded = fileToUpload;

            // Reads current file as dataUrl in order to send it to the server
            fileReaderService.readAsDataUrl(fileToUpload._file, vm)
                .then(function (result) {
                    data.file = result; // base64 encoded file
                    result = null; // eagerly clean memory to improve memory performance

                    // Uplods file to the backend
                    vm.uploadItemRequest = strukshurApiService.projectProgressItem.create(data);
                    vm.uploadItemRequest.$promise
                        .then(function (res) {
                            fileToUpload.isUploaded = true;
                            fileToUpload.isUploading = false;
                            vm.uploadedFiles.push(res.progressItem);
                        })
                        .catch(function (res) {
                            fileToUpload.isError = true;
                            fileToUpload.isUploading = false;
                            vm.errorMessage = (res.data && res.data.message ? res.data.message: '');

                            // Early return since the user cancelled the upload
                            if (res.xhrStatus && res.xhrStatus === 'abort') {
                                fileToUpload.errorMessage = 'The upload was cancelled by the user.';
                                return;
                            }

                            if (res.status === 403) {
                                vm.errorMessage = 'You don\'t have the necessary permission to add the file(s).';
                            } else if (vm.errorMessage.indexOf('enough storage') !== -1 && vm.errorMessage.indexOf('Upgrade') !== -1) {
                                vm.upgradeLink = true;
                            } else {
                                vm.errorMessage = 'There was an error trying to add the file(s) to the project.';
                            }
                            fileToUpload.errorMessage = vm.errorMessage;
                        })
                        .finally(function () {

                            // Uploads next file in queue
                            data = null;
                            vm.uploadNextFile();
                        })
                    ;
                })
            ;
        } else {

            // All files were processed
            vm.uploading = false;
            vm.hasFilesToUpload = false;
            vm.fileBeingUploaded = null;
        }
    };

    /**
     * Returns the next file to be uploaded from the queue
     *
     * @return {File}
     */
    vm.getNextFile = function () {
        return _find(vm.queuedFiles, { isUploading: false, isUploaded: false, isError: false });
    };

    vm.upgradeSettingsAccount = function () {
        vm.$parent.upgradeSettingsAccount();
    };

    // Setup uploader logic
    vm.uploader = new FileUploader({
        method: 'POST',
        autoUpload: false,
        queueLimit: 999,
        filters: [{
            name: 'isImageOrVideoFilter',
            fn: FileService.isImageOrVideoFilter
        }]
    });
    vm.uploader.onAfterAddingAll = vm.proccessAddedFiles;

    // Watcher to update upload progress
    vm.$on('updateProgress', function (event, args) {
        if (args.name === 'projectProgressItem' && vm.fileBeingUploaded) {
            vm.fileBeingUploaded.progress = args.progress;
        }
    });
})

.controller('ProjectDetailProgressEditItemCtrl', function ProjectDetailProgressEditItemCtrl($scope, $uibModalInstance, item, project, strukshurApiService) {
    var vm = $scope;

    vm.item = _clone(item);
    vm.form = {
        title: item.title,
        note: item.note,
        milestone: null,
    };
    vm.saving = false;
    vm.milestones = [];
    vm.errorMessage = null;

    // Retrieve list of project milestones
    strukshurApiService.projectMilestones.list({ project_id: project.id }).$promise
        .then(function (res) {
            var items = [];
            var existingIds = [];

            res.projectMilestones.forEach(function (milestone) {

                // Adds the milestones
                if (!existingIds.includes(milestone.id)) {
                    items.push({ id: milestone.id, title: milestone.title, type: 'milestone' });
                    existingIds.push(milestone.id);

                    // Adds the tasks
                    milestone.tasks.forEach(function (task) {
                        if (!existingIds.includes(task.id)) {
                            items.push({ id: task.id, title: ' ' + task.title, type: 'task' });
                            existingIds.push(task.id);
                        }
                    });
                }
            });
            vm.milestones = items;

            if (vm.item.task_id > 0) {
                var task = _find(vm.milestones, { id: vm.item.task_id });
                vm.item.milestone = task;
            } else if (item.milestone_id > 0) {
                var milestone = _find(vm.milestones, { id: vm.item.milestone_id });
                vm.item.milestone = milestone;
            }
        })
        .catch(function () { })
    ;

    /**
     * Dismisses the modal
     */
    vm.cancel = function () {
        $uibModalInstance.dismiss();
    };

    /**
     * Saves the item to the server
     */
    vm.saveItem = function () {
        vm.errorMessage = '';

        if (!vm.item.title) {
            vm.errorMessage = 'The progress item needs to have a title.';
            return;
        }

        var data: any = {
            item_id: item.id,
            title: vm.item.title,
            description: vm.item.note,
        };

        if (vm.item.milestone && vm.item.milestone.id) {
            data.milestone_or_task_id = vm.item.milestone.id;
            data.milestone_or_task_type = vm.item.milestone.type;
        }

        // Updates the
        vm.saving = true;
        strukshurApiService.projectProgressItem.update(data).$promise
            .then(function (res) {
                $uibModalInstance.close({ item: res.progressItem });
            })
            .catch(function (res) {
                vm.errorMessage = (res.data && res.data.message ? res.data.message: '');
                if (res.status === 403) {
                    vm.errorMessage = 'You don\'t have the necessary permission to save the file.';
                } else {
                    vm.errorMessage = 'There was an error trying to save the file.';
                }
            })
            .finally(function () {
                vm.saving = false;
            })
        ;
    };
})

;
