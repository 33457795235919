import {
    find as _find,
    findKey as _findKey,
    forOwn as _forOwn,
    pull as _pull,
    remove as _remove,
} from 'lodash';
import * as angular from 'angular';

angular.module('strukshurApp.projects', [
    'strukshurApp.projects.blueprints',
    'strukshurApp.projects.estimates',
    'strukshurApp.projects.files',
    'strukshurApp.projects.finishes',
    'strukshurApp.projects.inbox',
    'strukshurApp.projects.inspiration',
    'strukshurApp.projects.progress',
    'strukshurApp.projects.purchaseLists',
    'strukshurApp.projects.tasks',
    'strukshurApp.projects.team',
])

.config(function config($stateProvider, $uibModalProvider) {
    $uibModalProvider.options = { animation: true, backdrop: 'static', keyboard: false, ariaLabelledBy: 'modal-title', ariaDescribedBy: 'modal-body', size: 'lg' };

    $stateProvider
        .state('projects', {
            url: '/projects',
            views: {
                main: {
                    controller: 'projectsCtrl',
                    template: require('./projects.tpl.html')
                }
            },
            resolve: {
                userResolve: function(userRouterResolveService, userResolveService) {
                    userRouterResolveService.userMustBeLoggedin();

                    return userResolveService();
                },
                proResolve: function($q, $state, strukshurUserService){
                    var deferred = $q.defer();

                    return strukshurUserService.getUser().then(function (d) {

                        // Prevent access to user's profile page until pro paid account is active
                        if (strukshurUserService.isMissingPayment()) {
                            $state.go('pro-register-payment');

                            return false;
                        } else {
                            deferred.resolve(d);
                        }

                        return deferred.promise;
                    }, function (e) {
                        $state.go('home');

                        return false;
                    });
                }
            },
            data: { pageTitle: 'Projects', class: 'projects projects-home' }
        })

        .state('deleted-projects', {
            url: '/projects/deleted',
            views: {
                main: {
                    controller: 'DeletedProjectsCtrl',
                    template: require('./deleted-projects.tpl.html')
                }
            },
            resolve: {
                userResolve: function(userRouterResolveService, userResolveService) {
                    userRouterResolveService.userMustBeLoggedin();

                    return userResolveService();
                },
            },
            data: { pageTitle: 'Deleted projects', class: 'projects deleted-projects' }
        })

        .state('projects-detail', {
            url: '/projects/:project_id/:project_slug/detail',
            views: {
                main: {
                    controller: 'ProjectDetailCtrl',
                    template: require('./project.tpl.html')
                },
            },
            resolve: {
                project: function ($state, $stateParams, strukshurApiService) {
                    return strukshurApiService.project
                        .get({ project_id: $stateParams.project_id }).$promise
                        .then(function (res) { return res.project; })
                        .catch(function (res) {
                            if (res.status === 403) {
                                $state.go('projects-access-denied');
                            }
                        })
                    ;
                },
                userResolve: function (userRouterResolveService) {
                    return userRouterResolveService.userMustBeLoggedin();
                },
                memberInfo: function ($state, project, strukshurApiService, strukshurUserService, userResolve) {
                    return strukshurApiService.projectTeamMember
                        .get({ project_id: project.id, member_id: userResolve.id }).$promise
                        .then(function (res) {
                            strukshurUserService.setProjectPermissions(project, res.teamMember.permissions);

                            return res.teamMember;
                        })
                        .catch(function () {
                            return $state.go('projects-detail.access-denied');
                        })
                    ;
                }
            },
            data: { pageTitle: 'Project / Detail', class: 'projects projects-detail', fluidContainer: true }
        })

        .state('projects-detail.access-denied', {
            url: '/access-denied',
            views: {
                '': {
                    controller: 'ProjectAccessDeniedCtrl',
                    template: require('./project.access-denied.tpl.html')
                },
            },
            data: { pageTitle: 'Project / Access Denied', class: 'projects projects-access-denied' },
            params: { section: null }
        })

        .state('projects-access-denied', {
            url: '/access-denied',
            views: {
                main: {
                    controller: 'ProjectAccessDeniedCtrl',
                    template: require('./project.access-denied.tpl.html')
                },
            },
            data: { pageTitle: 'Project / Access Denied', class: 'projects projects-access-denied' }
        })
    ;
})

.controller('projectsCtrl', function projectsCtrl ($scope, $timeout, $uibModal, angularGridInstance, filterFilter, smoothScroll, strukshurApiService, strukshurOrganizationService, strukshurUserService, toastr, uiTourService) {
    var vm = $scope;

    vm.page = 0;
    vm.total = 0;
    vm.projects = [];
    vm.loading = false;
    vm.searchTerm = '';
    vm.currentUser = {};
    vm.filteredProjects = [];
    vm.userOrganizations = [];
    vm.user = strukshurUserService.getUser();

    vm.filter = { selectedOwner: null };
    vm.ownerOptions = [{ id: null, name: ' All projects' }];
    vm.filter.selectedOwner = vm.ownerOptions[0];

    vm.user.then(function () {
        vm.isPro = strukshurUserService.isPro();
        vm.currentUser = strukshurUserService.getUserObj();

        // Adds the current user as an owner option filter
        vm.ownerOptions.push({
            type: 'user',
            id: vm.currentUser.id,
            name: vm.currentUser.fullName,
            avatar: vm.currentUser.profileImage,
        });

        // Populates initial projects list
        vm.getNextPage();
    });

    /**
     * Controller initialization logic
     */
    vm.init = function () {

        // // Loads available user rooms
        // vm.rooms = [];
        // strukshurApiService.userRooms.list({ house_id: strukshurUserService.getHouseId() }).$promise
        //     .then(function (res) {
        //         vm.rooms = res.rooms;
        //     })
        // ;

        // Watches for search field changes to filter the projects list
        vm.$watch('searchTerm', vm.filterProjects);

        // Scrolls to top if coming from another long page
        smoothScroll(document.getElementById('top'));

        vm.getUserOrganizations();

        // Initializes the current page's tour
        vm.setupTour();
    };

    /**
     * Filters the project list according to the values from the UI
     */
    vm.filterProjects = function () {

        // Filter all the project lists
        var filter: any = { title: vm.searchTerm };
        if (vm.filter.selectedOwner.id) {
            filter.owner_id = vm.filter.selectedOwner.id;
        }
        vm.filteredProjects = filterFilter(vm.projects, filter);

        // Refresh project galleries
        $timeout(angularGridInstance.gallery.refresh, 50);
    };

    /**
     * Loads the next page for the projects's list from the server
     */
    vm.getNextPage = function () {

        // Only triggers a search if there's still more results to get or if
        // there's currently no other search request happening
        if ((vm.total !== 0 && vm.projects.length >= vm.total) || vm.loading) { return; }

        vm.page++;
        vm.loading = true;

        // Get timer to properly display loading content
        var timer = window.performance.now();

        // Loading additional projects
        strukshurApiService.projectprojects.query({ page: vm.page, limit: 40 }).$promise
            .then(function (res) {

                // artificial loading delay if the call takes less than 2s
                var delay = timer - window.performance.now() + 2500;
                $timeout(function () {
                    res.projects.forEach(function (project) {

                        // Only adds the project to the current page if it doesn't exist yet
                        if (!_find(vm.projects, { id: project.id })) {
                            vm.projects.push(project);
                        }

                        // Adds the current project's owner as a filter option if
                        // it hasn't been added yet
                        if (!_find(vm.ownerOptions, { id: project.owner_id })) {
                            vm.ownerOptions.push({
                                id: project.owner_id,
                                name: project.owner_name,
                                type: project.owner_type,
                                avatar: project.owner_avatar,
                            });
                        }
                    });

                    vm.loading = false;
                    vm.total = res.total;
                    vm.filterProjects();
                }, delay);
            })
            .catch(function () {
                vm.page--;
                vm.loading = false;
            })
        ;
    };

    /**
     * Loads the current user organizations'
     */
    vm.getUserOrganizations = () => {
        strukshurOrganizationService.getUserOrganizations()
            .then(res => vm.userOrganizations = res.organizations)
            .catch(angular.noop)
        ;
    };

    /**
     * Wether the user can duplicate the project or not
     *
     * @param  project  The project
     */
    vm.canDuplicateProject = (project) => {
        if (project.owner_type === 'user') {
            return vm.currentUser.id === project.owner_id;
        }

        let organization = _find(vm.userOrganizations, { id: project.owner_id });
        if (organization) {
            return organization.owner_id === vm.currentUser.id;
        }

        return false;
    };

    /**
     * Opens modal to create a new project
     */
    vm.createProjectPop = () => {
        var modalInstance;

        // if user has a basic plan then only allow 1 project to be created otherwise
        // show popup to upgrade account
        if (!strukshurUserService.hasAvailableProjects(vm.currentUser.plan, vm.currentUser.projectsOwned)) {
            modalInstance = $uibModal.open({
                scope: $scope,
                keyboard: true,
                controller: 'UpgradeModalCtrl',
                template: require('../../common/templates/upgrade-modal.tpl.html'),
                resolve: {}
            });
        } else {
            modalInstance = $uibModal.open({
                scope: vm,
                keyboard: true,
                controller: 'ProjectModalInstanceCtrl',
                template: require('../../common/templates/project-create-modal.tpl.html'),
                resolve: {
                    allowOwnerChange: true,
                    organization: null,
                    project: () => {},
                    title: () => 'Create project',
                }
            });

            modalInstance.result.then((newProject) => {
                if (newProject && newProject.id) {
                    vm.projects.unshift(newProject);
                    strukshurUserService.addProject(newProject);
                    vm.filterProjects();

                    $timeout(() => {

                        // Scrolls to new project element
                        smoothScroll(document.querySelector('div[data-project-id="' + newProject.id + '"]'));

                        // Adds highlight class to help user spot the new project
                        angular.element('div[data-project-id="' + newProject.id + '"]').addClass('highlight');

                        // Removes the highlight class after a delay
                        $timeout(() => angular.element('div[data-project-id="' + newProject.id + '"]').removeClass('highlight'), 2000);
                    }, 50);
                }
            }, angular.noop);
        }
    };

    /**
     * Opens modal to confirm a project's deletion
     *
     * @param  {object}  project  The project to delete
     */
    vm.confirmPop = function (project) {
        vm.confirmMessage = false;
        var modalInstance = $uibModal.open({
            scope: vm,
            keyboard: true,
            controller: 'ConfirmProjectDeleteCtrl',
            template: require('../../common/templates/confirm-modal.tpl.html'),
            resolve: {
                project: function () {
                    return project;
                },
                message: function () {
                    return 'Are you sure you want to delete the project? This action cannot be undone.';
                }
            }
        });

        modalInstance.result.then(function (selectedItem) {

            // Confirmed
            project.deleted = true;
            strukshurUserService.removeProject(project);
            _remove(vm.projects, project);
            vm.filterProjects();
        }, angular.noop);
    };

    /**
     * Opens modal to edit the received project
     *
     * @param  {object}  project  The project
     */
    vm.editPop = function (project) {
        var modalInstance = $uibModal.open({
            scope: vm,
            keyboard: true,
            controller: 'ProjectModalInstanceCtrl',
            template: require('../../common/templates/project-edit-modal.tpl.html'),
            resolve: {
                allowOwnerChange: true,
                organization: null,
                project: function () {
                    return project;
                },
                title: function () {
                    return 'Edit project';
                },
            }
        });

        modalInstance.result.then(
            function (selectedItem) {
                if (selectedItem && selectedItem.id) {
                    strukshurUserService.updateProject(selectedItem); //update top list

                    // Refreshes owned project count if the project owner was changed
                    if (project.owner_id !== selectedItem.owner_id) {
                        strukshurUserService.refreshOwnedProjectsCount();
                    }

                    // Updates local project with the info coming from the backend
                    _forOwn(selectedItem, function (v, k) {
                        project[k] = v;
                    });
                }
            },

            angular.noop
        );
    };

    /**
     * Opens modal to edit the received project
     *
     * @param  {object}  project  The project
     */
    vm.duplicateProject = function (project) {
        var modalInstance = $uibModal.open({
            scope: vm,
            keyboard: true,
            controller: 'ProjectDuplicateModalCtrl',
            template: require('../../common/templates/project-duplicate-modal.tpl.html'),
            resolve: {
                project: function () {
                    return project;
                },
            }
        });

        modalInstance.result.then(
            () => toastr.success('The project was scheduled to be duplicated successfully.', 'Success!'),
            angular.noop
        );
    };

    /**
     * Setup and initialize product tour for this page
     */
    vm.setupTour = function () {
        var steps = [
            { selector: '.btn-create-project', stepId: 'new-project', order: 0, popupClass: 'create-project-step', placement: 'left', content: 'Create a new project here.' },
            { selector: '.board', stepId: 'project-hover', order: 1, popupClass: 'project-hover-step', placement: 'right', content: 'Hover over a project to edit its info, delete it ot change its cover image here.' },
            { selector: '.board', order: 2, popupClass: 'project-select-step', placement: 'right', content: 'Select the project you\'d like to work on to get started.' },
        ];

        // First we setup the product tour
        vm.tour = uiTourService.createDetachedTour('projects-list-tour', { appendToBody: true, backdrop: true });

        // Then we add the defined steps to the tour
        steps.forEach(function (step) {
            vm.tour.createStep(step);
        });

        vm.tour.on('stepChanged', function (step) {
            vm.tour.pause();
            $timeout(vm.tour.resume, 100);
        });
    };

    /**
     * Initializes product tour
     */
    vm.initTour = function () {

        // And finally display the product tour overview modal to get the flow started
        var modalInstance = $uibModal.open({
            scope: vm,
            keyboard: true,
            controller: angular.noop,
            template: require('../../common/templates/product-tour/projects-list-overview.tpl.html')
        });
        modalInstance.result.then(angular.noop, angular.noop);
    };

    /**
     * Starts the tour by showing the first step
     */
    vm.startTour = function () {
        vm.tour.start();
    };

    vm.init();
})

.controller('DeletedProjectsCtrl', function DeletedProjectsCtrl ($scope, $timeout, $uibModal, angularGridInstance, filterFilter, smoothScroll, strukshurApiService, strukshurOrganizationService, strukshurUserService) {
    var vm = $scope;

    vm.page = 0;
    vm.total = 0;
    vm.projects = [];
    vm.loading = false;
    vm.searchTerm = '';
    vm.currentUser = {};
    vm.filteredProjects = [];
    vm.userOrganizations = [];
    vm.user = strukshurUserService.getUser();

    vm.user.then(() => {
        vm.isPro = strukshurUserService.isPro();
        vm.currentUser = strukshurUserService.getUserObj();
    });

    /**
     * Controller initialization logic
     */
    vm.init = () => {
        vm.getNextPage();
        smoothScroll(document.getElementById('top'));
    };

    /**
     * Loads the next page for the projects's list from the server
     */
    vm.getNextPage = () => {

        // Only triggers a search if there's still more results to get or if
        // there's currently no other search request happening
        if ((vm.total !== 0 && vm.projects.length >= vm.total) || vm.loading) { return; }

        vm.page++;
        vm.loading = true;

        // Get timer to properly display loading content
        var timer = window.performance.now();

        // Loading additional projects
        strukshurApiService.projects.deleted({ page: vm.page, limit: 40 }).$promise
            .then((res) => {

                // artificial loading delay if the call takes less than 2s
                var delay = timer - window.performance.now() + 2500;
                $timeout(() => {
                    res.projects.forEach((project) => {

                        // Only adds the project to the current page if it doesn't exist yet
                        if (!_find(vm.projects, { id: project.id })) {
                            vm.projects.push(project);
                        }
                    });

                    vm.total = res.total;
                }, delay);
            })
            .catch(() => vm.page--)
            .finally(() => vm.loading = false)
        ;
    };

    /**
     * Opens modal to confirm a project's restoration
     *
     * @param  {object}  project  The project to restore
     */
    vm.restoreProject = (project) => {
        vm.confirmMessage = false;
        var modalInstance = $uibModal.open({
            scope: vm,
            keyboard: true,
            controller: 'ConfirmProjectRestoreCtrl',
            template: require('../../common/templates/confirm-modal.tpl.html'),
            resolve: {
                project: () => project,
                message: () => 'Are you sure you want to restore the project?',
            }
        });

        modalInstance.result.then((selectedItem) => {

            // Confirmed
            project.deleted = false;
            _remove(vm.projects, project);
        }, angular.noop);
    };

    /**
     * Opens modal to confirm a project's forceful removel
     *
     * @param  {object}  project  The project to remove
     */
    vm.removeProject = (project) => {
        vm.confirmMessage = false;
        var modalInstance = $uibModal.open({
            scope: vm,
            keyboard: true,
            controller: 'ConfirmProjectRemoveCtrl',
            template: require('../../common/templates/confirm-modal.tpl.html'),
            resolve: {
                project: () => project,
                message: () => 'Are you sure you want to <b>completely</b> remove the project? This action cannot be undone.',
            }
        });

        modalInstance.result.then((selectedItem) => {

            // Confirmed
            _remove(vm.projects, project);
        }, angular.noop);
    };

    vm.init();
})

.controller('ProjectAccessDeniedCtrl', function ProjectAccessDeniedCtrl ($scope, $stateParams) {
    var vm = $scope;

    vm.section = $stateParams.section;
})

.controller('ProjectDetailCtrl', function ProjectDetailCtrl ($aside, $interval, $scope, $state, $timeout, $window, hlStickyElement, project, smoothScroll, strukshurApiService, strukshurUserService, strukshurUtilService, userResolve) {
    var vm = $scope;
    var currentPageYOffset;

    vm.pages = [];
    vm.project = project;
    vm.projectMenu = null;
    vm.menuVisible = false;
    vm.currentUser = userResolve;
    vm.availableProjects = [];
    vm.currentRoute = $state.current.name;

    // Sets wether or not the current member is a pro accessing it to bid on the project or not
    vm.currentUserIsProAndNotMember = vm.project.openForBids === true && vm.currentUser.userType === 'pro' && !strukshurUserService.hasPermission('project_view', project);

    // Search list of available projects for the user
    strukshurApiService.projectprojects.query({ page: 1, limit: 40 }).$promise
        .then((res) => {
            res.projects = _pull(res.projects, _find(res.projects, { id: vm.project.id }));
            vm.availableProjects = res.projects;
        })
    ;

    // Gets the logged-in user permission on the project
    vm.memberPermissions = [];
    strukshurUserService.getProjectPermissions(project).promise
        .then((permissions) => {
            vm.memberPermissions = permissions;

            // Checks if the user is allowed to access the project, the user can
            // access the project if they have the permission to do so or if
            // they're a pro user and the project is open for bidding
            if (!strukshurUserService.hasPermission('project_view', project) && !vm.projectIsOpenForBidsAndUserCanViewIt()) {
                return $state.go('projects-detail.access-denied');
            }

            // Assemble the project menu
            vm.setAvailablePages();

            // Sticks it to the page
            vm.makeMenuSticky();
        })
    ;

    /**
     * Sets project menu as a sticky element
     */
    vm.makeMenuSticky = () => {

        // We don't setup a sticky menu on phones because of the reduced size
        if (angular.element('html').hasClass('is-phone')) { return; }

        const menuEl = angular.element(document.querySelector('.project-menu-section .project-menu'));
        vm.stickyMenu = hlStickyElement(menuEl);

        // Draw the sticky element through an apply as the event wasn't from Angular
        angular.element($window).bind('scroll', () => {
            vm.$apply(() => vm.stickyMenu.draw());
        });

        // Redraws sticky menu if the window changed size
        angular.element($window).bind('resize', () => {
            vm.$apply(() => vm.stickyMenu.draw());
        });

        // Make sure we clean up after ourselves otherwise the sticky element will
        // stay in the stack and could cause trouble
        vm.$on('$destroy', () => vm.stickyMenu.destroy());
    };

    /**
     * Sets the list of available pages according to the current user permissions on the project
     */
    vm.setAvailablePages = () => {

        // Adds basic pages for pro users that are not a project member to view
        // the project details if the project is open for bids
        if (vm.currentUserIsProAndNotMember) {
            // if (strukshurUserService.hasPermission('project_bid_view', project) || vm.projectIsOpenForBidsAndUserCanViewIt()) {
            //     vm.pages.push({ title: 'Bids', route: 'projects-detail.bids', icon: 'bids', active: false, items: [], collapsed: true });
            // }

            if (strukshurUserService.hasPermission('project_inbox_view', project) || vm.projectIsOpenForBidsAndUserCanViewIt()) {
                vm.pages.push({ title: 'Inbox', route: 'projects-detail.inbox', icon: 'inbox-in', active: false, items: [], collapsed: true });
            }

            if (strukshurUserService.hasPermission('project_estimate_view', project) || vm.projectIsOpenForBidsAndUserCanViewIt()) {
                vm.pages.push({ title: 'Estimates', route: 'projects-detail.estimates', icon: 'calculator', active: false, items: [], collapsed: true });
            }
        } else {
            if (strukshurUserService.hasPermission('project_pin_view', project)) {
                vm.pages.push({ title: 'Inspiration', route: 'projects-detail.inspiration', icon: 'lightbulb-on', active: false, items: [], collapsed: true });
            }

            if (strukshurUserService.hasPermission('project_inbox_view', project)) {
                vm.pages.push({ title: 'Inbox', route: 'projects-detail.inbox', icon: 'inbox-in', active: false, items: [], collapsed: true });
            }

            if (strukshurUserService.hasPermission('project_task_view', project)) {
                vm.pages.push({ title: 'Tasks', route: 'projects-detail.tasks', icon: 'list-check', active: false, items: [], collapsed: true });
            }

            if (strukshurUserService.hasPermission('purchase_list_view', project)) {
                vm.pages.push({ title: 'Purchases', route: 'projects-detail.lists', icon: 'money-check-dollar-pen', active: false, items: [], collapsed: true });
            }

            if (strukshurUserService.hasPermission('project_progress_item_view', project)) {
                vm.pages.push({ title: 'Progress', route: 'projects-detail.progress', icon: 'photo-film', active: false, items: [], collapsed: true });
            }

            if (strukshurUserService.hasPermission('project_finish_view', project)) {
                vm.pages.push({ title: 'Finishes', route: 'projects-detail.finishes', icon: 'table-list', active: false, items: [], collapsed: true });
            }

            if (strukshurUserService.hasPermission('project_team_member_view', project)) {
                vm.pages.push({ title: 'Team', route: 'projects-detail.team', icon: 'users', active: false, items: [], collapsed: true });
            }

            if (strukshurUserService.hasPermission('project_file_view', project)) {
                vm.pages.push({ title: 'Files', route: 'projects-detail.files', icon: 'folders', active: false, items: [], collapsed: true });
            }

            if (strukshurUserService.hasPermission('project_estimate_view', project)) {
                vm.pages.push({ title: 'Estimates', route: 'projects-detail.estimates', icon: 'calculator', active: false, items: [], collapsed: true });
            }

            // if (strukshurUserService.hasPermission('project_bid_view', project)) {
            //     vm.pages.push({ title: 'Bids', route: 'projects-detail.bids', icon: 'bids', active: false, items: [], collapsed: true });
            // }

            if (strukshurUserService.hasPermission('project_room_blueprint_view', project)) {
                vm.pages.push({ title: 'Blueprints', route: 'projects-detail.blueprints', icon: 'cubes', active: false, items: [], collapsed: true });
            }
        }

        // Checks if the user has at least one section available on the project
        if (vm.pages.length > 0) {

            // Displays the project menu
            vm.menuVisible = true;

            // Navigates to the first page if the user accessed the project detail route
            if (vm.isActive('projects-detail')) {
                $state.go(vm.pages[0].route);
            }
        } else {
            $state.go('projects-detail.access-denied');
        }
    };

    /**
     * Opens the project menu aside
     */
    vm.openProjectMenu = function () {
        vm.projectMenu = $aside({
            title: 'Project Menu',
            template: require('../../common/templates/projectMenu.tpl.html'),
            controller: 'ProjectMenuCtrl',
            placement: 'left',
            animation: 'am-fade-and-slide-left',
            scope: vm,
            show: true
        });
    };

    /**
     * Wether the given route is active or not
     *
     * @param  {string}  route  The route name
     *
     * @return {boolean}
     */
    vm.isActive = function (route) {
        return $state.is(route);
    };

    /**
     * Wether to show the scroll to top button or not
     */
    vm.showScrollToTopButton = () => {
        if (currentPageYOffset !== window.pageYOffset) {
            currentPageYOffset = window.pageYOffset;

            if (currentPageYOffset > 500) {
                angular.element(document.getElementById('backtop')).addClass('visible');
            } else {
                angular.element(document.getElementById('backtop')).removeClass('visible');
            }
        }
    };

    /**
     * Sets the the sub menu items for the given page
     *
     * @param  {string}  pageName  Page name
     * @param  {array}   items     Page items
     */
    vm.setPageItems = function (pageName, items) {
        const key = _findKey(vm.pages, { route: pageName });
        if (key) {
            $timeout(function () {
                vm.$apply(function () {
                    vm.pages[key].items = items;
                });
            }, 100);
        }
    };

    /**
     * Navigates to the given page
     *
     * @param  {object}  page  The page
     */
    vm.goToPage = function (page) {

        // Only navigates to the route if it's not the current one
        if (vm.currentRoute !== page.route) {
            $state.go(page.route);
        }

        if (vm.projectMenu) {
            vm.projectMenu.hide();
        }
    };

    /**
     * Navigates to the given project
     *
     * @param  {object}  project  The project
     */
    vm.goToProject = function (project) {
        $state.go('projects-detail', { 'project_id': project.id, 'project_slug': strukshurUtilService.slugify(project.title) });
    };

    /**
     * Smoothly scrolls to the received element
     *
     * @param  {Object}  item  The item to scroll to
     */
    vm.goToPageItem = function (item) {
        smoothScroll(document.getElementById(item.href), { offset: 80 });
    };

    /**
     * Wether the current user has the given permission on the project
     *
     * @param  {String}  permission  The permission key
     *
     * @return {Boolean}
     */
    vm.checkPermission = function (permission) {
        return strukshurUserService.hasPermission(permission, vm.project);
    };

    /**
     * Wether the current user has any of the given permissions on the project
     *
     * @param  {Array}  permissions  The permission keys
     *
     * @return {Boolean}
     */
    vm.hasAnyPermissions = function (permissions) {
        return strukshurUserService.hasAnyPermissions(permissions, vm.project);
    };

    /**
     * Returns wether the current project is open for bids and if the user can view it
     *
     * @return {Boolean}
     */
    vm.projectIsOpenForBidsAndUserCanViewIt = function () {
        return (vm.project.openForBids === true && vm.currentUser.userType === 'pro');
    };

    /**
     * Redirects current user to the billing section of the settings page
     */
    vm.upgradeSettingsAccount = function () {
        $state.go('account-settings', { 'active_tab': 'billing' });
    };

    vm.$on('$stateChangeSuccess', function () {
        vm.pages.forEach(function(page) {
            page.items = [];
            page.active = vm.isActive(page.route);
        });
    });

    // Check if the button should appear only every 2 seconds so we don't overload the browser
    $interval(vm.showScrollToTopButton, 500);
})

.controller('ProjectMenuCtrl', function ProjectMenuCtrl ($scope) {
    var vm = $scope;
})

.controller('ConfirmProjectDeleteCtrl', function ConfirmProjectDeleteCtrl ($scope, $uibModalInstance, message, project, strukshurApiService, toastr) {
    var vm = $scope;

    vm.title = 'Confirm';
    vm.loading = false;
    vm.message = message;
    vm.project = project;
    vm.errorMessage = false;

    vm.confirm = function () {
        if (vm.loading) { return; }

        var data = {
            project_slug: vm.project.slug
        };

        vm.loading = true;
        vm.errorMessage = false;
        strukshurApiService.project.delete(data, function (d) {
            $uibModalInstance.close(true);
        }, function (err) {
            vm.loading = false;

            if (err && err.data && err.data.message) {
                toastr.error(err.data.message, 'Error');
            } else if (err.status === 403) {
                toastr.error('You don\'t have the necessary permission to delete the project.', 'Error');
            } else {
                toastr.error('There was an error trying to delete the project.', 'Error');
            }
        });
    };

    vm.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };
})

.controller('ConfirmProjectRemoveCtrl', function ConfirmProjectRemoveCtrl ($scope, $uibModalInstance, message, project, strukshurApiService, toastr) {
    var vm = $scope;

    vm.title = 'Remove project';
    vm.loading = false;
    vm.message = message;
    vm.project = project;
    vm.errorMessage = false;

    vm.confirm = () => {
        if (vm.loading) { return; }

        vm.loading = true;
        vm.errorMessage = false;
        strukshurApiService.project.remove({ project_id: project.id }).$promise
            .then(res => {
                $uibModalInstance.close(true);
            })
            .catch((err) => {
                if (err && err.data && err.data.message) {
                    toastr.error(err.data.message, 'Error');
                } else if (err.status === 403) {
                    toastr.error('You don\'t have the necessary permission to remove the project.', 'Error');
                } else {
                    toastr.error('There was an error trying to remove the project.', 'Error');
                }
            })
            .finally(() => vm.loading = false)
        ;
    };

    vm.cancel = () => $uibModalInstance.dismiss('cancel');
})

.controller('ConfirmProjectRestoreCtrl', function ConfirmProjectRestoreCtrl ($scope, $uibModalInstance, message, project, strukshurApiService, toastr) {
    var vm = $scope;

    vm.title = 'Restore project';
    vm.loading = false;
    vm.message = message;
    vm.project = project;
    vm.errorMessage = false;

    vm.confirm = () => {
        if (vm.loading) { return; }

        vm.loading = true;
        vm.errorMessage = false;
        strukshurApiService.project.restore({ project_id: project.id }).$promise
            .then(res => $uibModalInstance.close(true))
            .catch((err) => {
                if (err && err.data && err.data.message) {
                    toastr.error(err.data.message, 'Error');
                } else if (err.status === 403) {
                    toastr.error('You don\'t have the necessary permission to restore the project.', 'Error');
                } else {
                    toastr.error('There was an error trying to restore the project.', 'Error');
                }
            })
            .finally(() => vm.loading = false)
        ;
    };

    vm.cancel = () => $uibModalInstance.dismiss('cancel');
})

.controller('ProjectProgressModalCtrl', function ProjectProgressModalCtrl ($scope) {
    var vm = $scope;

    vm.progress = 0;
    vm.title = 'Uploading';

    vm.$on('updateProgress', function (event, args) {
        if (args.name === 'projectFile') {
            vm.progress = args.progress;

            if (vm.progress >= 100) {
                vm.title = 'Saving...';
            }
        }
    });
})

.controller('UpgradeModalCtrl', function UpgradeModalCtrl ($scope, $state, $uibModalInstance) {
    var vm = $scope;

    vm.loading = false;
    vm.errorMessage = false;
    vm.title = 'Upgrade Account';

    vm.confirm = function () {
        $uibModalInstance.close(true);
        $state.go('account-settings', { 'active_tab': 'billing' });
    };

    vm.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };
})

.controller('ProjectDuplicateModalCtrl', function ProjectDuplicateModalCtrl ($scope, $uibModalInstance, Flash, project, strukshurApiService, strukshurOrganizationService) {
    var vm = $scope;

    vm.loading = false;
    vm.teamMembers = [];
    vm.ownerOptions = [];
    vm.project = project;
    vm.duplicateForm = { title: project.title + ' (duplicate)', projectOwner: {} };
    vm.sections = [
        { label: 'Inspiration', id: 'inspiration', value: 'inspiration' },
        { label: 'Inbox', id: 'inbox', value: 'inbox' },
        { label: 'Tasks', id: 'tasks', value: 'tasks' },
        { label: 'Purchases', id: 'purchases', value: 'purchases' },
        { label: 'Progress', id: 'progress', value: 'progress' },
        { label: 'Finishes', id: 'finishes', value: 'finishes' },
        { label: 'Files', id: 'files', value: 'files' },
        { label: 'Estimates', id: 'estimates', value: 'estimates' },
    ];
    vm.sectionsOpts = {
        twistieExpandedTpl: '<i class="fa fa-minus-circle"></i>',
        twistieCollapsedTpl: '<i class="fa fa-plus-circle"></i>',
        twistieLeafTpl: '&nbsp;&nbsp;',
    };

    /**
     * Startup logic for the controller
     */
    vm.init = () => {

        // Load team members list to display project owner select widget
        strukshurApiService.projectTeamMembers.list({ project_id: vm.project.id }).$promise
            .then(res => {
                vm.teamMembers = res.members;

                vm.teamMembers.forEach(member => {

                    // Set type in order to group it later on the view
                    member.type = 'user';

                    // Add all retrieved organizations as a possible owner option
                    vm.ownerOptions.push(member);
                });

                // Set the current project owner as the selected one in the widget
                if (project.owner_type === 'user') {
                    vm.duplicateForm.projectOwner = _find(vm.teamMembers, { id: project.owner_id });
                }
            })
            .catch(angular.noop)
        ;

        // Returns list of organizations the user has an access to in order
            // to define as possible owner options
        strukshurOrganizationService.getUserOrganizations()
            .then(res => {
                vm.organizations = res.organizations;

                vm.organizations.forEach(organization => {

                    // Set type in order to group it later on the view
                    organization.type = 'organization';

                    // Add all retrieved organizations as a possible owner option
                    vm.ownerOptions.push(organization);
                });

                // Set the current project owner as the selected one in the widget
                if (project && project.owner_type === 'organization') {
                    vm.duplicateForm.projectOwner = _find(vm.organizations, { id: project.owner_id });
                }
            })
            .catch(angular.noop)
        ;
    };

    /**
     * Closes the current modal
     */
    vm.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };

    vm.duplicateProject = (form) => {
        if (vm.loading) { return; }

        if (!vm.duplicateForm.projectOwner) {
            Flash.create('danger', 'You need to select an owner for the project.', 10000, { container: 'project-duplicate-flash' }, true);
            return;
        }

        var selectedSections = [];
        vm.sections.forEach((section) => {
            if (section.selected) {
                selectedSections.push(section.value);
            }
        });

        if (selectedSections.length === 0) {
            Flash.create('danger', 'You need to select at least one section to duplicate.', 10000, { container: 'project-duplicate-flash' }, true);
            return;
        }

        if (form.$valid) {
            let data = {
                project_id: project.id,
                new_project_title: vm.duplicateForm.title,
                new_owner_id: vm.duplicateForm.projectOwner.id,
                new_owner_type: vm.duplicateForm.projectOwner.type,
                selected_sections: selectedSections.join(','),
            };

            vm.loading = true;
            strukshurApiService.project.duplicate(data).$promise
                .then(res => {
                    $uibModalInstance.close(true);
                })
                .catch(err => {
                    if (err.status === 403) {
                        Flash.create('danger', 'You don\'t have the necessary permission to duplicate the project.', 10000, { container: 'project-duplicate-flash' }, true);
                    } else {
                        Flash.create('danger', 'There was an error while duplicating the project.', 10000, { container: 'project-duplicate-flash' }, true);
                    }
                })
                .finally(() => vm.loading = false)
            ;
        }
    };

    vm.init();
})

;
