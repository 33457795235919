import * as angular from 'angular';

var app = angular.module('strukshurProjectService', ['ngResource']);
app.factory('strukshurProjectService', function ($q, strukshurApiService, $timeout) {
    var th = this;

    this.progressVideoStatus = function (progress) {
        $timeout(function (data) {
            //debugger;
            var progress = data.progress, th = data.th;
            strukshurApiService.getProgressVideoStatus.query({ progress_id: progress.id }, function (d) {
                var $progress;
                if (!d.isProcessing) {
                    progress.isProcessing = d.isProcessing;
                    progress.width = d.width;
                    progress.height = d.height;
                } else {
                    $progress = document.getElementById('progress_items');
                    if ($progress) {
                        th.progressVideoStatus(progress);
                    }
                }
            });
        }, 5000, true, { progress: progress, th: th });
    };

    this.pinVideoStatus = function (pin) {
        $timeout(function (data) {
            var pin = data.pin, th = data.th;
            strukshurApiService.getProjectPinVideoStatus.query({ pin_id: pin.id }, function (d) {
                var $pin;
                if (!d.isProcessing) {
                    pin.isProcessing = d.isProcessing;
                    pin.width = d.width;
                    pin.height = d.height;
                } else {
                    $pin = document.getElementById('project_pins');
                    if ($pin) {
                        th.pinVideoStatus(pin);
                    }
                }
            });
        }, 5000, true, { pin: pin, th: th });
    };

    this.finishesVideoStatus = function (finishFile) {
        $timeout(function (data) {
            var finishFile = data.finishFile, th = data.th;
            strukshurApiService.getFinishVideoStatus.query({ finishFile_id: finishFile.id }, function (d) {
                var $finishFile;
                if (!d.isProcessing) {
                    finishFile.isProcessing = d.isProcessing;
                    finishFile.width = d.width;
                    finishFile.height = d.height;
                } else {
                    $finishFile = document.getElementById('finishFile_items');
                    if ($finishFile) {
                        th.finishesVideoStatus(finishFile);
                    }
                }
            });
        }, 5000, true, { finishFile: finishFile, th: th });
    };

    return {
        progressVideoStatus: th.progressVideoStatus,
        pinVideoStatus: th.pinVideoStatus,
        finishesVideoStatus: th.finishesVideoStatus,

        /**
         * Creates a new project with the given params
         *
         * @param  {object}  myform  The form object
         *
         * @return {Promise}
         */
        createProject: function (myform) {
            var deferred = $q.defer();
            var fileBase64 = '';

            if (myform.projectCover.$viewValue && myform.projectCover.$viewValue.base64) {
                fileBase64 = 'data:' + myform.projectCover.$viewValue.filetype + ';base64,' + myform.projectCover.$viewValue.base64;
            }

            if (!myform.$valid) {
                deferred.reject();

                return deferred.promise;
            }

            var formData = {
                title: myform.projectTitle.$viewValue,
                details: myform.projectDetails.$viewValue,
                address1: myform.address1.$viewValue,
                address2: myform.address2.$viewValue,
                city: myform.city.$viewValue,
                state: myform.projectState.$viewValue,
                zip: myform.zip.$viewValue,
                organization_id: null,
                project_owner_id: null,
                cover: fileBase64
            };

            if (myform.projectOwner) {
                if (myform.projectOwner.type === 'organization') {
                    formData.organization_id = myform.projectOwner.id;
                } else {
                    formData.project_owner_id = myform.projectOwner.id;
                }
            }

            strukshurApiService.project.add(formData, function(d) {
                deferred.resolve(d);
            }, function(e) {
                console.log(e);
                deferred.reject(e);
            });

            return deferred.promise;
        },

        editProject: function (project_id, myform) {
            var deferred = $q.defer();
            var fileBase64 = '';

            if (myform.projectCover.$viewValue && myform.projectCover.$viewValue.base64) {
                fileBase64 = 'data:' + myform.projectCover.$viewValue.filetype + ';base64,' + myform.projectCover.$viewValue.base64;
            }

            if (!myform.$valid) {
                deferred.reject();

                return deferred.promise;
            }

            var formData = {
                project_id: project_id,
                title: myform.projectTitle.$viewValue,
                details: myform.projectDetails.$viewValue,
                address1: myform.address1.$viewValue,
                address2: myform.address2.$viewValue,
                city: myform.city.$viewValue,
                state: myform.projectState.$viewValue,
                zip: myform.zip.$viewValue,
                organization_id: null,
                project_owner_id: null,
                cover: fileBase64
            };

            if (myform.projectOwner) {
                if (myform.projectOwner.type === 'organization') {
                    formData.organization_id = myform.projectOwner.id;
                } else {
                    formData.project_owner_id = myform.projectOwner.id;
                }
            }

            strukshurApiService.project.update(formData, function (d) {
                deferred.resolve(d);
            }, function (e) {
                console.log(e);
                deferred.reject(e);
            });

            return deferred.promise;
        },

        /**
         * Returns the list of all available permissions on Strukshur
         *
         * @return {array}
         */
        getAllAvailablePermissions: function () {
            return [
                {
                    label: 'Inbox',
                    id: 'project_inbox',
                    value: 'project_inbox',
                    children: [
                        { label: 'View inbox', id: 'project_inbox_view', value: 'project_inbox_view' },
                        { label: 'Create groups', id: 'project_inbox_group_add', value: 'project_inbox_group_add' },
                        { label: 'Edit groups', id: 'project_inbox_group_edit', value: 'project_inbox_group_edit' },
                        { label: 'Delete groups', id: 'project_inbox_group_delete', value: 'project_inbox_group_delete' },
                        { label: 'Delete messages', id: 'project_inbox_message_delete', value: 'project_inbox_message_delete' },
                        { label: 'Add files', id: 'project_inbox_file_add', value: 'project_inbox_file_add' },
                    ]
                },
                {
                    label: 'Tasks',
                    id: 'project_task',
                    value: 'project_task',
                    children: [
                        { label: 'View tasks', id: 'project_task_view', value: 'project_task_view' },
                        { label: 'Add milestones', id: 'project_milestone_add', value: 'project_milestone_add' },
                        { label: 'Edit milestones', id: 'project_milestone_edit', value: 'project_milestone_edit' },
                        { label: 'Delete milestones', id: 'project_milestone_delete', value: 'project_milestone_delete' },
                        { label: 'Add tasks', id: 'project_task_add', value: 'project_task_add' },
                        { label: 'Edit tasks', id: 'project_task_edit', value: 'project_task_edit' },
                        { label: 'Delete tasks', id: 'project_task_delete', value: 'project_task_delete' },
                        { label: 'Add task comments', id: 'project_task_comment_add', value: 'project_task_comment_add' },
                        { label: 'Delete task comments', id: 'project_task_comment_delete', value: 'project_task_comment_delete' },
                        { label: 'Add task files', id: 'project_task_file_add', value: 'project_task_file_add' },
                        { label: 'Delete task files', id: 'project_task_file_delete', value: 'project_task_file_delete' },
                    ]
                },{
                    label: 'Purchase Lists',
                    id: 'purchase_list',
                    value: 'purchase_list',
                    children: [
                        { label: 'View purchase lists', id: 'purchase_list_view', value: 'purchase_list_view' },
                        { label: 'Add purchase lists', id: 'purchase_list_add', value: 'purchase_list_add' },
                        { label: 'Edit purchase lists', id: 'purchase_list_edit', value: 'purchase_list_edit' },
                        { label: 'Delete purchase lists', id: 'purchase_list_delete', value: 'purchase_list_delete' },
                        { label: 'Add list items', id: 'purchase_list_item_add', value: 'purchase_list_item_add' },
                        { label: 'Delete list items', id: 'purchase_list_item_delete', value: 'purchase_list_item_delete' },
                    ]
                },{
                    label: 'Project Rooms',
                    id: 'project_room',
                    value: 'project_room',
                    children: [
                        { label: 'Add project rooms', id: 'project_room_add', value: 'project_room_add' },
                        { label: 'Edit project rooms', id: 'project_room_edit', value: 'project_room_edit' },
                        { label: 'Delete project rooms', id: 'project_room_delete', value: 'project_room_delete' },
                    ]
                },{
                    label: 'Inspiration Board',
                    id: 'project_pin',
                    value: 'project_pin',
                    children: [
                        { label: 'View inspiration board', id: 'project_pin_view', value: 'project_pin_view' },
                        { label: 'Add pins', id: 'project_pin_add', value: 'project_pin_add' },
                        { label: 'Sync pins to project', id: 'project_pin_sync', value: 'project_pin_sync' },
                        { label: 'Delete pins', id: 'project_pin_delete', value: 'project_pin_delete' },
                    ]
                },{
                    label: 'Progress Board',
                    id: 'project_progress',
                    value: 'project_progress',
                    children: [
                        { label: 'View progress board', id: 'project_progress_item_view', value: 'project_progress_item_view' },
                        { label: 'Add items', id: 'project_progress_item_add', value: 'project_progress_item_add' },
                        { label: 'Edit items', id: 'project_progress_item_edit', value: 'project_progress_item_edit' },
                        { label: 'Delete items', id: 'project_progress_item_delete', value: 'project_progress_item_delete' },
                    ]
                },{
                    label: 'Finishes',
                    id: 'project_finish',
                    value: 'project_finish',
                    children: [
                        { label: 'View finishes', id: 'project_finish_view', value: 'project_finish_view' },
                        { label: 'Add finishes', id: 'project_finish_add', value: 'project_finish_add' },
                        { label: 'Edit finishes', id: 'project_finish_edit', value: 'project_finish_edit' },
                        { label: 'Delete finishes', id: 'project_finish_delete', value: 'project_finish_delete' },
                        { label: 'Add finish notes', id: 'project_finish_note_add', value: 'project_finish_note_add' },
                        { label: 'Edit finish notes', id: 'project_finish_note_edit', value: 'project_finish_note_edit' },
                        { label: 'Delete finish notes', id: 'project_finish_note_delete', value: 'project_finish_note_delete' },
                        { label: 'Add finish files', id: 'project_finish_file_add', value: 'project_finish_file_add' },
                        { label: 'Open finish files', id: 'project_finish_file_open', value: 'project_finish_file_open' },
                        { label: 'Delete finish files', id: 'project_finish_file_delete', value: 'project_finish_file_delete' },
                    ]
                },{
                    label: 'Team',
                    id: 'project_team_member',
                    value: 'project_team_member',
                    children: [
                        { label: 'View team members', id: 'project_team_member_view', value: 'project_team_member_view' },
                        { label: 'Invite members', id: 'project_team_member_invite', value: 'project_team_member_invite' },
                        { label: 'Edit members', id: 'project_team_member_edit', value: 'project_team_member_edit' },
                        { label: 'Remove members', id: 'project_team_member_delete', value: 'project_team_member_delete' },
                        { label: 'Remove pending invites', id: 'project_team_member_delete_invite', value: 'project_team_member_delete_invite' },
                    ]
                },{
                    label: 'Files',
                    id: 'project_file',
                    value: 'project_file',
                    children: [
                        { label: 'View files section', id: 'project_file_view', value: 'project_file_view' },
                        { label: 'Add files', id: 'project_file_add', value: 'project_file_add' },
                        { label: 'Open files ', id: 'project_file_open', value: 'project_file_open' },
                        { label: 'Edit files ', id: 'project_file_edit', value: 'project_file_edit' },
                        { label: 'Delete files ', id: 'project_file_delete', value: 'project_file_delete' },
                        // {
                        //     label: 'General files',
                        //     id: 'project_file_default_all',
                        //     value: 'project_file_default_all',
                        //     children: [
                        //         { label: 'Add general files', id: 'project_file_add_default', value: 'project_file_add_default' },
                        //         { label: 'Open general files ', id: 'project_file_open_default', value: 'project_file_open_default' },
                        //         { label: 'Edit general files ', id: 'project_file_edit_default', value: 'project_file_edit_default' },
                        //         { label: 'Delete general files ', id: 'project_file_delete_default', value: 'project_file_delete_default' },
                        //     ]
                        // },
                        // {
                        //     label: 'Meeting files',
                        //     id: 'project_file_meeting_all',
                        //     value: 'project_file_meeting_all',
                        //     children: [
                        //         { label: 'Add meeting files', id: 'project_file_add_meeting', value: 'project_file_add_meeting' },
                        //         { label: 'Open meeting files ', id: 'project_file_open_meeting', value: 'project_file_open_meeting' },
                        //         { label: 'Edit meeting files ', id: 'project_file_edit_meeting', value: 'project_file_edit_meeting' },
                        //         { label: 'Delete meeting files ', id: 'project_file_delete_meeting', value: 'project_file_delete_meeting' },
                        //     ]
                        // },
                        // {
                        //     label: 'Billing files',
                        //     id: 'project_file_billing_all',
                        //     value: 'project_file_billing_all',
                        //     children: [
                        //         { label: 'Add billing files', id: 'project_file_add_billing', value: 'project_file_add_billing' },
                        //         { label: 'Open billing files ', id: 'project_file_open_billing', value: 'project_file_open_billing' },
                        //         { label: 'Edit billing files ', id: 'project_file_edit_billing', value: 'project_file_edit_billing' },
                        //         { label: 'Delete billing files ', id: 'project_file_delete_billing', value: 'project_file_delete_billing' },
                        //     ]
                        // },
                        // {
                        //     label: 'Drawing files',
                        //     id: 'project_file_drawing_all',
                        //     value: 'project_file_drawing_all',
                        //     children: [
                        //         { label: 'Add drawing files', id: 'project_file_add_drawing', value: 'project_file_add_drawing' },
                        //         { label: 'Open drawing files ', id: 'project_file_open_drawing', value: 'project_file_open_drawing' },
                        //         { label: 'Edit drawing files ', id: 'project_file_edit_drawing', value: 'project_file_edit_drawing' },
                        //         { label: 'Delete drawing files ', id: 'project_file_delete_drawing', value: 'project_file_delete_drawing' },
                        //     ]
                        // },
                        // {
                        //     label: 'Specification files',
                        //     id: 'project_file_specification_all',
                        //     value: 'project_file_specification_all',
                        //     children: [
                        //         { label: 'Add specification files', id: 'project_file_add_specification', value: 'project_file_add_specification' },
                        //         { label: 'Open specification files ', id: 'project_file_open_specification', value: 'project_file_open_specification' },
                        //         { label: 'Edit specification files ', id: 'project_file_edit_specification', value: 'project_file_edit_specification' },
                        //         { label: 'Delete specification files ', id: 'project_file_delete_specification', value: 'project_file_delete_specification' },
                        //     ]
                        // },
                        // {
                        //     label: 'Warranty files',
                        //     id: 'project_file_warranty_all',
                        //     value: 'project_file_warranty_all',
                        //     children: [
                        //         { label: 'Add warranty files', id: 'project_file_add_warranty', value: 'project_file_add_warranty' },
                        //         { label: 'Open warranty files ', id: 'project_file_open_warranty', value: 'project_file_open_warranty' },
                        //         { label: 'Edit warranty files ', id: 'project_file_edit_warranty', value: 'project_file_edit_warranty' },
                        //         { label: 'Delete warranty files ', id: 'project_file_delete_warranty', value: 'project_file_delete_warranty' },
                        //     ]
                        // },
                        // {
                        //     label: 'Estimate files',
                        //     id: 'project_file_estimate_file_all',
                        //     value: 'project_file_file_all',
                        //     children: [
                        //         { label: 'Add estimate files', id: 'project_file_add_estimate_file', value: 'project_file_add_estimate_file' },
                        //         { label: 'Open estimate files ', id: 'project_file_open_estimate_file', value: 'project_file_open_estimate_file' },
                        //         { label: 'Edit estimate files ', id: 'project_file_edit_estimate_file', value: 'project_file_edit_estimate_file' },
                        //         { label: 'Delete estimate files ', id: 'project_file_delete_estimate_file', value: 'project_file_delete_estimate_file' },
                        //     ]
                        // },
                        // {
                        //     label: 'Finish files',
                        //     id: 'project_file_finish_all',
                        //     value: 'project_file_finish_all',
                        //     children: [
                        //         { label: 'Add finish files', id: 'project_file_add_finish', value: 'project_file_add_finish' },
                        //         { label: 'Open finish files ', id: 'project_file_open_finish', value: 'project_file_open_finish' },
                        //         { label: 'Edit finish files ', id: 'project_file_edit_finish', value: 'project_file_edit_finish' },
                        //         { label: 'Delete finish files ', id: 'project_file_delete_finish', value: 'project_file_delete_finish' },
                        //     ]
                        // },
                        // {
                        //     label: 'Inspection files',
                        //     id: 'project_file_inspection_all',
                        //     value: 'project_file_inspection_all',
                        //     children: [
                        //         { label: 'Add inspection files', id: 'project_file_add_inspection', value: 'project_file_add_inspection' },
                        //         { label: 'Open inspection files ', id: 'project_file_open_inspection', value: 'project_file_open_inspection' },
                        //         { label: 'Edit inspection files ', id: 'project_file_edit_inspection', value: 'project_file_edit_inspection' },
                        //         { label: 'Delete inspection files ', id: 'project_file_delete_inspection', value: 'project_file_delete_inspection' },
                        //     ]
                        // },
                        // {
                        //     label: 'Bid files',
                        //     id: 'project_file_bid_all',
                        //     value: 'project_file_bid_all',
                        //     children: [
                        //         { label: 'Add bid files', id: 'project_file_add_bid', value: 'project_file_add_bid' },
                        //         { label: 'Open bid files ', id: 'project_file_open_bid', value: 'project_file_open_bid' },
                        //         { label: 'Edit bid files ', id: 'project_file_edit_bid', value: 'project_file_edit_bid' },
                        //         { label: 'Delete bid files ', id: 'project_file_delete_bid', value: 'project_file_delete_bid' },
                        //     ]
                        // },
                    ]
                },{
                    label: 'Estimates',
                    id: 'project_estimate',
                    value: 'project_estimate',
                    children: [
                        { label: 'Open estimates section', id: 'project_estimate_view', value: 'project_estimate_view' },
                        { label: 'View estimates from others', id: 'project_estimate_view_others', value: 'project_estimate_view_others' },
                        { label: 'Mark estimates as final', id: 'project_estimate_mark_as_final', value: 'project_estimate_mark_as_final' },
                        { label: 'Add estimates', id: 'project_estimate_add', value: 'project_estimate_add' },
                        { label: 'Edit estimates', id: 'project_estimate_edit', value: 'project_estimate_edit' },
                        { label: 'Delete estimates', id: 'project_estimate_delete', value: 'project_estimate_delete' },
                        { label: 'Add categories', id: 'project_estimate_category_add', value: 'project_estimate_category_add' },
                        { label: 'Edit categories', id: 'project_estimate_category_edit', value: 'project_estimate_category_edit' },
                        { label: 'Delete categories', id: 'project_estimate_category_delete', value: 'project_estimate_category_delete' },
                        { label: 'Add sub categories', id: 'project_estimate_subcategory_add', value: 'project_estimate_subcategory_add' },
                        { label: 'Edit sub categories', id: 'project_estimate_subcategory_edit', value: 'project_estimate_subcategory_edit' },
                        { label: 'Delete sub categories', id: 'project_estimate_subcategory_delete', value: 'project_estimate_subcategory_delete' },
                        { label: 'Add sub category files', id: 'project_estimate_subcategory_file_add', value: 'project_estimate_subcategory_file_add' },
                        { label: 'Open sub category files', id: 'project_estimate_subcategory_file_open', value: 'project_estimate_subcategory_file_open' },
                        { label: 'Edit sub category files', id: 'project_estimate_subcategory_file_edit', value: 'project_estimate_subcategory_file_edit' },
                        { label: 'Delete sub category files', id: 'project_estimate_subcategory_file_delete', value: 'project_estimate_subcategory_file_delete' },
                    ]
                },{
                    label: 'Blueprints',
                    id: 'project_room_blueprint',
                    value: 'project_room_blueprint',
                    children: [
                        { label: 'View blueprints', id: 'project_room_blueprint_view', value: 'project_room_blueprint_view' },
                        { label: 'Save blueprints', id: 'project_room_blueprint_edit', value: 'project_room_blueprint_edit' },
                        { label: 'Clear blueprints', id: 'project_room_blueprint_delete', value: 'project_room_blueprint_delete' },
                    ]
                }
                // {
                //     label: 'Bids',
                //     id: 'project_bid',
                //     value: 'project_bid',
                //     children: [
                //         { label: 'View bids', id: 'project_bid_view', value: 'project_bid_view' },
                //         { label: 'Open project for bids', id: 'project_bid_open_for_bids', value: 'project_bid_open_for_bids' },
                //         { label: 'Edit project for bids details', id: 'project_bid_project_for_bids_details', value: 'project_bid_project_for_bids_details' },
                //         { label: 'Close project for bids', id: 'project_bid_close_for_bids', value: 'project_bid_close_for_bids' },
                //         { label: 'Accept bids', id: 'project_bid_accept', value: 'project_bid_accept' },
                //         { label: 'Reject bids', id: 'project_bid_reject', value: 'project_bid_reject' },
                //     ]
                // }
            ];
        },

        /**
         * Returns all permission ids available
         *
         * @return {array}
         */
        getAllPermissionsIds: function () {
            var permissions = this.getAllAvailablePermissions();

            var ids = [];
            permissions.forEach(function (permission) {
                ids.push(permission.id);
                if (permission.children) {
                    permission.children.forEach(function (child1) {
                        ids.push(child1.id);
                        if (child1.children) {
                            child1.children.forEach(function (child2) {
                                ids.push(child2.id);
                            });
                        }
                    });
                }
            });

            return ids;
        },
    };
});
