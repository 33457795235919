import {
    clone as _clone,
    find as _find,
    forOwn as _forOwn,
    orderBy as _orderBy,
    pull as _pull,
    remove as _remove,
} from 'lodash';
import * as angular from 'angular';
import * as isMobile from 'ismobilejs';
import * as EXIF from 'exif-js';
import { findDeep } from 'deepdash-es/standalone';
import { filterDeep } from 'deepdash-es/standalone';
import { saveAs } from 'file-saver';

import strukshurConfig from '../../config.ts';

angular.module('strukshurApp.organizations', ['ui.router', 'ui.bootstrap'])

.config(function config($stateProvider) {
    $stateProvider
        .state('organization', {
            url: '/organization/:slug/:id?active_tab&folder_id',
            views: {
                main: {
                    controller: 'OrganizationDetailCtrl',
                    template: require('./organization.tpl.html'),
                    resolve: {
                        organization: ($state, $stateParams, strukshurApiService) => {
                            return strukshurApiService.organization
                                .get({ organization_id: $stateParams.id }).$promise
                                .then((res) => { return res.organization; })
                                .catch((res) => {
                                    if (res.status === 403) {
                                        $state.go('organization-access-denied');
                                    } else {
                                        $state.go('organization-not-found');
                                    }
                                })
                            ;
                        },
                        userResolve: (userRouterResolveService) => {
                            return userRouterResolveService.userMustBeLoggedin();
                        },
                        memberInfo: ($state, organization, strukshurApiService, strukshurUserService) => {
                            return strukshurApiService.organizationMember
                                .get({ organization_id: organization.id, member_id: organization.current_member_id }).$promise
                                .then((res) => {
                                    strukshurUserService.setOrganizationPermissions(organization, res.member.permissions);

                                    return res.member;
                                })
                                .catch(() => $state.go('projects-detail.access-denied'))
                            ;
                        }
                    }
                }
            },
            data: { pageTitle: 'Organization Detail', class: 'organization-page', fluidContainer: true }
        })

        .state('organization-access-denied', {
            url: '/organization/access-denied',
            views: {
                main: {
                    controller: angular.noop,
                    template: require('./organization.access-denied.tpl.html')
                }
            },
            data: { pageTitle: 'Organization / Access Denied', class: 'organization-page organization-access-denied' }
        })

        .state('organization-not-found', {
            url: '/organization/not-found',
            views: {
                main: {
                    controller: angular.noop,
                    template: require('./organization.not-found.tpl.html')
                }
            },
            data: { pageTitle: 'Organization / Not Found', class: 'organization-page organization-not-found' }
        })
    ;
})

.controller('OrganizationDetailCtrl', function OrganizationDetailCtrl ($aside, $rootScope, $scope, $state, $stateParams, $timeout, $uibModal, angularGridInstance, FileService, filterFilter, Flash, organization, smoothScroll, strukshurApiService, strukshurUploadService, strukshurUserService, toastr) {
    var vm = $scope, tmpAvatar;

    vm.page = 1;
    vm.rooms = [];
    vm.files = [];
    vm.folders = [];
    vm.members = [];
    vm.isPro = false;
    vm.projects = [];
    vm.selected = [];
    vm.uploader = null;
    vm.pageError = null;
    vm.currentUser = {};
    vm.sortBy = 'name-asc';
    vm.currentFolder = null;
    vm.filteredProjects = [];
    vm.displayMethod = 'grid';
    vm.loadingAvatar = false;
    vm.loadingMembers = false;
    vm.savingSettings = false;
    vm.loadingProjects = false;
    vm.settingsChanged = false;
    vm.organizationMenu = null;
    vm.organization = organization;
    vm.folderId = $stateParams.folder_id;
    vm.memberFilter = { searchTerm: '' };
    vm.projectFilter = { searchTerm: '' };
    vm.canCreateOrganizationProject = false;
    vm.organizationData = _clone(organization);
    vm.availableTabs = [];
    vm.baseFileUploadUrl = strukshurConfig.api_url + '/organization/file/upload';
    vm.active_tab = null;

    /**
     * Handles initialization logic for the controller
     */
    vm.init = () => {
        vm.setupTabs();

        // Makes sure to only allow an user to create an organization project if
        // the onwer still has available projects to create under their account
        vm.canCreateOrganizationProject = (organization.owner_projectsOwned < organization.owner_projectsAllowed);

        // Makes a copy of the avatar images in order for the  previous one to not
        // be changed unless the organization updates their avatar
        tmpAvatar = vm.organization.avatar;
        vm.organization.avatarTmp = tmpAvatar;
        vm.avatar = tmpAvatar;

        // Loads current user info
        strukshurUserService.getUser()
            .then((res) => {
                vm.isPro = strukshurUserService.isPro();
                vm.currentUser = strukshurUserService.getUserObj();
            })
        ;

        // Loads available user rooms
        strukshurApiService.userRooms.list({ house_id: strukshurUserService.getHouseId() }).$promise
            .then((res) => vm.rooms = res.rooms)
        ;

        // Setup uploader logic
        vm.setupUploader();

        // Watches for search field changes to filter the projects list
        vm.$watch('projectFilter.searchTerm', vm.filterProjects);
    };

    /**
     * Wether the current user has the given permission on the organization
     *
     * @param  {string}  permission  The permission key
     *
     * @return {boolean}
     */
    vm.hasPermission = (permission) => strukshurUserService.hasPermission(permission, vm.organization);

    /**
     * Wether the current user has any of the given permissions on the organization
     *
     * @param  {array}  permissions  The permission keys
     *
     * @return {boolean}
     */
    vm.hasAnyPermissions = (permissions) => strukshurUserService.hasAnyPermissions(permissions, vm.organization);

    /**
     * Checks wether the user can upload files of the given type
     *
     * @return {boolean}
     */
    vm.canUploadFile = () => vm.hasAnyPermissions(['organization_file_add', 'organization_file_all']);

    /**
     * Wether the current user can open a given file or not
     *
     * @param  {object}  file  The file
     *
     * @return {boolean}
     */
    vm.canOpenFile = (file) => (vm.hasPermission('organization_file_open') || vm.hasPermission('organization_file_all'));

    /**
     * Wether the current user can edit a given file or not
     *
     * @param  {object}  file  The file
     *
     * @return {boolean}
     */
    vm.canEditFile = (file) => !file.readonly && vm.hasAnyPermissions(['organization_file_edit', 'organization_file_all']);

    /**
     * Wether the current user can delete a given file or not
     *
     * @param  {object}  file  The file
     *
     * @return {boolean}
     */
    vm.canDeleteFile = (folder) => !folder.readonly && vm.hasAnyPermissions(['organization_file_delete', 'organization_file_all']);

    /**
     * Wether the current user can open a given file or not
     *
     * @param  {object}  folder  The folder
     *
     * @return {boolean}
     */
    vm.canOpenFolder = (folder) => (vm.hasPermission('organization_file_open') || vm.hasPermission('organization_file_all'));

    /**
     * Wether the current user can edit a given folder or not
     *
     * @param  {object}  folder  The folder
     *
     * @return {boolean}
     */
    vm.canEditFolder = (folder) => !folder.readonly && vm.hasAnyPermissions(['organization_file_edit', 'organization_file_all']);

     /**
      * Wether the current user can delete a given folder or not
      *
      * @param  {object}  folder  The folder
      *
      * @return {boolean}
      */
    vm.canDeleteFolder = (folder) => !folder.readonly && vm.hasAnyPermissions(['organization_file_delete', 'organization_file_all']);

    /**
     * Setup logic for the page's tabset component
     */
    vm.setupTabs = () => {

        // Sets available tabs based on the current member's permissions
        if (vm.hasAnyPermissions(['organization_file_all', 'organization_file_view'])) {
            vm.availableTabs.push('files');
        }
        if (vm.hasAnyPermissions(['organization_project_all', 'organization_project_view'])) {
            vm.availableTabs.push('projects');
        }
        if (vm.hasAnyPermissions(['organization_member_all', 'organization_member_view'])) {
            vm.availableTabs.push('members');
        }
        if (vm.hasAnyPermissions(['organization_edit'])) {
            vm.availableTabs.push('settings');
        }

        // Displays error message if the user does not have a single section available to them
        if (vm.availableTabs.length === 0) {
            vm.pageError = {
                title: 'Sorry, you don\'t have permission to access this organization.',
                message: 'You\'ll need to contact the organization owner and ask them for permission in order to view this organization\'s content.',
            };
            vm.active_tab = 'error';
            return;
        }

        // Check if the user has access to view the the received tab
        if ($stateParams.active_tab && !vm.availableTabs.includes($stateParams.active_tab)) {
            vm.pageError = {
                title: 'Sorry, you don\'t have permission to access this section.',
                message: 'You\'ll need to contact the organization owner and ask them for permission in order to view this organization\'s content.',
            };
            vm.active_tab = 'error';
            return;
        }

        // Set active tab based on received data
        vm.active_tab = (vm.availableTabs.includes($stateParams.active_tab)) ? $stateParams.active_tab : vm.availableTabs[0];
    };

    /**
     * Navigates to the tab with the given index
     *
     * @param  {string}  index  The tab index
     */
    vm.goToTab = (index) => {

        // Does not navigate to the given tab since it's already the current one
        if (vm.active_tab === index) { return; }

        vm.active_tab = index;
        vm.organizationMenu.hide();
    };

    /**
     * Sets grid as the active display method
     */
    vm.setGridDisplay = () => {
        if (vm.displayMethod === 'grid') { return; }

        vm.displayMethod = 'grid';
        angular.element('body').removeClass('list-view-enabled');
        angular.element('.files-container').addClass('grid-view');
        angular.element('.files-container').removeClass('list-view');
    };

    /**
     * Sets list as the active display method
     */
     vm.setListDisplay = () => {
        if (vm.displayMethod === 'list') { return; }

        vm.displayMethod = 'list';
        angular.element('body').addClass('list-view-enabled');
        angular.element('.files-container').addClass('list-view');
        angular.element('.files-container').removeClass('grid-view');
    };

    /**
     * Hides the file search field
     */
    vm.hideSearch = () => {
        angular.element('.input-search-group').removeClass('focused');
    };

    /**
     * Shows the file search field
     */
     vm.showSearch = () => {
        angular.element('.input-search-group').addClass('focused');
        $timeout(() => (<HTMLInputElement>document.querySelector('.input-search')).focus(), 300);
    };

    /**
     * Opens the account settings menu aside
     */
    vm.openOrganizationMenu = () => {
        vm.organizationMenu = $aside({
            title: 'Organization Menu',
            template: require('./organization.menu.tpl.html'),
            controller: angular.noop,
            placement: 'left',
            animation: 'am-fade-and-slide-left',
            scope: vm,
            show: true
        });
    };

    /**
     * Setups file uploader logic
     */
     vm.setupUploader = () => {
        strukshurUploadService.setup({
            subscribeEvents: [{
                scope: vm,
                name: 'upload-items-added',
                callback: vm.processAddedFiles,
            }, {
                scope: vm,
                name: 'upload-item-complete',
                callback: vm.processUploadedFile,
            }],
        });
        vm.uploader = strukshurUploadService.getUploader();
    };

    /**
     * Sets cover image for the given file
     *
     * @param  {object}  file  The file
     */
     vm.setFileCover = (file) => {
        const ext = FileService.getFileExtension(file.file);

        if (FileService.supportsImagePreviewExtension(ext)) {
            file.imgSrc = file.file;
            file.elStyle = { 'background-image': `url('${file.imgSrc}');` };
            file.isSupportedImagePreview = true;
            file.fileClass = { 'no-filter': true };
        } else {
            file.isSupportedImagePreview = false;
            file.imgSrc = 'assets/icons/file-filled.svg';
        }
    };

    /**
     * Sets the sort order for the current files
     *
     * @param  {string}  sortBy  The field to sort by
     */
    vm.setSortMethod = (sortBy) => {
        if (vm.sortBy === sortBy) { return; }

        vm.sortBy = sortBy;
        vm.sortItems(sortBy);
    };

    /**
     * Sorts files based on the given field
     *
     * @param  {string}  sortBy  The field to sort by
     */
    vm.sortItems = (sortBy) => {
        const sort = sortBy.split('-')[0],
            order = sortBy.split('-')[1];

        // Sort by name
        if (sort === 'name') {
            vm.folders = _orderBy(vm.folders, ['favorite', 'name'], ['desc', order]);
            vm.files = _orderBy(vm.files, ['favorite', 'filename'], ['desc', order]);
        } else if (sort === 'date') {
            vm.files = _orderBy(vm.files, ['favorite', 'createdAt'], ['desc', order]);
        } else if (sort === 'size') {
            vm.files = _orderBy(vm.files, ['favorite', 'size'], ['desc', order]);
        }
    };

    /**
     * Toggle the selection state of the given item
     *
     * @param  {object}  item  The item
     */
    vm.toggleSelection = (item) => {
        item.selected = !item.selected;

        if (item.selected) {
            vm.selected.push(item);
        } else {
            _pull(vm.selected, item);
        }
    };

    /**
     * Triggers file upload input click event
     */
     vm.selectFiles = () => {
        angular.element('#file-upload-input').click();
    };

    /**
     * Processes a file list selection coming from the user
     *
     * @param  {array}  files  The list of files to be processed
     */
    vm.processAddedFiles = ($event, files) => {

        // Check if the user can upload files to the dropped section
        if (!vm.canUploadFile()) { return; }

        // First we retrieve a referene to the type which the files were added to
        let parent = vm.currentFolder;

        // Then we initialize the queue properly for the correct file
        vm.errorMessage = '';

        // Process added files
        let hasFileToUpload = false;
        files.forEach((file) => {

            // Don't add to queue files that are larger than the upload limit
            if ((file.file.size / 1024 / 1024) > strukshurConfig.max_upload_size) {
                vm.errorMessage = 'The file "\' + file.file.name + \'" is larger than the limit of '+strukshurConfig.max_upload_size+'MB.';
                return;
            }

            file.parent = parent;
            file.url = `${vm.baseFileUploadUrl}?organization_id=${vm.organization.id}&filename=${file.name}`;
            if (file.parent) {
                file.url = file.url + `&parent_id=${parent.id}`;
            }

            hasFileToUpload = true;
        });

        if (hasFileToUpload) {
            $rootScope.showUploadManagerModal();
        }
    };

    /**
     * Event fired when a file upload is completed successfully
     *
     * @param  {Event}     event  The event object
     * @param  {FileItem}  file   The native file object
     * @param  {object}    res    The response object
     */
    vm.processUploadedFile = (event, file, res) => {
        const uploaded = res.file;

        // Only adds file to the list if the uploaded project is the same of the current organization
        if (uploaded && uploaded.organization_id == vm.organization.id) {

            // Adds file if it was uploaded to the folder being currently displayed
            if ((vm.currentFolder && vm.currentFolder.id == uploaded.parent_id) ||
                (!vm.currentFolder && !uploaded.parent_id)) {

                uploaded.isFile = true;
                uploaded.selected = false;
                uploaded.file = FileService.encodeFileUrlForS3(uploaded.file);
                uploaded.extension = FileService.getFileExtension(uploaded.file);
                uploaded.fileType = FileService.getFileType(uploaded.file);
                vm.setFileCover(uploaded);
                vm.files.push(uploaded);
                vm.sortItems(vm.sortBy);
            }
        }
    };

    /**
     * Retrieve organization files from the backend
     */
     vm.loadFiles = () => {

        // Get timer to properly display loading content
        const timer = window.performance.now();

        // Creates search request based on the received folder id
        let promise;
        if (vm.folderId) {
            promise = strukshurApiService.organizationFolder.get({ folder_id: vm.folderId }).$promise;
        } else {
            promise = strukshurApiService.organizationFolders.listRootAssets({ organization_id: vm.organization.id }).$promise;
        }

        vm.loadingFiles = true;
        promise
            .then((res) => {

                // Artificial loading delay if the call takes less than 2s and
                // no previous project has been loaded
                const delay = (vm.files.length === 0 && vm.folders.length === 0) ? timer - window.performance.now() + 2000 : 0;
                $timeout(() => {
                    if (vm.folderId) {
                        vm.currentFolder = res.folder;
                        res.files = res.folder.files;
                        res.folders = res.folder.folders;
                    }

                    // Set files cover based on their extension
                    res.files.forEach(file => {
                        file.isFile = true;
                        file.selected = false;
                        file.file = FileService.encodeFileUrlForS3(file.file);
                        file.extension = FileService.getFileExtension(file.file);
                        file.fileType = FileService.getFileType(file.file);
                        vm.setFileCover(file);
                    });

                    // Marks all folder objects accordingly
                    res.folders.forEach(folder => {
                        folder.isFolder = true;
                        folder.selected = false;
                    });

                    vm.files = res.files;
                    vm.folders = res.folders;
                    vm.loadingFiles = false;

                    vm.sortItems(vm.sortBy);
                }, delay);
            })
            .catch((res) => {
                console.log(res);
                if (res.status === 403) {
                    Flash.create('danger', 'You don\'t have the necessary permission view the files.', 10000, { container: 'organizations-section-flash' }, true);
                } else {
                    Flash.create('danger', 'There was an error while loading the files.', 10000, { container: 'organizations-section-flash' }, true);
                }

                vm.loadingFiles = false;
            })
        ;
    };

    /**
     * Retrieve organization projects from the backend
     */
    vm.loadProjects = () => {

        // Get timer to properly display loading content
        const timer = window.performance.now();

        vm.loadingProjects = true;
        strukshurApiService.organizationProjects.list({ organization_id: organization.id }).$promise
            .then((res) => {

                // Artificial loading delay if the call takes less than 2s and
                // no previous project has been loaded
                const delay = (vm.projects.length === 0) ? timer - window.performance.now() + 2000 : 0;
                $timeout(() => {
                    if (vm.projects.length === 0) {
                        vm.projects = res.projects;
                    } else {
                        res.projects.forEach((project) => {
                            var existingProject = _find(vm.projects, { id: project.id });
                            if (!existingProject) {
                                vm.projects.push(project);
                            }
                        });
                    }

                    vm.loadingProjects = false;
                    vm.filterProjects();
                }, delay);
            })
            .catch((res) => {
                if (res.status === 403) {
                    Flash.create('danger', 'You don\'t have the necessary permission view the projects.', 10000, { container: 'projects-section-flash' }, true);
                } else {
                    Flash.create('danger', 'There was an error while loading the projects.', 10000, { container: 'projects-section-flash' }, true);
                }

                vm.loadingProjects = false;
            })
        ;
    };

    /**
     * Filters the project list according to the values from the UI
     */
    vm.filterProjects = () => {

        // Filter all the project lists
        const filter = { title: vm.projectFilter.searchTerm };
        vm.filteredProjects = filterFilter(vm.projects, filter);

        // Refresh projects gallery
        if (angularGridInstance.gallery) {
            $timeout(angularGridInstance.gallery.refresh, 50);
        }
    };

    /**
     * Retrieve organization members from the backend
     */
    vm.loadMembers = function () {

        // Get timer to properly display loading content
        var timer = window.performance.now();

        vm.loadingMembers = true;
        strukshurApiService.organizationMembers.list({ organization_id: organization.id }).$promise
            .then(function (res) {

                // Artificial loading delay if the call takes less than 2s and
                // no previous member has been loaded
                var delay = (vm.members.length === 0) ? timer - window.performance.now() + 2000 : 0;

                $timeout(function () {
                    if (vm.members.length === 0) {
                        vm.members = res.members;
                    } else {
                        res.members.forEach(function (member) {
                            var existingMember = _find(vm.members, { id: member.id });
                            if (!existingMember) {
                                vm.members.push(member);
                            }
                        });
                    }

                    vm.loadingMembers = false;
                }, delay);
            })
            .catch(function (res) {
                if (res.status === 403) {
                    Flash.create('danger', 'You don\'t have the necessary permission to view the members.', 10000, { container: 'members-section-flash' }, true);
                } else {
                    Flash.create('danger', 'There was an error while loading the members.', 10000, { container: 'members-section-flash' }, true);
                }

                vm.loadingMembers = false;
            })
        ;
    };

    /**
     * Opens the rename modal for the selected item
     */
    vm.renameSelected = () => {
        const item = vm.selected[0];
        if (item.isFile) {
            return vm.renameFile(item);
        }

        return vm.renameFolder(item);
    };

    /**
     * Opens the delete modal for the selected item
     */
    vm.deleteSelected = () => {
        const item = vm.selected[0];
        if (item.isFile) {
            return vm.removeFile(item);
        }

        return vm.removeFolder(item);
    };

    /**
     * Downloads the given file
     *
     * @param  {object}  file  The file
     */
    vm.downloadFile = (file) => {
        saveAs(file.file, file.filename);
    };

    /**
     * Opens a modal for adding a new organization folder
     */
    vm.newFolder = () => {
        const modalInstance = $uibModal.open({
            scope: vm,
            keyboard: true,
            controller: 'OrganizationDetailNewFolderCtrl',
            template: require('../../common/templates/projects.newFolderModal.tpl.html'),
            resolve: {
                parentFolder: vm.currentFolder,
                organization: vm.organization,
            }
        });

        modalInstance.result.then(

            // Resolved callback
            (data) => { vm.folders.push(data.folder) },

            // Rejected callback
            angular.noop
        );
    };

    /**
     * Redirects the user to the given organization folder
     *
     * @param  {object}  folder  The folder
     */
    vm.openFolder = (folder) => {
        if (!vm.canOpenFolder(folder)) { return; }

        $state.go('organization', { active_tab: 'files', folder_id: folder.id });
    };

    /**
     * Opens the modal for reming a organization folder
     *
     * @param  {object}  folder  The folder
     */
    vm.renameFolder = (folder) => {
        if (!vm.canEditFile(folder)) { return; }

        const modalInstance = $uibModal.open({
            scope: vm,
            keyboard: true,
            controller: 'OrganizationDetailEditFolderCtrl',
            template: require('../../common/templates/projects.editFolderModal.tpl.html'),
            resolve: {
                folder: folder
            }
        });

        modalInstance.result.then(

            // Resolved callback
            (data) => folder.name = data.folder.name,

            // Rejected callback
            angular.noop
        );
    };

    /**
     * Opens modal to move a folder to another destination
     *
     * @param  {object}  folder  The folder to move
     */
    vm.moveFolder = (folder) => {
        if (!vm.canEditFile(folder)) { return; }

        const modalInstance = $uibModal.open({
            scope: vm.$new(),
            keyboard: true,
            controller: 'OrganizationDetailMoveFolderCtrl',
            template: require('../../common/templates/projects.moveFolderModal.tpl.html'),
            resolve: {
                folder: folder,
                organization: vm.organization,
            }
        });

        modalInstance.result.then(

            // Resolved callback
            () => _remove(vm.folders, folder),

            // Rejected callback
            angular.noop
        );
    };

    /**
     * Favorites the received folder
     *
     * @param  {object}  folder  The folder
     */
    vm.favoriteFolder = (folder) => {
        if (!vm.canEditFile(folder)) { return; }

        strukshurApiService.organizationFolder.favorite({ folder_id: folder.id }).$promise
            .then(() => {
                folder.favorite = true;
                vm.sortItems(vm.sortBy);
            })
            .catch((res: any) => {
                if (res.status === 403) {
                    toastr.error('You don\'t have the necessary permission to update the folder.', 'Error');
                } else {
                    toastr.error('There was an error trying to favorite the folder.', 'Error');
                }
            })
        ;
    };

    /**
     * Unfavorites the received folder
     *
     * @param  {object}  folder  The folder
     */
    vm.unfavoriteFolder = (folder) => {
        if (!vm.canEditFile(folder)) { return; }

        strukshurApiService.organizationFolder.unfavorite({ folder_id: folder.id }).$promise
            .then(() => {
                folder.favorite = false;
                vm.sortItems(vm.sortBy);
            })
            .catch((res: any) => {
                if (res.status === 403) {
                    toastr.error('You don\'t have the necessary permission to update the folder.', 'Error');
                } else {
                    toastr.error('There was an error trying to unfavorite the folder.', 'Error');
                }
            })
        ;
    };

    /**
     * Removes the received folder from the server
     *
     * @param  {object}  folder  The folder
     */
    vm.removeFolder = (folder) => {
        if (!vm.canDeleteFolder(folder)) { return; }

        const $childScope = vm.$new();
        $childScope.folder = folder;
        $childScope.title = 'Delete folder';
        $childScope.message = 'Are you sure you want to delete the folder?';

        const modalInstance = $uibModal.open({
            keyboard: true,
            scope: $childScope,
            controller: 'OrganizationDetailDeleteFolderCtrl',
            template: require('../../common/templates/base.confirm-modal.tpl.html')
        });

        modalInstance.result.then(

            // Resolved callback
            () => _pull(vm.folders, folder),

            // Rejected callback
            angular.noop
        );
    };

    /**
     * Opens the modal for editing a organization file
     *
     * @param  {object}  file  The file
     */
    vm.editFile = (file) => {
        if (!vm.canEditFile(file)) { return; }

        // The user can't edit a file which is part of a final estimate
        if (file.isEstimateMarkedAsFinal) { return; }

        const modalInstance = $uibModal.open({
            scope: vm,
            keyboard: true,
            controller: 'OrganizationDetailEditFileCtrl',
            template: require('../../common/templates/projects.projectFileEditModal.tpl.html'),
            resolve: {
                file: file
            }
        });

        modalInstance.result.then(

            // Resolved callback
            (data) => file.filename = data.file.filename,

            // Rejected callback
            angular.noop
        );
    };

    /**
     * Opens modal to move a file to another destination
     *
     * @param  {object}  file  The file to move
     */
    vm.moveFile = (file) => {
        if (!vm.canEditFile(file)) { return; }

        const modalInstance = $uibModal.open({
            scope: vm.$new(),
            keyboard: true,
            controller: 'OrganizationDetailMoveFileCtrl',
            template: require('../../common/templates/projects.moveFileModal.tpl.html'),
            resolve: {
                file: file,
                organization: vm.organization,
            }
        });

        modalInstance.result.then(

            // Resolved callback
            () => _remove(vm.files, file),

            // Rejected callback
            angular.noop
        );
    };

    /**
     * Opens the modal for sharing an organization file
     *
     * @param  {Object}  file  The file
     */
    vm.shareFile = (file: any) => {
        const modalInstance = $uibModal.open({
            scope: vm,
            keyboard: true,
            controller: 'OrganizationDetailShareFileCtrl',
            template: require('../../common/templates/projects.shareFileModal.tpl.html'),
            resolve: {
                file: file,
            }
        });

        modalInstance.result.then(

            // Resolved callback
            (data: any) => {
                file.sharedFile = data.sharedFile;
                toastr.success('The file was shared successfully.', 'File shared');
            },

            // Rejected callback
            angular.noop
        );
    };

    /**
     * Favorites the received file
     *
     * @param  {object}  file  The file
     */
     vm.favoriteFile = (file) => {
        if (!vm.canEditFile(file)) { return; }

        strukshurApiService.organizationFile.favorite({ file_id: file.id }).$promise
            .then(() => {
                file.favorite = true;
                vm.sortItems(vm.sortBy);
            })
            .catch((res: any) => {
                if (res.status === 403) {
                    toastr.error('You don\'t have the necessary permission to update the file.', 'Error');
                } else {
                    toastr.error('There was an error trying to favorite the file.', 'Error');
                }
            })
        ;
    };

    /**
     * Unfavorites the received file
     *
     * @param  {object}  file  The file
     */
    vm.unfavoriteFile = (file) => {
        if (!vm.canEditFile(file)) { return; }

        strukshurApiService.organizationFile.unfavorite({ file_id: file.id }).$promise
            .then(() => {
                file.favorite = false;
                vm.sortItems(vm.sortBy);
            })
            .catch((res: any) => {
                if (res.status === 403) {
                    toastr.error('You don\'t have the necessary permission to update the file.', 'Error');
                } else {
                    toastr.error('There was an error trying to unfavorite the file.', 'Error');
                }
            })
        ;
    };

    /**
     * Removes the received file from the server
     *
     * @param  {Object}  file  The file
     */
    vm.removeFile = (file) => {
        if (!vm.canDeleteFile(file)) { return; }

        const $childScope = vm.$new();
        $childScope.file = file;
        $childScope.organization = vm.organization;
        $childScope.errorMessage = '';
        $childScope.title = 'Delete file';
        $childScope.message = 'Are you sure you want to delete the file?';

        const modalInstance = $uibModal.open({
            keyboard: true,
            scope: $childScope,
            controller: 'OrganizationDetailDeleteFileCtrl',
            template: require('../../common/templates/projects.deleteFileConfirmationModal.tpl.html')
        });

        modalInstance.result.then(

            // Resolved callback
            (data) => _pull(vm.files, file),

            // Rejected callback
            angular.noop
        );
    };

    /**
     * Opens modal to create a new project for the organization
     */
    vm.newProjectModal = function () {
        var modalInstance;

        // Makes sure to only allow an user to create an organization project if
        // the onwer still has available projects to create under their account
        if (!vm.canCreateOrganizationProject) {
            Flash.create('danger', 'The organization can\'t create more projects unless the owner\'s plan is upgraded.', 10000, { container: 'projects-section-flash' }, true);
        } else {
            modalInstance = $uibModal.open({
                scope: vm,
                keyboard: true,
                controller: 'ProjectModalInstanceCtrl',
                template: require('../../common/templates/project-create-modal.tpl.html'),
                resolve: {
                    allowOwnerChange: false,
                    organization: function () {
                        return organization;
                    },
                    project: function () {
                        return {};
                    },
                    title: function () {
                        return 'New project';
                    },
                }
            });

            modalInstance.result.then(
                function (newProject) {
                    if (newProject && newProject.id) {
                        vm.projects.unshift(newProject);
                        strukshurUserService.addProject(newProject);
                        vm.filterProjects();

                        $timeout(function () {

                            // Scrolls to new project element
                            smoothScroll(document.querySelector('div[data-project-id="' + newProject.id + '"]'));

                            // Adds highlight class to help user spot the new project
                            angular.element('div[data-project-id="' + newProject.id + '"]').addClass('highlight');

                            // Removes the highlight class after a delay
                            $timeout(function () {
                                angular.element('div[data-project-id="' + newProject.id + '"]').removeClass('highlight');
                            }, 2000);
                        }, 50);
                    }
                },

                angular.noop
            );
        }
    };

    /**
     * Opens modal to create a new project for the organization
     */
    vm.editProjectModal = function (project) {
        var modalInstance = $uibModal.open({
            scope: vm,
            keyboard: true,
            controller: 'ProjectModalInstanceCtrl',
            template: require('../../common/templates/project-edit-modal.tpl.html'),
            resolve: {
                allowOwnerChange: true,
                organization: function () {
                    return organization;
                },
                project: function () {
                    return project;
                },
                title: function () {
                    return 'Edit project';
                },
            }
        });

        modalInstance.result.then(
            function (selectedItem) {
                if (selectedItem && selectedItem.id) {
                    strukshurUserService.updateProject(selectedItem); //update top list

                    // Refreshes owned project count if the project owner was changed
                    if (project.owner_id !== selectedItem.owner_id) {
                        strukshurUserService.refreshOwnedProjectsCount();
                    }

                    // Removes the project from the list if its owner was changed
                    if (organization.id !== selectedItem.owner_id) {
                        _remove(vm.projects, project);
                        vm.filterProjects();
                    } else {

                        // Updates local project with the info coming from the backend
                        _forOwn(selectedItem, function (v, k) {
                            project[k] = v;
                        });
                    }
                }
            },

            angular.noop
        );
    };

    /**
     * Loads more organization projects from the backend
     */
    vm.loadMoreProjects = function () {

        // Only triggers a search if there's still more results to get or if
        // there's currently no other search request happening
        if ((vm.total !== 0 && vm.projects.length >= vm.total) || vm.loadingProjects) { return; }

        vm.page++;
        vm.loading = true;

        // Get timer to properly display loading content
        var timer = window.performance.now();

        // Loading additional projects
        strukshurApiService.organizationProjects.list({ organization_id: organization.id, page: vm.page, limit: 40 }).$promise
            .then(function (res) {

                // artificial loading delay if the call takes less than 2s
                var delay = timer - window.performance.now() + 2500;
                $timeout(function () {
                    res.projects.forEach(function (project) {
                        vm.projects.push(project);
                    });

                    vm.loading = false;
                    vm.total = res.total;
                    vm.filterProjects();
                }, delay);
            })
            .catch(function () {
                vm.page--;
                vm.loading = false;
            })
        ;
    };

    /**
     * Confirms organization project delete
     *
     * @param  {object}  project  The project to delete
     */
    vm.deleteProjectConfirmation = function (project) {
        vm.confirmMessage = false;
        var modalInstance = $uibModal.open({
            scope: vm,
            keyboard: true,
            controller: 'ConfirmProjectDeleteCtrl',
            template: require('../../common/templates/confirm-modal.tpl.html'),
            resolve: {
                project: function () {
                    return project;
                },
                message: function () {
                    return 'Are you sure you want to delete the project? This action cannot be undone.';
                }
            }
        });

        modalInstance.result.then(function () {

            // Confirmed
            project.deleted = true;
            _remove(vm.projects, project);
            strukshurUserService.removeProject(project);
            vm.filterProjects();
        }, function () {
            //cancel confirmation
        });
    };

    /**
     * Opens modal to invite a member to the user account
     */
    vm.openInviteMemberModal = function () {
        var modalInstance = $uibModal.open({
            scope: vm,
            keyboard: true,
            controller: 'OrganizationInviteMemberCtrl',
            template: require('../../common/templates/organizations.inviteMemberModal.tpl.html'),
            resolve: {
                organization: organization
            }
        });

        modalInstance.result.then(

            // Resolved callback
            function (data) {
                if (data.member) {
                    vm.members.push(data.member);
                }
            },

            // Rejected callback
            angular.noop
        );
    };

    /**
     * Resends an invitation for the given member
     *
     * @param  {object}  member  The member
     */
    vm.resendInvite = function (member) {
        if (member.resendingInvite) { return; }

        member.resendingInvite = true;
        strukshurApiService.organizationMember.resendInvite({ organization_id: organization.id, member_id: member.id }).$promise
            .then(function () {
                toastr.success('The invitation e-mail was sent successfully.', 'Success!');
            })
            .catch(function (err) {
                toastr.error('There was an error trying to send the invitation e-mail. Please try again later.', 'Error');
            })
            .finally(function () {
                member.resendingInvite = false;
            })
        ;
    };

    /**
     * Opens the modal to edit an existing member
     */
    vm.editMember = function (member) {
        var modalInstance = $uibModal.open({
            scope: vm,
            keyboard: true,
            controller: 'OrganizationEditMemberCtrl',
            template: require('../../common/templates/organizations.editMemberModal.tpl.html'),
            resolve: {
                organization: organization,
                member: member
            }
        });

        modalInstance.result.then(

            // Resolved callback
            function (data) {
                if (data.member) {
                    member.role = data.member.role;
                    member.location = data.member.location;
                    member.phoneNumber = data.member.phoneNumber;
                    member.permissions = (Array.isArray(data.member.permissions)) ? data.member.permissions.join(',') : data.member.permissions;
                }
            },

            // Rejected callback
            angular.noop
        );
    };

    /**
     * Remove member from organization
     *
     * @param  {object}  member
     */
    vm.removeMember = function (member) {
        var $childScope = vm.$new();
        $childScope.member = member;
        $childScope.organization = organization;
        $childScope.title = 'Remove member';
        $childScope.message = 'Are you sure you want to remove the member from the organization?';

        var modalInstance = $uibModal.open({
            keyboard: true,
            scope: $childScope,
            controller: 'OrganizationRemoveMemberCtrl',
            template: require('../../common/templates/base.confirm-modal.tpl.html')
        });

        modalInstance.result.then(

            // Resolved callback
            function (data) {
                _pull(vm.members, member);
            },

            // Rejected callback
            angular.noop
        );
    };

    /**
     * Prepends organization website url with http if necessary
     */
    vm.completeUrl = function () {
        if (typeof vm.organizationData.website === 'string' && vm.organizationData.website !== '') {
            if (vm.organizationData.website.indexOf('http') === -1) {
                vm.organizationData.website = 'http://'+vm.organizationData.website;
            }
        }
    };

    /**
     * Handles image selection to be the new profile image
     */
    vm.selectAvatar = function (eventObject, fileReader, file) {
        vm.loadingAvatar = false;

        if (!file || file.length === 0) {
            return;
        }

        if (vm.organization.avatarTmp && !vm.organization.avatarTmp.base64) {
            vm.organization.avatarTmp = null;
            Flash.create('danger', 'The selected image is invalid.', 10000, { container: 'settings-section-flash' }, true);
            return;
        }

        if (file[0].size > 1 * 1000 * 1000) {
            vm.organization.avatarTmp = null;
            Flash.create('danger', 'Image size must be less than 1MB.', 10000, { container: 'settings-section-flash' }, true);
            return;
        }

        if (file[0].type.substr(0, 5) !== 'image') {
            vm.organization.avatarTmp = null;
            Flash.create('danger', 'The selected file is not an image.', 10000, { container: 'settings-section-flash' }, true);
            return;
        } else {

            // Resize
            var dataUrlImage;
            var canvas, MAX_WIDTH, MAX_HEIGHT, width, height, ctx, img;
            MAX_WIDTH = 2048;
            MAX_HEIGHT = 2048;

            if (vm.organization.avatarTmp && vm.organization.avatarTmp.base64) {

                // Compress image
                vm.loadingAvatar = true;
                img = document.createElement('img');
                img.setAttribute('crossOrigin', 'anonymous');

                img.onload = function (ev) {
                    var resize;
                    width = img.width;
                    height = img.height;

                    if (width > height) {
                        if (width > MAX_WIDTH) {
                            height *= MAX_WIDTH / width;
                            width = MAX_WIDTH;
                        }
                    } else {
                        if (height > MAX_HEIGHT) {
                            width *= MAX_HEIGHT / height;
                            height = MAX_HEIGHT;
                        }
                    }

                    // Resize only if needed
                    if (width !== img.width || height !== img.height) {
                        canvas = document.createElement('canvas');
                        canvas.width = width;
                        canvas.height = height;
                        ctx = canvas.getContext('2d');
                        ctx.drawImage(img, 0, 0, width, height);

                        canvas.setAttribute('crossOrigin', 'anonymous');

                        dataUrlImage = canvas.toDataURL(vm.organization.avatarTmp.filetype);
                        vm.organization.avatarTmp.base64 = dataUrlImage.substr(dataUrlImage.indexOf(',') + 1);
                        vm.avatar = 'data:'+vm.organization.avatarTmp.filetype+';base64,'+vm.organization.avatarTmp.base64;
                        dataUrlImage = null;
                        canvas = null;
                        ctx = null;
                    }

                    //if iOS rotate if needed
                    if (isMobile.apple.device || isMobile.apple.ipod || isMobile.apple.phone || isMobile.apple.tablet) {
                        resize = document.createElement('img');
                        resize.setAttribute('crossOrigin', 'anonymous');

                        resize.onload = function (event) {
                            width = resize.width;
                            height = resize.height;
                            EXIF.getData(img, function () {
                                var orientation = EXIF.getTag(this, 'Orientation');
                                canvas = document.createElement('canvas');
                                ctx = canvas.getContext('2d');
                                if (orientation && orientation > 4) {
                                    canvas.width = height;
                                    canvas.height = width;
                                } else {
                                    canvas.width = width;
                                    canvas.height = height;
                                }
                                switch (orientation) {
                                    case 2:
                                        // horizontal flip
                                        ctx.translate(width, 0);
                                        ctx.scale(-1, 1);
                                        break;
                                    case 3:
                                        // 180° rotate left
                                        ctx.translate(width, height);
                                        ctx.rotate(Math.PI);
                                        break;
                                    case 4:
                                        // vertical flip
                                        ctx.translate(0, height);
                                        ctx.scale(1, -1);
                                        break;
                                    case 5:
                                        // vertical flip + 90 rotate right
                                        ctx.rotate(0.5 * Math.PI);
                                        ctx.scale(1, -1);
                                        break;
                                    case 6:
                                        // 90° rotate right
                                        ctx.rotate(0.5 * Math.PI);
                                        ctx.translate(0, -height);
                                        break;
                                    case 7:
                                        // horizontal flip + 90 rotate right
                                        ctx.rotate(0.5 * Math.PI);
                                        ctx.translate(width, -height);
                                        ctx.scale(-1, 1);
                                        break;
                                    case 8:
                                        // 90° rotate left
                                        ctx.rotate(-0.5 * Math.PI);
                                        ctx.translate(-width, 0);
                                        break;
                                    default:
                                        vm.avatar = 'data:'+vm.organization.avatarTmp.filetype+';base64,'+vm.organization.avatarTmp.base64;
                                        vm.settingsChanged = true;
                                        dataUrlImage = null;
                                        resize = null;
                                        img = null;
                                        canvas = null;
                                        ctx = null;
                                        vm.$apply();
                                        return;
                                }
                                if (orientation && orientation > 4) {
                                    ctx.drawImage(resize, 0, 0);
                                } else {
                                    ctx.drawImage(resize, 0, 0, width, height);
                                }

                                canvas.setAttribute('crossOrigin', 'anonymous');
                                dataUrlImage = canvas.toDataURL('image/jpeg');
                                vm.organization.avatarTmp.filetype = 'image/jpeg';
                                vm.organization.avatarTmp.base64 = dataUrlImage.substr(dataUrlImage.indexOf(',') + 1);
                                vm.avatar = 'data:'+vm.organization.avatarTmp.filetype+';base64,'+vm.organization.avatarTmp.base64;
                                dataUrlImage = null;
                                resize = null;
                                img = null;
                                canvas = null;
                                ctx = null;
                                vm.$apply();
                            });
                        };
                        resize.src = 'data:' + vm.organization.avatarTmp.filetype + ';base64,' + vm.organization.avatarTmp.base64;
                    } else {
                        vm.avatar = 'data:'+vm.organization.avatarTmp.filetype+';base64,'+vm.organization.avatarTmp.base64;
                        img = null;
                    }

                    vm.loadingAvatar = false;
                    vm.settingsChanged = true;
                };
                img.src = 'data:' + vm.organization.avatarTmp.filetype + ';base64,' + vm.organization.avatarTmp.base64;
            }
        }
    };

    /**
     * Marks the basic settings info section as changed if needed
     */
    vm.checkSettingsChanged = function () {
        var wasChanged = (
            (vm.organizationData.name !== vm.organization.name) ||
            (vm.organizationData.email !== vm.organization.email) ||
            (vm.organizationData.website !== vm.organization.website) ||
            (vm.organizationData.location !== vm.organization.location) ||
            (vm.organizationData.description != vm.organization.description) ||
            (vm.organizationData.avatar != vm.organization.avatar)
        );

        vm.settingsChanged = wasChanged;
    };

    /**
     * Save the organization settings
     *
     * @param  {object}  form  The form
     */
    vm.saveSettings = function (form) {
        if (form.$invalid) { return; }

        var modalInstance;

        // Handles avatar image upload progress if it was changed
        if ((form['avatar'] && form['avatar'].$viewValue && form['avatar'].$viewValue.base64)) {
            modalInstance = $uibModal.open({
                scope: vm,
                keyboard: false,
                backdrop: 'static',
                animation: false,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                controller: 'ProfileProgressModalCtrl',
                template: require('../../common/templates/progress-modal.tpl.html'),
                resolve: {}
            });
            modalInstance.result.then(angular.noop, angular.noop);
        }

        var data: any = {
            organization_id: organization.id,
            name: vm.organizationData.name,
            email: vm.organizationData.email,
            website: vm.organizationData.website,
            location: vm.organizationData.location,
            description: vm.organizationData.description,
        };

        if (form['avatar'] && form['avatar'].$viewValue && form['avatar'].$viewValue.base64) {
            data.avatar = vm.avatar;
        }

        vm.savingSettings = true;
        strukshurApiService.organization.update(data).$promise
            .then(function (res) {
                var updatedData = {
                    name: res.organization.name,
                    slug: res.organization.slug,
                    email: res.organization.email,
                    website: res.organization.website,
                    location: res.organization.location,
                    description: res.organization.description,
                    avatar: res.organization.avatar,
                };

                // Updates local data with the new info from the server
                vm.settingsChanged = false;
                _forOwn(updatedData, function (v, k) {
                    vm.organization[k] = v;
                    vm.organizationData[k] = v;
                });

                strukshurUserService.updateOrganization(organization);
                toastr.success('Organization settings updated successfully.', 'Success!');
            })
            .catch(function (err) {
                if (err && err.data && err.data.message) {
                    Flash.create('danger', err.data.message, 10000, { container: 'settings-section-flash' }, true);
                } else if (err.status === 403) {
                    Flash.create('danger', 'You don\'t have the necessary permission to update the organization.', 10000, { container: 'settings-section-flash' }, true);
                } else {
                    Flash.create('danger', 'There was an error updating the organization settings.', 10000, { container: 'settings-section-flash' }, true);
                }
            })
            .finally(function () {
                if (modalInstance) {
                    modalInstance.dismiss();
                }
                vm.savingSettings = false;
            })
        ;
    };

    vm.init();
})

.controller('OrganizationsNewOrganizationCtrl', function OrganizationsNewOrganizationCtrl ($scope, $uibModalInstance, Flash, strukshurApiService) {
    var vm = $scope;

    vm.avatar = null;
    vm.loading = false;
    vm.loadingImage = false;
    vm.newOrganization = {
        name: '',
        email: '',
        website: '',
        location: '',
        description: '',
    };

    /**
     * Callback when a new image is selected
     */
    vm.loadStart = function () {
        vm.loadingImage = true;
    };

    /**
     * Callback when the image finished loading
     */
    vm.loadEnd = function () {
        vm.loadingImage = false;
    };

    /**
     * Callback fired after the new image has been validated
     *
     * @param  {Object}  eventObject  The event
     * @param  {Object}  fileReader   The file reader
     * @param  {object}  file         The file
     */
    vm.afterValidate = function (eventObject, fileReader, file) {
        vm.loadingImage = false;

        if (!file || file.length === 0) {
            return;
        }

        if (vm.avatar && !vm.avatar.base64) {
            Flash.create('danger', 'Invalid avatar image.', 10000, { container: 'organization-section-flash' }, true);
            vm.avatar = null;
            return;
        }

        if (file[0].size > 0.5*1000*1000) {
            Flash.create('danger', 'Avatar image must be smaller than 500K.', 10000, { container: 'organization-section-flash' }, true);
            vm.avatar = null;
            return;
        }

        if (file[0].type.substr(0,5) !== 'image') {
            vm.avatar = null;
        } else {
            // Resize
            var dataUrlImage;
            var canvas, MAX_WIDTH, MAX_HEIGHT, width, height, ctx, img;
            MAX_WIDTH = 2048;
            MAX_HEIGHT = 2048;

            if (vm.avatar && vm.avatar.base64) {

                // Compress image
                img = document.createElement('img');
                img.setAttribute('crossOrigin', 'anonymous');

                img.onload = function (ev) {
                    var resize;
                    width = img.width;
                    height = img.height;

                    if (width > height) {
                        if (width > MAX_WIDTH) {
                            height *= MAX_WIDTH / width;
                            width = MAX_WIDTH;
                        }
                    } else {
                        if (height > MAX_HEIGHT) {
                            width *= MAX_HEIGHT / height;
                            height = MAX_HEIGHT;
                        }
                    }

                    // Resize only if needed
                    if (width !== img.width || height !== img.height) {
                        canvas = document.createElement('canvas');
                        canvas.width = width;
                        canvas.height = height;
                        ctx = canvas.getContext('2d');
                        ctx.drawImage(img, 0, 0, width, height);

                        canvas.setAttribute('crossOrigin', 'anonymous');

                        dataUrlImage = canvas.toDataURL(vm.avatar.filetype);
                        vm.avatar.base64 = dataUrlImage.substr(dataUrlImage.indexOf(',') + 1);
                        dataUrlImage = null;
                        canvas = null;
                        ctx = null;
                    }

                    // If iOS rotate if needed
                    if (isMobile.apple.device || isMobile.apple.ipod || isMobile.apple.phone || isMobile.apple.tablet) {
                        resize = document.createElement('img');
                        resize.setAttribute('crossOrigin', 'anonymous');

                        resize.onload = function (event) {
                            width = resize.width;
                            height = resize.height;
                            EXIF.getData(img, function () {
                                var orientation = EXIF.getTag(this, "Orientation");
                                canvas = document.createElement('canvas');
                                ctx = canvas.getContext('2d');
                                if (orientation && orientation > 4) {
                                    canvas.width = height;
                                    canvas.height = width;
                                } else {
                                    canvas.width = width;
                                    canvas.height = height;
                                }

                                switch (orientation) {
                                    case 2:
                                        // horizontal flip
                                        ctx.translate(width, 0);ctx.scale(-1, 1);
                                        break;
                                    case 3:
                                        // 180° rotate left
                                        ctx.translate(width, height);ctx.rotate(Math.PI);
                                        break;
                                    case 4:
                                        // vertical flip
                                        ctx.translate(0, height);ctx.scale(1, -1);
                                        break;
                                    case 5:
                                        // vertical flip + 90 rotate right
                                        ctx.rotate(0.5 * Math.PI);ctx.scale(1, -1);
                                        break;
                                    case 6:
                                        // 90° rotate right
                                        ctx.rotate(0.5 * Math.PI);ctx.translate(0, -height);
                                        break;
                                    case 7:
                                        // horizontal flip + 90 rotate right
                                        ctx.rotate(0.5 * Math.PI);ctx.translate(width, -height);ctx.scale(-1, 1);
                                        break;
                                    case 8:
                                        // 90° rotate left
                                        ctx.rotate(-0.5 * Math.PI);ctx.translate(-width, 0);
                                        break;
                                    default:
                                        dataUrlImage = null;
                                        resize = null;
                                        img = null;
                                        canvas = null;
                                        ctx = null;
                                        return;
                                }

                                if (orientation && orientation > 4) {
                                    ctx.drawImage(resize, 0, 0);
                                } else {
                                    ctx.drawImage(resize, 0, 0, width, height);
                                }

                                canvas.setAttribute('crossOrigin', 'anonymous');
                                dataUrlImage = canvas.toDataURL('image/jpeg');
                                vm.avatar.filetype = 'image/jpeg';
                                vm.avatar.base64 = dataUrlImage.substr(dataUrlImage.indexOf(',')+1);
                                dataUrlImage = null;
                                resize = null;
                                img = null;
                                canvas = null;
                                ctx = null;
                                vm.$apply();
                            });
                        };
                        resize.src = 'data:' + vm.avatar.filetype + ';base64,' + vm.avatar.base64;
                    } else {
                        img = null;
                    }
                };
                img.src = 'data:' + vm.avatar.filetype + ';base64,' + vm.avatar.base64;
            }
        }
    };

    /**
     * Adds protocol prefix for the website if no value has been provided
     */
    vm.addWebsiteUrlPrefix = function () {
        if (vm.newOrganization.website == '') {
            vm.newOrganization.website = 'http://';
        }
    };

    /**
     * Prepends organization website url with http if necessary
     */
    vm.completeWebsiteUrl = function () {
        if (typeof vm.newOrganization.website === 'string' && vm.newOrganization.website !== '') {
            if (vm.newOrganization.website.indexOf('http') === -1) {
                vm.newOrganization.website = 'http://'+vm.newOrganization.website;
            }
        }
    };

    /**
     * Saves new organization on the backend
     *
     * @param  {Object}  form  The form
     */
    vm.saveOrganization = function (form) {
        if (!vm.newOrganization.name) {
            Flash.create('danger', 'Your organization needs a name.', 7000, { container: 'organization-section-flash' }, true);
            return;
        }

        if (form.$invalid) { return; }

        var data = {
            name: vm.newOrganization.name,
            email: vm.newOrganization.email,
            website: vm.newOrganization.website,
            location: vm.newOrganization.location,
            description: vm.newOrganization.description,
            avatar: '',
        };

        if (vm.avatar) {
            data.avatar = 'data:' + vm.avatar.filetype + ';base64,' + vm.avatar.base64;
        }

        vm.loading = true;
        strukshurApiService.organization.create(data).$promise
            .then(function (res) {
                $uibModalInstance.close(res);
            })
            .catch(function (res) {
                if (res.status === 403) {
                    Flash.create('danger', 'You don\'t have the necessary permission to create an organization.', 10000, { container: 'organization-section-flash' }, true);
                } else {
                    Flash.create('danger', 'There was an error trying to save the organization.', 10000, { container: 'organization-section-flash' }, true);
                }
            })
            .finally(function () {
                vm.loading = false;
            })
        ;
    };
})

.controller('OrganizationsLeaveOrganizationCtrl', function OrganizationsLeaveOrganizationCtrl ($scope, $uibModalInstance, strukshurApiService, message, organization) {
    var vm = $scope;

    vm.loading = false;
    vm.title = 'Confirm';
    vm.message = message;
    vm.errorMessage = false;
    vm.organization = organization;

    vm.confirm = function () {
        if (vm.loading) { return; }

        var data = {
            organization_id: vm.organization.id
        };

        vm.loading = true;
        vm.errorMessage = false;
        strukshurApiService.organization.leave(data).$promise
            .then(function () {
                $uibModalInstance.close(true);
            })
            .catch(function (res) {
                if (res.status === 403) {
                    this.errorMessage = 'You don\'t have the necessary permission to leave the organization.';
                } else {
                    this.errorMessage = 'There was an error trying to leave the organization.';
                }
            })
            .finally(function () {
                vm.loading = false;
            })
        ;
    };

    vm.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };
})

.controller('OrganizationDetailNewFolderCtrl', function ($scope, $uibModalInstance, parentFolder, organization, strukshurApiService) {
    var vm = $scope;

    vm.loading = false;
    vm.errorMessage = '';
    vm.newFolder = { name: '' };

    vm.saveFolder = (form) => {
        if (form.folderForm.$valid) {
            vm.loading = true;
            vm.errorMessage = '';

            const data = {
                organization_id: organization.id,
                parent_id: (parentFolder) ? parentFolder.id : null,
                name: vm.newFolder.name,
            };

            strukshurApiService.organizationFolder.create(data).$promise
                .then((res) => {
                    let folder = res.folder;

                    if (!folder.childFiles) {
                        folder.childFiles = [];
                    }
                    if (!folder.childFolders) {
                        folder.childFolders = [];
                    }

                    $uibModalInstance.close({ folder: folder });
                })
                .catch((res) => {
                    if (res.status === 403) {
                        vm.errorMessage = 'You don\'t have the necessary permission to create the folder.';
                    } else {
                        vm.errorMessage = 'There was an error trying to create the project folder.';
                    }
                })
                .finally(() => vm.loading = false)
            ;
        }
    };
})

.controller('OrganizationDetailEditFolderCtrl', function ($scope, $uibModalInstance, folder, strukshurApiService) {
    var vm = $scope;

    vm.loading = false;
    vm.errorMessage = '';
    vm.editFolder = { name: folder.name };

    vm.saveFolder = (form) => {
        if (form.folderForm.$valid) {
            vm.loading = true;
            vm.errorMessage = '';

            const data = {
                folder_id: folder.id,
                name: vm.editFolder.name,
            };

            strukshurApiService.organizationFolder.update(data).$promise
                .then((res) => $uibModalInstance.close({ folder: res.folder }))
                .catch((res) => {
                    if (res.status === 403) {
                        vm.errorMessage = 'You don\'t have the necessary permission to edit the folder.';
                    } else {
                        vm.errorMessage = 'There was an error trying to edit the project folder.';
                    }
                })
                .finally(() =>  vm.loading = false)
            ;
        }
    };
})

.controller('OrganizationDetailMoveFolderCtrl', function ($scope, $uibModalInstance, folder, organization, strukshurApiService) {
    var vm = $scope;

    vm.folders = [];
    vm.saving = false;
    vm.folder = folder;
    vm.loading = false;
    vm.breadcrumb = [];
    vm.errorMessage = '';
    vm.currentFolders = [];
    vm.selectedFolder = null;

    /**
     * Controller initialization
     */
    vm.init = () => {

        // Loads organization folder structure so the user can pick the destination folder
        vm.loading = true;
        strukshurApiService.organizationFolder.paths({ organization_id: organization.id, from_folder: 'ROOT', depth: 5 }).$promise
            .then((res) => {

                // Filter out target folder and it's children since we should not let the user move if inside itself
                vm.folders = filterDeep(res.paths, (item) => item.id !== vm.folder.id, { pathFormat: 'array', childrenPath: ['children'], onFalse: { skipChildren: true } });
                vm.selectedFolder = 'ROOT';
                vm.currentFolders = vm.folders;
            })
            .catch((err) => {
                if (err.status === 403) {
                    vm.errorMessage = 'You don\'t have the necessary permission to list folders in this project.';
                } else {
                    if (err && err.data && err.data.message) {
                        vm.errorMessage = err.data.message;
                    } else {
                        vm.errorMessage = 'There was an error trying to list the project\'s folders.';
                    }
                }
            })
            .finally(() => vm.loading = false)
        ;
    };

    /**
     * Navigates to the current project's root folder
     */
    vm.goToRoot = () => {
        vm.breadcrumb = [];
        vm.selectedFolder = 'ROOT';
        vm.currentFolders = vm.folders;
    };

    /**
     * Navigates to the given folder on the selection view
     *
     * @param  {object}  folder  The folder
     */
    vm.goToFolder = (folder) => {
        const found = findDeep(vm.folders, (item) => item.id === folder.id, { pathFormat: 'array', childrenPath: ['children'] });
        if (found) {
            vm.selectedFolder = found.value;
            vm.currentFolders = found.value.children;

            // Pops out all elements from the breadcrumb after the selected folder
            vm.breadcrumb = vm.breadcrumb.splice(0, vm.breadcrumb.indexOf(folder)+1);
        }
    };

    /**
     * Handles folder selection
     *
     * @param  {object}  folder  The folder
     */
    vm.folderSelected = (folder) => {
        if (folder.selected) { return; }

        vm.currentFolders.forEach(folder => { folder.selected = false });

        vm.selectedFolder = folder;
        if (folder.children.length === 0) {
            folder.selected = true;
        } else {
            vm.currentFolders = folder.children;
            vm.breadcrumb.push({ id: folder.id, name: folder.name });
        }
    };

    /**
     * Moves the current folder to the selected destination
     */
    vm.moveFolder = () => {
        vm.errorMessage = '';

        let data: any = {
            folder_id: folder.id,
            organization_id: organization.id,
        };

        // Only adds selected folder information if it's a child folder.
        // The selected folder will be the current folder's new parent.
        if (vm.selectedFolder && vm.selectedFolder !== 'ROOT') {
            data.parent_id = vm.selectedFolder.id;
        }

        // Invites the user to the team
        vm.saving = true;
        strukshurApiService.organizationFolder.move(data).$promise
            .then((res) => $uibModalInstance.close())
            .catch((res) => {
                if (res.status === 403) {
                    vm.errorMessage = 'You don\'t have the necessary permission to move the folder.';
                } else {
                    if (res && res.data && res.data.message) {
                        vm.errorMessage = res.data.message;
                    } else {
                        vm.errorMessage = 'There was an error trying to move the folder.';
                    }
                }
            })
            .finally(() => vm.saving = false)
        ;
    };

    vm.init();
})

.controller('OrganizationDetailDeleteFolderCtrl', function ($scope, $uibModalInstance, strukshurApiService) {
    var vm = $scope;

    vm.onConfirmChosen = () => {

        // Deletes the folder on the server
        vm.loading = true;
        strukshurApiService.organizationFolder.delete({ folder_id: vm.folder.id }).$promise
            .then(() => $uibModalInstance.close())
            .catch((res) => {
                if (res.status === 403) {
                    vm.errorMessage = 'You don\'t have the necessary permission to delete the folder.';
                } else {
                    vm.errorMessage = 'There was an error trying to delete the folder.';
                }
            })
            .finally(() => vm.loading = false)
        ;
    };
})

.controller('OrganizationDetailEditFileCtrl', function ($scope, $uibModalInstance, file, strukshurApiService) {
    var vm = $scope;

    vm.loading = false;
    vm.errorMessage = '';
    vm.editFile = { name: file.filename };

    /**
     * Saves the file on the backend
     *
     * @param  {object}  form  The form
     */
    vm.saveFile = function (form: any) {
        if (form.fileForm.$valid) {
            vm.loading = true;
            vm.errorMessage = '';

            let data = {
                file_id: file.id,
                name: vm.editFile.name,
            };

            strukshurApiService.organizationFile.update(data).$promise
                .then((res: any) => $uibModalInstance.close({ file: res.file }))
                .catch((res: any) => {
                    if (res.status === 403) {
                        vm.errorMessage = 'You don\'t have the necessary permission to edit the file.';
                    } else {
                        vm.errorMessage = 'There was an error trying to edit the organization file.';
                    }
                })
                .finally(() => vm.loading = false)
            ;
        }
    };
})

.controller('OrganizationDetailMoveFileCtrl', function ($scope, $uibModalInstance, file, organization, strukshurApiService) {
    var vm = $scope;

    vm.file = file;
    vm.folders = [];
    vm.saving = false;
    vm.loading = false;
    vm.breadcrumb = [];
    vm.errorMessage = '';
    vm.currentFolders = [];
    vm.selectedFolder = null;

    /**
     * Controller initialization
     */
    vm.init = () => {

        // Loads organization folder structure so the user can pick the destination folder
        vm.loading = true;
        strukshurApiService.organizationFolder.paths({ organization_id: organization.id, from_folder: 'ROOT', depth: 5 }).$promise
            .then((res) => {
                vm.folders = res.paths;
                vm.selectedFolder = 'ROOT';
                vm.currentFolders = vm.folders;
            })
            .catch((err) => {
                if (err.status === 403) {
                    vm.errorMessage = 'You don\'t have the necessary permission to list folders in this organization.';
                } else {
                    if (err && err.data && err.data.message) {
                        vm.errorMessage = err.data.message;
                    } else {
                        vm.errorMessage = 'There was an error trying to list the organization\'s folders.';
                    }
                }
            })
            .finally(() => vm.loading = false)
        ;
    };

    /**
     * Navigates to the current organization's root folder
     */
    vm.goToRoot = () => {
        vm.breadcrumb = [];
        vm.selectedFolder = 'ROOT';
        vm.currentFolders = vm.folders;
    };

    /**
     * Navigates to the given folder on the selection view
     *
     * @param  {object}  folder  The folder
     */
    vm.goToFolder = (folder) => {
        const found = findDeep(vm.folders, (item) => item.id === folder.id, { pathFormat: 'array', childrenPath: ['children'] });
        if (found) {
            vm.selectedFolder = found.value;
            vm.currentFolders = found.value.children;

            // Pops out all elements from the breadcrumb after the selected folder
            vm.breadcrumb = vm.breadcrumb.splice(0, vm.breadcrumb.indexOf(folder)+1);
        }
    };

    /**
     * Handles folder selection
     *
     * @param  {object}  folder  The folder
     */
    vm.folderSelected = (folder) => {
        if (folder.selected) { return; }

        vm.currentFolders.forEach(folder => { folder.selected = false });

        vm.selectedFolder = folder;
        if (folder.children.length === 0) {
            folder.selected = true;
        } else {
            vm.currentFolders = folder.children;
            vm.breadcrumb.push({ id: folder.id, name: folder.name });
        }
    };

    /**
     * Moves the current file to the selected destination
     */
    vm.moveFile = () => {
        vm.errorMessage = '';

        let data: any = {
            file_id: file.id,
            organization_id: organization.id,
        };

        // Only adds selected folder information if it's a child folder.
        // The selected folder will be the current folder's new parent.
        if (vm.selectedFolder && vm.selectedFolder !== 'ROOT') {
            data.parent_id = vm.selectedFolder.id;
        }

        // Invites the user to the team
        vm.saving = true;
        strukshurApiService.organizationFile.move(data).$promise
            .then((res) => $uibModalInstance.close())
            .catch((res) => {
                if (res.status === 403) {
                    vm.errorMessage = 'You don\'t have the necessary permission to move the file.';
                } else {
                    if (res && res.data && res.data.message) {
                        vm.errorMessage = res.data.message;
                    } else {
                        vm.errorMessage = 'There was an error trying to move the file.';
                    }
                }
            })
            .finally(() => vm.saving = false)
        ;
    };

    vm.init();
})

.controller('OrganizationDetailShareFileCtrl', function ($scope, $uibModalInstance, file, ngClipboard, toastr, strukshurApiService) {
    var vm = $scope;

    vm.file = file;
    vm.emails = '';
    vm.loading = false;
    vm.loadingLink = false;
    vm.errorMessage = '';
    vm.canShareFile = (file.sharedFile != null);

    /**
     * Creates a new share link for the current file
     */
    vm.createShareLink = () => {
        if (vm.loadingLink) { return; }

        vm.loadingLink = true;
        vm.errorMessage = '';

        strukshurApiService.organizationFile.createShareLink({ file_id: vm.file.id }).$promise
            .then((res: any) => {
                vm.canShareFile = true;
                vm.file.sharedFile = res.sharedFile;
            })
            .catch((res: any) => {
                if (res.status === 403) {
                    vm.errorMessage = 'You don\'t have the necessary permission to edit the file.';
                } else {
                    vm.errorMessage = 'There was an error trying to edit the organization file.';
                }
            })
            .finally(() => vm.loadingLink = false)
        ;
    };

    /**
     * Copy shared file url
     */
    vm.copyLink = () => {
        ngClipboard.toClipboard(vm.file.sharedFile.shareUrl);
        toastr.success('The share link was copied to the clipboard.', 'Link copied!');
    };

    /**
     * Saves the file on the backend
     *
     * @param  {object}  form  The form
     */
    vm.shareFile = (form: any) => {
        if (vm.loading) { return; }

        if (form.shareFile.$valid) {
            vm.loading = true;
            vm.errorMessage = '';

            const data = {
                file_id: file.id,
                receipients: vm.emails,
            };

            strukshurApiService.organizationFile.shareFile(data).$promise
                .then((res: any) => {
                    $uibModalInstance.close({ sharedFile: vm.file.sharedFile })
                })
                .catch((res: any) => {
                    if (res.status === 403) {
                        vm.errorMessage = 'You don\'t have the necessary permission to share the file.';
                    } else {
                        vm.errorMessage = 'There was an error trying to share the organization file.';
                    }
                })
                .finally(() => vm.loading = false)
            ;
        }
    };
})

.controller('OrganizationDetailDeleteFileCtrl', function ($scope, $uibModalInstance, strukshurApiService) {
    var vm = $scope;

    vm.removeRelated = false;

    vm.onConfirmChosen = () => {

        // Deletes the file on the server
        vm.loading = true;
        strukshurApiService.organizationFile.delete({ file_id: vm.file.id }).$promise
            .then(() => $uibModalInstance.close())
            .catch((res: any) => {
                if (res.status === 403) {
                    vm.errorMessage = 'You don\'t have the necessary permission to delete the file.';
                } else {
                    vm.errorMessage = 'There was an error trying to delete the file.';
                }
            })
            .finally(() => vm.loading = false)
        ;
    };
})

.controller('OrganizationInviteMemberCtrl', function OrganizationInviteMemberCtrl ($scope, $uibModalInstance, ivhTreeviewMgr, organization, strukshurApiService, strukshurOrganizationService) {
    var vm = $scope;

    vm.loading = false;
    vm.foundUsers = [];
    vm.errorMessage = '';
    vm.availableRoles = [];
    vm.newMember = { user: '', email: '', role: '' };

    // Set all available organization permissions
    vm.permissions = strukshurOrganizationService.getAllAvailablePermissions();

    // Define permissions tree options
    vm.permissionOpts = {
        twistieExpandedTpl: '<i class="fa fa-minus-circle"></i>',
        twistieCollapsedTpl: '<i class="fa fa-plus-circle"></i>',
        twistieLeafTpl: '&nbsp;&nbsp;',
    };

    // Load available roles
    strukshurApiService.searchContractorRoles.list().$promise
        .then(function (res) {
            vm.availableRoles = res.roles;
        })
    ;

    /**
     * Invites the member to be a part of the project
     *
     * @param  {Object}  form  The form
     */
    vm.inviteMember = function (form) {
        vm.errorMessage = '';

        // Validates if a user or email was selected
        if (!vm.newMember.email && !vm.newMember.user) {
            vm.errorMessage = 'You need to select an user or input an email.';
            return;
        }

        // Validates if a role was selected
        if (!vm.newMember.role) {
            vm.errorMessage = 'You need to select a role for the team member.';
            return;
        }

        // Get all selected permissions
        var selectedPermissions: string[] = [];
        vm.permissions.forEach(function (permission) {

            // First level
            if (permission.selected) {
                selectedPermissions.push(permission.value);
            }

            if (permission.children) {

                // Second level
                permission.children.forEach(function (permission) {

                    if (permission.selected) {
                        selectedPermissions.push(permission.value);
                    }

                    if (permission.children) {

                        // Third level
                        permission.children.forEach(function (permission) {

                            if (permission.selected) {
                                selectedPermissions.push(permission.value);
                            }

                            if (permission.children) {

                                // Fourth level
                                permission.children.forEach(function (permission) {

                                    if (permission.selected) {
                                        selectedPermissions.push(permission.value);
                                    }
                                });
                            }
                        });
                    }
                });
            }
        });

        // Check if at least one permission was selected
        if (selectedPermissions.length === 0) {
            vm.errorMessage = 'You need to select at least one permission for the new member.';
            return;
        }

        var data: any = {
            role: vm.newMember.role.name,
            organization_id: organization.id,
            permissions: selectedPermissions.join(',')
        };

        if (vm.newMember.user) {
            data.user_slug = vm.newMember.user.slug;
        }
        if (vm.newMember.email) {
            data.email = vm.newMember.email;
        }

        // Invites the user to the team
        vm.loading = true;
        strukshurApiService.organizationMember.invite(data).$promise
            .then(function (res) {
                if (res.member) {
                    $uibModalInstance.close({ member: res.member });
                }
            })
            .catch(function (res) {
                if (res.status === 403) {
                    vm.errorMessage = 'You don\'t have the necessary permission to invite a team member.';
                } else {
                    if (res && res.data && res.data.message) {
                        vm.errorMessage = res.data.message;
                    } else {
                        vm.errorMessage = 'There was an error trying to invite the member to the team.';
                    }
                }
            })
            .finally(function () {
                vm.loading = false;
            })
        ;
    };

    /**
     * Find users based on the typed text
     */
    vm.findUsers = function (search) {
        if (search === '') {
            vm.foundUsers = [];
            return;
        }

        strukshurApiService.userFind
            .query({ terms: search, source: 'account' }).$promise
            .then(function (res) {
                vm.foundUsers = res.users;
            })
        ;
    };

    /**
     * Select all user permissions
     */
    vm.selectAllPermissions = function () {
        ivhTreeviewMgr.selectAll(vm.permissions);
    };

    /**
     * Deselect all user permissions
     */
    vm.deselectAllPermissions = function () {
        ivhTreeviewMgr.deselectAll(vm.permissions);
    };
})

.controller('OrganizationEditMemberCtrl', function OrganizationEditMemberCtrl ($scope, $timeout, $uibModalInstance, ivhTreeviewMgr, member, organization, strukshurApiService, strukshurOrganizationService) {
    var vm = $scope;

    vm.member = member;
    vm.loading = false;
    vm.permissions = [];
    vm.errorMessage = '';
    vm.availableRoles = [];
    vm.memberData = {
        role: null,
        location: member.location,
        phoneNumber: member.phoneNumber
    };

    // Define permissions tree options
    vm.permissionOpts = {
        twistieExpandedTpl: '<i class="fa fa-minus-circle"></i>',
        twistieCollapsedTpl: '<i class="fa fa-plus-circle"></i>',
        twistieLeafTpl: '&nbsp;&nbsp;',
    };

    /**
     * Initialization method for the controller
     */
    vm.init = function () {

        // Load available roles
        strukshurApiService.searchContractorRoles.list().$promise
            .then(function (res) {
                vm.availableRoles = res.roles;
                vm.memberData.role = _find(vm.availableRoles, { name: member.role.toUpperCase() });
            })
        ;

        // Get all available organization permissions
        vm.permissions = strukshurOrganizationService.getAllAvailablePermissions();

        // Deselect all permissions in order to then only elect ones set for the team member
        vm.deselectAllPermissions();

        // Only mark the permissions the team member has as selected
        if (member.permissions) {
            var memberPermissions = member.permissions.split(',');

            /**
             * Returns the permission of the given id
             *
             * @param  {Array}   permissions  The permission list
             * @param  {String}  id           The permision id
             *
             * @return {Object}
             */
            var findPermission = function (permissions, id) {
                var foundPermission = null;

                // We first try to find the permission at the root level
                permissions.forEach(function (permission) {
                    if (permission.id == id) {
                        foundPermission = permission;
                        return;
                    }

                    // If we didn't find the permission at the root level, then we try to
                    // search the permission children
                    if (permission.children) {
                        let childPermission = findPermission(permission.children, id);
                        if (childPermission) {
                            foundPermission = childPermission;
                            return;
                        }
                    }
                });

                return foundPermission;
            };

            // First we deselect all permissions
            ivhTreeviewMgr.deselectAll(vm.permissions);

            // Then we go through all member permissions and select them, but in an
            // async call as we need to allow the digest cycle to propagate, otherwise
            // partial select node states won't be rendered on the component
            $timeout(function() {
                memberPermissions.forEach(function (permission) {
                    ivhTreeviewMgr.select(vm.permissions, findPermission(vm.permissions, permission));
                });
            }, 300);
        }
    };

    /**
     * Invites the member to be a part of the project
     *
     * @param  {Object}  form  The form
     */
    vm.saveMember = function (form) {
        vm.errorMessage = '';

        // Validates if a role was selected
        if (!vm.memberData.role) {
            vm.errorMessage = 'You need to select a role for the team member.';
            return;
        }

        // Get all selected permissions
        var selectedPermissions = [];
        vm.permissions.forEach(function (permission) {

            // First level
            if (permission.selected) {
                selectedPermissions.push(permission.value);
            }

            if (permission.children) {

                // Second level
                permission.children.forEach(function (permission) {

                    if (permission.selected) {
                        selectedPermissions.push(permission.value);
                    }

                    if (permission.children) {

                        // Third level
                        permission.children.forEach(function (permission) {

                            if (permission.selected) {
                                selectedPermissions.push(permission.value);
                            }

                            if (permission.children) {

                                // Fourth level
                                permission.children.forEach(function (permission) {

                                    if (permission.selected) {
                                        selectedPermissions.push(permission.value);
                                    }
                                });
                            }
                        });
                    }
                });
            }
        });

        // Check if at least one permission was selected
        if (selectedPermissions.length === 0) {
            vm.errorMessage = 'You need to select at least one permission for the member.';
            return;
        }

        var data = {
            member_id: member.id,
            organization_id: organization.id,
            role: vm.memberData.role.name,
            location: vm.memberData.location,
            phone_number: vm.memberData.phoneNumber,
            permissions: selectedPermissions.join(',')
        };

        // Updates the team member on the project
        vm.loading = true;
        strukshurApiService.organizationMember.update(data).$promise
            .then(function (res) {
                $uibModalInstance.close({ member: res.member });
            })
            .catch(function (res) {
                if (res.status === 403) {
                    vm.errorMessage = 'You don\'t have the necessary permission to edit the team member.';
                } else {
                    if (res && res.data && res.data.message) {
                        vm.errorMessage = res.data.message;
                    } else {
                        vm.errorMessage = 'There was an error trying to edit the team member.';
                    }
                }
            })
            .finally(function () {
                vm.loading = false;
            })
        ;
    };

    /**
     * Select all user permissions
     */
    vm.selectAllPermissions = function () {
        ivhTreeviewMgr.selectAll(vm.permissions);
    };

    /**
     * Deselect all user permissions
     */
    vm.deselectAllPermissions = function () {
        ivhTreeviewMgr.deselectAll(vm.permissions);
    };

    vm.init();
})

.controller('OrganizationRemoveMemberCtrl', function OrganizationRemoveMemberCtrl ($scope, $uibModalInstance, strukshurApiService) {
    var vm = $scope;

    vm.onConfirmChosen = function () {
        var data = { organization_id: vm.organization.id, member_id: vm.member.id };

        // Removes the member from the project
        vm.loading = true;
        strukshurApiService.organizationMember.remove(data).$promise
            .then(function (res) {
                $uibModalInstance.close();
            })
            .catch(function (res) {
                if (res.status === 403) {
                    vm.errorMessage = 'You don\'t have the necessary permission to remove the team member.';
                } else {
                    vm.errorMessage = 'There was an error trying to remove the member from your team.';
                }
            })
            .finally(function () {
                vm.loading = false;
            })
        ;
    };
})

;
