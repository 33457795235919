import * as angular from 'angular';

angular.module('BlueprintDirective', [])
    .directive('blueprint', function (strukshurApiService, toastr) {
        return {
            restrict: 'E',
            template: require('./blueprint-directive.tpl.html'),
            scope: {
                state: '=',
                room: '=room'
            },
            controllerAs: 'bp',
            bindToController: true,
            link: (scope, el, attr) => {
                el.find('iframe')[0].onload = () => {
                    scope['onIframeLoaded']();
                };
            },
            controller: ($scope, $sce, $window) => {
                $scope.saving = false;
                $scope.blueprintFrame = angular.element('#blueprint-frame')[0];

                // Adds new event listeners for the current page only
                $window.addEventListener('message', (message) => {
                    if (message.data && message.data.action) {
                        if (message.data.action === 'frame-loaded') {

                            if ($scope.bp.room.blueprint) {
                                var msg = {
                                    action: 'loadBlueprintFile',
                                    file: $scope.bp.room.blueprint
                                };

                                $scope.blueprintFrame.contentWindow.postMessage(msg, $window.location.origin);
                            } else {
                                $scope.blueprintFrame.contentWindow.postMessage({ action: 'loadDefault' }, $window.location.origin);
                            }
                        }

                        if (message.data.action === 'save-blueprint') {
                            if ($scope.saving) { return; }

                            if (!$scope.bp.room.permissions.canEdit) {
                                return toastr.error('You don\'t have permission to save the blueprint.', 'Error!');
                            }

                            $scope.saving = true;
                            strukshurApiService.projectBlueprint.update({ room_id: $scope.bp.room.projectRoom.id, content: message.data.blueprint }).$promise
                                .then(() => {
                                    $scope.blueprintFrame.contentWindow.postMessage({ action: 'blueprint-saved' }, $window.location.origin);
                                    toastr.success('The blueprint was saved successfully.', 'Success!');
                                })
                                .catch(() => {
                                    $scope.blueprintFrame.contentWindow.postMessage({ action: 'blueprint-saved' }, $window.location.origin);
                                    toastr.error('There was an error while saving the blueprint.', 'Error!');
                                })
                                .finally(() => $scope.saving = false)
                            ;
                        }
                    }
                });

                $scope['onIframeLoaded'] = angular.noop;

                $scope.frameHeight = $window.innerHeight - 260;
                $scope.url = $sce.trustAsResourceUrl('/public/blueprint3d/index.html');
            }
        };
    })
;
