import { find as _find } from 'lodash';
import * as angular from 'angular';
import * as isMobile from 'ismobilejs';
import * as EXIF from 'exif-js';

angular.module('strukshurApp.room', ['ui.router', 'ui.bootstrap'])

.config(function config($stateProvider) {
    $stateProvider
        .state('room', {
            url: '/:userSlug/:houseSlug/room/:roomId/:roomSlug',
            views: {
                main: {
                    controller: 'RoomCtrl',
                    template: require('./room.tpl.html')
                }
            },
            resolve: {
                roomResolve: function($q, $state, $stateParams, strukshurApiService) {
                    return strukshurApiService.userRoom.get({ 'room_id': $stateParams.roomId, 'room_slug': $stateParams.roomSlug }).$promise
                        .then(function(res) {
                            if (res === undefined) {
                                $state.go('home');
                                return;
                            }

                            return res.room;
                        })
                        .catch(function () {
                            $state.go('home');
                        })
                    ;
                }
            },
            data: { pageTitle: 'Room Board', class: 'board' }
        })

        .state('blueprint', {
            url: '/blueprint/:userSlug/:houseSlug/room/:roomId/:roomSlug',
            views: {
                main: {
                    controller: 'BlueprintCtrl',
                    template: require('./blueprint.tpl.html')
                }
            },
            params: {
                forceReload: false
            },
            resolve: {
                roomResolve: function($q, $state, $stateParams, strukshurApiService) {
                    return strukshurApiService.userRoom.get({ 'room_id': $stateParams.roomId, 'room_slug': $stateParams.roomSlug }).$promise
                        .then(function(res) {
                            if (res === undefined) {
                                $state.go('home');
                                return;
                            }

                            return res.room;
                        })
                        .catch(function () {
                            $state.go('home');
                        })
                    ;
                }
            },
            data: { pageTitle: 'Room Blueprint', class: 'board page-blueprint' }
        })
    ;
})

.service('roomService', ['$q', '$http', 'strukshurUserService', 'strukshurApiService', '$timeout', function ($q, $http, strukshurUserService, strukshurApiService, $timeout) {
    var room;
    var roomProps;
    var rooms = [];
    var th = this;

    this.pinVideoStatus = function(pin){
        $timeout(function(data){
            //debugger;
            var pin = data.pin, th = data.th;
            strukshurApiService.getPinVideoStatus.query({pin_id: pin.id}, function(d){
                var $pin_room;
                if(!d.isProcessing){
                    pin.isProcessing = d.isProcessing;
                    pin.width = d.width;
                    pin.height = d.height;
                }
                else{
                    $pin_room = document.getElementById('pin_room');
                    if($pin_room){
                        th.pinVideoStatus(pin);
                    }
                }
            });
        }, 5000, true, {pin: pin, th: th});
    };
    this.getRooms = function() {
        return rooms;
    };

    this.setRooms = function(value) {
        return rooms = value;
    };

    this.setCurrentRoomProps = function (value) {
        roomProps = value;
    };

    this.getCurrentRoomProps = function () {
        return roomProps;
    };

    this.setCurrentRoom = function (value) {
        room = value;
    };

    this.setCurrentRoom = function () {
        return room;
    };
}])

.controller('RoomCtrl', function RoomCtrl($scope, $state, $stateParams, $timeout, $uibModal, angularGridInstance, roomResolve, roomService, strukshurApiService, strukshurPinterestService, strukshurUserService, uiTourService) {
    var tot, i,
    vm = $scope;

    // @TODO who is allowed to view this page ?

    // determine if current user is the owner of this room
    vm.roomOwner = roomResolve.is_owner;

    vm.currentRoom = roomResolve;
    vm.roomName = vm.currentRoom.room.slug.split('-')[1];
    vm.roomType = vm.currentRoom.room.slug.split('-')[0];
    vm.currentUserSlug = strukshurUserService.getUserSlug();
    vm.currentUser = {};
    vm.countProjectsOwn = 0;
    strukshurUserService.getUser().then(function (d) {
        vm.currentUser = d;
        if (vm.roomOwner) {
            strukshurApiService.projectprojects.query({ page: 0, limit: 40 }, function (d) {
                var i, tot;
                tot = d.projects.length;
                vm.countProjectsOwn = 0;
                for(i=0;i<tot;i++){
                    if(d.projects[i].owner_id===vm.currentUser.id){
                        vm.countProjectsOwn++;
                    }
                }
            });
        }

    });

    if (vm.roomOwner) {

        // load room checklist
        vm.checklistRoom = vm.currentRoom.room.roomProps;
        roomService.setCurrentRoomProps(vm.currentRoom.room.roomProps);
    }

    vm.roomLoaded = true;
    vm.hasMorePins = false;
    vm.pins = vm.currentRoom.pins;

    tot = vm.pins.length;
    for (i = 0; i < tot; i++) {
        if(vm.pins[i].isVideo && vm.pins[i].isProcessing){
            roomService.pinVideoStatus(vm.pins[i]);
        }
    }

    // List all available user rooms
    vm.rooms = [];
    strukshurApiService.userRooms.list({ house_id: strukshurUserService.getHouseId() }).$promise
        .then(function (res) {
            res.rooms.forEach(function (room) {
                if (room.id === vm.currentRoom.id) {
                    vm.selectedRoom = room;
                }
            });

            vm.rooms = res.rooms;
            roomService.setRooms(res.rooms);
        })
    ;

    vm.changeRoom = function() {

        // Does not perform the action if the product tour is active
        if (vm.isTourActive()) { return; }

        vm.pins = {};
        vm.roomLoaded = false;
        $state.go('room', { userSlug: $stateParams.userSlug, roomId: vm.selectedRoom.id, roomSlug: vm.selectedRoom.slug, houseSlug: 'my-house' }, { reload: true });
    };

    vm.openTaxonomyPopUp = function(item, room, $event) {
        $event.preventDefault();
        $event.stopPropagation();

        // Does not perform the action if the product tour is active
        if (vm.isTourActive()) { return; }

        var modalInstance = $uibModal.open({
            animation: true,
            keyboard: true,
            scope: vm,
            ariaLabelledBy: 'modal-title',
            ariaDescribedBy: 'modal-body',
            template: require('../../common/templates/taxonomy-select-type-modal.tpl.html'),
            controller: 'TaxonomySelectModalCtrl',
            resolve: {
                item: function () {
                    return item;
                }
            }
        });

        modalInstance.result.then(function (selectedItem) {
            //vm.selectedRoom = selectedItem;
        }, function () {
            //$log.info('Modal dismissed at: ' + new Date());
        });
    };

    // @TODO infinite scroll
    vm.loadMore = function() {
        return [];
    };

    vm.refresh = function(){
        angularGridInstance.gallery.refresh();
    };

    //var modalIsShown = false;
    vm.boardOpen = function (size) {
        var modalInstance = $uibModal.open({
            animation: true,
            keyboard: true,
            scope: vm,
            ariaLabelledBy: 'modal-title',
            ariaDescribedBy: 'modal-body',
            template: require('../../common/templates/board-modal.tpl.html'),
            controller: 'BoardModalInstanceCtrl',
            size: size,
            resolve: {
                items: function () {
                    return vm.items;
                }
            }
        });

        modalInstance.result.then(function (selectedItem) {
            //vm.selectedRoom = selectedItem;
        }, function () {
            //$log.info('Modal dismissed at: ' + new Date());
        });
    };

    vm.openProjectCreate = function (size) {
        var modalInstance;

        // Does not perform the action if the product tour is active
        if (vm.isTourActive()) { return; }

        if (!strukshurUserService.hasAvailableProjects(vm.currentUser.plan, vm.currentUser.projectsOwned)){
            modalInstance = $uibModal.open({
                animation: true,
                keyboard: true,
                scope: $scope,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                controller: 'UpgradeModalCtrl',
                template: require('../../common/templates/upgrade-modal.tpl.html'),
                resolve: {
                }
            });
        } else {
            modalInstance = $uibModal.open({
                animation: vm.animationsEnabled,
                keyboard: true,
                scope: vm,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                template: require('../../common/templates/project-create-modal.tpl.html'),
                controller: 'ProjectModalInstanceCtrl',
                size: size,
                resolve: {
                    allowOwnerChange: false,
                    organization: null,
                    project: function () {
                        return {};
                    },
                    title: function () {
                        return 'Create new project';
                    }
                }
            });

            modalInstance.result.then(function (selectedItem) {
                //vm.selected = selectedItem;
                vm.countProjectsOwn++;
                $state.go('projects');
            }, function () {
                //$log.info('Modal dismissed at: ' + new Date());
            });
        }
    };

    vm.addImage = function () {
        var modalInstance = $uibModal.open({
            animation: vm.animationsEnabled,
            keyboard: true,
            scope: vm,
            ariaLabelledBy: 'modal-title',
            ariaDescribedBy: 'modal-body',
            template: require('../../common/templates/pin-create-modal.tpl.html'),
            controller: 'PinNativeCtrl',
            resolve: {
                items: function () {
                    return [];
                }
            }
        });

        modalInstance.result.then(function (selectedItem) {
            //vm.selected = selectedItem;
        }, function (imported) {

            if (imported === true) {
                // re-loading the room fixes issue with pins not loading with grid module
                vm.roomUpdate();
            }
        });

        vm.addImageModal = modalInstance;
    };

    vm.roomLoad = function() {
        vm.roomLoaded = false;

        // @TODO load only the pins and change the url for deep linking

        strukshurApiService.getRoom.query({'user_slug': $stateParams.userSlug, 'room_slug': $stateParams.roomSlug}, function(data) {
            vm.roomLoaded = true;
            //vm.checklistRoom = vm.checklist[$stateParams.roomSlug];
            vm.pins = data.pins;
            vm.checklistRoom = data.room_tags;
            console.log('loaded pins');
            console.log(data);
            //angularGridInstance.gallery.refresh();
        }, function(e){
            vm.roomLoaded = true;
        });
    };

    vm.importPins = () => {

        // Does not perform the action if the product tour is active
        if (vm.isTourActive()) { return; }

        // Check if we first need to authenticate the user with Pinterest before proceeding
        if (!strukshurUserService.getUserObj().pinterestAccessToken) {

            // Opens authentication popup window
            strukshurPinterestService.authenticate();

            // Display authenticating modal to wait until the user has authenticated with Pinterest
            const modalInstance = $uibModal.open({
                scope: $scope,
                keyboard: true,
                windowClass: 'win-class',
                backdropClass: 'bg-class',
                controller: 'AuthenticatingPinterestModalCtrl',
                template: require('../../common/templates/pinterest-processing-auth.tpl.html'),
            });

            modalInstance.result.then(

                // Resolved callback
                vm.openImportPinsModal,

                // Rejected callback
                angular.noop
            );

            return;
        }

        vm.openImportPinsModal();
    };

    vm.openImportPinsModal = () => {
        strukshurPinterestService.boards.list({ sort: 'alphabetical' }).$promise
            .then(res => {
                vm.boards = res.data;
                vm.pinterestBookmark = res.bookmark;

                const modalInstance = $uibModal.open({
                    scope: vm,
                    keyboard: true,
                    animation: vm.animationsEnabled,
                    ariaLabelledBy: 'modal-title',
                    ariaDescribedBy: 'modal-body',
                    backdropClass: 'bg-class',
                    windowClass: 'win-class',
                    template: require('../../common/templates/board-pin-import.tpl.html'),
                    controller: 'PinImportCtrl',
                    resolve: {
                        items: () =>vm.items,
                    }
                });

                modalInstance.result.then(function (selectedItem) {
                    //vm.selected = selectedItem;
                }, function (imported) {
                    if (imported === true) {
                        console.log('reloading room');
                        console.log(vm.selectedRoom);
                        vm.roomUpdate();
                    }
                });
            })
        ;
    };

    /**
     * Opens the modal to sync pins to a selected project
     *
     * @param  {object}  room  The room to sync the pins from
     */
    vm.syncPins = function (room) {
        // Does not perform the action if the product tour is active
        if (vm.isTourActive()) { return; }

        var modalInstance = $uibModal.open({
            animation: vm.animationsEnabled,
            keyboard: true,
            scope: vm,
            ariaLabelledBy: 'modal-title',
            ariaDescribedBy: 'modal-body',
            backdropClass: 'bg-class',
            windowClass: 'win-class',
            template: require('../../common/templates/room-sync-pins-to-project.tpl.html'),
            controller: 'RoomSyncPinsToProjectCtrl',
            resolve: {
                room: function () { return room; }
            }
        });

        modalInstance.result.then(

            // Success callback
            function () { },

            // Cancel/fail callback
            function () { }
        );
    };

    vm.roomUpdate = function() {
        $state.go('room', { userSlug: $stateParams.userSlug, houseSlug: 'my-house', roomId: vm.currentRoom.id, roomSlug: vm.currentRoom.slug }, { reload: true });
    };

    /**
     * Setup and initialize product tour for this page
     */
    vm.setupTour = function () {
        var steps = [
            { selector: '.room-header .form-control', order: 0, popupClass: 'select-room-step', placement: 'bottom', content: 'Select a room from the quick dropdown here.' },
            { selector: '.pin-actions .new-pin', stepId: 'new-pin', order: 1, popupClass: 'new-pin-step', content: 'Add a pin or video by selecting this option.' },
            { selector: 'label[for="pin_image"]', stepId: 'file-select', order: 2, popupClass: 'file-select-step', placement: 'bottom', content: 'Select an image or video from your phone or PC here.' },
            { selector: '.rooms', order: 3, popupClass: 'select-rooms-step', content: 'Choose the rooms you like to save the pin to.' },
            { selector: '.import-pins', stepId: 'pinterest-import', order: 4, popupClass: 'pinterest-import-step', content: 'Import pins from your Pinterest account here.' },
            { selector: '.sync-pins', stepId: 'sync-pins', order: 5, popupClass: 'sync-pins-step', placement: 'left', content: 'Sync pins from your rooms to a project board here. Note: you must create a project and inspiration board first.' },
        ];

        if (!isMobile.phone && !isMobile.tablet) {
            steps.push({ selector: '.btn-blueprint', order: 6, popupClass: 'blueprint-step', content: 'And finally you can access your room\'s 3D model here.' });
        }

        // First we setup the product tour
        vm.tour = uiTourService.createDetachedTour('room-board-tour', { appendToBody: true, backdrop: true, preventScrolling: true });

        // Then we add the defined steps to the tour
        steps.forEach(function (step) {
            vm.tour.createStep(step);
        });

        vm.tour.on('stepChanged', function (step) {
            if (step.stepId === 'file-select') {
                vm.tour.pause();
                vm.addImage();
                $timeout(vm.tour.resume, 200);
            }

            if (step.stepId === 'pinterest-import') {
                vm.tour.pause();
                vm.addImageModal.close();
                $timeout(vm.tour.resume, 200);
            }

            if (step.stepId === 'sync-pins') {
                vm.tour.pause();
                $timeout(vm.tour.resume, 50);
            }
        });
    };

    /**
     * Initializes product tour
     */
    vm.initTour = function () {

        // And finally display the product tour overview modal to get the flow started
        var modalInstance = $uibModal.open({
            scope: vm,
            animation: true,
            keyboard: true,
            ariaLabelledBy: 'modal-title',
            ariaDescribedBy: 'modal-body',
            controller: angular.noop,
            template: require('../../common/templates/product-tour/room-board-overview-modal.tpl.html')
        });
        modalInstance.result.then(angular.noop, angular.noop);
    };

    /**
     * Starts the tour by showing the first step
     */
    vm.startTour = function () {
        vm.tour.start();
    };

    /**
     * Returns wether or not the product tour is active or not
     *
     * @return {Boolean}
     */
    vm.isTourActive = function() {
        return (vm.tour && vm.tour.getStatus() === vm.tour.Status.ON);
    };

    vm.setupTour();
})

    .controller('PinImportCtrl', function PinImportCtrl($scope, $uibModalInstance, strukshurApiService, strukshurPinterestService) {
        var form = {};

        $scope.pins = {};
        $scope.selection = [];
        $scope.importing = false;
        $scope.boardViewing = null;
        $scope.importingError = false;

        $scope.cancel = function (imported) {
            $uibModalInstance.dismiss(imported);
        };

        $scope.loadMore = () => {
            if ($scope.loading) { return; }

            $scope.loading = true;
            strukshurPinterestService.boards.list({ sort: 'alphabetical', bookmark: $scope.pinterestBookmark }).$promise
                .then(res => {
                    $scope.boards.push(...res.data);
                    $scope.pinterestBookmark = res.bookmark;
                    $scope.loading = false;
                })
            ;
        };

        $scope.getPinsFromBoard = function (board_id) {
            $scope.selection = [];
            $scope.boardViewing = board_id;

            strukshurPinterestService.pins.list({ 'board_id': board_id }).$promise
                .then((res) => $scope.pins = res.data)
            ;
        };

        // check all
        $scope.selectAll = function () {
            angular.forEach($scope.pins, function (pin, key) {
                $scope.selection.push(pin.id);
            });
        };

        $scope.formImport = function() {
            //console.log($scope.selection);
            //console.log($scope.pins);
            $scope.importing = true;
            $scope.importingError = false;

            var savePins = [];
            angular.forEach($scope.pins, function (pin, key) {
                var idx = $scope.selection.indexOf(pin.id);

                if (idx > -1) {
                    var original_link = pin.link || '';
                    var title = pin.title || '';
                    var site_name = pin.domain || '';
                    var image_original_url = pin.image_large_url;

                    savePins.push({
                        id: pin.id,
                        url: image_original_url,
                        original_link: original_link,
                        site_name: site_name,
                        title: title,
                        note: pin.note
                    });
                }
            });

            console.log('final selection of pins to import');
            console.log(savePins);

            strukshurApiService.postRoomBoardImport.save({ 'room_id': $scope.selectedRoom.id, 'pins': savePins }, function(d) {
                console.log(d);
                $scope.importing = false;
                $scope.cancel(true); // close modal
            }, function(e){
                $scope.importingError = true;
                $scope.importing = false;
                console.log('error importing pins');
            });
        };

        $scope.toggleSelection = function toggleSelection(pinId) {
            var idx = $scope.selection.indexOf(pinId);

            // Is currently selected
            if (idx > -1) {
                $scope.selection.splice(idx, 1);
            } else {

                // Is newly selected
                $scope.selection.push(pinId);
            }
        };
    })

    .controller('RoomSyncPinsToProjectCtrl', function RoomSyncPinsToProjectCtrl($scope, $state, $uibModalInstance, room, strukshurApiService, strukshurUserService) {
        var vm = $scope;
        var form = {};

        vm.pins = {};
        vm.boards = [];
        vm.projects = [];
        vm.baseRooms = [];
        vm.selection = [];
        vm.syncing = false;
        vm.newRoomName = '';
        vm.listedPins = false;
        vm.syncingError = null;
        vm.selectedRoom = room;
        vm.selectedProject = null;
        vm.selectedProjectRoom = null;

        // Get the list of projects the user can choose from
        strukshurApiService.projectprojects.query({ page: 1, limit: 50 }).$promise
            .then(function (res) {
                vm.projects = res.projects;
            })
            .catch(function () {
                vm.syncingError = 'There was an error while trying to load the available projects.';
            })
        ;

        // List all available base rooms
        strukshurApiService.getRooms.query({}).$promise
            .then(function (res) {
                vm.baseRooms = res.rooms;
            })
        ;

        /**
         * Cancel modal
         */
        vm.cancel = function () {
            $uibModalInstance.dismiss();
        };

        /**
         * Called when a project is selected
         *
         * @param  {Object}  project  The selected project
         * @param  {Object}  $select  The ui-select object
         */
        vm.projectSelected = function(project, $select) {
            if (project === vm.selectedProject) {
                return;
            }

            vm.syncingError = null;
            vm.selectedProjectRoom = null;
            vm.selectedProject = project;

            // Retrieve the list of available inspiration boards
            strukshurApiService.projectRooms.list({ 'project_id': vm.selectedProject.id }).$promise
                .then(function (res) {
                    var boardOptions = res.rooms;

                    // Add option to create new project room
                    boardOptions.unshift({ id: 'new-board', name: '-- new inspiration board --' });

                    vm.boards = res.rooms;
                })
                .catch(function (res) {
                    if (res.status === 403) {
                        vm.syncingError = 'You don\'t have the necessary permission to list the project rooms.';
                    } else {
                        vm.syncingError = 'There was an error while loading the selected project inspiration boards.';
                    }
                })
            ;
        };

        /**
         * Called when a project room is selected
         *
         * @param  {Object}  projectRoom  The selected project room
         * @param  {Object}  $select      The ui-select object
         */
        vm.projectRoomSelected = function(projectRoom, $select) {
            vm.selectedProjectRoom = projectRoom;

            if (projectRoom.id === 'new-board') {
                let newBoard = document.querySelector('.new-board-name') as HTMLElement;
                newBoard.focus();
            }

            vm.getProjectPinsToSync();
        };

        /**
         * Called when a room type is selected
         *
         * @param  {Object}  room     The selected room type
         * @param  {Object}  $select  The ui-select object
         */
        vm.newBoardTypeSelected = function(room, $select) {
            vm.newBoardType = room;
        };

        /**
         * Retrieves the room pins to sync to the project inspiration board
         */
        vm.getProjectPinsToSync = function() {
            vm.pins = [];
            vm.selection = [];
            vm.listedPins = false;
            vm.syncingError = null;

            strukshurApiService.projectPins.listPinsToSync({ room_id: vm.selectedRoom.id, project_id: vm.selectedProject.id, project_room_id: vm.selectedProjectRoom.id }).$promise
                .then(function(res) {
                    vm.pins = res.pins;
                    vm.listedPins = true;
                })
                .catch(function (res) {
                    if (res.status === 403) {
                        vm.syncingError = 'You don\'t have the necessary permission to view the project pins.';
                    } else {
                        vm.syncingError = 'There was an error while loading the pins to sync.';
                    }
                })
            ;
        };

        /**
         * Select all pins
         */
        vm.selectAll = function() {
            angular.forEach(vm.pins, function(pin, key) {
                vm.selection.push(pin.id);
            });
        };

        /**
         * Toggles the currently selected pin
         *
         * @param  {string}  pinId  The pin id
         */
        vm.toggleSelection = function toggleSelection(pinId) {
            var idx = vm.selection.indexOf(pinId);

            // Is currently selected
            if (idx > -1) {
                vm.selection.splice(idx, 1);
            } else {
                vm.selection.push(pinId);
            }
        };

        /**
         * Imports the selected pins to the project's inspiration board
         */
        vm.formImport = function() {
            vm.syncingError = null;

            var savePins = [];
            angular.forEach(vm.pins, function(pin, key) {
                var idx = vm.selection.indexOf(pin.id);

                if (idx > -1) {
                    savePins.push(pin.id);
                }
            });

            // Handles creation of a new project room
            if (vm.selectedProjectRoom.id === 'new-board') {
                if (!vm.newBoardType || !vm.newBoardType.id) {
                    return vm.syncingError = 'You need to select a new board room type.';
                }

                if (!vm.newBoardName) {
                    return vm.syncingError = 'You need to input the new board name.';
                }
            }

            var data: any = {
                'project_id': vm.selectedProject.id,
                'project_room_id': vm.selectedProjectRoom.id,
                'pins': savePins,
            };

            if (vm.selectedProjectRoom.id === 'new-board') {
                data.new_project_room_name = vm.newBoardName;
                data.new_project_room_type = vm.newBoardType.id;
            }

            vm.syncing = true;
            strukshurApiService.projectPins.importFromRoom(data).$promise
                .then(function () {
                    vm.cancel();
                })
                .catch(function (res) {
                    if (res.status === 403) {
                        vm.syncingError = 'You don\'t have the necessary permission to add new pins to the project.';
                    } else {
                        vm.syncingError = 'There was an error trying to sync the pins with the select inspiration board.';
                    }
                })
                .finally(function () {
                    vm.syncing = false;
                })
            ;
        };
    })

    .controller('BlueprintCtrl', function BlueprintCtrl($scope, $stateParams, $state, $window, roomResolve, strukshurApiService, strukshurUserService) {
        var vm = $scope;

        // Force a reload if necessary, in order to properly clean window
        // message events
        if ($stateParams.forceReload === true) {
            $window.location.reload();
        }

        // Updates the model url path to the new folder after the move to Webpack
        if (roomResolve.blueprint) {
            roomResolve.blueprint = roomResolve.blueprint.replace(/\/assets\//g, '/public/');
        }

        vm.selectedRoom = null;
        vm.currentRoom = roomResolve;
        vm.roomName = vm.currentRoom.name;

        // List all available user rooms
        vm.rooms = [];
        strukshurApiService.userRooms.list({ house_id: strukshurUserService.getHouseId() }).$promise
            .then(function (res) {
                res.rooms.forEach(function (room) {
                    if (room.id === vm.currentRoom.id) {
                        vm.selectedRoom = room;
                    }
                });

                // Only display interior rooms as options to navigate to
                vm.rooms = res.rooms.filter(function (room) {
                    return (room.room.location === 'interior');
                });
            })
        ;

        /**
         * Navigates to the newly selected room
         */
        vm.changeRoom = function() {
            vm.roomLoaded = false;
            $state.go('blueprint', { userSlug: $stateParams.userSlug, roomId: vm.selectedRoom.id, roomSlug: vm.selectedRoom.slug, houseSlug: 'my-house', forceReload: true }, { reload: true });
        };

        /**
         * Goes to the current room board
         */
        vm.goToBoard = function () {
            $state.go('room', { userSlug: $stateParams.userSlug, houseSlug: 'my-house', roomId: vm.currentRoom.id, roomSlug: vm.currentRoom.slug });
        };
    })

    .controller('ProjectModalInstanceCtrl', function ProjectModalInstanceCtrl ($scope, $uibModal, $uibModalInstance, allowOwnerChange, CartService, Flash, organization, project, strukshurApiService, strukshurProjectService, strukshurOrganizationService, strukshurUserService, title) {
        var vm = $scope;

        vm.form = {};
        vm.title = title;
        vm.loading = false;
        vm.project = project;
        vm.roomError = false;
        vm.stateReg = /^[a-zA-Z]{2}$/i;
        vm.teamMembers = [];
        vm.ownerOptions = [];
        vm.organizations = [];
        vm.project_form = { open_bids: false };
        vm.allowOwnerChange = allowOwnerChange;
        vm.currentUser = strukshurUserService.getUserObj();

        /**
         * Startup logic for the controller
         */
        vm.init = function () {

            // Editing project
            if (vm.project && vm.project.id) {
                vm.project_form.projectTitle = vm.project.title;
                vm.project_form.address1 = vm.project.address1;
                vm.project_form.address2 = vm.project.address2;
                vm.project_form.zip = vm.project.zip;
                vm.project_form.city = vm.project.city;
                vm.project_form.open_bids = vm.project.openBid;
                vm.project_form.projectState = vm.project.state;
                vm.project_form.projectDetails = vm.project.details;

                // Load team members list to display project owner select widget
                strukshurApiService.projectTeamMembers.list({ project_id: vm.project.id }).$promise
                    .then(function (res) {
                        vm.teamMembers = res.members;

                        vm.teamMembers.forEach(function (member) {

                            // Set type in order to group it later on the view
                            member.type = 'user';

                            // Add all retrieved organizations as a possible owner option
                            vm.ownerOptions.push(member);
                        });

                        // Set the current project owner as the selected one in the widget
                        if (project.owner_type === 'user') {
                            vm.project_form.projectOwner = _find(vm.teamMembers, { id: project.owner_id });
                        }
                    })
                    .catch(angular.noop)
                ;
            } else {

                // Adds the received organization as an owner option and selects it by default
                if (organization) {

                    // Set type in order to group it later on the view
                    organization.type = 'organization';
                    vm.ownerOptions.push(organization);

                    // Marks it as the default option
                    vm.project_form.projectOwner = organization;
                } else {

                    // Add the current user as a possible project owner when creating new projects
                    // from the regular projects page
                    var userOption = {
                        type: 'user',
                        id: vm.currentUser.id,
                        fullName: vm.currentUser.fullName,
                        profileImage: vm.currentUser.profileImage,
                    };
                    vm.ownerOptions.push(userOption);

                    // Marks it as the default option
                    vm.project_form.projectOwner = userOption;
                }
            }

            // Returns list of organizations the user has an access to in order
            // to define as possible owner options
            if (allowOwnerChange) {
                strukshurOrganizationService.getUserOrganizations()
                    .then(function (res) {
                        vm.organizations = res.organizations;

                        vm.organizations.forEach(function (organization) {

                            // Set type in order to group it later on the view
                            organization.type = 'organization';

                            // Add all retrieved organizations as a possible owner option
                            vm.ownerOptions.push(organization);
                        });

                        // Set the current project owner as the selected one in the widget
                        if (project && project.owner_type === 'organization') {
                            vm.project_form.projectOwner = _find(vm.organizations, { id: project.owner_id });
                        }
                    })
                    .catch(angular.noop)
                ;
            }
        };

        /**
         * Closes the current modal
         */
        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        // vm.popOpenBids = function () {
        //     var modalInstance;
        //     if (vm.project_form.open_bids) {
        //         modalInstance = $uibModal.open({
        //             scope: vm,
        //             keyboard: true,
        //             animation: true,
        //             ariaLabelledBy: 'modal-title',
        //             ariaDescribedBy: 'modal-body',
        //             controller: 'ConfirmOpenBidsCtrl',
        //             template: require('../../common/templates/confirm-modal.tpl.html'),
        //             resolve: {
        //                 message: function () {
        //                     return '<div class="alert alert-warning" role="alert"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> This will <strong>add</strong> your project for public bidding on Strukshur Bid system. Are you sure you want to continue?</div>';
        //                 }
        //             }
        //         });
        //         modalInstance.result.then(function (selectedItem) {
        //             //confirmed
        //             vm.project_form.open_bids = true;
        //         }, function () {
        //             //cancel confirmation
        //             vm.project_form.open_bids = false;
        //         });
        //     } else {
        //         modalInstance = $uibModal.open({
        //             animation: true,
        //             keyboard: true,
        //             scope: vm,
        //             ariaLabelledBy: 'modal-title',
        //             ariaDescribedBy: 'modal-body',
        //             controller: 'ConfirmOpenBidsCtrl',
        //             template: require('../../common/templates/confirm-modal.tpl.html'),
        //             resolve: {
        //                 message: function () {
        //                     return '<div class="alert alert-warning" role="alert"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> This will <strong>remove</strong> your project for public bidding on Strukshur Bid system. Are you sure you want to continue?</div>';
        //                 }
        //             }
        //         });
        //         modalInstance.result.then(function (selectedItem) {
        //             //confirmed
        //             vm.project_form.open_bids = false;
        //         }, function () {
        //             //cancel confirmation
        //             vm.project_form.open_bids = true;
        //         });
        //     }
        // };

        vm.createProject = function (myform) {
            var promise, modalInstance;

            if (vm.loading) { return; }

            vm.loading = true;
            vm.errorMessage = false;
            if (myform.$valid && myform.projectCover.$viewValue && myform.projectCover.$viewValue.base64) {
                modalInstance = $uibModal.open({
                    scope: $scope,
                    keyboard: false,
                    animation: false,
                    backdrop: 'static',
                    ariaLabelledBy: 'modal-title',
                    ariaDescribedBy: 'modal-body',
                    controller: 'ProjectProgressModalCtrl',
                    template: require('../../common/templates/progress-modal.tpl.html'),
                    resolve: {}
                });
                modalInstance.result.then(angular.noop, angular.noop);
            }

            // Sets project owner info on the form if it exists
            if (vm.project_form.projectOwner) {
                myform.projectOwner = vm.project_form.projectOwner;
            }

            // Edit project
            if (vm.project && vm.project.id) {
                promise = strukshurProjectService.editProject(vm.project.id, myform);
            } else {
                promise = strukshurProjectService.createProject(myform);
            }

            promise
                .then(function (data) {
                    if (modalInstance && modalInstance.close) {
                        modalInstance.close();
                    }

                    vm.loading = false;
                    vm.roomError = false;
                    vm.errorMessage = false;
                    $uibModalInstance.close(data.project);
                })
                .catch(function (err) {
                    if (modalInstance && modalInstance.close) {
                        modalInstance.close();
                    }

                    vm.loading = false;
                    if (err && err.data && err.data.message) {
                        Flash.create('danger', err.data.message, 10000, { container: 'project-save-flash' }, true);
                    } else if (err && err.status === 403) {
                        Flash.create('danger', 'You don\'t have the necessary permission to save the project.', 10000, { container: 'project-save-flash' }, true);
                    } else {
                        Flash.create('danger', 'There was an error while saving the project.', 10000, { container: 'project-save-flash' }, true);
                    }
                })
            ;
        };

        vm.getCityState = function () {
            var d_cache;
            if (vm.project_form.zip && vm.project_form.zip.length === 5) {
                d_cache = CartService.getTaxes(vm.project_form.zip); // cache zip codes

                if (d_cache && d_cache.city && d_cache.state) {
                    vm.form.ProjectForm.zip.$invalid = false;
                    vm.project_form.city = d_cache.city;
                    vm.project_form.projectState = d_cache.state;
                } else {
                    strukshurApiService.taxes.query({zip: vm.project_form.zip}, function (d) {
                        if (d.tax) {
                            CartService.setTaxes(d.tax, vm.project_form.zip); //cache zip codes
                            vm.form.ProjectForm.zip.$invalid = false;
                            vm.project_form.city = d.tax.city;
                            vm.project_form.projectState = d.tax.state;
                        }
                        else {
                            vm.form.ProjectForm.zip.$invalid = true;
                            vm.project_form.city = '';
                            vm.project_form.projectState = '';
                        }
                    });
                }
            } else {
                //vm.form.ProjectForm.zip.$invalid = true;
                vm.project_form.city = '';
                vm.project_form.projectState = '';
            }
        };

        vm.loadEnd = function () {
            vm.loadingImage = false;
        };

        vm.afterValidate = function (eventObject, fileReader, file) {
            vm.loadingImage = false;
            if (!file || file.length === 0) {
                return;
            }

            if (vm.projectCover && !vm.projectCover.base64) {
                vm.pinFail = 'Invalid Image';
                vm.projectCover = null;
                return;
            }

            if (file[0].size > 150*1000*1000) {
                vm.pinFail = 'Image must be less than 150MB';
                vm.projectCover = null;
                return;
            }

            if (file[0].type.substr(0, 5) !== 'image') {
                vm.projectCover = null;
            } else {

                // Resize
                var dataUrlImage;
                var canvas, MAX_WIDTH, MAX_HEIGHT, width, height, ctx, img;
                MAX_WIDTH = 2048;
                MAX_HEIGHT = 2048;

                if (vm.projectCover && vm.projectCover.base64) {

                    // Compress image
                    img = document.createElement('img');
                    img.setAttribute('crossOrigin', 'anonymous');

                    img.onload = function (ev) {
                        var resize;
                        width = img.width;
                        height = img.height;

                        if (width > height) {
                            if (width > MAX_WIDTH) {
                                height *= MAX_WIDTH / width;
                                width = MAX_WIDTH;
                            }
                        } else {
                            if (height > MAX_HEIGHT) {
                                width *= MAX_HEIGHT / height;
                                height = MAX_HEIGHT;
                            }
                        }

                        // Resize only if needed
                        if (width !== img.width || height !== img.height) {
                            canvas = document.createElement('canvas');
                            canvas.width = width;
                            canvas.height = height;
                            ctx = canvas.getContext('2d');
                            ctx.drawImage(img, 0, 0, width, height);

                            canvas.setAttribute('crossOrigin', 'anonymous');
                            dataUrlImage = canvas.toDataURL(vm.projectCover.filetype);
                            vm.projectCover.base64 = dataUrlImage.substr(dataUrlImage.indexOf(',') + 1);
                            dataUrlImage = null;
                            canvas = null;
                            ctx = null;
                        }

                        //if iOS rotate if needed
                        if (isMobile.apple.device || isMobile.apple.ipod || isMobile.apple.phone || isMobile.apple.tablet) {
                            resize = document.createElement('img');
                            resize.setAttribute('crossOrigin', 'anonymous');

                            resize.onload = function (event) {
                                width = resize.width;
                                height = resize.height;
                                EXIF.getData(img, function () {
                                    var orientation = EXIF.getTag(this, "Orientation");
                                    canvas = document.createElement('canvas');
                                    ctx = canvas.getContext('2d');
                                    if (orientation && orientation > 4) {
                                        canvas.width = height;
                                        canvas.height = width;
                                    } else {
                                        canvas.width = width;
                                        canvas.height = height;
                                    }

                                    switch (orientation) {
                                        case 2:
                                            // horizontal flip
                                            ctx.translate(width, 0);ctx.scale(-1, 1);
                                            break;
                                        case 3:
                                            // 180° rotate left
                                            ctx.translate(width, height);ctx.rotate(Math.PI);
                                            break;
                                        case 4:
                                            // vertical flip
                                            ctx.translate(0, height);ctx.scale(1, -1);
                                            break;
                                        case 5:
                                            // vertical flip + 90 rotate right
                                            ctx.rotate(0.5 * Math.PI);ctx.scale(1, -1);
                                            break;
                                        case 6:
                                            // 90° rotate right
                                            ctx.rotate(0.5 * Math.PI);ctx.translate(0, -height);
                                            break;
                                        case 7:
                                            // horizontal flip + 90 rotate right
                                            ctx.rotate(0.5 * Math.PI);ctx.translate(width, -height);ctx.scale(-1, 1);
                                            break;
                                        case 8:
                                            // 90° rotate left
                                            ctx.rotate(-0.5 * Math.PI);ctx.translate(-width, 0);
                                            break;
                                        default:
                                            dataUrlImage = null;
                                            resize = null;
                                            img = null;
                                            canvas = null;
                                            ctx = null;
                                            return;
                                    }

                                    if (orientation && orientation > 4) {
                                        ctx.drawImage(resize, 0, 0);
                                    } else {
                                        ctx.drawImage(resize, 0, 0, width, height);
                                    }

                                    canvas.setAttribute('crossOrigin', 'anonymous');
                                    dataUrlImage = canvas.toDataURL('image/jpeg');
                                    vm.projectCover.filetype = 'image/jpeg';
                                    vm.projectCover.base64 = dataUrlImage.substr(dataUrlImage.indexOf(',')+1);
                                    dataUrlImage = null;
                                    resize = null;
                                    img = null;
                                    canvas = null;
                                    ctx = null;
                                    vm.$apply();
                                });
                            };
                            resize.src = 'data:' + vm.projectCover.filetype + ';base64,' + vm.projectCover.base64;
                        } else {
                            img = null;
                        }
                    };
                    img.src = 'data:' + vm.projectCover.filetype + ';base64,' + vm.projectCover.base64;
                }
            }
        };

        vm.loadStart = function () {
            vm.pinFail = false;
            vm.loadingImage = true;
        };

        vm.init();
    })

    .controller('PinNativeCtrl', function PinNativeCtrl($scope, $state, $uibModalInstance, roomService, strukshurApiService, strukshurUserService, $uibModal) {
        $scope.loading = false;
        $scope.productRoomsError = false;
        $scope.pinFail = false;
        $scope.upgadeLink = false;
        $scope.roomsFail = false;
        $scope.form = { pinForm: {} };
        $scope.pinImage = {};
        $scope.pinImageSize = 0;
        $scope.isMobile = isMobile;
        $scope.canPlayVideo = false;
        $scope.isVideo = false;
        $scope.loadingImage = false;
        $scope.URL = window.URL || window.webkitURL;

        $scope.pin = {
            title: '',
            description: '',
            image: '',
            room_ids: []
        };
        $scope.rooms = [];

        $scope.currentUser = {};
        strukshurUserService.getUser().then(function (d) {
            $scope.currentUser = (d.user ? d.user : d);
        });

        $scope.currentRoomSelected = function () {
            var i,tot;
            tot = $scope.rooms.length;
            for (i = 0; i < tot; i++) {
                if ($scope.rooms[i].id === $scope.selectedRoom.id) {
                    $scope.rooms[i].checked = true;
                    break;
                }
            }
        };

        $scope.cancel = function (imported) {
            $uibModalInstance.dismiss(imported);
        };

        $scope.loadEnd = function () {
            $scope.loadingImage = false;
        };

        $scope.afterValidate = function (eventObject, fileReader, file) {
            var videoPlayer = document.getElementById('videoPlayer') as HTMLVideoElement;
            $scope.loadingImage = false;
            if(!file || file.length===0){
                return;
            }
            $scope.isVideo = (file[0].type.substr(0,5) !== 'image');
            $scope.pinImageSize = file[0].size;

            if($scope.pinImage && !$scope.pinImage.base64){
                $scope.pinFail = 'Invalid '+($scope.isVideo?'Video':'Image');
                $scope.pinImage = null;
                return;
            }
            if(file[0].size>150*1000*1000){
                $scope.pinFail = ($scope.isVideo?'Video':'Image')+' must be less than 150MB';
                $scope.pinImage = null;
                return;
            }

            if ($scope.isVideo) {
                $scope.canPlayVideo = videoPlayer.canPlayType(file[0].type);
                if ($scope.canPlayVideo && file[0].size <= 25*1024*1024) {
                    videoPlayer.src = URL.createObjectURL(file[0]);
                } else {
                    videoPlayer.src = '';
                }
            } else if (file[0].type.substr(0, 5) !== 'video' && file[0].type.substr(0, 5) !== 'image') {
                $scope.pinImage = null;
            } else {

                //resize
                var dataUrlImage;
                var canvas, MAX_WIDTH, MAX_HEIGHT, width, height, ctx, img;
                MAX_WIDTH = 2048;
                MAX_HEIGHT = 2048;

                if ($scope.pinImage && $scope.pinImage.base64) {

                    //compress image
                    img = document.createElement('img');
                    img.setAttribute('crossOrigin', 'anonymous');

                    img.onload = function(ev){
                        var resize;
                        width = img.width;
                        height = img.height;

                        if (width > height) {
                            if (width > MAX_WIDTH) {
                                height *= MAX_WIDTH / width;
                                width = MAX_WIDTH;
                            }
                        } else {
                            if (height > MAX_HEIGHT) {
                                width *= MAX_HEIGHT / height;
                                height = MAX_HEIGHT;
                            }
                        }

                        // Resize only if needed
                        if (width !== img.width || height !== img.height) {
                            canvas = document.createElement('canvas');
                            canvas.width = width;
                            canvas.height = height;
                            ctx = canvas.getContext('2d');
                            ctx.drawImage(img, 0, 0, width, height);

                            canvas.setAttribute('crossOrigin', 'anonymous');

                            dataUrlImage = canvas.toDataURL($scope.pinImage.filetype);
                            $scope.pinImage.base64 = dataUrlImage.substr(dataUrlImage.indexOf(',')+1);
                            dataUrlImage = null;
                            canvas = null;
                            ctx = null;
                        }

                        //if iOS rotate if needed
                        if(isMobile.apple.device || isMobile.apple.ipod || isMobile.apple.phone || isMobile.apple.tablet){
                            resize = document.createElement('img');
                            resize.setAttribute('crossOrigin', 'anonymous');

                            resize.onload = function(event){
                                width = resize.width;
                                height = resize.height;
                                EXIF.getData(img, function(){
                                    var orientation = EXIF.getTag(this, "Orientation");
                                    canvas = document.createElement('canvas');
                                    ctx = canvas.getContext('2d');
                                    if(orientation && orientation>4){
                                        canvas.width = height;
                                        canvas.height = width;
                                    }
                                    else{
                                        canvas.width = width;
                                        canvas.height = height;
                                    }
                                    switch(orientation){
                                        case 2:
                                            // horizontal flip
                                            ctx.translate(width, 0);ctx.scale(-1, 1);
                                            break;
                                        case 3:
                                            // 180° rotate left
                                            ctx.translate(width, height);ctx.rotate(Math.PI);
                                            break;
                                        case 4:
                                            // vertical flip
                                            ctx.translate(0, height);ctx.scale(1, -1);
                                            break;
                                        case 5:
                                            // vertical flip + 90 rotate right
                                            ctx.rotate(0.5 * Math.PI);ctx.scale(1, -1);
                                            break;
                                        case 6:
                                            // 90° rotate right
                                            ctx.rotate(0.5 * Math.PI);ctx.translate(0, -height);
                                            break;
                                        case 7:
                                            // horizontal flip + 90 rotate right
                                            ctx.rotate(0.5 * Math.PI);ctx.translate(width, -height);ctx.scale(-1, 1);
                                            break;
                                        case 8:
                                            // 90° rotate left
                                            ctx.rotate(-0.5 * Math.PI);ctx.translate(-width, 0);
                                            break;
                                        default:
                                            dataUrlImage = null;
                                            resize = null;
                                            img = null;
                                            canvas = null;
                                            ctx = null;
                                            return;
                                    }
                                    if(orientation && orientation>4){
                                        ctx.drawImage(resize, 0, 0);
                                    }
                                    else{
                                        ctx.drawImage(resize, 0, 0, width, height);
                                    }


                                    canvas.setAttribute('crossOrigin', 'anonymous');
                                    dataUrlImage = canvas.toDataURL('image/jpeg');
                                    $scope.pinImage.filetype = 'image/jpeg';
                                    $scope.pinImage.base64 = dataUrlImage.substr(dataUrlImage.indexOf(',')+1);
                                    dataUrlImage = null;
                                    resize = null;
                                    img = null;
                                    canvas = null;
                                    ctx = null;
                                    $scope.$apply();
                                });
                            };
                            resize.src = 'data:' + $scope.pinImage.filetype + ';base64,' + $scope.pinImage.base64;
                        }
                        else{
                            img = null;
                        }
                    };
                    img.src = 'data:' + $scope.pinImage.filetype + ';base64,' + $scope.pinImage.base64;
                }
            }
        };

        $scope.loadStart = function () {
            $scope.pinFail = false;
            $scope.loadingImage = true;
            $scope.$apply(); //it wasn't working without this call
        };

        $scope.pinCreateAdd = function () {
            var i, tot,modalInstance;
            $scope.productRoomsError = false;
            $scope.form.pinForm.title.$invalid = false;
            $scope.pinFail = false;
            $scope.upgadeLink = false;
            $scope.roomsFail = false;
            $scope.pin.room_ids = [];
            tot = $scope.rooms.length;
            for (i = 0; i < tot; i++) {
                if ($scope.rooms[i].checked) {
                    $scope.pin.room_ids.push($scope.rooms[i].id);
                }
            }
            //validate
            if ($scope.pin.title === '') {
                $scope.productRoomsError = true;
                $scope.form.pinForm.title.$invalid = true;
            }
            if (!$scope.pinImage || !$scope.pinImage.base64) {
                $scope.pinFail = 'You must include an image';
            }
            if ($scope.pin.room_ids.length === 0) {
                $scope.roomsFail = true;
            }
            if ($scope.productRoomsError || $scope.pinFail || $scope.roomsFail) {
                return;
            }

            $scope.pin.room_ids = JSON.stringify($scope.pin.room_ids);
            $scope.pin.image = 'data:'+$scope.pinImage.filetype+';base64,'+$scope.pinImage.base64;

            modalInstance = $uibModal.open({
                animation: false,
                keyboard: false,
                backdrop: 'static',
                scope: $scope,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                controller: 'PinProgressModalCtrl',
                template: require('../../common/templates/progress-modal.tpl.html'),
                resolve: {}
            });
            modalInstance.result.then(function (data) {},function (data) {});

            $scope.loading = true;
            strukshurApiService.createPin.add($scope.pin, function (d) {
                modalInstance.close();
                $scope.loading = false;
                $scope.cancel(true);
            }, function (e) {
                modalInstance.close();
                $scope.loading = false;
                $scope.pinFail = (e.data.message ? e.data.message : '');
                if ($scope.pinFail.indexOf('enough storage') !== -1 && $scope.pinFail.indexOf('Upgrade') !== -1) {
                    $scope.upgadeLink = true;
                }
            });
        };

        $scope.upgradeSettingsAccount = function () {
            $state.go('account-settings', { 'active_tab': 'billing' });
        };

        // Room list
        $scope.rooms = roomService.getRooms();
        if ($scope.rooms.length === 0) {
            strukshurApiService.userRooms.list({ house_id: strukshurUserService.getHouseId() }).$promise
                .then(function (res) {
                    roomService.setRooms(res.rooms);
                    $scope.rooms = angular.copy(res.rooms);
                    $scope.currentRoomSelected();
                })
            ;
        } else {
            $scope.rooms = angular.copy($scope.rooms);
            $scope.currentRoomSelected();
        }
    })

    .controller('PinProgressModalCtrl', function PinProgressModalCtrl ($scope) {
        $scope.progress = 0;
        $scope.title = 'Uploading';
        $scope.$on('updateProgress', function (event, args) {
            if(args.name==='createPin.add'){
                $scope.progress = args.progress;
                if($scope.progress>=100){
                    $scope.title = 'Saving...';
                }
            }
        });
    })

    .controller('AuthenticatingPinterestModalCtrl', function AuthenticatingPinterestModalCtrl ($interval, $scope, $uibModalInstance, strukshurPinterestService, strukshurUserService) {
        var vm = $scope;

        vm.intervalPromise = null;

        /**
         * Controller initialization logic
         */
        vm.init = () => {
            vm.intervalPromise = $interval(vm.loadPinterestAccessToken, 3000);
        };

        /**
         * Cancels the modal
         */
        vm.cancel = () => {
            $uibModalInstance.dismiss();
        };

        /**
         * Loads access token info from the server
         */
        vm.loadPinterestAccessToken = () => {
            strukshurPinterestService.getAccessToken(true)
                .then((res) => {

                    // Check if we have received a token
                    if (res.data.access_token) {
                        $interval.cancel(vm.intervalPromise);
                        strukshurUserService.setPinterestAccessToken(res.data.access_token);
                        $uibModalInstance.close();
                    }
                })
            ;
        };

        vm.init();
    })
;
