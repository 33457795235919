import {
    clone as _clone,
    find as _find,
    pull as _pull,
    remove as _remove,
} from 'lodash';
import * as angular from 'angular';
import * as isMobile from 'ismobilejs';
import * as Highcharts from 'highcharts/highcharts-gantt';
import DraggablePoints from 'highcharts/modules/draggable-points';
import Exporting from 'highcharts/modules/exporting';
import Pathfinder from 'highcharts/modules/pathfinder';
import Stock from 'highcharts/modules/stock';
import * as $ from 'jquery';
import * as moment from 'moment';

// Inject Highcharts modules into the main instance
DraggablePoints(Highcharts);
Exporting(Highcharts);
Pathfinder(Highcharts);
Stock(Highcharts);

angular.module('strukshurApp.projects.tasks', [])

.config(function config($stateProvider, $uibModalProvider) {
    $uibModalProvider.options = { animation: true, backdrop: 'static', keyboard: false, ariaLabelledBy: 'modal-title', ariaDescribedBy: 'modal-body' };

    $stateProvider
        .state('projects-detail.tasks', {
            url: '/tasks?taskId',
            controller: 'ProjectDetailTasksCtrl',
            template: require('./project.tasks.tpl.html'),
            data: { pageTitle: 'Project / Tasks', class: 'projects projects-detail-tasks' }
        })
    ;
})

.controller('ProjectDetailTasksCtrl', function ProjectDetailTasksCtrl ($compile, $scope, $state, $stateParams, $timeout, $uibModal, $window, smoothScroll, strukshurApiService, strukshurUtilService, toastr) {
    var vm = $scope;

    // Check if the user can access the current page or not
    if (!vm.$parent.checkPermission('project_task_view')) {
        return $state.go('projects-detail.access-denied', { section: 'Tasks' });
    }

    vm.loading = true;
    vm.ganttData = [];
    vm.milestones = [];
    vm.currentUser = {};
    vm.ganttChart = null;
    vm.upgradeLink = false;
    vm.parseInt = parseInt;
    vm.errorMessage = null;
    vm.currentView = 'tasks';
    vm.hasSelectedTask = null;
    vm.availableOptions = [];
    vm.taskId = $stateParams.taskId;
    vm.teamMembers = [{ id: -1, fullName: 'Anyone' }];
    vm.filter = {
        title: '',
        milestone: null,
        assignee: null,
        showOpen: true,
        showInProgress: true,
        showCompleted: true,
        showAccepted: true
    };
    vm.showNewReminderForm = false;
    vm.newReminder = { option: 'never' };
    vm.reminderOptions = [
        { value: '15min', text: '15 minutes before' },
        { value: '30min', text: '30 minutes before' },
        { value: '1hour', text: '1 hour before' },
        { value: '2hour', text: '2 hours before' },
        { value: '1day', text: '1 day before' },
        { value: '2day', text: '2 days before' },
        { value: '1week', text: '1 week before' },
        { value: '30day', text: '30 days before' }
    ];

    /**
     * Controller init logic
     */
    vm.init = () => {

        // Updates current route info on the project menu scope
        vm.$parent.currentRoute = $state.current.name;

        // Retrieve list of project milestones to filter by
        strukshurApiService.projectMilestones.list({ project_id: vm.$parent.project.id }).$promise
            .then((res) => {
                vm.currentUser = vm.$parent.currentUser;
                vm.milestones = res.projectMilestones;

                vm.updateGanttData();
                vm.updatePageItems();

                // Open and move to the selected task if any was given as a param
                if (vm.milestones.length > 0 && vm.taskId) {
                    var taskExists = false,
                        parentMilestone = null;

                    vm.milestones.forEach((milestone) => {
                        milestone.opened = false;
                        milestone.tasks.forEach((task) => {
                            if (task.id == vm.taskId) {
                                taskExists = true;
                                parentMilestone = milestone;
                                vm.setActiveTask(milestone, task);
                            }
                        });
                    });

                    $timeout(() => {
                        if (taskExists) {

                            // Opens the current task's milestone panel
                            vm.milestones[0].opened = false;
                            parentMilestone.opened = true;

                            // Scrolls thw window to the milestone
                            var el = angular.element('#milestone-'+parentMilestone.id+'-active-task')[0];
                            smoothScroll(el, { offset: 20 });
                        } else {

                            // Opens the first panel if no task was found
                            vm.milestones[0].opened = true;

                            // Displays error message if the task doesn't exist anymore
                            toastr.error('The task does not exist or was removed from the project.', 'Error');
                        }
                    }, 50);
                } else {

                    // Opens the first panel if no task param was given
                    vm.milestones[0].opened = true;
                }
            })
            .catch((res) => {
                if (res.status === 403) {
                    vm.errorMessage = 'You don\'t have the necessary permission view the tasks.';
                } else {
                    vm.errorMessage = 'There was an error while loading the project tasks.';
                }
            })
            .finally(() => vm.loading = false)
        ;

        // Retrieve team members t
        strukshurApiService.projectTeamMembers.list({ project_id: vm.$parent.project.id }).$promise
            .then((res) => {
                res.members.forEach((member) => {
                    vm.teamMembers.push(member);
                });
            })
            .catch(angular.noop)
        ;
    };

    /**
     * Checks if the current user has the given permission on the project
     *
     * @param  {string}  permission  The permission key
     *
     * @return {boolean}
     */
    vm.checkPermission = function (permission) {
        return vm.$parent.checkPermission(permission);
    };

    /**
     * Returns the text label of the given task based on its status
     *
     * @return {string}
     */
    vm.getTaskStatusText = function (task) {
        if (task.status === 'O') {
            return 'Open';
        } else if (task.status === 'P') {
            return 'In Progress';
        } else if (task.status === 'C') {
            return 'Completed';
        } else if (task.status === 'A') {
            return 'Accepted';
        }

        return '';
    };

    /**
     * Returns all current tasks for the project
     *
     * @return {array}
     */
    vm.getAllTasks = function () {
        var tasks = [];

        vm.milestones.forEach(function (milestone) {
            milestone.tasks.forEach(function (task) {
                task.milestone = milestone.title;
                tasks.push(task);
            });
        });

        return tasks;
    };

    /**
     * Opens the modal for adding a new project milestone
     */
    vm.newMilestone = function () {
        var modalInstance = $uibModal.open({
            scope: vm,
            keyboard: true,
            controller: 'ProjectDetailNewMilestoneCtrl',
            template: require('../../../common/templates/projects.newMilestoneModal.tpl.html'),
            resolve: {
                project: vm.$parent.project
            }
        });

        modalInstance.result.then(

            // Resolved callback
            function (data) {
                data.milestone.openPanelOnInit = true;
                vm.milestones.push(data.milestone);
                vm.updatePageItems();
                vm.updateGanttData();

                // Scrolls to the newly created milestone
                $timeout(function () {
                    smoothScroll(document.getElementById('milestone-' + data.milestone.id), { offset: 80 });
                }, 50);
            },

            // Rejected callback
            angular.noop
        );
    };

    /**
     * Adds the milestone as a point to the gantt chart
     *
     * @param  {Object}  milestone  The milestone
     */
    vm.addMilestoneToChart = function (milestone) {
        var series = vm.ganttChart.series[0],
            startDate = new Date(),
            endDate = new Date();

        series.addPoint({
            id: 'milestone-' + milestone.id,
            type: 'milestone',
            name: milestone.title,
            title: '',
            pointWidth: 4,
            start: startDate.getTime(),
            end: endDate.getTime(),
            // Disable drag and drop for milestones
            dragDrop: {
                draggableX: false,
                draggableY: false,
            },
        });
    };

    /**
     * Wether the milestone has ben rendered on the chart or not
     *
     * @param  {object}  milestone  The milestone
     *
     * @return {boolean}
     */
    vm.isMilestoneRenderedOnChart = function (milestone) {
        if (vm.ganttChart) {
            var point = vm.ganttChart.get('milestone-' + milestone.id);
            if (point) {
                return true;
            } else {
                return false;
            }
        }

        return false;
    };

    /**
     * Opens the modal for editing a project milestone
     */
    vm.editMilestone = function (milestone) {
        var modalInstance = $uibModal.open({
            scope: vm,
            keyboard: true,
            controller: 'ProjectDetailEditMilestoneCtrl',
            template: require('../../../common/templates/projects.editMilestoneModal.tpl.html'),
            resolve: {
                milestone: milestone
            }
        });

        modalInstance.result.then(

            // Resolved callback
            function (data) {
                milestone.title = data.milestone.title;
                vm.updatePageItems();
                vm.updateGanttData();

                // Updates milestone point on the chart
                vm.updateMilestonePointData(milestone);

                // Updates cell actions for the milestone on the chart
                vm.prepareGanttColumnsActionsForMilestone(milestone);
            },

            // Rejected callback
            angular.noop
        );
    };

    /**
     * Opens modal to copy the received milestone to other projects
     *
     * @param  {Object}  milestone  The milestone to copy
     */
    vm.copyMilestone = function (milestone) {
        var modalInstance = $uibModal.open({
            scope: vm,
            keyboard: true,
            controller: 'ProjectDetailCopyMilestoneCtrl',
            template: require('../../../common/templates/projects.copyProjectMilestoneModal.tpl.html'),
            resolve: {
                project: function () { return vm.$parent.project; },
                milestone: function () { return milestone; },
            }
        });

        modalInstance.result.then(

            // Resolved callback
            function (data) {

                // Adds the milestone to the screen if it was copied to the same project
                if (data.project_id === vm.$parent.project.id) {
                    data.milestone.openPanelOnInit = true;
                    vm.milestones.push(data.milestone);
                    vm.updatePageItems();
                    vm.updateGanttData();

                    // Scrolls to the newly created milestone
                    $timeout(function () {
                        smoothScroll(document.getElementById('milestone-' + data.milestone.id), { offset: 80 });
                    }, 50);
                }
            },

            // Rejected callback
            angular.noop
        );
    };

    /**
     * Deletes the received milestone on the server
     *
     * @param  {object}  milestone  The project milestone
     */
    vm.deleteMilestone = function (milestone) {
        var $childScope = vm.$new();
        $childScope.milestone = milestone;
        $childScope.project = vm.$parent.project;
        $childScope.title = 'Delete milestone';
        $childScope.message = 'Are you sure you want to delete the milestone?';

        var modalInstance = $uibModal.open({
            keyboard: true,
            scope: $childScope,
            controller: 'ProjectDetailDeleteMilestoneCtrl',
            template: require('../../../common/templates/base.confirm-modal.tpl.html')
        });

        modalInstance.result.then(

            // Resolved callback
            function (data) {
                _pull(vm.milestones, milestone);
                vm.updatePageItems();
                vm.updateGanttData();

                // Remove milestone and related tasks from the chart if it has been rendered
                if (vm.ganttChart) {

                    // First we remove the tasks related to the milestone from the chart
                    milestone.tasks.forEach(function (task) {
                        var point = vm.ganttChart.get('task-' + task.id);
                        if (point) {
                            point.remove();
                        }
                    });

                    // Then we remove the remaining milestone point from the chart
                    var point = vm.ganttChart.get('milestone-' + milestone.id);
                    if (point) {
                        point.remove();
                    }
                }
            },

            // Rejected callback
            angular.noop
        );
    };

    /**
     * Returns the task identified by the given id
     *
     * @param  {integer}  id  The task id
     *
     * @return {object}
     */
    vm.getTaskById = function (id) {
        var found;

        vm.milestones.forEach(function (milestone) {
            if (milestone.tasks) {
                milestone.tasks.forEach(function (task) {
                    if (task.id == id) {
                        found = task;
                        return;
                    }
                });
            }

            // already found the task so no need to loop further over the list
            if (found) { return; }
        });

        return found;
    };

    /**
     * Opens the modal for adding a new project task
     *
     * @param  {object}  milestone  The milestone to add the task to
     */
    vm.newTask = function (milestone) {
        if (!vm.checkPermission('project_task_add')) { return; }

        var modalInstance = $uibModal.open({
            scope: vm,
            keyboard: true,
            controller: 'ProjectDetailNewTaskCtrl',
            template: require('../../../common/templates/projects.newTaskModal.tpl.html'),
            resolve: {
                project: vm.$parent.project,
                milestone: milestone,
                tasks: vm.getAllTasks
            }
        });

        modalInstance.result.then(

            // Resolved callback
            function (data) {
                if (!data.task.comments) {
                    data.task.comments = [];
                }
                if (!data.task.files) {
                    data.task.files = [];
                }
                if (!data.task.predecessors) {
                    data.task.predecessors = [];
                }
                if (!data.task.successors) {
                    data.task.successors = [];
                }

                // Adds the new task to the selected milestone
                milestone.tasks.push(data.task);

                // Updates predecessor's task successors if any exists
                if (data.task.predecessors) {
                    vm.updateTaskSuccessors(data.task);
                }

                vm.updateGanttData();

                // Adds the new task as a point on the gantt chart if it was
                // already rendered
                if (data.task.startDate && data.task.endDate && vm.ganttChart) {

                    // Renders milestone on the chart if it's not there yet
                    if (!vm.isMilestoneRenderedOnChart(milestone)) {
                        vm.addMilestoneToChart(milestone);
                    }

                    // Renders task on the gantt chart
                    vm.addTaskToChart(milestone, data.task);
                }
            },

            // Rejected callback
            angular.noop
        );
    };

    /**
     * Opens the model for adding a new project task from the gantt chart
     */
    vm.newTaskFromGantt = function () {
        vm.hasSelectedTask = false;

        var modalInstance = $uibModal.open({
            scope: vm,
            keyboard: true,
            controller: 'ProjectDetailNewTaskCtrl',
            template: require('../../../common/templates/projects.newTaskModal.tpl.html'),
            resolve: {
                project: vm.$parent.project,
                milestone: null,
                tasks: vm.getAllTasks
            }
        });

        modalInstance.result.then(

            // Resolved callback
            function (data) {
                if (!data.task.comments) {
                    data.task.comments = [];
                }
                if (!data.task.files) {
                    data.task.files = [];
                }
                if (!data.task.predecessors) {
                    data.task.predecessors = [];
                }
                if (!data.task.successors) {
                    data.task.successors = [];
                }

                // Adds the new task to the selected milestone
                data.milestone.tasks.push(data.task);

                // Updates predecessor's task successors if any exists
                if (data.task.predecessors) {
                    vm.updateTaskSuccessors(data.task);
                }

                vm.updateGanttData();

                // Adds the new task as a point on the gantt chart
                if (data.task.startDate && data.task.endDate) {
                    vm.addTaskToChart(data.milestone, data.task);
                    vm.redrawGanttChart();
                    vm.updateMinAndMaxChartDates();
                }
            },

            // Rejected callback
            angular.noop
        );
    };

    /**
     * Adds the task as a point to the gantt chart
     *
     * @param  {Object}  milestone  The milestone
     * @param  {Object}  task       The task
     */
    vm.addTaskToChart = function (milestone, task) {
        var series = vm.ganttChart.series[0], undef,
            startDate = new Date(task.startDate * 1000),
            endDate = new Date(task.endDate * 1000);

        var deps = [];
        if (task.predecessors && task.predecessors.length) {
            task.predecessors.forEach(function (dep) {
                deps.push('task-' + dep);
            });
        }

        series.addPoint({
            id: 'task-' + task.id,
            type: 'task',
            name: task.title,
            title: task.title,
            parent: 'milestone-' + milestone.id,
            start: startDate.getTime(),
            end: endDate.getTime(),
            assignee: task.assignedToName,
            assigneeId: task.assignedToId,
            dependency: deps,
        });

        // Updates milestone point on the chart after new task insertion
        vm.updateMilestonePointData(milestone);
    };

    /**
     * Sets the given task as the active task for the milestone
     *
     * @param  {object}  milestone  The milestone
     * @param  {object}  task       The task
     */
    vm.setActiveTask = function (milestone, task) {
        if (milestone.activeTask !== task) {

            // Sets the given task as the active one in the panel
            milestone.activeTask = task;
            milestone.activeTask.currentFile = null;
            milestone.activeTask.uploading = false;
            milestone.activeTask.showNewReminderForm = false;
        }
    };

    /**
     * Marks the received comment as read
     *
     * @param  {object}  comment  The comment
     */
    vm.markCommentAsRead = function (task, comment) {
        if (!comment.readByUser) {
            strukshurApiService.projectTaskComment.read({ comment_id: comment.id }).$promise
                .then(function () {
                    comment.readByUser = true;
                    vm.checkIfUnreadCommentExists(task);
                })
                .catch(function (res) {
                    console.log(res);
                })
            ;
        }
    };

    /**
     * Checks if any of the comments for the given task are unread
     *
     * @param  {object}  task  The task to check
     */
    vm.checkIfUnreadCommentExists = function (task) {
        var hasUnreadComments = false;
        task.comments.forEach(function (comment) {
            if (comment.readByUser === false) {
                hasUnreadComments = true;
            }
        });
        task.hasUnreadComment = hasUnreadComments;
    };

    /**
     * Opens the modal for editing a project task
     *
     * @param  {object}  milestone  The task's milestone
     * @param  {object}  task       The task to edit
     * @param  {string}  autofocus  The field to autofocus on when opening the modal
     */
    vm.editTask = function (milestone, task, autofocus) {
        if (!vm.checkPermission('project_task_edit')) { return; }

        var autofocusField = autofocus || 'title';

        var modalInstance = $uibModal.open({
            scope: vm,
            keyboard: true,
            controller: 'ProjectDetailEditTaskCtrl',
            template: require('../../../common/templates/projects.editTaskModal.tpl.html'),
            resolve: {
                project: vm.$parent.project,
                task: task,
                tasks: vm.getAllTasks,
                autofocusField: function () { return autofocusField; }
            }
        });

        modalInstance.result.then(

            // Resolved callback
            function (data) {
                var startDateChanged = ((!task.startDate && data.task.startDate) || (task.startDate && !data.task.startDate) || ((task.startDate || data.task.startDate) && task.startDate !== data.task.startDate)),
                    endDateChanged = ((!task.endDate && data.task.endDate) || (task.endDate && !data.task.endDate) || ((task.endDate || data.task.endDate) && task.endDate !== data.task.endDate)),
                    previousEndDate = task.endDate;

                task.title = data.task.title;
                task.description = data.task.description;
                task.startDate = data.task.startDate;
                task.endDate = data.task.endDate;
                task.status = data.task.status;
                task.progress = data.task.progress;
                task.assignees = data.task.assignees;
                task.reminders = data.task.reminders;
                task.predecessors = data.task.predecessors;

                // Updates predecessor's task successors if any exists
                if (task.predecessors) {
                    vm.updateTaskSuccessors(task);
                }

                vm.updateGanttData();
                vm.updateTaskPointData(task);

                // Updates milestone point on the chart if any date has changed
                if (startDateChanged || endDateChanged) {
                    vm.updateMilestonePointData(milestone);

                    // Updates task dependencies accordingly if the current task
                    // end date was changed and is higher than the previous date
                    if (endDateChanged && task.endDate > previousEndDate && task.successors) {

                        // Gets the difference between the old and new end dates
                        var amountToMove = moment.duration(moment(task.endDate * 1000).diff(moment(previousEndDate * 1000)));

                        task.successors.forEach(function (successorId) {
                            var successor = vm.getTaskById(successorId);
                            if (successor) {
                                vm.moveTaskDates(milestone, successor, amountToMove, true);
                            }
                        });
                    }
                }

                // Updates cell actions for the task on the chart
                vm.prepareGanttColumnsActionsForTask(task);
            },

            // Rejected callback
            angular.noop
        );

        return modalInstance;
    };

    /**
     * Confirms deletion of the selected task on the gantt chart
     */
    vm.editTaskFromGantt = function () {
        var selected = vm.ganttChart.series[0].chart.getSelectedPoints()[0];

        if (selected && selected.type === 'task') {
            var taskId = parseInt(selected.id.split('-')[1], 10),
                milestoneId = parseInt(selected.parent.split('-')[1], 10),
                milestone = _find(vm.milestones, { id: milestoneId }),
                task = vm.getTaskById(taskId);

            // Opens modal for editing the task
            vm.editTask(milestone, task);
        }
    };

    /**
     * Opens modal to copy the received task to other projects
     *
     * @param  {Object}  task  The task to copy
     */
    vm.copyTask = function (task) {
        var modalInstance = $uibModal.open({
            scope: vm,
            keyboard: true,
            controller: 'ProjectDetailCopyTaskCtrl',
            template: require('../../../common/templates/projects.copyProjectTaskModal.tpl.html'),
            resolve: {
                project: function () { return vm.$parent.project; },
                milestones: function () { return vm.milestones; },
                task: function () { return task; },
            }
        });

        modalInstance.result.then(

            // Resolved callback
            function (data) {

                // Adds the copied task to the milestone if it's a part of the
                // currnet project
                vm.milestones.forEach(function (milestone) {
                    if (milestone.id === data.milestone_id) {
                        milestone.tasks.push(data.task);
                        vm.updateGanttData();
                    }
                });
            },

            // Rejected callback
            angular.noop
        );
    };

    /**
     * Updates the point data on the gantt chart for the given milestone
     *
     * @param  {object}  milestone  The milestone
     */
    vm.updateMilestonePointData = function (milestone) {

        // Only tries to update the milestone if the gantt chart has been rendered
        if (vm.ganttData && vm.ganttChart) {
            var point = vm.ganttChart.get('milestone-' + milestone.id);
            if (point) {
                var data: any = { name: milestone.title };

                // Updates milestone start and end dates if necessary
                if (milestone.tasks.length > 0) {

                    // Set initial dates to use as a base to compare with the rest
                    // of the milestone's tasks
                    var startDate = new Date(milestone.tasks[0].startDate * 1000),
                        endDate = new Date(milestone.tasks[0].endDate * 1000);

                    // Cycles through all tasks to define start and end dates
                    // for the milestone
                    milestone.tasks.forEach(function (task) {
                        var taskStartDate = new Date(task.startDate * 1000),
                            taskEndDate = new Date(task.endDate * 1000);

                        if (moment(taskStartDate).isSameOrBefore(startDate)) {
                            startDate = taskStartDate;
                        }

                        if (moment(taskEndDate).isSameOrAfter(endDate)) {
                            endDate = taskEndDate;
                        }
                    });

                    data.start = startDate.getTime();
                    data.end = endDate.getTime();
                }

                point.update(data);
            }
        }
    };

    /**
     * Updates the data of a task point on the gantt chart
     *
     * @param  {object}  task   The task
     */
    vm.updateTaskPointData = function(task) {

        // Do not update it if the chart hasn't been rendered yet
        if (!vm.ganttChart) { return; }

        var point = vm.ganttChart.get('task-' + task.id);
        if (point) {
            var startDate = new Date(task.startDate * 1000),
                endDate = new Date(task.endDate * 1000);

            var deps = [];
            if (task.predecessors && task.predecessors.length) {
                task.predecessors.forEach(function (dep) {
                    deps.push('task-' + dep);
                });
            }

            point.update({
                name: task.title,
                title: task.title,
                start: startDate.getTime(),
                end: endDate.getTime(),
                status: vm.getTaskStatusText(task),
                completed: { amount: task.progress / 100 },
                assignees: task.assignees,
                dependency: deps,
            });

            // Deselect current task after edit
            point.select(false, false);
        }
    };

    /**
     * Updates the successors of the given task based on its predecessor info
     *
     * @param  {object}  task  The task
     */
    vm.updateTaskSuccessors = function (task) {

        // First we remove the current task as a successor from all tasks
        vm.milestones.forEach(function (milestone) {
            milestone.tasks.forEach(function (ltask) {
                _pull(ltask.successors, task.id);
            });
        });

        // And then we re-add it to all predecessors that were selected on this task
        if (task.predecessors) {
            task.predecessors.forEach(function (predecessorId) {
                var predecessor = vm.getTaskById(predecessorId);
                if (predecessor) {
                    predecessor.successors.push(task.id);
                }
            });
        }
    };

    /**
     * Moves the received task's dates by the given amount
     *
     * @param  {object}   milestone       The task's milestone
     * @param  {object}   task            The task
     * @param  {object}   amountToMove    The amount to move the dates
     * @param  {boolean}  moveSuccessors  Wether to also move successors or not
     */
    vm.moveTaskDates = function (milestone, task, amountToMove, moveSuccessors) {

        // Moves the task dates by the given amount
        task.startDate = moment(task.startDate * 1000).add(amountToMove).unix();
        task.endDate = moment(task.endDate * 1000).add(amountToMove).unix();

        // Updates the task point on the gantt chart accordingly
        vm.updateTaskPointData(task);

        // Move any existing task successors
        if (moveSuccessors && task.successors) {
            task.successors.forEach(function (successorId) {
                var successor = vm.getTaskById(successorId);
                if (successor) {
                    vm.moveTaskDates(milestone, successor, amountToMove, moveSuccessors);
                }
            });
        }
    };

    /**
     * Deletes the received task on the server
     *
     * @param  {object}   milestone    The milestone
     * @param  {object}   task         The task
     * @param  {boolean}  redrawChart  Wether to redraw the gantt chart or not
     */
    vm.deleteTask = function(milestone, task, redrawChart) {
        var $childScope = vm.$new();
        $childScope.task = task;
        $childScope.milestone = milestone;
        $childScope.project = vm.$parent.project;
        $childScope.title = 'Delete task';
        $childScope.message = 'Are you sure you want to delete the task?';

        var modalInstance = $uibModal.open({
            keyboard: true,
            scope: $childScope,
            controller: 'ProjectDetailDeleteTaskCtrl',
            template: require('../../../common/templates/projects.deleteTaskConfirmationModal.tpl.html')
        });

        modalInstance.result.then(

            // Resolved callback
            function (data) {
                // var i1, i2, i3, tot1, tot2, tot3;
                if (milestone.activeTask === task) {
                    milestone.activeTask = null;
                }

                // Remove task from the milestone
                _pull(milestone.tasks, task);
                vm.updateGanttData();

                if (redrawChart === true) {
                    vm.redrawGanttChart();
                }
            },

            // Rejected callback
            angular.noop
        );
    };

    /**
     * Confirms deletion of the selected task on the gantt chart
     */
    vm.deleteTaskFromGantt = function () {
        var selected = vm.ganttChart.series[0].chart.getSelectedPoints()[0];

        if (selected && selected.type === 'task') {
            var taskId = parseInt(selected.id.split('-')[1], 10),
                milestoneId = parseInt(selected.parent.split('-')[1], 10),
                milestone = _find(vm.milestones, { id: milestoneId }),
                task = vm.getTaskById(taskId);

            // Opens delete task modal
            vm.deleteTask(milestone, task, true);
        }
    };

    /**
     * Filter the given task according to the user selected filters
     */
    vm.taskFilter = function (milestone) {
        return function (task) {
            if (

                // Filter by task title
                (vm.filter.title == null || task.title.toLowerCase().includes(vm.filter.title.toLowerCase())) &&

                // Filter by assignee
                (vm.filter.assignee == null || vm.filter.assignee.id === -1 || _find(task.assignees, { id: vm.filter.assignee.id })) &&

                // Filter by status
                (
                    // first we check if any of the status is on
                    (vm.filter.showOpen === true && task.status === 'O') ||
                    (vm.filter.showInProgress === true && task.status === 'P') ||
                    (vm.filter.showCompleted === true && task.status === 'C') ||
                    (vm.filter.showAccepted === true && task.status === 'A') ||

                    // Then we return true if all filters are off
                    (
                        vm.filter.showOpen === false && vm.filter.showInProgress === false &&
                        vm.filter.showCompleted === false && vm.filter.showAccepted === false
                    )
                )
            ) {
                return true;
            }

            // Set active task as null if necessary
            if (milestone.activeTask && milestone.activeTask.id === task.id) {
                milestone.activeTask = null;
            }

            return false;
        };
    };

    /**
     * Filter the given milestone according to the user selected filter
     */
    vm.milestoneFilter = function () {
        return function (milestone) {
            return (vm.filter.milestone == null || milestone.id == vm.filter.milestone.id);
        };
    };

    /**
     * Shows the task list view
     */
    vm.showTaskList = function () {
        vm.currentView = 'tasks';
    };

    /**
     * Updates the gantt chart data based on the current milestones data
     */
    vm.updateGanttData = function () {
        var data = [], yAxisCounter = 0, minChartDate, maxChartDate;

        vm.milestones.forEach(function (milestone) {
            var parent = {
                y: yAxisCounter,
                id: 'milestone-' + milestone.id,
                type: 'milestone',
                name: milestone.title,
                title: '',
                pointWidth: 4,
                totalTasksCount: milestone.tasks.length,
                openTasksCount: 0,
                acceptedTasksCount: 0,
                completedTasksCount: 0,
                inProgressTasksCount: 0,
                // Disable drag and drop for milestones
                dragDrop: {
                    draggableX: false,
                    draggableY: false,
                },
            };

            data.push(parent);
            yAxisCounter++;

            // Initialise min and max milestone dates with the first task info
            var minMilestoneDate, maxMilestoneDate;
            if (milestone.tasks.length > 0) {
                minMilestoneDate = new Date(milestone.tasks[0].startDate * 1000);
                maxMilestoneDate = new Date(milestone.tasks[0].endDate * 1000);
            }

            milestone.tasks.forEach(function (task) {
                var deps = [];
                if (task.predecessors && task.predecessors.length) {
                    task.predecessors.forEach(function (dep) {
                        deps.push('task-' + dep);
                    });
                }

                // We don't add tasks that don't have a start and end date to the
                // gantt chart, since the component freezes when trying to handle them
                if (task.endDate && task.startDate) {
                    var startDate = new Date(task.startDate * 1000);
                    var endDate = new Date(task.endDate * 1000);

                    if (moment(startDate).isBefore(minMilestoneDate)) {
                        minMilestoneDate = startDate;
                    }

                    if (moment(endDate).isAfter(maxMilestoneDate)) {
                        maxMilestoneDate = endDate;
                    }

                    var taskData: any = {
                        y: yAxisCounter,
                        id: 'task-' + task.id,
                        type: 'task',
                        name: task.title,
                        title: task.title,
                        parent: 'milestone-' + milestone.id,
                        start: startDate.getTime(),
                        end: endDate.getTime(),
                        assignees: task.assignees,
                        status: vm.getTaskStatusText(task),
                        dependency: (deps.length > 0) ? deps : null,
                    };

                    if (task.progress) {
                        taskData.completed = { amount: task.progress / 100 };
                    }

                    if (task.status === 'O') {
                        parent.openTasksCount += 1;
                    } else if (task.status === 'P') {
                        parent.inProgressTasksCount += 1;
                    } else if (task.status === 'C') {
                        parent.completedTasksCount += 1;
                    } else if (task.status === 'A') {
                        parent.acceptedTasksCount += 1;
                    }

                    data.push(taskData);
                    yAxisCounter++;
                }
            });

            if (minMilestoneDate || maxMilestoneDate) {

                // Handles min and max dates for the entire chart
                if (!minChartDate) {
                    minChartDate = minMilestoneDate.getTime();
                } else if (moment(minMilestoneDate).isBefore(minChartDate)) {
                    minChartDate = minMilestoneDate.getTime();
                }
                if (!maxChartDate) {
                    maxChartDate = maxMilestoneDate.getTime();
                } else if (moment(maxMilestoneDate).isAfter(maxChartDate)) {
                    maxChartDate = maxMilestoneDate.getTime();
                }
            }
        });

        vm.ganttData = data;
        vm.minChartDate = moment(minChartDate).subtract(14, 'd').toDate().getTime();
        vm.maxChartDate = moment(maxChartDate).add(14, 'd').toDate().getTime();
    };

    /**
     * Updates the min and max dates on the chart based on its current data
     */
    vm.updateMinAndMaxChartDates = function () {
        var minChartDate, maxChartDate;

        vm.milestones.forEach(function (milestone) {

            // Initialise min and max milestone dates with the first task info
            var minMilestoneDate, maxMilestoneDate;
            if (milestone.tasks.length > 0) {
                minMilestoneDate = new Date(milestone.tasks[0].startDate * 1000);
                maxMilestoneDate = new Date(milestone.tasks[0].endDate * 1000);
            }

            if (minMilestoneDate || maxMilestoneDate) {

                // Handles min and max dates for the entire chart
                if (!minChartDate) {
                    minChartDate = minMilestoneDate.getTime();
                } else if (moment(minMilestoneDate).isBefore(minChartDate)) {
                    minChartDate = minMilestoneDate.getTime();
                }
                if (!maxChartDate) {
                    maxChartDate = maxMilestoneDate.getTime();
                } else if (moment(maxMilestoneDate).isAfter(maxChartDate)) {
                    maxChartDate = maxMilestoneDate.getTime();
                }
            }
        });

        vm.minChartDate = moment(minChartDate).subtract(14, 'd').toDate().getTime();
        vm.maxChartDate = moment(maxChartDate).add(14, 'd').toDate().getTime();
    };

    /**
     * Disables drag and drop actions for the given gantt point
     *
     * @param  {object}  point  The point
     */
    vm.disableTaskPointMove = function (point) {
        point.update({ dragDrop: { draggableX: false }, disabled: true });
        angular.element(point.graphic.element).addClass('disabled');
    };

    /**
     * Enables drag and drop actions for the given gantt point
     *
     * @param  {object}  point  The point
     */
    vm.enableTaskPointMove = function (point) {
        point.update({ dragDrop: { draggableX: true }, disabled: false });
        angular.element(point.graphic.element).removeClass('disabled');
    };

    /**
     * Disables and moves all successors for the given task
     *
     * @param  {object}   milestone         The target task
     * @param  {object}   task              The target task
     * @param  {object}   amountToMove      The amount to move the successors dates
     * @param  {boolean}  initMoveTracking  Wether to reset moved track property on all deps before the other actions
     */
    vm.moveAndDisableTaskSuccessorsPoints = function (milestone, task, amountToMove, initMoveTracking) {
        if (initMoveTracking) {
            vm.initTaskMovedTracking(task);
        }

        if (task.successors) {
            task.successors.forEach(function (successorId) {
                var successor = vm.getTaskById(successorId);
                if (successor && !successor.alreadyMoved) {
                    var point = vm.ganttChart.get('task-' + successor.id);

                    // Disables the successor task point on the chart
                    vm.disableTaskPointMove(point);

                    // Moves the successor task's dates
                    if (amountToMove) {
                        successor.startDate = moment(successor.startDate * 1000).add(amountToMove).unix();
                        successor.endDate = moment(successor.endDate * 1000).add(amountToMove).unix();
                        vm.updateTaskPointData(successor);
                    }

                    // Marks the current task as already moved so we don't move
                    // it more than once
                    successor.alreadyMoved = true;

                    // Recurring call to cover the entire successor's tree
                    vm.moveAndDisableTaskSuccessorsPoints(milestone, successor, amountToMove, false);
                }
            });
        }
    };

    /**
     * Initializes prop to track if a dep task has already being moved on the same update action
     *
     * @param  {object} task The parent task
     */
    vm.initTaskMovedTracking = function (task) {
        task.alreadyMoved = false;
        if (task.successors) {
            task.successors.forEach(function (successorId) {
                var successor = vm.getTaskById(successorId);
                if (successor) {
                    vm.initTaskMovedTracking(successor);
                }
            });
        }
    };

    /**
     * Enables all successors the given task has and optionally moves their dates back
     *
     * @param  {object}  task          The target task
     * @param  {object}  amountToMove  The amount to move the successors dates
     */
    vm.enableTaskSuccessorsPoints = function (milestone, task, amountToMove) {
        if (task.successors) {
            task.successors.forEach(function (successorId) {
                var successor = vm.getTaskById(successorId);
                if (successor) {
                    var point = vm.ganttChart.get('task-' + successor.id);

                    // Disables the successor task point on the chart
                    vm.enableTaskPointMove(point);

                    // Moves back the successor task's dates
                    if (amountToMove) {
                        successor.startDate = moment(successor.startDate * 1000).subtract(amountToMove).unix();
                        successor.endDate = moment(successor.endDate * 1000).subtract(amountToMove).unix();
                        vm.updateTaskPointData(successor);
                    }

                    // Recurring call to cover the entire successor's tree
                    vm.enableTaskSuccessorsPoints(milestone, successor, amountToMove);
                }
            });
        }
    };

    /**
     * Event triggered when the user selected to print the chart
     *
     * @param {Event}  evt  The event object
     */
    vm.onBeforeChartPrinted = function (evt) {
        vm.$parent.stickyMenu.destroy();
    };

    /**
     * Event triggered after the chart was printed
     *
     * @param {Event}  evt  The event object
     */
    vm.onAfterChartPrinted = function (evt) {
        vm.$parent.stickyMenu.draw();
        vm.redrawGanttChart();
    };

    /**
     * Event triggered when a point is moused over on the gantt chart
     *
     * @param {Event}  evt  The event object
     */
    vm.onTaskMouseOver = function (evt) {
        if (evt.target && evt.target.type === 'milestone') {
            return false;
        }
    };

    /**
     * Event triggered when a point is selected on the gantt chart
     *
     * @param {Event}  evt  The event object
     */
    vm.onTaskSelected = function (evt) {
        if (evt.target && evt.target.type === 'milestone') {
            return false;
        }

        // Run in a timeout to allow the select to properly update
        $timeout(function () {
            vm.hasSelectedTask = (vm.ganttChart.series[0].chart.getSelectedPoints().length > 0);
        }, 10);
    };

    /**
     * Event triggered when a point is unselected on the gantt chart
     *
     * @param {Event}  evt  The event object
     */
    vm.onTaskUnselected = function (evt) {
        vm.hasSelectedTask = false;
    };

    /**
     * Custom event triggered when a point is double clicked on the gantt chart
     *
     * @param  {Event}  evt    The event object
     * @param  {Point}  point  The point clicked
     */
    vm.onTaskDoubleClicked = function (evt, point) {

        // Returns early if the user does not have the necessary permission
        if (!vm.checkPermission('project_task_edit')) { return; }

        point.select(true, false);
        vm.editTaskFromGantt();
    };

    /**
     * Event triggered when a point is removed from the gantt chart
     *
     * @param {Event}  evt  The event object
     */
    vm.onTaskRemoved = function (evt) {
        vm.hasSelectedTask = false;
    };

    /**
     * Event triggered  when a point is dropped after a drag action from the chart
     *
     * @param  {Event} evt The event object
     *
     * @return {boolean}
     */
    vm.onTaskPointDrop = function (evt) {
        var amountToMove = null,
            oldStart = evt.target.start,
            oldEnd = evt.target.end,
            newStart = evt.newPoint.start,
            newEnd = evt.newPoint.end,
            point = evt.target,
            taskId = parseInt(point.id.split('-')[1], 10),
            milestoneId = parseInt(point.parent.split('-')[1], 10),
            milestone = _find(vm.milestones, { id: milestoneId }),
            task = vm.getTaskById(taskId);

        // Sets data to send back to the server
        var data: any = { task_id: taskId };
        if (newStart) {
            data.start_date = moment.utc(newStart).format('YYYY-MM-DD HH:mm');
        }
        if (newEnd) {
            data.end_date = moment.utc(newEnd).format('YYYY-MM-DD HH:mm');

            if (newEnd > oldEnd) {
                amountToMove = moment.duration(moment(newEnd).diff(moment(oldEnd)));
            }
        }

        // Disables any further drag and drop action while the point is being saved
        vm.disableTaskPointMove(point);

        // Also disables and updates task's successors points on the gantt chart
        vm.moveAndDisableTaskSuccessorsPoints(milestone, task, amountToMove, true);

        // Saves new start and end dates for the task on the backend
        strukshurApiService.projectTask.moveDates(data).$promise
            .then(function (res) {
                task.startDate = res.projectTask.startDate;
                task.endDate = res.projectTask.endDate;

                // Enables back all successors' points on the gantt chart, we don't
                // use the amountToMove arg since the tasks have been previously
                // moved by the UI and should only be reverted in the case of an
                // error coming from the server
                vm.enableTaskSuccessorsPoints(milestone, task, null);
            })
            .catch(function (res) {
                if (res.status === 403) {
                    toastr.error('You don\'t have permission to update the project task.', 'Error');
                } else {
                    toastr.error('There was an error while updating the project task.', 'Error');
                }

                // Reverts the start and end dates to the previous value
                point.update({ start: oldStart, end: oldEnd });

                // Enables back all successors' points on the gantt chart and
                // reverts their dates to their previous values
                vm.enableTaskSuccessorsPoints(milestone, task, amountToMove);
            })
            .finally(function () {
                vm.enableTaskPointMove(point);
                vm.updateMilestonePointData(milestone);
                vm.updateMinAndMaxChartDates();
            })
        ;
    };

    /**
     * Forces a redraw of the chart in order to fix some Y Axis issues
     */
    vm.redrawGanttChart = function () {
        vm.showGanttChart();
    };

    /**
     * Truncates a milestone or task title based on the given text
     *
     * @param  {string}  text  The text to truncate
     *
     * @return {string}
     */
    vm.truncateTitle = function (text) {
        return strukshurUtilService.truncate(text, 20, '…');
    };

    /**
     * Prepares the gantt columns for the chart to accept update action from the user
     */
    vm.prepareGanttColumnsActions = function () {

        // Adds popover action to all milestone title cells
        angular.element('.milestone-title').each(function (i, el) {

            // Does not recreates cell actions if they have been already processed
            if ($(el).data('processed') === 'y') { return; }

            vm.prepareMilestoneTitlePopoverAction(el);
        });

        // Adds popover action to all task title cells
        angular.element('.task-title').each(function (i, el) {

            // Does not recreates cell actions if they have been already processed
            if ($(el).data('processed') === 'y') { return; }

            vm.prepareTaskTitlePopoverAction(el);
        });

        // Adds popover action to all task start date cells
        angular.element('.task-start-date').each(function (i, el) {

            // Does not recreates cell actions if they have been already processed
            if ($(el).data('processed') === 'y') { return; }

            vm.prepareTaskDatePopoverAction(el, 'start');
        });

        // Adds popover action to all task end date cells
        angular.element('.task-end-date').each(function (i, el) {

            // Does not recreates cell actions if they have been already processed
            if ($(el).data('processed') === 'y') { return; }

            vm.prepareTaskDatePopoverAction(el, 'end');
        });

        // Adds popover action to all task assignees cells
        angular.element('.task-assignees').each(function (i, el) {

            // Does not recreates cell actions if they have been already processed
            if ($(el).data('processed') === 'y') { return; }

            vm.prepareTaskAssigneesPopoverAction(el);
        });

        // Adds popover action to all task predecessors cells
        angular.element('.task-predecessors').each(function (i, el) {

            // Does not recreates cell actions if they have been already processed
            if ($(el).data('processed') === 'y') { return; }

            vm.prepareTaskPredecessorsPopoverAction(el);
        });
    };

    /**
     * Prepares the gantt columns popover actions for the given milestone
     */
    vm.prepareGanttColumnsActionsForMilestone = function (milestone) {

        // Adds popover action for the milestone's title cell
        angular.element('.milestone-title[data-milestone-id="'+milestone.id+'"]').each(function (i, el) {

            // Does not recreate action if it has already been processed
            if ($(el).data('processed') === 'y') { return; }

            vm.prepareMilestoneTitlePopoverAction(el);
        });
    };

    /**
     * Prepares the gantt columns popover actions for the given task
     */
    vm.prepareGanttColumnsActionsForTask = function (task) {

        // Adds popover action for the task's title cell
        angular.element('.task-title[data-task-id="'+task.id+'"]').each(function (i, el) {

            // Does not recreate action if it has already been processed
            if ($(el).data('processed') === 'y') { return; }

            vm.prepareTaskTitlePopoverAction(el);
        });

        // Adds popover action for the task's start date cell
        angular.element('.task-start-date[data-task-id="'+task.id+'"]').each(function (i, el) {

            // Does not recreate action if it has already been processed
            if ($(el).data('processed') === 'y') { return; }

            vm.prepareTaskDatePopoverAction(el, 'start');
        });

        // Adds popover action for the task's end date cell
        angular.element('.task-end-date[data-task-id="'+task.id+'"]').each(function (i, el) {

            // Does not recreate action if it has already been processed
            if ($(el).data('processed') === 'y') { return; }

            vm.prepareTaskDatePopoverAction(el, 'end');
        });

        // Adds popover action for the task's assignees cell
        angular.element('.task-assignees[data-task-id="'+task.id+'"]').each(function (i, el) {

            // Does not recreate action if it has already been processed
            if ($(el).data('processed') === 'y') { return; }

            vm.prepareTaskAssigneesPopoverAction(el);
        });

        // Adds popover action for the task's predecessors cell
        angular.element('.task-predecessors[data-task-id="'+task.id+'"]').each(function (i, el) {

            // Does not recreate action if it has already been processed
            if ($(el).data('processed') === 'y') { return; }

            vm.prepareTaskPredecessorsPopoverAction(el);
        });
    };

    /**
     * Prepares the column actions to display title popovers
     *
     * @param  {object}  el  The table cell element
     */
    vm.prepareMilestoneTitlePopoverAction = function (el) {
        var placement = (isMobile.phone) ? 'right' : 'top',
            milestoneId = $(el).data('milestone-id'),
            milestone = _find(vm.milestones, { id: milestoneId }),
            template = '<span uib-popover-template="\'milestone-title-popover.html\'" popover-is-open="targetMilestone.popoverOpened" popover-trigger="outsideClick" popover-class="milestone-title-popover" popover-append-to-body="true" popover-animation="true" popover-placement="'+placement+'" title="'+milestone.title+'"><b>'+vm.truncateTitle(milestone.title)+'</b></span>';

        var scope = vm.$new();
        scope.targetMilestone = _clone(milestone);
        scope.targetMilestone.saving = false;
        scope.targetMilestone.popoverOpened = false;

        // Compiles and add popover template to the gantt chart
        $(el).data('processed', 'y');
        angular.element(el).html(template);
        $compile(angular.element(el).contents())(scope);
    };

    /**
     * Prepares the column actions to display title popovers
     *
     * @param  {object}  el  The table cell element
     */
    vm.prepareTaskTitlePopoverAction = function (el) {
        var placement = (isMobile.phone) ? 'right' : 'top',
            taskId = $(el).data('task-id'),
            task = vm.getTaskById(taskId),
            template = '<span uib-popover-template="\'task-title-popover.html\'" popover-is-open="targetTask.popoverOpened" popover-trigger="outsideClick" popover-class="task-title-popover" popover-append-to-body="true" popover-animation="true" popover-placement="'+placement+'" title="'+task.title+'">'+vm.truncateTitle(task.title)+'</span>';

        var scope = vm.$new();
        scope.targetTask = _clone(task);
        scope.targetTask.saving = false;
        scope.targetTask.popoverOpened = false;

        // Compiles and add popover template to the gantt chart
        $(el).data('processed', 'y');
        angular.element(el).html(template);
        $compile(angular.element(el).contents())(scope);
    };

    /**
     * Prepares the column actions to display date popovers
     *
     * @param  {object}  el    The table cell element
     * @param  {string}  type  The date cell type ('start' or 'end')
     */
    vm.prepareTaskDatePopoverAction = function (el, type) {
        var template = null,
            taskEndDate = null,
            taskStartDate = null,
            milestoneId = $(el).data('milestone-id'),
            milestone = _find(vm.milestones, { id: milestoneId }),
            taskId = $(el).data('task-id'),
            task = vm.getTaskById(taskId);

        if (type === 'start') {
            taskStartDate = moment(task.startDate * 1000).format('MMM D, YYYY');
            template = '<span uib-popover-template="\'task-start-date-popover.html\'" popover-is-open="targetTask.popoverOpened" popover-trigger="outsideClick" popover-class="task-date-popover" popover-append-to-body="true" popover-animation="true" popover-placement="top">'+taskStartDate+'</span>';
        } else {
            taskEndDate = moment(task.endDate * 1000).format('MMM D, YYYY');
            template = '<span uib-popover-template="\'task-end-date-popover.html\'" popover-is-open="targetTask.popoverOpened" popover-trigger="outsideClick" popover-class="task-date-popover" popover-append-to-body="true" popover-animation="true" popover-placement="top">'+taskEndDate+'</span>';
        }

        // Creates local copy of the task object and reformats the date and time
        var scope = vm.$new();
        scope.isDatePopupOpen = false;
        scope.targetTask = _clone(task);
        scope.targetTask.saving = false;
        scope.targetTask.parent = milestone;
        scope.targetTask.popoverOpened = false;
        scope.targetTask.updatedEndDate = new Date(task.endDate * 1000);
        scope.targetTask.updatedEndTime = new Date(Math.round(task.endDate / 100) * 100000);
        scope.targetTask.updatedStartDate = new Date(task.startDate * 1000);
        scope.targetTask.updatedStartTime = new Date(Math.round(task.startDate / 100) * 100000);

        // Compiles and add popover template to the gantt chart
        $(el).data('processed', 'y');
        angular.element(el).html(template);
        $compile(angular.element(el).contents())(scope);
    };

    /**
     * Prepares the column actions to display assignees popovers
     *
     * @param  {object}  el  The table cell element
     */
    vm.prepareTaskAssigneesPopoverAction = function (el) {
        var taskId = $(el).data('task-id'),
            task = vm.getTaskById(taskId);

        var assignees = [];
        if (task.assignees) {
            task.assignees.forEach(function (assignee) {
                assignees.push(assignee.fullName);
            });
        }

        var assigneesText = 'Unassigned';
        if (assignees.length === 1) {
            assigneesText = vm.truncateTitle(assignees[0]);
        } else if (assignees.length > 1) {
            assigneesText = assignees.length + ' assignees';
        }

        var template = '<span uib-popover-template="\'task-assignees-popover.html\'" popover-is-open="targetTask.popoverOpened" popover-trigger="outsideClick" popover-class="task-assignees-popover" popover-append-to-body="true" popover-animation="true" popover-placement="top">'+assigneesText+'</span>';

        var scope = vm.$new();
        scope.targetTask = _clone(task);
        scope.targetTask.saving = false;
        scope.targetTask.popoverOpened = false;
        scope.targetTask.updatedAssignees = [];
        scope.members = _clone(vm.teamMembers);
        _remove(scope.members, { id: -1 });

        // Loads selected task assignees
        if (task.assignees) {
            task.assignees.forEach(function (assignee) {
                var member = _find(vm.teamMembers, { id: assignee.id });
                if (member) {
                    scope.targetTask.updatedAssignees.push(member);
                }
            });
        }

        // Sets old task assignee to the list if it's not present yet
        if (task.assignedToId > 0) {
            var alreadyAdded = _find(task.assignees, { id: task.assignedToId });
            if (!alreadyAdded) {
                var assignee = _find(vm.teamMembers, { id: task.assignedToId });
                if (assignee) {
                    scope.targetTask.updatedAssignees.push(assignee);
                }
            }
        }

        // Compiles and add popover template to the gantt chart
        $(el).data('processed', 'y');
        angular.element(el).html(template);
        $compile(angular.element(el).contents())(scope);
    };

    /**
     * Prepares the column actions to display predecessors popovers
     *
     * @param  {object}  el  The table cell element
     */
    vm.prepareTaskPredecessorsPopoverAction = function (el) {
        var taskId = $(el).data('task-id'),
            task = vm.getTaskById(taskId);

        var predecessors = [];
        if (task.predecessors) {
            task.predecessors.forEach(function (predecessor) {
                predecessors.push(predecessor);
            });
        }

        var predecessorsText = '-', predecessorsTitle = '';
        if (predecessors.length === 1) {
            var predecessor = vm.getTaskById(parseInt(predecessors[0], 10));
            if (predecessor) {
                predecessorsTitle = predecessor.title;
                predecessorsText = vm.truncateTitle(predecessor.title);
            }
        } else if (predecessors.length > 1) {
            predecessorsText = predecessors.length + ' predecessors';
        }

        var template = '<span uib-popover-template="\'task-predecessors-popover.html\'" popover-is-open="targetTask.popoverOpened" popover-trigger="outsideClick" popover-class="task-predecessors-popover" popover-append-to-body="true" popover-animation="true" popover-placement="top" title="'+predecessorsTitle+'">'+predecessorsText+'</span>';

        var scope = vm.$new();
        scope.targetTask = _clone(task);
        scope.targetTask.saving = false;
        scope.targetTask.popoverOpened = false;
        scope.targetTask.updatedPredecessors = [];

        // Asembles list of available predecessors to select for the target task
        scope.availablePredecessors = [];
        vm.getAllTasks().forEach(function (task) {
            if (moment(task.endDate * 1000).isSameOrBefore(moment.utc(scope.targetTask.startDate * 1000), 'day')) {
                scope.availablePredecessors.push(task);
            }
        });

        // Removes current task from the list of possible predecessors
        _remove(scope.availablePredecessors, { id: task.id });

        // Loads selected task predecessors
        if (task.predecessors) {
            task.predecessors.forEach(function (predecessor) {
                var task = vm.getTaskById(predecessor);
                if (task) {
                    scope.targetTask.updatedPredecessors.push(task);
                }
            });
        }

        // Compiles and add popover template to the gantt chart
        $(el).data('processed', 'y');
        angular.element(el).html(template);
        $compile(angular.element(el).contents())(scope);
    };

    /**
     * Updates the received milestone with its new title on the server
     *
     * @param  {object}  targetTask  The milestone to update
     */
    vm.updateMilestoneTitleFromPopover = function (targetMilestone) {
        if (targetMilestone.saving) { return; }

        targetMilestone.popoverOpened = false;

        var originalMilestone = _find(vm.milestones, { id: targetMilestone.id });

        // Sets data to send back to the server
        var data = {
            milestone_id: targetMilestone.id,
            title: targetMilestone.title,
        };

        targetMilestone.saving = true;
        strukshurApiService.projectMilestone.update(data).$promise
            .then(function (res) {
                originalMilestone.title = res.projectMilestone.title;

                // Updates milestone point on the chart
                vm.updateMilestonePointData(originalMilestone);

                // Recompiles the popover action after the milestone update
                var el = angular.element('.milestone-title[data-milestone-id="'+targetMilestone.id+'"]')[0];
                vm.prepareMilestoneTitlePopoverAction(el);
            })
            .catch(function (res) {
                if (res.status === 403) {
                    toastr.error('You don\'t have permission to update the project milestone.', 'Error');
                } else {
                    toastr.error('There was an error while updating the milestone\'s title.', 'Error');
                }
            })
            .finally(function () {
                targetMilestone.saving = false;
            })
        ;
    };

    /**
     * Updates the received task with its new title on the server
     *
     * @param  {object}  targetTask  The task to update
     */
    vm.updateTaskTitleFromPopover = function (targetTask) {
        if (targetTask.saving) { return; }

        targetTask.popoverOpened = false;

        var originalTask = vm.getTaskById(targetTask.id);

        // Sets data to send back to the server
        var data = {
            task_id: targetTask.id,
            title: targetTask.title,
        };

        targetTask.saving = true;
        strukshurApiService.projectTask.update(data).$promise
            .then(function (res) {
                originalTask.title = res.projectTask.title;

                // Updates task point on the chart
                vm.updateTaskPointData(originalTask);

                // Recompiles the popover action after the task update
                var el = angular.element('.task-title[data-task-id="'+targetTask.id+'"]')[0];
                vm.prepareTaskTitlePopoverAction(el);
            })
            .catch(function (res) {
                if (res.status === 403) {
                    toastr.error('You don\'t have permission to update the project task.', 'Error');
                } else {
                    toastr.error('There was an error while updating the task\'s title.', 'Error');
                }
            })
            .finally(function () {
                targetTask.saving = false;
            })
        ;
    };

    /**
     * Updates the received task with its new start date info on the server
     *
     * @param  {object}  targetTask  The task to update
     * @param  {string}  type        The date cell type ('start' or 'end')
     */
    vm.updateTaskDatesFromPopover = function (targetTask, type) {
        if (targetTask.saving) { return; }

        targetTask.popoverOpened = false;

        var updatedEndDate = _clone(targetTask.updatedEndDate);
        updatedEndDate.setHours(targetTask.updatedEndTime.getHours());
        updatedEndDate.setMinutes(targetTask.updatedEndTime.getMinutes());
        updatedEndDate.setSeconds(0);

        var updatedStartDate = _clone(targetTask.updatedStartDate);
        updatedStartDate.setHours(targetTask.updatedStartTime.getHours());
        updatedStartDate.setMinutes(targetTask.updatedStartTime.getMinutes());
        updatedStartDate.setSeconds(0);

        if (moment(updatedStartDate).diff(moment(updatedEndDate), 'minutes') > 1) {
            toastr.error('The end date cannot be earlier than the start date.', 'Error');
            return;
        }

        var amountToMove = null,
            originalTask = vm.getTaskById(targetTask.id),
            point = vm.ganttChart.get('task-' + targetTask.id),
            oldPointStart = point.start, oldPointEnd = point.end;

        // Sets data to send back to the server
        var data: any = {
            task_id: targetTask.id,
        };

        if ((originalTask.startDate * 1000) !== targetTask.updatedStartDate.getTime()) {
            data.start_date = moment.utc(updatedStartDate).format('YYYY-MM-DD HH:mm');
        }

        if ((originalTask.endDate * 1000) !== targetTask.updatedEndDate.getTime()) {
            data.end_date = moment.utc(updatedEndDate).format('YYYY-MM-DD HH:mm');

            // Also moves the dependent tasks if the new end date is higher than the current old
            if (targetTask.updatedEndDate > (originalTask.endDate * 1000)) {
                amountToMove = moment.duration(moment(targetTask.updatedEndDate).diff(moment(originalTask.endDate * 1000)));
            }
        }

        // Moves the task's point according to the user's change
        point.update({ start: updatedStartDate.getTime(), end: updatedEndDate.getTime() });

        // Disables any further drag and drop action while the point is being saved
        vm.disableTaskPointMove(point);

        // Also disables and updates task's successors points on the gantt chart
        vm.moveAndDisableTaskSuccessorsPoints(targetTask.parent, targetTask, amountToMove, true);

        targetTask.saving = true;
        strukshurApiService.projectTask.moveDates(data).$promise
            .then(function (res) {
                originalTask.endDate = res.projectTask.endDate;
                originalTask.startDate = res.projectTask.startDate;

                // Re-enables all successors' points on the gantt chart, we don't
                // use the amountToMove arg since the tasks have been previously
                // moved by the UI and should only be reverted in the case of an
                // error coming from the server
                vm.enableTaskSuccessorsPoints(targetTask.parent, targetTask, null);

                // Recompiles the popover action after the task update
                var el = angular.element('.task-'+type+'-date[data-task-id="'+targetTask.id+'"]')[0];
                vm.prepareTaskDatePopoverAction(el, type);
            })
            .catch(function (res) {
                if (res.status === 403) {
                    toastr.error('You don\'t have permission to update the project task.', 'Error');
                } else {
                    toastr.error('There was an error while updating the task\'s date.', 'Error');
                }

                // Reverts the start and end dates to the previous value
                point.update({ start: oldPointStart, end: oldPointEnd });

                // Enables back all successors' points on the gantt chart and
                // reverts their dates to their previous values
                vm.enableTaskSuccessorsPoints(targetTask.parent, targetTask, amountToMove);
            })
            .finally(function () {
                targetTask.saving = false;
                vm.enableTaskPointMove(point);
                vm.updateMilestonePointData(targetTask.parent);
                vm.updateMinAndMaxChartDates();
            })
        ;
    };

    /**
     * Updates the received task with its new assignees on the server
     *
     * @param  {object}  targetTask  The task to update
     */
    vm.updateTaskAssigneesFromPopover = function (targetTask) {
        if (targetTask.saving) { return; }

        targetTask.popoverOpened = false;

        var originalTask = vm.getTaskById(targetTask.id);

        var assignees = [];
        targetTask.updatedAssignees.forEach(function (assignee) {
            assignees.push(assignee.id);
        });

        // Sets data to send back to the server
        var data = {
            task_id: targetTask.id,
            assignees: JSON.stringify(assignees),
        };

        targetTask.saving = true;
        strukshurApiService.projectTask.update(data).$promise
            .then(function (res) {
                originalTask.assignees = res.projectTask.assignees;

                // Updates task point on the chart
                vm.updateTaskPointData(originalTask);

                // Recompiles the popover action after the task update
                var el = angular.element('.task-assignees[data-task-id="'+targetTask.id+'"]')[0];
                vm.prepareTaskAssigneesPopoverAction(el);
            })
            .catch(function (res) {
                if (res.status === 403) {
                    toastr.error('You don\'t have permission to update the project task.', 'Error');
                } else {
                    toastr.error('There was an error while updating the task\'s assignees.', 'Error');
                }
            })
            .finally(function () {
                targetTask.saving = false;
            })
        ;
    };

    /**
     * Updates the received task with its new predecessors on the server
     *
     * @param  {object}  targetTask  The task to update
     */
    vm.updateTaskPredecessorsFromPopover = function (targetTask) {
        if (targetTask.saving) { return; }

        targetTask.popoverOpened = false;

        var originalTask = vm.getTaskById(targetTask.id);

        var predecessors = [];
        targetTask.updatedPredecessors.forEach(function (predecessor) {
            predecessors.push(predecessor.id);
        });

        // Sets data to send back to the server
        var data = {
            task_id: targetTask.id,
            predecessors: JSON.stringify(predecessors),
        };

        targetTask.saving = true;
        strukshurApiService.projectTask.update(data).$promise
            .then(function (res) {
                originalTask.predecessors = res.projectTask.predecessors;

                // Updates predecessor's task successors if any exists
                if (originalTask.predecessors) {
                    vm.updateTaskSuccessors(originalTask);
                }

                // Updates task point on the chart
                vm.updateTaskPointData(originalTask);

                // Recompiles the popover action after the task update
                var el = angular.element('.task-predecessors[data-task-id="'+targetTask.id+'"]')[0];
                vm.prepareTaskPredecessorsPopoverAction(el);
            })
            .catch(function (res) {
                if (res.status === 403) {
                    toastr.error('You don\'t have permission to update the project task.', 'Error');
                } else {
                    toastr.error('There was an error while updating the task\'s predecessors.', 'Error');
                }
            })
            .finally(function () {
                targetTask.saving = false;
            })
        ;
    };

    /**
     * Calculates the gantt chart height based in its content
     *
     * @return {number}
     */
    vm.calcGanttChartHeight = () => {
        const navigatorHeight = vm.calcGanttChartNavigatorHeight();

        return (
            140 + // the height of the chart's top section
            navigatorHeight + // the height of the bottom navigator component
            (22 * vm.ganttData.length) // height of each row
        );
    };

    /**
     * Calculates the gantt chart's navigator height based in its content
     *
     * @return {number}
     */
    vm.calcGanttChartNavigatorHeight = () => {
        const calculatedHeight = (5 * vm.ganttData.length);

        return calculatedHeight <= 120 ? calculatedHeight : 120;
    };

    /**
     * Shows the gantt chart view
     */
    vm.showGanttChart = function () {
        vm.currentView = 'chart';
        vm.hasSelectedTask = false;

        // Simple local func that returns a span element with the given number of &nbsp;
        var nbspHelper = function (length) {
            return '<span class="nbsp-helper">' + '&nbsp;'.repeat(length) + '</span>';
        };

        // Only initializes the gantt component if there's any data to display
        if (vm.ganttData.length > 0) {
            var dateFormat = Highcharts.dateFormat,
                defined = Highcharts.defined,
                isObject = Highcharts.isObject;

            // Double clicker options
            const doubleClicker = {
                clickedOnce: false,
                timer: null,
                timeBetweenClicks: 400,
            };

            // call to reset double click timer
            const resetDoubleClick = () => {
                clearTimeout(doubleClicker.timer);
                doubleClicker.timer = null;
                doubleClicker.clickedOnce = false;
            };

            // Set initial options
            var ganttOptions: any = {
                title: { enabled: false },
                credits: { href: 'https://strukshur.com/', text: 'strukshur.com' },
                chart: {
                    events: {
                        beforePrint: vm.onBeforeChartPrinted,
                        afterPrint: vm.onAfterChartPrinted,
                    },
                    height: vm.calcGanttChartHeight(),
                },
                series: [{
                    data: vm.ganttData,
                    minPointLength: 20,
                    name: vm.$parent.project.title,
                    dragDrop: {
                        liveRedraw: false,
                    },
                    groupPadding: 0.1,
                    pointPadding: 0.1,
                    point: {
                        events: {
                            drop: vm.onTaskPointDrop,
                        }
                    }
                }],
                xAxis: [{
                    type: 'datetime',
                    min: vm.minChartDate,
                    max: vm.maxChartDate,
                    tickInterval: 24 * 36e5, // day
                    currentDateIndicator: true,
                    dateTimeLabelFormats: {
                        day: '%e',
                        month: '%b \'%y',
                    },
                    grid: {
                        cellHeight: 24,
                    },
                }, {
                	linkedTo: 0,
                    labels: {
                    	format: '{value:%B}'
                    },
                    grid: {
                        cellHeight: 24,
                    },
                    tickInterval: 24 * 30 * 36e5, // month
                }],
                yAxis: {
                    type: 'category',
                    labels: {
                        style: {
                            fontSize: '11px',
                            textOverflow: 'ellipsis',
                        }
                    }
                },
                navigator: {
                    enabled: true,
                    height: vm.calcGanttChartNavigatorHeight(),
                    liveRedraw: true,
                    series: {
                        type: 'gantt',
                        pointPlacement: 0.5,
                        pointPadding: 0.25
                    },
                    yAxis: {
                        min: 0,
                        max: 5,
                        reversed: true,
                        categories: []
                    },
                },
                scrollbar: {
                    enabled: true
                },
                rangeSelector: {
                    enabled: true,
                    selected: 0,
                },
                exporting: {
                    filename: 'strukshur_' + vm.$parent.project.slug,
                },
                plotOptions: {
                    series: {
                        animation: false, // Do not animate dependency connectors
                        dataLabels: {
                            enabled: true,
                            style: {
                                cursor: 'default',
                                pointerEvents: 'none',
                            }
                        },
                        allowPointSelect: true,
                        point: {
                            events: {
                                click: function (evt) {
                                    if (doubleClicker.clickedOnce === true && doubleClicker.timer) {
                                        resetDoubleClick();

                                        // The actual function to be called for the double click
                                        vm.onTaskDoubleClicked(evt, this);
                                    } else {
                                        doubleClicker.clickedOnce = true;
                                        doubleClicker.timer = setTimeout(function() {
                                            resetDoubleClick();
                                        }, doubleClicker.timeBetweenClicks);
                                    }
                                },
                                mouseOver: vm.onTaskMouseOver,
                                select: vm.onTaskSelected,
                                unselect: vm.onTaskUnselected,
                                remove: vm.onTaskRemoved
                            }
                        }
                    }
                },
                tooltip: {
                    pointFormatter: function () {
                        var point = this,
                            format = '%e. %b %y',
                            options = point.options,
                            completed = options.completed,
                            amount = isObject(completed) ? completed.amount : completed,
                            progress = ((amount || 0) * 100) + '%',
                            lines;

                        // Task assignees
                        var assignees = [];
                        if (options.assignees) {
                            options.assignees.forEach(function (assignee) {
                                assignees.push(assignee.fullName);
                            });
                        }

                        // Define the lines for thr tooltip
                        if (point.type === 'task') {
                            lines = [{
                                value: point.name,
                                style: 'font-weight: bold;'
                            }, {
                                title: 'Status',
                                value: point.status
                            }, {
                                title: 'Start',
                                value: dateFormat(format, point.start)
                            }, {
                                title: 'End',
                                value: dateFormat(format, point.end)
                            }, {
                                title: 'Completed',
                                value: progress
                            }, {
                                title: 'Assignees',
                                value: (assignees.length > 0)  ? assignees.join(', ') : 'Unassigned'
                            }];

                            if (point.disabled) {
                                lines.push({
                                    value: 'Moving is disabled while a task is being updated',
                                    style: 'font-style: italic;',
                                });
                            }
                        } else {
                            lines = [{
                                value: point.name,
                                style: 'font-weight: bold;'
                            }, {
                                title: 'Start',
                                value: dateFormat(format, point.start)
                            }, {
                                title: 'End',
                                value: dateFormat(format, point.end)
                            }];

                            if (point.totalTasksCount > 0) {
                                lines.push({
                                    title: 'Total tasks',
                                    value: point.totalTasksCount,
                                });
                            }

                            if (point.totalTasksCount > 0) {
                                lines.push({
                                    title: 'Open tasks',
                                    value: point.openTasksCount,
                                });
                            }

                            if (point.inProgressTasksCount > 0) {
                                lines.push({
                                    title: 'In progress tasks',
                                    value: point.inProgressTasksCount,
                                });
                            }

                            if (point.completedTasksCount > 0) {
                                lines.push({
                                    title: 'Completed tasks',
                                    value: point.completedTasksCount,
                                });
                            }

                            if (point.acceptedTasksCount > 0) {
                                lines.push({
                                    title: 'Accepted tasks',
                                    value: point.acceptedTasksCount,
                                });
                            }
                        }

                        return lines.reduce(function (str, line) {
                            var s = '',
                                style = (
                                    defined(line.style) ? line.style : 'font-size: 0.8em;'
                                );

                            if (line.visible !== false) {
                                s = (
                                    '<span style="' + style + '">' +
                                    (defined(line.title) ? line.title + ': ' : '') +
                                    (defined(line.value) ? line.value : '') +
                                    '</span><br/>'
                                );
                            }

                            return str + s;
                        }, '');
                    }
                }
            };

            // Enables drag and drop support if the user can edit tasks
            if (vm.checkPermission('project_task_edit')) {
                ganttOptions.plotOptions.series.dragDrop = {
                    draggableX: true,
                    draggableY: false,
                    dragMinY: 0,
                    dragMaxY: 2,
                    dragPrecisionX: 24 * 36e5 // Snap to eight hours
                };
            }

            // Sets specific gantt options for users running on mobile
            if (isMobile.phone) {

                // Sets max series width if the device is mobile
                ganttOptions.yAxis.labels.style.width = ($window.innerWidth * 0.25) + 'px';

                // Sets only a few zoom options on mobile
                ganttOptions.rangeSelector.buttons = [
                    {
                        type: 'month',
                        count: 1,
                        text: '1m'
                    }, {
                        type: 'month',
                        count: 3,
                        text: '3m'
                    }, {
                        type: 'all',
                        text: 'All'
                    }
                ];
            }

            // Sets gantt chart columns based on the device width
            var ganttColumns = [{
                title: { text: 'Title' },
                labels: {
                    formatter: function () {
                        if (this.point.type === 'task') {
                            var taskId = this.point.id.split('-')[1];

                            return '<span class="task-title" data-task-id="'+taskId+'" data-processed="n">'+vm.truncateTitle(this.point.title)+'</span>';
                        } else if (this.point.type === 'milestone') {
                            var milestoneId = this.point.id.split('-')[1];

                            return '<span class="milestone-title" data-milestone-id="'+milestoneId+'" data-processed="n"><b>'+vm.truncateTitle(this.point.name)+'</b></span>';
                        }

                        return '';
                    },
                    useHTML: true
                }
            }];
            if ($window.innerWidth > 1200) {
                ganttColumns.push({
                    title: { text: 'Start' },
                    labels: {
                        formatter: function () {
                            if (this.point.type !== 'task') { return nbspHelper(8); }

                            var taskId = this.point.id.split('-')[1],
                                milestoneId = this.point.parent.split('-')[1];

                            return '<span class="task-start-date" data-milestone-id="'+milestoneId+'" data-task-id="'+taskId+'" data-processed="n">'+moment(this.point.start).format('MMM D, YYYY')+'</span>';
                        },
                        useHTML: true
                    }
                });

                ganttColumns.push({
                    title: { text: 'End' },
                    labels: {
                        formatter: function () {
                            if (this.point.type !== 'task') { return nbspHelper(7); }

                            var taskId = this.point.id.split('-')[1],
                                milestoneId = this.point.parent.split('-')[1];

                            return '<span class="task-end-date" data-milestone-id="'+milestoneId+'" data-task-id="'+taskId+'" data-processed="n">'+moment(this.point.end).format('MMM D, YYYY')+'</span>';
                        },
                        useHTML: true
                    }
                });
            }
            if ($window.innerWidth > 1400) {
                ganttColumns.push({
                    title: { text: 'Assignees' },
                    labels: {
                        formatter: function () {

                            // Does not handle assigness on a milestone level
                            if (this.point.type === 'milestone') { return nbspHelper(12); }

                            var taskId = this.point.id.split('-')[1];

                            var assignees = [];
                            if (this.point.assignees) {
                                this.point.assignees.forEach(function (assignee) {
                                    assignees.push(assignee.fullName);
                                });
                            }

                            var assigneesText = 'Unassigned';
                            if (assignees.length === 1) {
                                assigneesText = assignees[0];
                            } else if (assignees.length > 1) {
                                assigneesText = assignees.length + ' assignees';
                            }

                            return '<span class="task-assignees" data-task-id="'+taskId+'" data-processed="n">'+vm.truncateTitle(assigneesText)+'</span>';
                        },
                        useHTML: true
                    }
                });

                ganttColumns.push({
                    title: { text: 'Predecessors' },
                    labels: {
                        formatter: function () {

                            // Does not handle assigness on a milestone level
                            if (this.point.type === 'milestone') { return nbspHelper(16); }

                            var taskId = this.point.id.split('-')[1];

                            var predecessors = [];
                            if (this.point.dependency) {
                                this.point.dependency.forEach(function (predecessor) {
                                    predecessors.push(predecessor.split('-')[1]);
                                });
                            }

                            var predecessorsText = '-';
                            if (predecessors.length === 1) {
                                var predecessor = vm.getTaskById(parseInt(predecessors[0], 10));
                                if (predecessor) {
                                    predecessorsText = predecessor.title;
                                }
                            } else if (predecessors.length > 1) {
                                predecessorsText = predecessors.length + ' predecessors';
                            }

                            return '<span class="task-predecessors" data-task-id="'+taskId+'" data-processed="n">'+vm.truncateTitle(predecessorsText)+'</span>';
                        },
                        useHTML: true
                    }
                });
            }

            ganttOptions.yAxis.grid = {
                borderColor: '#ccd6eb',
                columns: ganttColumns
            };

            // Initializes gantt chart component
            vm.ganttChart = Highcharts.ganttChart('gantt-container', ganttOptions);

            $timeout(vm.prepareGanttColumnsActions, 250);
        }
    };

    /**
     * Opens the modal for adding a new comment
     *
     * @param  {object}  task  The task to add the task to
     */
    vm.newComment = function (task) {
        var modalInstance = $uibModal.open({
            scope: vm,
            keyboard: true,
            controller: 'ProjectDetailNewTaskCommentCtrl',
            template: require('../../../common/templates/projects.newTaskCommentModal.tpl.html'),
            resolve: {
                project: vm.$parent.project,
                task: task
            }
        });

        modalInstance.result.then(

            // Resolved callback
            function (data) {
                data.comment.readByUser = true;
                task.comments.push(data.comment);
            },

            // Rejected callback
            angular.noop
        );
    };

    vm.showReminderForm = function(task){
        var i,tot,i2,tot2, found;
        task.showNewReminderForm = true;
        task.newReminderLoading = false;
        vm.newReminder.option = '';
        vm.availableOptions = [];

        tot = vm.reminderOptions.length;
        tot2 = (task.reminders?task.reminders.length:0);
        for(i=0;i<tot;i++){
            found = false;
            for(i2=0;i2<tot2;i2++){
                if(vm.reminderOptions[i].value===task.reminders[i2].option){
                    found = true;
                    break;
                }
            }
            if(!found){
                if(vm.newReminder.option===''){
                    vm.newReminder.option = vm.reminderOptions[i].value;
                }
                vm.availableOptions.push(vm.reminderOptions[i]);
            }
        }
        if(vm.availableOptions.length===0){
            task.showNewReminderForm = false;
        }
    };
    /**
     * Adds a inline form to add new reminder
     *
     * @param {object}  task  The task to add the task to
     */
    vm.addReminder = function (task) {
        task.newReminderLoading = true;
        if(!task.reminders){
            task.reminders = [];
        }

        strukshurApiService.projectTaskReminder.create({option:vm.newReminder.option, task_id: task.id}).$promise
            .then(function (res) {
                task.reminders.push(res.reminder);
            })
            .catch(function (res) {
                vm.errorMessage = 'There was an error trying to add the reminder to the project task.';
            })
            .finally(function () {
                task.newReminderLoading = false;
                task.showNewReminderForm = false;
            })
        ;

    };

    vm.getOptionReminder = function (option) {
        var i, tot;
        tot = vm.reminderOptions.length;
        for(i=0;i<tot;i++){
            if(vm.reminderOptions[i].value===option){
                return vm.reminderOptions[i].text;
            }
        }
        return '';
    };

    vm.deleteReminder = function (task, reminder) {
        var i, tot;

        task.showNewReminderForm = false;
        reminder.loading = true;
        strukshurApiService.projectTaskReminder.delete({reminder_id: reminder.id}).$promise
            .then(function (res) {
                tot = task.reminders.length;
                for(i=0;i<tot;i++){
                    if(task.reminders[i].id===reminder.id){
                        task.reminders.splice(i,1);
                        break;
                    }
                }
            })
            .catch(function (res) {
                vm.errorMessage = 'There was an error trying to add the reminder to the project task.';
            })
            .finally(function () { reminder.loading = false; })
        ;

    };

    /**
     * Deletes the received comment on the server
     *
     * @param  {object}  task     The task the comment is in
     * @param  {object}  comment  The comment to delete
     */
    vm.deleteComment = function(task, comment) {
        var $childScope = vm.$new();
        $childScope.comment = comment;
        $childScope.project = vm.$parent.project;
        $childScope.title = 'Delete comment';
        $childScope.message = 'Are you sure you want to delete the comment?';

        var modalInstance = $uibModal.open({
            keyboard: true,
            scope: $childScope,
            controller: 'ProjectDetailDeleteTaskCommentCtrl',
            template: require('../../../common/templates/base.confirm-modal.tpl.html')
        });

        modalInstance.result.then(

            // Resolved callback
            function (data) {
                _pull(task.comments, comment);
            },

            // Rejected callback
            angular.noop
        );
    };

    /**
     * Triggers a file select event for the given active task
     *
     * @param  {object}  task  The task to select the file for
     */
    vm.selectFile = (task) => {
        angular.element('#file-' + task.id).click();
    };

    /**
     * Opens the modal to select a file to add to the active task
     *
     * @param  {object}  task  The task
     */
    vm.openFilePickerModal = (task) => {
        const modalInstance = $uibModal.open({
            keyboard: true,
            scope: vm.$new(),
            controller: 'FilePickerModal',
            template: require('../../../common/templates/projects.filePickerModal.tpl.html'),
            resolve: {
                options: { project: vm.$parent.project },
            },
        });

        modalInstance.result.then(
            res => {
                const file = res.selected[0];

                // Open upload progress modal
                const progressModal = $uibModal.open({
                    scope: $scope,
                    controller: 'ProjectProgressModalCtrl',
                    template: require('../../../common/templates/progress-modal.tpl.html'),
                    resolve: {}
                });
                progressModal.result.then(angular.noop, angular.noop);

                const data = {
                    project_id: vm.$parent.project.id,
                    task_id: task.id,
                    file_id: file.id,
                };

                task.uploading = true;
                vm.upgradeLink = false;
                vm.errorMessage = false;

                strukshurApiService.projectTaskFile.createFromFile(data).$promise
                    .then(res => {
                        task.currentFile = null;
                        if (typeof task.files === 'undefined') {
                            task.files = [];
                        }
                        task.files.push(res.projectTaskFile);
                    })
                    .catch(res => {
                        if (res && res.data && res.data.message) {
                            vm.errorMessage = res.data.message;
                            if (vm.errorMessage.indexOf('enough storage') !==- 1 && vm.errorMessage.indexOf('Upgrade') !== -1) {
                                vm.upgradeLink = true;
                            }
                        } else {
                            vm.errorMessage = 'An error occurred uploading the file.';
                        }
                    })
                    .finally(() => {
                        progressModal.close();
                        task.uploading = false;
                    })
                ;
            },

            // Rejected callback
            angular.noop
        );
    }

    /**
     * Uploads the current selected file for the received task
     *
     * @param  {object}  task  The task
     */
    vm.uploadFile = (task) => {
        let data = {
                project_id: vm.$parent.project.id,
                task_id: task.id,
                file: 'data:'+task.currentFile.filetype+';base64,'+task.currentFile.base64,
                filename: task.currentFile.filename
            };
        vm.errorMessage = false;
        vm.upgradeLink = false;

        task.uploading = true;
        strukshurApiService.projectTaskFile.create(data).$promise
            .then(res => {
                task.currentFile = null;
                if (typeof task.files === 'undefined') {
                    task.files = [];
                }
                task.files.push(res.projectTaskFile);
            })
            .catch(res => {
                if (res && res.data && res.data.message) {
                    vm.errorMessage = res.data.message;
                    if (vm.errorMessage.indexOf('enough storage') !==- 1 && vm.errorMessage.indexOf('Upgrade') !== -1) {
                        vm.upgradeLink = true;
                    }
                } else {
                    vm.errorMessage = 'An error occurred uploading the file.';
                }
            })
            .finally(() => task.uploading = false)
        ;
        data = null;
    };

    /**
     * Popup for upgrade account
     */
    vm.upgradeSettingsAccount = function () {
        vm.$parent.upgradeSettingsAccount();
    };

    /**
     * Cancels the current task file selection
     *
     * @param  {object}  task  The task
     */
    vm.cancelFile = function (task) {
        task.currentFile = null;
    };

    /**
     * Deletes the received file on the server
     *
     * @param  {object}  task  The task the file is in
     * @param  {object}  file  The file to delete
     */
    vm.deleteFile = function (task, file) {
        var $childScope = vm.$new();
        $childScope.file = file;
        $childScope.project = vm.$parent.project;
        $childScope.title = 'Delete file';
        $childScope.message = 'Are you sure you want to delete the file?';

        var modalInstance = $uibModal.open({
            keyboard: true,
            scope: $childScope,
            controller: 'ProjectDetailDeleteTaskFileCtrl',
            template: require('../../../common/templates/base.confirm-modal.tpl.html')
        });

        modalInstance.result.then(

            // Resolved callback
            function (data) {
                _pull(task.files, file);
            },

            // Rejected callback
            angular.noop
        );
    };

    /**
     * Updates the page items to show on the project sidebar
     */
    vm.updatePageItems = () => {
        let items = [];
        vm.milestones.forEach((item) => {
            items.push({ title: item.title, href: 'milestone-' + item.id });
        });
        vm.$parent.setPageItems($state.current.name, items);
    };

    vm.init();
})

.controller('ProjectDetailNewMilestoneCtrl', function ($scope, $uibModalInstance, project, strukshurApiService) {
    var vm = $scope;

    vm.loading = false;
    vm.newMilestone = { title: '' };
    vm.errorMessage = '';

    vm.saveMilestone = function (form) {
        if (form.milestoneForm.$valid) {
            vm.loading = true;
            vm.errorMessage = '';

            var data = {
                project_id: project.id,
                title: vm.newMilestone.title
            };

            strukshurApiService.projectMilestone.create(data).$promise
                .then(function (res) {
                    var milestone = res.projectMilestone;

                    if (!milestone.tasks) {
                        milestone.tasks = [];
                    }

                    $uibModalInstance.close({ milestone: milestone });
                })
                .catch(function (res) {
                    if (res.status === 403) {
                        vm.errorMessage = 'You don\'t have the necessary permission to create the milestone.';
                    } else {
                        vm.errorMessage = 'There was an error trying to create the project milestone.';
                    }
                })
                .finally(function () {
                    vm.loading = false;
                })
            ;
        }
    };
})

.controller('ProjectDetailEditMilestoneCtrl', function ($scope, $uibModalInstance, milestone, strukshurApiService) {
    var vm = $scope;

    vm.loading = false;
    vm.errorMessage = '';
    vm.editMilestone = { title: milestone.title };

    vm.saveMilestone = function (form) {
        if (form.milestoneForm.$valid) {
            vm.loading = true;
            vm.errorMessage = '';

            var data = {
                milestone_id: milestone.id,
                title: vm.editMilestone.title
            };

            strukshurApiService.projectMilestone.update(data).$promise
                .then(function (res) {
                    $uibModalInstance.close({ milestone: res.projectMilestone });
                })
                .catch(function (res) {
                    if (res.status === 403) {
                        vm.errorMessage = 'You don\'t have the necessary permission to edit the milestone.';
                    } else {
                        vm.errorMessage = 'There was an error trying to edit the project milestone.';
                    }
                })
                .finally(function () {
                    vm.loading = false;
                })
            ;
        }
    };
})

.controller('ProjectDetailCopyMilestoneCtrl', function ProjectDetailCopyMilestoneCtrl ($scope, $uibModalInstance, milestone, project, strukshurApiService) {
    var vm = $scope;

    vm.loading = true;
    vm.projects = [];
    vm.errorMessage = '';
    vm.selectedTasks = [];
    vm.availableTasks = milestone.tasks;
    vm.allTasksFlag = false;
    vm.loadingProjects = true;
    vm.newMilestone = {
        tasks: [],
        title: milestone.title + ' (copy)',
    };

    // Searches list of available projects for the current user
    strukshurApiService.projectprojects.query({ page: 1, limit: 50 }).$promise
        .then(function (res) {
            vm.loading = false;
            vm.projects = res.projects;
            vm.loadingProjects = false;
            vm.newMilestone.project = project;

            // Select the first project and disable the field if the user only
            // has one project to choose from
            if (vm.projects.length === 1) {
                vm.newMilestone.project = vm.projects[0];
            }
        })
        .catch(function (res) {
            if (res.status === 403) {
                vm.errorMessage = 'You don\'t have the necessary permission to load the projects.';
            } else {
                vm.errorMessage = 'There was an error trying to load the projects.';
            }
        })
    ;

    /**
     * Toggles the state of all task checkboxes in the modal
     */
    vm.toggleAll = function () {
        vm.allTasksFlag = !vm.allTasksFlag;
        vm.availableTasks.forEach(function (task) {
            task.selected = vm.allTasksFlag;
        });
    };

    /**
     * Copies the milestone to the selected project on the backend
     *
     * @param  {Object}  form  The form
     */
    vm.confirmCopy = function (form) {
        if (form.$valid) {
            vm.errorMessage = '';

            // Collect the selected rooms
            vm.selectedTasks = [];
            angular.forEach(form, function(value, index: string) {
                if (index.includes('task-')) {
                    if (value.$viewValue === true || value.$viewValue === 'true') {
                        var taskId = value.$$attr.value;
                        vm.selectedTasks.push(taskId);
                    }
                }
            });

            var data = {
                milestone_id: milestone.id,
                project_id: vm.newMilestone.project.id,
                selected_tasks: vm.selectedTasks.join(','),
                title: vm.newMilestone.title,
            };

            // Copies the milestone to the selected project
            vm.loading = true;
            strukshurApiService.projectMilestone.copy(data).$promise
                .then(function (res) {
                    $uibModalInstance.close({ project_id: vm.newMilestone.project.id, milestone: res.projectMilestone });
                })
                .catch(function (res) {
                    if (res.status === 403) {
                        vm.errorMessage = 'You don\'t have the necessary permission to copy the project milestone.';
                    } else {
                        vm.errorMessage = 'There was an error trying to copy the project milestone.';
                    }
                })
                .finally(function () {
                    vm.loading = false;
                })
            ;
        }
    };
})

.controller('ProjectDetailDeleteMilestoneCtrl', function ($scope, $uibModalInstance, strukshurApiService) {
    var vm = $scope;

    vm.onConfirmChosen = function () {

        // Deletes the milestone on the server
        vm.loading = true;
        strukshurApiService.projectMilestone.delete({ milestone_id: vm.milestone.id }).$promise
            .then(function (res) {
                $uibModalInstance.close();
            })
            .catch(function (res) {
                if (res.status === 403) {
                    vm.errorMessage = 'You don\'t have the necessary permission to delete the milestone.';
                } else {
                    vm.errorMessage = 'There was an error trying to delete the milestone.';
                }
            })
            .finally(function () {
                vm.loading = false;
            })
        ;
    };
})

.controller('ProjectDetailNewTaskCtrl', function ProjectDetailNewTaskCtrl ($scope, $uibModalInstance, project, milestone, strukshurApiService, tasks) {
    var first_date, day_after;

    first_date = new Date();
    if (first_date.getHours() >= 12) {
        first_date.setDate(first_date.getDate() + 1);
    }
    first_date = new Date(first_date.getFullYear(), first_date.getMonth(), first_date.getDate(), 12, 0, 0);
    day_after = new Date();
    day_after.setDate(first_date.getDate() + 1);

    var vm = $scope;
    vm.loading = false;
    vm.errorMessage = '';
    vm.newTask = {
        title: '',
        milestone: null,
        startDate: first_date,
        startTime: new Date(first_date.getFullYear(), first_date.getMonth(), first_date.getDate(), 12, 0, 0),
        endDate: day_after,
        endTime: new Date(day_after.getFullYear(), day_after.getMonth(), day_after.getDate(), 12, 0, 0),
        progress: 0,
        assignees: [],
        reminders: [],
        predecessors: [],
    };
    vm.milestones = [];
    vm.timeOptions = { timeStripZeroSeconds: true };
    vm.startDatePopup = { opened: false };
    vm.endDatePopup = { opened: false };

    vm.statusOptions = [
        { id: 'O', label: 'Open' },
        { id: 'P', label: 'In Progress' },
        { id: 'C', label: 'Completed' },
        { id: 'A', label: 'Accepted' }
    ];
    vm.newTask.status = vm.statusOptions[0];
    vm.availableOptions = [];
    vm.reminderOptions = [
        { value: '15min', text: '15 minutes before' },
        { value: '30min', text: '30 minutes before' },
        { value: '1hour', text: '1 hour before' },
        { value: '2hour', text: '2 hours before' },
        { value: '1day', text: '1 day before' },
        { value: '2day', text: '2 days before' },
        { value: '1week', text: '1 week before' },
        { value: '30day', text: '30 days before' }
    ];
    vm.newReminder = {};
    vm.availablePredecessors = [];
    vm.progressDisabled = false;

    // Handles assign to team members list
    vm.teamMembers = [];
    strukshurApiService.projectTeamMembers.list({ project_id: project.id }).$promise
        .then(function (res) {
            res.members.forEach(function(member) {
                vm.teamMembers.push(member);
            });
        })
        .catch(function () {})
    ;

    // Display milestone selection widget if none was provided to the modal
    if (!milestone) {
        vm.milestones = vm.$parent.milestones;
    }

    /**
     * Displays start date picker
     */
    vm.showStartDate = function () {
        vm.startDatePopup.opened = true;
    };

    /**
     * Displays end date picker
     */
    vm.showEndDate = function () {
        vm.endDatePopup.opened = true;
    };

    /**
     * Sets the available predecessors based on the task's start date
     */
    vm.filterAvailablePredecessors = function () {
        vm.availablePredecessors = [];
        tasks.forEach(function (task) {
            if (moment(task.endDate * 1000).isSameOrBefore(moment.utc(vm.newTask.startDate), 'day')) {
                vm.availablePredecessors.push(task);
            }
        });
    };

    /**
     * Removes the invalid predecessors after the task has changed its start date
     */
    vm.removeInvalidDependencies = function () {
        vm.newTask.predecessors.forEach(function (dependency) {
            if (moment(dependency.endDate * 1000).isAfter(moment.utc(vm.newTask.startDate), 'day')) {
                _remove(vm.newTask.predecessors, dependency);
            }
        });
    };

    vm.removeReminderNew = function (reminder) {
        var i, tot;
        tot = vm.newTask.reminders.length;
        for (i = 0; i < tot; i++) {
            if (vm.newTask.reminders[i].option === reminder.option) {
                vm.newTask.reminders.splice(i, 1);
                break;
            }
        }
        vm.closeReminderNew();
    };

    vm.showReminderFormNew = function() {
        var i, tot, i2, tot2, found;
        vm.showNewReminderForm = true;
        vm.newReminder.option = null;
        vm.availableOptions = [];

        tot = vm.reminderOptions.length;
        tot2 = (vm.newTask.reminders ? vm.newTask.reminders.length : 0);
        for (i = 0; i < tot; i++) {
            found = false;
            for (i2 = 0; i2 < tot2; i2++) {
                if (vm.reminderOptions[i].value === vm.newTask.reminders[i2].option.value) {
                    found = true;
                    break;
                }
            }

            if (!found) {
                vm.availableOptions.push(vm.reminderOptions[i]);
            }
        }

        if (vm.availableOptions.length === 0) {
            vm.showNewReminderForm = false;
        } else {

            // Sets the first found option as the selected one
            vm.newReminder.option = vm.availableOptions[0];
        }
    };

    vm.addNewReminder = function () {
        vm.newTask.reminders.push({ option: vm.newReminder.option });
        vm.showNewReminderForm = false;
    };

    vm.closeReminderNew = function() {
        vm.showNewReminderForm = false;
    };

    /**
     * Callback fired when the user changes the start date
     */
    vm.onStartDateChanged = function () {
        vm.filterAvailablePredecessors();
        vm.removeInvalidDependencies();
    };

    /**
     * Callback fired when the user changes the status option
     */
    vm.onStatusChanged = function (newVal, oldVal) {

        // Changes progress to 100 if the task was marked as completed or accepted
        if (newVal && newVal.id === 'O') {
            vm.newTask.progress = 0;
            vm.progressDisabled = false;
        }

        // Changes progress to 100 if the task was marked as completed or accepted
        if (newVal && (newVal.id === 'C' || newVal.id === 'A')) {
            vm.newTask.progress = 100;
            vm.progressDisabled = true;
        }

        // Changes progress back to 50 if the task was changed from completed
        // or accepted to in-progress
        if (oldVal && (oldVal.id === 'C' || oldVal.id === 'A') && newVal && newVal.id === 'P') {
            vm.newTask.progress = 50;
            vm.progressDisabled = false;
        }
    };

    /**
     * Saves the task on the database
     *
     * @param  {object}  form  The form
     */
    vm.saveTask = function (form) {
        if (form.taskForm.$valid) {
            vm.errorMessage = '';

            if (vm.newTask.startDate && vm.newTask.startTime) {
                vm.newTask.startDate.setHours(vm.newTask.startTime.getHours());
                vm.newTask.startDate.setMinutes(vm.newTask.startTime.getMinutes());
                vm.newTask.startDate.setSeconds(0);
            } else {
                vm.newTask.reminders = [];
            }

            if (vm.newTask.endDate && vm.newTask.endTime) {
                vm.newTask.endDate.setHours(vm.newTask.endTime.getHours());
                vm.newTask.endDate.setMinutes(vm.newTask.endTime.getMinutes());
                vm.newTask.endDate.setSeconds(0);
            } else {
                vm.newTask.reminders = [];
            }

            if (!milestone && !vm.newTask.milestone) {
                vm.errorMessage = 'You need to select a milestone for the task.';
                return;
            }

            // Check if the start date is lower than the current date
            if (vm.newTask.startDate && vm.newTask.endDate && (moment(vm.newTask.startDate).diff(moment(vm.newTask.endDate), 'minutes') > 1)) {
                vm.errorMessage = 'The end date cannot be lower than the start date.';
                return;
            }

            // Task predecessors
            var predecessors: any[] = [];
            vm.newTask.predecessors.forEach(function (task) {
                predecessors.push(task.id);
            });

            // Task assignees
            var assignees: any[] = [];
            vm.newTask.assignees.forEach(function (assignee) {
                assignees.push(assignee.id);
            });

            // Task reminders
            var reminders: any[] = [];
            vm.newTask.reminders.forEach(function (reminder) {
                reminders.push({ option: reminder.option.value });
            });

            // Basic task data
            var data: any = {
                project_id: project.id,
                milestone_id: (milestone) ? milestone.id : vm.newTask.milestone.id,
                title: vm.newTask.title,
                description: vm.newTask.description,
                progress: Math.round(vm.newTask.progress),
                assignees: JSON.stringify(angular.copy(assignees)),
                reminders: JSON.stringify(angular.copy(reminders)),
                predecessors: JSON.stringify(angular.copy(predecessors)),
            };

            // Add optional fields if necessary
            if (vm.newTask.startDate) {
                data.start_date = moment.utc(vm.newTask.startDate).format('YYYY-MM-DD HH:mm');
            }
            if (vm.newTask.endDate) {
                data.end_date = moment.utc(vm.newTask.endDate).format('YYYY-MM-DD HH:mm');
            }
            if (vm.newTask.status) {
                data.status = vm.newTask.status.id;
            }

            // Saves the new task to the database
            vm.loading = true;
            strukshurApiService.projectTask.create(data).$promise
                .then(function (res) {
                    $uibModalInstance.close({ task: res.projectTask, milestone: vm.newTask.milestone });
                })
                .catch(function (res) {
                    if (res.status === 403) {
                        vm.errorMessage = 'You don\'t have the necessary permission to create the task.';
                    } else {
                        vm.errorMessage = 'There was an error trying to create the project task.';
                    }
                })
                .finally(function () {
                    vm.loading = false;
                })
            ;
        }
    };

    vm.filterAvailablePredecessors();
    vm.$watch('newTask.status', vm.onStatusChanged);
    vm.$watch('newTask.startDate', vm.onStartDateChanged);
})

.controller('ProjectDetailEditTaskCtrl', function ProjectDetailEditTaskCtrl ($scope, $timeout, $uibModalInstance, autofocusField, project, strukshurApiService, task, tasks) {
    var vm = $scope;

    vm.loading = false;
    vm.errorMessage = '';
    vm.editTask = {
        title: task.title,
        description: task.description,
        startDate: (task.startDate) ? new Date(task.startDate * 1000) : '',
        startTime: (task.startDate) ? new Date(task.startDate * 1000) : '', // remove seconds
        endDate: (task.endDate) ? new Date(task.endDate * 1000) : '',
        endTime: (task.endDate) ? new Date(Math.round(task.endDate / 100) * 100000) : '', // remove seconds
        reminders: [],
        progress: 0,
        predecessors: [],
        assignees: [],
    };
    vm.hasRelatedTasks = (task.predecessors.length > 0 || task.successors.length > 0);
    vm.datePickerStart = { minDate: null };
    vm.startDatePopup = { opened: false };
    vm.endDatePopup = { opened: false };
    vm.autofocusField = autofocusField;

    vm.statusOptions = [
        { id: 'O', label: 'Open' },
        { id: 'P', label: 'In Progress' },
        { id: 'C', label: 'Completed' },
        { id: 'A', label: 'Accepted' }
    ];
    vm.editTask.status = _find(vm.statusOptions, { id: task.status });

    vm.showNewReminderForm = false;
    vm.reminderOptions = [
        { value: '15min', text: '15 minutes before' },
        { value: '30min', text: '30 minutes before' },
        { value: '1hour', text: '1 hour before' },
        { value: '2hour', text: '2 hours before' },
        { value: '1day', text: '1 day before' },
        { value: '2day', text: '2 days before' },
        { value: '1week', text: '1 week before' },
        { value: '30day', text: '30 days before' }
    ];
    vm.newReminder = {};
    vm.availableOptions = [];
    vm.removedReminders = [];
    vm.availablePredecessors = [];
    vm.progressDisabled = false;

    /**
     * Bootstrap logic for the controller
     */
    vm.init = function () {

        // Handles autofocus logic for the selected field
        $timeout(function () {
            if (['title', 'description', 'progress'].includes(autofocusField)) {
                angular.element('#' + autofocusField).focus();
            } else if (autofocusField === 'status') {
                angular.element('.ui-select-status .btn-secondary.ui-select-toggle').click();
            } else if (autofocusField === 'assignees') {
                angular.element('.ui-select-assignees .ui-select-search').click();
            } else if (autofocusField === 'startDate') {
                vm.startDatePopup.opened = true;
            } else if (autofocusField === 'endDate') {
                vm.endDatePopup.opened = true;
            }
        }, 400);

        // Loads existing predecessors on the form
        task.predecessors.forEach(function (depId) {
            var depObj = _find(tasks, { id: depId });
            vm.editTask.predecessors.push(depObj);
        });

        // Timeout to resolve an issue with the progress indicator not loading the
        // correct value if set at the moment
        $timeout(function () {
            vm.editTask.progress = task.progress;
        }, 50);

        // Loads existing reminders
        if (task.reminders) {
            task.reminders.forEach(function (reminder) {
                var option = _find(vm.reminderOptions, { value: reminder.option });
                vm.editTask.reminders.push({ option: { id: reminder.id, value: reminder.option, text: option.text } });
            });
        }

        // Load available team members
        vm.teamMembers = [];
        strukshurApiService.projectTeamMembers.list({ project_id: project.id }).$promise
            .then(function (res) {
                res.members.forEach(function(member) {
                    vm.teamMembers.push(member);
                });

                // Load selected task assignees
                if (task.assignees) {
                    task.assignees.forEach(function (assignee) {
                        var member = _find(vm.teamMembers, { id: assignee.id });
                        if (member) {
                            vm.editTask.assignees.push(member);
                        }
                    });
                }

                // Set old task assignee to the list if it's not present yet
                if (task.assignedToId > 0) {
                    var alreadyAdded = _find(task.assignees, { id: task.assignedToId });
                    if (!alreadyAdded) {
                        var assignee = _find(vm.teamMembers, { id: task.assignedToId });
                        if (assignee) {
                            vm.editTask.assignees.push(assignee);
                        }
                    }
                }
            })
            .catch(angular.noop)
        ;

        vm.filterAvailablePredecessors();
        vm.$watch('editTask.status', vm.onStatusChanged);
        vm.$watch('editTask.startDate', vm.onStartDateChanged);
    };

    /**
     * Displays start date picker
     */
    vm.showStartDate = function () {
        vm.startDatePopup.opened = true;
    };

    /**
     * Displays end date picker
     */
    vm.showEndDate = function () {
        vm.endDatePopup.opened = true;
    };

    /**
     * Sets the available predecessors based on the task's start date
     */
    vm.filterAvailablePredecessors = function () {
        vm.availablePredecessors = [];
        tasks.forEach(function (task) {
            if (moment(task.endDate * 1000).isSameOrBefore(moment.utc(vm.editTask.startDate), 'day')) {
                vm.availablePredecessors.push(task);
            }
        });

        // Removes current task from the list of possible predecessors
        _remove(vm.availablePredecessors, { id: task.id });
    };

    /**
     * Removes the invalid predecessors after the task has changed its start date
     */
    vm.removeInvalidDependencies = function () {
        vm.editTask.predecessors.forEach(function (dependency) {
            if (moment(dependency.endDate * 1000).isAfter(moment.utc(vm.editTask.startDate), 'day')) {
                _remove(vm.editTask.predecessors, dependency);
            }
        });
    };

    vm.removeReminderEdit = function (reminder) {
        var i, tot;
        tot = vm.editTask.reminders.length;
        for (i = 0; i < tot; i++) {
            if ((reminder.id && vm.editTask.reminders[i].id === reminder.id) || (vm.editTask.reminders[i].option === reminder.option)) {
                vm.removedReminders.push(reminder);
                vm.editTask.reminders.splice(i, 1);
                break;
            }
        }
        vm.closeReminderEdit();
    };

    vm.showReminderFormEdit = function() {
        var i,tot,i2,tot2, found;

        vm.showNewReminderForm = true;
        vm.newReminder.option = null;
        vm.availableOptions = [];

        tot = vm.reminderOptions.length;
        tot2 = (vm.editTask.reminders ? vm.editTask.reminders.length : 0);
        for (i = 0; i < tot; i++) {
            found = false;

            for (i2 = 0; i2 < tot2; i2++) {
                if (vm.reminderOptions[i].value === vm.editTask.reminders[i2].option.value) {
                    found = true;
                    break;
                }
            }

            if (!found) {
                vm.availableOptions.push(vm.reminderOptions[i]);
            }
        }

        if (vm.availableOptions.length === 0) {
            vm.showNewReminderForm = false;
        } else {

            // Sets the first found option as the selected one
            vm.newReminder.option = vm.availableOptions[0];
        }
    };

    vm.addNewReminderEdit = function () {
        vm.editTask.reminders.push({ option: vm.newReminder.option });
        vm.showNewReminderForm = false;
    };

    vm.closeReminderEdit = function () {
        vm.showNewReminderForm = false;
    };

    /**
     * Callback fired when the user changes the start date
     */
    vm.onStartDateChanged = function () {
        vm.filterAvailablePredecessors();
        vm.removeInvalidDependencies();
    };

    /**
     * Callback fired when the user changes the status option
     */
    vm.onStatusChanged = function (newVal, oldVal) {

        // Changes progress to 100 if the task was marked as completed or accepted
        if (newVal && newVal.id === 'O') {
            vm.editTask.progress = 0;
            vm.progressDisabled = false;
        }

        // Changes progress to 100 if the task was marked as completed or accepted
        if (newVal && (newVal.id === 'C' || newVal.id === 'A')) {
            vm.editTask.progress = 100;
            vm.progressDisabled = true;
        }

        // Changes progress back to 50 if the task was changed from completed
        // or accepted to in-progress
        if (oldVal && (oldVal.id === 'C' || oldVal.id === 'A') && newVal && newVal.id === 'P') {
            vm.editTask.progress = 50;
            vm.progressDisabled = false;
        }
    };

    /**
     * Saves the task on the database
     *
     * @param  {object}  form  The form
     */
    vm.saveTaskEdit = function (form) {
        var data, i, tot;
        if (form.taskForm.$valid) {
            vm.errorMessage = '';

            // Check if the start date is lower than the current date
            if (vm.editTask.startDate && vm.editTask.startTime) {
                vm.editTask.startDate.setHours(vm.editTask.startTime.getHours());
                vm.editTask.startDate.setMinutes(vm.editTask.startTime.getMinutes());
                vm.editTask.startDate.setSeconds(0);
            } else {
                tot = vm.editTask.reminders.length;
                for (i=0;i<tot;i++) {
                    vm.removedReminders.push(angular.copy(vm.editTask.reminders[i]));
                }
                vm.editTask.reminders = [];

            }

            if (vm.editTask.endDate && vm.editTask.endTime) {
                vm.editTask.endDate.setHours(vm.editTask.endTime.getHours());
                vm.editTask.endDate.setMinutes(vm.editTask.endTime.getMinutes());
                vm.editTask.endDate.setSeconds(0);
            } else {
                tot = vm.editTask.reminders.length;
                for (i = 0; i < tot; i++) {
                    vm.removedReminders.push(angular.copy(vm.editTask.reminders[i]));
                }
                vm.editTask.reminders = [];
            }

            if (vm.editTask.startDate && vm.editTask.endDate && (moment(vm.editTask.startDate).diff(moment(vm.editTask.endDate), 'minutes') > 1)) {
                vm.errorMessage = 'The end date cannot be lower than the start date.';
                return;
            }

            // Task assignees
            var assignees = [];
            vm.editTask.assignees.forEach(function (assignee) {
                assignees.push(assignee.id);
            });

            // Task predecessors
            var predecessors = [];
            vm.editTask.predecessors.forEach(function (task) {
                predecessors.push(task.id);
            });

            // Task reminders
            var reminders = [];
            vm.editTask.reminders.forEach(function (reminder) {
                reminders.push({ id: reminder.option.id, option: reminder.option.value });
            });
            var removedReminders = [];
            vm.removedReminders.forEach(function (reminder) {
                removedReminders.push(reminder.option);
            });

            // Basic task data
            data = {
                task_id: task.id,
                title: vm.editTask.title,
                description: vm.editTask.description,
                progress: Math.round(vm.editTask.progress),
                assignees: JSON.stringify(assignees),
                reminders: JSON.stringify(angular.copy(reminders)),
                removedReminders: JSON.stringify(angular.copy(removedReminders)),
                predecessors: JSON.stringify(predecessors),
            };

            // Add optional fields if necessary
            if (vm.editTask.startDate) {
                data.start_date = moment.utc(vm.editTask.startDate).format('YYYY-MM-DD HH:mm');
            }
            if (vm.editTask.endDate) {
                data.end_date = moment.utc(vm.editTask.endDate).format('YYYY-MM-DD HH:mm');
            }
            if (vm.editTask.status) {
                data.status = vm.editTask.status.id;
            }

            // Saves the new task to the database
            vm.loading = true;
            strukshurApiService.projectTask.update(data).$promise
                .then(function (res) {
                    var task = res.projectTask;

                    $uibModalInstance.close({ task: task });
                })
                .catch(function (res) {
                    if (res.status === 403) {
                        vm.errorMessage = 'You don\'t have the necessary permission to edit the task.';
                    } else {
                        vm.errorMessage = 'There was an error trying to edit the project task.';
                    }
                })
                .finally(function () {
                    vm.loading = false;
                })
            ;
        }
    };

    vm.init();
})

.controller('ProjectDetailCopyTaskCtrl', function ProjectDetailCopyTaskCtrl ($scope, $uibModalInstance, milestones, project, strukshurApiService, task) {
    var vm = $scope;

    vm.loading = false;
    vm.errorMessage = '';
    vm.selectedProject = {};
    vm.loadingProjects = false;
    vm.loadingMilestones = false;
    vm.availableProjects = [];
    vm.availableMilestones = milestones;
    vm.newTask = {
        title: task.title + ' (copy)',
    };

    // Searches list of available projects for the current user
    vm.loadingProjects = true;
    strukshurApiService.projectprojects.query({ page: 1, limit: 50 }).$promise
        .then(function (res) {
            vm.loadingProjects = false;
            vm.newTask.project = project;
            vm.availableProjects = res.projects;

            // Select the first project and disable the field if the user only
            // has one project to choose from
            if (vm.availableProjects.length === 1) {
                vm.newTask.project = vm.availableProjects[0];
            }
        })
        .catch(function (res) {
            if (res.status === 403) {
                vm.errorMessage = 'You don\'t have the necessary permission to load the projects.';
            } else {
                vm.errorMessage = 'There was an error trying to load the projects.';
            }
        })
    ;

    /**
     * Callback fired when a project was selected by the user
     */
    vm.projectSelected = function () {
        vm.loadingMilestones = true;
        strukshurApiService.projectMilestones.list({ project_id: vm.newTask.project.id }).$promise
            .then(function (res) {
                vm.newTask.milestone = null;
                vm.loadingMilestones = false;
                vm.availableMilestones = res.projectMilestones;

                // Select the first milestone and disable the field if the user
                // only has one milestone to choose from
                if (vm.availableMilestones.length === 1) {
                    vm.newTask.milestone = vm.availableMilestones[0];
                }
            })
            .catch(function (res) {
                if (res.status === 403) {
                    vm.errorMessage = 'You don\'t have the necessary permission to load the milestones.';
                } else {
                    vm.errorMessage = 'There was an error trying to load the milestones.';
                }
            })
        ;
    };

    /**
     * Copies the milestone to the selected project on the backend
     *
     * @param  {Object}  form  The form
     */
    vm.confirmCopy = function (form) {
        if (form.$valid) {
            vm.errorMessage = '';

            if (!vm.newTask.project) {
                vm.errorMessage = 'You need to select a project to copy the task.';
            }

            if (!vm.newTask.milestone) {
                vm.errorMessage = 'You need to select a milestone to copy the task.';
            }

            var data = {
                task_id: task.id,
                project_id: vm.newTask.project.id,
                milestone_id: vm.newTask.milestone.id,
                title: vm.newTask.title,
            };

            // Copies the task to the selected project
            vm.loading = true;
            strukshurApiService.projectTask.copy(data).$promise
                .then(function (res) {
                    $uibModalInstance.close({ milestone_id: vm.newTask.milestone.id, task: res.projectTask });
                })
                .catch(function (res) {
                    if (res.status === 403) {
                        vm.errorMessage = 'You don\'t have the necessary permission to copy the project task.';
                    } else {
                        vm.errorMessage = 'There was an error trying to copy the project task.';
                    }
                })
                .finally(function () {
                    vm.loading = false;
                })
            ;
        }
    };
})

.controller('ProjectDetailDeleteTaskCtrl', function ($scope, $uibModalInstance, strukshurApiService) {
    var vm = $scope;

    vm.onConfirmChosen = function () {

        // Deletes the task on the server
        vm.loading = true;
        strukshurApiService.projectTask.delete({ task_id: vm.task.id }).$promise
            .then(function (res) {
                $uibModalInstance.close();
            })
            .catch(function (res) {
                if (res.status === 403) {
                    vm.errorMessage = 'You don\'t have the necessary permission to delete the task.';
                } else {
                    vm.errorMessage = 'There was an error trying to delete the task.';
                }
            })
            .finally(function () {
                vm.loading = false;
            })
        ;
    };
})

.controller('ProjectDetailNewTaskCommentCtrl', function ($scope, $uibModalInstance, project, strukshurApiService, task) {
    var vm = $scope;

    vm.loading = false;
    vm.errorMessage = '';
    vm.newComment = { comment: '' };

    /**
     * Saves the comment tn the database
     *
     * @param  {object}  form  The form
     */
    vm.saveComment = function (form) {
        if (form.commentForm.$valid) {
            vm.errorMessage = '';

            var data = {
                project_id: project.id,
                task_id: task.id,
                comment: vm.newComment.comment
            };

            // Saves the new task to the database
            vm.loading = true;
            strukshurApiService.projectTaskComment.create(data).$promise
                .then(function (res) {
                    var comment = res.projectTaskComment;

                    $uibModalInstance.close({ comment: comment });
                })
                .catch(function (res) {
                    if (res.status === 403) {
                        vm.errorMessage = 'You don\'t have the necessary permission to comment on the task.';
                    } else {
                        vm.errorMessage = 'There was an error trying to add the comment to the task.';
                    }
                })
                .finally(function () { vm.loading = false; })
            ;
        }
    };
})

.controller('ProjectDetailDeleteTaskCommentCtrl', function ($scope, $uibModalInstance, strukshurApiService) {
    var vm = $scope;

    vm.onConfirmChosen = function () {

        // Deletes the task comment on the server
        vm.loading = true;
        strukshurApiService.projectTaskComment.delete({ comment_id: vm.comment.id }).$promise
            .then(function (res) {
                $uibModalInstance.close();
            })
            .catch(function (res) {
                if (res.status === 403) {
                    vm.errorMessage = 'You don\'t have the necessary permission to delete the comment.';
                } else {
                    vm.errorMessage = 'There was an error trying to delete the comment.';
                }
            })
            .finally(function () {
                vm.loading = false;
            })
        ;
    };
})

.controller('ProjectDetailDeleteTaskFileCtrl', function ($scope, $uibModalInstance, strukshurApiService) {
    var vm = $scope;

    vm.onConfirmChosen = function () {

        // Deletes the task file on the server
        vm.loading = true;
        strukshurApiService.projectTaskFile.delete({ file_id: vm.file.id }).$promise
            .then(function (res) {
                $uibModalInstance.close();
            })
            .catch(function (res) {
                if (res.status === 403) {
                    vm.errorMessage = 'You don\'t have the necessary permission to delete the file.';
                } else {
                    vm.errorMessage = 'There was an error trying to delete the file.';
                }
            })
            .finally(function () {
                vm.loading = false;
            })
        ;
    };
})

;
