import * as angular from 'angular';
declare var fbq;

angular.module('strukshurApp.account', ['ui.router', 'ui.bootstrap'])

.config(function config($stateProvider) {
    $stateProvider
        .state('account', {
            url: '/account',
            views: {
                main: {
                    controller: 'AccountCtrl',
                    template: require('./account.tpl.html'),
                }
            },
            data: { pageTitle: 'Account', class: 'account' }
        })

        .state('register-auth', {
            url: '/register-auth',
            views: {
                main: {
                    controller: 'RegisterAuthCtrl',
                    template: require('./register_auth.tpl.html'),
                }
            },
            data: { pageTitle: 'Register', class: 'account page-account-auth template-page-simple' }
        })

        .state('register-auth-pro', {
            url: '/register-auth/pro',
            views: {
                main: {
                    controller: 'RegisterAuthProCtrl',
                    template: require('./register_auth_pro.tpl.html'),
                }
            },
            data: { pageTitle: 'Register Pro', class: 'account page-account-auth template-page-simple' }
        })

        .state('register-link', {
            url: '/register-link',
            views: {
                main: {
                    controller: 'RegisterLinkCtrl',
                    template: require('./register_link.tpl.html'),
                }
            },
            data: { pageTitle: 'Register', class: 'account page-account-auth template-page-simple' }
        })

        .state('logout', {
            url: '/logout',
            views: {
                main: {
                    controller: 'LogoutCtrl'
                }
            },
            data: { pageTitle: 'Logout' }
        })

        .state('proemailconfirmation', {
            url: '/pro/user/confirm_email/:plan/:user_id/:token',
            views: {
                main: {
                    controller: 'EmailConfirmationCtrl',
                    template: require('./email_confirmation.tpl.html'),
                }
            },
            resolve: {
                strukshurApiService: 'strukshurApiService',
                userEmailConfirmPromise: function (strukshurApiService, $state, $stateParams) {
                    var confirmation = strukshurApiService.userEmailConfirmation.save({ user_id: $stateParams.user_id, token: $stateParams.token }, function (d) {
                        console.log('userEmailConfirmation success');
                        console.log(d);
                    }, function (e) {
                        console.log('userEmailConfirmation error');
                        console.log(e);
                        $state.go('home');
                    });

                    return confirmation.$promise;
                }
            },
            data: { pageTitle: 'Email Confirmation', class: 'account page-account-auth email-confirmation-page' }
        })

        .state('emailconfirmation', {
            url: '/user/confirm_email/:plan/:user_id/:token',
            views: {
                main: {
                    controller: 'EmailConfirmationCtrl',
                    template: require('./email_confirmation.tpl.html'),
                }
            },
            resolve: {
                strukshurApiService: 'strukshurApiService',
                userEmailConfirmPromise: (strukshurApiService, $state, $stateParams) => {
                    const confirmation = strukshurApiService.userEmailConfirmation.save({ user_id: $stateParams.user_id, token: $stateParams.token }).$promise
                        .then(angular.noop)
                        .catch(() => /*$state.go('home')*/ 1)
                    ;

                    return confirmation.$promise;
                }
            },
            data: { pageTitle: 'Email Confirmation', class: 'account page-account-auth email-confirmation-page' }
        })

        .state('channelInvitation', {
            url: '/user/invitation/:project_id/:thread_id/:code',
            views: {
                main: {
                    controller: 'PricingCtrl',
                    template: require('../home/pricing.tpl.html'),
                }
            },
            data: { pageTitle: 'Pricing', class: 'home', headerLogoFull: true },
            resolve: {
                plans: () => 'pro',
            }
        })

        .state('projectInvitation', {
            url: '/user/invitation/:project_id/:code',
            views: {
                main: {
                    controller: 'PricingCtrl',
                    template: require('../home/pricing.tpl.html'),
                }
            },
            data: { pageTitle: 'Pricing', class: 'home', headerLogoFull: true },
            resolve: {
                plans: function () {
                    return 'pro';
                }
            }
        })

        .state('resetpassword', {
            url: '/user/password_reset/:token',
            views: {
                main: {
                    controller: 'ResetPasswordCtrl',
                    template: require('./reset_password.tpl.html'),
                }
            },
            data: { pageTitle: 'Reset Password', class: 'account page-account-auth template-page-simple' }
        })

        .state('proInvitationAccept', {
            url: '/organization/invite/accept/:member_id/:code',
            views: {
                main: {
                    controller: 'ProInvitationAcceptCtrl',
                    template: require('../home/home.tpl.html'),
                }
            },
            data: { pageTitle: 'Home', class: 'home', headerLogoFull: true }
        })

        .state('proInvitation', {
            url: '/organization/invite/:member_id/:code',
            views: {
                main: {
                    controller: 'PricingCtrl',
                    template: require('../home/pricing.tpl.html'),
                }
            },
            data: { pageTitle: 'Pricing', class: 'home', headerLogoFull: true },
            resolve: {
                plans: function () {
                    return 'pro';
                }
            }
        })
    ;
})

.controller('AccountCtrl', function AccountCtrl($scope) {
    var vm = $scope;
})

.controller('RegisterLinkCtrl', function RegisterLinkCtrl($rootScope, $scope, $state, strukshurAuthUserService) {
    var vm = $scope;

    // @TODO
    // prevent access you are already loggedin or have to active auth id/token
    if ($rootScope.loggedin || strukshurAuthUserService.getFacebookUserId() === '') {
        $state.go('home');
    }

    // pinterest would not work here since we dont get the email address in response
    vm.connectionType = 'Facebook';

    // Login and link accounts
    vm.formLogin = function (myform) {
        vm.loading = true;

        var promise = strukshurAuthUserService.login(myform);
        promise.then(
            function (data) {
                vm.loading = false;
                console.log('promise login Success');
                $state.go('ideas');
            },

            function (reason) {
                console.log('promise login Failed: ' + reason);
                vm.loading = false;
            }
        );
    };
})

.controller('RegisterAuthCtrl', function RegisterAuthCtrl($rootScope, $scope, $state, strukshurAuthUserService) {
    var vm = $scope;

    vm.form = {};
    vm.form.RegForm = {};

    vm.plan_id = 'basic';
    vm.loadingLogin = false;
    vm.loadingRegister = false;

    // @TODO add pinterest check here
    // prevent access you are already loggedin or have to active auth id/token
    if ($rootScope.loggedin || strukshurAuthUserService.hasAccessToken() === false) {
        $state.go('home');
    }

    // check which access token we have FB or Pinterest
    vm.connectionType = strukshurAuthUserService.getAuthConnectionType();

    vm.agree_terms = false;

    // passes in from auth response
    vm.setFormScope = function (scope) {
        scope.email = strukshurAuthUserService.getUserEmail();
        scope.first_name = strukshurAuthUserService.getUserFirstName();
        scope.last_name = strukshurAuthUserService.getUserLastName();
    };

    vm.formRegister = function (myform) {
        vm.loadingRegister = true;

        var promise = strukshurAuthUserService.register(myform);

        promise.then(function(data) {
            vm.loadingRegister = false;
            $state.go('setup-interior', {userSlug: data.user.slug});
        }, function(reason) {
            console.log('promise Failed: ' + reason);
            vm.loadingRegister = false;
        });
    };

    vm.formLogin = function(myform) {
        vm.loadingLogin = true;

        var promise = strukshurAuthUserService.login(myform);

        promise.then(function(data) {
            vm.loadingLogin = false;
            console.log('promise login Success');
            $state.go('ideas');

        }, function(reason) {
            console.log('promise login Failed: ' + reason);
            //myform.email.$setValidity('invalidlogin', false);
            vm.loadingLogin = false;
        });
    };
})

.controller('LogoutCtrl', function LogoutCtrl ($rootScope, $scope, strukshurAuthUserService, strukshurUserService) {
    var vm = $scope;

    strukshurAuthUserService.logout();
    strukshurUserService.setLoggedOut();
    $rootScope.loggedin = false;
    vm.user = false;

    delete vm.user;

    window.location.href = window.location.protocol + '//' + window.location.host;
})

.controller('EmailConfirmationCtrl', function EmailConfirmationCtrl ($scope, $timeout, strukshurAuthUserService, strukshurUserService, userEmailConfirmPromise) {
    var vm = $scope;

    vm.user = {};
    vm.confirmation_success = false;

    vm.init = () => {
        if (userEmailConfirmPromise.user !== undefined) {
            vm.user = userEmailConfirmPromise.user;

            strukshurUserService.setUser(userEmailConfirmPromise.user);

            // store jwt in case user didnt have from after the email click
            strukshurAuthUserService.setJwt(userEmailConfirmPromise.jwt);

            vm.confirmation_success = true;

            $timeout(() => {
                if (typeof fbq !== 'undefined') {
                    fbq('track', 'StartTrial');
                }
            }, 250);
        }
    };

    vm.init();
})

.controller('ResetPasswordCtrl', function ResetPasswordCtrl($scope, $stateParams, strukshurAuthUserService) {
    var vm = $scope;

    vm.form = {};
    vm.formSuccess = false;
    vm.token = $stateParams.token;

    vm.formResetPassword = function(myform) {
        vm.loading = true;
        myform.token = vm.token;

        var promise = strukshurAuthUserService.resetPassword(myform);
        promise.then(function(greeting) {
            vm.loading = false;
            vm.formSuccess = true;
            console.log('promise resetPassword Success: ' + greeting);
            //$state.go('home', {}, {reload: true});
            vm.formSuccess = true;
        }, function(reason) {
            console.log('promise resetPassword Failed: ' + reason);
            vm.loading = false;
        });
    };
})

.controller('ProInvitationAcceptCtrl', function ProInvitationAcceptCtrl ($scope, $state, $stateParams, $uibModal, strukshurApiService, toastr) {
    var vm = $scope;

    //make readonly the email
    vm.code = $stateParams.code;
    vm.memberId = $stateParams.member_id;

    // Validate invitation
    strukshurApiService.organizationMember.validateInvite({ member_id: vm.memberId, code: vm.code }).$promise
        .then(function (res) {
            if (res.member && res.member.email) {
                vm.email = res.member.email;
                vm.fromOrganizationInvitation = true;

                if (res.member.status === 'active') {
                    vm.openLogin();
                } else {
                    strukshurApiService.organizationMember.acceptInvite({ member_id: vm.memberId, code: vm.code, user_slug: res.user.slug }).$promise
                        .then(function (res) {
                            var organization = res.organization;

                            vm.loading = false;
                            window.location.href = $state.get('organization').url.replace(':slug', organization.slug).replace(':id', organization.id);
                        })
                        .catch(function (res) {
                            vm.handleAcceptInviteError(res);
                        })
                    ;
                }
            }
        })
        .catch(function (res) {
            vm.handleAcceptInviteError(res);
        })
    ;

    vm.animationsEnabled = true;
    vm.openLogin = function () {
        var modalInstance = $uibModal.open({
            scope: vm,
            keyboard: true,
            animation: vm.animationsEnabled,
            ariaLabelledBy: 'modal-title',
            ariaDescribedBy: 'modal-body',
            template: require('../../common/templates/login-modal.tpl.html'),
            controller: 'ModalLoginCtrl',
            resolve: {
                items: function () {
                    return vm.items;
                }
            }
        });

        modalInstance.result.then(function (selectedItem) {
            //vm.selected = selectedItem;
        }, function () {
            //$log.info('Modal dismissed at: ' + new Date());
        });
    };

    vm.handleAcceptInviteError = function (res) {
        if (res.status === 403) {
            toastr.error('You don\'t have the necessary permission to accept the invite.', 'Error');
        } else if (res.status === 404) {
            toastr.error('The invite for the organization has either expired or been deleted.', 'Error');
        } else {
            toastr.error('There was an error trying to accept the invite.', 'Error');
        }
    };
})
;
