import {
    clone as _clone,
    filter as _filter,
    find as _find,
    has as _has,
    orderBy as _orderBy,
    pull as _pull,
    remove as _remove,
} from 'lodash';
import * as angular from 'angular';

angular.module('strukshurApp.upload', [])

.controller('DefaultUploadManagerModal', function DefaultUploadManagerModal ($rootScope, $scope, fileReaderService, Flash, strukshurUploadService) {
    var vm = $scope;

    vm.form = {};
    vm.uploading = false;
    vm.queuedFiles = [];
    vm.upgradeLink = false;
    vm.errorMessage = null;
    vm.hasFilesToUpload = false;
    vm.uploadQueueError = false;
    vm.fileBeingUploaded = null;
    vm.totalUploadingItems = 0;
    vm.currentUploadingItemIndex = 0;

    /**
     * Controller initialization logic
     */
    vm.init = () => {
        vm.uploader = strukshurUploadService.getUploader();
        vm.queuedFiles = strukshurUploadService.getQueue();
        vm.checkHasFileToUpload();

        vm.queuedFiles.forEach((file) => {
            if (file.isSupportedImage) {
                fileReaderService.readAsDataUrl(file._file, vm)
                    .then((result) => {
                        document.getElementById(file.id).style.backgroundImage = 'url('+result+')';
                        result = null; // eagerly clean memory to improve memory performance
                    })
                ;
            }
        });

        strukshurUploadService.subscribe('upload-items-added', vm, vm.checkHasFileToUpload);
    };

    /**
     * Closes the modal
     */
    vm.close = () => {
        $rootScope.closeUploadManagerModal();
    };

    /**
     * Quickstarts the upload proccess for the selected files
     */
     vm.startUpload = () => {

        // early return if the upload is already in progress
        if (strukshurUploadService.uploading) { return; }

        let processErrorMessage = null;

        vm.upgradeLink = false;
        vm.errorMessage = null;

        // Check if the file queue is empty
        if (vm.queuedFiles.length === 0) {
            processErrorMessage = 'You must include at least one file to upload.';
        }

        // The item titles cannot be empty
        vm.queuedFiles.forEach((file) => {
            if (file.title.trim() === '') {
                processErrorMessage = 'The item title cannot be empty.';
                return;
            }
        });

        // Do not proceed if an error has been found
        if (processErrorMessage) {
            Flash.create('danger', processErrorMessage, 10000, { container: 'error-message-flash' }, true);
            return;
        }

        strukshurUploadService.startUpload();
    };

    /**
     * Triggers file selection
     */
     vm.addFile = () => {
        if (vm.uploading) { return; }

        document.getElementById('file-upload-input').click();
    };

    /**
     * Removes a file from queue
     *
     * @param  {object}  file  The file to remove from the queue
     */
     vm.removeFile = (file) => {
        if (file.uploading || file.isUploaded) { return; }

        strukshurUploadService.removeFromQueue(file);
        _remove(vm.queuedFiles, file);
        vm.checkHasFileToUpload();
    };

    /**
     * Cancels the file being currently uploaded
     */
    vm.cancelUploadingFile = () => {
        strukshurUploadService.cancelUploadingFile();
        vm.checkHasFileToUpload();
    };

    /**
     * Clears the current file queue
     */
    vm.clearQueue = () => {
        if (strukshurUploadService.uploading) { return; }

        strukshurUploadService.clearQueue();
        vm.queuedFiles = strukshurUploadService.getQueue();
        vm.checkHasFileToUpload();
    };

    /**
     * Cancels all the uploads currently queued
     */
    vm.cancelUploadQueue = () => {
        vm.uploading = false;
        strukshurUploadService.cancelAll();
    };

    /**
     * Checks wether there's still any file needed to be uploaded
     */
    vm.checkHasFileToUpload = () => {
        vm.hasFilesToUpload = strukshurUploadService.hasFileToUpload();
    };

    vm.init();
})

.controller('FilePickerModal', function FilePickerModal ($rootScope, $scope, $uibModalInstance, FileService, options, strukshurApiService) {
    var vm = $scope;

    vm.files = [];
    vm.folders = [];
    vm.selected = [];
    vm.loading = false;
    vm.breadcrumb = [];
    vm.options = options;
    vm.errorMessage = null;
    vm.availableProjects = [];
    vm.currentFolder = null;
    vm.currentProject = null;
    vm.currentOrganization = null;
    vm.currentRootItem = null;
    vm.allowFileSelect = false;
    vm.allowMultiSelect = false;
    vm.allowFolderSelect = false;
    vm.allowedFileExtensions = [];
    vm.availableOrganizations = [];
    vm.selectionMode = 'file'; // 'file', 'folder', 'both'

    /**
     * Controller initialization logic
     */
    vm.init = () => {
        vm.setupModal();
        vm.loadProjects();
        vm.loadOrganizations();

        // If a project was provided, we load its root files and folders as well
        if (vm.currentProject || vm.currentOrganization) {
            vm.loadFiles();
        }
    };

    /**
     * Sets up the modal based on the current options
     */
    vm.setupModal = () => {
        vm.currentFolder = options.folder;
        vm.currentProject = options.project;
        vm.currentOrganization = options.organization;
        vm.currentRootItem = options.project ?? options.organization;
        vm.allowFileSelect = (options.allowFileSelect !== false);
        vm.allowMultiSelect = (options.allowMultiSelect === true);
        vm.allowFolderSelect = (options.allowFolderSelect !== false);
        vm.allowedFileExtensions = options.allowedFileExtensions || [];
    }

    /**
     * Loads files based on the current project and folder
     *
     * @param  {object}  folder  An optional folder to load the files from
     */
    vm.loadFiles = (folder) => {

        // Creates search request based on the received folder id
        let promise;
        if (folder) {
            if (vm.currentOrganization) {
                promise = strukshurApiService.organizationFolder.get({ folder_id: folder.id }).$promise;
            } else {
                promise = strukshurApiService.projectFolder.get({ folder_id: folder.id }).$promise;
            }
        } else if (vm.currentProject) {
            promise = strukshurApiService.projectFolders.listRootAssets({ project_id: vm.currentProject.id }).$promise;
        } else if (vm.currentOrganization) {
            promise = strukshurApiService.organizationFolders.listRootAssets({ organization_id: vm.currentOrganization.id }).$promise;
        }

        vm.loading = true;
        promise
            .then(res => {
                if (folder) {
                    vm.currentFolder = (vm.currentProject) ? res.projectFolder : res.folder;
                    res.path = (vm.currentProject) ? res.projectFolder.path : res.folder.path;
                    res.files = (vm.currentProject) ? res.projectFolder.files : res.folder.files;
                    res.folders = (vm.currentProject) ? res.projectFolder.folders : res.folder.folders;
                } else {
                    vm.currentFolder = null;
                    vm.currentRootItem = vm.currentProject || vm.currentOrganization;
                }

                // Set files cover based on their extension
                res.files.forEach(file => {
                    file.isFile = true;
                    file.selected = false;
                    file.extension = FileService.getFileExtension(file.file);
                    file.fileType = FileService.getFileType(file.file);
                    file.disabled = vm.allowedFileExtensions.length > 0 && !vm.allowedFileExtensions.includes(file.extension);
                    vm.setFileCover(file);
                });

                // Marks all folder objects accordingly
                res.folders.forEach(folder => {
                    folder.isFolder = true;
                    folder.selected = false;
                });

                vm.files = res.files;
                vm.folders = res.folders;

                // Pops out all elements from the breadcrumb after the selected folder
                vm.breadcrumb = res.path || [];

                vm.sortItems('name-asc');
            })
            .finally(() => vm.loading = false)
        ;
    };

    /**
     * Loads all available projects for the current user
     */
    vm.loadProjects = () => {
        vm.availableProjects = _clone($rootScope.userProjects);
        vm.availableProjects.forEach(project => project.itemType = 'project');
    };

    /**
     * Loads all available organizations for the current user
     */
     vm.loadOrganizations = () => {
        vm.availableOrganizations = _clone($rootScope.userOrganizations);
        vm.availableOrganizations.forEach(organization => organization.itemType = 'organization');
    };

    /**
     * Navigates to the given folder on the selection view
     *
     * @param  {object}  folder  The folder
     */
    vm.goToFolder = (folder) => {
        if (vm.currentFolder && vm.currentFolder.id === folder.id) { return; }

        vm.loadFiles(folder);

        // Pops out all elements from the breadcrumb after the selected folder
        vm.breadcrumb = vm.breadcrumb.splice(0, vm.breadcrumb.indexOf(folder)+1);
    };

    /**
     * Handles project selection
     *
     * @param  {object}  project  The selected project
     */
    vm.projectSelected = (project) => {
        if (vm.currentProject && project.id === vm.currentProject.id) { return; }

        vm.selected = [];
        vm.currentFolder = null;
        vm.currentProject = project;
        vm.currentOrganization = null;
        vm.loadFiles();
    };

    /**
     * Handles organization selection
     *
     * @param  {object}  organization  The selected organization
     */
     vm.organizationSelected = (organization) => {
        if (vm.currentOrganization && organization.id === vm.currentOrganization.id) { return; }

        vm.selected = [];
        vm.currentFolder = null;
        vm.currentProject = null;
        vm.currentOrganization = organization;
        vm.currentRootItem = organization;
        vm.currentRootItem.title = organization.name;
        vm.loadFiles();
    };

    /**
     * Sets cover image for the given file
     *
     * @param  {object}  file  The file
     */
     vm.setFileCover = (file) => {
        const ext = FileService.getFileExtension(file.filename);

        if (FileService.supportsImagePreviewExtension(ext)) {
            file.imgSrc = file.file;
            file.elStyle = { 'background-image': `url('${file.imgSrc}');` };
            file.isSupportedImagePreview = true;
            file.fileClass = { 'no-filter': true };
        } else {
            file.isSupportedImagePreview = false;
            file.imgSrc = 'assets/icons/file-filled.svg';
        }
    };

    /**
     * Sets the sort order for the current files
     *
     * @param  {string}  sortBy  The field to sort by
     */
    vm.setSortMethod = (sortBy) => {
        if (vm.sortBy === sortBy) { return; }

        vm.sortBy = sortBy;
        vm.sortItems(sortBy);
    };

    /**
     * Sorts files based on the given field
     *
     * @param  {string}  sortBy  The field to sort by
     */
    vm.sortItems = (sortBy) => {
        const sort = sortBy.split('-')[0],
            order = sortBy.split('-')[1];

        // Sort by name
        if (sort === 'name') {
            vm.folders = _orderBy(vm.folders, ['favorite', 'name'], ['desc', order]);
            vm.files = _orderBy(vm.files, ['favorite', 'filename'], ['desc', order]);
        } else if (sort === 'date') {
            vm.files = _orderBy(vm.files, ['favorite', 'createdAt'], ['desc', order]);
        } else if (sort === 'size') {
            vm.files = _orderBy(vm.files, ['favorite', 'size'], ['desc', order]);
        }
    };

    /**
     * Handles selection toggle for the given item
     *
     * @param  {object}  item  The item
     */
    vm.toggleSelection = (item) => {
        if (item.disabled) { return; }
        if (item.isFile && vm.selectionMode === 'folder') { return; }
        if (item.isFolder && vm.selectionMode === 'file') { return; }

        // Handles case where multi select is disabled
        if (!vm.allowMultiSelect && !item.selected) {
            vm.files.forEach(file => file.selected = false);
            vm.folders.forEach(folder => folder.selected = false);
        }

        item.selected = !item.selected;
        vm.updateSelected();
    };

    /**
     * Goes back to the root of the current project / organization
     */
    vm.goToRoot = () => {
        vm.breadcrumb = [];
        vm.currentFolder = null;
        vm.loadFiles();
    };

    /**
     * Navigates to the given folder
     *
     * @param  {object}  folder  The folder
     */
    vm.openFolder = (folder) => {
        if (vm.currentFolder && vm.currentFolder.id === folder.id) { return; }

        vm.selected = [];
        vm.currentFolder = null;
        vm.loadFiles(folder);
    };

    /**
     * Update selected items based on their state
     */
    vm.updateSelected = () => {
        vm.selected = _filter([...vm.files, ...vm.folders], item => item.selected);
    };

    /**
     * Confirm item(s) selection
     */
    vm.confirm = () => {
        $uibModalInstance.close({ selected: vm.selected });
    };

    vm.init();
})

;
