import * as angular from 'angular';
import strukshurConfig from '../../config.ts';

var app = angular.module('strukshurApiService', ['angularLocalStorage', 'ngResource']);
app.factory('strukshurApiService', ['$resource', 'storage', '$http', '$rootScope','$timeout', function ($resource, storage, $http, $rootScope, $timeout) {
    if (storage.get('jwt') !== null && storage.get('jwt') !== '') {
        $http.defaults.headers.common.Authorization = 'Bearer ' + storage.get('jwt');
    }

    return {
        setReauth: function() {
            /**
             * set when user must reauthenticate, the JWT should not be sent with the request
             * @type {string}
             */
            $http.defaults.headers.common.Authorization = '';
        },

        setJwt: function (jwt) {
            $http.defaults.headers.common.Authorization = 'Bearer ' + jwt;
        },

        userAuthFacebookCheck: $resource(strukshurConfig.domain + '/user/check/facebook', {} ,
            {
                query: {method: 'POST', params: {fbid: "@fbid", fbtoken: "@fbtoken"}}
            }
        ),
        userAuthPinterestCheck: $resource(strukshurConfig.domain + '/user/check/pinterest', {} ,
            {
                query: {method: 'POST', params: {pid: "@pid", ptoken: "@ptoken"}}
            }
        ),
        userEmailCheck: $resource(strukshurConfig.domain + '/user/check/account', {} ,
            {
                query: {method: 'POST', params: {email: "@email"}, isArray: true}
            }
        ),
        userForgotPassword: $resource(strukshurConfig.domain + '/user/forgotpassword', {} ,
            {
                save: {method: 'POST', params: {email: "@email"}}
            }
        ),
        userEmailConfirmation: $resource(strukshurConfig.domain + '/user/emailconfirmation', {} ,
            {
                save: {method: 'POST', params: {user_id: "@user_id", token: "@token"}}
            }
        ),
        postAccountLinkFacebook: $resource(strukshurConfig.api_url + '/me/account/link/facebook', {} ,
            {
                save: {method: 'POST', params: {fbid: "@fbid", fbtoken: "@fbtoken"}}
            }
        ),
        postAccountLinkPinterest: $resource(strukshurConfig.api_url + '/me/account/link/pinterest', {} ,
            {
                save: {method: 'POST', params: {pid: "@pid", ptoken: "@ptoken"}}
            }
        ),
        getUserDetails: $resource(strukshurConfig.api_url + '/me/details', {},
            {
                query: { method: 'GET' }
            }
        ),
        getStorageInfo: $resource(strukshurConfig.api_url + '/me/storage', {},
            {
                query: { method: 'GET' }
            }
        ),
        tour: $resource(strukshurConfig.api_url + '/me', {},
            {
                setUserHasSeenMyHomeTour: { method: 'PUT', ignoreLoadingBar: true, url: strukshurConfig.api_url + '/me/has-seen-my-home-tour' }
            }
        ),
        searchRoomTypes: $resource(strukshurConfig.api_url + '/admin/room/find', {} ,
            {
                query: {method: 'GET', params: {terms: "@terms"}}
            }
        ),
        getAdminRoomProps: $resource(strukshurConfig.api_url + '/admin/room/props', {} ,
            {
                query: {method: 'GET', params: {page: "@page"}}
            }
        ),
        RoomProp: $resource(strukshurConfig.api_url + '/admin/room/prop', {} ,
            {
                query: {method: 'GET', params: {id: "@id"}},
                add: {method: 'POST'},
                update: {method: 'PUT'}
            }
        ),
        RoomAttr: $resource(strukshurConfig.api_url + '/admin/room/attr', {} ,
            {
                //query: {method: 'GET', params: {id: "@id"}},
                add: {method: 'POST'},
                update: {method: 'PUT'},
                delete: {method: 'DELETE'}
            }
        ),
        searchRoomProps: $resource(strukshurConfig.api_url + '/admin/room/props/find', {} ,
            {
                query: {method: 'POST'}
            }
        ),
        searchSubAttrs: $resource(strukshurConfig.api_url + '/admin/room/sub/attr/find', {} ,
            {
                query: {method: 'POST'}
            }
        ),
        getAdminProductCategories: $resource(strukshurConfig.api_url + '/admin/product/categories', {} ,
            {
                query: {method: 'GET'}
            }
        ),

        getRooms: $resource(strukshurConfig.api_url + '/browse/rooms', {} ,
            {
                query: {method: 'GET'}
            }
        ),
        getRoom: $resource(strukshurConfig.api_url + '/browse/room', {} ,
            {
                query: {method: 'GET', params: {user_slug: "@user_slug", room_slug: "@room_slug", page: "@page"}}
            }
        ),
        getRoomBoard: $resource(strukshurConfig.api_url + '/browse/room/board', {} ,
            {
                query: {method: 'GET', params: {user_slug: "@user_slug", room_slug: "@room_slug", page: "@page"}}
            }
        ),
        postRoomBoardImport: $resource(strukshurConfig.api_url + '/room/board/import', {},
            {
                save: { method: 'POST' }
            }
        ),
        postRoomProductAdd: $resource(strukshurConfig.api_url + '/room/product/add', {} ,
            {
                save: {method: 'POST'}
            }
        ),
        roomChecklist: $resource(strukshurConfig.api_url + '/room/checklist/details', {} ,
            {
                query: {method: 'GET', params: {room_slug: "@room_slug"}},
                save: {method: 'POST', params: {room_prop_id: "@room_prop_id", room_slug: "@room_slug", checklist: "@checklist"}}
            }
        ),
        getPropAttr: $resource(strukshurConfig.api_url + '/room/prop/attr', {} ,
            {
                query: {method: 'GET', params: {prop_id: "@prop_id"}}
            }
        ),
        getRoomProps: $resource(strukshurConfig.api_url + '/room/props', {} ,
            {
                query: {method: 'GET', params: {room_slug: "@room_slug"}}
            }
        ),
        getRoomProduct: $resource(strukshurConfig.api_url + '/room/product', {} ,
            {
                add: {method: 'POST', params: {room_slug: "@room_slug", product_slug: "@product_slug"}}
            }
        ),

        // Pin
        pinTag: $resource(strukshurConfig.api_url + '/pin/tag', {} ,
            {
                add: {method: 'POST', params: {id: "@id", note: "@note", prop_id: "@prop_id", attr_id: "@attr_id"}},
                update: {method: 'PUT', params: {checklist: "@checklist", prop_id: "@prop_id", room_slug: "@room_slug"}},
                delete: {method: 'DELETE', params: {id: "@id"}}
            }
        ),
        getPin: $resource(strukshurConfig.api_url + '/pin/detail', {} ,
            {
                query: {method: 'GET', params: {id: "@id"}},
                delete: {method: 'DELETE', params: {pin_id: "@pin_id"}}
            }
        ),
        getAllPinsRoom: $resource(strukshurConfig.api_url + '/pins/all/room', {} ,
            {
                query: {method: 'GET', params: {user_slug: "@user_slug", room_slug: "@room_slug"}}
            }
        ),
        createPin: $resource(strukshurConfig.api_url + '/pin/create', {} ,
            {
                add: {
                    method: 'POST',
                    uploadEventHandlers: {
                        progress: function(e) {
                            var progress = Math.round(e.lengthComputable ? e.loaded * 100 / e.total : 0);
                            var name = 'createPin.add';
                            //console.log('Progress -> ',e);
                            if(progress>=100){
                                if(progress>=100){
                                    $timeout(function (progress) {
                                        $rootScope.$broadcast('updateProgress', {name: name, progress: progress});
                                        $timeout(function (progress) {
                                            $rootScope.$broadcast('updateProgress', {name: name, progress: progress});
                                        }, 200, true, progress);
                                    }, 200, true, progress);
                                }
                            }
                            else{
                                $rootScope.$broadcast('updateProgress', {name: name, progress: progress});
                            }
                        }
                    }
                }
            }
        ),
        getPinVideoStatus: $resource(strukshurConfig.api_url + '/pin/video/status', {} ,
            {
                query: {method: 'GET', params: {pin_id: "@pin_id"}, ignoreLoadingBar: true}
            }
        ),

        // Projects
        projects: $resource(strukshurConfig.api_url + '/projects', {},
            {
                query: { method: 'GET' },
                deleted: { method: 'GET', url: strukshurConfig.api_url + '/projects/deleted' },
                ownedProjectsCount: { method: 'GET', url: strukshurConfig.api_url + '/projects/owned-count' },
            }
        ),

        bidsActive: $resource(strukshurConfig.api_url + '/bids/my', {},
            {
                query: { method: 'GET' }
            }
        ),
        project: $resource(strukshurConfig.api_url + '/project', {},
            {
                get: { method: 'GET', params: { project_slug: '@project_slug' } },
                add: {
                    method: 'POST',
                    uploadEventHandlers: {
                        progress: function(e) {
                            var progress = Math.round(e.lengthComputable ? e.loaded * 100 / e.total : 0);
                            var name = 'projectFile';
                            //console.log('Progress -> ',e);
                            if(progress>=100){
                                if(progress>=100){
                                    $timeout(function (progress) {
                                        $rootScope.$broadcast('updateProgress', {name: name, progress: progress});
                                        $timeout(function (progress) {
                                            $rootScope.$broadcast('updateProgress', {name: name, progress: progress});
                                        }, 200, true, progress);
                                    }, 200, true, progress);
                                }
                            }
                            else{
                                $rootScope.$broadcast('updateProgress', {name: name, progress: progress});
                            }
                        }
                    }
                },
                update: {
                    method: 'PUT',
                    uploadEventHandlers: {
                        progress: function(e) {
                            var progress = Math.round(e.lengthComputable ? e.loaded * 100 / e.total : 0);
                            var name = 'projectFile';
                            //console.log('Progress -> ',e);
                            if(progress>=100){
                                if(progress>=100){
                                    $timeout(function (progress) {
                                        $rootScope.$broadcast('updateProgress', {name: name, progress: progress});
                                        $timeout(function (progress) {
                                            $rootScope.$broadcast('updateProgress', {name: name, progress: progress});
                                        }, 200, true, progress);
                                    }, 200, true, progress);
                                }
                            }
                            else{
                                $rootScope.$broadcast('updateProgress', {name: name, progress: progress});
                            }
                        }
                    }
                },
                restore: { method: 'POST', params: { project_id: '@project_id' }, url: strukshurConfig.api_url + '/project/restore' },
                duplicate: { method: 'POST', params: { project_id: '@project_id' }, url: strukshurConfig.api_url + '/project/duplicate' },
                delete: { method: 'DELETE', params: { project_slug: '@project_slug' } },
                remove: { method: 'DELETE', params: { project_id: '@project_id' }, url: strukshurConfig.api_url + '/project/remove' }
            }
        ),
        projectsNear: $resource(strukshurConfig.api_url + '/projects/near', {} ,
            {
                query: { method: 'GET', params: { role_id: '@role_id' } },
            }
        ),
        projectprojects: $resource(strukshurConfig.api_url + '/project/projects', {} ,
            {
                query: { method: 'GET' },
            }
        ),
        projectTeamMember: $resource(strukshurConfig.api_url + '/project/member', {},
            {
                invite: { method: 'POST', url: strukshurConfig.api_url + '/project/member/invite' },
                resendInvite: { method: 'PUT', params: { project_id: '@project_id', invite_id: '@invite_id' }, url: strukshurConfig.api_url + '/project/invite/resend' },
                get: { method: 'GET', params: { project_id: '@project_id', member_id: '@member_id' } },
                update: { method: 'PUT', params: { project_id: '@project_id', member_id: '@member_id' } },
                remove: { method: 'DELETE', params: { project_id: '@project_id', member_id: '@member_id' } },
                removeInvite: { method: 'DELETE', params: { project_id: '@project_id', invite_id: '@invite_id' }, url: strukshurConfig.api_url + '/project/invite' }
            }
        ),
        projectTeamMembers: $resource(strukshurConfig.api_url + '/project/members', {},
            {
                list: { method: 'GET', params: { project_id: '@project_id' } }
            }
        ),
        availableProjectRoom: $resource(strukshurConfig.api_url + '/room/available-project-rooms', {},
            {
                list: { method: 'GET', params: { project_id: '@project_id' } }
            }
        ),
        projectRoomWithPin: $resource(strukshurConfig.api_url + '/room/rooms-with-pins', {},
            {
                list: { method: 'GET', params: { project_id: '@project_id' } }
            }
        ),
        projectsSample: $resource(strukshurConfig.api_url + '/bids/sample', {},
            {
                list: { method: 'GET' }
            }
        ),

        // Taxes

        taxes: $resource(strukshurConfig.api_url + '/taxes', {} ,
            {
                query: {method: 'GET', params: {zip: '@zip'}}
            }
        ),

        // Shipping Methods

        shippingMethods: $resource(strukshurConfig.api_url + '/shipping/methods', {} ,
            {
                calculate: {method: 'POST'}
            }
        ),

        //
        checkoutPaymentCard: $resource(strukshurConfig.api_url + '/payment/checkout/card', {} ,
            {
                save: {method: 'POST'}
            }
        ),

        // Bid

        bid: $resource(strukshurConfig.api_url + '/bid', {} ,
            {
                add: {method: 'POST'}
            }
        ),
        acceptBid: $resource(strukshurConfig.api_url + '/bid/accept', {},
            {
                execute: { method: 'POST', params: { bid_id: '@bid_id' }}
            }
        ),
        rejectBid: $resource(strukshurConfig.api_url + '/bid/reject', {},
            {
                execute: { method: 'POST', params: { bid_id: '@bid_id' }}
            }
        ),

        // Store

        product: $resource(strukshurConfig.domain + '/public/product', {},
            {
                get: { method: 'GET', params: { id: '@id' } },
                getSimilar: { method: 'GET', params: { id: '@id' }, url: strukshurConfig.domain + '/public/product/similar' },
            }
        ),

        products: $resource(strukshurConfig.domain + '/public/products', {},
            {
                listPopular: { method: 'GET', url: strukshurConfig.domain + '/public/products/popular' },
            }
        ),

        productCategory: $resource(strukshurConfig.domain + '/public/product/category', {},
            {
                listProducts: { method: 'GET', params: { category: '@category', page: '@page' }, url: strukshurConfig.domain + '/public/product/category/products' },
            }
        ),

        productCategories: $resource(strukshurConfig.domain + '/public/product/categories', {},
            {
                list: { method: 'GET' }
            }
        ),

        productVendor: $resource(strukshurConfig.domain + '/public/product/vendor', {},
            {
                get: { method: 'GET', params: { slug: '@slug' } },
                listProducts: { method: 'GET', params: { vendor: '@vendor', page: '@page' }, url: strukshurConfig.domain + '/public/product/vendor/products' },
            }
        ),

        productVendors: $resource(strukshurConfig.domain + '/public/product/vendors', {},
            {
                list: { method: 'GET' },
            }
        ),

        getProductCategories: $resource(strukshurConfig.api_url + '/product/categories', {} ,
            {
                query: {method: 'GET'}
            }
        ),
        getStoreVendors: $resource(strukshurConfig.api_url + '/store/vendors', {} ,
            {
                query: {method: 'GET'}
            }
        ),
        getStoreVendor: $resource(strukshurConfig.api_url + '/store/vendor', {} ,
            {
                query: {method: 'GET', params: {slug: "@slug"}}
            }
        ),
        getProductsByVendor: $resource(strukshurConfig.api_url + '/products/vendor', {} ,
            {
                query: {method: 'GET', params: {vendor: "@vendor", page: "@page"}}
            }
        ),
        // subscription payment

        userSubscription: $resource(strukshurConfig.api_url + '/payment/subscribe', {} ,
            {
                save: {method: 'POST'},
                cancel: {method: 'DELETE'}
            }
        ),
        couponValidate: $resource(strukshurConfig.api_url + '/payment/coupon', {} ,
            {
                query: {method: 'GET', params: {coupon: '@coupon'}}
            }
        ),
        // Pro
        proDetails: $resource(strukshurConfig.api_url + '/pro/details', {} ,
            {
                query: {method: 'GET', params: {user_slug: "@user_slug"}},
                update: {method: 'PUT'}
            }
        ),
        proBackgroundImage: $resource(strukshurConfig.api_url + '/pro/background', {} ,
            {
                save: {
                    method: 'POST',
                    uploadEventHandlers: {
                        progress: function(e) {
                            var progress = Math.round(e.lengthComputable ? e.loaded * 100 / e.total : 0);
                            var name = 'proGallery.update';
                            //console.log('Progress -> ',e);
                            if(progress>=100){
                                if(progress>=100){
                                    $timeout(function (progress) {
                                        $rootScope.$broadcast('updateProgress', {name: name, progress: progress});
                                        $timeout(function (progress) {
                                            $rootScope.$broadcast('updateProgress', {name: name, progress: progress});
                                        }, 200, true, progress);
                                    }, 200, true, progress);
                                }
                            } else {
                                $rootScope.$broadcast('updateProgress', {name: name, progress: progress});
                            }
                        }
                    }
                }
            }
        ),

        proGalleries: $resource(strukshurConfig.api_url + '/pro/galleries', {},
            {
                query: {method: 'GET', params: {user_slug: "@user_slug"}},
                mostRecent: { method: 'GET', params: { count: '@count' }, url: strukshurConfig.domain + '/public/pro/galleries/recent' },
            }
        ),

        proGallery: $resource(strukshurConfig.api_url + '/pro/gallery', {} ,
            {
                query: {method: 'GET', params: {id: "@id"}},
                add: {method: 'POST'},
                update: {
                    method: 'PUT',
                    uploadEventHandlers: {
                        progress: function(e) {
                            var progress = Math.round(e.lengthComputable ? e.loaded * 100 / e.total : 0);
                            var name = 'proGallery.update';
                            //console.log('Progress -> ',e);
                            if(progress>=100){
                                if(progress>=100){
                                    $timeout(function (progress) {
                                        $rootScope.$broadcast('updateProgress', {name: name, progress: progress});
                                        $timeout(function (progress) {
                                            $rootScope.$broadcast('updateProgress', {name: name, progress: progress});
                                        }, 200, true, progress);
                                    }, 200, true, progress);
                                }
                            }
                            else{
                                $rootScope.$broadcast('updateProgress', {name: name, progress: progress});
                            }
                        }
                    }
                },
                delete: {method: 'DELETE'}
            }
        ),
        getGalleryVideoStatus: $resource(strukshurConfig.api_url + '/pro/gallery/video/status', {} ,
            {
                query: {method: 'GET', params: {gallery_id: "@gallery_id"}, ignoreLoadingBar: true}
            }
        ),
        userFind: $resource(strukshurConfig.api_url + '/search/users', {} ,
            {
                query: { method: 'GET', params: { terms: '@terms', project_id: '@project_id', source: '@source' } }
            }
        ),
        proFind: $resource(strukshurConfig.api_url + '/search/pro', {} ,
            {
                query: { method: 'GET', params: { terms: '@terms', category: '@category', zip: '@zip', radius: '@radius', page: '@page' } }
            }
        ),
        findUsersByName: $resource(strukshurConfig.api_url + '/search/users-by-name', {},
            {
                query: { method: 'GET', params: { term: "@term" }}
            }
        ),
        upgradeUser: $resource(strukshurConfig.api_url + '/payment/user/upgrade', {},
            {
                // upgrade user plan
                upgrade: { method: 'PUT' }
            }
        ),
        upgradePro: $resource(strukshurConfig.api_url + '/payment/pro/upgrade', {},
            {
                // upgrade pro plan
                upgrade: { method: 'PUT' }
            }
        ),

        organizations: $resource(strukshurConfig.api_url + '/organizations', {},
            {
                list: { method: 'GET' }
            }
        ),
        organization: $resource(strukshurConfig.api_url + '/organization', {},
            {
                get: { method: 'GET', params: { organization_id: '@organization_id' } },
                create: { method: 'POST' },
                update: {
                    method: 'PUT',
                    params: { organization_id: '@organization_id' },
                    uploadEventHandlers: {
                        progress: function(e) {
                            var progress = Math.round(e.lengthComputable ? e.loaded * 100 / e.total : 0);
                            var name = 'organization.update';

                            if (progress >= 100) {
                                if (progress >= 100) {
                                    $timeout(function (progress) {
                                        $rootScope.$broadcast('updateProgress', { name: name, progress: progress });
                                        $timeout(function (progress) {
                                            $rootScope.$broadcast('updateProgress', { name: name, progress: progress });
                                        }, 200, true, progress);
                                    }, 200, true, progress);
                                }
                            } else {
                                $rootScope.$broadcast('updateProgress', { name: name, progress: progress });
                            }
                        }
                    }
                },
                leave: { method: 'DELETE', params: { organization_id: '@organization_id' }, url: strukshurConfig.api_url + '/organization/leave' },
            }
        ),

        // Organization Folders
        organizationFolders: $resource(strukshurConfig.api_url + '/organization/folders', {},
            {
                list: { method: 'GET', params: { organization_id: '@organization_id', type: '@type' } },
                listRootAssets: { method: 'GET', params: { organization_id: '@organization_id' }, url: strukshurConfig.api_url + '/organization/root-assets' }
            }
        ),
        organizationFolder: $resource(strukshurConfig.api_url + '/organization/folder', {},
            {
                get: { method: 'GET', params: { folder_id: '@folder_id' } },
                paths: { method: 'GET', params: { organization_id: '@organization_id', from_folder: '@from_folder', depth: '@depth' }, url: strukshurConfig.api_url + '/organization/folder/paths' },
                create: { method: 'POST', params: { organization_id: '@organization_id' } },
                update: { method: 'PATCH', params: { folder_id: '@folder_id' } },
                move: { method: 'PATCH', params: { organization_id: '@organization_id', parent_id: '@parent_id', folder_id: '@folder_id' }, url: strukshurConfig.api_url + '/organization/folder/move' },
                favorite: { method: 'PATCH', params: { folder_id: '@folder_id' }, url: strukshurConfig.api_url + '/organization/folder/favorite' },
                unfavorite: { method: 'PATCH', params: { folder_id: '@folder_id' }, url: strukshurConfig.api_url + '/organization/folder/unfavorite' },
                delete: { method: 'DELETE', params: { folder_id: '@folder_id' } },
            }
        ),

        // Organization Files
        organizationFiles: $resource(strukshurConfig.api_url + '/organization/files', {},
            {
                list: { method: 'GET', params: { organization_id: '@organization_id' } }
            }
        ),
        organizationFile: $resource(strukshurConfig.api_url + '/organization/file', {},
            {
                create: {
                    method: 'POST',
                    uploadEventHandlers: {
                        progress: (e) => {
                            let progress = Math.round(e.lengthComputable ? e.loaded * 100 / e.total : 0);
                            const name = 'organizationFile';

                            if (progress >= 100) {
                                $timeout((progress) => {
                                    $rootScope.$broadcast('updateProgress', { name: name, progress: progress });
                                    $timeout((progress) => {
                                        $rootScope.$broadcast('updateProgress', { name: name, progress: progress });
                                    }, 200, true, progress);
                                }, 200, true, progress);
                            } else {
                                $rootScope.$broadcast('updateProgress', { name: name, progress: progress });
                            }
                        }
                    }
                },
                update: { method: 'PATCH', params: { file_id: '@file_id' } },
                move: { method: 'PATCH', params: { organization_id: '@organization_id', parent_id: '@parent_id', file_id: '@file_id' }, url: strukshurConfig.api_url + '/organization/file/move' },
                favorite: { method: 'PATCH', params: { file_id: '@file_id' }, url: strukshurConfig.api_url + '/organization/file/favorite' },
                unfavorite: { method: 'PATCH', params: { file_id: '@file_id' }, url: strukshurConfig.api_url + '/organization/file/unfavorite' },
                createShareLink: { method: 'POST', params: { file_id: '@file_id' }, url: strukshurConfig.api_url + '/organization/file/create-link' },
                shareFile: { method: 'POST', params: { file_id: '@file_id' }, url: strukshurConfig.api_url + '/organization/file/share-file' },
                delete: { method: 'DELETE', params: { file_id: '@file_id' } },
            }
        ),

        organizationMembers: $resource(strukshurConfig.api_url + '/organization/members', {},
            {
                list: { method: 'GET', params: { organization_id: '@organization_id' } }
            }
        ),
        organizationMember: $resource(strukshurConfig.api_url + '/organization/member', {},
            {
                get: { method: 'GET', params: { organization_id: '@organization_id', member_id: '@member_id' } },
                update: { method: 'PUT', params: { organization_id: '@organization_id', member_id: '@member_id' } },
                remove: { method: 'DELETE', params: { organization_id: '@organization_id', member_id: '@member_id' } },
                invite: { method: 'POST', url: strukshurConfig.api_url + '/organization/member/invite' },
                acceptInvite: { method: 'PUT', params: { member_id: '@member_id' }, url: strukshurConfig.api_url + '/organization/member/invite/accept' },
                rejectInvite: { method: 'DELETE', params: { member_id: '@member_id', code: '@code' }, url: strukshurConfig.api_url + '/organization/member/invite/reject' },
                resendInvite: { method: 'PUT', params: { organization_id: '@organization_id', member_id: '@member_id' }, url: strukshurConfig.api_url + '/organization/member/invite/resend' },
                validateInvite: { method: 'GET', params: { member_id: '@member_id' }, url: strukshurConfig.api_url + '/organization/member/invite/validate' },
            }
        ),

        organizationProjects: $resource(strukshurConfig.api_url + '/organization/projects', {},
            {
                list: { method: 'GET', params: { organization_id: '@organization_id' } }
            }
        ),

        stripeEnrollment: $resource(strukshurConfig.api_url + '/pro/stripe/enroll', {},
            {
                save: { method: 'POST' }
            }
        ),
        stripeDisconnect: $resource(strukshurConfig.api_url + '/pro/stripe/disconnect', {},
            {
                save: { method: 'POST' }
            }
        ),
        proMessage: $resource(strukshurConfig.api_url + '/thread/direct/message', {},
            {
                // validate invitation
                save: { method: 'POST' }
            }
        ),

        // ideas
        ideas: $resource(strukshurConfig.domain + '/public/ideas', {},
            {
                search: { method: 'GET', params: { page: '@page', size: '@size', terms: '@terms' } },
            }
        ),

        getIdeas: $resource(strukshurConfig.api_url + '/search/ideas', {},
            {
                query: { method: 'GET', params: { page: '@page', size: '@size' } },
            }
        ),

        // Products
        getProductsByCategory: $resource(strukshurConfig.api_url + '/products/category', {} ,
            {
                query: {method: 'GET', params: {category: "@category", page: "@page"}}
            }
        ),

        getProductBySlug: $resource(strukshurConfig.api_url + '/product', {} ,
            {
                query: {method: 'GET', params: {slug: "@slug"}}
            }
        ),

        search: $resource(strukshurConfig.domain + '/public/search', {} ,
            {
                search: { method: 'GET', params: { terms: '@terms', page: '@page' } },
            }
        ),

        getSearch: $resource(strukshurConfig.api_url + '/search', {} ,
            {
                query: {method: 'GET', params: {terms: "@terms", page: "@page"}}
            }
        ),

        // Admin Users
        getAdminUsers: $resource(strukshurConfig.api_url + '/admin/user/list', {} ,
            {
                query: {method: 'GET', params: {page: "@page"}}
            }
        ),
        getAdminUser: $resource(strukshurConfig.api_url + '/admin/user', {} ,
            {
                query: {method: 'GET', params: {id: "@id"}},
                remove: {method: 'DELETE', params: {user_id: "@user_id"}}
            }
        ),
        postAdminUserAdd: $resource(strukshurConfig.api_url + '/admin/user/add', {} ,
            {
                save: {method: 'POST'}
            }
        ),
        postAdminUserEdit: $resource(strukshurConfig.api_url + '/admin/user/edit', {} ,
            {
                save: {method: 'POST'}
            }
        ),
        postAdminUserResend: $resource(strukshurConfig.api_url + '/admin/user/resend', {} ,
            {
                send: {method: 'POST'}
            }
        ),
        postAdminPassword: $resource(strukshurConfig.api_url + '/admin/user/password', {} ,
            {
                change: {method: 'POST'}
            }
        ),
        adminSubscription: $resource(strukshurConfig.api_url + '/admin/user/subscription', {} ,
            {
                cancel: {method: 'DELETE', params: {user_id: "@user_id"}}
            }
        ),

        // Admin Products

        getAdminProducts: $resource(strukshurConfig.api_url + '/admin/products', {} ,
            {
                query: {method: 'GET', params: {page: "@page"}}
            }
        ),
        getAdminProduct: $resource(strukshurConfig.api_url + '/admin/product', {} ,
            {
                query: {method: 'GET', params: {id: "@id"}}
            }
        ),
        postAdminProductAdd: $resource(strukshurConfig.api_url + '/admin/product/add', {} ,
            {
                save: {method: 'POST'}
            }
        ),
        postAdminProductEdit: $resource(strukshurConfig.api_url + '/admin/product/edit', {} ,
            {
                save: {method: 'POST'}
            }
        ),
        adminProductDelete: $resource(strukshurConfig.api_url + '/admin/product/delete', {} ,
            {
                save: {method: 'DELETE'}
            }
        ),

        // Admin Vendors

        getAdminVendors: $resource(strukshurConfig.api_url + '/admin/vendors', {} ,
            {
                query: {method: 'GET', params: {page: "@page"}}
            }
        ),
        getAdminVendor: $resource(strukshurConfig.api_url + '/admin/vendor', {} ,
            {
                query: {method: 'GET', params: {id: "@id"}}
            }
        ),
        postAdminVendorAdd: $resource(strukshurConfig.api_url + '/admin/vendor/add', {} ,
            {
                save: {
                    method: 'POST',
                    uploadEventHandlers: {
                        progress: function(e) {
                            var progress = Math.round(e.lengthComputable ? e.loaded * 100 / e.total : 0);
                            var name = 'postAdminVendorAdd.save';
                            //console.log('Progress -> ',e);
                            if(progress>=100){
                                if(progress>=100){
                                    $timeout(function (progress) {
                                        $rootScope.$broadcast('updateProgress', {name: name, progress: progress});
                                        $timeout(function (progress) {
                                            $rootScope.$broadcast('updateProgress', {name: name, progress: progress});
                                        }, 200, true, progress);
                                    }, 200, true, progress);
                                }
                            }
                            else{
                                $rootScope.$broadcast('updateProgress', {name: name, progress: progress});
                            }
                        }
                    }
                }
            }
        ),
        postAdminVendorEdit: $resource(strukshurConfig.api_url + '/admin/vendor/edit', {} ,
            {
                save: {method: 'POST'}
            }
        ),
        searchVendors: $resource(strukshurConfig.api_url + '/admin/vendor/find', {} ,
            {
                query: {method: 'GET', params: {terms: "@terms"}}
            }
        ),

        // Admin Product Categories



        getAdminCategories: $resource(strukshurConfig.api_url + '/admin/categories', {} ,
            {
                query: {method: 'GET', params: {page: "@page"}}
            }
        ),
        getAdminCategory: $resource(strukshurConfig.api_url + '/admin/category', {} ,
            {
                query: {method: 'GET', params: {id: "@id"}}
            }
        ),
        postAdminCategoryAdd: $resource(strukshurConfig.api_url + '/admin/category/add', {} ,
            {
                save: {method: 'POST'}
            }
        ),
        postAdminCategoryEdit: $resource(strukshurConfig.api_url + '/admin/category/edit', {} ,
            {
                save: {method: 'POST'}
            }
        ),
        searchCategory: $resource(strukshurConfig.api_url + '/admin/category/find', {} ,
            {
                query: {method: 'GET', params: {terms: "@terms"}}
            }
        ),

        // Admin Contractor Skills

        getAdminSkills: $resource(strukshurConfig.api_url + '/admin/skills', {} ,
            {
                query: {method: 'GET', params: {page: "@page"}}
            }
        ),
        getAdminSkill: $resource(strukshurConfig.api_url + '/admin/skill', {} ,
            {
                query: {method: 'GET', params: {id: "@id"}},
                delete: {method: 'DELETE', params: {id: "@id"}}
            }
        ),
        postAdminSkillAdd: $resource(strukshurConfig.api_url + '/admin/skill/add', {} ,
            {
                save: {method: 'POST'}
            }
        ),
        postAdminSkillEdit: $resource(strukshurConfig.api_url + '/admin/skill/edit', {} ,
            {
                save: {method: 'POST'}
            }
        ),

        // Admin Contractor Role
        adminContractorRole: $resource(strukshurConfig.api_url + '/admin/contractor-role', {},
            {
                create: { method: 'PUT' }
            }
        ),
        adminContractorRoles: $resource(strukshurConfig.api_url + '/admin/contractor-roles', {},
            {
                list: { method: 'GET' }
            }
        ),

        // Admin Vendor Products

        getVendorProducts: $resource(strukshurConfig.api_url + '/vendor/products', {} ,
            {
                query: {method: 'GET', params: {page: "@page", terms: "@terms"}}
            }
        ),
        getVendorProduct: $resource(strukshurConfig.api_url + '/vendor/product', {} ,
            {
                query: {method: 'GET', params: {id: "@id"}}
            }
        ),
        postVendorProductAdd: $resource(strukshurConfig.api_url + '/vendor/product/add', {} ,
            {
                save: {method: 'POST'}
            }
        ),
        postVendorProductEdit: $resource(strukshurConfig.api_url + '/vendor/product/edit', {} ,
            {
                save: {method: 'POST'}
            }
        ),
        productAdminDelete: $resource(strukshurConfig.api_url + '/vendor/product/delete', {} ,
            {
                delete: {method: 'DELETE'}
            }
        ),
        getVendorProductCategories: $resource(strukshurConfig.api_url + '/vendor/product/categories', {} ,
            {
                query: {method: 'GET'}
            }
        ),
        searchProductVendors: $resource(strukshurConfig.api_url + '/vendor/product/vendor/find', {} ,
            {
                query: {method: 'GET', params: {terms: "@terms"}}
            }
        ),
        searchVendorProductRoomTypes: $resource(strukshurConfig.api_url + '/vendor/product/room/find', {} ,
            {
                query: {method: 'GET', params: {terms: "@terms"}}
            }
        ),
        searchVendorRoomProps: $resource(strukshurConfig.api_url + '/vendor/product/room/props/find', {} ,
            {
                query: {method: 'POST'}
            }
        ),
        searchContractorRoles: $resource(strukshurConfig.api_url + '/search/contractor-roles', {},
            {
                list: { method: 'GET' }
            }
        ),
        dashboardTotals: $resource(strukshurConfig.api_url + '/pro/store/totals', {},
            {
                query: { method: 'GET' }
            }
        ),

        //orders
        ordersSeller: $resource(strukshurConfig.api_url + '/orders/seller', {},
            {
                query: { method: 'GET', params: {page: "@page", terms: "@terms"} } //gets the list of orders
            }
        ),
        orderDetails: $resource(strukshurConfig.api_url + '/order/details', {},
            {
                query: { method: 'GET', params: {suborder_id: "@suborder_id"} },
                save: { method: 'PUT' }
            }
        ),
        myOrderDetails: $resource(strukshurConfig.api_url + '/my/order/details', {},
            {
                query: { method: 'GET', params: {suborder_id: "@suborder_id"} }
            }
        ),
        getMyOrders: $resource(strukshurConfig.api_url + '/my/orders', {} ,
            {
                query: {method: 'GET', params: {page: "@page"}}
            }
        ),
        orderEmail: $resource(strukshurConfig.api_url + '/order/email', {},
            {
                save: { method: 'POST'}
            }
        ),
        productOrderDetails: $resource(strukshurConfig.api_url + '/order/product/details', {},
            {
                save: { method: 'PUT' },
                add: { method: 'POST' }
            }
        ),
        productOrderReturn: $resource(strukshurConfig.api_url + '/order/return', {},
            {
                save: { method: 'POST' }
            }
        ),
        chargeSuborder: $resource(strukshurConfig.api_url + '/charge/suborder', {},
            {
                save: { method: 'POST' }
            }
        ),
        refundCharge: $resource(strukshurConfig.api_url + '/refund/charge', {},
            {
                save: { method: 'POST' }
            }
        ),
        suborderFiles: $resource(strukshurConfig.api_url + '/suborder/file', {},
            {
                create: { method: 'POST' }
            }
        ),

        // Project Questions

        postQuestion: $resource(strukshurConfig.api_url + '/thread/save', {} ,
            {
                save: {method: 'POST'}
            }
        ),
        message: $resource(strukshurConfig.api_url + '/thread/message', {},
            {
                delete: { method: 'DELETE', params: { message_id: '@message_id' } },
                save: { method: 'POST' }
            }
        ),
        thread: $resource(strukshurConfig.api_url + '/thread', {} ,
            {
                query: { method: 'GET', params: { page: "@page", thread_id: "@thread_id" }, ignoreLoadingBar: true },
                add: { method: 'POST' },
                update: { method: 'PUT' },
                delete: { method: 'DELETE', params: { thread_id: '@thread_id' } },
            }
        ),
        getProjectProQuestions: $resource(strukshurConfig.api_url + '/thread/pro/default', {} ,
            {
                query: {method: 'GET', params: {page: "@page", project_id: "@project_id"}}
            }
        ),
        getProjectThreads: $resource(strukshurConfig.api_url + '/threads/project', {} ,
            {
                query: {method: 'GET', params: {project_id: "@project_id"}}
            }
        ),

        // Invitations

        acceptInvite: $resource(strukshurConfig.api_url + '/invitation/accept', {} ,
            {
                save: {method: 'POST'}
            }
        ),
        validateInvite: $resource(strukshurConfig.api_url + '/invitation/validate', {} ,
            {
                query: {method: 'GET', params: {project_id: "@project_id", thread_id: "@thread_id", code: "@code"}}
            }
        ),

        // Contact form

        contact: $resource(strukshurConfig.api_url + '/invitation/contact', {} ,
            {
                save: {method: 'POST'}
            }
        ),

        // Purchase Lists

        purchaseLists: $resource(strukshurConfig.api_url + '/project/purchase-lists', {},
            {
                list: { method: 'GET', params: { project_id: '@project_id' } }
            }
        ),
        purchaseList: $resource(strukshurConfig.api_url + '/project/purchase-list', {},
            {
                create: { method: 'PUT' },
                update: { method: 'POST', params: { list_id: '@list_id' } },
                delete: { method: 'DELETE', params: { list_id: '@list_id' } }
            }
        ),
        purchaseListItem: $resource(strukshurConfig.api_url + '/project/purchase-list/item', {},
            {
                create: {
                    method: 'PUT',
                    uploadEventHandlers: {
                        progress: function(e) {
                            var progress = Math.round(e.lengthComputable ? e.loaded * 100 / e.total : 0);
                            var name = 'projectFile';
                            //console.log('Progress -> '+progress,e);
                            if(progress>=100){
                                $timeout(function (progress) {
                                    $rootScope.$broadcast('updateProgress', {name: name, progress: progress});
                                    $timeout(function (progress) {
                                        $rootScope.$broadcast('updateProgress', {name: name, progress: progress});
                                    }, 200, true, progress);
                                }, 200, true, progress);
                            }
                            else{
                                $rootScope.$broadcast('updateProgress', {name: name, progress: progress});
                            }

                        }
                    }
                },
                move: { method: 'POST', params: { item_id: '@item_id', new_list_id: '@new_list_id' } },
                delete: { method: 'DELETE', params: { item_id: '@item_id' } }
            }
        ),

        // Project Folders
        projectFolders: $resource(strukshurConfig.api_url + '/project/folders', {},
            {
                list: { method: 'GET', params: { project_id: '@project_id', type: '@type' } },
                listRootAssets: { method: 'GET', params: { project_id: '@project_id' }, url: strukshurConfig.api_url + '/project/root-assets' }
            }
        ),
        projectFolder: $resource(strukshurConfig.api_url + '/project/folder', {},
            {
                get: { method: 'GET', params: { folder_id: '@folder_id' } },
                paths: { method: 'GET', params: { project_id: '@project_id', from_folder: '@from_folder', depth: '@depth' }, url: strukshurConfig.api_url + '/project/folder/paths' },
                create: { method: 'POST', params: { project_id: '@project_id' } },
                update: { method: 'PATCH', params: { folder_id: '@folder_id' } },
                move: { method: 'PATCH', params: { project_id: '@project_id', parent_id: '@parent_id', folder_id: '@folder_id' }, url: strukshurConfig.api_url + '/project/folder/move' },
                favorite: { method: 'PATCH', params: { folder_id: '@folder_id' }, url: strukshurConfig.api_url + '/project/folder/favorite' },
                unfavorite: { method: 'PATCH', params: { folder_id: '@folder_id' }, url: strukshurConfig.api_url + '/project/folder/unfavorite' },
                delete: { method: 'DELETE', params: { folder_id: '@folder_id' } },
            }
        ),

        // Project Files
        projectFiles: $resource(strukshurConfig.api_url + '/project/files', {},
            {
                list: { method: 'GET', params: { project_id: '@project_id', type: '@type' } }
            }
        ),
        projectFile: $resource(strukshurConfig.api_url + '/project/file', {},
            {
                create: {
                    method: 'POST',
                    uploadEventHandlers: {
                        progress: (e) => {
                            let progress = Math.round(e.lengthComputable ? e.loaded * 100 / e.total : 0);
                            const name = 'projectFile';

                            if (progress >= 100) {
                                $timeout((progress) => {
                                    $rootScope.$broadcast('updateProgress', { name: name, progress: progress });
                                    $timeout((progress) => {
                                        $rootScope.$broadcast('updateProgress', { name: name, progress: progress });
                                    }, 200, true, progress);
                                }, 200, true, progress);
                            } else {
                                $rootScope.$broadcast('updateProgress', { name: name, progress: progress });
                            }
                        }
                    }
                },
                update: { method: 'PATCH', params: { file_id: '@file_id' } },
                copy: { method: 'POST', params: { project_id: '@project_id', parent_id: '@parent_id', file_id: '@file_id' }, url: strukshurConfig.api_url + '/project/file/copy' },
                move: { method: 'PATCH', params: { project_id: '@project_id', parent_id: '@parent_id', file_id: '@file_id' }, url: strukshurConfig.api_url + '/project/file/move' },
                favorite: { method: 'PATCH', params: { file_id: '@file_id' }, url: strukshurConfig.api_url + '/project/file/favorite' },
                unfavorite: { method: 'PATCH', params: { file_id: '@file_id' }, url: strukshurConfig.api_url + '/project/file/unfavorite' },
                activate: { method: 'PATCH', params: { file_id: '@file_id' }, url: strukshurConfig.api_url + '/project/file/activate' },
                inactivate: { method: 'PATCH', params: { file_id: '@file_id' }, url: strukshurConfig.api_url + '/project/file/inactivate' },
                createShareLink: { method: 'POST', params: { file_id: '@file_id' }, url: strukshurConfig.api_url + '/project/file/create-link' },
                shareFile: { method: 'POST', params: { file_id: '@file_id' }, url: strukshurConfig.api_url + '/project/file/share-file' },
                delete: { method: 'DELETE', params: { file_id: '@file_id' } },
            }
        ),

        // Project Pins
        projectPins: $resource(strukshurConfig.api_url + '/project/pins', {},
            {
                list: { method: 'GET', params: { project_id: '@project_id', type: '@type' } },
                listPinsToSync: { method: 'GET', params: { room_id: '@room_id', project_id: '@project_id', project_room_id: '@project_room_id' }, url: strukshurConfig.api_url + '/project/pins/list-pins-to-sync' },
                importFromRoom: { method: 'POST', params: { project_id: '@project_id', project_room_id: '@project_room_id' }, url: strukshurConfig.api_url + '/project/pins/import-from-room' },
                importFromPinterest: { method: 'POST', params: { project_id: '@project_id', room_id: '@room_id' }, url: strukshurConfig.api_url + '/project/pins/import-from-pinterest' }
            }
        ),
        getProjectPinVideoStatus: $resource(strukshurConfig.api_url + '/project/pin/video/status', {},
            {
                query: { method: 'GET', params: { pin_id: '@pin_id' }, ignoreLoadingBar: true }
            }
        ),
        projectPin: $resource(strukshurConfig.api_url + '/project/pin', {},
            {
                get: { method: 'GET', params: { pin_id: '@pin_id' } },
                create: {
                    method: 'PUT',
                    uploadEventHandlers: {
                        progress: function(e) {
                            var progress = Math.round(e.lengthComputable ? e.loaded * 100 / e.total : 0);
                            var name = 'projectFile';
                            //console.log('Progress -> '+progress,e);
                            if (progress >= 100) {
                                $timeout(function (progress) {
                                    $rootScope.$broadcast('updateProgress', {name: name, progress: progress});
                                    $timeout(function (progress) {
                                        $rootScope.$broadcast('updateProgress', {name: name, progress: progress});
                                    }, 200, true, progress);
                                }, 200, true, progress);
                            } else {
                                $rootScope.$broadcast('updateProgress', {name: name, progress: progress});
                            }

                        }
                    }
                },
                createFromFile: {
                    method: 'PUT',
                    url: strukshurConfig.api_url + '/project/pin/from-file',
                    uploadEventHandlers: {
                        progress: e => {
                            const progress = Math.round(e.lengthComputable ? e.loaded * 100 / e.total : 0);
                            const name = 'projectFile';

                            if (progress >= 100) {
                                $timeout(progress => {
                                    $rootScope.$broadcast('updateProgress', { name: name, progress: progress });
                                    $timeout(progress => {
                                        $rootScope.$broadcast('updateProgress', { name: name, progress: progress });
                                    }, 200, true, progress);
                                }, 200, true, progress);
                            } else {
                                $rootScope.$broadcast('updateProgress', { name: name, progress: progress });
                            }
                        }
                    }
                },
                delete: { method: 'DELETE', params: { pin_id: '@pin_id' } }
            }
        ),

        // Project Pin Tag
        projectPinTags: $resource(strukshurConfig.api_url + '/project/pin/tags', {},
            {
                list: { method: 'GET', params: { pin_id: '@pin_id' } }
            }
        ),
        projectPinTag: $resource(strukshurConfig.api_url + '/project/pin/tag', {},
            {
                create: { method: 'POST' },
                update: { method: 'PUT', params: { tag_id: '@tag_id' } },
                delete: { method: 'DELETE', params: { tag_id: '@tag_id' } }
            }
        ),

        // Project Milestones
        projectMilestones: $resource(strukshurConfig.api_url + '/project/milestones', {},
            {
                list: { method: 'GET', params: { project_id: '@project_id' } }
            }
        ),
        projectMilestone: $resource(strukshurConfig.api_url + '/project/milestone', {},
            {
                create: { method: 'PUT' },
                update: { method: 'POST', params: { milestone_id: '@milestone_id' } },
                copy: { method: 'POST', params: { milestone_id: '@milestone_id' }, url: strukshurConfig.api_url + '/project/milestone/copy' },
                delete: { method: 'DELETE', params: { milestone_id: '@milestone_id' } }
            }
        ),

        // Project Tasks
        projectTask: $resource(strukshurConfig.api_url + '/project/task', {},
            {
                create: { method: 'PUT' },
                update: { method: 'POST', params: { task_id: '@task_id' } },
                moveDates: { method: 'POST', params: { task_id: '@task_id' }, url: strukshurConfig.api_url + '/project/task/move-dates' },
                copy: { method: 'POST', params: { task_id: '@task_id' }, url: strukshurConfig.api_url + '/project/task/copy' },
                delete: { method: 'DELETE', params: { task_id: '@task_id' } },
                addComent: { method: 'PUT' },
                deleteComent: { method: 'DELETE', params: { comment_id: '@comment_id' } }
            }
        ),
        projectGantt: $resource(strukshurConfig.api_url + '/project/tasks/gantt', {},
            {
                update: { method: 'POST' }
            }
        ),

        // Project Task Comments
        projectTaskComment: $resource(strukshurConfig.api_url + '/project/task/comment', {},
            {
                create: { method: 'POST' },
                delete: { method: 'DELETE', params: { comment_id: '@comment_id' } },
                read: { method: 'POST', params: { comment_id: '@comment_id' }, url: strukshurConfig.api_url + '/project/task/comment/read', ignoreLoadingBar: true },
            }
        ),

        // Project Task Comments
        projectTaskReminder: $resource(strukshurConfig.api_url + '/project/task/reminder', {},
            {
                create: { method: 'POST' },
                delete: { method: 'DELETE', params: { reminder_id: '@reminder_id' } }
            }
        ),

        // Project Task Files
        projectTaskFile: $resource(strukshurConfig.api_url + '/project/task/file', {},
            {
                create: { method: 'POST' },
                createFromFile: { method: 'POST', url: strukshurConfig.api_url + '/project/task/file/from-file' },
                delete: { method: 'DELETE', params: { file_id: '@file_id' } }
            }
        ),

        // Project progress items
        projectProgressItems: $resource(strukshurConfig.api_url + '/project/progress/items', {},
            {
                list: { method: 'GET', params: { project_id: '@project_id' } }
            }
        ),
        getProgressVideoStatus: $resource(strukshurConfig.api_url + '/project/progress/video/status', {},
            {
                query: { method: 'GET', params: { progress_id: '@progress_id' }, ignoreLoadingBar: true }
            }
        ),
        projectProgressItem: $resource(strukshurConfig.api_url + '/project/progress/item', {},
            {
                get: { method: 'GET', params: { item_id: '@item_id' } },
                create: {
                    method: 'POST',
                    cancellable: true,
                    ignoreLoadingBar: true,
                    uploadEventHandlers: {
                        progress: function(e) {
                            var progress = Math.round(e.lengthComputable ? e.loaded * 100 / e.total : 0);
                            var name = 'projectProgressItem';

                            if (progress >= 100) {
                                $timeout(function (progress) {
                                    $rootScope.$broadcast('updateProgress', { name: name, progress: progress });
                                    $timeout(function (progress) {
                                        $rootScope.$broadcast('updateProgress', { name: name, progress: progress });
                                    }, 200, true, progress);
                                }, 200, true, progress);
                            } else {
                                $rootScope.$broadcast('updateProgress', { name: name, progress: progress });
                            }

                        }
                    }
                },
                update: { method: 'PUT', params: { item_id: '@item_id' } },
                delete: { method: 'DELETE', params: { item_id: '@item_id' } }
            }
        ),

        // Project Progress Item Tag
        projectProgressItemTags: $resource(strukshurConfig.api_url + '/project/progress/item/tags', {},
            {
                list: { method: 'GET', params: { item_id: '@item_id' } }
            }
        ),
        projectProgressItemTag: $resource(strukshurConfig.api_url + '/project/progress/item/tag', {},
            {
                create: { method: 'POST' },
                update: { method: 'PUT', params: { tag_id: '@tag_id' } },
                delete: { method: 'DELETE', params: { tag_id: '@tag_id' } }
            }
        ),

        // Estimate Categories
        estimateCategories: $resource(strukshurConfig.api_url + '/estimate/categories', {},
            {
                list: { method: 'GET' }
            }
        ),

        // Project Estimates
        projectEstimate: $resource(strukshurConfig.api_url + '/project/estimate', {},
            {
                get: { method: 'GET', params: { estimate_id: '@estimate_id' } },
                create: { method: 'POST' },
                update: { method: 'PUT', params: { estimate_id: '@estimate_id' } },
                duplicate: { method: 'POST', params: { project_id: '@project_id', estimate_id: '@estimate_id' }, url: strukshurConfig.api_url + '/project/estimate/duplicate' },
                delete: { method: 'DELETE', params: { estimate_id: '@estimate_id' } },
                markAsFinal: { method: 'PUT', params: { estimate_id: '@estimate_id' }, url: strukshurConfig.api_url + '/project/estimate/mark-as-final' },
                createChangeOrder: { method: 'PUT', params: { estimate_id: '@estimate_id' }, url: strukshurConfig.api_url + '/project/estimate/create-change-order' }
            }
        ),
        projectEstimates: $resource(strukshurConfig.api_url + '/project/estimates', {},
            {
                list: { method: 'GET', params: { project_id: '@project_id' } }
            }
        ),

        // Project Estimate Categories
        projectEstimateCategory: $resource(strukshurConfig.api_url + '/project/estimate/category', {},
            {
                add: { method: 'POST' },
                update: { method: 'PUT', params: { category_id: '@category_id' } },
                delete: { method: 'DELETE', params: { category_id: '@category_id' } }
            }
        ),

        // Project Estimate Sub Categories
        projectEstimateSubCategory: $resource(strukshurConfig.api_url + '/project/estimate/category/sub-category', {},
            {
                create: { method: 'POST' },
                update: { method: 'PUT', params: { sub_category_id: '@sub_category_id' } },
                delete: { method: 'DELETE', params: { sub_category_id: '@sub_category_id' } }
            }
        ),

        // Project Estimate Versions
        projectEstimateVersions: $resource(strukshurConfig.api_url + '/project/estimates/versions', {},
            {
                list: { method: 'GET', params: { project_id: '@project_id' } }
            }
        ),

        // Project Invites
        projectInvites: $resource(strukshurConfig.api_url + '/project/invites', {},
            {
                list: { method: 'GET', params: { project_id: '@project_id', status: '@status' } }
            }
        ),

        // Project Estimate Sub Category File
        projectEstimateSubCategoryFile: $resource(strukshurConfig.api_url + '/project/estimate/file', {},
            {
                create: {
                    method: 'POST',
                    uploadEventHandlers: {
                        progress: function(e) {
                            var progress = Math.round(e.lengthComputable ? e.loaded * 100 / e.total : 0);
                            var name = 'projectFile';

                            if (progress >= 100) {
                                $timeout(function (progress) {
                                    $rootScope.$broadcast('updateProgress', { name: name, progress: progress });
                                    $timeout(function (progress) {
                                        $rootScope.$broadcast('updateProgress', { name: name, progress: progress });
                                    }, 200, true, progress);
                                }, 200, true, progress);
                            } else {
                                $rootScope.$broadcast('updateProgress', { name: name, progress: progress });
                            }
                        },
                    },
                },
                createFromFile: {
                    method: 'POST',
                    url: strukshurConfig.api_url + '/project/estimate/file/from-file',
                    uploadEventHandlers: {
                        progress: e => {
                            const progress = Math.round(e.lengthComputable ? e.loaded * 100 / e.total : 0);
                            const name = 'projectFile';

                            if (progress >= 100) {
                                $timeout(progress => {
                                    $rootScope.$broadcast('updateProgress', { name: name, progress: progress });
                                    $timeout(progress => {
                                        $rootScope.$broadcast('updateProgress', { name: name, progress: progress });
                                    }, 200, true, progress);
                                }, 200, true, progress);
                            } else {
                                $rootScope.$broadcast('updateProgress', { name: name, progress: progress });
                            }
                        },
                    },
                },
                update: { method: 'PATCH', params: { file_id: '@file_id' } },
                activate: { method: 'PATCH', params: { file_id: '@file_id' }, url: strukshurConfig.api_url + '/project/estimate/file/activate' },
                inactivate: { method: 'PATCH', params: { file_id: '@file_id' }, url: strukshurConfig.api_url + '/project/estimate/file/inactivate' },
                delete: { method: 'DELETE', params: { file_id: '@file_id' } },
            }
        ),

        // Project Estimate Sub Category Image
        projectEstimateSubCategoryImage: $resource(strukshurConfig.api_url + '/project/estimate/image', {},
            {
                create: {
                    method: 'POST',
                    uploadEventHandlers: {
                        progress: function (e) {
                            let name = 'projectFile',
                                progress = Math.round(e.lengthComputable ? e.loaded * 100 / e.total : 0);

                            if (progress >= 100) {
                                $timeout(function (progress) {
                                    $rootScope.$broadcast('updateProgress', { name: name, progress: progress });
                                    $timeout(function (progress) {
                                        $rootScope.$broadcast('updateProgress', { name: name, progress: progress });
                                    }, 200, true, progress);
                                }, 200, true, progress);
                            } else {
                                $rootScope.$broadcast('updateProgress', { name: name, progress: progress });
                            }
                        }
                    }
                },
                createFromFile: {
                    method: 'POST',
                    url: strukshurConfig.api_url + '/project/estimate/image/from-file',
                    uploadEventHandlers: {
                        progress: e => {
                            const progress = Math.round(e.lengthComputable ? e.loaded * 100 / e.total : 0);
                            const name = 'projectFile';

                            if (progress >= 100) {
                                $timeout(progress => {
                                    $rootScope.$broadcast('updateProgress', { name: name, progress: progress });
                                    $timeout(progress => {
                                        $rootScope.$broadcast('updateProgress', { name: name, progress: progress });
                                    }, 200, true, progress);
                                }, 200, true, progress);
                            } else {
                                $rootScope.$broadcast('updateProgress', { name: name, progress: progress });
                            }
                        },
                    },
                },
                delete: { method: 'DELETE', params: { image_id: '@image_id' } }
            }
        ),

        // User
        user: $resource(strukshurConfig.api_url + '/me', {},
            {
                updateBasicSettings: {
                    method: 'PUT',
                    url: strukshurConfig.api_url + '/me/basic',
                    uploadEventHandlers: {
                        progress: function(e) {
                            var progress = Math.round(e.lengthComputable ? e.loaded * 100 / e.total : 0);
                            var name = 'user.update';

                            if (progress >= 100) {
                                if (progress >= 100) {
                                    $timeout(function (progress) {
                                        $rootScope.$broadcast('updateProgress', { name: name, progress: progress });
                                        $timeout(function (progress) {
                                            $rootScope.$broadcast('updateProgress', { name: name, progress: progress });
                                        }, 200, true, progress);
                                    }, 200, true, progress);
                                }
                            } else {
                                $rootScope.$broadcast('updateProgress', { name: name, progress: progress });
                            }
                        }
                    }
                },
                updateBusinessSettings: {
                    method: 'PUT',
                    url: strukshurConfig.api_url + '/me/business',
                    uploadEventHandlers: {
                        progress: function(e) {
                            var progress = Math.round(e.lengthComputable ? e.loaded * 100 / e.total : 0);
                            var name = 'user.update';

                            if (progress >= 100) {
                                if (progress >= 100) {
                                    $timeout(function (progress) {
                                        $rootScope.$broadcast('updateProgress', { name: name, progress: progress });
                                        $timeout(function (progress) {
                                            $rootScope.$broadcast('updateProgress', { name: name, progress: progress });
                                        }, 200, true, progress);
                                    }, 200, true, progress);
                                }
                            } else {
                                $rootScope.$broadcast('updateProgress', { name: name, progress: progress });
                            }
                        }
                    }
                },
                changePassword: {
                    method: 'PUT',
                    url: strukshurConfig.api_url + '/me/change-password',
                },
                updateNotificationSettings: {
                    method: 'PUT',
                    url: strukshurConfig.api_url + '/me/notification-settings',
                }
            }
        ),

        // User Rooms
        room: $resource(strukshurConfig.api_url + '/room', {},
            {
                getDefault: { method: 'GET', url: strukshurConfig.api_url + '/room/default' }
            }
        ),

        // User Rooms
        userRooms: $resource(strukshurConfig.api_url + '/me/rooms', {},
            {
                list: { method: 'GET', params: { house_id: '@house_id' } }
            }
        ),

        // User Room
        userRoom: $resource(strukshurConfig.api_url + '/me/room', {},
            {
                create: { method: 'POST' },
                get: { method: 'GET', params: { room_id: '@room_id', room_slug: '@room_slug' } },
                update: { method: 'PUT', params: { room_id: '@room_id' } },
                updateCover: { method: 'PUT', params: { room_id: '@room_id' }, url: strukshurConfig.api_url + '/me/room/cover' },
                updateBlueprint: { method: 'PUT', params: { room_id: '@room_id' }, url: strukshurConfig.api_url + '/me/room/blueprint' },
                delete: { method: 'DELETE', params: { room_id: '@room_id' } }
            }
        ),

        // Project Rooms
        projectRooms: $resource(strukshurConfig.api_url + '/project/rooms', {},
            {
                list: { method: 'GET', params: { project_id: '@project_id' } },
                listForBlueprints: { method: 'GET', params: { project_id: '@project_id' }, url: strukshurConfig.api_url + '/project/rooms/for-blueprints' },
            }
        ),

        // Project Room
        projectRoom: $resource(strukshurConfig.api_url + '/project/room', {},
            {
                create: { method: 'POST' },
                update: { method: 'PUT', params: { room_id: '@room_id' } },
                delete: { method: 'DELETE', params: { room_id: '@room_id' } }
            }
        ),

        // Admin Estimate Categories
        adminEstimateCategories: $resource(strukshurConfig.api_url + '/admin/estimate-categories', {},
            {
                list: { method: 'GET', params: { page: '@page' } }
            }
        ),

        // Admin Estimate Category
        adminEstimateCategory: $resource(strukshurConfig.api_url + '/admin/estimate-category', {},
            {
                get: { method: 'GET', params: { id: '@id' } },
                create: { method: 'POST' },
                update: { method: 'PUT', params: { category_id: '@category_id' } },
                delete: { method: 'DELETE', params: { category_id: '@category_id' } }
            }
        ),

        // Notifications
        notifications: $resource(strukshurConfig.api_url + '/me/notifications', {},
            {
                list: { method: 'GET', ignoreLoadingBar: true },
                markAsRead: { method: 'PUT', ignoreLoadingBar: true, params: { ids: '@ids' }, url: strukshurConfig.api_url + '/me/notifications/mark-as-read' }
            }
        ),

        // Project finishes
        projectFinishes: $resource(strukshurConfig.api_url + '/project/finishes', {},
            {
                list: { method: 'GET', params: { project_id: '@project_id' } },
            }
        ),
        projectFinish: $resource(strukshurConfig.api_url + '/project/finish', {},
            {
                create: { method: 'POST' },
                copy: { method: 'POST', params: { project_id: '@project_id', project_finish_id: '@project_finish_id', selected_rooms: '@selected_rooms' }, url: strukshurConfig.api_url + '/project/finish/copy' },
                update: { method: 'PUT', params: { project_finish_id: '@project_finish_id' } },
                delete: { method: 'DELETE', params: { project_finish_id: '@project_finish_id' } },
            }
        ),

        // Project finish file
        projectFinishFile: $resource(strukshurConfig.api_url + '/project/finish/file', {},
            {
                create: {
                    method: 'POST',
                    uploadEventHandlers: {
                        progress: function(e) {
                            var progress = Math.round(e.lengthComputable ? e.loaded * 100 / e.total : 0);
                            var name = 'projectFile';

                            if (progress >= 100) {
                                $timeout(function (progress) {
                                    $rootScope.$broadcast('updateProgress', { name: name, progress: progress });
                                    $timeout(function (progress) {
                                        $rootScope.$broadcast('updateProgress', { name: name, progress: progress });
                                    }, 200, true, progress);
                                }, 200, true, progress);
                            } else {
                                $rootScope.$broadcast('updateProgress', { name: name, progress: progress });
                            }
                        }
                    }
                },
                createFromFile: {
                    method: 'POST',
                    url: strukshurConfig.api_url + '/project/finish/file/from-file',
                    uploadEventHandlers: {
                        progress: (e) => {
                            const progress = Math.round(e.lengthComputable ? e.loaded * 100 / e.total : 0);
                            const name = 'projectFile';

                            if (progress >= 100) {
                                $timeout((progress) => {
                                    $rootScope.$broadcast('updateProgress', { name: name, progress: progress });
                                    $timeout((progress) => {
                                        $rootScope.$broadcast('updateProgress', { name: name, progress: progress });
                                    }, 200, true, progress);
                                }, 200, true, progress);
                            } else {
                                $rootScope.$broadcast('updateProgress', { name: name, progress: progress });
                            }
                        }
                    }
                },
                delete: { method: 'DELETE', params: { file_id: '@file_id', remove_related: '@remove_related' } }
            }
        ),

        getFinishVideoStatus: $resource(strukshurConfig.api_url + '/project/finish/video/status', {},
            {
                query: { method: 'GET', params: { finishFile_id: '@finishFile_id' }, ignoreLoadingBar: true }
            }
        ),

        projectFinishFileTag: $resource(strukshurConfig.api_url + '/project/finish/file/tag', {},
            {
                create: { method: 'POST'}
            }
        ),
        // Project finish notes
        projectFinishNote: $resource(strukshurConfig.api_url + '/project/finish/note', {},
            {
                create: { method: 'POST' },
                update: { method: 'PUT', params: { note_id: '@note_id' } },
                delete: { method: 'DELETE', params: { note_id: '@note_id' } }
            }
        ),

        // Project bids
        projectBid: $resource(strukshurConfig.api_url + '/project/bid', {},
            {
                get: { method: 'GET', params: { project_bid_id: '@project_bid_id' } },
                create: { method: 'POST' },
                update: { method: 'PUT', params: { project_bid_id: '@project_bid_id' } },
                delete: { method: 'DELETE', params: { project_bid_id: '@project_bid_id' } },
                accept: { method: 'PUT', params: { project_bid_id: '@project_bid_id' }, url: strukshurConfig.api_url + '/project/bid/accept' },
                reject: { method: 'PUT', params: { project_bid_id: '@project_bid_id' }, url: strukshurConfig.api_url + '/project/bid/reject' },
                addEstimate: { method: 'POST', params: { project_bid_id: '@project_bid_id' }, url: strukshurConfig.api_url + '/project/bid/estimate' },
                removeEstimate: { method: 'DELETE', params: { project_bid_id: '@project_bid_id', project_estimate_id: '@project_estimate_id' }, url: strukshurConfig.api_url + '/project/bid/estimate' },
                addFile: {
                    method: 'POST',
                    params: { project_bid_id: '@project_bid_id' },
                    url: strukshurConfig.api_url + '/project/bid/file',
                    uploadEventHandlers: {
                        progress: function(e) {
                            var progress = Math.round(e.lengthComputable ? e.loaded * 100 / e.total : 0);
                            var name = 'projectFile';

                            if (progress >= 100) {
                                $timeout(function (progress) {
                                    $rootScope.$broadcast('updateProgress', { name: name, progress: progress });
                                    $timeout(function (progress) {
                                        $rootScope.$broadcast('updateProgress', { name: name, progress: progress });
                                    }, 200, true, progress);
                                }, 200, true, progress);
                            } else {
                                $rootScope.$broadcast('updateProgress', { name: name, progress: progress });
                            }
                        }
                    }
                },
                removeFile: { method: 'DELETE', params: { project_bid_id: '@project_bid_id', project_file_id: '@project_file_id' }, url: strukshurConfig.api_url + '/project/bid/file' },
            }
        ),
        projectBids: $resource(strukshurConfig.api_url + '/project/bids', {},
            {
                list: { method: 'GET', params: { project_id: '@project_id' } },
                listProBids: { method: 'GET', params: { project_id: '@project_id' }, url: strukshurConfig.api_url + '/project/bids/pro-bids' },
                open: { method: 'PUT', params: { project_id: '@project_id' }, url: strukshurConfig.api_url + '/project/bids/open' },
                changeDetails: { method: 'PUT', params: { project_id: '@project_id' }, url: strukshurConfig.api_url + '/project/bids/change-details' },
                close: { method: 'PUT', params: { project_id: '@project_id' }, url: strukshurConfig.api_url + '/project/bids/close' },
            }
        ),

        // Project finish notes
        timezones: $resource(strukshurConfig.api_url + '/timezones', {},
            {
                list: { method: 'GET'}
            }
        ),

        // DeliveryPrices
        getCities: $resource(strukshurConfig.api_url + '/city/list', {},
            {
                list: { method: 'GET', params: {state: '@state'}}
            }
        ),
        getStates: $resource(strukshurConfig.api_url + '/state/list', {},
            {
                list: { method: 'GET'}
            }
        ),
        getDeliveryPrices: $resource(strukshurConfig.api_url + '/delivery/list', {},
            {
                list: { method: 'GET'}
            }
        ),
        getDeliveryPrice: $resource(strukshurConfig.api_url + '/delivery', {},
            {
                query: { method: 'GET'}
            }
        ),
        getCitiesUsed: $resource(strukshurConfig.api_url + '/city/used/list', {},
            {
                list: { method: 'GET', params: {state: '@state', vendor_id: '@vendor_id'}}
            }
        ),
        deliveryPriceSave: $resource(strukshurConfig.api_url + '/delivery/save', {},
            {
                create: { method: 'POST'},
                update: { method: 'PUT'},
                remove: { method: 'DELETE'}
            }
        ),

        // User list
        userList: $resource(strukshurConfig.api_url + '/me/list', {},
            {
                get: { method: 'GET', params: { id: '@id' } },
                create: { method: 'POST' },
                update: { method: 'PUT', params: { id: '@id' } },
                remove: { method: 'DELETE', params: { id: '@id' } },
            }
        ),
        userLists: $resource(strukshurConfig.api_url + '/me/lists', {},
            {
                fetch: { method: 'GET' },
            }
        ),

        // User list item
        userListItem: $resource(strukshurConfig.api_url + '/me/list/item', {},
            {
                create: { method: 'POST' },
                remove: { method: 'DELETE', params: { id: '@id' } },
            }
        ),
        userListItems: $resource(strukshurConfig.api_url + '/me/list/items', {},
            {
                fetch: { method: 'GET', params: { id: '@id' } },
            }
        ),

        // Project blueprints
        projectBlueprint: $resource(strukshurConfig.api_url + '/project/blueprint', {},
            {
                get: { method: 'GET', params: { room_id: '@room_id' } },
                update: { method: 'PUT', params: { room_id: '@room_id' } },
                remove: { method: 'DELETE', params: { room_id: '@room_id' } },
            }
        ),
    };
}]);
