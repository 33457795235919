import * as angular from 'angular';

angular.module('strukshurApp.auth', ['ui.router', 'ui.bootstrap'])

.config(function config($stateProvider) {
    $stateProvider.state('auth', {
        url: '/auth',
        views: {
            'main': {
                controller: 'AuthCtrl',
                template: require('./auth.tpl.html'),
            }
        },
        data: { pageTitle: 'Auth', class: 'auth' }
    });
})

.controller('AuthCtrl', function AuthCtrl($scope, $state, storage, $rootScope) {
    var usernameAuth = 'strukshur';
    var usernamePass = 'homebase';

    var auth = storage.get('strukauth') || false;

    if (auth) {
        $state.go('home');
    }

    $scope.login = function () {
        $scope.dataLoading = true;
        if (typeof $scope.username === 'string' && $scope.username.toLowerCase() === usernameAuth && $scope.password === usernamePass) {
            storage.set('strukauth', 'true');
            $rootScope.auth = true;
            $state.go('home', {}, { reload: true });
        } else {
            $scope.error = 'Invalid Credentials. Please try again.';
            $scope.dataLoading = false;
        }
    };
})
;
