import { find as _find, remove as _remove } from 'lodash';
import * as angular from 'angular';

import strukshurConfig from '../../config.ts';

var app = angular.module('strukshurUploadService', []);
app.service('strukshurUploadService', ($rootScope, FileService, FileUploader, storage, toastr) => {

    /**
     * Angular file uploader instance
     */
    var uploader;

    const service = {

        /**
         * Reference to the current file being uploaded
         */
        currentFile: null,

        /**
         * The index of current file being uploaded from the queue
         */
        currentUploadingItemIndex: 0,

        /**
         * Wether to display a loading bar when uploading files
         */
        displayLoadingBar: false,

        /**
         * Wether the service has been initialized or not
         */
        initialized: false,

        /**
         * Notification list where each key maps to a list of handlers
         * that subscribe to a specific event from the service.
         *
         * The supported event types are:
         *   upload-item-added: triggered when a single file is added to the queue
         *   upload-item-failed: triggered when there's an error while adding a file
         *   upload-item-before-upload: triggered before the start of a file upload
         *   upload-item-progress: triggered on file upload progress
         *   upload-item-success: triggered once a file upload is complete
         *   upload-item-error: triggered after a file upload error
         *   upload-item-cancel: triggered when the file upload is cancelled
         *   upload-item-complete: triggered after a file upload action is complete, regardless of its success
         *   upload-items-added: triggered when multiple files are added either by dropping them or selecting from the file dialog
         *   upload-queue-progress: triggered on the upload queue progress
         *   upload-queue-complete: triggered after all items on the queue have been progress, regardless of their success
         */
        notifyList: {},

        /**
         * The supported event types
         */
        supportedNotifyEvents: [
            'upload-item-added', 'upload-item-failed', 'upload-item-before-upload', 'upload-item-progress',
            'upload-item-success', 'upload-item-error', 'upload-item-cancel', 'upload-item-complete',
            'upload-items-added', 'upload-queue-progress', 'upload-queue-complete',
        ],

        /**
         * File upload queue
         */
        queue: [],

        /**
         * Wether the entire upload queue has been processed or not
         */
        processed: false,

        /**
         * Queue's pploading status
         */
        uploading: false,

        /**
         * Wether the upload queue has triggered an error or not
         */
        uploadQueueError: false,

        /**
         * Adds the given file to the upload queue
         *
         * @param  file  The file
         */
        addToQueue: (file) => {
            uploader.addToQueue(file);
        },

        /**
         * Cancels all current uploads
         */
        cancelAll: () => {
            uploader.cancelAll();
            service.uploading = false;
            service.currentFile = null;
        },

        /**
         * Cancels the file being currently uploaded
         */
        cancelUploadingFile: () => {
            service.currentFile.cancel();
            service.currentFile.isError = true;
            service.currentFile.isUploading = false;
            service.currentFile.errorMessage = 'The upload was cancelled by the user.';
        },

        /**
         * Clears upload queue and stops any future upload actions
         */
        clearQueue: () => {
            uploader.clearQueue();
            service.queue = [];
            $rootScope.$emit('upload-queue-cleared');
        },

        /**
         * Checks wether there's still any file needed to be uploaded
         *
         * @return {boolean}
         */
        hasFileToUpload: () => {
            return (_find(service.queue, { isUploading: false, isUploaded: false, isError: false }));
        },

        /**
         * Service initialization logic
         */
        init: () => {
            uploader = new FileUploader({
                method: 'POST',
                queueLimit: 999,
                autoUpload: false,
                withCredentials: true,
                headers: { Authorization: 'Bearer ' + storage.get('jwt') },
            });

            service.initUploaderEvents();

            service.initialized = true;
        },

        /**
         * initialize all uploader related events
         */
        initUploaderEvents: () => {
            uploader.onAfterAddingFile = (item) => {

                // Check if the file is bigger than the max allowed
                if ((item.file.size / 1024 / 1024) > strukshurConfig.max_upload_size) {
                    let msg = `The file "${item.file.name}" is larger than the limit of ${strukshurConfig.max_upload_size}MB.`;
                    item.errorMessage = msg;
                    return;
                }

                item.errorMessage = '';
                item.isUploaded = false;
                item.isUploading = false;
                item.name = item.file.name;
                item.size = item.file.size;
                item.title = item.file.name;
                item.id = Math.random() + '_' + item.name;
                item.isImage = FileService.isImage(item.file);
                item.isVideo = FileService.isVideo(item.file);
                item.isSupportedImage = FileService.isAllowedImageType(item.file);

                service.queue.push(item);

                $rootScope.$emit('upload-item-added', item);
            };

            uploader.onWhenAddingFileFailed = (item, filter, options) => $rootScope.$emit('upload-item-failed', item, filter, options);

            uploader.onBeforeUploadItem = (item) => {
                item.isUploading = true;
                service.currentFile = item;
                service.currentUploadingItemIndex++;

                $rootScope.$emit('upload-item-before-upload', item);
            };

            uploader.onProgressItem = (item, progress) => {
                item.progress = progress;

                $rootScope.$emit('upload-item-progress', item, progress);
            };

            uploader.onSuccessItem = (item, response, status, headers) => {
                item.isUploaded = true;
                item.isUploading = false;
                service.currentFile = null;

                $rootScope.$emit('upload-item-success', item, response, status, headers);
            };

            uploader.onErrorItem = (item, response, status, headers) => {
                item.isError = true;
                item.isUploading = false;

                // Early return since the user cancelled the upload
                if (response.xhrStatus && response.xhrStatus === 'abort') {
                    item.errorMessage = 'The upload was cancelled by the user.';
                    return;
                }

                let errorMessage = (response.data && response.data.message ? response.data.message: '');
                if (status === 403) {
                    errorMessage = 'You don\'t have the necessary permission to upload the file.';
                } else if (errorMessage.includes('enough storage') && errorMessage.includes('Upgrade')) {
                    errorMessage = 'Not enough space available. Please upgrade the project owner\'s plan to continue.';
                } else {
                    errorMessage = 'There was an error trying to upload the file.';
                }

                service.uploadQueueError = true;
                item.errorMessage = errorMessage;
                toastr.error(errorMessage, 'Upload error');

                $rootScope.$emit('upload-item-error', item, response, status, headers);
            };

            uploader.onCancelItem = (item, response, status, headers) => {
                item.isError = true;
                item.isCancelled = true;
                item.isUploading = false;
                item.errorMessage = 'The upload was cancelled by the user.';

                $rootScope.$emit('upload-item-cancel', item, response, status, headers);
            };

            uploader.onCompleteItem = (item, response, status, headers) => $rootScope.$emit('upload-item-complete', item, response, status, headers);

            uploader.onAfterAddingAll = (addedItems) => {
                service.processed = false;
                $rootScope.$emit('upload-items-added', addedItems);
            };

            uploader.onProgressAll = (progress) => $rootScope.$emit('upload-queue-progress', progress);

            uploader.onCompleteAll = () => {
                if (service.uploadQueueError) {
                    toastr.error('We encontered a problem while processing your queue.', 'Upload error');
                } else {
                    toastr.success('All the files you added to the queue were processed.', 'Upload complete!');
                }

                // All files were processed
                service.processed = true;
                service.uploading = false;
                service.currentFile = null;
                service.uploadQueueError = false;

                $rootScope.$emit('upload-queue-complete');
            };
        },

        /**
         * Returns the upload queue
         *
         * @returns array
         */
        getQueue: () => service.queue,

        /**
         * @returns FileUploader
         */
        getUploader: () => uploader,

        /**
         * Removes the given file from the upload queue
         *
         * @param  file  The file
         */
        removeFromQueue: (file) => {
            uploader.removeFromQueue(file);
            _remove(service.queue, file);
        },

        /**
         * Prepares the upload service for use
         *
         * @param  config  Optional config object
         */
        setup: (config?) => {
            if (!service.initialized) {
                service.init();
            }

            if ('displayLoadingBar' in config) {
                service.displayLoadingBar = config.displayLoadingBar;
            }

            if ('subscribeEvents' in config) {
                service.setupEventsSubscription(config.subscribeEvents);
            }
        },

        /**
         * Subscribes to the received events
         *
         * @param {array}  events  The events to setup the subscription for
         */
        setupEventsSubscription: (events: any[]) => {
            for (let event of events) {
                if (!(event.name in service.notifyList)) {
                    service.notifyList[event.name] = [];
                }

                let handler = service.subscribe(event.name, event.scope, event.callback);
                service.notifyList[event.name].push({ ...event, handler: handler });
            }
        },

        /**
         * Quickstarts the upload process for the queued files
         */
        startUpload: () => {
            if (service.uploading) { return; }

            service.uploading = true;
            service.currentFile = null;
            // service.hasFilesToUpload = true;
            service.currentUploadingItemIndex = 0;
            // service.uploadItemsTotal = _filter(vm.queuedFiles, { isUploading: false, isUploaded: false, isError: false }).length;
            uploader.uploadAll();
        },

        /**
         * Subscribes to the given event
         *
         * @param  eventName  The event name
         * @param  scope      The scope
         * @param  callback   The callback
         */
        subscribe: (eventName, scope, callback) => {
            if (!service.supportedNotifyEvents.includes(eventName)) {
                throw new Error(`Events of type ${eventName} are not supported.`);
            }

            let handler = $rootScope.$on(eventName, callback);
            scope.$on('$destroy', handler);

            // Returns unsubscribe function so the consumer can optionally unsubscribe
            // from the event manually if they want to
            return handler;
        },
    };

    return service;
});
