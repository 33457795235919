import * as angular from 'angular';

angular.module('ChangeOnBlurDirective', [])

.directive('changeOnBlur', () => {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: (scope, elm, attrs) => {
            if (attrs.type === 'radio' || attrs.type === 'checkbox') { return; }

            var oldValue = null;
            var expressionToCall = attrs.changeOnBlur;

            elm.bind('focus', () => {
                scope.$apply(() => oldValue = elm.val());
            })

            elm.bind('blur', () => {
                scope.$apply(() => {
                    var newValue = elm.val();
                    if (newValue !== oldValue) {
                        scope.$eval(expressionToCall);
                    }
                });
            });
        }
    };
})

;
