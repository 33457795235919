import {
    find as _find,
    pull as _pull,
    sample as _sample,
    sortBy as _sortBy,
} from 'lodash';
import * as angular from 'angular';

angular.module('strukshurApp.setup', ['ui.router', 'ui.bootstrap', 'userResolveService'])

.config(function config($stateProvider) {
    $stateProvider
        .state('setup-interior', {
            url: '/:userSlug/setup/interior',
            views: {
                main: {
                    controller: 'SetupInteriorCtrl',
                    template: require('./setup.interior.tpl.html')
                }
            },
            resolve: {
                userResolve: function(userResolveService) {
                    return userResolveService();
                }
            },
            data: { pageTitle: 'Design', class: 'setup setup-interior', headerLogoFull: true }
        })

        .state('my-home-confirmation', {
            url: '/:userSlug/my-home/email-confirmed',
            views: {
                main: {
                    controller: 'SetupInteriorCtrl',
                    template: require('./setup.interior.tpl.html')
                }
            },
            resolve: {
                userResolve: function(userResolveService) {
                    return userResolveService();
                }
            },
            data: { pageTitle: 'Design', class: 'setup setup-interior', headerLogoFull: true }
        })

        .state('confirm', {
            url: '/home/register/complete/:plan',
            views: {
                main: {
                    controller: 'SetupConfirmCtrl',
                    template: require('./setup.confirm.tpl.html')
                }
            },
            data: { pageTitle: 'Confirm your Account', class: 'register-confirm', headerLogoFull: true }
        })

        .state('register-payment', {
            url: '/home/register/payment',
            params: {
                emailConfirmed: false
            },
            views: {
                main: {
                    controller: 'ProRegisterPaymentCtrl',
                    template: require('../pros/pros.register.payment.tpl.html')
                }
            },
            resolve: {
                userResolve: function(userRouterResolveService){
                    return userRouterResolveService.userMustBeReg();
                }
            },
            data: { pageTitle: 'Register Account - Payment', class: 'pro-register template-page-simple' }
        })

        .state('confirm-invitation', {
            url: '/confirm/invitation',
            views: {
                main: {
                    controller: 'SetupConfirmInvitationCtrl',
                    template: require('./setup.confirm.invitation.tpl.html')
                }
            },
            data: { pageTitle: 'Confirm your Account', class: 'setup setup-interior', headerLogoFull: true }
        })
    ;
})

.controller('SetupConfirmCtrl', function SetupConfirmCtrl ($scope, $stateParams, $timeout, PricingService) {
    var vm = $scope;

    vm.overlay = false;
    vm.plan = PricingService.getPlan($stateParams.plan);
    vm.plan_name = PricingService.getName($stateParams.plan);

    if (typeof gtag !== 'undefined' && vm.plan.gtagHash) {
        console.log(vm.plan.gtagHash);
        $timeout(gtag('event', 'conversion', { 'send_to': vm.plan.gtagHash }), 250);
    }
})

.controller('SetupConfirmInvitationCtrl', function SetupConfirmInvitationCtrl ($scope, $state, $timeout) {
    var vm = $scope;

    vm.overlay = false;

    $timeout(() => $state.go('projects'), 1000 * 5);
})

;
