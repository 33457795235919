import * as angular from 'angular';

var app = angular.module('InboxService', []);

app.factory('InboxService', ['$http', '$rootScope', 'storage',  function ($http, $rootScope, storage) {
    var lastChannel = {};

    return {
        getLastChannel: function (project_id) {
            if (typeof lastChannel[String(project_id)] === 'undefined') {
                lastChannel[String(project_id)] = {};
            }

            return lastChannel[String(project_id)];
        },
        setLastChannel: function (chanelName, project_id) {

            lastChannel[String(project_id)] = chanelName;
        },
        isUrl: function (text) {
            var regex;
            regex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/i;
            return regex.test(text);
        },
    };
}]);
